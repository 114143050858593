<template>
  <v-dialog
    v-model="dialogprofile"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
     <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-card>
      <v-toolbar dark color="fourth" dense>
        <v-btn icon dark @click.stop="dialogprofile = false">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>Profile</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <template>
        <v-progress-linear
          indeterminate
          color="primary"
          rounded
          v-if="loading.page"
        ></v-progress-linear>
        <v-tabs
          v-model="tabprofile"
          background-color="white"
          color="fourth"
          v-if="access.read === 1"
        >
          <v-tab id="tabform" href="#tab-form" @click="FirstLoad"> Form </v-tab>
          <v-tab href="#tab-list" v-if="find.id !== ''"> List </v-tab>
          <v-tab href="#tab-list" id="tablist" @click="List" v-else> List </v-tab>
          <v-tab-item :eager ="true" value="tab-form">
            <v-form
              enctype="multipart/form-data"
              ref="form_profile"
              lazy-validation
            >
              <v-row class="mx-2 ma-1">
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    color="tertiary"
                    class="white--text"
                    :disabled="access.add === 0 ? true : false"
                    rounded
                    @click="Add"
                    small
                  >
                    <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
                  </v-btn>
                </v-col>
                <v-col cols="12" :sm="form.profile_corporate === true?'12':'3'" :md="form.profile_corporate === true?'12':'3'">
                  <v-avatar color="primary" size="100" @click="Upload">
                    <img
                      :src="form.profile_avatar_url"
                      v-if="form.profile_avatar !== ''"
                    />
                    <input
                      type="file"
                      ref="profile_avatar"
                      @change="UploadAvatar"
                      accept="image/*"
                      style="display: none"
                    />
                  </v-avatar>
                </v-col>
                <v-col cols="12" sm="9" md="9" v-show="form.profile_corporate === false">
                  <v-card class="secondary text-center cursor" width="200" height="150" @click="UploadIDImages" :img="form.profile_refidimages_url">
                    <div class="caption primary mb-3 white--text">Kartu Tanda Penduduk</div>
                    <input
                      type="file"
                      ref="profile_refidimages"
                      @change="UploadIDImagesURL"
                      accept="image/*"
                      style="display: none"
                    />
                  </v-card>
                </v-col>
                <!-- ID -->
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="form.profile_id"
                    required
                    :readonly="form.profile_id_readonly"
                    :background-color="
                      form.profile_id_readonly ? 'readonly' : ''
                    "
                    :maxlength="maxinput.id"
                    :counter="maxinput.id"
                    label="ID *"
                    placeholder="ID"
                    id="profile_id"
                    @keyup.enter="Confirm('write', '')"
                  >
                  </v-text-field>
                </v-col>
                <!-- REFID -->
                <!-- <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    v-model="form.profile_refid"
                    label="Ref ID"
                    :maxlength="maxinput.refid"
                    :counter="maxinput.refid"
                    placeholder="Ref ID"
                    id="profile_refid"
                    @keyup.enter="Confirm('write', '')"
                  >
                  </v-text-field>
                </v-col> -->
                <!-- EXTID -->
                <!-- <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    v-model="form.profile_extid"
                    :maxlength="maxinput.extid"
                    :counter="maxinput.extid"
                    label="Ext ID"
                    placeholder="Ext ID"
                    id="profile_extid"
                    @keyup.enter="Confirm('write', '')"
                  >
                  </v-text-field>
                </v-col> -->
                <v-col cols="12" sm="2" md="2" class="mt-n5">
                  <label class="caption">Active Status</label>
                  <v-switch
                    v-model="form.profile_actived"
                    color="primary"
                    id="form.profile_actived"
                    :label="form.profile_actived !== true ? '' : 'Active'"
                  ></v-switch>
                </v-col>
                <!-- Corporate -->
                <v-col cols="12" sm="2" md="2" class="mt-n5">
                  <label class="caption">Corporate</label>
                  <v-switch
                    v-model="form.profile_corporate"
                    color="primary"
                    id="form.profile_corporate"
                    :label="form.profile_corporate !== true ? '' : 'Yes'"
                  ></v-switch>
                </v-col>
                <!-- Profile LOB -->
                <v-col cols="12" sm="5" md="5" class="mt-3">
                  <app-cb
                    cb_type="profilelob"
                    cb_url="apiListProfileLOB"
                    :cb_url_parameter="`profilelob_actived=Y&profilelob_id=${addon.lob}`"
                    cb_title="Profile Line of Business"
                    cb_id="profile_lob"
                    cb_desc="profile_lob_desc"
                    cb_rules="Please fill Line of Business"
                    cb_desc_readonly="readonly"
                    cb_items_id="profilelob_id"
                    cb_items_desc="profilelob_desc"
                    cb_items_additional_1="profilelob_profiletype"
                    cb_items_additional_2="profilelob_profiletype_desc"
                    :cb_value_id="form.profile_lob"
                    :cb_value_desc="form.profile_lob_desc"
                    @clicked="GetLob"
                  >
                  </app-cb>
                </v-col>
                 <!-- Profile Type -->
                <!-- <v-col cols="12" sm="3" md="3" class="mt-3">
                  <app-cb
                    cb_type="profiletype"
                    cb_url="apiListProfileType"
                    cb_url_parameter="profiletype_actived=Y"
                    cb_title="Profile Type"
                    cb_id="profile_type"
                    cb_desc="profile_typedesc"
                    cb_rules="Please fill Profiletype"
                    cb_id_readonly="readonly"
                    cb_desc_readonly="readonly"
                    cb_items_id="profiletype_id"
                    cb_items_desc="profiletype_desc"
                    :cb_value_id="form.profile_type"
                    :cb_value_desc="form.profile_type_desc"
                  >
                  </app-cb>
                </v-col> -->
                 <v-col cols="12" :sm="form.profile_branchunitf?'5':'3'" :md="form.profile_branchunitf?'5':'3'">
                  <app-cb
                    cb_type="profilecgroup"
                    cb_url="apiListProfileCGroup"
                    cb_url_parameter="profilecgroup_actived=Y"
                    cb_title="Company Group"
                    cb_id="profile_cgroup"
                    cb_desc="profile_cgroup_desc"
                    :cb_rules="form.profile_corporate === true?'Please fill Company Group':''"
                    cb_desc_readonly="readonly"
                    cb_items_id="profilecgroup_id"
                    cb_items_desc="profilecgroup_desc"
                    :cb_value_id="form.profile_cgroup"
                    :cb_value_desc="form.profile_cgroup_desc"
                    @clicked="GetCGroup"
                  >
                  </app-cb>
                </v-col>
                <!-- Code (Salutation) -->
                <v-col cols="12" sm="2" md="2" v-if="form.profile_branchunitf === false">
                  <app-cb
                    cb_type="code"
                    cb_url="apiListCode"
                    cb_url_parameter="code_group=SALUTATION&code_actived=Y"
                    cb_title="Salutation"
                    cb_single="Y"
                    cb_id="profile_salutation"
                    cb_desc="profile_salutationdesc"
                    :cb_rules="form.profile_lob === '07' && form.profile_lob === '08'?'':'Please fill Salutation'"
                    cb_desc_readonly="readonly"
                    cb_items_id="code_id"
                    cb_items_desc="code_desc"
                    :cb_value_id="form.profile_salutation"
                    :cb_value_desc="form.profile_salutationdesc"
                    @clicked="GetSalutation"
                  >
                  </app-cb>
                </v-col>
                 <v-col cols="12" sm="5" md="5" class="mt-n3" v-if="form.profile_branchunitf">
                  <v-text-field
                    v-model="form.profile_branchunit"
                    required
                    :rules="form.profile_branchunit_rules"
                    :maxlength="maxinput.desc"
                    label="Branch Name *"
                    placeholder="Branch Name"
                    id="profile_branchunit"
                    @keyup.enter="Confirm('write', '')"
                    prepend-inner-icon="badge"
                    @change="ChangeProfileName"
                  >
                  </v-text-field>
                </v-col>
                <!-- ID -->
                <v-col cols="12" sm="5" md="5" class="mt-n3" v-else>
                  <v-text-field
                    v-model="form.profile_name"
                    required
                    :rules="form.profile_name_rules"
                    :maxlength="maxinput.desc"
                    label="Name *"
                    placeholder="Name"
                    id="profile_name"
                    @keyup.enter="Confirm('write', '')"
                    prepend-inner-icon="badge"
                  >
                  </v-text-field>
                </v-col>
                <!-- ID -->
                <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                    v-model="form.profile_alias"
                    label="Alias *"
                    placeholder="Alias"
                    @keyup.enter="Confirm('write', '')"
                    prepend-inner-icon="mood"
                  >
                  </v-text-field>
                </v-col>
                <!-- Profile Address -->
                <v-col cols="12" sm="9" md="9" class="mt-n4">
                  <v-text-field
                    id="profile_address"
                    label="Address *"
                    placeholder="Address"
                    v-model="form.profile_address"
                    :rules="form.profile_address_rules"
                    prepend-inner-icon="location_on"
                  >
                  </v-text-field>
                </v-col>
                <!-- Profile Phone 1 -->
                <v-col cols="12" sm="3" md="3" class="mt-n4">
                  <v-text-field
                    label="Phone*"
                    :maxlength="maxinput.nophone"
                    :counter="maxinput.nophone"
                    placeholder="Phone"
                    v-model="form.profile_phone_1"
                    :rules="form.profile_phone_1_rules"
                    prepend-inner-icon="call"
                  >
                  </v-text-field>
                </v-col>
                <!-- Gender -->
                <v-col cols="12" sm="3" md="3" class="mt-n1" v-if="form.profile_corporate === false">
                  <v-select
                    v-model="form.profile_gender"
                    required
                    :items="rows.gender"
                    :item-text="rows.gender.text"
                    :item-value="rows.gender.value"
                    label="Gender"
                    placeholder="Gender"
                    id="profile_gender"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="7" md="7"  class="mt-n5" v-if="form.profile_corporate === false">
                  <v-text-field
                    label="Birth Place *"
                    placeholder="Birth Place"
                    v-model="form.profile_birth_place"
                    prepend-inner-icon="home"
                  >
                  </v-text-field>
                </v-col>
                <!-- Birth Date -->
                <v-col cols="12" sm="2" md="2"  class="mt-n5" v-if="form.profile_corporate === false">
                  <v-dialog
                    ref="dialog_birthdate"
                    v-model="modal.birthdate"
                    :return-value.sync="form.profile_birth_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.profile_birth_date"
                        label="Birth Date"
                        prepend-inner-icon="event"
                        background-color="date"
                        v-on="on"
                        v-mask="'####-##-##'"
                        placeholder="YYYY-MM-DD"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.profile_birth_date"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.birthdate = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog_birthdate.save(
                            form.profile_birth_date
                          )
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                  <!-- Profile Marital Status -->
                  <!-- <v-col cols="12" sm="3" md="3" class="mt-5">
                    <v-select
                      v-model="form.profile_marital_status"
                      required
                      :items="rows.marital"
                      :item-text="rows.marital.text"
                      :item-value="rows.marital.value"
                      label="Marital"
                      placeholder="Marital"
                      id="profile_marital_status"
                      dense
                    >
                    </v-select>
                  </v-col> -->

                  <!-- Profile Religion -->
                  <!-- <v-col cols="12" sm="3" md="3" class="mt-5">
                    <v-select
                      v-model="form.profile_religion"
                      required
                      :items="rows.religion"
                      :item-text="rows.religion.text"
                      :item-value="rows.religion.value"
                      label="Religion"
                      placeholder="Religion"
                      id="profile_religion"
                      dense
                    >
                    </v-select>
                  </v-col> -->
                  <!-- Profile Height -->
                  <!-- <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      label="Height*"
                      placeholder="Height"
                      v-model="form.profile_height"
                    >
                    </v-text-field>
                  </v-col> -->
                  <!-- Profile Weight -->
                  <!-- <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      label="Weight*"
                      placeholder="Weight"
                      v-model="form.profile_weight"
                    >
                    </v-text-field>
                  </v-col> -->
                  <!-- Profile Education -->
                  <!-- <v-col cols="12" sm="3" md="3" class="mt-5">
                    <v-select
                      v-model="form.profile_education"
                      required
                      :items="rows.education"
                      :item-text="rows.education.text"
                      :item-value="rows.education.value"
                      label="Education"
                      placeholder="Education"
                      id="profile_education"
                      dense
                    >
                    </v-select>
                  </v-col> -->
                  <!-- Profile Mothers Name -->
                  <!-- <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      label="Mothers Name*"
                      placeholder="Mothers Name"
                      v-model="form.profile_mothers_name"
                    >
                    </v-text-field>
                  </v-col> -->
                <v-col cols="12" sm="2" md="2">
                  <v-select
                    v-model="form.profile_pic_gender"
                    required
                    :items="rows.gender"
                    :item-text="rows.gender.text"
                    :item-value="rows.gender.value"
                    label="Gender"
                    placeholder="Gender"
                    id="profile_pic_gender"
                    :rules="form.profile_pic_gender_rules"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="mt-n5">
                    <v-text-field
                      id="profile_pic_name"
                      :label="form.profile_lob === '01'?'PIC Name':'PIC Name *'"
                      placeholder="PIC Name"
                      v-model="form.profile_pic_name"
                      :rules="form.profile_lob === '01'?'':form.profile_pic_name_rules"
                      prepend-inner-icon="person"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Profile PIC Name -->
                  <v-col cols="12" sm="2" md="2" class="mt-n5">
                    <v-text-field
                      id="profile_pic_title"
                      :label="form.profile_lob === '01'?'PIC Title':'PIC Title *'"
                      placeholder="PIC Title"
                      v-model="form.profile_pic_title"
                      :rules="form.profile_lob === '01'?'':form.profile_pic_title_rules"
                      prepend-inner-icon="work"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Profile PIC Mobile -->
                  <v-col cols="12" sm="2" md="2" class="mt-n5">
                    <v-text-field
                      id="profile_pic_phone"
                      :label="form.profile_lob === '01'?'PIC Mobile':'PIC Mobile *'"
                      :maxlength="maxinput.nophone"
                      :counter="maxinput.nophone"
                      placeholder="PIC Mobile"
                      v-model="form.profile_pic_phone"
                      :rules="form.profile_lob === '01'?'':form.profile_pic_phone_rules"
                      prepend-inner-icon="smartphone"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Profile PIC Email -->
                  <v-col cols="12" sm="3" md="3" class="mt-n5">
                    <v-text-field
                      id="profile_pic_email"
                      :label="form.profile_lob === '01'?'PIC Email':'PIC Email *'"
                      placeholder="PIC Email"
                      v-model="form.profile_pic_email"
                      :rules="form.profile_lob === '01'?'':form.profile_pic_email_rules"
                      prepend-inner-icon="email"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                  <v-btn
                    color="primary"
                    class="white--text"
                    :disabled="
                      access.add === 0 || access.edit === 0 ? true : false
                    "
                    outlined
                    @click="Confirm('write', '')"
                  >
                    <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
                  </v-btn>
                  </v-col>
                  <!-- Profile PIC Address -->
                  <!-- <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="PIC Address"
                      placeholder="PIC Address"
                      v-model="form.profile_pic_address"
                    >
                    </v-text-field>
                  </v-col> -->
                  <!-- Profile PIC ZIPCODE -->
                  <!-- <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      label="PIC Zipcode"
                      :maxlength="maxinput.zipcode"
                      :counter="maxinput.zipcode"
                      placeholder="PIC Zipcode"
                      v-model="form.profile_pic_zipcode"
                    >
                    </v-text-field>
                  </v-col> -->
                <!-- BRANCH -->
                <!-- <v-col cols="12" sm="3" md="3" class="mt-3">
                  <app-cb
                    cb_type="branch"
                    cb_url="apiListBranch"
                    cb_url_parameter="branch_actived=Y"
                    cb_title="Branch"
                    cb_id="profile_branch"
                    cb_desc="profile_branchdesc"
                    cb_rules="Please fill Branch"
                    cb_desc_readonly="readonly"
                    cb_items_id="branch_id"
                    cb_items_desc="branch_desc"
                    :cb_value_id="form.profile_branch"
                    :cb_value_desc="form.profile_branchdesc"
                  >
                  </app-cb>
                </v-col> -->

                <!-- Profile Ref Type -->
                <!-- <v-col cols="12" sm="2" md="2" class="mt-5">
                  <v-select
                    v-model="form.profile_ref_type"
                    required
                    :items="rows.reftype"
                    :item-text="rows.reftype.text"
                    :item-value="rows.reftype.value"
                    label="Ref Type"
                    placeholder="Ref Type"
                    id="profile_ref_type"
                    dense
                  >
                  </v-select>
                </v-col> -->
                <!-- REFNO  -->
                <!-- <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    v-model="form.profile_ref_no"
                    :maxlength="maxinput.refno"
                    :counter="maxinput.refno"
                    label="Ref No*"
                    placeholder="Ref No"
                    id="profile_ref_no"
                    @keyup.enter="Confirm('write', '')"
                  >
                  </v-text-field>
                </v-col> -->

                <!-- Corporate -->
                <!-- <v-col cols="12" sm="2" md="2" class="mt-n5">
                  <label class="caption">Biz Source</label>
                  <v-switch
                    v-model="form.profile_bizsourcef"
                    color="primary"
                    id="form.profile_bizsourcef"
                    :label="form.profile_bizsourcef !== true ? '' : 'Yes'"
                  ></v-switch>
                </v-col> -->
                <!-- Active -->
              </v-row>
              <v-row class="mx-2">
                <v-col cols="12" sm="5" md="5">
                  <div v-show="url_type !== 'add' ? true : false">
                    <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                    <v-banner
                      v-model="cont"
                      single-line
                      transition="slide-y-transition"
                    >
                      <pre>
Created Date 	 : {{form.profile_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.profile_cuser)">{{form.profile_cuser}}</span>
Last Update Date : {{form.profile_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.profile_luser)">{{form.profile_luser}}</span>
</pre>
                    </v-banner>
                  </div>
                </v-col>
              </v-row>
              <v-tabs
                v-model="tabs"
                background-color="white"
                color="secondary"
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows
              >
                <!-- <v-tab id="tabgeneral" href="#tab-general" ref="tabgeneral"> General</v-tab> -->
                <!-- <v-tab id="tabcompany" href="#tab-company" ref="tabcompany">
                  Company Info
                </v-tab> -->
                <!-- <v-tab id="tabtaxation" tab-value="tab-taxation" key="tab-taxation" ref="tabtaxation" v-show="form.profile_lob ==='04' && url_type === 'edit'"> Taxation </v-tab> -->
                <v-tab
                  v-if="url_type !== 'add'"
                  id="tabaccount"
                  href="#tab-account"
                  @click="ListProfileAccount(form.profile_id)"
                >
                  Account
                </v-tab>
                <v-tab id="taborganization" href="#tab-organization" v-show="form.profile_lob ==='02' && url_type === 'edit'">
                  Organization
                </v-tab>
                <v-tab id="tabportfolio" href="#tab-portfolio" v-show="form.profile_lob ==='02' && url_type === 'edit'">
                  Portfolio
                </v-tab>
                <v-tab id="tabcapital" href="#tab-capital" v-show="form.profile_lob ==='02' && url_type === 'edit'">
                  Finance Report
                </v-tab>
                <v-tab id="tabattachment" href="#tab-attachment" v-show="form.profile_lob ==='02' && url_type === 'edit'">
                  Attachment
                </v-tab>
                <!-- <v-tab id="tabagency" href="#tab-agency" v-show="form.profile_lob ==='04' && url_type === 'edit'"> Agency </v-tab> -->
                <!-- <v-tab-item :eager ="true" key="tab-general" value="tab-general"> -->
                  
                  <!-- <div class="font-weight-black mt-3 ml-5">PHONE NUMBER:</div>
                  <v-row class="mx-2 mt-2"> -->
                    <!-- Profile Phone 2 -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Phone 2"
                        :maxlength="maxinput.nophone"
                        :counter="maxinput.nophone"
                        placeholder="Phone 2"
                        v-model="form.profile_phone_2"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Mobile 1 -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Mobile 1"
                        :maxlength="maxinput.nophone"
                        :counter="maxinput.nophone"
                        placeholder="Mobile 1"
                        v-model="form.profile_mobile_1"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Mobile 2 -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Mobile 2"
                        :maxlength="maxinput.nophone"
                        :counter="maxinput.nophone"
                        placeholder="Mobile 2"
                        v-model="form.profile_mobile_2"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile mobile 1 Wa -->
                    <!-- <v-col cols="12" sm="2" md="2" class="mt-n5">
                      <label class="caption">Mobile 1 WA</label>
                      <v-switch
                        v-model="form.profile_mobile_1_wa"
                        color="primary"
                        id="form.profile_mobile_1_wa"
                        :label="form.profile_mobile_1_wa !== true ? '' : 'Yes'"
                      ></v-switch>
                    </v-col> -->

                    <!-- Profile mobile 2 Wa -->
                    <!-- <v-col cols="12" sm="2" md="2" class="mt-n5">
                      <label class="caption">Mobile 2 WA</label>
                      <v-switch
                        v-model="form.profile_mobile_2_wa"
                        color="primary"
                        id="form.profile_mobile_2_wa"
                        :label="form.profile_mobile_2_wa !== true ? '' : 'Yes'"
                      ></v-switch>
                    </v-col>
                  </v-row> -->
                  <!-- <div class="font-weight-black mt-3 ml-5">ADDRESSES:</div>
                  <v-row class="mx-2 mt-2"> -->
                    <!-- Profile Provinces -->
                    <!-- <v-col cols="12" sm="3" md="3" class="mt-3">
                      <app-cb
                        cb_type="province"
                        cb_url="apiListGenDetailShort"
                        cb_url_parameter="gendet_gentab_id=P01&gendet_actived=Y"
                        cb_title="Provinces"
                        cb_id="profile_province"
                        cb_desc="profile_province_desc"
                        cb_rules="Please fill Province"
                        cb_desc_readonly="readonly"
                        cb_items_id="gendet_id"
                        cb_items_desc="gendet_desc"
                        :cb_value_id="form.profile_province"
                        :cb_value_desc="form.profile_province_desc"
                        @clicked="GetData"
                      >
                      </app-cb>
                    </v-col> -->
                    <!-- Profile Regency -->
                    <!-- <v-col cols="12" sm="3" md="3" class="mt-3">
                      <app-cb
                        cb_type="regency"
                        cb_url="apiListGenDetailShort"
                        :cb_url_parameter="`gendet_gentab_id=R06&gendet_fieldid_1=P01&gendet_fieldvalue_1=${form.profile_province}&gendet_actived=Y`"
                        cb_title="Regency"
                        cb_id="profile_regency"
                        cb_desc="profile_regency_desc"
                        cb_rules="Please fill Regency"
                        cb_desc_readonly="readonly"
                        cb_items_id="gendet_id"
                        cb_items_desc="gendet_desc"
                        :cb_value_id="form.profile_regency"
                        :cb_value_desc="form.profile_regency_desc"
                        @clicked="GetData"
                      >
                      </app-cb>
                    </v-col> -->
                    <!-- Profile Regency -->
                    <!-- <v-col cols="12" sm="3" md="3" class="mt-3">
                      <app-cb
                        cb_type="districts"
                        cb_url="apiListGenDetailShort"
                        :cb_url_parameter="`gendet_gentab_id=D01&gendet_fieldid_1=R06&gendet_fieldvalue_1=${form.profile_regency}&gendet_actived=Y`"
                        cb_title="Districts"
                        cb_id="profile_districts"
                        cb_desc="profile_districts_desc"
                        cb_rules="Please fill Districts"
                        cb_desc_readonly="readonly"
                        cb_items_id="gendet_id"
                        cb_items_desc="gendet_desc"
                        :cb_value_id="form.profile_districts"
                        :cb_value_desc="form.profile_districts_desc"
                        @clicked="GetData"
                      >
                      </app-cb>
                    </v-col> -->
                    <!-- Profile Villages -->
                    <!-- <v-col cols="12" sm="3" md="3" class="mt-3">
                      <app-cb
                        cb_type="villages"
                        cb_url="apiListGenDetailShort"
                        :cb_url_parameter="`gendet_gentab_id=V14&gendet_fieldid_1=D01&gendet_fieldvalue_1=${form.profile_districts}&gendet_actived=Y`"
                        cb_title="Villages"
                        cb_id="profile_villages"
                        cb_desc="profile_villages_desc"
                        cb_rules="Please fill Villages"
                        cb_desc_readonly="readonly"
                        cb_items_id="gendet_id"
                        cb_items_desc="gendet_desc"
                        :cb_value_id="form.profile_villages"
                        :cb_value_desc="form.profile_villages_desc"
                        @clicked="GetData"
                      >
                      </app-cb>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        id="profile_city"
                        label="City *"
                        :maxlength="maxinput.city"
                        :counter="maxinput.city"
                        placeholder="City"
                        v-model="form.profile_city"
                        :rules="form.profile_city_rules"
                      >
                      </v-text-field>
                    </v-col> -->
                    
                    <!-- Profile Address 2 -->
                    <!-- <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Address 2"
                        placeholder="Address"
                        v-model="form.profile_address_2"
                      >
                      </v-text-field>
                    </v-col> -->
                     <!-- Profile Address 2 -->
                    <!-- <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Address 3"
                        placeholder="Address"
                        v-model="form.profile_address_3"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Paypos No -->
                    <!-- <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        label="Zip Code"
                        :maxlength="maxinput.zipcode"
                        :counter="maxinput.zipcode"
                        placeholder="Zip Code"
                        v-model="form.profile_zipcode"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Latutiude -->
                    <!-- <v-col cols="12" sm="5" md="5">
                      <v-text-field
                        label="Latitude"
                        placeholder="Latitude"
                        v-model="form.profile_latitude"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Longitude -->
                    <!-- <v-col cols="12" sm="5" md="5">
                      <v-text-field
                        label="Longitude"
                        placeholder="Longitude"
                        v-model="form.profile_longitude"
                      >
                      </v-text-field>
                    </v-col> -->
                  <!-- </v-row> -->
                  <!-- <div class="font-weight-black mt-3 ml-5" v-show="form.profile_corporate === false">WORK INFO:</div>
                  <v-row class="mx-2 mt-2" v-show="form.profile_corporate === false"> -->
                    <!-- ProfileSalary -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <vuetify-money
                        v-model           ="form.profile_salary"
                        v-bind:options    ="formatamount"
                        label             ="Salary"
                        placeholder       ="Salary"
                        background-color  ="numeric"
                        v-bind:properties ="properties"
                      />
                    </v-col> -->
                    <!-- Profile EMP Status -->
                    <!-- <v-col cols="12" sm="3" md="3" class="mt-5">
                      <v-select
                        v-model="form.profile_empstatus"
                        required
                        :items="rows.empstatus"
                        :item-text="rows.empstatus.text"
                        :item-value="rows.empstatus.value"
                        label="Employee status"
                        placeholder="Employee status"
                        id="profile_empstatus"
                        dense
                      >
                      </v-select>
                    </v-col> -->
                    <!-- Profile EMP Title -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Employee title *"
                        placeholder="Employee title"
                        v-model="form.profile_emptitle"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Purpose -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Purpose *"
                        placeholder="Purpose"
                        v-model="form.profile_purpose"
                        :maxlength="maxinput.purpose"
                        :counter="maxinput.purpose"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Sourceincome -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Source Income *"
                        placeholder="Source Income"
                        v-model="form.profile_sourceincome"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Comp Name -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Company Name"
                        placeholder="Company Name"
                        v-model="form.profile_compname"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Job Desc -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Job Desc"
                        placeholder="Job Desc"
                        v-model="form.profile_jobdesc"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Job CLass -->
                    <!-- <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Job Class"
                        placeholder="Job Class"
                        v-model="form.profile_jobclass"
                        :maxlength="maxinput.jobclass"
                        :counter="maxinput.jobclass"
                      >
                      </v-text-field>
                    </v-col> -->

                    <!-- Profile Remarks -->
                    <!-- <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        label="Remarks"
                        placeholder="Remarks"
                        v-model="form.profile_remarks"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile Range Salary -->
                    <!-- <v-col cols="12" sm="3" md="3" class="mt-n6">
                      <label class="caption">Range Salary</label>
                      <v-switch
                        v-model="form.profile_rangesalary"
                        color="primary"
                        id="form.profile_rangesalary"
                        :label="form.profile_rangesalary !== true ? '' : 'Yes'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-tab-item> -->

                <!-- <v-tab-item :eager ="true" key="tab-company" value="tab-company">
                  <v-row class="mx-2 ma-1"> -->
                    <!-- Profile CG Group -->
                    <!-- Profile SC Group -->
                    <!-- <v-col cols="12" sm="1" md="1">
                      <v-text-field
                        label="Sub Company Group"
                        :maxlength="maxinput.scgroup"
                        :counter="maxinput.scgroup"
                        placeholder="Subcompany Group"
                        v-model="form.profile_scgroup"
                      >
                      </v-text-field>
                    </v-col> -->
                    <!-- Profile PIC Name -->

                    <!-- Profile Rating -->
                    <!-- <v-col cols="12" sm="3" md="3" class="mt-5">
                      <v-select
                        v-model="form.profile_rating"
                        required
                        :items="rows.rating"
                        :item-text="rows.rating.text"
                        :item-value="rows.rating.value"
                        label="Rating"
                        placeholder="Rating"
                        id="profile_rating"
                        dense
                      >
                      </v-select>
                    </v-col> -->
                  <!-- </v-row> -->
                <!-- </v-tab-item> -->

                <!-- <v-tab-item :eager ="true" value="tab-taxation">
                  <v-row class="mx-2 ma-1">
                    <v-col cols="12" sm="2" md="2" class="mt-3">
                      <app-cb
                        cb_type="code"
                        cb_url="apiListCode"
                        cb_url_parameter="code_group=TAXTYPE&code_actived=Y"
                        cb_title="Tax type"
                        cb_single="Y"
                        cb_id="profile_tax_type"
                        cb_desc="profile_tax_typedesc"
                        cb_rules=""
                        cb_desc_readonly="readonly"
                        cb_items_id="code_id"
                        cb_items_desc="code_desc"
                        :cb_value_id="form.profile_tax_type"
                        :cb_value_desc="form.profile_tax_typedesc"
                      >
                      </app-cb>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <vuetify-money
                        v-model           ="form.profile_vat_pct"
                        v-bind:options    ="formatrate"
                        label             ="VAT %"
                        placeholder       ="VAT %"
                        background-color  ="numeric"
                        v-bind:properties ="properties"
                      />
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <vuetify-money
                        v-model           ="form.profile_tax_pct"
                        v-bind:options    ="formatrate"
                        label             ="TAX %"
                        placeholder       ="TAX %"
                        background-color  ="numeric"
                        v-bind:properties ="properties"
                      />
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Tax ID*"
                        :maxlength="maxinput.taxid"
                        :counter="maxinput.taxid"
                        placeholder="Tax ID "
                        v-model="form.profile_tax_id"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <vuetify-money
                        v-model           ="form.profile_tax_loadingpct"
                        v-bind:options    ="formatrate"
                        label             ="TAX Loading %"
                        placeholder       ="TAX Loading %"
                        background-color  ="numeric"
                        v-bind:properties ="properties"
                      />
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Tax Name*"
                        placeholder="Tax Name"
                        v-model="form.profile_tax_name"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="Tax Address*"
                        placeholder="Tax Address"
                        v-model="form.profile_tax_address"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        label="Sponsor *"
                        placeholder="Sponsor"
                        v-model="form.profile_sponsorshippct"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-menu
                        ref="dialog_taxsholiday"
                        v-model="modal.taxsholiday"
                        transition="scale-transition"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="form.profile_tax_sholiday"
                            label="Start Holiday"
                            prepend-inner-icon="event"
                            background-color="date"
                            v-on="on"
                            v-mask="'####-##-##'"
                            placeholder="YYYY-MM-DD"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.profile_tax_sholiday"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal.taxsholiday = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.dialog_taxsholiday.save(
                                form.profile_tax_sholiday
                              )
                            "
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-menu
                        ref="dialog_taxeholiday"
                        v-model="modal.taxeholiday"
                        transition="scale-transition"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="form.profile_tax_eholiday"
                            label="End Holiday"
                            prepend-inner-icon="event"
                            background-color="date"
                            v-on="on"
                            v-mask="'####-##-##'"
                            placeholder="YYYY-MM-DD"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.profile_tax_eholiday"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal.taxeholiday = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.dialog_taxeholiday.save(
                                form.profile_tax_eholiday
                              )
                            "
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-tab-item> -->
                <v-tab-item value="tab-organization" v-show="form.profile_id">
                  <app-profile-organization :value="true" :id="form.profile_id"/>
                </v-tab-item>
                <v-tab-item value="tab-portfolio" v-show="form.profile_id">
                  <app-profile-porto :value="true" :id="form.profile_id"/>
                </v-tab-item>
                <v-tab-item value="tab-capital" v-show="form.profile_id">
                  <app-profile-capital :value="true" :id="form.profile_id"/>
                </v-tab-item>
                <v-tab-item value="tab-attachment" v-show="form.profile_id">
                  <app-attachment modul="profile" :refno="form.profile_id"/>
                </v-tab-item>

                <!-- <v-tab-item :eager ="true" value="tab-agency">
                  <v-row class="mx-2 ma-1">
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        label="Recruiter ID*"
                        :maxlength="maxinput.recruiterid"
                        :counter="maxinput.recruiterid"
                        placeholder="Recruiter ID"
                        v-model="form.profile_recruiter_id"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        label="Agency LIC*"
                        :maxlength="maxinput.agencyid"
                        :counter="maxinput.agencyid"
                        placeholder="Agency LIC"
                        v-model="form.profile_agency_lic_id"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-menu
                        ref="dialog_licdate"
                        v-model="modal.licdate"
                        transition="scale-transition"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="form.profile_agency_lic_date"
                            label="Agency Date"
                            prepend-inner-icon="event"
                            background-color="date"
                            v-on="on"
                            v-mask="'####-##-##'"
                            placeholder="YYYY-MM-DD"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.profile_agency_lic_date"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal.licdate = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.dialog_licdate.save(
                                form.profile_agency_lic_date
                              )
                            "
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-menu
                        ref="dialog_licexpdate"
                        v-model="modal.licexpdate"
                        transition="scale-transition"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="form.profile_agency_lic_exp_date"
                            label="Agency Exp"
                            prepend-inner-icon="event"
                            background-color="date"
                            v-on="on"
                            v-mask="'####-##-##'"
                            placeholder="YYYY-MM-DD"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.profile_agency_lic_exp_date"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal.licexpdate = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.dialog_licexpdate.save(
                                form.profile_agency_lic_exp_date
                              )
                            "
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        label="Agency Level*"
                        placeholder="Agency Level"
                        v-model="form.profile_agency_level"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mt-n5">
                      <label class="caption">Ujroh</label>
                      <v-switch
                        v-model="form.profile_ujroh_flag"
                        color="primary"
                        id="form.profile_ujroh_flag"
                        :label="form.profile_ujroh_flag !== true ? '' : 'Yes'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-tab-item> -->

                <v-tab-item value="tab-account">
                  <template>
                    <v-card-title class="ml-n4">
                      <v-btn
                        :color="
                          profile_account.selected.length ===
                          profile_account.items.length
                            ? 'fourth'
                            : ''
                        "
                        @click="SelectAllProfileAccount"
                        class="mr-2"
                        title="select all"
                        small
                        >select all
                      </v-btn>
                      <v-btn
                        class="mr-2"
                        @click="OpenProfileAccount"
                        title="add"
                        small
                        >Add
                      </v-btn>
                      <v-btn
                        color="error"
                        title="delete selected"
                        class="mr-2"
                        v-if="profile_account.selected.length > 0"
                        :disabled="access.delete === 0 ? true : false"
                        small
                      >
                        <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        title="search"
                        v-if="profile_account.searchbutton === false"
                        @click="SearchProfileAccount"
                        small
                      >
                        <v-icon>{{$vars.V('icon_search')}}</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="profile_account.search"
                        append-icon="search"
                        @click:append="profile_account.searchbutton = false"
                        label="Type and Enter"
                        single-line
                        hide-details
                        v-if="profile_account.searchbutton"
                        id="searchaccount"
                        clearable
                      ></v-text-field>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-data-table
                      :value="profile_account.selected"
                      :headers="profile_account.headers"
                      :items="profile_account.items"
                      :search="profile_account.search"
                      @input="CheckProfileAccount($event)"
                      item-key="profileaccount_id"
                      show-select
                      class="elevation-1"
                       :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'first_page',
                        lastIcon: 'last_page',
                        prevIcon: 'keyboard_arrow_left',
                        nextIcon: 'keyboard_arrow_right',
                      }"
                      dense
                      :loading="loading.profileaccount"
                      loading-text="Loading... Please wait"
                    >
                      <template v-slot:item.action="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="
                            EditProfileAccount(
                              item.profileaccount_id,
                              item.profileaccount_profileid
                            )
                          "
                          :disabled="access.edit === 0 ? true : false"
                          title="edit"
                        >
                          edit
                        </v-icon>
                        <v-icon
                          small
                          @click="
                            Confirm(
                              'deleteprofileaccount',
                              item.profileaccount_id,
                              item.profileaccount_profileid
                            )
                          "
                          :disabled="access.delete === 0 ? true : false"
                          title="delete"
                        >
                          delete
                        </v-icon>
                      </template>
                      <template v-slot:item.profileaccount_bank="{ item }">
                        {{ item.profileaccount_bank }} -
                        {{ item.profileaccount_bankdesc }}
                      </template>
                    </v-data-table>
                  </template>
                </v-tab-item>
              </v-tabs>
            </v-form>
          </v-tab-item>

          <v-tab-item value="tab-list">
            <template>
              <v-card-title class="ml-n4">
                <v-btn
                  :color="selected.length === items.length ? 'secondary' : ''"
                  @click="SelectAllPage"
                  class="mr-2"
                  title="select all page"
                  small
                  >{{$vars.V('txt_select_all_page')}}
                </v-btn>
                <v-btn
                  color="error"
                  title="delete selected"
                  @click="Confirm('multidelete', '')"
                  class="mr-2"
                  v-if="selected.length > 0"
                  :disabled="access.delete === 0 ? true : false"
                  small
                >
                  <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  title="search"
                  v-if="btn_search === false"
                  @click="OpenSearch"
                  small
                >
                  <v-icon>{{$vars.V('icon_search')}}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <!-- <v-text-field
				        v-model="search"
				        append-icon="search"
				        @click:append="btn_search = false"
				        label="Search"
				        single-line
				        hide-details
				        v-if ="btn_search"
				      	></v-text-field> -->
              </v-card-title>
              <v-data-table
                :value="selected"
                :headers="headers"
                :items="items"
                :search="search"
                @input="Check($event)"
                item-key="profile_id"
                show-select
                class="elevation-1"
                 :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'first_page',
                  lastIcon: 'last_page',
                  prevIcon: 'keyboard_arrow_left',
                  nextIcon: 'keyboard_arrow_right',
                }"
                dense
                :loading="loading.list"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.profile_name="{ item }">
                    {{item.profile_salutation}} {{item.profile_name}}
                </template>
                <template v-slot:item.profile_pic_name="{ item }">
                    {{item.profile_pic_name}}<br>{{item.profile_pic_email}}
                </template>
                <template v-slot:item.profile_cdate="{ item }">
                    {{$functions.FormatDateTime(item.profile_cdate)}}<br>{{item.profile_cuser}}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="Get(item.profile_id)"
                    title="Get"
                    v-if="value"
                  >
                    get_app
                  </v-icon>
                  <v-icon
                    small
                    class="mr-2"
                    @click="Edit(item.profile_id)"
                    :disabled="access.edit === 0 ? true : false"
                    title="edit"
                  >
                    edit
                  </v-icon>
                  <v-icon
                    small
                    @click="Confirm('delete', item.profile_id)"
                    :disabled="access.delete === 0 ? true : false"
                    title="delete"
                  >
                    delete
                  </v-icon>
                </template>
              </v-data-table>
            </template>
          </v-tab-item>
        </v-tabs>
        <v-dialog
          v-model="remarks.dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          persistent
          v-if="remarks.feedback !== ''"
        >
          <v-card>
            <v-toolbar dark color="fourth" dense>
              <v-btn icon dark @click="remarks.dialog = false">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Error Messages</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-2 ml-n2">
              <div
                class="subheading font-weight-bold"
                v-html="remarks.feedback"
              ></div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Dialog Write Profileaccount -->
        <v-dialog
          v-model="dialog.profileaccount"
          scrollable
          transition="dialog-bottom-transition"
          persistent
          max-width="700"
        >
          <v-card>
            <v-toolbar dark color="primary" dense class="mb-2">
              <v-btn icon dark @click="Close('profileaccount')">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Profile Account</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="AddProfileAccount" title="Add New"
                ><v-icon color="white">add</v-icon></v-btn
              >
              <v-btn
                icon
                dark
                @click="Confirm(url_type, '')"
                title="Save Account"
                ><v-icon color="white">save</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-progress-linear
                :indeterminate="true"
                v-if="loading.profileaccountdialog"
              ></v-progress-linear>
              <v-form
                enctype="multipart/form-data"
                ref="form_profileaccount"
                lazy-validation
              >
                <v-row class="mx-2 mt-2">
                  <v-col cols="12" sm="12" md="12" class="mt-n3">
                    <v-text-field
                      v-model="form.profileaccount_id"
                      required
                      label="ID"
                      placeholder="ID"
                      :rules="form.profileaccount_id_rules"
                      :maxlength="maxinput.accountid"
                      :counter="maxinput.accountid"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <app-cb
                      cb_type="currency"
                      cb_url="apiListCurrency"
                      cb_url_parameter="currency_actived=Y"
                      cb_title="Currency"
                      cb_id="profileaccount_currency"
                      cb_desc="profileaccount_currencydesc"
                      cb_rules="Please fill Currency"
                      cb_desc_readonly="readonly"
                      cb_items_id="currency_id"
                      cb_items_desc="currency_desc"
                      :cb_value_id="form.profileaccount_currency"
                      :cb_value_desc="form.profileaccount_currencydesc"
                    >
                    </app-cb>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <app-cb
                      cb_type="bank"
                      cb_url="apiListBank"
                      cb_url_parameter="bank_actived=Y"
                      cb_title="Bank"
                      cb_id="profileaccount_bank"
                      cb_desc="profileaccount_bankdesc"
                      cb_items_id="bank_id"
                      cb_items_desc="bank_desc"
                      cb_rules="Please fill Bank"
                      :cb_value_id="form.profileaccount_bank"
                      :cb_value_desc="form.profileaccount_bankdesc"
                    >
                    </app-cb>
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <v-text-field
                      v-model="form.profileaccount_accountno"
                      required
                      :rules="form.profileaccount_accountno_rules"
                      label="No"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <v-text-field
                      v-model="form.profileaccount_accountname"
                      :rules="form.profileaccount_accountname_rules"
                      required
                      label="Name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="mt-n3">
                    <v-text-field
                      v-model="form.profileaccount_bankbranch"
                      label="Branch"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mx-2 mt-2">
                  <v-col cols="12" sm="4" md="4" class="mt-n3">
                    <label class="caption">Active</label>
                    <v-switch
                      v-model="form.profileaccount_actived"
                      color="primary"
                      id="form.profileaccount_actived"
                      :label="
                        form.profileaccount_actived !== true ? '' : 'Active'
                      "
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- end: Dialog Write Profile Account -->

        <!-- Advanced Search -->
        <v-dialog
          v-model="dialog.search"
          max-width="490"
          transition="dialog-bottom-transition"
          persistent
        >
          <v-card>
            <v-toolbar dark color="primary" dense>
              <v-btn icon dark @click="dialog.search = false">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Advanced Search</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="Reset">
                <v-icon>clear_all</v-icon>
              </v-btn>
              <v-btn icon dark @click="List">
                <v-icon>{{$vars.V('icon_search')}}</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <template>
                <v-row>
                  <!-- ID -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="find.id"
                      label="ID"
                      id="find_id"
                      @keyup.enter="Search"
                    ></v-text-field>
                  </v-col>
                  <!-- Name -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="find.name"
                      label="Name"
                      @keyup.enter="Search"
                    ></v-text-field>
                  </v-col>
                  <!-- Personal ID -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="find.idno"
                      label="Personal ID No"
                      @keyup.enter="Search"
                    ></v-text-field>
                  </v-col>
                  <!-- Personal ID -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="find.npwp"
                      label="NPWP No"
                      @keyup.enter="Search"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="mt-n3">
                  <app-cb
                    cb_type="profilelob"
                    cb_url="apiListProfileLOB"
                    cb_url_parameter="profilelob_actived=Y"
                    cb_title="Profile Line of Business"
                    cb_id="find_profile_lob"
                    cb_desc="find_profile_lob_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="profilelob_id"
                    cb_items_desc="profilelob_desc"
                    :cb_value_id="find.lob"
                    :cb_value_desc="find.lobdesc"
                    @clicked="GetFindLob"
                  >
                  </app-cb>
                </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Advanced Search -->
        <v-dialog
          v-model="confirm.dialog"
          :max-width="Variable('confirm', 0)"
          persistent
        >
          <v-card :color="Variable('confirm', 4)">
            <v-card-title :class="Variable('confirm', 3)"
              >{{ confirm.title }}?</v-card-title
            >
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                id="dialog"
                :color="Variable('confirm', 1)"
                :disabled="confirm.text === 'Ok' ? false : true"
                @click="Loading"
                >{{ confirm.text }}</v-btn
              >
              <v-btn
                :color="Variable('confirm', 2)"
                @click="confirm.dialog = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog Users -->
        <app-users
          :visible="dialog.users"
          :users_id="form.display_users"
          @close="dialog.users = false"
        ></app-users>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    id: String,
    url_param: String,
    action: String,
  },
  data: () => ({
    url_type: "add",
    cont: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 10,
      extid: 10,
      refno: 16,
      nophone: 30,
      zipcode: 10,
      rating: 10,
      city: 20,
      refid: 15,
      cgroup: 10,
      scgroup: 10,
      desc: 150,
      accountid: 20,
      taxid: 50,
      recruiterid: 8,
      agencyid: 30,
      jobclass: 10,
      purpose: 10,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        value: "profile_id",
      },
      { text: "Group", value: "profile_cgroup_desc" },
      { text: "Name", value: "profile_name" },
      { text: "LOB", value: "profile_lob_desc" },
      { text: "PIC", value: "profile_pic_name" },
      { text: "Created", value: "profile_cdate" },
      { text: "Actived", value: "profile_actived" },
      { text: "Actions", value: "action", sortable: false },
    ],
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    accessmenu: [],
    loading: {
      page: false,
      list: false,
      subclass: false,
      profileaccount: false,
      profileaccountdialog: false,
    },
    btn_search: false,
    modal: {
      birthdate: false,
      taxsholiday: false,
      taxeholiday: false,
      licdate: false,
      licexpdate: false,
      chexpdate: false,
    },

    form: {
      profile_avatar_url: "",
      profile_avatar: "",
      profile_id: "",
      profile_id_rules: [(v) => !!v || "Please fill ID"],
      profile_id_readonly: true,
      profile_refid: "",
      profile_extid: "",
      profile_type: "",
      profile_type_desc: "",
      profile_lob: "",
      profile_lob_desc: "",
      profile_salutation: "PT",
      profile_salutationdesc: "",
      profile_branch: "01",
      profile_branchdesc: "KANTOR PUSAT",
      profile_corporate: true,
      profile_bizsourcef: false,
      profile_name: "",
      profile_name_rules: [(v) => !!v || "Please fill Name"],
      profile_alias: "",
      profile_ref_type: "",
      profile_ref_no: "",
      profile_tax_loadingpct: 0,

      //general
      profile_birth_place: "",
      profile_birth_date: "",
      profile_gender: "",
      profile_marital_status: "",
      profile_religion: "",
      profile_education: "",
      profile_mothers_name: "",
      profile_height: "",
      profile_weight: "",

      //addresses
      profile_city: "",
      profile_city_rules: [(v) => !!v || "Please fill City"],
      profile_latitude: "",
      profile_longitude: "",
      profile_address: "",
      profile_address_rules: [(v) => !!v || "Please fill Address"],
      profile_zipcode: "",

      //phone number
      profile_phone_1: "",
      profile_phone_1_rules: [(v) => !!v || "Please fill Phone"],
      profile_phone_2: "",
      profile_mobile_1: "",
      profile_mobile_1_wa: "",
      profile_mobile_2: "",
      profile_mobile_2_wa: "",
      //end
      //company info
      profile_cgroup: "NA01",
      profile_cgroup_desc: "NOT AVAILABLE",
      profile_scgroup: "",
      profile_pic_name: "",
      profile_pic_name_rules: [(v) => !!v || "Please fill PIC Name"],
      profile_pic_gender: "",
      profile_pic_gender_rules: [(v) => !!v || "Please fill PIC Gender"],
      profile_pic_title: "",
      profile_pic_title_rules: [(v) => !!v || "Please fill PIC Title"],
      profile_pic_phone: "",
      profile_pic_phone_rules: [(v) => !!v || "Please fill PIC Phone"],
      profile_pic_email: "",
      profile_pic_email_rules: [(v) => !!v || "Please fill PIC Email"],
      profile_pic_address: "",
      profile_pic_zipcode: "",

      //end
      //work info
      profile_empstatus: "",
      profile_emptitle: "",
      profile_salary: 0,
      profile_purpose: "",
      profile_compname: "",
      profile_jobdesc: "",
      profile_jobclass: "",
      profile_rangesalary: "",
      profile_sourceincome: "",
      //end
      //taxation
      profile_tax_id: "",
      profile_tax_name: "",
      profile_tax_address: "",
      profile_tax_type: "",
      profile_tax_typedesc: "",
      profile_tax_sholiday: "",
      profile_tax_eholiday: "",
      profile_vat_pct: 0,
      profile_tax_pct: 0,
      profile_sponsorshippct: "",
      //end

      //agency
      profile_recruiter_id: "",
      profile_agency_lic_id: "",
      profile_agency_lic_date: "",
      profile_agency_lic_exp_date: "",
      profile_agency_level: "",
      profile_ujroh_flag: "",
      //end
      profile_fax_1: "",
      profile_email_1: "",
      profile_paypos_no: "",
      profile_address_2: "",
      profile_address_3: "",
      profile_remarks: "",
      profile_rating: "",
      profile_province: "",
      profile_province_desc: "",
      profile_regency: "",
      profile_regency_desc: "",
      profile_districts: "",
      profile_districts_desc: "",
      profile_villages: "",
      profile_villages_desc: "",
      profile_actived: true,
      display_users: "",

      //profile account
      profileaccount_id: "",
      profileaccount_id_rules: [(v) => !!v || "Please fill ID"],
      profileaccount_profileid: "",
      profileaccount_profileidpar: "",
      profileaccount_currency: "",
      profileaccount_currencydesc: "",

      profileaccount_bank: "",
      profileaccount_bankdesc: "",
      profileaccount_accountno: "",
      profileaccount_accountno_rules: [(v) => !!v || "Please fill No Account"],
      profileaccount_accountname: "",
      profileaccount_accountname_rules: [
        (v) => !!v || "Please fill Account Name",
      ],
      profileaccount_bankbranch: "",
      profileaccount_actived: true,
      profileaccount_cdate: "",
      profileaccount_cuser: "",
      profileaccount_ldate: "",
      profileaccount_luser: "",
      profile_refidimages: "",
      profile_refidimages_url: "",
      profile_branchunit: "",
      profile_branchunit_rules: [(v) => !!v || "Please fill Branch Unit Name"],
      profile_branchunitf: false
    },
    format_pct: {
      vat_pct: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      tax_pct: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
    },
    dialog: {
      users: false,
      search: false,
      profileaccount: false,
    },
    tabprofile: "tab-list",
    find: {
      id: "",
      name: "",
      idno: "",
      npwp: "",
      lob: "",
      lobdesc: "",
    },
    addon: {
      lob: "",
      lobdesc: "",
    },
    rows: {
      gender: [
        { text: "", value: "" },
        { text: "Male", value: "M" },
        { text: "Female", value: "F" },
      ],
      reftype: [
        { text: "", value: "" },
        { text: "SIUP", value: "SIUP" },
        { text: "KTP", value: "KTP" },
        { text: "SIM", value: "SIM" },
      ],
      nationality: [
        { text: "WNI", value: "WNI" },
        { text: "WNA", value: "WNA" },
      ],
      empstatus: [
        { text: "", value: "" },
        { text: "APPRENTICE", value: "APPRENTICE" },
        { text: "CONTRACT", value: "CONTRACT" },
        { text: "PERMANENT", value: "PERMANENT" },
      ],
      marital: [
        { text: "", value: "" },
        { text: "SINGLE", value: "SINGLE" },
        { text: "MARRIED", value: "MARRIED" },
      ],
      education: [
        { text: "", value: "" },
        { text: "SD", value: "SD" },
        { text: "SLTP", value: "SLTP" },
        { text: "SLTA", value: "SLTA" },
        { text: "D1", value: "D1" },
        { text: "D2", value: "D2" },
        { text: "D3", value: "D3" },
        { text: "D4", value: "D4" },
        { text: "S1", value: "S1" },
        { text: "S2", value: "S2" },
        { text: "S3", value: "S3" },
      ],
      religion: [
        { text: "", value: "" },
        { text: "ISLAM", value: "ISLAM" },
        { text: "PROTESTAN", value: "PROTESTAN" },
        { text: "KATOLIK", value: "KATOLIK" },
        { text: "HINDU", value: "HINDU" },
        { text: "BUDHA", value: "BUDHA" },
      ],
      category: [
        { text: "", value: "" },
        { text: "WIFE", value: "WIFE" },
        { text: "HUSBAND", value: "HUSBAND" },
        { text: "SON", value: "SON" },
        { text: "DAUGHTER", value: "DAUGHTER" },
      ],
      bloodtype: [
        { text: "", value: "" },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "AB", value: "AB" },
        { text: "O", value: "O" },
      ],
      rating: [
        { text: "", value: "" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
      ],
      autocc: [],
      search: null,
    },
    // Data Table Profile Account
    profile_account: {
      headers: [
        {
          text: "ID",
          align: "left",
          value: "profileaccount_id",
        },
        { text: "Profile", value: "profileaccount_profileid" },
        { text: "Currency", value: "profileaccount_currency" },
        { text: "Bank", value: "profileaccount_bank" },
        { text: "Branch", value: "profileaccount_bankbranch" },
        { text: "No", value: "profileaccount_accountno" },
        { text: "Name", value: "profileaccount_accountname" },
        { text: "Action", value: "action" },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    //Format Number
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 4
    },
    formatint: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 0
    },
    properties: {
      reverse: true
    },
    flag: {
      profileaccount: false,
    },
    tabs: null
    // end: Data Table Profile Account
  }),
  watch: {
    id: function (newdata) {
      if (newdata !== "") {
        this.find.id = newdata
        this.ListData()
      } else {
        this.find.id = ""
      }
    },
    dialogprofile: function (newdata) {
      if (newdata === true) {
        this.FirstLoad()
      }
    }
  },
  created() {
    if (this.value === true) {
      this.FirstLoad()
    }
  },
  computed: {
    dialogprofile: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access()
      this.ListData()
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      this.loading.page = true
      let modul = "profile"
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read
            this.access.add = priv[0].usersmenu_add
            this.access.edit = priv[0].usersmenu_edit
            this.access.delete = priv[0].usersmenu_delete
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              )
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            )
          }
          this.loading.page = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0)
          this.loading.page = false
        })
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items
    },
    Check(value) {
      this.selected = value
    },
    ListData () {
      if (this.action === 'add') {
        this.tabprofile = "tab-form"
      } else {
        this.List()
      }
    },
    //Function for get all data from table Profile with API in mod_users.go
    List() {
      this.loading.list = true
      let formdata = {
          profile_id: this.find.id,
          profile_name: this.find.name,
          profile_ref_no: this.find.idno,
          profile_tax_id: this.find.npwp,
          profile_lob: this.find.lob,
          order_by: "profile_id",
          order_type: "ASC",
          limit: this.limit,
      }
      let mainsearch = this.find.id+ "" +this.find.name+ "" +this.find.idno+ "" +this.find.npwp+ "" +this.find.lob
      if (mainsearch === "") {
        let parameter = this.url_param;
        if (parameter !== undefined && parameter !== "") {
          parameter = parameter+"&profile_id="+this.find.id
          let newparameter = JSON.parse(
            '{"' +
              decodeURI(parameter)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
              '"}'
          );
          if(newparameter.profile_lob !== undefined) {
            this.addon.lob = newparameter.profile_lob
            this.find.lob = newparameter.profile_lob
            this.find.lobdesc = newparameter.profile_lob_desc
            this.form.profile_lob = newparameter.profile_lob
            this.form.profile_lob_desc = newparameter.profile_lob_desc
            this.CheckLOB(this.form.profile_lob)
          }
          formdata = Object.assign(formdata, newparameter);
        }
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListProfile"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0)
            } else {
              this.items = feedback
            }
          } else {
            this.items = feedback
            // this.SnackBar(true, "error", feedback, 0)
          }
          this.loading.list = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000)
          this.loading.list = false
        })
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id, key1) {
      if (flag === "write") {
        if (this.$refs.form_profile.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        } 
        // else {
          // var profile_province = document.getElementById("profile_province").value
          // var profile_regency = document.getElementById("profile_regency").value
          // var profile_districts = document.getElementById("profile_districts").value
          // var profile_villages = document.getElementById("profile_villages").value
          // var profile_city = document.getElementById("profile_city").value
          // var profile_address = document.getElementById("profile_address").value
          // var profile_cgroup = document.getElementById("profile_cgroup").value
          // if (profile_province === '' || profile_regency === '' || profile_districts === '' || profile_villages === '' || profile_city === '' || profile_address === '' || this.form.profile_phone_1 === '') {
          //     this.tabs = 'tab-general'
          //     document.getElementById("tabgeneral").scrollIntoView()
          // } else if (profile_cgroup === '' || this.form.profile_pic_name === '' || this.form.profile_pic_phone === '' || this.form.profile_pic_email === '') {
          //     this.tabs = 'tab-company'
          //     document.getElementById("tabcompany").scrollIntoView()
          // }
        // }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.profile_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (
        flag === "addprofileaccount" ||
        flag === "editprofileaccount"
      ) {
        if (this.$refs.form_profileaccount.validate()) {
          this.url_type = this.url_type;
          this.confirm.dialog = true;
          this.confirm.title = "Save Account";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "deleteprofileaccount") {
        this.url_type = "deleteprofileaccount";
        this.confirm.dialog = true;
        this.confirm.title = "Delete Account`" + id + "`";
        this.form.profileaccount_id = id;
        this.form.profileaccount_profileid = key1;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },

    //Function to request insert data to table Profile 'The API will validating your data, include the user who process the data' (go to APIWriteProfile with url_type = "add")
    Add() {
      this.url_type = "add";
      this.form.profile_id = "";
      this.form.profile_name = "";
      this.form.profile_actived = "";
      this.form.profile_id_readonly = true;
      setTimeout(function () {
        document.getElementById("profile_lob").focus();
      }, 500);
    },
    //Function to request update data to table Profile 'The API will validating your data, include the user who process the data' (go to APIWriteProfile with url_type = "edit")
    Edit(id) {
      this.loading.list = true;
      this.ListProfileAccount(id);
      let formdata = {
        profile_id: id,
        profile_name: "",
        order_by: "profile_id",
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListProfile"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.tabprofile = "tab-form";
              this.url_type = "edit";
              this.form.profile_avatar = feedback[0].profile_avatar;
              this.form.profile_avatar_url = feedback[0].profile_avatar_url;
              this.form.profile_refidimages = feedback[0].profile_refidimages;
              this.form.profile_refidimages_url = feedback[0].profile_refidimages_url;
              this.form.profile_id = feedback[0].profile_id;
              this.form.profile_name = feedback[0].profile_name;
              this.form.profile_actived = this.$functions.TrueOrFalse(
                feedback[0].profile_actived
              );
              this.form.profile_cdate = feedback[0].profile_cdate;
              this.form.profile_cuser = feedback[0].profile_cuser;
              this.form.profile_ldate = feedback[0].profile_ldate;
              this.form.profile_luser = feedback[0].profile_luser;
              this.form.profile_id_readonly = true;
              this.form.profile_refid = true;
              this.form.profile_refid = feedback[0].profile_refid;
              this.form.profile_extid = feedback[0].profile_extid;
              this.form.profile_salutation = feedback[0].profile_salutation;
              this.form.profile_branch = feedback[0].profile_branch;
              this.form.profile_branchdesc = feedback[0].profile_branchdesc;
              this.form.profile_corporate = this.$functions.TrueOrFalse(
                feedback[0].profile_corporate
              );
              this.form.profile_bizsourcef = this.$functions.TrueOrFalse(
                feedback[0].profile_bizsourcef
              );
              this.form.profile_name = feedback[0].profile_name;
              this.form.profile_alias = feedback[0].profile_alias;
              this.form.profile_ref_type = feedback[0].profile_ref_type;
              this.form.profile_pic_name = feedback[0].profile_pic_name;
              this.form.profile_pic_phone = feedback[0].profile_pic_phone;
              this.form.profile_pic_email = feedback[0].profile_pic_email;
              this.form.profile_pic_address = feedback[0].profile_pic_address;
              this.form.profile_pic_zipcode = feedback[0].profile_pic_zipcode;
              this.form.profile_birth_place = feedback[0].profile_birth_place;
              this.form.profile_birth_date = feedback[0].profile_birth_date;
              this.form.profile_gender = feedback[0].profile_gender;
              this.form.profile_address = feedback[0].profile_address;
              this.form.profile_city = feedback[0].profile_city;
              this.form.profile_zipcode = feedback[0].profile_zipcode;
              this.form.profile_phone_1 = feedback[0].profile_phone_1;
              this.form.profile_phone_2 = feedback[0].profile_phone_2;
              this.form.profile_mobile_1 = feedback[0].profile_mobile_1;
              this.form.profile_mobile_1_wa = this.$functions.TrueOrFalse(
                feedback[0].profile_mobile_1_wa
              );
              this.form.profile_mobile_2 = feedback[0].profile_mobile_2;
              this.form.profile_mobile_2_wa = this.$functions.TrueOrFalse(
                feedback[0].profile_mobile_2_wa
              );
              this.form.profile_fax_1 = feedback[0].profile_fax_1;
              this.form.profile_email_1 = feedback[0].profile_email_1;
              this.form.profile_tax_id = feedback[0].profile_tax_id;
              this.form.profile_tax_name = feedback[0].profile_tax_name;
              this.form.profile_tax_address = feedback[0].profile_tax_address;
              this.form.profile_tax_type = feedback[0].profile_tax_type;
              this.form.profile_tax_sholiday = feedback[0].profile_tax_sholiday;
              this.form.profile_tax_eholiday = feedback[0].profile_tax_eholiday;
              this.form.profile_vat_pct = feedback[0].profile_vat_pct;
              this.form.profile_tax_pct = feedback[0].profile_tax_pct;
              this.form.profile_recruiter_id = feedback[0].profile_recruiter_id;
              this.form.profile_agency_lic_id =
                feedback[0].profile_agency_lic_id;
              this.form.profile_agency_lic_date =
                feedback[0].profile_agency_lic_date;
              this.form.profile_agency_lic_exp_date =
                feedback[0].profile_agency_lic_exp_date;
              this.form.profile_agency_level = feedback[0].profile_agency_level;
              this.form.profile_ujroh_flag = this.$functions.TrueOrFalse(
                feedback[0].profile_ujroh_flag
              );
              this.form.profile_type = feedback[0].profile_type;
              this.form.profile_type_desc = feedback[0].profile_type_desc;
              this.form.profile_ref_no = feedback[0].profile_ref_no;
              this.form.profile_cgroup = feedback[0].profile_cgroup;
              this.form.profile_cgroup_desc = feedback[0].profile_cgroup_desc;
              this.form.profile_scgroup = feedback[0].profile_scgroup;
              this.form.profile_marital_status =
                feedback[0].profile_marital_status;
              this.form.profile_religion = feedback[0].profile_religion;
              this.form.profile_education = feedback[0].profile_education;
              this.form.profile_mothers_name = feedback[0].profile_mothers_name;
              this.form.profile_height = feedback[0].profile_height;
              this.form.profile_weight = feedback[0].profile_weight;
              this.form.profile_paypos_no = feedback[0].profile_paypos_no;
              this.form.profile_address_2 = feedback[0].profile_address_2;
              this.form.profile_address_3 = feedback[0].profile_address_3;
              this.form.profile_rangesalary = this.$functions.TrueOrFalse(
                feedback[0].profile_rangesalary
              );
              this.form.profile_sourceincome = feedback[0].profile_sourceincome;
              this.form.profile_remarks = feedback[0].profile_remarks;
              this.form.profile_empstatus = feedback[0].profile_empstatus;
              this.form.profile_emptitle = feedback[0].profile_emptitle;
              this.form.profile_salary = feedback[0].profile_salary;
              this.form.profile_purpose = feedback[0].profile_purpose;
              this.form.profile_compname = feedback[0].profile_compname;
              this.form.profile_jobdesc = feedback[0].profile_jobdesc;
              this.form.profile_jobclass = feedback[0].profile_jobclass;
              this.form.profile_rating = feedback[0].profile_rating;
              this.form.profile_latitude = feedback[0].profile_latitude;
              this.form.profile_longitude = feedback[0].profile_longitude;
              this.form.profile_tax_loadingpct = feedback[0].profile_tax_loadingpct;
              this.form.profile_sponsorshippct = feedback[0].profile_sponsorshippct;
              this.form.profile_lob = feedback[0].profile_lob;
              this.form.profile_lob_desc = feedback[0].profile_lob_desc;
              this.form.profile_province = feedback[0].profile_province;
              this.form.profile_province_desc = feedback[0].profile_province_desc;
              this.form.profile_regency = feedback[0].profile_regency;
              this.form.profile_regency_desc = feedback[0].profile_regency_desc;
              this.form.profile_districts = feedback[0].profile_districts;
              this.form.profile_districts_desc = feedback[0].profile_districts_desc;
              this.form.profile_villages = feedback[0].profile_villages;
              this.form.profile_villages_desc = feedback[0].profile_villages_desc;
              this.form.profile_branchunit = feedback[0].profile_branchunit
              this.CheckLOB(this.form.profile_lob)
              setTimeout(function () {
                document.getElementById("profile_name").focus();
              }, 500);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
          this.loading.list = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
          this.loading.list = false
        })
    },
    Upload() {
      this.$refs.profile_avatar.click();
    },

    UploadAvatar() {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.form.profile_avatar_url = e.target.result;
          this.form.profile_avatar = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    Delete(id) {
      let formdata = {
        url_type: "delete",
        profile_id: id,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfile"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000)
              this.confirm.dialog = false
              this.confirm.text = "Ok"
              this.selected = []
              this.List()
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected)
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfile"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback[0].feedback === "Y") {
            let feed
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
              this.remarks.dialog = true
            }
            this.SnackBar(true, "success", feed, 0)
            this.confirm.dialog = false
            this.confirm.text = "Ok"
            this.selected = []
            this.List()
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    Loading() {
      this.confirm.text = "Loading..."
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write()
      } else if (this.url_type === "delete") {
        this.Delete(this.profile_id)
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      } else if (
        this.url_type === "addprofileaccount" ||
        this.url_type === "editprofileaccount"
      ) {
        this.WriteProfileAccount();
      } else if (this.url_type === "deleteprofileaccount") {
        this.DeleteProfileAccount(
          this.form.profileaccount_id,
          this.form.profileaccount_profileid
        );
      }
    },
    Write() {
      let formdata = {
        url_type: this.url_type,
        profile_id: this.form.profile_id,
        profile_refid: this.form.profile_refid,
        profile_extid: this.form.profile_extid,
        profile_salutation: this.form.profile_salutation,
        profile_branch: this.form.profile_branch,
        profile_corporate: this.$functions.ActivedFlag(
          this.form.profile_corporate
        ),
        profile_bizsourcef: this.$functions.ActivedFlag(
          this.form.profile_bizsourcef
        ),
        profile_name: this.form.profile_name,
        profile_alias: this.form.profile_alias,
        profile_ref_type: this.form.profile_ref_type,
        profile_ref_no: this.form.profile_ref_no,
        profile_pic_name: this.form.profile_pic_name,
        profile_pic_title: this.form.profile_pic_title,
        profile_pic_phone: this.form.profile_pic_phone,
        profile_pic_email: this.form.profile_pic_email,
        profile_pic_address: this.form.profile_pic_address,
        profile_pic_zipcode: this.form.profile_pic_zipcode,
        profile_pic_gender: this.form.profile_pic_gender,
        profile_birth_place: this.form.profile_birth_place,
        profile_birth_date: this.form.profile_birth_date,
        profile_gender: this.form.profile_gender,
        profile_address: this.form.profile_address,
        profile_city: this.form.profile_city,
        profile_zipcode: this.form.profile_zipcode,
        profile_phone_1: this.form.profile_phone_1,
        profile_phone_2: this.form.profile_phone_2,
        profile_tax_loadingpct: String(this.form.profile_tax_loadingpct),
        profile_mobile_1: this.form.profile_mobile_1,
        profile_mobile_1_wa: this.$functions.ActivedFlag(
          this.form.profile_mobile_1_wa
        ),
        profile_mobile_2: this.form.profile_mobile_2,
        profile_mobile_2_wa: this.$functions.ActivedFlag(
          this.form.profile_mobile_2_wa
        ),
        profile_fax_1: this.form.profile_fax_1,
        profile_email_1: this.form.profile_email_1,
        profile_tax_id: this.form.profile_tax_id,
        profile_tax_name: this.form.profile_tax_name,
        profile_tax_address: this.form.profile_tax_address,
        profile_tax_type: this.form.profile_tax_type,
        profile_tax_sholiday: this.form.profile_tax_sholiday,
        profile_tax_eholiday: this.form.profile_tax_eholiday,
        profile_vat_pct: String(this.form.profile_vat_pct),
        profile_tax_pct: String(this.form.profile_tax_pct),
        profile_recruiter_id: this.form.profile_recruiter_id,
        profile_agency_lic_id: this.form.profile_agency_lic_id,
        profile_agency_lic_date: this.form.profile_agency_lic_date,
        profile_agency_lic_exp_date: this.form.profile_agency_lic_exp_date,
        profile_agency_level: this.form.profile_agency_level,
        profile_ujroh_flag: this.$functions.ActivedFlag(
          this.form.profile_ujroh_flag
        ),
        profile_type: this.form.profile_type,
        profile_cgroup: this.form.profile_cgroup,
        profile_scgroup: this.form.profile_scgroup,
        profile_marital_status: this.form.profile_marital_status,
        profile_religion: this.form.profile_religion,
        profile_education: this.form.profile_education,
        profile_mothers_name: this.form.profile_mothers_name,
        profile_height: this.form.profile_height,
        profile_weight: this.form.profile_weight,
        profile_paypos_no: this.form.profile_paypos_no,
        profile_address_2: this.form.profile_address_2,
        profile_address_3: this.form.profile_address_3,
        profile_rangesalary: this.$functions.ActivedFlag(
          this.form.profile_rangesalary
        ),
        profile_avatar: this.form.profile_avatar,
        profile_avatar_url: this.form.profile_avatar_url.replace(
          /^.+?;base64,/,
          ""
        ),
        profile_sourceincome: this.form.profile_sourceincome,
        profile_remarks: this.form.profile_remarks,
        profile_empstatus: this.form.profile_empstatus,
        profile_emptitle: this.form.profile_emptitle,
        profile_salary: String(this.form.profile_salary),
        profile_purpose: this.form.profile_purpose,
        profile_compname: this.form.profile_compname,
        profile_jobdesc: this.form.profile_jobdesc,
        profile_jobclass: this.form.profile_jobclass,
        profile_rating: this.form.profile_rating,
        profile_latitude: this.form.profile_latitude,
        profile_longitude: this.form.profile_longitude,
        profile_sponsorshippct: String(this.form.profile_sponsorshippct),
        profile_lob: this.form.profile_lob,
        profile_province: this.form.profile_province,
        profile_province_desc: this.form.profile_province_desc,
        profile_regency: this.form.profile_regency,
        profile_regency_desc: this.form.profile_regency_desc,
        profile_districts: this.form.profile_districts,
        profile_districts_desc: this.form.profile_districts_desc,
        profile_villages: this.form.profile_villages,
        profile_villages_desc: this.form.profile_villages_desc,
        profile_refidimages: this.form.profile_refidimages,
        profile_refidimages_url: this.form.profile_refidimages_url.replace(
          /^.+?;base64,/,
          ""
        ),
        profile_actived: this.$functions.ActivedFlag(this.form.profile_actived),
        profile_branchunit: this.form.profile_branchunit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfile"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          console.log("Feedback Response Insert", feedback);
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000)
              this.confirm.dialog = false
              this.confirm.text = "Ok"
              if (this.url_type === "add") {
                this.form.profile_cuser = feedback[0].feedback_users_id
                this.form.profile_cdate = feedback[0].feedback_users_date
                if (this.form.profile_lob === '02') {
                  this.tabs = 'tab-organization'
                  document.getElementById("taborganization").scrollIntoView()
                }
              }
              this.form.profile_luser = feedback[0].feedback_users_id
              this.form.profile_ldate = feedback[0].feedback_users_date
              this.form.profile_id = feedback[0].feedback_id
              this.Reset()
              this.find.id = feedback[0].feedback_id
              this.url_type = "edit"
              this.Search()
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    Close(flag) {
      if (flag === "confirm") {
        this.confirm.dialog = false;
        if (this.url_type !== "add") {
          this.url_type = "edit";
        }
      } else if (flag === "profileaccount") {
        this.url_type = "addprofileaccount";
        this.dialog.profileaccount = false;
        this.dialog.editprofileaccount = false;
        let id = document.getElementById("profile_id").value
        this.ListProfileAccount(id);
      } else if (flag === "profileaccount") {
        this.url_type = "editprofileaccount";
        this.dialog.editprofileaccount = false;
        let id = document.getElementById("profile_id").value
        this.ListProfileAccount(id);
      }
    },
    // open dialog for add profileaccount
    OpenProfileAccount() {
      this.dialog.profileaccount = true;
      this.AddProfileAccount();
    },
    AddProfileAccount() {
      this.url_type = "addprofileaccount";
      this.form.profileaccount_id = "";
      this.form.profileaccount_profileid = "";
      this.form.profileaccount_currency = "";
      this.form.profileaccount_currencydesc = "";
      this.form.profileaccount_bank = "";
      this.form.profileaccount_bankdesc = "";
      this.form.profileaccount_bankbranch = "";
      this.form.profileaccount_accountno = "";
      this.form.profileaccount_accountname = "";
      this.form.profileaccount_actived = true;
      document.getElementById("profileaccount_currency").value = ""
      document.getElementById("profileaccount_bank").value = ""
      this.flag.profileaccount = true;
      this.dialog.profileaccount = true;
    },
    //select all item datatable profileaccount
    SelectAllProfileAccount() {
      this.profile_account.selected =
        this.profile_account.selected === this.profile_account.items
          ? []
          : this.profile_account.items;
    },
    // check value selected datatable profile account
    CheckProfileAccount(value) {
      this.profile_account.selected = value;
    },
    // search profile account
    SearchProfileAccount() {
      this.profile_account.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchaccount").focus();
      }, 1000);
    },
    // list data for profileaccount
    ListProfileAccount(id) {
      this.loading.profileaccount = true;
      let formdata = {
        profileaccount_profileid: id,
        order_by: "profileaccount_profileid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListProfileAccount"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          // console.log("profaccount", feedback)
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.profile_account.items = feedback;
            }
          } else {
            this.profile_account.items = feedback;
            // this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.profileaccount = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.profileaccount = false;
        });
    },

    // function for write profile account
    WriteProfileAccount() {
      let currency = document.getElementById("profileaccount_currency").value
      let bank = document.getElementById("profileaccount_bank").value
      let profileid = document.getElementById("profile_id").value

      let formdata = {
        url_type: this.url_type,
        profileaccount_id: this.form.profileaccount_id,
        profileaccount_profileid: profileid,
        profileaccount_currency: currency,
        profileaccount_bank: bank,
        profileaccount_accountno: this.form.profileaccount_accountno,
        profileaccount_accountname: this.form.profileaccount_accountname,
        profileaccount_profileidpar: this.form.profileaccount_profileidpar,
        profileaccount_bankbranch: this.form.profileaccount_bankbranch,
        profileaccount_actived: this.$functions.ActivedFlag(
          this.form.profileaccount_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfileAccount"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addprofileaccount") {
                this.form.profileaccount_cuser = feedback[0].feedback_users_id;
                this.form.profileaccount_cdate =
                  feedback[0].feedback_users_date;
              }
              this.form.profileaccount_ldate = feedback[0].feedback_users_id;
              this.form.profileaccount_luser = feedback[0].feedback_users_date;
              this.url_type = "editprofileaccount";
              this.dialog.profileaccount = false;
              this.ListProfileAccount(profileid);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    EditProfileAccount(id, profaccountid) {
      this.dialog.profileaccount = true;
      let formdata = {
        profileaccount_id: id,
        profileaccount_profileid: profaccountid,
        order_by: "profileaccount_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListProfileAccount"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editprofileaccount";
              this.form.profileaccount_id = feedback[0].profileaccount_id;
              this.form.profileaccount_profileid = feedback[0].profileaccount_profileid;
              this.form.profileaccount_currency = feedback[0].profileaccount_currency;
              this.form.profileaccount_currencydesc = feedback[0].profileaccount_currencydesc;
              this.form.profileaccount_bank = feedback[0].profileaccount_bank;
              this.form.profileaccount_bankdesc = feedback[0].profileaccount_bankdesc;
              this.form.profileaccount_accountno = feedback[0].profileaccount_accountno;
              this.form.profileaccount_accountname = feedback[0].profileaccount_accountname;
              this.form.profileaccount_profileidpar = feedback[0].profileaccount_profileid;
              this.form.profileaccount_bankbranch =  feedback[0].profileaccount_bankbranch;
              this.form.profileaccount_actived = this.$functions.YesOrNo(feedback[0].profileaccount_actived);
              this.form.profileaccount_cdate = feedback[0].profileaccount_cdate;
              this.form.profileaccount_cuser = feedback[0].profileaccount_cuser;
              this.form.profileaccount_ldate = feedback[0].profileaccount_ldate;
              this.form.profileaccount_luser = feedback[0].profileaccount_luser;
              this.dialog.editprofileaccount = true;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.profileaccountdialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.profileaccountdialog = false;
        });
    },

    // function for delete data profile account
    DeleteProfileAccount(id, profaccount) {
      let profaccountid = document.getElementById("profile_id").value
      let formdata = {
        url_type: "deleteprofileaccount",
        profileaccount_id: id,
        profileaccount_profileid: profaccount,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfileAccount"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];

              this.ListProfileAccount(profaccountid);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      }
      if (color === "error") {
        this.confirm.dialog = false
        this.confirm.text = "Ok"
      }
    },
    Get(id) {
      this.dialogprofile = false
      this.$emit("get", id)
    },
    ShowUsers(id) {
      this.dialog.users = true
      this.form.display_users = id
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position)
    },
    OpenSearch() {
      this.dialog.search = true;
      this.btn_search = false;
      setTimeout(function () {
        document.getElementById("find_id").focus();
      }, 500);
    },
    Search() {
      this.btn_search = false
      this.dialog.search = false
      this.List()
    },
    Reset() {
      this.find.id = "";
      this.find.name = "";
      this.find.idno = "";
      this.find.npwp = "";
      this.find.lob = "";
      this.find.lobdesc = "";
      setTimeout(function () {
        document.getElementById("find_id").focus();
      }, 500);
    },
    GetData (value) {
      var splitdata = this.$functions.Split(value, "|")
      var dataid    = splitdata[0]
      if (dataid.length === 2) {
        this.form.profile_province      = dataid
        this.form.profile_province_desc = splitdata[1]
      } else if (dataid.length === 4) {
        this.form.profile_regency        = dataid
        this.form.profile_regency_desc   = splitdata[1]
      } else if (dataid.length === 7) {
        this.form.profile_districts        = dataid
        this.form.profile_districts_desc   = splitdata[1]
      } else if (dataid.length === 10) {
        this.form.profile_villages        = dataid
        this.form.profile_villages_desc   = splitdata[1]
      }
    },
    GetLob (value) {
      var split = this.$functions.Split(value, "|")
      this.form.profile_lob       = split[0]
      this.form.profile_lob_desc  = split[1]
      this.form.profile_type      = split[3]
      this.form.profile_type_desc = split[4]
      this.ChangeProfileName()
    },
    GetFindLob (value) {
      var split = this.$functions.Split(value, "|")
      this.find.lob       = split[0]
      this.find.lobdesc  = split[1]
    },
    UploadIDImages() {
      this.$refs.profile_refidimages.click();
    },
    UploadIDImagesURL() {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.form.profile_refidimages_url = e.target.result;
          this.form.profile_refidimages = e.target.result;
        };
        // this.form.profile_refidimages = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    GetCGroup (value) {
      var split = this.$functions.Split(value, "|")
      this.form.profile_cgroup       = split[0]
      this.form.profile_cgroup_desc  = split[1]
      this.ChangeProfileName()
    },
    GetSalutation (value) {
      var split = this.$functions.Split(value, "|")
      this.form.profile_salutation       = split[0]
      this.form.profile_salutationdesc  = split[1]
    },
    ChangeProfileName () {
      if (this.form.profile_lob === '03' || this.form.profile_lob === '05') {
        this.form.profile_branchunitf = true
        let fullname = this.form.profile_cgroup_desc +' '+this.form.profile_branchunit
        this.form.profile_name = fullname.replaceAll('PT ','')
      } else {
        this.form.profile_branchunitf = false
      }
    },
    CheckLOB (lob) {
      if (lob === '03' || lob === '05') {
        this.form.profile_branchunitf = true
      } else {
        this.form.profile_branchunitf = false
      }
    }
  },
}
</script>