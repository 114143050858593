<template>
  <div class="mx-4">
    <v-tab-item> List </v-tab-item>
    <v-card-title>
      <!-- <v-btn
        color="error"
        title="Delete"
        v-if="selected.length > 0"
        :disabled="access.delete === 0 ? true : false"
      >
        <v-icon>{{$vars.V('icon_delete')}}</v-icon>
      </v-btn>
      <v-spacer></v-spacer> -->

      <v-text-field
        :disabled="selected.length >= 1"
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      ref="data_table"
      v-model="selected"
      :headers="headers"
      :items="items"
      :hide-default-footer="true"
      :single-select="singleselect"
      :search="search"
      item-key="mapheader_id"
      show-select
      :loading="loading"
      class="elevation-1"
    >
     <template  v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox 
        :disabled="mode === 'editing'"
         :value="isSelected" @input="select($event)"></v-simple-checkbox>
      </template>
      <template v-slot:header.data-table-select="{ on, props }">
        <v-simple-checkbox
        :disabled="mode === 'editing'"
         :value="isSelected" @input="select($event)"></v-simple-checkbox>

      </template>
      <template v-slot:item.action="items">
        <tr>
          <td>
            <v-btn
              :disabled="selected.length > 0 || mode === 'editing'"
              @click="Editing(items)"
              icon
              class="fa fa-edit"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </td>
          <td class="mx-4">
            <v-btn
              :disabled="selected.length > 0 || mode === 'editing'"
              @click="Delete(items)"
              icon
              class="fa fa-delete"
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-visible="selected.length == 0"
        :disabled="loading || mode === 'editing'"
        v-model="page"
        :length="maxpage"
        @input="gotoPage"
      ></v-pagination>

      <app-snackbar ref="snackbar"></app-snackbar>
    </div>
  </div>
</template>
<script>
import api from "./functions.js";

export default {
  components: {},
  data: () => ({
    mode: "adding",
    page: 1,
    maxpage: 0,
    limit: 10,
    loading: true,
    selected: [],
    search: "",
    singleselect: false,
    headers: [
      {
        text: "ID",
        align: "left",
        sortable: false,
        value: "mapheader_id",
      },
      { text: "Description", value: "mapheader_desc" },
      { text: "Actived", value: "mapheader_actived" },
      { text: "Actions", value: "action", sortable: false },
    ],
    items: [],
  }),

  methods: {
    Editing: function (e) {
      this.mode = "editing";
      this.$parent.$refs.form.$emit("Editing", e.item);
    },
    refreshData: function () {
      this.page = 1;
      this.limit = 10;
      this.fetchData();
    },
    Delete: function (items) {
      api.toggle(this.selected, items.item);
    },
    fetchData: function () {
      this.loading = true;
      api.ListData(
        this,
        this.$refs.snackbar,
        function (ctx, data) {
          ctx;
          ctx.items = data;

          ctx.loading = false;
          if (data.length > 0) {
            ctx.$parent.$emit("RecordCount", parseInt(data[0].count));
            ctx.maxpage = parseInt(data[0].max_page);
          }
        },
        this.limit,
        (this.page <= 0 ? 0 : this.page - 1) * this.limit
      );
    },
    gotoPage: function (page) {
      this.page = page;
      this.fetchData();
    },
  },
  mounted() {
    this.refreshData();

    this.$on("RefreshData", function () {
      this.refreshData();
      this.selected = [];
    });
  },
  watch: {
    selected: function () {
      this.$parent.$emit("SelectionRow", this.selected);
    },
  },

  computed: {
    ListData() {
      return api.ListDataAsync(this.limit, this.page * this.limit);
    },
  },
};
</script>