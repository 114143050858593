<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Table Limit"></app-drawer_menu>
	  	<v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
	  	<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if ="access.read === 1"
	    >
	        <v-tab
	        	id     	="tabform"
	        	href   	="#tab-form"
	        	@click	="FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href	="#tab-list"
	        	@click 	="List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  	="tab-form"
			>
			
			<!-- Form Table Limit -->
			<v-form enctype="multipart/form-data" ref="form_tablelimit" lazy-validation>
				<v-row class="mx-2 ma-1">
					<v-col cols="12" sm="12" md="12">
						<v-btn
							color		="tertiary"
							class		="white--text"
							:disabled   ="access.add === 0 ? true:false" 
							rounded
							@click 	    ="Add" 
							small
						>
							<v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
						</v-btn> &nbsp;
						<v-btn
							color		="primary"
							class		="white--text"
							:disabled 	="access.add === 0 || access.edit === 0 ? true:false"
							rounded
							@click		="Confirm('write','')" 
							small
						>
							<v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
						</v-btn> &nbsp;
						<v-btn
							color		="primary"
							class		="white--text"							
							rounded							
							small
							v-if="url_type !== 'add'"
						>
							<v-icon dark>bookmarks</v-icon>Copy Previous Year
						</v-btn>
					</v-col>
					<!-- Form Treaty Year -->
					<v-col cols="12" sm="3" md="2">                    
							<v-text-field
								v-model 	  	="form.tablelimit_treatyyear"
								required
								:rules          ="form.tablelimit_treatyyear_rules"
								:maxlength 	    ="maxinput.desc"
								label       	="Treaty Year *"
								placeholder 	="Treaty Year"
								background-color  ="numeric"
								id 			    ="tablelimit_treatyyear"
								v-mask         = "'####'"
								@keyup.enter 	="Confirm('write','')"
								reverse
								class           = "mt-n2"
							>
							</v-text-field>                    
					</v-col>
					<!-- Sub Class -->
					<v-col cols="12" sm="9" md="10">
					<app-cb
						cb_type           ="subclass"
						cb_url            ="apiListSubClass"
						cb_url_parameter  ="subclass_actived=Y"
						cb_title          ="Subclass"
						cb_id             ="tablelimit_subclassid"
						cb_desc           ="tablelimit_subclassid_desc"
						cb_rules          ="Please fill Subclass"
						cb_desc_readonly  ="readonly"
						cb_items_id       ="subclass_id"
						cb_items_desc     ="subclass_desc"
						:cb_value_id      ="form.tablelimit_subclassid"
						:cb_value_desc		="form.tablelimit_subclassid_desc"
						cb_items_additional_1 ="subclass_ujroh"
					>
					</app-cb>
					</v-col>
				</v-row>

				<!-- Info -->
				<v-row class="mx-2">
					<v-col cols="12" sm="5" md="5">
					<div v-show="url_type !== 'add' ? true:false ">
						<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
						<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.tablelimit_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.tablelimit_cuser)">{{form.tablelimit_cuser}}</span>
Last Update Date : {{form.tablelimit_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.tablelimit_luser)">{{form.tablelimit_luser}}</span>
</pre>
						</v-banner>
					</div>
					</v-col>
				</v-row>
				<!-- end: Info -->
			</v-form>
			<!-- end: Form Table Limit -->

			<template v-if="url_type !== 'add'">			  	
				<!-- Tab Sub Module -->
				<v-tabs
					background-color="white"
					color="fourth"
					v-if ="access.read === 1"
				>
					<v-tab
						id      ="tabtreatylimit"
						href   	="#tab-treatylimit"	
						@click	="ListTreatyLimit(form.tablelimit_row)"
					>
						Treaty Limit
					</v-tab>
					<v-tab 
						id      ="tabcocessionlimit"
						href	="#tab-cocessionlimit"
						@click 	="ListCoCessionLimit(form.tablelimit_row)"
					>
						Co. Cession Limit
					</v-tab>
					<v-tab-item
						value  ="tab-treatylimit"
					>
						<!-- Data Table Treaty Limit -->
						<template>
							<!-- Main Button Treaty Limit -->
							<v-card-title
								class="ml-n4"
							>
								<v-btn 
									:color 	="treatylimit.selected.length === treatylimit.items.length ? 'fourth':''"
									@click	="SelectAllTreatyLimit"
									class	="mr-2"
									title	="select all"
									small
									>select all
								</v-btn>
								<v-btn 
									@click	="OpenTreatyLimit"
									class	="mr-2"
									title	="select all"
									small
									>Add
								</v-btn>
								<v-btn 
									color     ="error"
									title 	  ="delete selected"
									@click 	  ="Confirm('multideletetreatylimit','')"
									class	  ="mr-2"
									v-if  	  ="treatylimit.selected.length > 0"
									:disabled ="access.delete === 0 ? true:false"
									small>
									<v-icon>{{$vars.V('icon_delete')}}</v-icon>
								</v-btn>
								<v-btn 
									color  ="primary"
									title  ="search"
									v-if   ="treatylimit.searchbutton === false"
									@click ="SearchTreatyLimit"
									small>
									<v-icon>{{$vars.V('icon_search')}}</v-icon>
								</v-btn>
								<v-spacer></v-spacer>
								<v-text-field
									v-model				="treatylimit.search"
									append-icon			="search"
									@click:append		="treatylimit.searchbutton = false"
									label				="Type and Enter"
									single-line
									hide-details
									v-if 				="treatylimit.searchbutton"
									id   				="searchtreatylimit"
									clearable
									@keyup.enter 		="ListTreatyLimit(form.tablelimit_row)"
								></v-text-field>
							</v-card-title>
							<!-- end: Main Button Treaty Limit  -->
							<v-spacer></v-spacer>
							<v-data-table
								:value="treatylimit.selected"
								:headers="treatylimit.headers"
								:items="ItemsTreatyLimit"
								:search="treatylimit.search"
								@input="CheckTreatyLimit($event)"
								item-key="treatylimit_row"
								show-select
								class="elevation-1"
								 :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
									showFirstLastPage: true,
									firstIcon: 'first_page',
									lastIcon: 'last_page',
									prevIcon: 'keyboard_arrow_left',
									nextIcon: 'keyboard_arrow_right'
								}"
								dense
								:loading 	="loading.list"
								loading-text="Loading... Please wait"
							>
								<template v-slot:item.treatylimit_tsilimit="{ item }">
									{{$functions.NewFormatNumber(item.treatylimit_tsilimit,4)}}
								</template>
								<template v-slot:item.treatylimit_fieldid_1="{ item }">
									{{item.treatylimit_fieldvaluedesc_1}}!{{item.treatylimit_fieldvaluedesc_2}}!{{item.treatylimit_fieldvaluedesc_3}}
								</template>
								<template v-slot:item.action="{ item }">
									<v-icon
										small
										class	  ="mr-2"
										@click	  ="EditTreatyLimit(item.treatylimit_tablelimitrow, item.treatylimit_id)"
										:disabled ="access.edit === 0 ? true:false"
										title 	  ="edit"
									>
										edit
									</v-icon>
									<v-icon
										small
										@click	  ="Confirm('deletetreatylimit',item.treatylimit_tablelimitrow, item.treatylimit_id)"
										:disabled ="access.delete === 0 ? true:false"
										title 	  ="delete"
									>
										delete
									</v-icon>
								</template>
							</v-data-table>
						</template>
						<!-- end: Data Table Treaty Limit -->
					</v-tab-item>
					<v-tab-item
						value  ="tab-cocessionlimit"
					>
						<!-- Data Table Co Cession Limit -->
						<template>
							<!-- Main Button Co Cession Limit -->
							<v-card-title
								class="ml-n4"
							>
								<v-btn 
									:color 	="cocessionlimit.selected.length === cocessionlimit.items.length ? 'fourth':''"									
									@click	="SelectAllCoCessionLimit"
									class	="mr-2"
									title	="select all"
									small
									>select all
								</v-btn>								
								<v-btn 
									@click	="OpenCoCessionLimit"
									class	="mr-2"
									title	="add"
									small
									>Add
								</v-btn>
								<v-btn 
									color     ="error"
									title 	  ="delete selected"
									@click 	  ="Confirm('multideletecocessionlimit','')"
									class	  ="mr-2"
									v-if  	  ="cocessionlimit.selected.length > 0"
									:disabled ="access.delete === 0 ? true:false"
									small>
									<v-icon>{{$vars.V('icon_delete')}}</v-icon>
								</v-btn>
								<v-btn 
									color  ="primary"
									title  ="search"
									v-if   ="cocessionlimit.searchbutton === false"
									@click ="SearchCoCessionLimit"
									small>
									<v-icon>{{$vars.V('icon_search')}}</v-icon>
								</v-btn>
								<v-spacer></v-spacer>
								<v-text-field
									v-model			="cocessionlimit.search"
									append-icon		="search"
									@click:append	="cocessionlimit.searchbutton = false"
									label			="Type and Enter"
									single-line
									hide-details
									v-if 			="cocessionlimit.searchbutton"
									id   			="searchcocessionlimit"
									clearable
									@keyup.enter 	="ListCoCessionLimit(form.tablelimit_row)"
								></v-text-field>
							</v-card-title>
							<!-- end: Main Button Co Cession Limit  -->
							<v-spacer></v-spacer>
							<v-data-table
								:value="cocessionlimit.selected"
								:headers="cocessionlimit.headers"
								:items="ItemsCocessionLimit"
								:search="cocessionlimit.search"
								@input="CheckCoCessionLimit($event)"
								item-key="treatylimit_row"
								show-select
								class="elevation-1"
								 :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
									showFirstLastPage: true,
									firstIcon: 'first_page',
									lastIcon: 'last_page',
									prevIcon: 'keyboard_arrow_left',
									nextIcon: 'keyboard_arrow_right'
								}"
								dense
								:loading 	="loading.cocessionlimit"
								loading-text="Loading... Please wait"
							>
								<template v-slot:item.cocessionlimit_oursharemin="{ item }">
									{{ item.cocessionlimit_oursharemin }} - {{ item.cocessionlimit_oursharemax }}
								</template>
								<template v-slot:item.action="{ item }">
									<v-icon
										small
										class	  ="mr-2"
										@click	  ="EditCoCessionLimit(item.cocessionlimit_tablelimitrow, item.cocessionlimit_instype, item.cocessionlimit_oursharemin)"
										:disabled ="access.edit === 0 ? true:false"
										title 	  ="edit"
									>
										edit
									</v-icon>
									<v-icon
										small
										@click	  ="Confirm('deletecocessionlimit',item.cocessionlimit_tablelimitrow, item.cocessionlimit_instype, item.cocessionlimit_oursharemin)"
										:disabled ="access.delete === 0 ? true:false"
										title 	  ="delete"
									>
										delete
									</v-icon>
								</template>
							</v-data-table>
						</template>
						<!-- end: Data Table Co Cession Limit -->
					</v-tab-item>
				</v-tabs>
				<!-- end: Tab Sub Module -->
			</template>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  <v-card-title 
			  		class="ml-n4"
			  	>
			  		<v-btn 
					  	:color 	="selected.length === items.length ? 'secondary':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	title	="select all page"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
					</v-btn>
	                <v-btn 
	                	color     ="error"
	                	title 	  ="delete selected"
	                	@click 	  ="Confirm('multidelete','')"
	                	class	  ="mr-2"
	                	v-if  	  ="selected.length > 0"
	                	:disabled ="access.delete === 0 ? true:false"
	                	small>
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-btn 
	                	color  ="primary"
	                	title  ="search"
	                	v-if   ="btn_search === false"
	                	@click ="btn_search = true"
	                	small>
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    :value="selected"
			    :headers="headers"
			    :items="items"
			    :search="search"
			    @input="Check($event)"
			    item-key="tablelimit_row"
			    show-select
			    class="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			    dense
			    :loading 	="loading.list"
    			loading-text="Loading... Please wait"
			  >
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class	  ="mr-2"
			        @click	  ="Edit(item.tablelimit_row)"
			        :disabled ="access.edit === 0 ? true:false"
			        title 	  ="edit"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click	  ="Confirm('delete',item.tablelimit_row)"
			        :disabled ="access.delete === 0 ? true:false"
			        title 	  ="delete"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
		</v-tab-item>
  	</v-tabs>
	<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
		v-model	="snackbar.dialog"
		:timeout	="snackbar.timeout"
		color		="fourth"
		rounded	="pill"
		top
		style 	="z-index: 9999"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
  	</v-snackbar>
  	<v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
	<!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>

	<!-- Dialog Treaty Limit -->
	<v-dialog
		v-model="dialog.treatylimit"
		scrollable
		transition="dialog-bottom-transition"
		persistent
		max-width="720"
	>
		<v-card>
			<v-toolbar dark color="primary" dense class="mb-2">
			<v-btn icon dark @click="Close('treatylimit')">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Treaty Limit</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon dark @click="AddTreatyLimit" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
			<v-btn icon dark @click="Confirm(url_type,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
			</v-toolbar>
			<v-card-text>
				<v-progress-linear :indeterminate="true" v-if="loading.treatylimitdialog"></v-progress-linear>
				<v-form enctype="multipart/form-data" ref="form_treatylimit" lazy-validation>
					<div class="font-weight-black mt-4">
						CONDITIONS APPLY WITH:
					</div>
					<v-row class="mx-2 mt-2">
						<v-col cols="12" sm="3" md="3">
							<v-select
								v-model		="form.treatylimit_category"										
								:items		="form.treatylimit_category_option"
								:item-text  ="form.treatylimit_category_option.text"
                  				:item-value ="form.treatylimit_category_option.value"
								label		="Category"
								id			="treatylimit_category"
							></v-select>
						</v-col>
						<v-col cols="12" sm="3" md="3">							
							<v-text-field 
								v-model.lazy 		="form.treatylimit_cession"
								ref 				="treatylimit_cession"
								label 				="Cession Limit (%)"
								placeholder 		="0"
								background-color	="numeric"
								v-money 			="format_percent"
								:maxlength 	        ="maxinput.percent"
								id 		      	    ="treatylimit_cession"
								reverse								
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="3" md="3">							
							<v-text-field 
								v-model.lazy 		="form.treatylimit_policycession"
								ref 				="treatylimit_policycession"
								label 				="Policy Cession Limit (%)"
								placeholder 		="0"
								background-color	="numeric"
								v-money 			="format_percent"
								:maxlength 	        ="maxinput.percent"
								id 		      	    ="treatylimit_policycession"
								reverse								
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="3" md="3">							
							<v-text-field 
								v-model.lazy 		="form.treatylimit_reinsshare"
								ref 				="treatylimit_reinsshare"
								label 				="Reins Share (%)"
								placeholder 		="0"
								background-color	="numeric"
								v-money 			="format_percent"
								:maxlength 	        ="maxinput.percent"
								id 		      	    ="treatylimit_reinsshare"
								reverse								
							>
							</v-text-field>
						</v-col>
						<!-- <v-col cols="12" sm="4" md="4" class="mt-n3">
							<div class="text-caption">
								Hold Cession
							</div>
							<v-btn-toggle dense v-model="form.treatylimit_holdcession" rounded>
								<v-btn small>
									No
								</v-btn>
								<v-btn small>
									Yes
								</v-btn>
							</v-btn-toggle>
						</v-col>
						<v-col cols="12" sm="4" md="4" class="mt-n3">
							<div class="text-caption">
								Priority Cession
							</div>
							<v-btn-toggle dense v-model="form.treatylimit_prioritycession" rounded>
								<v-btn small>
									No
								</v-btn>
								<v-btn small>
									Yes
								</v-btn>
							</v-btn-toggle>
						</v-col> -->
					</v-row>
					<div class="font-weight-black mt-4">
						CONDITIONS APPLY FOR:
					</div>
					<v-row class="mx-2 mt-2">
						<v-col cols="12" sm="8" md="8">							
							<app-cb
								cb_type           	="instype"
								cb_url            	="apiListReinsType"
								cb_url_parameter  	="reinstype_actived=Y"
								cb_title          	="Reinsurance Type"
								cb_id             	="treatylimit_reinstype"								
								cb_rules          	=""	
								cb_items_id         ="reinstype_id"
								cb_items_desc       ="reinstype_desc"
								:cb_value_id        ="form.treatylimit_reinstype"
								:cb_value_desc		="form.treatylimit_reinstypedesc"							
							>
							</app-cb>
						</v-col>
						<v-col cols="12" sm="4" md="4" class="mt-n3">							
							<v-text-field 
								v-model.lazy 		="form.treatylimit_tsilimit"
								ref 				="treatylimit_tsilimit"
								label 				="Applicable TSI Limit"
								placeholder 		="0"
								background-color	="numeric"
								v-money 			="format_amount"
								id 		      	    ="treatylimit_tsilimit"
								reverse								
							>
							</v-text-field>
						</v-col>
					</v-row>
					<div class="font-weight-black mb-10">
						OBJECT INFO:
					</div>
					<v-row class="mx-2" v-for="index in 20" :key="index">
						<v-col cols="12" sm="12" md="12" class="mt-n5" v-if ="gendet_fieldlabel_value[index] !== undefined">
							<app-oi
							:oi_title         ="gendet_fieldlabel_value[index]"
							:oi_id            ="`gendet_fieldid_${index}`"
							:oi_desc          ="`gendet_fielddesc_${index}`"
							oi_rules          ="Please fill"
							:oi_desc_readonly ="false"
							:oi_refid         ="gendet_fieldid_value[index]"
							:oi_value_id      ="gentab_detailid_value[index]"
							:oi_value_desc    ="gentab_detaildesc_value[index]"
							dense
							></app-oi>
						</v-col>
					</v-row>
				</v-form>
				<!-- <v-col cols="12" sm="12" md="12" class="mb-7 mt-n7">
					<div v-show="url_type === 'editdetail' ? true:false ">
						<v-checkbox v-model="flag.showdetail" label="Show Info"></v-checkbox>
						<v-banner v-model="flag.showdetail" single-line transition="slide-y-transition">
							<pre>
							Created Date 	 : {{form.gendet_cdate}}
							Created User 	 : {{form.gendet_cuser}}
							Last Update Date : {{form.gendet_ldate}}
							Last Update User : {{form.gendet_luser}}
							</pre>
						</v-banner>
					</div>
				</v-col> -->
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>        	
			</v-card-actions>
        </v-card>
    </v-dialog>
	<!-- end: Dialog Treaty Limit -->

	<!-- Dialog Co Cession Limit -->
	<v-dialog
      v-model="dialog.cocessionlimit"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="550"
    >
      	<v-card>
			<v-toolbar dark color="primary" dense class="mb-2">
			<v-btn icon dark @click="Close('cocessionlimit')">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Co Cession Limit</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon dark @click="AddCoCessionLimit" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
			<v-btn icon dark @click="Confirm(url_type,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
			</v-toolbar>
			<v-card-text>
				<v-progress-linear :indeterminate="true" v-if="loading.cocessionlimitdialog"></v-progress-linear>
				<v-form enctype="multipart/form-data" ref="form_cocessionlimit" lazy-validation>					
					<v-row class="mx-2 mt-2">						
						<v-col cols="12" sm="12" md="12" class="mt-n3">
							<app-cb
								cb_type           	="instype"
								cb_url            	="apiListInsType"
								cb_url_parameter  	="instype_actived=Y"
								cb_title          	="Source Type"
								cb_id             	="cocessionlimit_instype"
								cb_desc            	="cocessionlimit_instypedesc"
								cb_rules          	="Please fill Source Type"
								cb_items_id       	="instype_id"
								cb_items_desc      	="instype_desc"
								:cb_value_id        ="form.cocessionlimit_instype"
								:cb_value_desc      ="form.cocessionlimit_instypedesc"
							>
							</app-cb>
						</v-col>
						<!-- <v-col cols="12" sm="8" md="8" class="mt-n3">
							<div class="text-caption">
								Hold Cession
							</div>
							<v-btn-toggle dense v-model="form.cocessionlimit_holdcession" rounded>
								<v-btn small>
									No
								</v-btn>
								<v-btn small>
									Yes
								</v-btn>
							</v-btn-toggle>
						</v-col> -->
						<v-col cols="12" sm="4" md="4" class="mt-n3">							
							<v-text-field 
								v-model.lazy 		="form.cocessionlimit_oursharemin"
								ref 				="cocessionlimit_oursharemin"
								label 				="Min (%)"
								placeholder 		="0"
								background-color	="numeric"
								v-money 			="format_percent"
								id 		      	    ="cocessionlimit_oursharemin"
								reverse								
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="4" md="4" class="mt-n3">
							<v-text-field 
								v-model.lazy 		="form.cocessionlimit_oursharemax"
								ref 				="cocessionlimit_oursharemax"
								label 				="Max (%)"
								placeholder 		="0"
								background-color	="numeric"
								v-money 			="format_percent"
								id 		      	    ="cocessionlimit_oursharemax"
								reverse								
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="4" md="4" class="mt-n3">
							<v-text-field 
								v-model.lazy 		="form.cocessionlimit_cession"
								ref 				="cocessionlimit_cession"
								label 				="Cession (%)"
								placeholder 		="0"
								background-color	="numeric"
								v-money 			="format_percent"
								id 		      	    ="cocessionlimit_cession"
								reverse								
							>
							</v-text-field>
						</v-col>						
					</v-row>
				</v-form>
				<!-- <v-col cols="12" sm="12" md="12" class="mb-7 mt-n7">
					<div v-show="url_type === 'editdetail' ? true:false ">
						<v-checkbox v-model="flag.showdetail" label="Show Info"></v-checkbox>
						<v-banner v-model="flag.showdetail" single-line transition="slide-y-transition">
							<pre>
							Created Date 	 : {{form.gendet_cdate}}
							Created User 	 : {{form.gendet_cuser}}
							Last Update Date : {{form.gendet_ldate}}
							Last Update User : {{form.gendet_luser}}
							</pre>
						</v-banner>
					</div>
				</v-col> -->
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>        	
			</v-card-actions>
        </v-card>
    </v-dialog>
	<!-- end: Dialog Co Cession Limit -->


  </div>
</template>
<script>
export default {
	data: () => ({
		url_type: "add",
		cont: false,
		access: {
			read: 0,
			add: 0,
			edit: 0,
			delete: 0,
		},
		maxinput: {
			subclassid: 6,
			description: 225,
			percent: 5
		},
		snackbar: {
			dialog: false,
			color: "success",
			text: "",
			timeout: 3000,
		},
		confirm: {
			dialog: false,
			title: "",
			text: "Ok",
		},		
		format_money: {
			decimal: ',',
			thousands: '.',
			precision: 0,
		},
		format_percent: {
			decimal: ',',
			thousands: '.',
			precision: 2,
		},
		format_amount: {
			decimal: ',',
			thousands: '.',
			precision: 4,
		},
		selected: [],
		search: "",
		headers: [
			{
				text: "Row",
				align: "left",
				value: "tablelimit_row",
			},
			{ text: "Treaty Year", value: "tablelimit_treatyyear" },
			{ text: "Sub Class ID", value: "tablelimit_subclassid" },
			{ text: "Description", value: "tablelimit_description" },
			{ text: "Actions", value: "action", sortable: false },
		],
		remarks: {
			feedback: "",
			dialog: false,
		},
		items: [],
		accessmenu: [],
		loading: {
			page: true,
			list: false,
			detaildialog: false,
			treatylimit: false,
			treatylimitdialog: false,
			cocessionlimit: false,
			cocessionlimitdialog: false,
		},
		btn_search: false,
		form: {
			tablelimit_row: '',
        	tablelimit_subclassid: '',
        	tablelimit_subclassid_rules: [v => !!v || 'Please fill ID'],
        	tablelimit_subclassid_readonly: false,
        	tablelimit_description: '',
        	tablelimit_description_rules: [v => !!v || 'Please fill Description'],
            tablelimit_treatyyear: '',
            tablelimit_treatyyear_rules: [v => !!v || 'Please fill Description'],
			// Treaty Limit
			treatylimit_tablelimitrow: '',
			treatylimit_id: '',
			treatylimit_category: '',
			treatylimit_cession: '',
			treatylimit_policycession: '',
			treatylimit_reinsshare: '',
			treatylimit_holdcession: '',
			treatylimit_prioritycession: '',
			treatylimit_tsilimit: '',
			treatylimit_category_option: [			
				{text: '-- Select --', value: ''},
				{text: 'Low', value: 'L'},
				{text: 'Medium', value: 'M'},
				{text: 'High', value: 'H'}				
			],
			treatylimit_reinstype: '',
			treatylimit_reinstypedesc:'',
			// Co Cession Limit
			cocessionlimit_row: '',
			cocessionlimit_tablelimitrow: '',
			cocessionlimit_instype: '',			
			cocessionlimit_instypedesc: '',			
			cocessionlimit_oursharemin: 0,
			cocessionlimit_oursharemax: 0,
			cocessionlimit_cession: 0,
			cocessionlimit_holdcession: 0,
			display_users: '',
        },
        selected_subclass: [],
		items_subclass: [],
		selected_channel: [],
		items_channel: [],
        gentab_fieldlabel: {},
        gentab_fieldid_value: {},
        gentab_fieldname_value: {},
        gendet_fieldid_value: {},
        gendet_fieldlabel_value: {},
        gentab_detailid_value: {},
        gentab_detaildesc_value: {},
        attributes: false,
        detail: {
        	headers: [
				{
					text: "ID",
					align: "left",
					sortable: false,
					value: "gendet_id",
				},
				{ text: "Description", value: "gendet_desc" },
				{ text: "Actived", value: "gendet_actived" },
				{ text: "Actions", value: "action", sortable: false },
			],
			items: [],
			selected: [],
			checksubclass: false,
			checkchannel: false,
			search: "",
			searchbutton: false,
        },
        flag: {
        	detail: false,
        	showdetail: false,
			treatylimit: false,
			showtreatylimit: false,
			cocessionlimit: false,
			showcocessionlimit: false,
        },
        dialog: {
        	attributes: false,
        	subclass: false,
        	channel: false,
        	detail: false,			
			treatylimit: false,
			cocessionlimit: false,
			users: false,
        },
		// Data Table Treaty Limit		
		treatylimit: {
        	headers: [
				{
					text: "Category",
					align: "left",
					value: "treatylimit_category",
				},
				{ text: "Cession Limit(%)", value: "treatylimit_cession" },
				{ text: "TSI Limit", value: "treatylimit_tsilimit" },
				{ text: "Object Info", value: "treatylimit_fieldid_1" },
				{ text: "Action", value: "action" }
			],
			items: [],
			selected: [],
			search: "",
			searchbutton: false,
        },
		// end: Data Table Treaty Limit

		// Data Table Co Cession Limit				
		cocessionlimit: {
        	headers: [
				{
					text: "Source Type",
					align: "left",
					value: "cocessionlimit_instypedesc",
				},			
				{ text: "Share(%)", value: "cocessionlimit_oursharemin" },			
				{ text: "% Limit", value: "cocessionlimit_cession" },
				{ text: "Action", value: "action" }
			],
			items: [],
			selected: [],
			search: "",
			searchbutton: false,
        },
		treatylimit_objectinfo: []
		// end: Data Table Co Cession Limit
	}),
	created() {
		this.FirstLoad()
	},
	computed: {
		ItemsTreatyLimit() {
			for (let i = 0 ;i <= this.treatylimit.items.length - 1 ;i++) {
				const treatylimitindex = this.treatylimit.items[i]
				treatylimitindex.treatylimit_row = this.treatylimit.items[i].treatylimit_tablelimitrow+'|'+this.treatylimit.items[i].treatylimit_id
			}
			return this.treatylimit.items
		},
		ItemsCocessionLimit() {
			for (let i = 0 ;i <= this.cocessionlimit.items.length - 1 ;i++) {
				const cocessionlimitindex = this.cocessionlimit.items[i]
				cocessionlimitindex.treatylimit_row = this.cocessionlimit.items[i].cocessionlimit_tablelimitrow+'|'+this.cocessionlimit.items[i].cocessionlimit_instype+'|'+this.cocessionlimit.items[i].cocessionlimit_oursharemin
			}
			return this.cocessionlimit.items
		}
    },
	methods: {
		FirstLoad() {
			//this function use for validating the screen with user privilleges
			this.Access()
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul ="gentab"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let priv = response.data
					if (priv.length > 0) {
						this.access.read = priv[0].usersmenu_read
						this.access.add = priv[0].usersmenu_add
						this.access.edit = priv[0].usersmenu_edit
						this.access.delete = priv[0].usersmenu_delete
						if (priv[0].usersmenu_read === 0) {
							this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
						}
					} else {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					}
					this.loading.page = false
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 0)
					this.loading.page = false
				})
		},
		SelectAllPage() {
			this.selected = this.selected === this.items ? [] : this.items
		},
		Check(value) {
			this.selected = value
		},
		SelectAllDetail() {
			this.detail.selected = this.detail.selected === this.detail.items ? [] : this.detail.items
		},
		CheckDetail(value) {
			this.detail.selected = value
		},
		//Function for get all data from table GenTab with API in mod_users.go
		List() {
			this.loading.list = true
			let formdata = {
				tablelimit_row: "",
				tablelimit_description: "",
				order_by: "tablelimit_row",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListTableLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback !=="Y") {
							this.SnackBar(
								true,
								"error",
								feedback[0].feedback,
								0
							)
						} else {
							this.items = feedback
						}
					} else {
						this.items = feedback
						this.SnackBar(true, 'error', this.$functions.NoData(), 0)
					}
					this.loading.list = false
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 3000)
					this.loading.list = false
				})
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
		Confirm(flag, id, key1, key2) {
			if (flag ==="write") {
				if (this.$refs.form_tablelimit.validate()) {
					this.confirm.dialog = true
					this.confirm.title ="Save"
					setTimeout(function() {
						document.getElementById("dialog").focus()
					}, 500)
				}
			} else if (flag ==="delete") {
				this.url_type ="delete"
				this.confirm.dialog = true
				this.confirm.title ="Delete `" + id + "`"
				this.gentab_id = id
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag ==="multidelete") {
				this.url_type ="multidelete"
				this.confirm.dialog = true
				this.confirm.title =
					"Delete `" + this.selected.length + "` data"
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag ==="addsubclass") {
				this.url_type ="addsubclass"
				this.confirm.dialog   = true
				this.confirm.title    = 'Save Sub Class General Table'
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			} else if (flag ==="addchannel") {
				this.url_type ="addchannel"
				this.confirm.dialog   = true
				this.confirm.title    = 'Save Channel General Table'
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'adddetail') {
				if (this.$refs.form_gendet.validate()) {
					this.url_type 		  = 'adddetail'
					this.confirm.dialog   = true
					this.confirm.title    = 'Save Detail'
					setTimeout(function(){
					document.getElementById('dialog').focus()
					}, 500)
				}
			} else if (flag === 'editdetail') {
				if (this.$refs.form_gendet.validate()) {
					this.url_type 		  = 'editdetail'
					this.confirm.dialog   = true
					this.confirm.title    = 'Edit Detail'
					setTimeout(function(){
					document.getElementById('dialog').focus()
					}, 500)
				}
			} else if (flag === 'deletedetail') {
				this.url_type 		  = 'deletedetail'
				this.confirm.dialog   = true
				this.confirm.title    = 'Delete Detail `' + id + '`'
				this.form.gendet_id   = id
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'multideletedetail') {
				this.url_type 		  = 'multideletedetail'
				this.confirm.dialog   = true
				this.confirm.title    = 'Delete `'+ this.detail.selected.length + '` data'
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'addtreatylimit') {
				if (this.$refs.form_treatylimit.validate()) {
					this.url_type 		  = 'addtreatylimit'
					this.confirm.dialog   = true
					this.confirm.title    = 'Save Treaty Limit'
					setTimeout(function(){
						document.getElementById('dialog').focus()
					}, 500)					
				}				
			} else if (flag === 'edittreatylimit') {
				if (this.$refs.form_treatylimit.validate()) {
					this.url_type 		  = 'edittreatylimit'
					this.confirm.dialog   = true
					this.confirm.title    = 'Save Treaty Limit'
					setTimeout(function(){
						document.getElementById('dialog').focus()
					}, 500)					
				}				
			} else if (flag === 'deletetreatylimit') {
				this.url_type 		  				= 'deletetreatylimit'
				this.confirm.dialog   				= true
				this.confirm.title    				= 'Delete Treaty Limit Row `' + id + '` ID `' + key1 + '`'
				this.form.treatylimit_tablelimitrow	= id
				this.form.treatylimit_id			= key1
				setTimeout(function(){
					document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'multideletetreatylimit') {
				this.url_type 		  = 'multideletetreatylimit'
				this.confirm.dialog   = true
				this.confirm.title    = 'Delete `'+ this.treatylimit.selected.length + '` data'
				setTimeout(function(){
					document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'addcocessionlimit') {
				if (this.$refs.form_cocessionlimit.validate()) {
					this.url_type 		  = 'addcocessionlimit'
					this.confirm.dialog   = true
					this.confirm.title    = 'Save Co Cession Limit'
					setTimeout(function(){
						document.getElementById('dialog').focus()
					}, 500)
				}
			} else if (flag === 'editcocessionlimit') {
				if (this.$refs.form_cocessionlimit.validate()) {
					this.url_type 		  = 'editcocessionlimit'
					this.confirm.dialog   = true
					this.confirm.title    = 'Save Co Cession Limit'
					setTimeout(function(){
						document.getElementById('dialog').focus()
					}, 500)
				}
			} else if (flag === 'deletecocessionlimit') {
				this.url_type 		  					= 'deletecocessionlimit'
				this.confirm.dialog   					= true
				this.confirm.title    					= 'Delete Co Cession Limit `' + id + '`'
				this.form.cocessionlimit_tablelimitrow	= id
				this.form.cocessionlimit_instype   		= key1
				this.form.cocessionlimit_oursharemin   	= key2
				setTimeout(function(){
					document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'multideletecocessionlimit') {
				this.url_type 		  = 'multideletecocessionlimit'
				this.confirm.dialog   = true
				this.confirm.title    = 'Delete `'+ this.cocessionlimit.selected.length + '` data'
				setTimeout(function(){
					document.getElementById('dialog').focus()
				}, 500)
			}
		},

		//Function to request insert data to table Table Limit 'The API will validating your data, include the user who process the data' (go to APIWriteGenTab with url_type ="add")
		Add() {
			this.url_type 					         ="add"			
			this.form.tablelimit_subclassid          =""
			this.form.tablelimit_description	     =""
			this.form.tablelimit_treatyyear	         =""			
			this.form.tablelimit_subclassid_readonly = false
			setTimeout(function() {
				document.getElementById("tablelimit_subclassid").focus()
			}, 500)
		},
		//Function to request update data to table Table Limit 'The API will validating your data, include the user who process the data' (go to APIWriteGenTab with url_type ="edit")
		Edit(id) {			
			this.loading.list = true			
				
			this.ListTreatyLimit(id)
			this.ListCoCessionLimit(id)

			let formdata = {
				tablelimit_row			: id,
				tablelimit_subclassid	: "",
				tablelimit_description	: "",
				tablelimit_treatyyear	: "",
				order_by				: "tablelimit_row",
				order_type				: "ASC",
				limit					: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListTableLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback ==="Y") {
							document.getElementById("tabform").click()

							this.url_type 						="edit"
							this.form.tablelimit_row 			= feedback[0].tablelimit_row
							this.form.tablelimit_subclassid 	= feedback[0].tablelimit_subclassid
							this.form.tablelimit_subclassid_desc= feedback[0].tablelimit_description
							this.form.tablelimit_description	= feedback[0].tablelimit_description
							this.form.tablelimit_treatyyear		= feedback[0].tablelimit_treatyyear							
							this.form.tablelimit_cdate 			= feedback[0].tablelimit_cdate
							this.form.tablelimit_cuser 			= feedback[0].tablelimit_cuser
							this.form.tablelimit_ldate 			= feedback[0].tablelimit_ldate
							this.form.tablelimit_luser 			= feedback[0].tablelimit_luser																																	

							// Set param Row
							this.form.treatylimit_tablelimitrow		=	id
							this.form.cocessionlimit_tablelimitrow	=	id
							
							setTimeout(function() {
								document.getElementById("tablelimit_description").focus()								
							}, 500)
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog = true
							this.remarks.feedback = feedback[0].feedback
						}
					} else {
						this.SnackBar(true, "error", feedback, 0)
					}
					this.loading.list = false
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
					this.loading.list = false
				})
		},
		Delete(id) {
			let formdata = {
				url_type: "delete",
				gentab_id: id,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteGenTab"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback ==="Y") {
							this.SnackBar(
								true,
								"success",
								"Delete Success",
								3000
							)
							this.confirm.dialog = false
							this.confirm.text ="Ok"
							this.selected = []
							this.List()
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog 	= true
							this.remarks.feedback 	= feedback[0].feedback
						}
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
		},
		MultiProcess() {
			let multiData = JSON.stringify(this.selected)
			let multiDetail = JSON.stringify(this.detail.selected)
			let formdata = {
				url_type: this.url_type,
				gendet_gentab_id: this.form.gentab_id,
				multi_data: multiData,
				multi_detail: multiDetail,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteGenTab"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback[0].feedback ==="Y") {
						let feed
						if (feedback[0].feedback_failed == 0) {
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						} else {
							this.remarks.feedback = feedback[0].feedback_failed_remarks
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
							this.remarks.dialog = true
						}
						this.SnackBar(true, "success", feed, 0)
						this.confirm.dialog = false
						this.confirm.text 	="Ok"
						this.selected 		= []
						if (this.url_type === 'multidelete') {
							this.List()
						} else if (this.url_type === 'multideletedetail') {
							this.ListDetail()
							this.url_type = 'edit'
						}
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
				})
		},
		Loading() {
			this.confirm.text ="Loading..."
			if (this.url_type ==="add" || this.url_type ==="edit") {
				this.Write()
			} else if (this.url_type ==="delete") {
				this.Delete(this.gentab_id)
			} else if (this.url_type ==="multidelete") {
				this.MultiProcess("delete")
			} else if (this.url_type === 'adddetail' || this.url_type === 'editdetail') {
				this.WriteDetail()
			} else if (this.url_type === 'deletedetail') {
				this.DeleteDetail(this.form.gendet_id)
			} else if (this.url_type === 'multideletedetail') {
				this.MultiProcess('multideletedetail')
			} else if (this.url_type === 'addtreatylimit' || this.url_type === 'edittreatylimit') {
				this.WriteTreatyLimit()				
			} else if (this.url_type ==="deletetreatylimit") {
				this.DeleteTreatyLimit(this.form.treatylimit_tablelimitrow, this.form.treatylimit_id)
			} else if (this.url_type === 'multideletetreatylimit') {
				this.MultiProcessTreatyLimit('multideletetreatylimit')
			} else if (this.url_type === 'addcocessionlimit' || this.url_type === 'editcocessionlimit') {
				this.WriteCoCessionLimit()				
			} else if (this.url_type ==="deletecocessionlimit") {
				this.DeleteCoCessionLimit(this.form.cocessionlimit_tablelimitrow, this.form.cocessionlimit_instype, this.form.cocessionlimit_oursharemin)
			} else if (this.url_type === 'multideletecocessionlimit') {
				this.MultiProcessCocessionLimit('multideletecocessionlimit')
			} 
		},
		Write() {
			let tablelimit_subclassid  = document.getElementById('tablelimit_subclassid').value
			let tablelimit_subclassid_desc  = document.getElementById('tablelimit_subclassid_desc').value
			let formdata = {
				url_type				: this.url_type,
				tablelimit_row			: this.form.tablelimit_row,
				tablelimit_subclassid	: tablelimit_subclassid,
				tablelimit_description	: tablelimit_subclassid_desc,
				tablelimit_treatyyear	: this.form.tablelimit_treatyyear
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteTableLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback ==="Y") {
							this.SnackBar(
								true,
								"success",
								"Save Success",
								3000
							)
							this.confirm.dialog = false
							this.confirm.text ="Ok"
							if (this.url_type ==="add") {
								this.form.tablelimit_cuser = feedback[0].feedback_users_id
								this.form.tablelimit_cdate = feedback[0].feedback_users_date
							}
							this.form.tablelimit_ldate = feedback[0].feedback_users_id
							this.form.tablelimit_luser = feedback[0].feedback_users_date
							this.url_type ="edit"
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog 	= true
							this.remarks.feedback 	= feedback[0].feedback
						}
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
		},
		SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			if (color ==="error") {
				this.confirm.dialog 	= false
				this.confirm.text 		="Ok"
			}
		},
	    GetData (value) {
	    	if (value !== undefined) {
		    	let splits = this.$functions.Split(value, '|')
		    	this.$set(this.gentab_fieldlabel, splits[2],splits[1])
	    	}
	    },
	  	Close (flag) {
	  		if (flag === 'confirm') {
	  			this.confirm.dialog = false
	  			if (this.url_type !== 'add') {
	  				this.url_type = 'edit'
	  			}
	  		} else if (flag === 'treatylimit') {
				this.url_type = 'edit'
	  			this.dialog.treatylimit = false
				this.ListTreatyLimit(this.form.treatylimit_tablelimitrow)
			} else if (flag === 'cocessionlimit') {
				this.url_type = 'edit'
				this.dialog.cocessionlimit = false
				this.ListCoCessionLimit(this.form.cocessionlimit_tablelimitrow)	  			
			}
	  	},
	  	Search () {
	  		this.detail.searchbutton = true
	  		setTimeout(function() {
				document.getElementById("searchdetail").focus()
			}, 500)
	  	},
		
		// Function Treaty Limit
		SearchTreatyLimit () {
	  		this.treatylimit.searchbutton = true
	  		setTimeout(function() {
				document.getElementById("searchtreatylimit").focus()
			}, 500)
	  	},

		ListTreatyLimit (id) {
			this.loading.list = true
			let formdata = {
				treatylimit_tablelimitrow: id,
				order_by: "treatylimit_tablelimitrow",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListTreatyLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback !=="Y") {
							this.SnackBar(
								true,
								"error",
								feedback[0].feedback,
								0
							)
						} else {
							this.treatylimit.items = feedback
						}
					} else {
						this.treatylimit.items = feedback
						// this.SnackBar(true, 'error', this.$functions.NoData(), 0)
					}
					this.loading.list = false
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 3000)
					this.loading.list = false
				})
		},

		WriteTreatyLimit () {
			let cession			= document.getElementById('treatylimit_cession').value
			let policycession	= document.getElementById('treatylimit_policycession').value
			let reinsshare 		= document.getElementById('treatylimit_reinsshare').value
			let tsilimit 		= document.getElementById('treatylimit_tsilimit').value
			let holdcession     = 'N'
			let prioritycession = 'N'
			let reinstype		= document.getElementById('treatylimit_reinstype').value
			this.treatylimit_objectinfo = []      
			for (let i = 1; i <= 20; i ++) {
				let id            = document.getElementById('refgendet_fieldid_' + i)
				if (id !== null) {
					let idvalue       = id.value
					let valueid       = document.getElementById('gendet_fieldid_' + i).value
					let valuedesc     = document.getElementById('gendet_fielddesc_' + i).value
					this.treatylimit_objectinfo.push({treatylimit_fieldid: idvalue, treatylimit_fieldvalueid: valueid, treatylimit_fieldvaluedesc: valuedesc})
				}
			}      
			let formdata = {
				url_type					: this.url_type,
				treatylimit_tablelimitrow	: this.form.treatylimit_tablelimitrow,
				treatylimit_id				: this.form.treatylimit_id,
				treatylimit_category		: this.form.treatylimit_category,
				treatylimit_cession			: cession,
				treatylimit_policycession	: policycession,
				treatylimit_reinsshare		: reinsshare,
				treatylimit_holdcession		: holdcession,
				treatylimit_prioritycession	: prioritycession,
				treatylimit_reinstype		: reinstype,
				treatylimit_tsilimit		: tsilimit,
				treatylimit_objectinfo      : JSON.stringify(this.treatylimit_objectinfo)
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteTreatyLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback ==="Y") {
							this.SnackBar(
								true,
								"success",
								"Save Success",
								3000
							)
							this.confirm.dialog = false
							this.confirm.text ="Ok"
							if (this.url_type ==="addtreatylimit") {
								this.form.cocessionlimit_cuser = feedback[0].feedback_users_id
								this.form.cocessionlimit_cdate = feedback[0].feedback_users_date
							}
							this.form.cocessionlimit_ldate = feedback[0].feedback_users_id
							this.form.cocessionlimit_luser = feedback[0].feedback_users_date
							this.url_type ="edittreatylimit"
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog 	= true
							this.remarks.feedback 	= feedback[0].feedback
						}
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
		},

		EditTreatyLimit (row, id) {
			this.ClearDetailData()
			this.DetailData()
			this.flag.treatylimit			= true
			this.dialog.treatylimit			= true
			this.loading.treatylimitdialog 	= true			
			let formdata = {
				treatylimit_tablelimitrow: row,
				treatylimit_id: id,
				order_by: 'treatylimit_tablelimitrow',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListTreatyLimit'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
            .then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {	            		
	            		this.url_type 			    		= 'edittreatylimit'
	            		this.form.treatylimit_tablelimitrow = feedback[0].treatylimit_tablelimitrow
						this.form.treatylimit_id  			= feedback[0].treatylimit_id	

	                    this.$refs.treatylimit_cession.$el.getElementsByTagName('input')[0].value		= feedback[0].treatylimit_cession	                					
	                    this.$refs.treatylimit_policycession.$el.getElementsByTagName('input')[0].value = feedback[0].treatylimit_policycession	                					
	                    this.$refs.treatylimit_reinsshare.$el.getElementsByTagName('input')[0].value 	= feedback[0].treatylimit_reinsshare

						this.form.treatylimit_category		= feedback[0].treatylimit_category
											
						this.form.treatylimit_holdcession  		= this.$functions.YesOrNo(feedback[0].treatylimit_holdcession)
						this.form.treatylimit_prioritycession	= this.$functions.YesOrNo(feedback[0].treatylimit_prioritycession)
						
						this.form.treatylimit_reinstype		= feedback[0].treatylimit_reinstype	
						this.form.treatylimit_reinstypedesc	= feedback[0].treatylimit_reinstypedesc

						this.$refs.treatylimit_tsilimit.$el.getElementsByTagName('input')[0].value	= feedback[0].treatylimit_tsilimit

						this.form.treatylimit_cdate			= feedback[0].treatylimit_cdate
						this.form.treatylimit_cuser			= feedback[0].treatylimit_cuser
						this.form.treatylimit_ldate			= feedback[0].treatylimit_ldate
						this.form.treatylimit_luser			= feedback[0].treatylimit_luser
						// Object Info
						for (let i = 1; i <= 20; i ++) {
							let field_valueid       = `treatylimit_fieldvalueid_` + i
							let field_valuedesc     = `treatylimit_fieldvaluedesc_` + i
							if (feedback[0][field_valueid] !== '') {
								this.$set(this.gentab_detailid_value, i, feedback[0][field_valueid])
								this.$set(this.gentab_detaildesc_value, i, feedback[0][field_valuedesc])
							} else {
								this.$set(this.gentab_detailid_value, i, '')
								this.$set(this.gentab_detaildesc_value, i, '')
							}
						}
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
				}
				this.loading.treatylimitdialog = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
				this.loading.treatylimitdialog = false
			})
		},

		DeleteTreatyLimit(row, id) {
			let formdata = {
				url_type: "deletetreatylimit",
				treatylimit_tablelimitrow: row,
				treatylimit_id: id
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteTreatyLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback ==="Y") {
							this.SnackBar(
								true,
								"success",
								"Delete Success",
								3000
							)
							this.confirm.dialog = false
							this.confirm.text ="Ok"
							this.selected = []
							this.ListCoCessionLimit()
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog 	= true
							this.remarks.feedback 	= feedback[0].feedback
						}
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
		},

		MultiProcessTreatyLimit() {
			let multiData = JSON.stringify(this.treatylimit.selected)
			let formdata = {
				url_type: this.url_type,
				treatylimit_id: this.form.treatylimit_id,
				multi_data: multiData
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteTreatyLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback[0].feedback ==="Y") {
						let feed
						if (feedback[0].feedback_failed == 0) {
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						} else {
							this.remarks.feedback = feedback[0].feedback_failed_remarks
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
							this.remarks.dialog = true
						}
						this.SnackBar(true, "success", feed, 0)
						this.confirm.dialog 		= false
						this.confirm.text 			="Ok"
						this.treatylimit.selected	= []
						if (this.url_type === 'multideletetreatylimit') {
							this.ListTreatyLimit()
						}
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
				})
		},

		OpenTreatyLimit () {
			this.dialog.treatylimit = true
			this.DetailData()
			this.AddTreatyLimit()
		},

		AddTreatyLimit () {
			this.url_type      	  	 				= 'addtreatylimit'		
			this.form.treatylimit_id				= ''
			this.form.treatylimit_category			= ''
			this.form.treatylimit_cession			= 0
			this.form.treatylimit_policycession		= 0
			this.form.treatylimit_reinsshare		= 0
			this.form.treatylimit_holdcession		= 0
			this.form.treatylimit_prioritycession	= 0
			this.form.treatylimit_reinstype			= ''
			this.form.treatylimit_reinstypedesc		= ''
			this.form.treatylimit_tsilimit			= 0
			this.flag.treatylimit					= true
			this.dialog.treatylimit					= true
			this.ClearDetailData()
			
			let data = this
			setTimeout(function() {
				data.$refs.treatylimit_cession.$el.getElementsByTagName('input')[0].value = 0
				data.$refs.treatylimit_policycession.$el.getElementsByTagName('input')[0].value = 0
				data.$refs.treatylimit_reinsshare.$el.getElementsByTagName('input')[0].value = 0
				data.$refs.treatylimit_tsilimit.$el.getElementsByTagName('input')[0].value = 0
			}, 1000)
			
		},

		SelectAllTreatyLimit() {
			this.treatylimit.selected = this.treatylimit.selected === this.treatylimit.items ? [] : this.treatylimit.items
		},

		CheckTreatyLimit(value) {
			this.treatylimit.selected = value
		},
		// end: Function Treaty Limit

		// Function Co Cession Limit
		SearchCoCessionLimit () {
	  		this.cocessionlimit.searchbutton = true
	  		setTimeout(function() {
				document.getElementById("searchcocessionlimit").focus()
			}, 1000)
	  	},

		ListCoCessionLimit (id) {
			this.loading.cocessionlimit = true
			let formdata = {
				cocessionlimit_tablelimitrow: id,
				order_by: "cocessionlimit_tablelimitrow",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListCoCessionLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback !=="Y") {
							this.SnackBar(
								true,
								"error",
								feedback[0].feedback,
								0
							)
						} else {
							this.cocessionlimit.items = feedback
						}
					} else {
						this.cocessionlimit.items = feedback
						// this.SnackBar(true, 'error', this.$functions.NoData(), 0)
					}
					this.loading.cocessionlimit = false
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 3000)
					this.loading.cocessionlimit = false
				})
		},
		MultiProcessCocessionLimit() {
			let multiData = JSON.stringify(this.cocessionlimit.selected)
			let formdata = {
				url_type: this.url_type,
				cocessionlimit_row: this.form.cocessionlimit_row,
				multi_data: multiData
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteCoCessionLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback[0].feedback ==="Y") {
						let feed
						if (feedback[0].feedback_failed == 0) {
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						} else {
							this.remarks.feedback = feedback[0].feedback_failed_remarks
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
							this.remarks.dialog = true
						}
						this.SnackBar(true, "success", feed, 0)
						this.confirm.dialog 		= false
						this.confirm.text 			="Ok"
						this.cocessionlimit.selected	= []
						if (this.url_type === 'multideletecocessionlimit') {
							this.ListCoCessionLimit()
						}
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
				})
		},

		WriteCoCessionLimit () {
			let instype		= document.getElementById('cocessionlimit_instype').value
			let oursharemin	= document.getElementById('cocessionlimit_oursharemin').value
			let oursharemax = document.getElementById('cocessionlimit_oursharemax').value
			let cession 	= document.getElementById('cocessionlimit_cession').value
			let holdcession 
            if (this.form.cocessionlimit_holdcession == 1) {
				holdcession = 'Y'
			} else if (this.form.cocessionlimit_holdcession == 0) {
				holdcession = 'N'
			} else {
				holdcession = 'N'
			}

			let formdata = {
				url_type						: this.url_type,
				cocessionlimit_tablelimitrow	: this.form.cocessionlimit_tablelimitrow,
				cocessionlimit_instype			: instype,
				cocessionlimit_oursharemin		: oursharemin,
				cocessionlimit_oursharemax		: oursharemax,
				cocessionlimit_cession			: cession,
				cocessionlimit_holdcession		: holdcession
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteCoCessionLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback ==="Y") {
							this.SnackBar(
								true,
								"success",
								"Save Success",
								3000
							)
							this.confirm.dialog = false
							this.confirm.text ="Ok"
							if (this.url_type ==="addcocessionlimit") {
								this.form.cocessionlimit_cuser = feedback[0].feedback_users_id
								this.form.cocessionlimit_cdate = feedback[0].feedback_users_date
							}
							this.form.cocessionlimit_ldate = feedback[0].feedback_users_id
							this.form.cocessionlimit_luser = feedback[0].feedback_users_date
							this.url_type ="editcocessionlimit"
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog 	= true
							this.remarks.feedback 	= feedback[0].feedback
						}
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
		},

		EditCoCessionLimit (row, instype, oursharemin) {
			this.flag.cocessionlimit			= true
			this.dialog.cocessionlimit			= true
			this.loading.cocessionlimitdialog 	= true

			let formdata = {
				cocessionlimit_tablelimitrow: row,
				cocessionlimit_instype: instype,
				cocessionlimit_oursharemin: oursharemin,
				order_by: 'cocessionlimit_tablelimitrow',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListCoCessionLimit'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
            .then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {	            		
	            		this.url_type 			    			= 'editcocessionlimit'
	            		this.form.cocessionlimit_tablelimitrow 	= feedback[0].cocessionlimit_tablelimitrow
						this.form.cocessionlimit_instype  		= feedback[0].cocessionlimit_instype
						this.form.cocessionlimit_instypedesc	= feedback[0].cocessionlimit_instypedesc

	                    this.$refs.cocessionlimit_oursharemin.$el.getElementsByTagName('input')[0].value	= feedback[0].cocessionlimit_oursharemin	                					
	                    this.$refs.cocessionlimit_oursharemax.$el.getElementsByTagName('input')[0].value 	= feedback[0].cocessionlimit_oursharemax	                					
	                    this.$refs.cocessionlimit_cession.$el.getElementsByTagName('input')[0].value 		= feedback[0].cocessionlimit_cession	                					

						this.form.cocessionlimit_holdcession  = this.$functions.YesOrNo(feedback[0].cocessionlimit_holdcession)

						this.form.cocessionlimit_cdate			= feedback[0].cocessionlimit_cdate
						this.form.cocessionlimit_cuser			= feedback[0].cocessionlimit_cuser
						this.form.cocessionlimit_ldate			= feedback[0].cocessionlimit_ldate
						this.form.cocessionlimit_luser			= feedback[0].cocessionlimit_luser	     
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
				}
				this.loading.cocessionlimitdialog = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
				this.loading.cocessionlimitdialog = false
			})
		},

		DeleteCoCessionLimit(row, instype, oursharemin) {
			let formdata = {
				url_type: "deletecocessionlimit",
				cocessionlimit_tablelimitrow: row,
				cocessionlimit_instype: instype,
				cocessionlimit_oursharemin: oursharemin
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteCoCessionLimit"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback ==="Y") {
							this.SnackBar(
								true,
								"success",
								"Delete Success",
								3000
							)
							this.confirm.dialog = false
							this.confirm.text ="Ok"
							this.selected = []
							this.ListCoCessionLimit()
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog 	= true
							this.remarks.feedback 	= feedback[0].feedback
						}
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
		},

		OpenCoCessionLimit () {
			this.dialog.cocessionlimit = true
			this.AddCoCessionLimit()
		},

		AddCoCessionLimit () {
			this.url_type      	  	 				= 'addcocessionlimit'			
			this.form.cocessionlimit_instype		= ''
			this.form.cocessionlimit_instypedesc	= ''
			this.form.cocessionlimit_oursharemin	= 0
			this.form.cocessionlimit_oursharemax	= 0
			this.form.cocessionlimit_cession		= 0
			this.form.cocessionlimit_holdcession	= 0
			this.flag.cocessionlimit				= true
			this.dialog.cocessionlimit				= true
			
			this.$refs.cocessionlimit_oursharemin.$el.getElementsByTagName('input')[0].value = 0
			this.$refs.cocessionlimit_oursharemax.$el.getElementsByTagName('input')[0].value = 0
			this.$refs.cocessionlimit_cession.$el.getElementsByTagName('input')[0].value = 0	
		},

		SelectAllCoCessionLimit() {
			this.cocessionlimit.selected = this.cocessionlimit.selected === this.cocessionlimit.items ? [] : this.cocessionlimit.items
		},

		CheckCoCessionLimit(value) {
			this.cocessionlimit.selected = value
		},
		DetailData () {
			let formdata = {
				subclass_id: this.form.tablelimit_subclassid,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListSubClassObjectInfo'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback 		 = response.data
				let checking 		 = false
				for (let i = 1; i <= 20; i ++) {
					let field_id    = `subclass_fieldid_` + i
					let field_label = `subclass_fieldlabel_` + i
					if (feedback[0][field_id] !== '') {
						this.$set(this.gendet_fieldid_value, i, feedback[0][field_id])
						this.$set(this.gendet_fieldlabel_value, i, feedback[0][field_label])
						if (feedback[0].subclass_fieldid_1 !== '') {
							checking = true
						}
					}
				}
				// this.DetailValue()
				this.attributes = checking
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
			})
		},
		DetailValue () {
			if (this.form.gendet_id !== '') {
				let formdata = {
						gendet_id: this.form.gendet_id,
						gendet_gentab_id: this.form.gentab_id,
				}
				let param 	   = this.$functions.ParamPOST(formdata)
				this.$axios.post(this.$functions.UrlPOST('apiListGenTabDetail'),param,{
			        headers: {
			            'Content-Type': 'text/plain; charset=ISO-8859-1'
			        }
				})
				.then(response => {
					let feedback = response.data
					if (feedback.length > 0) {
						for (let i = 1; i <= 20; i ++) {
							let value_id    = `gendet_fieldvalue_` + i
							let value_desc  = `gendet_fieldvaluedesc_` + i
							if (value_desc !== '') {
								this.$set(this.gentab_detailid_value, i, feedback[0][value_id])
								this.$set(this.gentab_detaildesc_value, i, feedback[0][value_desc])
							}
						}
					}
				})
				.catch(e => {
					this.SnackBar(true, 'error', e, 3000)
				})
			}
		},
		ClearDetailData () {
			for (let i = 1; i <= 20; i ++) {
				if (document.getElementById('gendet_fielddesc_' + i) !== null) {
				    this.$set(this.gentab_detailid_value, i, '')
				    this.$set(this.gentab_detaildesc_value, i, '')
				}
			}
		},
		ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
		//  end: Function Co Cession Limit
	},
};
</script>