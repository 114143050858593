<template>
  <div>
    <app-drawer_menu drawer_menu_title="Payment Request"></app-drawer_menu>
    <v-tabs background-color="white" color="fourth">
      <v-tab id="tabform" href="#tab-form">
        Form
      </v-tab>
      <v-tab href="#tab-list">
        List
      </v-tab>
      <v-tab-item value="tab-form">
        <v-form
          enctype="multipart/form-data"
          ref="form_paymentrequest"
          lazy-validation
        >
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <v-btn color="tertiary" class="white--text" rounded small>
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn>
              &nbsp;
              <v-btn color="primary" class="white--text" rounded small>
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="10" class="mb-n5">
              <v-card class="mb-12" outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="3" sm="3" md="4" class="mb-n5">
                      <v-text-field
                        label="Request No."
                        placeholder="Request No."
                        readonly="readonly"
                        background-color="readonly"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2" class="mb-n5">
                      <v-dialog
                        max-width="290px"
                        persistent
                        v-model="modal_bookingdate"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="booking_date"
                            prepend-inner-icon="event"
                            placeholder="Booking Date"
                            background-color="date"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="booking_date"
                          scrollable
                          actions
                        >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              @click="modal_bookingdate = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="modal_bookingdate = false"
                              >OK</v-btn
                            >
                          </v-card-actions>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="6" md="2" class="mb-n5">
                      <v-dialog
                        max-width="290px"
                        persistent
                        v-model="modal_refferencedate"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="refference_date"
                            prepend-inner-icon="event"
                            placeholder="Currency Date"
                            background-color="date"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="refference_date"
                          scrollable
                          actions
                        >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              @click="modal_refferencedate = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="modal_refferencedate = false"
                              >OK</v-btn
                            >
                          </v-card-actions>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <app-cb
                        cb_title="Voucher Type"
                        cb_rules="Please fill ID"
                        cb_desc_readonly="readonly"
                        :cb_value_id="form.paymentrequest_id"
                        :cb_value_desc="form.paymentrequest_desc"
                      >
                      </app-cb>
                    </v-col>
                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <v-text-field
                        label="Voucher No."
                        placeholder="Voucher No."
                        readonly="readonly"
                        background-color="readonly"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <v-text-field
                        label="Status"
                        placeholder="Status"
                        readonly="readonly"
                        background-color="readonly"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <app-cb
                        cb_title="Branch"
                        cb_rules="Please fill ID"
                        cb_desc_readonly="readonly"
                        :cb_value_id="form.paymentrequest_id"
                        :cb_value_desc="form.paymentrequest_desc"
                      >
                      </app-cb>
                    </v-col>
                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <v-text-field
                        label="Reference No."
                        placeholder="Reference No."
                        readonly
                        background-color="readonly"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <v-text-field
                        placeholder="0"
                        background-color="numeric"
                        v-money="format_money"
                        reverse
                        readonly
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <app-cb
                        cb_title="Profit Cost Center"
                        cb_rules="Please fill ID"
                        cb_desc_readonly="readonly"
                        :cb_value_id="form.paymentrequest_id"
                        :cb_value_desc="form.paymentrequest_desc"
                      >
                      </app-cb>
                    </v-col>
                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <v-text-field
                        label="Bank Account No."
                        placeholder="Bank Account No."
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <v-text-field
                        label="Bank Account Name."
                        placeholder="Bank Account Name."
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <app-cb
                        cb_title="Cash/Bank Account"
                        cb_rules="Please fill ID"
                        cb_desc_readonly="readonly"
                        :cb_value_id="form.paymentrequest_id"
                        :cb_value_desc="form.paymentrequest_desc"
                      >
                      </app-cb>
                    </v-col>

                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <v-text-field label="Bank Name." placeholder="Bank Name.">
                      </v-text-field>
                    </v-col>
                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <v-select
                        label="Reference type"
                        placeholder="Reference type"
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="4" sm="3" md="4" class="mb-n5">
                      <app-cb
                        cb_title="Payment To"
                        cb_rules="Please fill ID"
                        cb_desc_readonly="readonly"
                        :cb_value_id="form.paymentrequest_id"
                        :cb_value_desc="form.paymentrequest_desc"
                      >
                      </app-cb>
                    </v-col>

                    <v-col cols="12" sm="6" md="2" class="mb-n5">
                      <v-dialog
                        max-width="290px"
                        persistent
                        v-model="modal_refferencedate"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="refference_date"
                            prepend-inner-icon="event"
                            placeholder="Reference Date"
                            background-color="date"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="refference_date"
                          scrollable
                          actions
                        >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              @click="modal_refferencedate = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="modal_refferencedate = false"
                              >OK</v-btn
                            >
                          </v-card-actions>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>

                    <v-col cols="12" sm="6" md="2" class="mb-n5">
                      <v-dialog
                        max-width="290px"
                        persistent
                        v-model="modal_refferencedate"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="refference_date"
                            prepend-inner-icon="event"
                            placeholder="Due Date"
                            background-color="date"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="refference_date"
                          scrollable
                          actions
                        >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              @click="modal_refferencedate = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="modal_refferencedate = false"
                              >OK</v-btn
                            >
                          </v-card-actions>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4" sm="3" md="8" class="mb-n5">
                      <v-text-field label="Remarks" placeholder="Remarks">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-2">
                    <v-col cols="4" sm="3" md="4">
                      <v-checkbox
                        v-model="form.extdownload"
                        :label="`Ext Data Download`"
                        false-value="N"
                        true-value="Y"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4" sm="3" md="4">
                      <div>
                        <v-checkbox
                          v-model="cont"
                          label="Show Info"
                        ></v-checkbox>
                        <v-banner
                          v-model="cont"
                          single-line
                          transition="slide-y-transition"
                        >
                          <pre>
Created By	 :
Last Update By : 
Un/Verified By : 
Un/Approved By :
Close/Canceled By : 
                        </pre
                          >
                        </v-banner>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mb-n5">
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-6"
                      >
                        <v-icon left>cached</v-icon>
                        CLEAR
                      </v-btn>
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-6"
                      >
                        <v-icon left>print</v-icon>
                        PRINTSLIP
                      </v-btn>
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-6"
                      >
                        <v-icon left>approval</v-icon>
                        APPROVE
                      </v-btn>
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-6"
                      >
                        <v-icon left>close</v-icon>
                        UNAPPROVE
                      </v-btn>
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-6"
                      >
                        <v-icon left>pending_actions</v-icon>
                        REQUEST APPROVAL
                      </v-btn>
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-6"
                      >
                        <v-icon left>verified</v-icon>
                        VERIFY
                      </v-btn>
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-6"
                      >
                        <v-icon left>unpublished</v-icon>
                        UNVERIFY
                      </v-btn>
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-6"
                      >
                        <v-icon left>lock_open</v-icon>
                        OPEN
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mb-n5">
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-3"
                      >
                        <v-icon left>folder_delete</v-icon>
                        DROP
                      </v-btn>
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-3"
                      >
                        <v-icon left>file_upload</v-icon>
                        LOAD
                      </v-btn>
                      <v-btn
                        tile
                        color="theme--dark blue darken-1"
                        class="mr-3"
                      >
                        <v-icon left>file_download</v-icon>
                        DOWNLOAD
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="2" class="mb-n5">
              <v-card class="mb-4" outlined>
                <v-card-text>
                  <v-text-field
                    label="Currency."
                    placeholder="Currency."
                    readonly="readonly"
                    background-color="readonly"
                  >
                  </v-text-field>

                  <v-text-field
                    label="Nominal In C.C"
                    placeholder="0"
                    background-color="numeric"
                    v-money="format_money"
                    reverse
                    readonly
                  >
                  </v-text-field>

                  <v-text-field
                    label="Difference In C.C"
                    placeholder="0"
                    background-color="numeric"
                    v-money="format_money"
                    reverse
                    readonly
                  >
                  </v-text-field>

                  <v-text-field
                    label="Total In C.C"
                    placeholder="0"
                    background-color="numeric"
                    v-money="format_money"
                    reverse
                    readonly
                  >
                  </v-text-field>

                  <v-text-field
                    label="Booking Rate"
                    placeholder="0"
                    background-color="numeric"
                    v-money="format_money"
                    reverse
                    readonly
                  >
                  </v-text-field>

                  <v-text-field
                    label="Total In B.C"
                    placeholder="0"
                    background-color="numeric"
                    v-money="format_money"
                    reverse
                    readonly
                  >
                  </v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <v-btn-toggle>
                <v-btn small color="darkgrey" @click="addNewRow"
                  >Add More Details</v-btn
                >
              </v-btn-toggle>
              <v-btn-toggle>
                <v-btn small @click="OpenDocument">Document</v-btn>
              </v-btn-toggle>

              <v-card class="mb-12" outlined>
                <template>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left"></th>
                          <th class="text-left">
                            Document
                          </th>
                          <th class="text-left">
                            Account
                          </th>
                          <th class="text-left">
                            Branch
                          </th>
                          <th class="text-left">
                            Date
                          </th>
                          <th class="text-left">
                            Remarks
                          </th>
                          <th class="text-left">
                            Nominal In OC
                          </th>
                          <th class="text-left">
                            Rate
                          </th>
                          <th></th>
                          <th class="text-left">
                            Nominal In CC
                          </th>
                          <th class="text-left">
                            Hint
                          </th>
                          <th class="text-left">
                            Matching
                          </th>
                          <th class="text-left">
                            Preference
                          </th>
                          <th class="text-left"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <div v-if="!rowfields.length">
                          <p class="text-left red--text">
                            Not Found.
                          </p>
                        </div>
                        <tr v-for="(rowfield, k) in rowfields" :key="k">
                          <td>
                            <v-btn
                              color="red"
                              class="white--text"
                              rounded
                              small
                              @click="deleteRow(k, rowfield)"
                              ><v-icon dark>mdi-minus</v-icon></v-btn
                            >
                          </td>
                          <td>
                            <v-text-field
                              v-model="rowfield.document"
                            ></v-text-field>
                          </td>
                          <td>
                            <app-cb
                              v-model="rowfield.account"
                              cb_type="account"
                              cb_url="apiListAccount"
                              cb_url_parameter="account_actived=Y"
                              cb_title="Account"
                              cb_single="Y"
                              cb_rules="Please fill Account"
                              cb_desc_readonly="readonly"
                              :cb_value_id="form.paymentrequest_id"
                              :cb_value_desc="form.paymentrequest_desc"
                            >
                            </app-cb>
                          </td>
                          <td>
                            <app-cb
                              v-model="rowfield.branch"
                              cb_type="branch"
                              cb_url="apiListBranch"
                              cb_url_parameter="branch_actived=Y"
                              cb_title="Branch"
                              cb_single="Y"
                              cb_rules="Please fill Branch"
                              cb_desc_readonly="readonly"
                              :cb_value_id="form.paymentrequest_id"
                              :cb_value_desc="form.paymentrequest_desc"
                            >
                            </app-cb>
                          </td>
                          <td>
                            <v-dialog
                              max-width="290px"
                              persistent
                              v-model="modal_detailsdate"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="details_date"
                                  prepend-inner-icon="event"
                                  placeholder="Date"
                                  background-color="date"
                                  v-on="on"
                                  required
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="details_date"
                                scrollable
                                actions
                              >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    @click="modal_detailsdate = false"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="primary"
                                    @click="modal_detailsdate = false"
                                    >OK</v-btn
                                  >
                                </v-card-actions>
                              </v-date-picker>
                            </v-dialog>
                          </td>
                          <td>
                            <v-text-field
                              v-model="rowfield.remarks"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="rowfield.nominaloc"
                              placeholder="0"
                              background-color="numeric"
                              v-money="format_money"
                              reverse
                            >
                            </v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="rowfield.rate"
                              placeholder="0"
                              background-color="numeric"
                              v-money="format_money"
                              reverse
                            >
                            </v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="rowfield.rate"
                              placeholder="0"
                              background-color="numeric"
                              v-money="format_money"
                              reverse
                            >
                            </v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="rowfield.nominalcc"
                              placeholder="0"
                              background-color="numeric"
                              v-money="format_money"
                              reverse
                            >
                            </v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="rowfield.hint"
                              placeholder="0"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-checkbox
                              v-model="rowfield.matching"
                              false-value="N"
                              true-value="Y"
                            ></v-checkbox>
                          </td>
                          <td>
                            <v-text-field
                              v-model="rowfield.preference"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-btn color="default" rounded small
                              >follow this rate</v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </v-form>

        <!-- Dialog Get Document -->
        <v-dialog
          v-model="dialog.document"
          scrollable
          transition="dialog-bottom-transition"
          persistent
          fullscreen
        >
          <v-card>
            <v-toolbar dark color="primary" dense class="mb-2">
              <v-btn icon dark @click="dialog.document = false">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Get Document</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn icon dark title="Add"
                ><v-icon color="white">add</v-icon></v-btn
              >
              <v-btn icon dark title="Save"
                ><v-icon color="white">save</v-icon></v-btn
              >

              <v-btn icon dark title="Clear" @click="clearFormGetDocument"
                ><v-icon color="white">cached</v-icon></v-btn
              >

              <v-btn icon dark title="Retrieve"
                ><v-icon color="white">wysiwyg</v-icon></v-btn
              >

              <v-btn icon dark title="Load"
                ><v-icon color="white">file_upload</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <!-- Form Get Document-->
              <v-form
                enctype="multipart/form-data"
                ref="FormGetDocument"
                lazy-validation
              >
                <v-row class="mx-2 mt-2">
                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-row>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-select label="Query type" placeholder="CN/DN">
                        </v-select>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_type="profile"
                          cb_url="apiListProfile"
                          cb_url_parameter="profile_actived=Y"
                          cb_title="Profile Name"
                          cb_id="receiptrequest_doc_profile"
                          cb_desc="receiptrequest_doc_profiledesc"
                          cb_rules="Please fill Profile Name"
                          cb_desc_readonly="readonly"
                          cb_items_id="profile_id"
                          cb_items_desc="profile_name"
                          :cb_value_id="formdocument.receiptrequest_doc_profile"
                          :cb_value_desc="
                            formdocument.receiptrequest_doc_profiledesc
                          "
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.document_id"
                          required
                          :rules="form.document_id_rules"
                          :readonly="flag.document"
                          :counter="maxinput.id"
                          :maxlength="maxinput.id"
                          label="Profile Ref ID"
                          placeholder="Profile Ref ID"
                          id="document_id"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Document No"
                          cb_rules="Please Fill Document No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Refference No"
                          cb_rules="Please Fill Refference No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Bussines Source"
                          cb_rules="Please Fill Bussines Source"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Company Group"
                          cb_rules="Please Fill Company Group"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_type="branch"
                          cb_url="apiListBranch"
                          cb_url_parameter="branch_actived=Y"
                          cb_title="Branch"
                          cb_id="receiptrequest_doc_branch"
                          cb_desc="receiptrequest_doc_branchdesc"
                          cb_rules="Please fill Branch"
                          cb_desc_readonly="readonly"
                          cb_items_id="branch_id"
                          cb_items_desc="branch_desc"
                          :cb_value_id="formdocument.receiptrequest_doc_branch"
                          :cb_value_desc="
                            formdocument.receiptrequest_doc_branchdesc
                          "
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.account_no"
                          required
                          label="Account No"
                          placeholder="Account No"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.account_no"
                          required
                          label="Account No"
                          placeholder="Account No"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.document_no_first"
                          required
                          label="Original Doc No"
                          placeholder="Original Doc No"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.document_no_second"
                          required
                          label="Original Doc No"
                          placeholder="Original Doc No"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          mask="date"
                          type="date"
                          background-color="date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          mask="date"
                          type="date"
                          background-color="date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="3" sm="6" md="6" class="mb-n5">
                        <v-text-field label="Remarks" placeholder="Remarks">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-row>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Document No"
                          cb_rules="Please Fill Document No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Voucher No"
                          cb_rules="Please Fill Voucher No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Voucher No"
                          cb_rules="Please Fill Voucher No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Refference No"
                          cb_rules="Please Fill Refference No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Collection No"
                          cb_rules="Please Fill Collection No"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_type="currency"
                          cb_url="apiListCurrency"
                          cb_url_parameter="currency_actived=Y"
                          cb_title="Currency"
                          cb_id="receiptrequest_doc_currency"
                          cb_desc="receiptrequest_doc_currencydesc"
                          cb_rules="Please fill Currency"
                          cb_desc_readonly="readonly"
                          cb_items_id="currency_id"
                          cb_items_desc="currency_desc"
                          :cb_value_id="
                            formdocument.receiptrequest_doc_currency
                          "
                          :cb_value_desc="
                            formdocument.receiptrequest_doc_currencydesc
                          "
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Suspend/Open Account"
                          cb_rules="Please Fill"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field label="Batch No" placeholder="Batch No">
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          label="Bussines Sourec Ref ID"
                          placeholder="Bussines Sourec Ref ID"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          label="Application Source"
                          placeholder="Application Source"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          mask="date"
                          type="date"
                          background-color="date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          mask="date"
                          type="date"
                          background-color="date"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6" md="6" class="mb-n5">
                        <v-select
                          label="Reference type"
                          placeholder="Reference type"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="6" sm="6" md="6" class="mb-n5">
                        <v-select label="Sort By" placeholder="(NONE)">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Not In Collection`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Not In Request`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Paid Policy`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Pair Data`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-select
                      label="Condition"
                      placeholder="Not In Policy Cancellation"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Exclude Tax`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="8" sm="8" md="8" class="mb-n5">
                    <v-text-field
                      label="Total Amount Selected"
                      placeholder="0"
                      background-color="numeric"
                      v-money="format_money"
                      reverse
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" sm="4" md="4" class="mb-n5">
                    <v-text-field
                      placeholder="0"
                      background-color="numeric"
                      v-money="format_money"
                      reverse
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="10" sm="10" md="10" class="mb-6 mt-6">
                    <v-btn-toggle>
                      <v-btn small color="darkgrey">select all</v-btn>
                    </v-btn-toggle>
                    <v-btn-toggle>
                      <v-btn small color="darkgrey">deselect all</v-btn>
                    </v-btn-toggle>
                    <!-- Table On Dialog Document -->
                    <v-data-table
                      :headers="tbldocument"
                      :items-per-page="5"
                      class="elevation-1"
                      loading
                    >
                      <template> </template>
                    </v-data-table>
                    <!-- End Table -->
                  </v-col>
                  <v-col cols="2" sm="2" md="2" class="mb-10 mt-12">
                    <v-card outlined>
                      <p
                        class="text-center font-weight-medium"
                        style="background-color:#ff5f0a"
                      >
                        SELECTED DATA
                      </p>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" align="center">
                          <v-btn elevation="2">REMOVE</v-btn>
                          <v-btn elevation="2">CLEAR ALL</v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
              <!-- End Form Document-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- End Dialog-->
      </v-tab-item>
      <v-tab-item value="tab-list"> </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    ready: false,
    rowfields: [
      {
        document: "",
        account: "",
        branch: "",
        details_date: "",
        remarks: "",
        nominaloc: "",
        nominalcc: "",
        rate: "",
        hint: "",
        matching: "",
        preference: "",
      },
    ],
    tbldocument: [
      {
        text: "voucher",
        sortable: false,
        value: "voucher",
        width: "300px",
      },
      {
        text: "voucher type",
        value: "voucher_type",
        width: "200px",
      },

      {
        text: "Doc.No",
        value: "doc_no",
        width: "300px",
      },

      {
        text: "Ref.No",
        value: "ref_no",
        width: "300px",
      },
      {
        text: "Ref.Date",
        value: "ref_date",
        width: "200px",
      },

      {
        text: "ProAcc",
        value: "pro_acc",
        width: "300px",
      },
      {
        text: "Name",
        value: "name",
        width: "300px",
      },
      {
        text: "Remarks",
        value: "remarks",
        width: "300px",
      },
    ],
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 10,
      desc: 150,
    },

    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    flag: {
      document: false,
    },
    dialog: {
      document: false,
    },
    modal_bookingdate: false,
    booking_date: null,
    modal_refferencedate: false,
    refference_date: null,
    modal_detailsdate: false,
    details_date: null,
    remarks: {
      feedback: "",
      dialog: false,
    },
    loading: {
      page: true,
      list: false,
    },
    format_money: {
      decimal: ",",
      thousands: ".",
      precision: 0,
    },
    btn_search: false,
    form: {
      paymentrequest_id_rules: [(v) => !!v || "Please Fill ID"],
      paymentrequest_id: "",
      paymentrequest_desc: "",
    },

    formdocument: {
      receiptrequest_doc_profile: "",
      receiptrequest_doc_currency: "",
      receiptrequest_doc_branch: "",
    },
  }),
  methods: {
    deleteRow(index, rowfield) {
      var idx = this.rowfields.indexOf(rowfield);
      console.log(idx, index);
      if (idx > -1) {
        this.rowfields.splice(idx, 1);
      }
    },
    addNewRow() {
      for (let i = 1; i <= 10; i++) {
        this.rowfields.push({
          document: "",
          account: "",
          branch: "",
          details_date: "",
          remarks: "",
          nominaloc: "",
          rate: "",
          nominalcc: "",
          hint: "",
          matching: "",
          preference: "",
        });
      }
      this.ready = true;
    },
    OpenDocument() {
      this.dialog.document = true;
    },
    clearFormGetDocument() {
      this.$refs.FormGetDocument.reset();
    },
  },
};
</script>
