<template>
  <div>
    <app-drawer_menu drawer_menu_title="Company Account"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs background-color="primary" color="white" v-if="access.read === 1">
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad">
        Form
      </v-tab>
      <v-tab href="#tab-list" @click="List">
        List
      </v-tab>
      <v-tab-item value="tab-form">
        <v-form
          enctype="multipart/form-data"
          ref="form_compacc"
          lazy-validation
        >
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>
            <!-- ID -->
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="form.compacc_id"
                required
                :rules="form.compac_id_rules"
                :readonly="form.compacc_id_readonly"
                :background-color="form.bank_id_readonly ? 'readonly' : ''"
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID *"
                placeholder="ID"
                id="compacc_id"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Desc -->
            <v-col cols="12" sm="6" md="4" class="mt-n3">
              <v-text-field
                v-model="form.compacc_desc"
                required
                :rules="form.compacc_desc_rules"
                :maxlength="maxinput.desc"
                label="Description *"
                placeholder="Description"
                id="compacc_desc"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <app-cb
                cb_type="account"
                cb_url="apiListAccount"
                cb_url_parameter="account_actived=Y"
                cb_title="Account"
                cb_id="compacc_account"
                cb_desc="compacc_accountdesc"
                cb_rules="Please fill Account"
                cb_desc_readonly="readonly"
                cb_items_id="account_id"
                cb_items_desc="account_desc"
                :cb_value_id="form.compacc_account"
                :cb_value_desc="form.compacc_accountdesc"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="form.compacc_accountgroup"
                required
                label="Account Group*"
                placeholder="Account Group"
                id="compacc_accountgroup"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <app-cb
                cb_type="currency"
                cb_url="apiListCurrency"
                cb_url_parameter="currency_actived=Y"
                cb_title="Currency"
                cb_id="compacc_currency"
                cb_desc="compacc_currencydesc"
                cb_rules="Please fill Currency"
                cb_desc_readonly="readonly"
                cb_items_id="currency_id"
                cb_items_desc="currency_desc"
                :cb_value_id="form.compacc_currency"
                :cb_value_desc="form.compacc_currencydesc"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <app-cb
                cb_type="profile"
                cb_url="apiListProfile"
                cb_url_parameter="profile_actived=Y"
                cb_title="Owner"
                cb_id="compacc_owner"
                cb_desc="compacc_ownerdesc"
                cb_rules="Please fill Owner"
                cb_desc_readonly="readonly"
                cb_items_id="profile_id"
                cb_items_desc="profile_name"
                :cb_value_id="form.compacc_owner"
                :cb_value_desc="form.compacc_ownerdesc"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <app-cb
                cb_type="branch"
                cb_url="apiListBranch"
                cb_url_parameter="branch_actived=Y"
                cb_title="Branch"
                cb_id="compacc_branch"
                cb_desc="compacc_branchdesc"
                cb_rules="Please fill Branch"
                cb_desc_readonly="readonly"
                cb_items_id="branch_id"
                cb_items_desc="branch_desc"
                :cb_value_id="form.compacc_branch"
                :cb_value_desc="form.compacc_branchdesc"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <app-cb
                cb_type="bank"
                cb_url="apiListBank"
                cb_url_parameter="bank_actived=Y"
                cb_title="Bank"
                cb_id="compacc_bankid"
                cb_desc="compacc_bankiddesc"
                cb_rules="Please fill Bank"
                cb_desc_readonly="readonly"
                cb_items_id="bank_id"
                cb_items_desc="bank_desc"
                :cb_value_id="form.compacc_bankid"
                :cb_value_desc="form.compacc_bankiddesc"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="form.compacc_name"
                required
                label="name*"
                placeholder="name"
                id="compacc_name"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Active -->
            <v-col cols="12" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.compacc_actived"
                color="primary"
                id="form.compacc_actived"
                :label="form.compacc_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                v-model="form.compacc_bankflag"
                :label="`Bank`"
                false-value="N"
                true-value="Y"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                v-model="form.compacc_cashflag"
                :label="`Cash`"
                false-value="N"
                true-value="Y"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                v-model="form.compacc_interflag"
                :label="`Inter`"
                false-value="N"
                true-value="Y"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                v-model="form.compacc_extflag"
                :label="`Ext`"
                false-value="N"
                true-value="Y"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                v-model="form.compacc_printslip"
                :label="`Printslip`"
                false-value="N"
                true-value="Y"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                <v-banner
                  v-model="cont"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{ form.compacc_cdate }}
Created User 	 : {{ form.compacc_cuser }}
Last Update Date : {{ form.compacc_ldate }}
Last Update User : {{ form.compacc_luser }}
</pre
                  >
                </v-banner>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item value="tab-list">
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
              >{{$vars.V('txt_select_all_page')}}
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="compacc_id"
            show-select
            class="elevation-1"
             :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.compacc_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                mouse
              </v-icon>|
              <v-icon
                small
                @click="Confirm('delete', item.compacc_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999;"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 10,
      desc: 150,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        value: "compacc_id",
      },
      { text: "Description", value: "compacc_desc" },
      { text: "Currency", value: "compacc_currency" },
      { text: "Actived", value: "compacc_actived" },
      { text: "Actions", value: "action", sortable: false },
    ],
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    accessmenu: [],
    loading: {
      page: true,
      list: false,
    },
    btn_search: false,
    form: {
      compacc_id: "",
      compacc_id_rules: [(v) => !!v || "Please fill ID"],
      compacc_id_readonly: false,
      compacc_desc: "",

      compacc_desc_rules: [(v) => !!v || "Please fill Description"],
      compacc_account: "",
      compacc_accountdesc: "",
      compacc_accountgroup: "",
      compacc_currency: "",
      compacc_cashflag: "",
      compacc_bankflag: "",
      compacc_interflag: "",
      compacc_branch: "",
      compacc_extflag: "",
      compacc_owner: "",
      compacc_printslip: "",
      compacc_name: "",
      compacc_bankid: "",
      compacc_actived: true,
    },
  }),
  created() {
    this.FirstLoad();
  },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access();
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "compacc";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            if (priv[0].feedback !== "Y") {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = priv[0].feedback;
            } else {
              this.access.read = priv[0].usersmenu_read;
              this.access.add = priv[0].usersmenu_add;
              this.access.edit = priv[0].usersmenu_edit;
              this.access.delete = priv[0].usersmenu_delete;
              if (priv[0].usersmenu_read === 0) {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.NoPriviledge(modul),
                  0
                );
              }
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    //Function for get all data from table compacc with API in mod_compacc.go
    List() {
      this.loading.list = true;
      let formdata = {
        compacc_id: "",
        compacc_desc: "",
        order_by: "compacc_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCompacc"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.list = false;
        });
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id) {
      if (flag === "write") {
        if (this.$refs.form_compacc.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function() {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.compacc_id = id;
        setTimeout(function() {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function() {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    //Function to request insert data to table compacc 'The API will validating your data, include the user who process the data' (go to APIWriteCompacc with url_type = "add")
    Add() {
      this.ClearCB();
      this.url_type = "add";
      this.form.compacc_id = "";
      this.form.compacc_desc = "";
      this.form.compacc_account = "";
      this.form.compacc_accountdesc = "";
      this.form.compacc_accountgroup = "";
      this.form.compacc_currency = "";
      this.form.compacc_currencydesc = "";
      this.form.compacc_cashflag = "";
      this.form.compacc_bankflag = "";
      this.form.compacc_interflag = "";
      this.form.compacc_branch = "";
      this.form.compacc_branchdesc = "";
      this.form.compacc_extflag = "";
      this.form.compacc_actived = true;
      this.form.compacc_owner = "";
      this.form.compacc_ownerdesc = "";
      this.form.compacc_printslip = "";
      this.form.compacc_name = "";
      this.form.compacc_bankid = "";
      this.form.compacc_bankiddesc = "";
      this.form.compacc_id_readonly = false;
      setTimeout(function() {
        document.getElementById("compacc_id").focus();
      }, 500);
    },
    //Function to request update data to table compacc 'The API will validating your data, include the user who process the data' (go to APIWritecompacc with url_type = "edit")
    Edit(id) {
      this.loading.list = true;
      this.ClearCB();
      let formdata = {
        compacc_id: id,
        compacc_desc: "",
        order_by: "compacc_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCompacc"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click();
              this.url_type = "edit";
              this.form.compacc_id = feedback[0].compacc_id;
              this.form.compacc_desc = feedback[0].compacc_desc;
              this.form.compacc_account = feedback[0].compacc_account;
              this.form.compacc_accountdesc = feedback[0].compacc_accountdesc;
              this.form.compacc_accountgroup = feedback[0].compacc_accountgroup;
              this.form.compacc_currency = feedback[0].compacc_currency;
              this.form.compacc_currencydesc = feedback[0].compacc_currencydesc;
              this.form.compacc_cashflag = feedback[0].compacc_cashflag;
              this.form.compacc_bankflag = feedback[0].compacc_bankflag;
              this.form.compacc_interflag = feedback[0].compacc_interflag;

              this.form.compacc_branch = feedback[0].compacc_branch;
              this.form.compacc_branchdesc = feedback[0].compacc_branchdesc;
              this.form.compacc_extflag = feedback[0].compacc_extflag;

              this.form.compacc_actived = this.$functions.TrueOrFalse(
                feedback[0].compacc_actived
              );
              this.form.compacc_owner = feedback[0].compacc_owner;
              this.form.compacc_ownerdesc = feedback[0].compacc_ownerdesc;
              this.form.compacc_printslip = feedback[0].compacc_printslip;

              this.form.compacc_name = feedback[0].compacc_name;
              this.form.compacc_bankid = feedback[0].compacc_bankid;
              this.form.compacc_bankiddesc = feedback[0].compacc_bankiddesc;
              this.form.compacc_cdate = feedback[0].compacc_cdate;
              this.form.compacc_cuser = feedback[0].compacc_cuser;
              this.form.compacc_ldate = feedback[0].compacc_ldate;
              this.form.compacc_luser = feedback[0].compacc_luser;
              this.form.compacc_id_readonly = true;
              setTimeout(function() {
                document.getElementById("compacc_desc").focus();
              }, 500);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.list = false;
        });
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        compacc_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCompacc"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.List();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCompacc"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.List();
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.compacc_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      }
    },
    Write() {
      let compaccaccount = document.getElementById("compacc_account").value;
      let compacccurrency = document.getElementById("compacc_currency").value;
      let compaccowner = document.getElementById("compacc_owner").value;
      let compaccbranch = document.getElementById("compacc_branch").value;
      let compaccbankid = document.getElementById("compacc_bankid").value;
      let formdata = {
        url_type: this.url_type,
        compacc_id: this.form.compacc_id,
        compacc_desc: this.form.compacc_desc,
        compacc_account: compaccaccount,
        compacc_accountgroup: this.form.compacc_accountgroup,
        compacc_currency: compacccurrency,
        compacc_cashflag: this.form.compacc_cashflag,
        compacc_bankflag: this.form.compacc_bankflag,
        compacc_interflag: this.form.compacc_interflag,
        compacc_branch: compaccbranch,
        compacc_extflag: this.form.compacc_extflag,
        compacc_actived: this.$functions.ActivedFlag(this.form.compacc_actived),
        compacc_owner: compaccowner,
        compacc_printslip: this.form.compacc_printslip,
        compacc_name: this.form.compacc_name,
        compacc_bankid: compaccbankid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCompacc"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.compacc_cuser = feedback[0].feedback_users_id;
                this.form.compacc_cdate = feedback[0].feedback_users_date;
              }
              this.form.compacc_luser = feedback[0].feedback_users_id;
              this.form.compacc_ldate = feedback[0].feedback_users_date;
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    ClearCB() {
      this.form.compacc_account = "";
      this.form.compacc_accountdesc = "";
    },
  },
};
</script>
