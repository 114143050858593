<template>
    <v-dialog
    v-model="dialogusers"
    max-width="290"
    persistent
    transition="dialog-bottom-transition"
    >
    <template>
    <v-card
        class="mx-auto"
        max-width="290"
    >
        <v-img
        :src="form.avatarurl"
        height="100%"
        cover
        ></v-img>

        <v-card-title>
            {{form.name}}
        </v-card-title>

        <v-card-subtitle>
            {{form.title}}
            <div class="font-weight-black">{{form.id}}</div>
        </v-card-subtitle>
        
        <v-card-actions>
        <v-btn
            color="fourth"
            text
            @click.stop =CloseDialog()
        >
            Close
        </v-btn>

        <v-spacer></v-spacer>
        
        </v-card-actions>
    </v-card>
    </template>
    </v-dialog>
</template>
<script>
   export default {
       data: () => ({
            form: {
                id: '',
                name: '',
                title: '',
                avatarurl: '',
            },
            modal: {
               users: false
           },
        }),
        props: [
                'visible',
                'users_id',
        ],
        watch: {
            users_id: function (newdata) {
               if (newdata !== '') {
                   this.CleanData()
                   this.Detail(newdata)
               }
           },
        },
        computed: {
            dialogusers: {
                get() {
                    return this.visible
                },
                set(value) {
                    if (!value) {
                        this.$emit('close')
                    }
                }
            }
        },
        methods: {
            Detail (id) {
               let formdata = {
                   users_id: id,
               }
               let param      = this.$functions.ParamPOST(formdata)
               this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
                   headers: {
                       'Content-Type': 'text/plain; charset=ISO-8859-1'
                   }
               })
               .then(response => {
                let feedback    = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                        this.form.id = feedback[0].users_id
                        this.form.name = feedback[0].users_name
                        this.form.title = feedback[0].users_title
                        this.form.avatarurl = feedback[0].users_avatar_url
                    }
                } else {
                    this.form.name = ''
                }
               })
               .catch(e => {
                   alert(e)
               })
           },
           CleanData () {
              this.form.id = ''
              this.form.name = ''
              this.form.avatarurl = ''
           },
           CloseDialog () {
                this.dialogusers = false
           }
        },
    }
</script>