<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Deductible"></app-drawer_menu>
	  	<v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
	  	<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if = "access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_deductible" lazy-validation>
			<v-row class="mx-2 ma-1">
				<v-col cols="12" sm="12" md="12">
					<v-btn
				      color		="tertiary"
				      class		="white--text"
				      :disabled = "access.add === 0 ? true:false" 
				      rounded
				      @click 	= "Add" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
				    </v-btn> &nbsp;
				    <v-btn
				      color		="primary"
				      class		="white--text"
				      :disabled = "access.add === 0 || access.edit === 0 ? true:false"
				      rounded
				      @click 	= "Confirm('write','')" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
				    </v-btn>
				</v-col>
              <!-- ID -->
               <v-col cols="12" sm="2" md="2">
                  <v-text-field
                  v-model 	  	= "form.deductible_id"
                  required
                  :rules 	  	="form.deductible_id_rules"
                  :readonly     = "form.deductible_id_readonly"
                  :background-color = "form.deductible_id_readonly ? 'readonly' : ''"
				  :maxlength 	="maxinput.id"
				  :counter		="maxinput.id"
                  label       	= "ID *"
                  placeholder 	= "ID"
                  id 			= "deductible_id"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- TSI/SI -->
               <v-col cols="12" sm="3" md="3">
                  <v-text-field
                  v-model.lazy 	= "form.deductible_tsisipct"
                  v-money       = "format_money"
                  label       	= "Deductible (%)*"
                  placeholder 	= "%"
                  :maxlength 	="maxinput.tsisipct"
				  :counter		="maxinput.tsisipct"
                  id 			= "deductible_tsisipct"
                  ref 			= "deductible_tsisipct"
                  background-color ="numeric"
                  reverse
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2">
                  <v-select
                  v-model 	  	   = "form.deductible_tsisiflag"
                  :items           = "rows.deductible_tsisiflag"
                  :item-text       = "rows.deductible_tsisiflag.text"
                  :item-value      = "rows.deductible_tsisiflag.value"
                  label       	   = "Of"
                  placeholder 	   = "Of"
                  id 			   = "deductible_tsisiflag"
                  @keyup.enter 	   = "Confirm('write','')"
                  >
                  </v-select>
               </v-col>
               <v-col cols="12" sm="3" md="3">
                  <v-text-field
                  v-model.lazy 	= "form.deductible_claimpct"
                  v-money       = "format_money"
                  label       	= "Or Of Claim (%)*"
                  placeholder 	= "%"
                  :maxlength 	="maxinput.claimpct"
				  :counter		="maxinput.claimpct"
                  id 			= "deductible_claimpct"
                  ref 			= "deductible_claimpct"
                  background-color ="numeric"
                  reverse
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="1" md="1" class="mt-n5">
					<label class="caption">Total Loss</label>
					<v-switch
						v-model	= "form.deductible_totallossflag"
						color  	= "primary"
						id 		= "form.deductible_totallossflag"
					></v-switch>
				</v-col>
               <!-- Active -->
               <v-col cols="12" sm="1" md="1" class="mt-n5">
					<label class="caption">Active Status</label>
					<v-switch
						v-model	= "form.deductible_actived"
						color  	= "primary"
						id 		= "form.deductible_actived"
					></v-switch>
				</v-col>
               <!-- With Flag -->
               <v-col cols="12" sm="2" md="2">
					<v-switch
						v-model	="deductible_withflag"
						color  	= "primary"
						id 		= "deductible_withflag"
						label ="With Flag"
					></v-switch>
				</v-col>
				<!-- Currency -->
				<v-col cols="12" sm="2" md="2">
	                <app-cb
	                cb_type           	= "currency"
	                cb_url            	= "apiListCurrency"
	                cb_url_parameter  	= "currency_actived=Y"
	                cb_title          	= "In CCY"
	                cb_id             	= "deductible_ccy"
	                cb_desc          	= "deductible_ccydesc"
	                cb_rules          	= ""
	                cb_desc_readonly  	= "readonly"
	                cb_items_id       	= "currency_id"
	                cb_items_desc     	= "currency_desc"
	                :cb_value_id        = "form.deductible_ccy"
	                :cb_value_desc      = "form.deductible_ccydesc"
	                cb_single 			= "Y"
	                >
	                </app-cb>
            	</v-col>
            	<!-- Currency -->
				<v-col cols="12" sm="2" md="2">
	                <app-cb
	                cb_type           	= "indemnity"
	                cb_url            	= "apiListIndemnity"
	                cb_url_parameter  	= "indemnity_actived=Y"
	                cb_title          	= "For"
	                cb_id             	= "deductible_forindemnity"
	                cb_desc          	= "deductible_forindemnitydesc"
	                cb_rules          	= ""
	                cb_desc_readonly  	= "readonly"
	                cb_items_id       	= "indemnity_id"
	                cb_items_desc     	= "indemnity_desc"
	                :cb_value_id        = "form.deductible_forindemnity"
	                :cb_value_desc      = "form.deductible_forindemnitydesc"
	                cb_single 			= "Y"
	                >
	                </app-cb>
            	</v-col>
            	<v-col cols="12" sm="3" md="3" class="mt-n3">
                  <v-text-field
                  v-model.lazy 	= "form.deductible_amountmin"
                  v-money		= "format_money"
                  label       	= "Amount Minimum"
                  placeholder 	= "Min"
                  id 			= "deductible_amountmin"
                  ref 			= "deductible_amountmin"
                  background-color ="numeric"
                  reverse
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="3" md="3" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.deductible_amountmax"
                  v-money		= "format_money"
                  label       	= "Amount Maximum"
                  placeholder 	= "Max"
                  id 			= "deductible_amountmax"
                  ref 			= "deductible_amountmax"
                  background-color ="numeric"
                  reverse
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- Remarks English -->
               <v-col cols="12" sm="6" md="6">
                  <v-textarea
                  v-model 	  	= "form.deductible_remarkseng"
                  required
                  :rules        = "form.deductible_remarkseng_rules"
				  :maxlength 	= "maxinput.desc"
                  label       	= "Remarks (E) *"
                  placeholder 	= "Remarks (E)"
                  id 			= "deductible_remarkseng"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-textarea>
               </v-col>
               <!-- Remarks English -->
               <v-col cols="12" sm="6" md="6">
                  <v-textarea
                  v-model 	  	= "form.deductible_remarksind"
                  required
                  :rules        = "form.deductible_remarksind_rules"
				  :maxlength 	= "maxinput.desc"
                  label       	= "Remarks (I) *"
                  placeholder 	= "Remarks (I)"
                  id 			= "deductible_remarksind"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-textarea>
               </v-col>
            </v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
			   		<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.deductible_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.deductible_cuser)">{{form.deductible_cuser}}</span>
Last Update Date : {{form.deductible_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.deductible_luser)">{{form.deductible_luser}}</span>
</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  <v-card-title 
			  		class="ml-n4"
			  	>
			  		<v-btn 
					  	:color 	="selected.length === items.length ? 'secondary':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	title	="select all page"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
					</v-btn>
	                <v-btn 
	                	color     = "error"
	                	title 	  = "delete selected"
	                	@click 	  = "Confirm('multidelete','')"
	                	class	  = "mr-2"
	                	v-if  	  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false"
	                	small>
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-btn 
	                	color  = "primary"
	                	title  = "search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true"
	                	small>
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    :value="selected"
			    :headers="headers"
			    :items="items"
			    :search="search"
			    @input="Check($event)"
			    item-key="deductible_id"
			    show-select
			    class="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			    dense
			    :loading 	= "loading.list"
    			loading-text="Loading... Please wait"
			  >
			  	<template v-slot:item.deductible_tsisipct="{ item }">
			    	{{ FormatNumber(item.deductible_tsisipct,4) }}
			    </template>
			    <template v-slot:item.deductible_claimpct="{ item }">
			    	{{ FormatNumber(item.deductible_claimpct,4) }}
			    </template>
			    <template v-slot:item.deductible_amountmin="{ item }">
			    	{{ FormatNumber(item.deductible_amountmin,4) }}
			    </template>
			    <template v-slot:item.deductible_amountmax="{ item }">
			    	{{ FormatNumber(item.deductible_amountmax,4) }}
			    </template>
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class	  ="mr-2"
			        @click	  ="Edit(item.deductible_id)"
			        :disabled = "access.edit === 0 ? true:false"
			        title 	  = "edit"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click	  ="Confirm('delete',item.deductible_id)"
			        :disabled = "access.delete === 0 ? true:false"
			        title 	  = "delete"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
		</v-tab-item>
  	</v-tabs>
	<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      top
      style 	= "z-index: 9999"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
  </v-snackbar>
    <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
	<!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>
<script>
export default {
	data: () => ({
		url_type: "add",
		cont: false,
		access: {
			read: 0,
			add: 0,
			edit: 0,
			delete: 0,
		},
		maxinput: {
			id: 10,
			desc: 8000,
		},
		snackbar: {
			dialog: false,
			color: "success",
			text: "",
			timeout: 3000,
		},
		confirm: {
			dialog: false,
			title: "",
			text: "Ok",
		},
		selected: [],
		search: "",
		headers: [
			{
				text: "ID",
				align: "left",
				value: "deductible_id",
			},
			{ text: "TSI %", value: "deductible_tsisipct" },
			{ text: "Claim %", value: "deductible_claimpct" },
			{ text: "Minimum", value: "deductible_amountmin" },
			{ text: "Maximum", value: "deductible_amountmax" },
			{ text: "Remarks", value: "deductible_remarkseng" },
			{ text: "Actived", value: "deductible_actived" },
			{ text: "Actions", value: "action", sortable: false },
		],
		remarks: {
			feedback: "",
			dialog: false,
		},
		items: [],
		accessmenu: [],
		loading: {
			page: true,
			list: false,
		},
		deductible_withflag: false,
		btn_search: false,
		form: {
			deductible_id: "",
			deductible_id_rules: [(v) => !!v || "Please fill ID"],
			deductible_id_readonly: false,
			deductible_claimpct: 0,
			deductible_tsisipct: 0,
			deductible_tsisiflag: "",
			deductible_amountmin: 0,
			deductible_amountmax: 0,
			deductible_remarkseng: "",
			deductible_remarkseng_rules: [(v) => !!v || "Please fill English"],
			deductible_remarksind: "",
			deductible_remarksind_rules: [(v) => !!v || "Please fill Indonesia"],
			deductible_totallossflag: false,
			deductible_actived: true,
			display_users: '',
		},
		dialog: {
			users: false
		},
		format_money: {
            decimal: '.',
            thousands: ',',
            precision: 4
        },
		rows: {
			deductible_tsisiflag:[
				{text: 'SI', value: 'SI'},
            	{text: 'TSI', value: 'TSI'},
			]
		},
	}),
	watch: {
		deductible_withflag(newvalue) {
			console.log(newvalue)
		}
	},
	created() {
		this.FirstLoad()
	},
	methods: {
		FirstLoad() {
			//this function use for validating the screen with user privilleges
			this.Access()
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "deductible"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let priv = response.data
					if (priv.length > 0) {
						this.access.read = priv[0].usersmenu_read
						this.access.add = priv[0].usersmenu_add
						this.access.edit = priv[0].usersmenu_edit
						this.access.delete = priv[0].usersmenu_delete
						if (priv[0].usersmenu_read === 0) {
							this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
						}
					} else {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					}
					this.loading.page = false
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 0)
					this.loading.page = false
				})
		},
		SelectAllPage() {
			this.selected = this.selected === this.items ? [] : this.items
		},
		Check(value) {
			this.selected = value
		},
		//Function for get all data from table Deductible with API in mod_users.go
		List() {
			this.loading.list = true
			let formdata = {
				deductible_id: "",
				deductible_remarkseng: "",
				order_by: "deductible_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListDeductible"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback !== "Y") {
							this.SnackBar(
								true,
								"error",
								feedback[0].feedback,
								0
							)
						} else {
							this.items = feedback
						}
					} else {
						this.items = feedback
						this.SnackBar(true, 'error', this.$functions.NoData(), 0)
					}
					this.loading.list = false
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 3000)
					this.loading.list = false
				})
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
		Confirm(flag, id) {
			if (flag === "write") {
				if (this.$refs.form_deductible.validate()) {
					this.confirm.dialog = true
					this.confirm.title = "Save"
					setTimeout(function() {
						document.getElementById("dialog").focus()
					}, 500)
				}
			} else if (flag === "delete") {
				this.url_type = "delete"
				this.confirm.dialog = true
				this.confirm.title = "Delete `" + id + "`"
				this.deductible_id = id
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag === "multidelete") {
				this.url_type = "multidelete"
				this.confirm.dialog = true
				this.confirm.title =
					"Delete `" + this.selected.length + "` data"
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			}
		},

		//Function to request insert data to table Deductible 'The API will validating your data, include the user who process the data' (go to APIWriteDeductible with url_type = "add")
		Add() {
			this.url_type = "add"
			this.form.deductible_id = ""
			this.form.deductible_remarkseng = ""
			this.form.deductible_actived = true
			this.form.deductible_id_readonly = false
			setTimeout(function() {
				document.getElementById("deductible_id").focus()
			}, 500)
		},
		//Function to request update data to table Deductible 'The API will validating your data, include the user who process the data' (go to APIWriteDeductible with url_type = "edit")
		Edit(id) {
			this.loading.list = true
			let formdata = {
				deductible_id: id,
				order_by: "deductible_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListDeductible"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback === "Y") {
							document.getElementById("tabform").click()
							this.url_type 						= "edit"
							this.form.deductible_id 			= feedback[0].deductible_id
							this.deductible_withflag			= feedback[0].deductible_withflag
							this.form.deductible_ccy			= feedback[0].deductible_ccy
							this.form.deductible_forindemnity	= feedback[0].deductible_forindemnity
							this.form.deductible_tsisipct		= this.$functions.FormatNumber(feedback[0].deductible_tsisipct,4)
							this.$refs.deductible_tsisipct.$el.getElementsByTagName('input')[0].value = this.$functions.FormatNumber(feedback[0].deductible_tsisipct,4)
							this.form.deductible_tsisiflag		= feedback[0].deductible_tsisiflag
							this.form.deductible_claimpct		= this.$functions.FormatNumber(feedback[0].deductible_claimpct,4)
							this.$refs.deductible_claimpct.$el.getElementsByTagName('input')[0].value = this.$functions.FormatNumber(feedback[0].deductible_claimpct,4)
							this.form.deductible_amountmin		= this.$functions.FormatNumber(feedback[0].deductible_amountmin,4)
							this.$refs.deductible_amountmin.$el.getElementsByTagName('input')[0].value = this.$functions.FormatNumber(feedback[0].deductible_amountmin,4)
							this.form.deductible_amountmax		= this.$functions.FormatNumber(feedback[0].deductible_amountmax,4)
							this.$refs.deductible_amountmax.$el.getElementsByTagName('input')[0].value = this.$functions.FormatNumber(feedback[0].deductible_amountmax,4)
							this.form.deductible_remarkseng 	= feedback[0].deductible_remarkseng
							this.form.deductible_remarksind 	= feedback[0].deductible_remarksind
							this.form.deductible_totallossflag	= this.$functions.TrueOrFalse(feedback[0].deductible_totallossflag)
							this.form.deductible_actived 		= this.$functions.TrueOrFalse(feedback[0].deductible_actived)
							this.form.deductible_cdate 			= feedback[0].deductible_cdate
							this.form.deductible_cuser 			= feedback[0].deductible_cuser
							this.form.deductible_ldate 			= feedback[0].deductible_ldate
							this.form.deductible_luser 			= feedback[0].deductible_luser
							this.form.deductible_id_readonly 	= true
							setTimeout(function() {
								document.getElementById("deductible_remarkseng").focus()
							}, 500)
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog = true
							this.remarks.feedback = feedback[0].feedback
						}
					} else {
						this.SnackBar(true, "error", feedback, 0)
					}
					this.loading.list = false
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
					this.loading.list = false
				})
		},
		Delete(id) {
			let formdata = {
				url_type: "delete",
				deductible_id: id,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteDeductible"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback === "Y") {
							this.SnackBar(
								true,
								"success",
								"Delete Success",
								3000
							)
							this.confirm.dialog = false
							this.confirm.text = "Ok"
							this.selected = []
							this.List()
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog 	= true
							this.remarks.feedback 	= feedback[0].feedback
						}
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
		},
		MultiProcess() {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteDeductible"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback[0].feedback === "Y") {
						let feed
						if (feedback[0].feedback_failed == 0) {
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						} else {
							this.remarks.feedback = feedback[0].feedback_failed_remarks
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
							this.remarks.dialog = true
						}
						this.SnackBar(true, "success", feed, 0)
						this.confirm.dialog = false
						this.confirm.text 	= "Ok"
						this.selected 		= []
						this.List()
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
				})
		},
		Loading() {
			this.confirm.text = "Loading..."
			if (this.url_type === "add" || this.url_type === "edit") {
				this.Write()
			} else if (this.url_type === "delete") {
				this.Delete(this.deductible_id)
			} else if (this.url_type === "multidelete") {
				this.MultiProcess("delete")
			}
		},
		Write() {
			let currency = document.getElementById('deductible_ccy').value
			let indemnity = document.getElementById('deductible_forindemnity').value
			let formdata = {
				url_type: this.url_type,
				deductible_id: this.form.deductible_id,
				deductible_tsisipct: this.form.deductible_tsisipct,
				deductible_tsisiflag: this.form.deductible_tsisiflag,
				deductible_claimpct: this.form.deductible_claimpct,
				deductible_withflag: this.$functions.ActivedFlag(this.deductible_withflag),
				deductible_ccy: currency,
				deductible_forindemnity: indemnity,
				deductible_amountmin: this.form.deductible_amountmin,
				deductible_amountmax: this.form.deductible_amountmax,
				deductible_remarkseng: this.form.deductible_remarkseng,
				deductible_remarksind: this.form.deductible_remarksind,
				deductible_actived: this.$functions.ActivedFlag(this.form.deductible_actived),
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteDeductible"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"success",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "add") {
							this.form.deductible_cuser = feedback[0].feedback_users_id
							this.form.deductible_cdate = feedback[0].feedback_users_date
						}
						this.form.deductible_luser = feedback[0].feedback_users_id
						this.form.deductible_ldate = feedback[0].feedback_users_date
						this.url_type = "edit"
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
		SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			if (color === "error") {
				this.confirm.dialog 	= false
				this.confirm.text 		= "Ok"
			}
		},
		FormatNumber (value, fixed) {
            return this.$functions.FormatNumber(value, fixed)
        },
		ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	},
};
</script>