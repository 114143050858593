<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Branch"></app-drawer_menu>
	  	<v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
	  	<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if = "access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_branch" lazy-validation>
			<v-row class="mx-2 ma-1">
				<v-col cols="12" sm="12" md="12">
					<v-btn
				      color		="tertiary"
				      class		="white--text"
				      :disabled = "access.add === 0 ? true:false" 
				      rounded
				      @click 	= "Add" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
				    </v-btn> &nbsp;
				    <v-btn
				      color		="primary"
				      class		="white--text"
				      :disabled = "access.add === 0 || access.edit === 0 ? true:false"
				      rounded
				      @click 	= "Confirm('write','')" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
				    </v-btn>
				</v-col>
			 <v-col cols="12" sm="6" md="4">
                <app-cb
                cb_type           	= "branchparent"
                cb_url            	= "apiListBranchParent"
                cb_url_parameter  	= "branchparent_actived=Y"
                cb_title          	= "Parent"
                cb_id             	= "branch_parent"
                cb_desc          	= "branch_parentdesc"
                cb_rules          	= "Please fill Branch Parent"
                cb_desc_readonly  	= "readonly"
                cb_items_id       	= "branchparent_id"
                cb_items_desc     	= "branchparent_desc"
                :cb_value_id        = "form.branch_parent"
                :cb_value_desc      = "form.branch_parentdesc"
                >
                </app-cb>
            </v-col>
              <!-- ID -->
               <v-col cols="12" sm="6" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_id"
                  required
                  :rules 	  	="form.branch_id_rules"
                  :readonly     = "form.branch_id_readonly"
                  :background-color = "form.branch_id_readonly ? 'readonly' : ''"
        				  :maxlength 	="maxinput.id"
        				  :counter		="maxinput.id"
                  label       	= "ID *"
                  placeholder 	= "ID"
                  id 			= "branch_id"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- ID -->
               <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_desc"
                  required
                  :rules        ="form.branch_desc_rules"
				          :maxlength 	  ="maxinput.desc"
                  label       	= "Description *"
                  placeholder 	= "Description"
                  id 			= "branch_desc"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_alias"
                  required
                  label       	= "Alias"
                  placeholder 	= "Description"
                  id 			      = "branch_alias"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="6" md="6" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_address"
                  required
                  :rules 	  	  = "form.branch_address_rules"
                  label       	= "Address *"
                  placeholder 	= "Address"
                  id 			= "branch_address"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_zipcode"
                  required
                  :rules 	  	  = "form.branch_zipcode_rules"
                  label       	= "Zip Code *"
                  placeholder 	= "Zip Code"
                  id 			      = "branch_zipcode"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_phone_1"
                  required
                  type 			    = "number"
                  :rules 	  	  = "form.branch_phone_1_rules"
                  label       	= "Phone 1 *"
                  placeholder 	= "62XXXXXXXXXXXXX"
                  id 			= "branch_phone_1"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_phone_2"
                  required
                  type 			    = "number"
                  label       	= "Phone 2"
                  placeholder 	= "62XXXXXXXXXXXXX"
                  id 			      = "branch_phone2"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_fax"
                  required
                  type 			    = "number"
                  label       	= "FAX"
                  placeholder 	= "62XXXXXXXXXXXXX"
                  id 			      = "branch_fax"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_email"
                  required
                  label       	= "Email"
                  placeholder 	= "name@domain"
                  id 			      = "branch_email"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_latitude"
                  required
                  :rules 	  	  = "form.branch_latitude_rules"
                  label       	= "Latitude"
                  placeholder  	= "Latitude"
                  id 			      = "branch_latitude"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_longitude"
                  required
                  :rules 	  	  = "form.branch_longitude_rules"
                  label  		    = "Longitude"
                  placeholder  	= "Longitude"
                  id 			      = "branch_longitude"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_pic"
                  required
                  :rules 	  	  = "form.branch_pic_rules"
                  label       	= "PIC Name *"
                  placeholder  	= "PIC Name"
                  id 			      = "branch_pic"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_pictitle"
                  required
                  :rules 	  	  = "form.branch_pictitle_rules"
                  label       	= "PIC Title *"
                  placeholder  	= "PIC Title"
                  id 			      = "branch_pictitle"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4" class="mt-n8">
                  <div class="overline mb-2">Sign Images</div>
                  <v-card xs3 class="text-xs-center cursor" flat label="Sign Images" @click="UploadSignClick">
                     <v-img 
                           v-model="form.branch_sign_images" 
                           :src="form.branch_sign_images_url" 
                           contain
                           height="80"
                     ></v-img>
                     <input type="file" ref="branch_sign_images" @change="UploadSignImages" accept="image/*" style="display:none">
                  </v-card>
               </v-col>
               <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_sign_name"
                  required
                  :rules 	  	  = "form.branch_sign_name_rules"
                  label       	= "Sign Name"
                  placeholder  	= "Sign Name"
                  id 			      = "branch_sign_name"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.branch_sign_title"
                  required
                  :rules 	  	  = "form.branch_sign_title_rules"
                  label       	= "Sign Title"
                  placeholder  	= "Sign Title"
                  id 			      = "branch_sign_title"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
			   <!-- Active -->
               <v-col cols="12" sm="2" md="2" class="mt-n5">
					<label class="caption">Operation</label>
					<v-switch
						v-model	= "form.branch_op_flag"
						color  	= "primary"
						id 		  = "form.branch_op_flag"
						:label 	= "form.branch_op_flag !== true ? '' : 'Active'" 
					></v-switch>
				</v-col>
				<v-col cols="12" sm="2" md="2" class="mt-n5">
					<label class="caption">Active Status</label>
					<v-switch
						v-model	= "form.branch_actived"
						color  	= "primary"
						id 		  = "form.branch_actived"
						:label 	= "form.branch_actived !== true ? '' : 'Active'" 
					></v-switch>
				</v-col>
            </v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
			   		<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.branch_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.branch_cuser)">{{form.branch_cuser}}</span>
Last Update Date : {{form.branch_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.branch_luser)">{{form.branch_luser}}</span>
</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  <v-card-title 
			  		class="ml-n4"
			  	>
			  		<v-btn 
					  	:color 	="selected.length === items.length ? 'secondary':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	title	="select all page"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
					</v-btn>
	                <v-btn 
	                	color     = "error"
	                	title 	  = "delete selected"
	                	@click 	  = "Confirm('multidelete','')"
	                	class	    = "mr-2"
	                	v-if  	  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false"
	                	small>
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-btn 
	                	color  = "primary"
	                	title  = "search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true"
	                	small>
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    :value="selected"
			    :headers="headers"
			    :items="items"
			    :search="search"
			    @input="Check($event)"
			    item-key="branch_id"
			    show-select
			    class="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			    dense
			    :loading 	= "loading.list"
    			loading-text="Loading... Please wait"
			  >
			  	<template v-slot:item.branch_parent="{ item }">
			  		{{ item.branch_parent }} - {{ item.branch_parentdesc }}
			  	</template>
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class	  ="mr-2"
			        @click	  ="Edit(item.branch_id)"
			        :disabled = "access.edit === 0 ? true:false"
			        title 	  = "edit"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click	  ="Confirm('delete',item.branch_id)"
			        :disabled = "access.delete === 0 ? true:false"
			        title 	  = "delete"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
		</v-tab-item>
  	</v-tabs>
	<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      top
      style 	= "z-index: 9999"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
  </v-snackbar>
  <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
	<!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>
<script>
export default {
	data: () => ({
		url_type: "add",
		cont: false,
		access: {
			read: 0,
			add: 0,
			edit: 0,
			delete: 0,
		},
		maxinput: {
			id: 4,
			desc: 150,
		},
		snackbar: {
			dialog: false,
			color: "success",
			text: "",
			timeout: 3000,
		},
		confirm: {
			dialog: false,
			title: "",
			text: "Ok",
		},
		selected: [],
		search: "",
		headers: [
			{
				text: "ID",
				align: "left",
				value: "branch_id",
			},
			{ text: "Description", value: "branch_desc" },
			{ text: "Parent", value: "branch_parent" },
			{ text: "Actived", value: "branch_actived" },
			{ text: "Actions", value: "action", sortable: false },
		],
		remarks: {
			feedback: "",
			dialog: false,
		},
		items: [],
		accessmenu: [],
		loading: {
			page: true,
			list: false,
		},
		btn_search: false,
		form: {
			branch_id: "",
			branch_id_rules: [(v) => !!v || "Please fill ID"],
			branch_id_readonly: false,
			branch_desc: "",
			branch_desc_rules: [(v) => !!v || "Please fill Description"],
			branch_parent: "",
			branch_parentdesc: "",
			branch_alias: "",
			branch_address: "",
			branch_zipcode: "",
			branch_phone_1: "",
			branch_phone_2: "",
			branch_fax: "",
			branch_email: "",
			branch_pic: "",
			branch_pictitle: "",
			branch_sign_images: "",
			branch_sign_images_url: "",
			branch_sign_imagesold: "",
			branch_op_flag: true,
			branch_actived: true,
			display_users: '',
		},
		dialog: {
			users: false
		}
	}),
	created() {
		this.FirstLoad()
	},
	methods: {
		FirstLoad() {
			//this function use for validating the screen with user privilleges
			this.Access()
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "branch"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let priv = response.data
				if (priv.length > 0) {
					this.access.read = priv[0].usersmenu_read
					this.access.add = priv[0].usersmenu_add
					this.access.edit = priv[0].usersmenu_edit
					this.access.delete = priv[0].usersmenu_delete
					if (priv[0].usersmenu_read === 0) {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					}
				} else {
					this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
				}
				this.loading.page = false
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
				this.loading.page = false
			})
		},
		SelectAllPage() {
			this.selected = this.selected === this.items ? [] : this.items
		},
		Check(value) {
			this.selected = value
		},
		//Function for get all data from table Branch with API in mod_users.go
		List() {
			this.loading.list = true
			let formdata = {
				branch_id: "",
				branch_desc: "",
				order_by: "branch_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListBranch"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== "Y") {
						this.SnackBar(
							true,
							"error",
							feedback[0].feedback,
							0
						)
					} else {
						this.items = feedback
					}
				} else {
					this.items = feedback
					this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading.list = false
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 3000)
				this.loading.list = false
			})
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
		Confirm(flag, id) {
			if (flag === "write") {
				if (this.$refs.form_branch.validate()) {
					this.confirm.dialog = true
					this.confirm.title = "Save"
					setTimeout(function() {
						document.getElementById("dialog").focus()
					}, 500)
				}
			} else if (flag === "delete") {
				this.url_type = "delete"
				this.confirm.dialog = true
				this.confirm.title = "Delete `" + id + "`"
				this.branch_id = id
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag === "multidelete") {
				this.url_type = "multidelete"
				this.confirm.dialog = true
				this.confirm.title =
					"Delete `" + this.selected.length + "` data"
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			}
		},
		//Function to request insert data to table Branch 'The API will validating your data, include the user who process the data' (go to APIWriteBranch with url_type = "add")
		Add() {
			this.ClearCB()
			this.url_type 						= "add"
			this.form.branch_id 				= ""
			this.form.branch_desc 				= ""
			this.form.branch_alias				= ""
			this.form.branch_parent 			= ""
			this.form.branch_parentdesc 		= ""
			this.form.branch_address 			= ""
			this.form.branch_zipcode 			= ""
			this.form.branch_phone_1 			= ""
			this.form.branch_phone2 			= ""
			this.form.branch_pic 				= ""
			this.form.branch_pictitle 			= ""
			this.form.branch_latitude 			= ""
			this.form.branch_longitude 			= ""
			this.form.branch_sign_images 		= ""
			this.form.branch_sign_images_url 	= ""
			this.form.branch_sign_name 			= ""
			this.form.branch_sign_title 		= ""
			this.form.branch_actived 			= true
			this.form.branch_id_readonly 		= false
			setTimeout(function() {
				document.getElementById("branch_parent").focus()
			}, 500)
		},
		//Function to request update data to table Branch 'The API will validating your data, include the user who process the data' (go to APIWriteBranch with url_type = "edit")
		Edit(id) {
			this.loading.list = true
			this.ClearCB()
			let formdata = {
				branch_id: id,
				branch_desc: "",
				order_by: "branch_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListBranch"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						document.getElementById("tabform").click()
						this.url_type 					= "edit"
						this.form.branch_id 			= feedback[0].branch_id
						this.form.branch_desc 			= feedback[0].branch_desc
						this.form.branch_alias 			= feedback[0].branch_alias
						this.form.branch_parent 		= feedback[0].branch_parent
						this.form.branch_parentdesc		= feedback[0].branch_parentdesc
						this.form.branch_address		= feedback[0].branch_address
						this.form.branch_zipcode		= feedback[0].branch_zipcode
						this.form.branch_phone_1		= feedback[0].branch_phone_1
						this.form.branch_phone_2		= feedback[0].branch_phone_2
						this.form.branch_fax			= feedback[0].branch_fax
						this.form.branch_email			= feedback[0].branch_email
						this.form.branch_latitude		= feedback[0].branch_latitude
						this.form.branch_longitude		= feedback[0].branch_longitude
						this.form.branch_pic			= feedback[0].branch_pic
						this.form.branch_pictitle		= feedback[0].branch_pictitle
						this.form.branch_sign_name		= feedback[0].branch_sign_name
						this.form.branch_sign_title		= feedback[0].branch_sign_title
						this.form.branch_sign_images	= feedback[0].branch_sign_images
						this.form.branch_sign_images_url= feedback[0].branch_sign_images_url
						this.form.branch_op_flag 		= this.$functions.TrueOrFalse(feedback[0].branch_op_flag)
						this.form.branch_actived 		= this.$functions.TrueOrFalse(feedback[0].branch_actived)
						this.form.branch_cdate 			= feedback[0].branch_cdate
						this.form.branch_cuser 			= feedback[0].branch_cuser
						this.form.branch_ldate 			= feedback[0].branch_ldate
						this.form.branch_luser 			= feedback[0].branch_luser
						this.form.branch_id_readonly 	= true
						setTimeout(function() {
							document.getElementById("branch_desc").focus()
						}, 500)
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(true, "error", feedback, 0)
				}
				this.loading.list = false
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog = true
				this.remarks.feedback = e
				this.loading.list = false
			})
		},
		Delete(id) {
			let formdata = {
				url_type: "delete",
				branch_id: id,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteBranch"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"success",
							"Delete Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						this.selected = []
						this.List()
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
		MultiProcess() {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteBranch"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback[0].feedback === "Y") {
					let feed
					if (feedback[0].feedback_failed == 0) {
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					} else {
						this.remarks.feedback = feedback[0].feedback_failed_remarks
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, "success", feed, 0)
					this.confirm.dialog = false
					this.confirm.text 	= "Ok"
					this.selected 		= []
					this.List()
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = feedback[0].feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
		Loading() {
			this.confirm.text = "Loading..."
			if (this.url_type === "add" || this.url_type === "edit") {
				this.Write()
			} else if (this.url_type === "delete") {
				this.Delete(this.branch_id)
			} else if (this.url_type === "multidelete") {
				this.MultiProcess("delete")
			}
		},
		Write() {
			let branchparent = document.getElementById('branch_parent').value
			let formdata = {
				url_type: this.url_type,
				branch_id: this.form.branch_id,
				branch_desc: this.form.branch_desc,
				branch_alias: this.form.branch_alias,
				branch_parent: branchparent,
				branch_address: this.form.branch_address,
				branch_zipcode: this.form.branch_zipcode,
				branch_phone_1: this.form.branch_phone_1,
				branch_phone_2: this.form.branch_phone_2,
				branch_fax: this.form.branch_fax,
				branch_email: this.form.branch_email,
				branch_pic: this.form.branch_pic,
				branch_pictitle: this.form.branch_pictitle,
				branch_sign_name: this.form.branch_sign_name,
				branch_sign_title: this.form.branch_sign_title,
				branch_op_flag: this.$functions.ActivedFlag(this.form.branch_op_flag),
				branch_actived: this.$functions.ActivedFlag(this.form.branch_actived),
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteBranch"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.Upload()
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
		SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			if (color === "error") {
				this.confirm.dialog 	= false
				this.confirm.text 		= "Ok"
			}
		},
		ClearCB () {
			this.form.branch_parent 	 		= ''
			this.form.branch_parentdesc 		= ''
		},
		Upload () {
        	// Append to FormData, because JWT not support form multipart/form-data to upload files
            let formData  = new FormData()
            formData.append('url_type', 'branch')
            formData.append('branch_id', this.form.branch_id)
            formData.append('branch_sign_imagesold', this.form.branch_sign_imagesold)
            formData.append('branch_sign_images', this.form.branch_sign_images)
            this.$axios.post(this.$functions.UploadPOST('apiWriteUploadFile', ``), formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
            })
            .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
   				if (feedback[0].feedback === 'Y') {
   	        	  this.SnackBar(true, 'success', 'Save Success', 3000)
                  this.confirm.dialog      = false
                  this.confirm.text        = 'Ok'
                  if (this.url_type === 'add') {
                     this.form.bank_cuser  = feedback[0].feedback_users_id
                     this.form.bank_cdate  = feedback[0].feedback_users_date
                  }
                  this.form.bank_luser  = feedback[0].feedback_users_id
                  this.form.bank_ldate  = feedback[0].feedback_users_date
                  this.url_type         = 'edit'
   	  			} else {
                  this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
   					this.remarks.dialog = true
   					this.remarks.feedback = feedback[0].feedback
   				}
            } else {
               this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
               this.remarks.dialog = true
               this.remarks.feedback = feedback[0].feedback
            }
			})
        	.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
		//Function for give preview image Sign Images
	    UploadSignClick () {
	       this.$refs.branch_sign_images.click()
	    },
	    UploadSignImages () {
			// Reference to the DOM input element
			var input = event.target
			// Ensure that you have a file before attempting to read it
			if (input.files && input.files[0]) {
			// create a new FileReader to read this image and convert to base64
			var reader = new FileReader()
			// Define a callback function to run, when FileReader finishes its job
			reader.onload = (e) => {
				// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
				// Read image as base64 and set to imageData
				this.form.branch_sign_images_url = e.target.result
			}
			this.form.branch_sign_images = input.files[0]
			// Start the reader job - read file as a data url (base64 format)
			reader.readAsDataURL(input.files[0])
			}
	    },
		ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	},
};
</script>