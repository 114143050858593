import axios from 'axios'
import functions from './functions'


const Api = {
    Delete: async function(data, callbackOk, callbackError) {
        let formdata = {
            url_type: 'add',
            map_type: 'header',
            ids: data
        }

        let param = functions.ParamJWT(formdata)
        let encode = functions.EncodeJWT(param)
        try {
            const response = await axios.post(functions.UrlJWT('apiDeleteMapUpload2'), encode, {
                headers: {
                    'Content-Type': 'text/plain; charset=ISO-8859-1'
                }
            })
            callbackOk(functions.DecodeJWT(response.data))
        } catch (e) {
            callbackError(e)
        }
    },

    loadingZeros: function(number, leading) {
        return (number).toPrecision(leading).split('.').reverse().join('');

    },
    toggle: function(collection, item) {
        var idx = collection.indexOf(item);
        if (idx !== -1) {
            collection.splice(idx, 1);
        } else {
            collection.push(item);
        }
    },

    Submit: async function(refs, data, callbackOk, callbackError) {
        let formdata = {
            url_type: 'add',
            map_type: 'header',

            mapheader_id: data.mapheader_code,
            mapheader_desc: data.mapheader_desc,
            mapheader_group: data.mapheader_group,
            mapheader_partial: data.mapheader_partial,
            mapheader_reference: data.mapheader_reference,
            mapheader_allowed: data.mapheader_allowed,
            mapheader_actived: data.mapheader_actived,
        }

        let param = functions.ParamJWT(formdata)
        let encode = functions.EncodeJWT(param)
        try {
            const response = await axios.post(functions.UrlJWT('apiWriteMapUpload'), encode, {
                headers: {
                    'Content-Type': 'text/plain; charset=ISO-8859-1'
                }
            })
            callbackOk(refs, functions.DecodeJWT(response.data))
        } catch (e) {
            callbackError(refs, e)
        }

    },
    ListDataAsync: async function(limit, offset) {
        let formdata = {
            mapheader_id: '',
            mapheader_desc: '',
            order_by: 'mapheader_cdate',
            order_type: 'DESC',
            limit: limit != undefined ? limit : undefined,
            offset: offset != undefined ? offset : undefined
        }
        console.log(formdata)
        let param = functions.ParamJWT(formdata)
        let encode = functions.EncodeJWT(param)
        try {
            const response = await axios.post(functions.UrlJWT('apiListMapUpload'), encode, {
                headers: {
                    'Content-Type': 'text/plain; charset=ISO-8859-1'
                }
            })
            return functions.DecodeJWT(response.data)
        } catch (e) {
            return e

        }

    },
    ListData: function(ref, snackbar, callbackOk, limit, offset) {
        let formdata = {
            mapheader_id: '',
            mapheader_desc: '',
            order_by: 'mapheader_cdate',
            order_type: 'DESC',
            limit: limit != undefined ? limit : undefined,
            offset: offset != undefined ? offset : undefined
        }
        console.log(formdata)
        let param = functions.ParamJWT(formdata)
        let encode = functions.EncodeJWT(param)
        axios.post(functions.UrlJWT('apiListMapUpload'), encode, {
                headers: {
                    'Content-Type': 'text/plain; charset=ISO-8859-1'
                }
            })
            .then(response => {

                callbackOk(ref, functions.DecodeJWT(response.data))
            })
            .catch(e => {
                snackbar.ShowSnackbar("error", e, 0, function() {
                    ref.$router.back();
                });
            })
    },

    CheckAccess2: function(reference, snackbar, callbackOk) {
        var ref = reference;
        this.CheckAccess(
            function(data) {
                callbackOk(data, reference)
            },
            /*
            on have no access, show snackbar forevevr
             */
            function() {
                snackbar.ShowSnackbar(
                    "error",
                    "You dont have access `Map Upload` modul, please contact Administrator",
                    0,
                    function() {
                        ref.$router.back();
                    }
                );
            },

            /*
          On error show snackbar 
          */
            function(err) {
                snackbar.ShowSnackbar("error", err, 0, function() {
                    ref.$router.back();
                });
            },
            undefined
        );
    },


    CheckAccess: async function(callbackOk, callbackNoPrivilege, callbackError, limit) {
        let formdata = {
            menu_id: 'mapupload',
            users_id: functions.UsersID(),
            order_by: 'menu_id',
            order_type: 'ASC',
            limit: limit,
        }
        let param = functions.ParamJWT(formdata)
        let encode = functions.EncodeJWT(param)

        try {
            const response = await axios.post(functions.UrlJWT('apiListMenuUser'), encode, {
                headers: {
                    'Content-Type': 'text/plain; charset=ISO-8859-1'
                }
            })
            let Ok = {
                access: {
                    read: 0,
                    add: 0,
                    edit: 0,
                    delete: 0,
                },
                accessmenu: []
            }
            Ok.accessmenu = functions.DecodeJWT(response.data)
            if (Ok.accessmenu.length > 0) {

                Ok.access.read = Ok.accessmenu[0].usersmenu_read
                Ok.access.add = Ok.accessmenu[0].usersmenu_add
                Ok.access.edit = Ok.accessmenu[0].usersmenu_edit
                Ok.access.delete = Ok.accessmenu[0].usersmenu_delete
                console.log(Ok)
                if (Ok.accessmenu[0].usersmenu_read === 0) {
                    if (callbackNoPrivilege != null) {
                        callbackNoPrivilege()
                    }

                } else {
                    callbackOk(Ok)
                }
            } else {

                callbackNoPrivilege()
            }
        } catch (e) {
            callbackError(e)
        }
    }


}
export default Api