import { render, staticRenderFns } from "./mapping_tab_list.vue?vue&type=template&id=b293b9d8&"
import script from "./mapping_tab_list.vue?vue&type=script&lang=js&"
export * from "./mapping_tab_list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCardTitle,VDataTable,VIcon,VPagination,VSimpleCheckbox,VTabItem,VTextField})
