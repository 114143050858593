
<template>
  <div>
    <app-drawer_menu drawer_menu_title="Claim"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>

    <v-stepper v-model="pp_la" vertical>
      <v-stepper-step :complete="pp_la > 1" step="1"> Start </v-stepper-step>

      <v-stepper-content step="1">
        <v-card class="mb-12" outlined>
          <v-form
            enctype="multipart/form-data"
            ref="form_policy"
            lazy-validation
          >
            <v-row>
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-card-text>
                  <v-dialog
                  ref                 ="dialog_claim_dol"
                  v-model             ="modal.claim_dol"
                  :return-value.sync  ="form.claim_dol"
                  width               ="290px"
                  persistent
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model             ="form.claim_dol"
                      label               ="Date Of Loss"
                      prepend-inner-icon  ="event"
                      background-color    ="date"
                      v-on                ="on"
                      v-mask              ="'####-##-##'"
                      placeholder         ="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.claim_dol" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.claim_dol = false">Cancel</v-btn>
                    <v-btn
                      text
                      color   ="primary"
                      @click  ="$refs.dialog_claim_dol.save(form.claim_dol)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                </v-card-text>
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <v-card-text>
                  <app-cb
                    cb_type="policy"
                    cb_url="apiListPolicy"
                    cb_url_parameter="policy_no="
                    cb_title="Certificate"
                    cb_id="claim_policyno"
                    cb_single="Y"
                    cb_desc="policy_certificateno"
                    cb_rules="Please fill Policy"
                    cb_desc_readonly="readonly"
                    cb_items_id="policy_no"
                    :cb_value_id="form.claim_policyno"
                    :cb_value_desc="form.claim_certificateno"
                  >
                  </app-cb>
                </v-card-text>
              </v-col>
              <v-col cols="12" sm="5" md="5" class="mt-n3">
                <v-card-text>
                  <v-text-field
                    v-model="form.claim_certificateno"
                    label="Reference No"
                    placeholder="<<Automation No.>>"
                    readonly="readonly"
                    background-color="readonly"
                  >
                  </v-text-field>
                </v-card-text>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn color="primary" @click="Confirm('writestep1', '')"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-stepper-content>

      <v-stepper-step :complete="pp_la > 2" step="2">
        Main Form
        <small></small>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-row>
          <v-col cols="12" sm="12" md="8" class="mb-n5">
            <v-card class="mb-12" outlined>
              <v-card-title>General Claim Information</v-card-title>
              <!-- <v-card-subtitle>Select Campaign Template</v-card-subtitle> -->
              <v-card-text>
                <v-row>
                  <!-- Register Date -->
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <v-dialog
                      ref="dialog_inception"
                      v-model="modal.inception"
                      :return-value.sync="form.policy_inception"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.policy_inception"
                          label="Register Date"
                          prepend-inner-icon="event"
                          background-color="date"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="form.policy_inception" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal.inception = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialog_inception.save(form.policy_inception),
                              IEDMOnChange('inception')
                          "
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <!-- Report Date -->
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <v-dialog
                      ref="dialog_inception"
                      v-model="modal.inception"
                      :return-value.sync="form.policy_inception"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.policy_inception"
                          label="Report Date"
                          prepend-inner-icon="event"
                          background-color="date"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="form.policy_inception" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal.inception = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialog_inception.save(form.policy_inception),
                              IEDMOnChange('inception')
                          "
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <v-dialog
                      ref="dialog_inception"
                      v-model="modal.inception"
                      :return-value.sync="form.policy_inception"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.policy_inception"
                          label="Ori Register Date"
                          prepend-inner-icon="event"
                          background-color="date"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="form.policy_inception" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal.inception = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialog_inception.save(form.policy_inception),
                              IEDMOnChange('inception')
                          "
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <v-dialog
                      ref="dialog_inception"
                      v-model="modal.inception"
                      :return-value.sync="form.policy_inception"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.policy_inception"
                          label="Survey Date"
                          prepend-inner-icon="event"
                          background-color="date"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="form.policy_inception" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal.inception = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialog_inception.save(form.policy_inception),
                              IEDMOnChange('inception')
                          "
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <!-- Claim ID -->
                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      v-model="form.claim_policyno"
                      label="Campaign No."
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>

                  <!--References No-->
                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      label="References No."
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      v-model="form.claim_refno"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Certificate Number -->
                  <v-col cols="8" sm="8" md="8" class="mb-n5">
                    <v-text-field
                      v-model="form.claim_certificateno"
                      label="Certificate No"
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-text-field
                      v-model="form.pp_end_no"
                      readonly="readonly"
                      background-color="numeric"
                      reverse
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-text-field
                      v-model="form.pp_end_ext_no"
                      readonly="readonly"
                      background-color="numeric"
                      reverse
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Default Claimant -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      label="Policy Subclass"
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      v-model="form.claim_policytype_id"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Claimant Type -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      label="Campaign"
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      v-model="form.claim_campaign_id"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Default Payment To -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <app-cb
                      cb_type="marketingofficer"
                      cb_url="apiListUsers"
                      cb_url_parameter="users_actived=Y"
                      cb_title="Default Payment To"
                      cb_id="policy_mo"
                      cb_desc="policy_mo_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="users_id"
                      cb_items_desc="users_name"
                      :cb_value_id="form.policy_mo"
                      :cb_value_desc="form.policy_mo_desc"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Handling Branch -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <app-cb
                      cb_type="channel"
                      cb_url="apiListChannel"
                      cb_url_parameter="channel_actived=Y"
                      cb_title="Handling Branch"
                      cb_id="policy_channel"
                      cb_desc="policy_channel_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="channel_id"
                      cb_items_desc="channel_desc"
                      :cb_value_id="form.policy_channel"
                      :cb_value_desc="form.policy_channel_desc"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Handling CT -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <app-cb
                      cb_type="branch"
                      cb_url="apiListBranch"
                      cb_url_parameter="branch_actived=Y"
                      cb_title="Handling CT"
                      cb_id="policy_branch"
                      cb_desc="policy_branch_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="branch_id"
                      cb_items_desc="branch_desc"
                      :cb_value_id="form.policy_branch"
                      :cb_value_desc="form.policy_branch_desc"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Cause Of Loss -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <app-cb
                      cb_type="policyholder"
                      cb_url="apiListProfile"
                      cb_url_parameter="profile_actived=Y"
                      cb_title="Cause Of Loss"
                      cb_id="policy_pholder"
                      cb_desc="policy_pholder_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="profile_id"
                      cb_items_desc="profile_name"
                      :cb_value_id="form.policy_pholder"
                      :cb_value_desc="form.policy_pholder_desc"
                      @clicked="GetPolicyHolder"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Loss Location -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <app-cb
                      cb_type="policyholder"
                      cb_url="apiListProfile"
                      cb_url_parameter="profile_actived=Y"
                      cb_title="Loss Location"
                      cb_id="policy_insured"
                      cb_desc="policy_insured_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="profile_id"
                      cb_items_desc="profile_name"
                      :cb_value_id="form.policy_insured"
                      :cb_value_desc="form.policy_insured_desc"
                      @clicked="GetInsured"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Internal Surveyor -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <app-cb
                      cb_type="policyholder"
                      cb_url="apiListProfile"
                      cb_url_parameter="profile_actived=Y"
                      cb_title="Internal Surveyor"
                      cb_id="policy_insured"
                      cb_desc="policy_insured_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="profile_id"
                      cb_items_desc="profile_name"
                      :cb_value_id="form.policy_insured"
                      :cb_value_desc="form.policy_insured_desc"
                      @clicked="GetInsured"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Internal Asessor -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <app-cb
                      cb_type="policyholder"
                      cb_url="apiListProfile"
                      cb_url_parameter="profile_actived=Y"
                      cb_title="Internal Asessor"
                      cb_id="policy_insured"
                      cb_desc="policy_insured_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="profile_id"
                      cb_items_desc="profile_name"
                      :cb_value_id="form.policy_insured"
                      :cb_value_desc="form.policy_insured_desc"
                      @clicked="GetInsured"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Claim Event -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <app-cb
                      cb_type="policyholder"
                      cb_url="apiListProfile"
                      cb_url_parameter="profile_actived=Y"
                      cb_title="Claim Event"
                      cb_id="policy_insured"
                      cb_desc="policy_insured_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="profile_id"
                      cb_single="Y"
                      cb_items_desc="profile_name"
                      :cb_value_id="form.policy_insured"
                      :cb_value_desc="form.policy_insured_desc"
                      @clicked="GetInsured"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Email -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <v-textarea
                      v-model="form.policy_longinsuredname"
                      id="form.policy_longinsuredname"
                      label="Email"
                      hint="Insured Name"
                      rows="1"
                      prepend-inner-icon="mail"
                    ></v-textarea>
                  </v-col>
                  <!-- Phone -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <v-textarea
                      v-model="form.claim_remarks"
                      label="Phone"
                      hint="Business"
                      prepend-inner-icon="phone_in_talk"
                      rows="1"
                    ></v-textarea>
                  </v-col>

                  <!-- Mobile -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <v-textarea
                      v-model="form.pp_business"
                      label="Mobile"
                      hint="Business"
                      prepend-inner-icon="phone_iphone"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-2">
                    <label>Whatsapp :</label>
                    <v-checkbox class="mt-n1"> </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="mb-n5">
            <v-card class="mb-4" outlined>
              <v-card-title></v-card-title>
              <!-- <v-card-subtitle>Select Campaign Template</v-card-subtitle> -->
              <v-card-text>
                <v-row>
                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      v-model="form.pp_surroundingarea"
                      ref="pp_surroundingarea"
                      id="pp_surroundingarea"
                      label="Claim Type"
                      prepend-inner-icon="content_copy"
                      hint="Claim type"
                      :value="$store.getters.long_insured_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      v-model="form.pp_surroundingarea"
                      ref="pp_surroundingarea"
                      id="pp_surroundingarea"
                      hint="Area"
                      :value="$store.getters.long_insured_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="mb-n5">
                    <v-text-field
                      v-model="form.pp_surroundingarea"
                      ref="pp_surroundingarea"
                      id="pp_surroundingarea"
                      label="Insurance Type"
                      prepend-inner-icon="content_copy"
                      hint="Insurance Type"
                      :value="$store.getters.long_insured_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      v-model="form.pp_surroundingarea"
                      ref="pp_surroundingarea"
                      id="pp_surroundingarea"
                      label="CNO"
                      background-color="numeric"
                      hint="Area"
                      :value="$store.getters.long_insured_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      v-model="form.pp_surroundingarea"
                      ref="pp_surroundingarea"
                      id="pp_surroundingarea"
                      label="ANO"
                      hint="Area"
                      background-color="numeric"
                      :value="$store.getters.long_insured_name"
                    ></v-text-field>
                  </v-col>
                  <!-- ExGratia -->
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <label>ExGratia :</label>
                    <v-checkbox
                      v-model="genpolicy.dialog.exgratia"
                      :label="payors.form.input.default === true ? 'Yes' : ''"
                      class="mt-n1"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <label>Merimen :</label>
                    <v-checkbox
                      v-model="payors.form.input.default"
                      :label="payors.form.input.default === true ? 'Yes' : ''"
                      class="mt-n1"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <label>Back Dated :</label>
                    <v-checkbox
                      v-model="payors.form.input.default"
                      :label="payors.form.input.default === true ? 'Yes' : ''"
                      class="mt-n1"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <label>Setled :</label>
                    <v-checkbox
                      v-model="payors.form.input.default"
                      :label="payors.form.input.default === true ? 'Yes' : ''"
                      class="mt-n1"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-alert border="top" colored-border type="info" elevation="2">
              Please save your data first to unlock the next form
            </v-alert>
            <!-- <v-btn color="primary" @click="pp_la = 3" outlined block>Save</v-btn> -->
            <v-btn color="primary" @click="gpioverlay = false" outlined block>
              <v-icon dark>{{ $vars.V("icon_save") }}</v-icon
              >{{ $vars.V("txt_save") }}</v-btn
            >

            <v-card class="mb-4 mt-4" outlined>
              <v-card-title>General</v-card-title>
              <v-card-text>
                <v-btn
                  tile
                  color="theme--dark purple darken-1"
                  class="mr-2"
                  @click="genpolicy.dialog.risk = true"
                >
                  <v-icon left>lock</v-icon>
                  LOSS ITEM
                </v-btn>
                <v-btn
                  tile
                  color="theme--dark purple darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.deductible = true"
                >
                  <v-icon left>mdi-heart</v-icon>
                  DEDUCTIBLE
                </v-btn>
              </v-card-text>
              <v-card-title>Expense</v-card-title>
              <v-card-text>
                <v-btn
                  tile
                  color="theme--dark green darken-1"
                  class="mr-2"
                  @click="genpolicy.dialog.wording = true"
                >
                  <v-icon left>lock</v-icon>
                  EXPENSE
                </v-btn>
                <v-btn
                  tile
                  color="theme--dark green darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.clause = true"
                >
                  <v-icon left>mdi-heart</v-icon>
                  SALVAGE
                </v-btn>
                <v-btn
                  tile
                  color="theme--dark green darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.busines = true"
                >
                  <v-icon left>lock</v-icon>
                  SUBROGATION
                </v-btn>
              </v-card-text>
              <v-card-text>
                <v-btn
                  tile
                  color="theme--dark blue darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.payors = true"
                >
                  <v-icon left>mdi-heart</v-icon>
                  CLAIM SURVEY
                </v-btn>
                <v-btn
                  tile
                  color="theme--dark blue darken-3"
                  class="mr-2"
                  @click="genpolicy.dialog.feeduty = true"
                >
                  <v-icon left>assignment_ind</v-icon>
                  FEE & DUTY
                </v-btn>
                <v-btn
                  tile
                  color="theme--dark blue darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.payors = true"
                >
                  <v-icon left>mdi-heart</v-icon>
                  CLAIM COMPLAINT
                </v-btn>
              </v-card-text>
              <v-card-text>
                <v-btn
                  tile
                  color="theme--dark red darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.payors = true"
                >
                  <v-icon left>mdi-calendar-check</v-icon>
                  RESERVE
                </v-btn>
                <v-btn
                  tile
                  color="theme--dark red darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.feeduty = true"
                >
                  <v-icon left>mdi-check-circle</v-icon>
                  APPROVE
                </v-btn>
                <v-btn
                  tile
                  color="theme--dark red darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.payors = true"
                >
                  <v-icon left>mdi-pencil</v-icon>
                  CORRECTION
                </v-btn>
              </v-card-text>
              <v-card-text>
                <v-btn
                  tile
                  color="theme--dark red darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.payors = true"
                >
                  <v-icon left>mdi-cancel</v-icon>
                  CANCELATION
                </v-btn>
                <v-btn
                  tile
                  color="theme--dark red darken-2"
                  class="mr-2"
                  @click="genpolicy.dialog.history = true"
                >
                  <v-icon left>mdi-history</v-icon>
                  HISTORY
                </v-btn>
              </v-card-text>
              <v-overlay :absolute="true" :value="gpioverlay">
                <v-btn color="danger" @click="gpioverlay = false">
                  <v-icon left>lock</v-icon> Locked
                </v-btn>
              </v-overlay>
            </v-card>
          </v-col>
        </v-row>
      </v-stepper-content>

      <!-- <v-stepper-step :complete="pp_la > 3" step="3">
        Calculate
        <small></small>
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-row>
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-card class="mb-12" outlined>
              <v-card-title>Premium Calculation</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="6" class="mb-n5">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>Type</td>
                            <td>New</td>
                          </tr>
                          <tr>
                            <td>Quotation No</td>
                            <td>123456</td>
                          </tr>
                          <tr>
                            <td>C/I No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Policy No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Certificate No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Remarks</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="mb-n5">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>ANO</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Inforce Date</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Period</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Voyage</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Open Cover</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Comment</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-card class="mb-12" outlined>
              <v-card-title>General Policy Information</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="6" class="mb-n5">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>Policy No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Certificate No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Prev Policy No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Prev Certificate No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Endorse/Renewal #</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Boking Date</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Effective Date</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Expiry At</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>segment</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Policy Holder</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Insured Name</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Business Source</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Fax</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Sent To (Policy)</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Sent To (Claim)</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Batch No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>ANO</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="mb-n5">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>Premium Amount Due</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Premium Paid</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Premium Outstanding</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Premium Lapse</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Payment Due</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Insurance Period</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Risk Coverage</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Memo</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Comment</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Remarks</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Original Document No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Reference Date</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Reference No</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Bulk Inforce</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>STNC Date</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-toolbar flat color="primary" dark>
              <v-toolbar-title>Review</v-toolbar-title>
            </v-toolbar>
            <v-tabs vertical>
              <v-tab>Object Information</v-tab>
              <v-tab>Risk Coverage</v-tab>
              <v-tab>Interest Insured</v-tab>
              <v-tab>Deductible</v-tab>
              <v-tab>General Clause</v-tab>
              <v-tab>General Condition</v-tab>
              <v-tab>General Exclusions</v-tab>
              <v-tab>Claim History</v-tab>
              <v-tab>O/S Premium</v-tab>
              <v-tab>O/S Claim Payable</v-tab>
              <v-tab>Business Source</v-tab>
              <v-tab>Premium Paid</v-tab>
              <v-tab>Claim Paid</v-tab>
              <v-tab>Installment</v-tab>
              <v-tab>DN/CN List</v-tab>
              <v-tab>Policy Conjunction</v-tab>
              <v-tab>Policy EStamp</v-tab>
              <v-tab>Interested Party</v-tab>
              <v-tab>Beneficiaries</v-tab>
              <v-tab>Policy Limit</v-tab>
              <v-tab>Credit Limit</v-tab>
              <v-tab>Master Limit Liability</v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <p>
                      Sed aliquam ultrices mauris. Donec posuere vulputate arcu.
                      Morbi ac felis. Etiam feugiat lorem non metus. Sed a
                      libero.
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <p>
                      Morbi nec metus. Suspendisse faucibus, nunc et
                      pellentesque egestas, lacus ante convallis tellus, vitae
                      iaculis lacus elit id tortor. Sed mollis, eros et ultrices
                      tempus, mauris ipsum aliquam libero, non adipiscing dolor
                      urna a orci. Curabitur ligula sapien, tincidunt non,
                      euismod vitae, posuere imperdiet, leo. Nunc sed turpis.
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <p>
                      Fusce a quam. Phasellus nec sem in justo pellentesque
                      facilisis. Nam eget dui. Proin viverra, ligula sit amet
                      ultrices semper, ligula arcu tristique sapien, a accumsan
                      nisi mauris ac eros. In dui magna, posuere eget,
                      vestibulum et, tempor auctor, justo.
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step :complete="pp_la > 4" step="4">
        Payor Information
        <small></small>
      </v-stepper-step>

      <v-stepper-step :complete="pp_la > 5" step="5">
        Review & Done
        <small></small>
      </v-stepper-step> -->
    </v-stepper>

    <!-- DIALOG -->
    <!-- Start dialog risk -->
    <v-dialog
      v-model="genpolicy.dialog.risk"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.risk = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Loss Item</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="OpenRisk" title="Add New Map Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Map Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.valuedialog"
          ></v-progress-linear>
          <v-spacer></v-spacer>
          <!-- Menu Table Risk -->
          <v-col cols="12" sm="12" md="12">
            <v-btn
              :color="
                risk.table.selected.length === risk.table.items.length
                  ? 'fourth'
                  : ''
              "
              @click="SelectAllMapValue"
              title="select all"
              class="mr-2"
              small
            >
              select all
            </v-btn>
            <v-btn @click="OpenRisk" class="mr-2" title="select all" small>
              Add
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multideletemapfield', '')"
              class="mr-2"
              v-if="risk.table.selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="risk.table.search_button === false"
              @click="SearchMapValue"
              small
            >
              <v-icon>search</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="risk.table.search"
              append-icon="search"
              @click:append="risk.table.search_button = false"
              label="Type and Enter"
              single-line
              hide-values
              v-if="risk.table.search_button"
              id="risk.table.search"
              clearable
              @keyup.enter="
                ListMapValue(
                  form.mapheader_id,
                  mapdetail.form.input.fieldfrom,
                  mapdetail.form.input.fieldto
                )
              "
            ></v-text-field>
            <!-- Start Table Risk -->
            <v-data-table
              v-model="risk.table.selected"
              :headers="risk.table.data"
              :items="risk.table.items"
              class="elevation-1"
              item-key="risk_id"
              show-select
               :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right',
              }"
            >
              <template v-slot:top>
                <v-switch
                  v-model="singleselect"
                  label="Single select"
                  class="pa-3"
                ></v-switch>
              </template>
              <template v-slot:item.action="{ item }">
                <!-- <v-icon small class="mr-2" @click="EditValue(item.risk_headerid, item.risk_fieldfrom, item.risk_fieldto, item.risk_from, item.risk_to)" :disabled = "access.edit === 0 ? true:false">
                  {{$vars.V('icon_edit')}}
                </v-icon> -->
                <v-icon
                  small
                  @click="
                    Confirm(
                      'deleterisk',
                      item.risk_headerid,
                      item.risk_fieldfrom,
                      item.risk_fieldto,
                      item.risk_from,
                      item.risk_to
                    )
                  "
                  :disabled="access.delete === 0 ? true : false"
                >
                  {{$vars.V('icon_delete')}}
                </v-icon>
              </template>
            </v-data-table>
            <!-- End Table Risk -->
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="risk.dialog.form"
      transition="dialog-bottom-transition"
      max-width="890"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="risk.dialog.form = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Loss Item</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Risk"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Loss Item"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- Interest Insured -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <app-cb
                cb_type="subclass"
                cb_url="apiListSubClass"
                cb_url_parameter="subclass_actived=Y"
                cb_title="Interest Insured"
                cb_id="policy_subclass"
                cb_desc="policy_subclass_desc"
                cb_rules="Please fill ID"
                cb_desc_readonly="readonly"
                cb_items_id="subclass_id"
                cb_items_desc="subclass_desc"
                :cb_value_id="form.policy_subclass"
                :cb_value_desc="form.policy_subclass_desc"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <app-cb
                cb_type="subclass"
                cb_url="apiListSubClass"
                cb_url_parameter="subclass_actived=Y"
                cb_title="Nature of Claim"
                cb_id="policy_subclass"
                cb_desc="policy_subclass_desc"
                cb_rules="Please fill ID"
                cb_desc_readonly="readonly"
                cb_items_id="subclass_id"
                cb_items_desc="subclass_desc"
                :cb_value_id="form.policy_subclass"
                :cb_value_desc="form.policy_subclass_desc"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-text-field
                v-model="risk.form.period"
                label="Additional Loss Info"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2" class="mb-n5">
              <app-cb
                cb_type="subclass"
                cb_url="apiListSubClass"
                cb_url_parameter="subclass_actived=Y"
                cb_title="Nature of Claim"
                cb_id="policy_subclass"
                cb_desc="policy_subclass_desc"
                cb_rules="Please fill ID"
                cb_single="Y"
                cb_desc_readonly="readonly"
                cb_items_id="subclass_id"
                cb_items_desc="subclass_desc"
                :cb_value_id="form.policy_subclass"
                :cb_value_desc="form.policy_subclass_desc"
              >
              </app-cb>
            </v-col>
            <v-col cols="4" sm="4" md="4" class="mt-n3">
              <v-text-field
                v-model="risk.form.period"
                placeholder="0"
                background-color="numeric"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2" class="mb-n5">
              <app-cb
                cb_type="subclass"
                cb_url="apiListSubClass"
                cb_url_parameter="subclass_actived=Y"
                cb_title="Nature of Claim"
                cb_id="policy_subclass"
                cb_desc="policy_subclass_desc"
                cb_rules="Please fill ID"
                cb_single="Y"
                cb_desc_readonly="readonly"
                cb_items_id="subclass_id"
                cb_items_desc="subclass_desc"
                :cb_value_id="form.policy_subclass"
                :cb_value_desc="form.policy_subclass_desc"
              >
              </app-cb>
            </v-col>
            <v-col cols="4" sm="4" md="4" class="mt-n3">
              <v-text-field
                v-model="risk.form.period"
                placeholder="0"
                background-color="numeric"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog risk -->

    <!-- Start dialog benefit -->
    <v-dialog
      v-model="genpolicy.dialog.benefit"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.benefit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Benefit</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.valuedialog"
          ></v-progress-linear>
          <v-spacer></v-spacer>
          <!-- Menu Table Risk -->
          <v-col cols="12" sm="12" md="12">
            <v-btn
              :color="
                benefit.table.selected.length === benefit.table.items.length
                  ? 'fourth'
                  : ''
              "
              @click="SelectAllMapValue"
              title="select all"
              class="mr-2"
              small
            >
              select all
            </v-btn>
            <v-btn @click="OpenBenefit" class="mr-2" title="select all" small>
              Add
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multideletemapfield', '')"
              class="mr-2"
              v-if="benefit.table.selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="benefit.table.search_button === false"
              @click="SearchMapValue"
              small
            >
              <v-icon>search</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="benefit.table.search"
              append-icon="search"
              @click:append="benefit.table.search_button = false"
              label="Type and Enter"
              single-line
              hide-values
              v-if="benefit.table.search_button"
              id="benefit.table.search"
              clearable
              @keyup.enter="
                ListMapValue(
                  form.mapheader_id,
                  mapdetail.form.input.fieldfrom,
                  mapdetail.form.input.fieldto
                )
              "
            ></v-text-field>
            <!-- Start Table Risk -->
            <v-data-table
              v-model="benefit.table.selected"
              :headers="benefit.table.data"
              :items="benefit.table.items"
              class="elevation-1"
              item-key="benefit_id"
              show-select
               :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right',
              }"
            >
              <template v-slot:top>
                <v-switch
                  v-model="singleselect"
                  label="Single select"
                  class="pa-3"
                ></v-switch>
              </template>
              <template v-slot:item.action="{ item }">
                <!-- <v-icon small class="mr-2" @click="EditValue(item.benefit_headerid, item.benefit_fieldfrom, item.benefit_fieldto, item.benefit_from, item.benefit_to)" :disabled = "access.edit === 0 ? true:false">
                  {{$vars.V('icon_edit')}}
                </v-icon> -->
                <v-icon
                  small
                  @click="
                    Confirm(
                      'deletebenefit',
                      item.benefit_headerid,
                      item.benefit_fieldfrom,
                      item.benefit_fieldto,
                      item.benefit_from,
                      item.benefit_to
                    )
                  "
                  :disabled="access.delete === 0 ? true : false"
                >
                  {{$vars.V('icon_delete')}}
                </v-icon>
              </template>
            </v-data-table>
            <!-- End Table Risk -->
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="benefit.dialog.form"
      transition="dialog-bottom-transition"
      max-width="890"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="benefit.dialog.form = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Beneficiaries</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Map Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Map Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- Field Profile ID -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <app-cb
                cb_type="policyholder"
                cb_url="apiListProfile"
                cb_url_parameter="profile_actived=Y"
                cb_title="Profile ID"
                cb_id="pp_policyholder_id"
                cb_desc="pp_policyholder_name"
                cb_rules="Please fill ID"
                cb_desc_readonly="readonly"
                cb_items_id="profile_id"
                cb_items_desc="profile_name"
                :cb_value_id="form.pp_policyholder_id"
                :cb_value_desc="form.pp_policyholder_name"
              ></app-cb>
            </v-col>
            <!-- Field Address -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-textarea
                v-model="benefit.form.input.address"
                label="Address"
                hint="Address"
                rows="3"
              ></v-textarea>
            </v-col>
            <!-- Field Zip Code -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-text-field
                v-model="benefit.form.zip_code"
                label="Zip Code"
                placeholder="Zip Code"
              ></v-text-field>
            </v-col>
            <!-- Field Gender -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-select
                v-model="benefit.form.input.gender"
                :items="benefit.form.group.gender"
                label="Gender"
                item-value="id"
                item-text="text"
                return-object
                dense
              ></v-select>
            </v-col>
            <!-- Field Relation -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-select
                v-model="benefit.form.input.relation"
                :items="benefit.form.group.relation"
                label="Relation"
                item-value="id"
                item-text="text"
                return-object
                dense
              ></v-select>
            </v-col>
            <!-- Field Birth Date -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-dialog
                ref="dialog_benefit_birth_date"
                v-model="benefit.dialog.birth_date"
                :return-value.sync="benefit.form.input.birth_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="benefit.form.input.birth_date"
                    label="Birth Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="benefit.form.input.birth_date"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="benefit.dialog.birth_date = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dialog_benefit_birth_date.save(
                        benefit.form.input.birth_date
                      )
                    "
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!-- Ujroh -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="benefit.form.input.reim"
                background-color="numeric"
                reverse
                label="Reim / Indem"
                placeholder="Reim / Indem"
                hint="(%)"
              ></v-text-field>
            </v-col>
            <!-- Field Remark -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-textarea
                v-model="benefit.form.input.address"
                label="Remark"
                hint="Remark"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog benefit -->

    <!-- Start dialog deductible -->
    <v-dialog
      v-model="genpolicy.dialog.deductible"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.deductible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Deductible</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Deductible"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Deductible"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.valuedialog"
          ></v-progress-linear>
          <v-spacer></v-spacer>
          <!-- Menu Table Deductible -->
          <v-col cols="12" sm="12" md="12">
            <v-btn
              :color="
                deductible.table.selected.length ===
                deductible.table.items.length
                  ? 'fourth'
                  : ''
              "
              @click="SelectAllMapValue"
              title="select all"
              class="mr-2"
              small
            >
              select all
            </v-btn>
            <v-btn
              @click="OpenDeductible"
              class="mr-2"
              title="select all"
              small
            >
              Add
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multideletemapfield', '')"
              class="mr-2"
              v-if="deductible.table.selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="deductible.table.search_button === false"
              @click="SearchMapValue"
              small
            >
              <v-icon>search</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="deductible.table.search"
              append-icon="search"
              @click:append="deductible.table.search_button = false"
              label="Type and Enter"
              single-line
              hide-values
              v-if="deductible.table.search_button"
              id="deductible.table.search"
              clearable
              @keyup.enter="
                ListMapValue(
                  form.mapheader_id,
                  mapdetail.form.input.fieldfrom,
                  mapdetail.form.input.fieldto
                )
              "
            ></v-text-field>
            <!-- Start Table Deductible -->
            <v-data-table
              v-model="deductible.table.selected"
              :headers="deductible.table.data"
              :items="deductible.table.items"
              class="elevation-1"
              item-key="deductible_id"
              show-select
               :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right',
              }"
            >
              <template v-slot:top>
                <v-switch
                  v-model="singleselect"
                  label="Single select"
                  class="pa-3"
                ></v-switch>
              </template>
              <template v-slot:item.action="{ item }">
                <!-- <v-icon small class="mr-2" @click="EditValue(item.deductible_headerid, item.deductible_fieldfrom, item.deductible_fieldto, item.deductible_from, item.deductible_to)" :disabled = "access.edit === 0 ? true:false">
                  {{$vars.V('icon_edit')}}
                </v-icon> -->
                <v-icon
                  small
                  @click="
                    Confirm(
                      'deletedeductible',
                      item.deductible_headerid,
                      item.deductible_fieldfrom,
                      item.deductible_fieldto,
                      item.deductible_from,
                      item.deductible_to
                    )
                  "
                  :disabled="access.delete === 0 ? true : false"
                >
                  {{$vars.V('icon_delete')}}
                </v-icon>
              </template>
            </v-data-table>
            <!-- End Table Deductible -->
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deductible.dialog.form"
      transition="dialog-bottom-transition"
      max-width="890"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="deductible.dialog.form = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Deductible</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Deductible"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Deductible"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- Field Deductible -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-text-field
                v-model="deductible.form.deductible"
                label="Deductible"
                placeholder="Deductible"
              ></v-text-field>
            </v-col>
            <!-- Field Selection -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-select
                v-model="deductible.form.input.selection"
                :items="deductible.form.group.selection"
                label="Selection"
                item-value="id"
                item-text="text"
                return-object
              ></v-select>
            </v-col>
            <!-- Field Risk -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-text-field
                v-model="deductible.form.risk"
                label="Risk"
                placeholder="0"
              ></v-text-field>
            </v-col>
            <!-- Field Year -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-text-field
                v-model="deductible.form.year"
                label="Year #"
                placeholder="0"
                background-color="numeric"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog deductible -->

    <!-- Start dialog wording -->
    <v-dialog
      v-model="genpolicy.dialog.wording"
      transition="dialog-bottom-transition"
      max-width="500"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.wording = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Policy Wording List</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Map Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Map Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-text-field
                v-model="form.pp_leader_policy_no"
                label="C/I No"
                placeholder="C/I No"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-text-field
                v-model="form.pp_leader_policy_no"
                label="Policy No"
                placeholder="Policy No"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-text-field
                v-model="form.pp_leader_policy_no"
                label="Insured"
                placeholder="Insured"
                background-color="readonly"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog wording -->

    <!-- Start dialog clause -->
    <v-dialog
      v-model="genpolicy.dialog.clause"
      transition="dialog-bottom-transition"
      max-width="500"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.clause = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Banker's Clause</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Map Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Map Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <app-cb
                cb_type="banker"
                cb_url="apiListBanker"
                cb_url_parameter="profile_actived=Y"
                cb_title="Bank Name"
                cb_id="pp_banker_id"
                cb_desc="pp_banker_name"
                cb_rules="Please fill ID"
                cb_desc_readonly="readonly"
                cb_items_id="profile_id"
                cb_items_desc="profile_name"
              >
              </app-cb>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog clause -->

    <!-- Start dialog busines -->
    <v-dialog
      v-model="genpolicy.dialog.busines"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.busines = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Business Source</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Map Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Map Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.valuedialog"
          ></v-progress-linear>
          <v-spacer></v-spacer>
          <!-- Menu Table Risk -->
          <v-col cols="12" sm="12" md="12">
            <v-btn
              :color="
                busines.table.selected.length === busines.table.items.length
                  ? 'fourth'
                  : ''
              "
              @click="SelectAllMapValue"
              title="select all"
              class="mr-2"
              small
            >
              select all
            </v-btn>
            <v-btn @click="OpenBusines" class="mr-2" title="select all" small>
              Add
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multideletemapfield', '')"
              class="mr-2"
              v-if="busines.table.selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="busines.table.search_button === false"
              @click="SearchMapValue"
              small
            >
              <v-icon>search</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="busines.table.search"
              append-icon="search"
              @click:append="busines.table.search_button = false"
              label="Type and Enter"
              single-line
              hide-values
              v-if="busines.table.search_button"
              id="busines.table.search"
              clearable
              @keyup.enter="
                ListMapValue(
                  form.mapheader_id,
                  mapdetail.form.input.fieldfrom,
                  mapdetail.form.input.fieldto
                )
              "
            ></v-text-field>
            <!-- Start Table Risk -->
            <v-data-table
              v-model="busines.table.selected"
              :headers="busines.table.data"
              :items="busines.table.items"
              class="elevation-1"
              item-key="busines_id"
              show-select
               :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right',
              }"
            >
              <template v-slot:top>
                <v-switch
                  v-model="singleselect"
                  label="Single select"
                  class="pa-3"
                ></v-switch>
              </template>
              <template v-slot:item.action="{ item }">
                <!-- <v-icon small class="mr-2" @click="EditValue(item.busines_headerid, item.busines_fieldfrom, item.busines_fieldto, item.busines_from, item.busines_to)" :disabled = "access.edit === 0 ? true:false">
                  {{$vars.V('icon_edit')}}
                </v-icon> -->
                <v-icon
                  small
                  @click="
                    Confirm(
                      'deletebusines',
                      item.busines_headerid,
                      item.busines_fieldfrom,
                      item.busines_fieldto,
                      item.busines_from,
                      item.busines_to
                    )
                  "
                  :disabled="access.delete === 0 ? true : false"
                >
                  {{$vars.V('icon_delete')}}
                </v-icon>
              </template>
            </v-data-table>
            <!-- End Table Risk -->
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="busines.dialog.form"
      transition="dialog-bottom-transition"
      max-width="890"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="busines.dialog.form = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Business Source</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Business Source"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Business Source"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- Field Business Source ID -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-text-field
                v-model="busines.form.id"
                label="Business Source ID"
                placeholder="Business Source ID"
              ></v-text-field>
            </v-col>
            <!-- Field Business Source Name -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-text-field
                v-model="busines.form.name"
                label="Business Source Name"
                placeholder="0"
              ></v-text-field>
            </v-col>
            <!-- Field Business Source Type -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-select
                v-model="busines.form.input.type"
                :items="busines.form.group.type"
                label="Business Source Type"
                item-value="id"
                item-text="text"
                return-object
              ></v-select>
            </v-col>
            <!-- Field Fee | Discount | Up Fee -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-text-field
                v-model="busines.form.year"
                label="Fee | Discount | Up Fee (%)"
                placeholder="0"
                background-color="numeric"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog busines -->

    <!-- Start dialog Payor Information -->
    <v-dialog
      v-model="genpolicy.dialog.payors"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.payors = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Payor Information</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Map Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Map Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- Field Payor ID -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <app-cb
                cb_type="constructionclass"
                cb_url="apiListClass"
                cb_url_parameter="users_actived=Y"
                cb_title="Payor ID"
                cb_id="pp_class_id"
                cb_desc="pp_class_desc"
                cb_rules="Please fill ID"
                cb_desc_readonly="readonly"
                cb_items_id="users_id"
                cb_items_desc="users_name"
                :cb_value_id="form.pp_class_id"
                :cb_value_desc="form.pp_class_desc"
              >
              </app-cb>
            </v-col>
            <!-- Field Address -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.address"
                label="Address"
                placeholder="Address"
              >
              </v-text-field>
            </v-col>
            <!-- Field Group Discount -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.group_dicount"
                background-color="numeric"
                reverse
                label="Group Discount"
                placeholder="Group Discount"
                hint="(%)"
              ></v-text-field>
            </v-col>
            <!-- Field No Claim Discount -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.group_dicount"
                background-color="numeric"
                reverse
                label="No Claim Discount"
                placeholder="No Claim Discount"
                hint="(%)"
              ></v-text-field>
            </v-col>
            <!-- Field Premium Discount -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.group_dicount"
                background-color="numeric"
                reverse
                label="Premium Discount"
                placeholder="Premium Discount"
                hint="(%)"
              ></v-text-field>
            </v-col>
            <!-- Field Discount in Amount -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.group_dicount"
                background-color="numeric"
                reverse
                label="Discoun in Amount"
                placeholder="Discoun in Amount"
                hint="(%)"
              ></v-text-field>
            </v-col>
            <!-- Field Surchage Discount -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.group_dicount"
                background-color="numeric"
                reverse
                label="Surchage Discount"
                placeholder="Surchage Discount"
                hint="(%)"
              ></v-text-field>
            </v-col>
            <!-- Field Payment Method -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-select
                v-model="payors.form.input.gender"
                :items="payors.form.group.gender"
                label="Payment Method"
                item-value="id"
                item-text="text"
                return-object
                dense
              ></v-select>
            </v-col>
            <!-- Field Default -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <label>Default :</label>
              <v-checkbox
                v-model="payors.form.input.default"
                :label="payors.form.input.default === true ? 'Yes' : ''"
              >
              </v-checkbox>
            </v-col>
            <!-- Field Discount -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <label>Discount :</label>
              <v-checkbox
                v-model="payors.form.input.discount"
                :label="payors.form.input.discount === true ? 'Yes' : ''"
              >
              </v-checkbox>
            </v-col>
            <!-- Field Allow Payor Due Greater Than Fac Due -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <label>Allow Payor Due Greater Than Fac Due :</label>
              <v-checkbox
                v-model="payors.form.input.allow"
                :label="payors.form.input.allow === true ? 'Yes' : ''"
              >
              </v-checkbox>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <div class="text-center">Premium Payment Procedure</div>
            </v-col>
            <!-- Grace Period -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.group_dicount"
                background-color="numeric"
                reverse
                label="Grace Period"
                placeholder="Grace Period"
                hint="In Day(s)"
              ></v-text-field>
            </v-col>
            <!-- Due Date -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-dialog
                ref="dialog_due_date"
                v-model="modal.due_date"
                :return-value.sync="form.pp_due_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.pp_due_date"
                    label="Due Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.pp_due_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.due_date = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_due_date.save(form.pp_due_date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!-- WPC -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.group_dicount"
                background-color="numeric"
                reverse
                label="WPC"
                placeholder="WPC"
                hint="In Day(s)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mb-n5"></v-col>
            <!-- Period Term -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <v-select
                v-model="payors.form.input.period_term"
                :items="payors.form.group.period_term"
                label="Period Term"
                item-value="id"
                item-text="text"
                return-object
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.Daily"
                background-color="readonly"
                label="Daily"
                placeholder="Daily"
                readonly
              >
              </v-text-field>
            </v-col>
            <!-- Of Installment -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.group_dicount"
                background-color="numeric"
                reverse
                label="Of Installment"
                placeholder="Of Installment"
                hint="In Day(s)"
              ></v-text-field>
            </v-col>
            <!-- Modal Factor -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.group_dicount"
                background-color="numeric"
                reverse
                label="Modal Factor"
                placeholder="Modal Factor"
                hint="(%)"
              ></v-text-field>
            </v-col>
            <!-- 1st Installment Date -->
            <v-col cols="12" sm="6" md="12" class="mb-n5">
              <v-dialog
                ref="dialog_installment_date"
                v-model="modal.installment_date"
                :return-value.sync="form.pp_installment_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.pp_installment_date"
                    label="1st Installment Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.pp_installment_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal.installment_date = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dialog_installment_date.save(
                        form.pp_installment_date
                      )
                    "
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!-- Field Billing by Policy Year -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <label>Billing by Policy Year :</label>
              <v-checkbox
                v-model="payors.form.input.policy_year"
                :label="payors.form.input.policy_year === true ? 'Yes' : ''"
              >
              </v-checkbox>
            </v-col>
            <!-- Field Installment Fee & Duty -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <label>Installment Fee & Duty :</label>
              <v-checkbox
                v-model="payors.form.input.installment_fee_duty"
                :label="
                  payors.form.input.installment_fee_duty === true ? 'Yes' : ''
                "
              >
              </v-checkbox>
            </v-col>
            <!-- Field SI Schedule -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <label>SI Schedule :</label>
              <v-checkbox
                v-model="payors.form.input.si_schedule"
                :label="payors.form.input.si_schedule === true ? 'Yes' : ''"
              >
              </v-checkbox>
            </v-col>
            <!-- Field Billing Follow SI SChedule -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <label>Billing Follow SI SChedule :</label>
              <v-checkbox
                v-model="payors.form.input.billing_follow_si_schedule"
                :label="
                  payors.form.input.billing_follow_si_schedule === true
                    ? 'Yes'
                    : ''
                "
              >
              </v-checkbox>
            </v-col>
            <!-- Interest Rate -->
            <v-col cols="12" sm="6" md="6" class="mb-n5">
              <v-text-field
                v-model="payors.form.input.interest_rate"
                background-color="numeric"
                reverse
                label="Interest Rate"
                placeholder="Interest Rate"
                hint="(%)"
              ></v-text-field>
            </v-col>
            <!-- Field Decresing SI -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <label>Decresing SI :</label>
              <v-checkbox
                v-model="payors.form.input.decresing_si"
                :label="payors.form.input.decresing_si === true ? 'Yes' : ''"
              >
              </v-checkbox>
            </v-col>
            <!-- Field Decresing Premium -->
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <label>Decresing Premium :</label>
              <v-checkbox
                v-model="payors.form.input.decresing_premium"
                :label="
                  payors.form.input.decresing_premium === true ? 'Yes' : ''
                "
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog Payor Information -->

    <!-- Start fee & duty -->
    <v-dialog
      v-model="genpolicy.dialog.feeduty"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.feeduty = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Fee & Duty</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.valuedialog"
          ></v-progress-linear>
          <v-spacer></v-spacer>
          <!-- Menu Table Risk -->
          <v-col cols="12" sm="12" md="12">
            <v-btn
              :color="
                feeduty.table.selected.length === feeduty.table.items.length
                  ? 'fourth'
                  : ''
              "
              @click="SelectAllMapValue"
              title="select all"
              class="mr-2"
              small
            >
              select all
            </v-btn>
            <v-btn @click="OpenFeeduty()" class="mr-2" title="select all" small>
              Add
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multideletemapfield', '')"
              class="mr-2"
              v-if="feeduty.table.selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="feeduty.table.search_button === false"
              @click="SearchMapValue"
              small
            >
              <v-icon>search</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="feeduty.table.search"
              append-icon="search"
              @click:append="feeduty.table.search_button = false"
              label="Type and Enter"
              single-line
              hide-values
              v-if="feeduty.table.search_button"
              id="feeduty.table.search"
              clearable
              @keyup.enter="
                ListMapValue(
                  form.mapheader_id,
                  mapdetail.form.input.fieldfrom,
                  mapdetail.form.input.fieldto
                )
              "
            ></v-text-field>
            <!-- Start Table Risk -->
            <v-data-table
              v-model="feeduty.table.selected"
              :headers="feeduty.table.data"
              :items="feeduty.table.items"
              class="elevation-1"
              item-key="feeduty_id"
              show-select
               :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right',
              }"
            >
              <template v-slot:top>
                <v-switch
                  v-model="singleselect"
                  label="Single select"
                  class="pa-3"
                ></v-switch>
              </template>
              <template v-slot:item.action="{ item }">
                <!-- <v-icon small class="mr-2" @click="EditValue(item.feeduty_headerid, item.feeduty_fieldfrom, item.feeduty_fieldto, item.feeduty_from, item.feeduty_to)" :disabled = "access.edit === 0 ? true:false">
                  {{$vars.V('icon_edit')}}
                </v-icon> -->
                <v-icon
                  small
                  @click="
                    Confirm(
                      'deletefeeduty',
                      item.feeduty_headerid,
                      item.feeduty_fieldfrom,
                      item.feeduty_fieldto,
                      item.feeduty_from,
                      item.feeduty_to
                    )
                  "
                  :disabled="access.delete === 0 ? true : false"
                >
                  {{$vars.V('icon_delete')}}
                </v-icon>
              </template>
            </v-data-table>
            <!-- End Table Risk -->
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="feeduty.dialog.form"
      transition="dialog-bottom-transition"
      max-width="890"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="feeduty.dialog.form = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Fee and Duty</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddMapDetail" title="Add New Map Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, form.mapheader_id)"
            title="Save Map Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- Field Profile ID -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <app-cb
                cb_type="policyholder"
                cb_url="apiListProfile"
                cb_url_parameter="profile_actived=Y"
                cb_title="Profile ID"
                cb_id="pp_policyholder_id"
                cb_desc="pp_policyholder_name"
                cb_rules="Please fill ID"
                cb_desc_readonly="readonly"
                cb_items_id="profile_id"
                cb_items_desc="profile_name"
                :cb_value_id="form.pp_policyholder_id"
                :cb_value_desc="form.pp_policyholder_name"
              ></app-cb>
            </v-col>
            <!-- Field Fee & Duty Code -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-text-field
                v-model="feeduty.form.zip_code"
                background-color="combo"
                label="Fee & Duty Code"
                placeholder="Fee & Duty Code"
              ></v-text-field>
            </v-col>
            <!-- Field Remarks -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-text-field
                v-model="feeduty.form.input.remarks"
                label="Remarks"
                placeholder="Remarks"
              ></v-text-field>
            </v-col>
            <!-- Field In Amount -->
            <v-col cols="12" sm="2" md="2" class="mb-n5">
              <v-text-field
                v-model="feeduty.form.input.amount"
                background-color="combo"
                label="In Amount"
                placeholder="Currency"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mb-n5">
              <v-text-field
                v-model="feeduty.form.input.amount"
                background-color="numeric"
                placeholder="0.00"
                hint="(Nominal)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-n5">
              <v-text-field
                v-model="feeduty.form.input.amount"
                background-color="numeric"
                label="or"
                placeholder="0.00"
                hint="(%)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mb-n5">
              <v-select
                v-model="feeduty.form.input.gender"
                :items="feeduty.form.group.amount"
                item-value="id"
                label="Category"
                item-text="text"
                return-object
                dense
              ></v-select>
            </v-col>
            <!-- Field Year -->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-select
                v-model="feeduty.form.input.year"
                :items="feeduty.form.group.year"
                label="Year"
                item-value="id"
                item-text="text"
                hint="(0 : All Coverage Period)"
                return-object
                dense
              ></v-select>
            </v-col>
            <!-- Field Contributed -->
            <v-col cols="12" sm="4" md="4">
              <label>Contributed :</label>
              <v-checkbox
                v-model="feeduty.form.input.contributed"
                :label="feeduty.form.input.contributed === true ? 'Yes' : ''"
              >
              </v-checkbox>
            </v-col>
            <!-- Field R/I Tabarru -->
            <v-col cols="12" sm="4" md="4">
              <label>R/I Tabarru :</label>
              <v-checkbox
                v-model="feeduty.form.input.tabarru"
                :label="feeduty.form.input.tabarru === true ? 'Yes' : ''"
              >
              </v-checkbox>
            </v-col>
            <!-- Field AutoGenerated -->
            <v-col cols="12" sm="4" md="4">
              <label>AutoGenerated :</label>
              <v-checkbox
                v-model="feeduty.form.input.autogenerated"
                :label="feeduty.form.input.autogenerated === true ? 'Yes' : ''"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End fee & duty -->

    <!-- Start Unkown -->
    <v-breadcrumbs :items="pp_breadcrumb">
      <template v-slot:divider>
        <v-icon>trip_origin</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item v-on:click="BreadcrumbURL(item.text)">{{
          item.text.toUpperCase()
        }}</v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Policy Processing</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text @click="dialog = false">Save</v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>This will be showing time now</v-subheader>
          <v-card class="policycard" v-if="widgets == false">
            <v-app-bar dark color="primary">
              <v-toolbar-title
                >Policy Processing Document Printing</v-toolbar-title
              >
              <v-spacer></v-spacer>
            </v-app-bar>
            <!-- <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>by Kurt Wagner</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item> -->
            <div id="policycard">
              <v-row>
                <v-col cols="12" sm="5" md="5" class="tdleft"
                  >Campaign No :
                </v-col>
                <v-col cols="12" sm="7" md="7" class="tdright"
                  ><input type="text" v-model="form.pp_campaign_no" readonly
                /></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="5" md="5" class="tdleft">
                  Quotation No :
                </v-col>
                <v-col cols="12" sm="7" md="7" class="tdright"
                  ><input type="text" v-model="form.pp_quotation_no" readonly
                /></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="5" md="5" class="tdleft"
                  ><label for="documenttype">Document Type : </label></v-col
                >
                <v-col cols="12" sm="7" md="7" class="tdright"
                  ><select
                    id="documenttype"
                    class="textpolicycard"
                    v-model="selected.documenttype"
                  >
                    <option>COMMISION NOTE</option>
                    <option>DOCUMENT RECEIPT</option>
                    <option>FACULTATIVE INSURANCE SLIP</option>
                    <option>KUITANSI POLIS</option>
                    <option>OVERRIDING DISCOUNT</option>
                    <option>POLICY SCHEDULE</option>
                    <option>PREMIUM NOTE</option>
                    <option>PRODUCTION CONTROL SHEET</option>
                    <option>RENEWAL NOTICE</option>
                  </select>
                </v-col>
              </v-row>
              <!-- <v-btn
                        color		="primary"
                        class		="white--text mt-2"
                        rounded 
                        @click	="Print(form.pp_campaign_no, selected, 'pdf')"
                      >
                        <v-icon dark>print</v-icon>Print
                  </v-btn> -->
              <button
                class="buttoncard"
                v-on:click="Print(form.pp_campaign_no, selected.documenttype)"
              >
                Print
              </button>
              <button class="buttoncard" v-on:click="widgets = true">
                View Log
              </button>
            </div>
            <!-- <v-card-actions>
                <v-row class="mx-2">
                  <v-col cols="12" sm="4" md="4">
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                  </v-col>
                </v-row>
              </v-card-actions> -->
          </v-card>
          <v-card class="policycard1" v-else>
            <v-system-bar color="white">
              <v-spacer></v-spacer>
              <v-card-subtitle v-on:click="widgets = false"
                >Close<v-icon>mdi-close</v-icon></v-card-subtitle
              >
            </v-system-bar>
            <v-app-bar dark color="primary">
              <v-toolbar-title>Policy Schedule</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
            <!-- <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>by Kurt Wagner</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item> -->
            <div id="policycard1">
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"
                  ><label for="languange">Languange : </label></v-col
                >
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <select
                    id="languange"
                    class="textpolicycard"
                    v-model="selected.languange"
                  >
                    <option>English</option>
                    <option>Indonesia</option>
                  </select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"
                  >Print Date :
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mb-n5 tdright">
                  <v-dialog
                    ref="dialog_leader_policy_date"
                    v-model="modal.leader_policy_date"
                    :return-value.sync="form.pp_leader_policy_date"
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.pp_leader_policy_date"
                        prepend-inner-icon="event"
                        background-color="date"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.pp_leader_policy_date"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.leader_policy_date = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog_leader_policy_date.save(
                            form.pp_leader_policy_date
                          )
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft">Options : </v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <input
                    type="radio"
                    id="one"
                    class="ml-2 mr-1"
                    value="Without Originality"
                    v-model="selected.picked"
                  /><label for="one">Without Originality</label>
                  <input
                    type="radio"
                    id="two"
                    class="ml-2 mr-1"
                    value="Original"
                    v-model="selected.picked"
                  /><label for="two">Original</label>
                  <input
                    type="radio"
                    id="three"
                    class="ml-2 mr-1"
                    value="Duplicate"
                    v-model="selected.picked"
                  /><label for="three">Duplicate</label>
                  <input
                    type="radio"
                    id="Four"
                    class="ml-2 mr-1"
                    value="Triplecate"
                    v-model="selected.picked"
                  /><label for="four">Triplecate</label>
                  <input
                    type="radio"
                    id="five"
                    class="ml-2 mr-1"
                    value="Copy"
                    v-model="selected.picked"
                  /><label for="five">Copy</label>
                </v-col>
                <!-- <label class="container-checkbox">
                      <input type="checkbox" >
                      <span class="checkmark"></span>
                    </label> -->
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-policy mb-n2">
                    <input
                      type="checkbox"
                      id="Premium"
                      value="Show Premium Content"
                      v-model="checked.premium"
                    />
                    <label for="Premium">Show Premium Content</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-policy mb-n2">
                    <input
                      type="checkbox"
                      id="Discount"
                      value="Show Discount"
                      v-model="checked.discount"
                    />
                    <label for="Discount">Show Discount</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft">
                  Select item to Print :</v-col
                >
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-policy mb-n2">
                    <input
                      type="checkbox"
                      id="Attachment"
                      value="Attachment"
                      v-model="checked.attachment"
                    />
                    <label for="Attachment">Attachment</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-policy mb-n2">
                    <input
                      type="checkbox"
                      id="Selected Classess"
                      value="Selected Classes"
                      v-model="checked.selected"
                    />
                    <label for="Selected Classess">Selected Classess</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-policy mb-n2">
                    <input
                      type="checkbox"
                      id="Deferred Premium Clause"
                      value="Deferred Premium Clause"
                      v-model="checked.deferred"
                    />
                    <label for="Deferred Premium Clause"
                      >Deferred Premium Clause</label
                    >
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-policy mb-n2">
                    <input
                      type="checkbox"
                      id="PA Card"
                      value="PA Card"
                      v-model="checked.pa"
                    />
                    <label for="PA Card">PA Card</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-policy">
                    <input
                      type="checkbox"
                      id="With Header And Footer"
                      value="With Header And Footer"
                      v-model="checked.headerfooter"
                    />
                    <label for="With Header And Footer"
                      >With Header And Footer</label
                    >
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"
                  ><label for="layout">Layout : </label></v-col
                >
                <v-col cols="12" sm="6" md="6" class="tdright"
                  ><select
                    id="layout"
                    class="textpolicycard"
                    v-model="selected.layout"
                  >
                    <option disabled value="">Default</option>
                    <option>Point</option>
                  </select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"
                  ><label for="layoutAttachment"
                    >Layout Attachment :
                  </label></v-col
                >
                <v-col cols="12" sm="6" md="6" class="tdright"
                  ><select
                    id="layoutAttachment"
                    class="textpolicycard"
                    v-model="selected.layoutAttachment"
                  >
                    <option disabled value="">Default</option>
                    <option>Point</option>
                  </select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft mt-n2">
                  <button
                    class="buttoncard"
                    v-on:click="Export(form.pp_campaign_no)"
                  >
                    Export
                  </button>
                  <button
                    class="buttoncard"
                    v-on:click="
                      Print(form.pp_campaign_no, selected.documenttype, 'pdf')
                    "
                  >
                    Print
                  </button>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdright mt-n2">
                  <button
                    class="buttoncard"
                    v-on:click="
                      Preview(form.pp_campaign_no, selected.documenttype)
                    "
                  >
                    Preview Only
                  </button>
                  <button
                    class="buttoncard"
                    v-on:click="
                      Print(form.pp_campaign_no, selected.documenttype, 'pdf')
                    "
                  >
                    Preview WithPrint
                  </button>
                </v-col>
              </v-row>
              <!-- <v-btn
                        color		="primary"
                        class		="white--text mt-2"
                        rounded 
                        @click	="Print(form.pp_campaign_no, selected.documenttype, 'pdf')"
                      >
                        <v-icon dark>print</v-icon>Print
                  </v-btn>
                  <v-btn
                        color		="primary"
                        class		="white--text mt-2"
                        rounded 
                        @click	="Print(form.pp_campaign_no, selected.documenttype, 'word')"
                      >
                        <v-icon dark>print</v-icon>Word
                  </v-btn> -->
            </div>
          </v-card>
        </v-list>
        <v-row class="mx-2" v-show="selected.iframe">
          <!-- <v-slider
            v-model="slider"
            prepend-icon="mdi-pan-horizontal"
            min="0"
            max="1000"
            thumb-label
          ></v-slider> -->
          <v-col cols="12" sm="12" md="12">
            <iframe
              :src="selected.iframeURL"
              frameborder="1"
              width="100%"
              height="1000px"
              id="PDFPreview"
            >
            </iframe>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}
          </v-btn>
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Start dialog history -->
    <v-dialog
      v-model="genpolicy.dialog.history"
      transition="dialog-bottom-transition"
      :maximize="true"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.history = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>History</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-timeline align="start">
            <v-timeline-item
              v-for="(histories, i) in history"
              :key="i"
              size="small"
            >
              <template v-slot:icon>
                <!-- Menambahkan template untuk ikon -->
                <v-icon color="white">mdi-account</v-icon>
                <!-- Ganti 'mdi-account' dengan ikon pengguna yang sesuai -->
              </template>
              <div>
                <h2
                  :class="`mt-n1 headline font-weight-light mb-4 text-${histories.color}`"
                >
                  Lorem ipsum
                </h2>
                <div>
                  <p>Current Date and Time: {{ dumyDateHistory() }}</p>
                </div>

                <div>
                  Lorem ipsum dolor sit amet, no nam oblique veritus. Commune
                  scaevola imperdiet nec ut, sed euismod convenire principes at.
                  Est et nobis iisque percipit, an vim zril disputando
                  voluptatibus, vix an salutandi sententiae.
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog history -->

    <!-- Start dialog exgratia -->
    <v-dialog
      v-model="genpolicy.dialog.exgratia"
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genpolicy.dialog.exgratia = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>ExGratia</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- User-->
            <v-col cols="6" sm="6" md="6" class="mt-3">
              <app-cb
                cb_type="users"
                cb_url="apiListUsers"
                cb_url_parameter="users_actived=Y"
                cb_title="User"
                cb_rules="Please fill User"
                cb_desc_readonly="readonly"
                cb_items_id="users_id"
                cb_items_desc="users_name"
              >
              </app-cb>
            </v-col>
            <!-- Approve Date -->
            <v-col cols="6" sm="6" md="6" class="mb-n5">
              <v-text-field
                label="Approve Date"
                placeholder="YYYY-MM-DD"
                background-color="date"
                prepend-inner-icon="event"
              >
              </v-text-field>
            </v-col>
            <!-- Approve-->
            <v-col cols="6" sm="6" md="6" class="mb-n5">
              <v-text-field
                label="Approve"
                prepend-inner-icon="person"
                readonly
                placeholder="Approve"
              ></v-text-field>
            </v-col>
            <!-- Approve-->
            <v-col cols="6" sm="6" md="6" class="mb-n5">
              <v-text-field
                label="Attachment"
                readonly
                prepend-inner-icon="attachment"
                placeholder="Attachment"
              ></v-text-field>
            </v-col>
            <!-- Remarks-->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-textarea
                label="Remarks"
                hint="Remarks"
                prepend-inner-icon="edit"
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog exgratia -->
  </div>
</template>
  <script>
export default {
  data: () => ({
    // ========================================================================
    // Start General Policy Information
    // ========================================================================
    history: [
      {
        color: "cyan",
        dates: "1960",
      },
      {
        color: "green",
        dates: "1970",
      },
      {
        color: "pink",
        dates: "1980",
      },
      {
        color: "amber",
        dates: "1990",
      },
      {
        color: "orange",
        dates: "2000",
      },
    ],
    gpioverlay: true,
    pp_la: 1,
    genpolicy: {
      table: {
        data: [
          { text: "ID", value: "genpolicy_id", sortable: false },
          { text: "CI No", value: "genpolicy_ci_no", sortable: false },
          { text: "Policy No", value: "genpolicy_policy_no" },
          { text: "Insurance Type", value: "genpolicy_policy_no" },
          { text: "Policy No", value: "genpolicy_policy_no" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        single_select: false,
        selected: [],
        search: "",
        search_button: false,
      },
      form: {
        claim_dol: '',
        input: {
          from: [],
          to: [],
          type: [],
          defaultvalue: "",
          func: [],
          param_1: "",
          param_2: "",
          compulsory: false,
          allowspace: false,
          touppercase: false,
          mapvalue: false,
        },
        group: {
          from: [],
          to: [],
          type: ["N", "D", "S"],
          func: ["Left", "Right", "Mid"],
        },
        rules: {},
        readonly: {
          from: false,
          param_1: false,
          param_2: false,
        },
      },
      dialog: {
        risk: false,
        history: false,
        exgratia: false,
        benefit: false,
        deductible: false,
        wording: false,
        clause: false,
        busines: false,
        payors: false,
        feeduty: false,
      },
    },

    // ========================================================================
    // End General Policy Information
    // ========================================================================

    // ========================================================================
    // Start Risk
    // ========================================================================
    risk: {
      table: {
        data: [
          { text: "Rate Code", value: "polrisk_rate_code", sortable: false },
          {
            text: "Rate Category",
            value: "polrisk_rate_category",
            sortable: false,
          },
          { text: "Period", value: "polrisk_period" },
          { text: "Discount & Loading (%)", value: "polrisk_discount" },
          { text: "Net Rate PCT Loss (%)", value: "polrisk_net_rate_pct_loss" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        single_select: false,
        selected: [],
        search: "",
        search_button: false,
      },
      form: {
        input: {
          rate_code: [],
          rate_category: [],
          period: "",
          discount: "",
          net_rate_pct_loss: "",
        },
        group: {
          rate_code: [],
          rate_category: [],
        },
        rules: {},
        readonly: {
          rate_code: false,
        },
      },
      dialog: {
        form: false,
      },
    },
    // ========================================================================
    // End Risk
    // ========================================================================

    // ========================================================================
    // Start Benefit
    // ========================================================================
    benefit: {
      table: {
        data: [
          {
            text: "Profile ID",
            value: "polbenefit_profile_id",
            sortable: false,
          },
          { text: "Address", value: "polbenefit_address", sortable: false },
          { text: "Zip Code", value: "polbenefit_zip_code" },
          { text: "Gender", value: "polbenefit_gender" },
          { text: "Relation", value: "polbenefit_relation" },
          { text: "Birth Date", value: "polbenefit_birth_date" },
          { text: "Reim / Indem", value: "polbenefit_reim_no" },
          { text: "Remark", value: "polbenefit_remark" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        single_select: false,
        selected: [],
        search: "",
        search_button: false,
      },
      form: {
        input: {
          profile_id: "",
          address: "",
          zip_code: "",
          gender: [],
          relation: "",
          birth_date: new Date().toISOString().substr(0, 10),
          reim: "",
          remark: "",
        },
        group: {
          gender: ["male", "female"],
          relation: ["1", "2"],
        },
        rules: {},
        readonly: {
          profile_id: false,
        },
      },
      dialog: {
        form: false,
        birth_date: false,
      },
    },
    // ========================================================================
    // End Benefit
    // ========================================================================

    // ========================================================================
    // Start Deductible
    // ========================================================================
    deductible: {
      table: {
        data: [
          { text: "Deductible", value: "poldeductible_id", sortable: false },
          {
            text: "Selection",
            value: "poldeductible_selection",
            sortable: false,
          },
          { text: "Risk", value: "poldeductible_risk" },
          { text: "Year #", value: "poldeductible_year" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        single_select: false,
        selected: [],
        search: "",
        search_button: false,
      },
      form: {
        input: {
          id: [],
          selection: [],
          risk: "",
          year: "",
        },
        group: {
          id: [],
          selection: [],
        },
        rules: {},
        readonly: {
          id: false,
        },
      },
      dialog: {
        form: false,
      },
    },
    // ========================================================================
    // End Deductible
    // ========================================================================

    // ========================================================================
    // Start Business Source
    // ========================================================================
    busines: {
      table: {
        data: [
          { text: "Biz. Source ID", value: "polbizsource_id", sortable: false },
          { text: "BSID", value: "polbizsource_bsid", sortable: false },
          { text: "Biz. Source Name", value: "polbizsource_name" },
          { text: "Source Type", value: "polbizsource_type" },
          { text: "Fee | Discount | Up. Fee (%)", value: "polbizsource_fee" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        single_select: false,
        selected: [],
        search: "",
        search_button: false,
      },
      form: {
        input: {
          id: [],
          bsid: [],
          name: "",
          type: [],
          fee: "",
          discount: "",
          upfee: "",
        },
        group: {
          id: [],
          bsid: [],
          type: ["N", "D", "S"],
        },
        rules: {},
        readonly: {
          id: false,
          bsid: false,
        },
      },
      dialog: {
        form: false,
      },
    },
    // ========================================================================
    // End Business Source
    // ========================================================================

    // ========================================================================
    // Start Payor Information
    // ========================================================================
    payors: {
      table: {
        data: [
          { text: "Biz. Source ID", value: "polbizsource_id", sortable: false },
          { text: "BSID", value: "polbizsource_bsid", sortable: false },
          { text: "Biz. Source Name", value: "polbizsource_name" },
          { text: "Source Type", value: "polbizsource_type" },
          { text: "Fee | Discount | Up. Fee (%)", value: "polbizsource_fee" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        single_select: false,
        selected: [],
        search: "",
        search_button: false,
      },
      form: {
        input: {
          id: [],
          bsid: [],
          name: "",
          type: [],
          fee: "",
          discount: "",
          upfee: "",
        },
        group: {
          id: [],
          bsid: [],
          type: ["N", "D", "S"],
        },
        rules: {},
        readonly: {
          id: false,
          bsid: false,
        },
      },
      dialog: {
        form: false,
      },
    },
    // ========================================================================
    // End Payor Information
    // ========================================================================

    // ========================================================================
    // Start Fee & Duty
    // ========================================================================
    feeduty: {
      table: {
        data: [
          { text: "Fee & Duty", value: "polfeeduty_feeduty", sortable: false },
          { text: "Payor ID", value: "polfeeduty_payor_id", sortable: false },
          { text: "Payor Name", value: "polfeeduty_payor_name" },
          { text: "Currency", value: "polfeeduty_currency" },
          { text: "Year #", value: "polfeeduty_year" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        single_select: false,
        selected: [],
        search: "",
        search_button: false,
      },
      form: {
        input: {
          feeduty: [],
          payor_id: [],
          payor_name: "",
          currency: [],
          category: [],
          year: "",
          contributed: false,
          tabarru: false,
          autogenerated: false,
        },
        group: {
          feeduty: [],
          payor_id: [],
          currency: [],
          year: ["0", "1", "2", "3", "4"],
          category: ["basic", "premium"],
        },
        rules: {},
        readonly: {
          feeduty: false,
          payor_id: false,
        },
      },
      dialog: {
        form: false,
      },
    },
    // ========================================================================
    // End Fee & Duty
    // ========================================================================

    //Format Number
    format_money: {
      decimal: ",",
      thousands: ".",
      precision: 0,
    },
    pp_stepper: 2,
    pp_panel: [0],
    form: {
      claim_policyrow: "",
      claim_certificateno: "",
      claim_policyno: "",
      claim_refno: "",

      claim_campaign_id: "",
      claim_campaign_id_desc: "",
      claim_revisionno: "",
      claim_correctionno: "",
      claim_subclass_id: "",
      claim_subclass_id_desc: "",
      claim_policytype_id: "",
      claim_policytype_id_desc: "",
      claim_branch_id: "",
      claim_handling_branch_id: "",
      claim_dateofloss: "",

      claim_timeofloss: "",
      claim_reportdate: "",
      claim_reporttime: "",
      claim_col_id: "",
      claim_remarks: "",
      policy_row: "",
      policy_no: "",
      policy_certificateno: "",
      policy_refno: "",
      policy_campaign_id: "",

      campaign_subclass: "",
      quotation_no: "",

      policy_subclass: "",
      policy_subclass_desc: "",
      policy_instype: "",
      policy_instype_desc: "",
      policy_mo: "",
      policy_mo_desc: "",
      policy_channel: "",
      policy_channel_desc: "",
      policy_branch: "",
      policy_branch_desc: "",
      policy_costcenter: "",
      policy_costcenter_desc: "",
      policy_pholder: "",
      policy_pholder_desc: "",
      policy_insured: "",
      policy_insured_desc: "",

      policy_longinsuredname: "",

      policy_inception: new Date().toISOString().substr(0, 10),
      policy_expiry: new Date().toISOString().substr(0, 10),
      policy_day: 1,
      policy_month: 0,

      pp_campaign_id_rules: [(v) => !!v || "Please fill Campaign ID"],

      pp_campaign_no: "",
      pp_campaign_desc: "",
      pp_quotation_no: "",
      pp_policy_no: "",
      pp_end_no: 0,
      pp_end_ext_no: 0,
      pp_renewal_no: 0,
      pp_instype_id: "",
      pp_instype_name: "",
      pp_subclass_id: "",
      pp_subclass_desc: "",
      pp_channel_id: "",
      pp_channel_desc: "",
      pp_branch_id: "",
      pp_branch_desc: "",
      pp_costcenter_id: "",
      pp_costcenter_desc: "",
      pp_policyholder_id: "",
      pp_policyholder_name: "",
      pp_insurance_type: "",
      pp_sum_insured: 0,
      pp_add_sum_insured: 0,
      pp_policy_limit: 0,
      pp_longinsured_name: "",
      pp_business: "",
      pp_inception: new Date().toISOString().substr(0, 10),
      pp_expiry: new Date().toISOString().substr(0, 10),
      pp_inception_time: "12:00",
      pp_expiry_time: "12:00",
      pp_period_day: 0,
      pp_extended: 0,
      pp_extended_period: true,
      pp_day_basis: 365,
      pp_validation: 0,
      pp_reference_date: new Date().toISOString().substr(0, 10),
      pp_leader_policy_date: new Date().toISOString().substr(0, 10),
      pp_reference_no: "",
      pp_leader_policy_no: "",
      pp_old_policy_no: "",
      pp_ujroh: 0,
      pp_prorata_basis_data: [
        {
          id: "T",
          text: "Default",
        },
        {
          id: "D",
          text: "Daily",
        },
        {
          id: "M",
          text: "Month",
        },
      ],
      pp_prorata_basis: "T",
      pp_tab_source: true,
      pp_tab_source_item: false,
      pp_tab_payor: true,
      pp_tab_payor_item: false,
      pp_tab_payor_prm_grp_disc_pct: 0,
      pp_tab_payor_prm_disc_pct: 0,
      pp_tab_payor_prm_disc_amt: 0,
      pp_tab_payor_payment_method_data: [
        {
          id: "TRANSFER",
          text: "Transfer",
        },
        {
          id: "CASH",
          text: "Cash",
        },
        {
          id: "CREDIT",
          text: "Credit Card",
        },
        {
          id: "VIRTUAL",
          text: "Virtual",
        },
      ],
      pp_tab_payor_payment_method: "TRANSFER",
      pp_tab_payor_grace_period: 0,
      pp_tab_payor_wpc_period: 0,
      pp_tab_payor_duedate: new Date().toISOString().substr(0, 10),
      pp_tab_payor_period_term_data: [
        {
          id: 0,
          text: "Daily",
        },
        {
          id: 1,
          text: "MOnthly",
        },
        {
          id: 3,
          text: "Quarterly",
        },
        {
          id: 6,
          text: "Half Yearly",
        },
        {
          id: 12,
          text: "Annually",
        },
      ],
      pp_tab_payor_period_term: 0,
      pp_tab_payor_ninstallment: 0,
      pp_tab_payor_first_due_date: new Date().toISOString().substr(0, 10),
      pp_tab_payor_billing_policyyear: false,
      pp_tab_payor_instalment_feeduty: false,
      pp_tab_payor_items_source: [],
      pp_tab_payor_items_feeduty: [],
      pp_tab_risk_coverage: [],
      pp_tab_risk_benefit: [],
      pp_tab_risk_deductible: [],
    },
    policy_objectinfo: [],
    policy_objectinfo_id: {},
    policy_objectinfo_value: {},
    policy_objectinfo_label: {},
    policy_objectinfo_datalist: {},
    policy_objectinfo_type: {},
    policy_objectinfo_editable: {},
    selected: {
      documenttype: "COMMISION NOTE",
      languange: "English",
      picked: "Without Originality",
      layout: "",
      checkedOption: [],
      layoutAttachment: "",
      iframe: false,
      iframeURL: "",
    },
    checked: {
      premium: false,
      discount: false,
      attachment: false,
      selected: false,
      deferred: false,
      pa: false,
      headerfooter: false,
    },
    slider: 0,
    loading: {
      page: false,
      riskcoverage: false,
      benefit: false,
      deductible: false,
      source: false,
      feeduty: false,
    },
    modal: {
      claim_dol: false,
      inception: false,
      expiry: false,
      inception_time: false,
      expiry_time: false,
      reference_date: false,
      leader_policy_date: false,
      due_date: false,
      first_due_date: false,
    },
    dialog: false,
    widgets: false,
    pp_breadcrumb: [
      {
        text: "Treaty Arragement",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Business Result",
        disabled: false,
        href: "breadcrumbs_link_1",
      },
      {
        text: "Upload",
        disabled: true,
        href: "breadcrumbs_link_2",
      },
      {
        text: "Print",
        disabled: false,
        href: "#/policyproduction/print",
      },
    ],
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
  }),
  created() {
    // this.RiskCoverage()
    // this.Benefit()
    // this.Deductible()
    // this.PolicySource()
    // this.FeeDuty()
  },
  methods: {
    dumyDateHistory() {
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
      return formattedDate;
    },
    // Temp
    AddMapDetail() {},
    SelectAllMapValue() {},

    SearchMapValue() {},
    // End: Temp
    Confirm(flag) {
      if (flag === "writestep1") {
        if (this.$refs.form_policy.validate()) {
          this.url_type = "writestep1";
          this.confirm.dialog = true;
          this.confirm.title = "Confirm Policy";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      }
    },
    Close(flag) {
      if (flag === "writestep1") {
        this.url_type = "add";
        this.confirm.dialog = false;
      }
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "writestep1") {
        this.Step1();
      }
    },

    GetGeneralTable(gentab_id, row) {
      let formdata = {
        gentab_id: gentab_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListGenTab"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.$set(
                this.policy_objectinfo_datalist,
                row,
                feedback[0].gentab_selectf
              );
              this.$set(
                this.policy_objectinfo_type,
                row,
                feedback[0].gentab_fieldtype
              );
              if (feedback[0].gentab_selectf == "Y") {
                this.GetGeneralTableDetail(gentab_id, row);
              }
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    GetGeneralTableDetail(gentab_id, row) {
      let formdata = {
        gendet_gentab_id: gentab_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListGenDetailShort"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              let gendet_value = [];
              for (let i = 0; i < feedback.length; i++) {
                gendet_value.push(feedback[i]["gendet_id"]);
              }

              this.$set(this.policy_objectinfo_id, row, gentab_id);
              this.$set(this.policy_objectinfo_value, row, gendet_value);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    GetObjectInfo(subclass_id) {
      let formdata = {
        subclass_id: subclass_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListPolicy"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              for (let i = 1; i <= 20; i++) {
                if (feedback[0]["subclass_fieldid_" + i] != "") {
                  this.$set(
                    this.policy_objectinfo_id,
                    i,
                    feedback[0]["subclass_fieldid_" + i]
                  );
                  if (feedback[0]["subclass_fielddefaultvalue_" + i] != "") {
                    this.$set(
                      this.policy_objectinfo_value,
                      i,
                      feedback[0]["subclass_fielddefaultvalue_" + i]
                    );
                  } else {
                    this.$set(this.policy_objectinfo_value, i, "");
                  }
                  this.$set(
                    this.policy_objectinfo_label,
                    i,
                    feedback[0]["subclass_fieldlabel_" + i]
                  );
                  this.$set(this.policy_objectinfo_editable, i, "N");

                  this.policy_objectinfo.push({
                    ["subclass_fieldid_" + i]:
                      feedback[0]["subclass_fieldid_" + i],
                    ["subclass_fieldname_" + i]:
                      feedback[0]["subclass_fieldname_" + i],
                  });
                  this.GetGeneralTable(feedback[0]["subclass_fieldid_" + i], i);
                }
              }
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    GetCBObjectInformation(row, value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let id = splits[1];
        this.policy_objectinfo_id[row] = id;
      }
    },

    ObjectInfoEditableOnChange(row) {
      if (this.policy_objectinfo_editable[row] == "Y") {
        this.$set(this.policy_objectinfo_editable, row, "N");
      } else {
        this.$set(this.policy_objectinfo_editable, row, "Y");
      }
    },

    GetPolicy() {
      let claim_policyno = document.getElementById("claim_policyno").value;
      let formdata = {
        policy_no: claim_policyno,
        order_by: "policy_no",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListPolicy"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          console.log("GetPolicy", feedback);
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.policy_row = feedback[0].policy_row;
              this.form.policy_certificateno = feedback[0].policy_certificateno;

              this.form.claim_policyrow = feedback[0].policy_row;
              this.form.claim_certificateno = feedback[0].policy_certificateno;

              this.form.claim_policyno = feedback[0].policy_no;
              this.form.claim_refno = feedback[0].policy_refno;
              this.form.claim_campaign_id = feedback[0].policy_campaign_id;
              this.form.claim_campaign_id_desc =
                feedback[0].policy_campaign_id_desc;
              this.form.claim_policytype_id = feedback[0].policy_subclass_id;
              this.form.claim_policytype_id_desc =
                feedback[0].policy_subclass_id_desc;
              this.form.claim_refno = feedback[0].policy_refno;
              let subclass_id = feedback[0].policy_no;
              // let pholder = feedback[0].campaign_pholder_desc
              // let insured = feedback[0].campaign_pholder_desc

              if (
                this.form.claim_policyno != "" &&
                this.form.claim_certificateno != ""
              ) {
                this.WriteStep1();
                this.GetObjectInfo(subclass_id);

                // Set Long Insured Name
                this.PHIOnChange(
                  this.form.policy_pholder_desc,
                  this.form.policy_insured_desc
                );
              }
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    // Step 1
    Step1() {
      this.GetPolicy();
    },
    WriteStep1() {
      console.log("Write Step 1");
      this.loading.page = true;
      let formdata = {
        url_type: this.url_type,
        claim_policyno: this.form.claim_policyno,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteClaim"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              console.log("Step1", feedback);
              this.SnackBar(true, "success", "Campaign Available", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.form.quotation_no = feedback[0].policy_quotation_no;
              this.pp_la = 2;
              this.loading.page = false;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    // Step 2
    IEDMOnChange(flag) {
      let day = parseInt(this.form.policy_day);
      let month = parseInt(this.form.policy_month);
      let formdata = {
        url_type: flag,
        calculate_inception: this.form.policy_inception,
        calculate_expiry: this.form.policy_expiry,
        calculate_day: day,
        calculate_month: month,
      };
      console.log("Param IEDM", formdata);
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCalculate"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          console.log("Response", feedback);
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.policy_inception = feedback[0].calculate_inception;
              this.form.policy_expiry = feedback[0].calculate_expiry;
              this.form.policy_day = feedback[0].calculate_day;
              this.form.policy_month = feedback[0].calculate_month;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    GetPolicyHolder(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let policyholder = splits[1];
        this.form.policy_pholder_desc = policyholder;
        this.PHIOnChange(policyholder, this.form.policy_insured_desc);
      }
    },
    GetInsured(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let insured = splits[1];
        this.form.policy_insured_desc = insured;
        this.PHIOnChange(this.form.policy_pholder_desc, insured);
      }
    },
    PHIOnChange(policyholder, insured) {
      if (policyholder == insured) {
        console.log("Long Insured Name", insured);
        this.form.policy_longinsuredname = insured;
      } else {
        console.log("Long Insured Name", policyholder + " QQ " + insured);
        this.form.policy_longinsuredname = policyholder + " QQ " + insured;
      }
    },
    Step2() {},
    WriteStep2() {},
    WriteStep3() {},
    WriteStep4() {},
    WriteStep5() {},

    SnackBar(dialog, color, text, timeout) {
      if (text !== "No Data Available") {
        this.snackbar = {
          dialog: dialog,
          color: color,
          text: text,
          timeout: timeout,
        };
        if (color === "error") {
          this.confirm.dialog = false;
          this.confirm.text = "Ok";
        }
      }
    },

    PolicyInfo() {
      let pp_campaign_id = document.getElementById("pp_campaign_id").value;
      if (pp_campaign_id === "") {
        setTimeout(function () {
          document.getElementById("pp_campaign_id").focus();
        }, 500);
      } else {
        let formData = new FormData();
        formData.append("campaign_id", pp_campaign_id);
        this.$axios
          .post(this.$functions.SafeURL("apiListCampaign", ``), formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data[0].feedback === "Y") {
              this.pp_stepper = 2;
              this.form.pp_campaign_no = response.data[0].campaign_id;
              this.form.pp_campaign_desc = response.data[0].campaign_title;
              this.form.pp_instype_id = response.data[0].campaign_instype;
              this.form.pp_instype_desc =
                response.data[0].campaign_instype_desc;
              this.form.pp_subclass_id = response.data[0].campaign_subclass;
              this.form.pp_subclass_desc =
                response.data[0].campaign_subclass_desc;
              this.form.pp_channel_id = response.data[0].campaign_channel;
              this.form.pp_channel_desc =
                response.data[0].campaign_channel_desc;
              this.form.pp_branch_id = response.data[0].campaign_branch;
              this.form.pp_branch_desc = response.data[0].campaign_branch_desc;
              this.form.pp_costcenter_id = response.data[0].campaign_costcenter;
              this.form.pp_costcenter_desc =
                response.data[0].campaign_costcenter_desc;
              this.form.pp_policyholder_id = response.data[0].campaign_pholder;
              this.form.pp_policyholder_name =
                response.data[0].campaign_pholder_name;
            } else {
              this.SnackBar(true, "error", response.data[0].feedback, 3000);
            }
          })
          .catch((e) => {
            this.confirm.dialog = false;
            this.confirm.ok_text = "Ok";
            this.confirm.ok_disabled = false;
            this.SnackBar(true, "error", e, 3000);
          });
      }
    },
    RiskCoverage() {
      this.loading.riskcoverage = true;
      let formData = new FormData();
      this.$axios
        .post(this.$functions.SafeURL("apiListFeeDuty", ``), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            alert("No Data");
          } else {
            this.form.pp_tab_risk_coverage = response.data;
          }
          this.loading.riskcoverage = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading.riskcoverage = false;
        });
    },
    OpenRisk() {
      this.risk.dialog.form = true;
      // this.Risk.form.input.fieldfrom = policyid
      // this.Risk.form.input.fieldto = fieldto
      // this.ListRisk(id, fieldfrom, fieldto)
      this.AddRisk();
    },
    AddRisk() {
      this.url_type = this.url_type;
      this.risk.form.input.profile_id = "";
      this.risk.form.input.address = "";
      setTimeout(function () {
        document.getElementById("risk_from").focus();
      }, 500);
    },
    Benefit() {
      this.loading.benefit = true;
      let formData = new FormData();
      this.$axios
        .post(this.$functions.SafeURL("apiListFeeDuty", ``), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            alert("No Data");
          } else {
            this.form.pp_tab_risk_benefit = response.data;
          }
          this.loading.benefit = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading.benefit = false;
        });
    },
    ListBenefit(id, fieldfrom, fieldto) {
      this.mapvalue.table.items = [];
      this.loading.value = true;
      let formdata = {
        mapvalue_headerid: id,
        mapvalue_fieldfrom: fieldfrom,
        mapvalue_fieldto: fieldto,
        datavalue: this.mapvalue.table.search,
        order_type: "ASC",
        limit: "100",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListBenefit"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.mapvalue.table.items = feedback;
            }
          }
          this.loading.value = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.value = false;
        });
    },
    OpenBenefit() {
      this.benefit.dialog.form = true;
      // this.benefit.form.input.fieldfrom = policyid
      // this.benefit.form.input.fieldto = fieldto
      // this.ListBenefit(id, fieldfrom, fieldto)
      this.AddBenefit();
    },
    AddBenefit() {
      this.url_type = this.url_type;
      this.benefit.form.input.profile_id = "";
      this.benefit.form.input.address = "";
      setTimeout(function () {
        document.getElementById("benefit_from").focus();
      }, 500);
    },
    Deductible() {
      this.loading.deductible = true;
      let formData = new FormData();
      this.$axios
        .post(this.$functions.SafeURL("apiListFeeDuty", ``), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            alert("No Data");
          } else {
            this.form.pp_tab_risk_deductible = response.data;
          }
          this.loading.deductible = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading.deductible = false;
        });
    },
    OpenDeductible() {
      this.deductible.dialog.form = true;
      // this.Deductible.form.input.fieldfrom = policyid
      // this.Deductible.form.input.fieldto = fieldto
      // this.ListDeductible(id, fieldfrom, fieldto)
      this.AddDeductible();
    },
    AddDeductible() {
      this.url_type = this.url_type;
      this.deductible.form.input.profile_id = "";
      this.deductible.form.input.address = "";
      setTimeout(function () {
        document.getElementById("deductible_from").focus();
      }, 500);
    },
    BreadcrumbURL(url) {
      if (url === "Print") {
        // this.$router.push({name: 'print', params: { id: this.pp_campaign_id }})
        this.dialog = true;
      }
    },
    Preview(id, type) {
      let url;
      if (type == "" || type == null || type == undefined) {
        this.SnackBar(true, "error", "Please Select Document Type", 3000);
      } else {
        url = this.$functions.DocumentURL(
          type,
          `id=${id}&lang=${this.form.pp_leader_policy_date}&option=${this.selected.picked}&Layout=${this.selected.layout}&LayoutAttachment=${this.selected.layoutAttachment}`,
          "pdf"
        );
        this.selected.iframeURL = url;
        if (this.selected.iframe == false) {
          this.selected.iframe = true;
        } else {
          alert("c");
          this.selected.iframeURL = url;
          document
            .getElementById("PDFPrevie")
            .contentDocument.location.reload(true);
        }
        // window.open(url, '_blank')
      }
    },
    Export(id) {
      let formData = new FormData();
      formData.append("campaign_id", id);
      this.$axios
        .post(this.$functions.SafeURL("apiWriteExcel", ``), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            alert("No Data");
          } else {
            this.form.pp_tab_risk_benefit = response.data;
          }
          this.loading.benefit = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading.benefit = false;
        });
    },
    Print(id, type, convert) {
      let url;
      if (type == "" || type == null || type == undefined) {
        this.SnackBar(true, "error", "Please Select Document Type", 3000);
      } else {
        url = this.$functions.DocumentURL(
          type,
          `id=${id}&lang=${this.form.pp_leader_policy_date}&option=${this.selected.picked}&Layout=${this.selected.layout}&LayoutAttachment=${this.selected.layoutAttachment}&ShowPremiumContent=${this.checked.premium}&ShowDiscount=${this.checked.discount}&Attachment=${this.checked.attachment}&SelectedClassess=${this.checked.selected}&DeferredPremiumClause=${this.checked.deferred}&PACard=${this.checked.pa}&WithHeaderAndFooter=${this.checked.headerfooter}`,
          convert
        );
        this.pdf = url;
        window.open(url, "_blank");
      }
    },
    PolicySource() {
      this.loading.source = true;
      let formData = new FormData();
      this.$axios
        .post(this.$functions.SafeURL("apiListProfile", `limit=1`), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            alert("No Data");
          } else {
            this.form.pp_tab_payor_items_source = response.data;
          }
          this.loading.source = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading.source = false;
        });
    },
    Busines() {
      this.loading.feeduty = true;
      let formData = new FormData();
      this.$axios
        .post(this.$functions.SafeURL("apiListBusines", ``), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            alert("No Data");
          } else {
            this.form.pp_tab_payor_items_feeduty = response.data;
          }
          this.loading.feeduty = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading.feeduty = false;
        });
    },
    OpenBusines() {
      this.busines.dialog.form = true;
      // this.busines.form.input.fieldfrom = policyid
      // this.busines.form.input.fieldto = fieldto
      // this.ListFeedOpenBusines(id, fieldfrom, fieldto)
      this.AddBusOpenBusines();
    },
    AddBusOpenBusines() {
      this.url_type = this.url_type;
      this.busines.form.input.profile_id = "";
      this.busines.form.input.address = "";
      setTimeout(function () {
        document.getElementById("busines_from").focus();
      }, 500);
    },
    Payors() {
      this.loading.payors = true;
      let formData = new FormData();
      this.$axios
        .post(this.$functions.SafeURL("apiListPayors", ``), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            alert("No Data");
          } else {
            this.form.pp_tab_payor_items_payors = response.data;
          }
          this.loading.payors = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading.payors = false;
        });
    },
    FeeDuty() {
      this.loading.feeduty = true;
      let formData = new FormData();
      this.$axios
        .post(this.$functions.SafeURL("apiListFeeDuty", ``), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            alert("No Data");
          } else {
            this.form.pp_tab_payor_items_feeduty = response.data;
          }
          this.loading.feeduty = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading.feeduty = false;
        });
    },
    OpenFeeduty() {
      this.feeduty.dialog.form = true;
      // this.feeduty.form.input.fieldfrom = policyid
      // this.feeduty.form.input.fieldto = fieldto
      // this.ListFeedOpenFeeduty(id, fieldfrom, fieldto)
      this.AddFeeduty();
    },
    AddFeeduty() {
      this.url_type = this.url_type;
      this.feeduty.form.input.profile_id = "";
      this.feeduty.form.input.address = "";
      setTimeout(function () {
        document.getElementById("feeduty_from").focus();
      }, 500);
    },
  },
};
</script>
  