<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Usersact"></app-drawer_menu>
	  	<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if = "this.access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_Usersact" lazy-validation>
			<v-row class="mx-2">
				<v-col cols="12" sm="12" md="12">
					<v-btn
						color		    ="tertiary"
						class		    ="white--text"
						:disabled   = "access.add === 0 ? true:false" 
						rounded
						@click 	    = "Add" 
						small
					>
						<v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
					</v-btn> &nbsp;
					<v-btn
						color		="primary"
						class		="white--text"
						:disabled = "access.add === 1 || access.edit === 1 ? false:true"
						rounded
						@click 	= "Confirm('write','')" 
						small
					>
						<v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
					</v-btn>
				</v-col>
              <!-- ID -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	= "form.usersact_userid"
                  required 
                  :rules 	  	="form.usersact_userid_rules"
                  :readonly     = "form.usersact_userid_readonly"
				  :maxlength	="maxinput.id"
				  :counter		="maxinput.id"
                  label       	= "ID *"
                  placeholder 	= "ID"
                  id 			= "usersact_userid"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- Attempt Number -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	= "form.usersact_attemptnum"
                  required 
                  :rules 	  	="form.usersact_attemptnum_rules"
                  type          ="number"
                  label       	= "Attempt Number"
                  placeholder 	= "Attempt Number"
                  id 			= "usersact_attemptnum"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               	<v-col cols="12" sm="4" md="4" class="mt-n5">
					<label>Active Status</label>
					<v-switch
						v-model		="form.usersact_actived"
						color  		= "primary"
						:label 		="form.usersact_actived !== true ? '' : 'Active'" 
					></v-switch>
                </v-col>
			</v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<v-card-title>
				<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
					<template v-slot:activator="{ on }">
						<button class="AlertButton ErrorButton" v-on="on">
							<div class="blobs-container">
								<v-icon class="blob red mt-1 mr-2">error_outline</v-icon>
								Open Error Message
							</div>
						</button>
					</template>
					<v-card>
						<v-toolbar dark color="primary">
						<v-btn icon dark @click="remarks.dialog = false">
							<v-icon>{{$vars.V('icon_close')}}</v-icon>
						</v-btn>
						<v-toolbar-title>Data Failed</v-toolbar-title>
						</v-toolbar>
						<v-list three-line subheader>
							<v-list-item>
								<v-list-item-content>
								<v-list-item-title>Daftar Data yang gagal diproses</v-list-item-title>
								<v-list-item-subtitle><div class="ml-4" v-html="remarks.feedback"></div></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-dialog>
			</v-card-title>
			<template>
			  <v-card-title>
	                <v-btn 
	                	color = "error"
	                	title = "Delete"
	                	@click = "Confirm('multidelete','')"
	                	v-if  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false">
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-btn 
	                	color  = "primary"
	                	title  = "Search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true">
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    v-model="selected"
			    :headers="headers"
			    :items="items"
			    :single-select="singleselect"
			    :search="search"
			    item-key="usersact_userid"
			    show-select
			    class="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			  >
			    <template v-slot:top>
			      <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
			    </template>
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class="mr-2"
			        @click="Edit(item.usersact_userid)"
			        :disabled = "access.edit === 0 ? true:false"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click="Confirm('delete',item.usersact_userid)"
			        :disabled = "access.delete === 0 ? true:false"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
            <template  slot="items" slot-scope="row">
                <tr>
                    <td class="text-xs-left">{{row.item.usersact_userid}}</td>
                    <td class="text-xs-left">{{row.item.usersact_attemptnum}}</td>
                    <td class="text-xs-left">Edit</td>
                </tr>
            </template>
		  	</v-tab-item>
  	</v-tabs>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      absolute
      top
      style 	= "z-index: 9999;"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
	</v-snackbar>
	<v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
		<v-card>
			<v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
			<v-card-text>
				
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn id="dialog" color="success"
					:disabled   = "confirm.text === 'Ok' ? false:true"
					@click      = "Loading">{{confirm.text}}</v-btn>
				<v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
  </div>
</template>
<script>
export default {
  	data: () => ({
		  url_type: 'add',
		  cont: false,
  		access: {
  			read: 0,
  			add: 0,
  			edit: 0,
  			delete: 0,
		  },
		maxinput:{
			id:20,
		},
  		snackbar_dialog: false,
	    snackbar_color: 'success',
	    snackbar_text: '',
	    snackbar_timeout: 3000,
	    confirm: {
	    	dialog: false,
	    	title: '',
	    	ok_text: 'Ok',
	    	ok_disabled: false,
	    },
        singleselect: false,
        selected: [],
        search: '',
        headers: [
          {
            text: 'ID',
            align: 'left',
            value: 'usersact_userid',
          },
          { text: 'Attempt Number', value: 'usersact_attemptnum' },
          { text: 'Actions', value: 'action', sortable: false }
		],
		remarks: {
			feedback:'',
			dialog: false,
		},
		items: [],
		feeds: [],
		accessmmenu: [],
        loading: {
          list : false
        },
        btn_search: false,
        form: {
        	usersact_userid: '',
        	usersact_userid_rules: [v => !!v || 'Please fill ID'],
        	usersact_userid_readonly: false,
        	usersact_attemptnum: '',
        	usersact_attemptnum_rules: [v => !!v || 'Please fill Attempt Number'],
        	usersact_actived: true,
        },
        loader: null,
        loading_save: false
      	}),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
			//this function use for validating the screen with user privilleges
  			this.Access()
  			setTimeout(function(){
                document.getElementById('usersact_userid').focus()
            }, 500)
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access () {
			let formdata = {
				menu_id: 'usersact',
				users_id: this.$functions.UsersID(),
				order_by: 'menu_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiListMenuUser'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let priv = this.$functions.DecodeJWT(response.data)
				if (priv.length > 0) {
                	this.access.read 	= priv[0].usersmenu_read
                	this.access.add  	= priv[0].usersmenu_add
                	this.access.edit    = priv[0].usersmenu_edit
                	this.access.delete  = priv[0].usersmenu_delete
                	if (priv[0].usersmenu_read === 0) {
                		this.NoPriviledge()
                	}
                } else {
                	this.NoPriviledge()
                }
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.list = false
			})
		},
		//This function use for give feedback when user has no access with some feature
        NoPriviledge () {
        	this.SnackBar(true, 'error', 'You dont have access `UsersAct` modul, please contact Administrator', 30000)
        },
		//Function for get all data from table usersact with API in mod_users.go
		List () {
			let formdata = {
					usersact_userid: '',
					usersact_attemptnum: '',
					order_by: 'usersact_userid',
					order_type: 'ASC',
					limit: this.limit,
			}
			let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiListUsersAct'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				this.items = this.$functions.DecodeJWT(response.data)
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.list = false
			})
		},  
		//Pop Up message for validating (Ok or Cancel) your proccess
	  	Confirm (flag, id) {
	  		if (flag === 'write') {
		  		if (this.$refs.form_usersact.validate()) {
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
		  		}
	  		} else if (flag === 'delete') {
	  			this.url_type 		  = 'delete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ id + '`?'
	  			this.usersact_userid 	  = id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multidelete') {
	  			this.url_type 		  = 'multidelete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		}
		},
		//Function to request insert data to table usersact 'The API will validating your data, include the user who process the data' (go to APIWriteUsersAct with url_type = "add") 
	  	Add () {
	  		this.url_type 		 			= 'add'
	  		this.form.usersact_userid 	 	= ''
	  		this.form.usersact_attemptnum 	= ''
	  		this.form.usersact_actived 		= true
	  		this.form.usersact_userid_readonly = false
	  		setTimeout(function(){
                document.getElementById('usersact_userid').focus()
            }, 500)
		},
		//Function to request update data to table usersact 'The API will validating your data, include the user who process the data' (go to APIWriteUsersAct with url_type = "edit") 
		Edit (id) {
			let formdata = {
				usersact_userid: id,
				usersact_attemptnum: '',
				order_by: 'usersact_userid',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiListUsersAct'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
            .then(response => {
				this.feeds = this.$functions.DecodeJWT(response.data)
				if (this.feeds[0].feedback === 'Y') {
            		document.getElementById('tabform').click()
            		this.url_type 			    = 'edit'
            		this.form.usersact_userid   	    = this.feeds[0].usersact_userid
            		this.form.usersact_attemptnum 	    = this.feeds[0].usersact_attemptnum
            		this.form.usersact_userid_readonly = true
					setTimeout(function(){
                		document.getElementById('usersact_attemptnum').focus()
            		}, 500)
	  			} else {
					this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
					this.remarks.dialog = true
					this.remarks.feedback = this.feeds[0].feedback
				}
			})
			.catch(e => {
				this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
		Delete (id) {
			let formdata = {
				usersact_userid: id,
			}
	  		let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiDeleteUsersAct'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				this.feeds = this.$functions.DecodeJWT(response.data)
				if (this.feeds[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Delete Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.ok_text 	 = 'Ok'
					this.confirm.ok_disabled = false
					this.url_type 			 = ''
					this.List()
	  			} else {
					this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
					this.remarks.dialog = true
					this.remarks.feedback = this.feeds[0].feedback
				}
			})
			.catch(e => {
				this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
	  	MultiProcess () {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				data_multi: multiData,
			}
			let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiWriteUsersAct'),encode,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				this.items = this.$functions.DecodeJWT(response.data)
				if (this.items[0].feedback === 'Y') {
					let feed
					if (this.items[0].feedback_failed == 0){
						feed = `Success : ${this.items[0].feedback_success}, Failed : ${this.items[0].feedback_failed}`
					} else {
						this.remarks.feedback = this.$functions.FeedbackReplacer(this.items[0].feedback_failed_remarks)
						feed = `Success : ${this.items[0].feedback_success}, Failed : ${this.items[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, 'success', feed, 5000)
					this.confirm.dialog  	 = false
					this.confirm.ok_text 	 = 'Ok'
					this.confirm.ok_disabled = false
					this.url_type 			 = ''
					this.List()
				} else {
					this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
					this.remarks.dialog = true
					this.remarks.feedback = this.feeds[0].feedback
				}
			})
			.catch(e => {
				this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
	  	Loading () {
	  		this.confirm.ok_text 	 = 'Loading...'
	  		this.confirm.ok_disabled = true
	  		if (this.url_type === 'add' || this.url_type === 'edit') {
	  			this.Write()
	  		} else if (this.url_type === 'delete') {
	  			this.Delete(this.usersact_userid)
	  		} else if (this.url_type === 'multidelete') {
	  			this.MultiProcess()
	  		}
	  	},
		Write () {
	  		let formdata = {
				url_type: this.url_type,
				usersact_userid: this.form.usersact_userid,
				usersact_attemptnum: this.form.usersact_attemptnum,
				usersact_actived: this.form.usersact_actived,
			}
	  		let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiWriteUsersAct'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = this.$functions.DecodeJWT(response.data)
				if (feedback[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Save Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.text 	 	 = 'Ok'
		  			this.url_type 			 = 'edit'
	  			} else {
	  				this.SnackBar(true, 'error', 'Check Error Message', 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback[0].feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', 'Check Error Message', 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
	  	SnackBar (dialog, color, text, timeout) {
	      this.snackbar = {
	      	dialog: dialog,
	      	color: color,
	      	text: text,
	      	timeout: timeout
	      }
	      if (color === 'error') {
	      	this.confirm.dialog 	 = false
	      	this.confirm.text 	 	 = 'Ok'
	      }
	    }
	}
}
</script>