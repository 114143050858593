<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Interest"></app-drawer_menu>
	  	<v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
	  	<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if = "access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_interest" lazy-validation>
			<v-row class="mx-2 ma-1">
				<v-col cols="12" sm="12" md="12">
					<v-btn
				      color		    ="tertiary"
				      class			="white--text"
				      :disabled     = "access.add === 0 ? true:false" 
				      rounded
				      @click 	    = "Add" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
				    </v-btn> &nbsp;
				    <v-btn
				      color		="primary"
				      class		="white--text"
				      :disabled = "access.add === 0 || access.edit === 0 ? true:false"
				      rounded
				      @click 	= "Confirm('write','')" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
				    </v-btn>
				</v-col>
              <!-- ID -->
               <v-col cols="12" sm="2" md="2">
                  <v-text-field
                  v-model 	  	= "form.interest_id"
                  required
                  :rules 	  	="form.interest_id_rules"
                  :readonly     = "form.interest_id_readonly"
                  :background-color = "form.interest_id_readonly ? 'readonly' : ''"
				  :maxlength 	="maxinput.id"
				  :counter		="maxinput.id"
                  label       	= "ID *"
                  placeholder 	= "ID"
                  id 			= "interest_id"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- Desc -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field
                  v-model 	  	= "form.interest_desc"
                  required
                  :rules        ="form.interest_desc_rules"
				  :maxlength 	="maxinput.desc"
                  label       	= "Description *"
                  placeholder 	= "Description"
                  id 			= "interest_desc"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
                <v-col cols="12" sm="2" md="2">
                    <v-checkbox v-model="form.interest_adjustable" label="Adjustable"></v-checkbox>
                </v-col>
                <!-- ADjust Premium -->
                <v-col cols="12" sm="4" md="4">
                <v-text-field 
                v-model.lazy 	  ="form.interest_adjustpremium"
                ref               = "interest_adjustpremium"
                label       	  = "Adjust premium"
                :maxlength        ="maxinput.premi"
                placeholder 	  = "Rp 0"
                background-color  = "numeric"
                v-money           = "format_money"
                @keyup.enter 	  = "Confirm('write','')"
                reverse
                >
                </v-text-field>
                </v-col>
               <!-- Adjust Method -->
               <!-- <v-col cols="12" sm="4" md="4">
                  <v-text-field
                  v-model 	  	= "form.interest_adjustmethod"
                  required
                  :rules        ="form.interest_adjustmethod_rules"
				  :maxlength 	="maxinput.method"
                  label       	= "Adjust Method *"
                  placeholder 	= "Adjust Method"
                  id 			= "interest_adjustmethod"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col> -->
                <v-col cols="12" sm="2" md="2">
                    <v-checkbox v-model="form.interest_agreedvalue" label="Agreed Value"></v-checkbox>
                </v-col>
			   <!-- Active -->
               <v-col cols="12" sm="2" md="2" class="mt-n5">
					<label class="caption">Active Status</label>
					<v-switch
						v-model	="form.interest_actived"
						color  	= "primary"
						id 		= "form.interest_actived"
						:label 	="form.interest_actived !== true ? '' : 'Active'" 
					></v-switch>
				</v-col>
            </v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
			   		<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.interest_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.interest_cuser)">{{form.interest_cuser}}</span>
Last Update Date : {{form.interest_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.interest_luser)">{{form.interest_luser}}</span>
</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
			<v-tabs
				background-color="white"
				color="fourth"
				v-show="url_type !== 'add' ? true:false "
			>
				<v-tab
					href   	 ="#tab-noc"
					@click 	 = "ListNOC(form.interest_id)"
				>
					Nature of Loss
				</v-tab>
				<v-tab 
					href="#tab-lossitem"
					@click = "ListLossItem(form.interest_id)"
				>
					Loss Item
				</v-tab>
				<v-tab-item
					value  ="tab-noc"
				>
					<template>
						<v-card-title 
							class="ml-n4"
						>
							<v-btn 
								:color 	="selected_natureclaimint.length === items_natureclaimint.length ? 'fourth':''"
								@click	="SelectAllPageNOC"
								class	="mr-2"
								title	="select all page"
								small
								>{{$vars.V('txt_select_all_page')}}
							</v-btn>
							<v-btn 
								color     = "error"
								title 	  = "delete selected"
								@click 	  = "ConfirmNOC('multidelete','')"
								class	  = "mr-2"
								v-if  	  = "selected_natureclaimint.length > 0"
								:disabled = "access.delete === 0 ? true:false"
								small>
								<v-icon>{{$vars.V('icon_delete')}}</v-icon>
							</v-btn>    
							<v-btn 
								@click	="AddNOC"
								class	="mr-2"
								title	="Add New"
								small
								>Add
							</v-btn>
							<v-btn 
								color  = "primary"
								title  = "search"
								v-if   = "btn_search_natureclaimint === false"
								@click = "btn_search_natureclaimint = true"
								small>
								<v-icon>{{$vars.V('icon_search')}}</v-icon>
							</v-btn>
							<v-spacer></v-spacer>
							<v-text-field
							v-model="search_natureclaimint"
							append-icon="search"
							@click:append="btn_search_natureclaimint = false"
							label="Search"
							single-line
							hide-details
							v-if ="btn_search_natureclaimint"
							></v-text-field>
						</v-card-title>
						<v-data-table
						:value="selected_natureclaimint"
						:headers="headers_natureclaimint"
						:items="items_natureclaimint"
						:search="search_natureclaimint"
						@input="CheckNOC($event)"
						item-key="natureclaimint_natureclaimid"
						show-select
						class="elevation-1"
						 :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
							showFirstLastPage: true,
							firstIcon: 'first_page',
							lastIcon: 'last_page',
							prevIcon: 'keyboard_arrow_left',
							nextIcon: 'keyboard_arrow_right'
						}"
						dense
						:loading 	= "loading_noc.list"
						loading-text="Loading... Please wait"
						>
						<template v-slot:item.action="{ item }">
							<v-icon
							small
							@click	  ="ConfirmNOC('delete',item.natureclaimint_natureclaimid)"
							:disabled = "access.delete === 0 ? true:false"
							title 	  = "delete"
							>
							delete
							</v-icon>
						</template>
						</v-data-table>
					</template>
				</v-tab-item>
				<v-tab-item
					value  ="tab-lossitem"
				>
					<template>
						<v-card-title 
							class="ml-n4"
						>
							<v-btn 
								:color 	="selected_lossitem.length === items_lossitem.length ? 'fourth':''"
								@click	="SelectAllPageLossItem"
								class	="mr-2"
								title	="select all page"
								small
								>{{$vars.V('txt_select_all_page')}}
							</v-btn>
							<v-btn 
								color     = "error"
								title 	  = "delete selected"
								@click 	  = "ConfirmLossItem('multidelete','')"
								class	  = "mr-2"
								v-if  	  = "selected_lossitem.length > 0"
								:disabled = "access.delete === 0 ? true:false"
								small>
								<v-icon>{{$vars.V('icon_delete')}}</v-icon>
							</v-btn>    
							<v-btn 
								@click	="AddLossItem"
								class	="mr-2"
								title	="Add New"
								small
								>Add
							</v-btn>
							<v-btn 
								color  = "primary"
								title  = "search"
								v-if   = "btn_search_lossitem === false"
								@click = "btn_search_lossitem = true"
								small>
								<v-icon>{{$vars.V('icon_search')}}</v-icon>
							</v-btn>
							<v-spacer></v-spacer>
							<v-text-field
							v-model="search_lossitem"
							append-icon="search"
							@click:append="btn_search_lossitem = false"
							label="Search"
							single-line
							hide-details
							v-if ="btn_search_lossitem"
							></v-text-field>
						</v-card-title>
						<v-data-table
						:value="selected_lossitem"
						:headers="headers_lossitem"
						:items="items_lossitem"
						:search="search_lossitem"
						@input="CheckLossItem($event)"
						item-key="lossitemint_lossitemid"
						show-select
						class="elevation-1"
						 :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
							showFirstLastPage: true,
							firstIcon: 'first_page',
							lastIcon: 'last_page',
							prevIcon: 'keyboard_arrow_left',
							nextIcon: 'keyboard_arrow_right'
						}"
						dense
						:loading 	= "loading_noc.list"
						loading-text="Loading... Please wait"
						>
						<template v-slot:item.action="{ item }">
							<v-icon
							small
							@click	  ="ConfirmLossItem('delete',item.lossitemint_lossitemid)"
							:disabled = "access.delete === 0 ? true:false"
							title 	  = "delete"
							>
							delete
							</v-icon>
						</template>
						</v-data-table>
					</template>
				</v-tab-item>
			</v-tabs>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  <v-card-title 
			  		class="ml-n4"
			  	>
			  		<v-btn 
					  	:color 	="selected.length === items.length ? 'fourth':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	title	="select all page"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
					</v-btn>
	                <v-btn 
	                	color     = "error"
	                	title 	  = "delete selected"
	                	@click 	  = "Confirm('multidelete','')"
	                	class	  = "mr-2"
	                	v-if  	  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false"
	                	small>
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-btn 
	                	color  = "primary"
	                	title  = "search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true"
	                	small>
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    :value="selected"
			    :headers="headers"
			    :items="items"
			    :search="search"
			    @input="Check($event)"
			    item-key="interest_id"
			    show-select
			    class="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			    dense
			    :loading 	= "loading.list"
    			loading-text="Loading... Please wait"
			  >
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class	  ="mr-2"
			        @click	  ="Edit(item.interest_id)"
			        :disabled = "access.edit === 0 ? true:false"
			        title 	  = "edit"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click	  ="Confirm('delete',item.interest_id)"
			        :disabled = "access.delete === 0 ? true:false"
			        title 	  = "delete"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
		</v-tab-item>
  	</v-tabs>
	<v-dialog
      v-model="dialog.natureclaim"
      max-width="400"
      transition="dialog-transition"
    >
      <v-card>
		<v-toolbar dark color="primary" dense class="mb-2">
		<v-btn icon dark @click="Close('natureclaim')">
			<v-icon>{{$vars.V('icon_close')}}</v-icon>
		</v-btn>
		<v-toolbar-title>Nature of Loss</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-btn icon dark @click="AddNOC" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
		<v-btn icon dark @click="ConfirmNOC(url_type_noc,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
		</v-toolbar>
        <v-card-text>
			<v-row class="mx-auto mt-5">
                    <app-cb
                    cb_type             = "natureclaim"
                    cb_url              = "apiListNatureClaim"
                    cb_url_parameter    = "natureclaim_actived=Y"
                    cb_title            = "Nature of Loss"
                    cb_id               = "natureclaimint_natureclaimid"
                    cb_desc             = "natureclaimint_natureclaimdesc"
                    cb_rules            = "Please fill Nature of Loss"
                    cb_desc_readonly    = "readonly"
                    cb_items_id         = "natureclaim_id"
                    cb_items_desc       = "natureclaim_desc"
                    :cb_value_id        = "form.natureclaimint_natureclaimid"
                    :cb_value_desc      = "form.natureclaimint_natureclaimdesc"
                    >
                    </app-cb>
			</v-row>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
        </v-card>
    </v-dialog>
	<v-dialog
      v-model="dialog.lossitem"
      max-width="400"
      transition="dialog-transition"
    >
      <v-card>
		<v-toolbar dark color="primary" dense class="mb-2">
		<v-btn icon dark @click="Close('lossitem')">
			<v-icon>{{$vars.V('icon_close')}}</v-icon>
		</v-btn>
		<v-toolbar-title>Loss Item</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-btn icon dark @click="AddLossItem" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
		<v-btn icon dark @click="ConfirmLossItem(url_type_lossitem,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
		</v-toolbar>
        <v-card-text>
			<v-row class="mx-auto mt-5">
                    <app-cb
                    cb_type             = "lossitem"
                    cb_url              = "apiListLossItem"
                    cb_url_parameter    = "lossitem_actived=Y"
                    cb_title            = "Loss of Item"
                    cb_id               = "lossitemint_lossitemid"
                    cb_desc             = "lossitemint_lossitemdesc"
                    cb_rules            = "Please fill Loss of Item"
                    cb_desc_readonly    = "readonly"
                    cb_items_id         = "lossitem_id"
                    cb_items_desc       = "lossitem_desc"
                    :cb_value_id        = "form.lossitemint_lossitemid"
                    :cb_value_desc      = "form.lossitemint_lossitemdesc"
                    >
                    </app-cb>
			</v-row>
        </v-card-text>
        <v-card-actions>
        	
        </v-card-actions>
        </v-card>
    </v-dialog>
	<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      top
      style 	= "z-index: 9999"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
  </v-snackbar>
	<v-dialog v-model="confirm_noc.dialog" max-width="490" hide-overlay persistent>
        <v-card>
            <v-card-title class="primary white--text">{{confirm_noc.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
            	<v-spacer></v-spacer>
            	<v-btn id="dialog" color="success"
            		   :disabled   = "confirm_noc.text === 'Ok' ? false:true"
      				   @click      = "LoadingNOC">{{confirm_noc.text}}</v-btn>
                <v-btn color="error" @click="confirm_noc.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
	<v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
	<!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>
<script>
export default {
	data: () => ({
		url_type: "add",
		url_type_noc: 'add',
		url_type_lossitem: 'add',
		cont: false,
		access: {
			read: 0,
			add: 0,
			edit: 0,
			delete: 0,
		},
        format_money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        },
		maxinput: {
			id: 6,
			desc: 150,
            premi: 5,
            method: 10,
		},
		snackbar: {
			dialog: false,
			color: "success",
			text: "",
			timeout: 3000,
		},
		confirm: {
			dialog: false,
			title: "",
			text: "Ok",
		},
		confirm_noc: {
			dialog: false,
			title: '',
			text: 'Ok',
		},
		confirm_lossitem: {
			dialog: false,
			title: '',
			text: 'Ok',
		},
		dialog: {
			natureclaim: false,
			lossitem: false,
			users: false,
		},
		selected: [],
		search: "",
		headers: [
			{
				text: "ID",
				align: "left",
				value: "interest_id",
			},
			{ text: "Description", value: "interest_desc" },
			{ text: "Actived", value: "interest_actived" },
			{ text: "Actions", value: "action", sortable: false },
		],
		selected_natureclaimint: [],
		search_natureclaimint: "",
        headers_natureclaimint: [
			{ text: 'Nature of Loss', value: 'natureclaimint_natureclaimid' },
			{ text: 'Desc', value: 'natureclaimint_natureclaimdesc' },
			{ text: 'Actions', value: 'action', sortable: false }
		],
		selected_lossitem: [],
		search_lossitem: "",
        headers_lossitem: [
			{ text: 'Kind of Loss ID', value: 'lossitemint_lossitemid' },
			{ text: 'Desc', value: 'lossitemint_lossitemdesc' },
			{ text: 'Actions', value: 'action', sortable: false }
		],
		remarks: {
			feedback: "",
			dialog: false,
		},
		items: [],
		items_natureclaimint: [],
		items_lossitem: [],
		accessmenu: [],
		loading: {
			page: true,
			list: false,
		},
		loading_noc: {
			page: true,
			list : false
		},
		loading_lossitem: {
			page: true,
			list : false
        },
		btn_search: false,
        btn_search_natureclaimint: false,
		btn_search_lossitem: false,
		form: {
			interest_id: "",
			interest_id_rules: [(v) => !!v || "Please fill ID"],
			interest_id_readonly: false,
			interest_desc: "",
			interest_desc_rules: [(v) => !!v || "Please fill Description"],
			interest_adjustable: false,
			// interest_adjustmethod: "",
			// interest_adjustmethod_rules: [(v) => !!v || "Please fill Adjust Method"],
			interest_agreedvalue: false,
			interest_actived: true,
			natureclaimint_natureclaimid: '',
			natureclaimint_natureclaimdesc: '',
			lossitem_id: '',
			display_users: '',
		},			
	}),
	created() {
		this.FirstLoad()
	},
	methods: {
		FirstLoad() {
			//this function use for validating the screen with user privilleges
			this.Access()
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "interest"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let priv = response.data
				if (priv.length > 0) {
					this.access.read = priv[0].usersmenu_read
					this.access.add = priv[0].usersmenu_add
					this.access.edit = priv[0].usersmenu_edit
					this.access.delete = priv[0].usersmenu_delete
					if (priv[0].usersmenu_read === 0) {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					}
				} else {
					this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
				}
				this.loading.page = false
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
				this.loading.page = false
			})
		},
		SelectAllPage() {
			this.selected = this.selected === this.items ? [] : this.items
		},
		SelectAllPageNOC () {
	      this.selected_natureclaimint = this.selected_natureclaimint === this.items_natureclaimint ? [] : this.items_natureclaimint
	    },
		SelectAllPageLossItem () {
	      this.selected_lossitem = this.selected_lossitem === this.items_lossitem ? [] : this.items_lossitem
	    },
		Check(value) {
			this.selected = value
		},
	    CheckNOC (value) {
	      this.selected_natureclaimint = value
	    },
	    CheckLossItem (value) {
	      this.selected_lossitem = value
	    },
		//Function for get all data from table interest with API in mod_users.go
		List() {
			this.loading.list = true
			let formdata = {
				interest_id: "",
				interest_desc: "",
				order_by: "interest_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListInterest"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback !== "Y") {
							this.SnackBar(
								true,
								"error",
								feedback[0].feedback,
								0
							)
						} else {
							this.items = feedback
						}
					} else {
						this.items = feedback
						// this.SnackBar(true, 'error', this.$functions.NoData(), 0)
					}
					this.loading.list = false
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 3000)
					this.loading.list = false
				})
		},
        ListNOC (id) {
			this.loading_noc.list = true
			let formdata = {
				natureclaimint_interestid: id,
				order_by: 'natureclaimint_natureclaimid',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListNatureClaimInt'),param,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.items_natureclaimint = feedback
					}
				} else {
					this.items_natureclaimint = feedback
					// this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading_noc.list = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading_noc.list = false
			})
		},
        ListLossItem (id) {
			this.loading_lossitem.list = true
			let formdata = {
				lossitemint_interestid: id,
				order_by: 'lossitemint_interestid',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListLossItemInt'),param,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.items_lossitem = feedback
					}
				} else {
					this.items_lossitem = feedback
					// this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading_noc.list = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading_noc.list = false
			})
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
		Confirm(flag, id) {
			if (flag === "write") {
				if (this.$refs.form_interest.validate()) {
					this.confirm.dialog = true
					this.confirm.title = "Save"
					setTimeout(function() {
						document.getElementById("dialog").focus()
					}, 500)
				}
			} else if (flag === "delete") {
				this.url_type = "delete"
				this.confirm.dialog = true
				this.confirm.title = "Delete `" + id + "`"
				this.interest_id = id
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag === "multidelete") {
				this.url_type = "multidelete"
				this.confirm.dialog = true
				this.confirm.title = "Delete `" + this.selected.length + "` data"
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			}
		},
		ConfirmNOC (flag, id) {
			if (flag === 'write') {
				this.confirm_noc.dialog   	= true
				this.confirm_noc.title    	= 'Save'
				this.url_type_noc 			= 'add'
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'delete') {
				this.url_type_noc	  = 'delete'
				this.confirm_noc.dialog   = true
				this.confirm_noc.title    = 'Delete Nature Claim`'+ id + '` from Cause of Loss `' + this.form.interest_id +'`'
				this.form.natureclaimint_natureclaimid = id
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'multidelete') {
				this.url_type_noc 		  = 'multidelete'
				this.confirm_noc.dialog   = true
				this.confirm_noc.title    = 'Delete `'+ this.selected_natureclaimint.length + '` data'
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			}
		},
		ConfirmLossItem (flag, id) {
			if (flag === 'write') {
				this.confirm_lossitem.dialog   	= true
				this.confirm_lossitem.title    	= 'Save'
				this.url_type_lossitem 			= 'add'
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'delete') {
				this.url_type_lossitem	  = 'delete'
				this.confirm_lossitem.dialog   = true
				this.confirm_lossitem.title    = 'Delete Loss Item `'+ id + '` from Interest `' + this.form.interest_id +'`'
				this.form.lossitem_id 	  = id
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			} else if (flag === 'multidelete') {
				this.url_type_lossitem 		  = 'multidelete'
				this.confirm_lossitem.dialog   = true
				this.confirm_lossitem.title    = 'Delete `'+ this.selected_lossitem.length + '` data'
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			}
		},
		//Function to request insert data to table interest 'The API will validating your data, include the user who process the data' (go to APIWriteInterest with url_type = "add")
		Add() {
			this.url_type = "add"
			this.form.interest_id = ""
			this.form.interest_desc = ""
			this.form.interest_actived = true
			this.form.interest_id_readonly = false
			setTimeout(function() {
				document.getElementById("interest_id").focus()
			}, 500)
		},
        AddNOC() { 
            this.dialog.natureclaim = true
			this.url_type_noc = 'write'
			this.form.natureclaimint_natureclaimid = ''
			this.form.natureclaimint_natureclaimdesc = ''
			document.getElementById('natureclaimint_natureclaimid').value = ''
			document.getElementById('natureclaimint_natureclaimdesc').value = ''
        },
        AddLossItem() { 
            this.dialog.lossitem = true
			this.url_type_lossitem = 'write'
			this.form.lossitemint_lossitemid = ''
			this.form.lossitemint_lossitemdesc = ''
			document.getElementById('lossitemint_lossitemid').value = ''
			document.getElementById('lossitemint_lossitemdesc').value = ''
        },
		//Function to request update data to table interest 'The API will validating your data, include the user who process the data' (go to APIWriteInterest with url_type = "edit")
		Edit(id) {
			this.loading.list = true
			let formdata = {
				interest_id: id,
				interest_desc: "",
				order_by: "interest_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListInterest"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						document.getElementById("tabform").click()
						this.url_type 																	= "edit"
						this.form.interest_id 															= feedback[0].interest_id
						this.form.interest_desc 														= feedback[0].interest_desc
						this.form.interest_adjustable 													= this.$functions.TrueOrFalse(feedback[0].interest_adjustable)
						// this.form.interest_adjustmethod 												= feedback[0].interest_adjustmethod
						this.$refs.interest_adjustpremium.$el.getElementsByTagName('input')[0].value 	= feedback[0].interest_adjustpremium
						this.form.interest_agreedvalue 													= this.$functions.TrueOrFalse(feedback[0].interest_agreedvalue)
						this.form.interest_actived 														= this.$functions.TrueOrFalse(feedback[0].interest_actived)
						this.form.interest_cdate 														= feedback[0].interest_cdate
						this.form.interest_cuser 														= feedback[0].interest_cuser
						this.form.interest_ldate 														= feedback[0].interest_ldate
						this.form.interest_luser 														= feedback[0].interest_luser
						this.form.interest_id_readonly 													= true
						setTimeout(function() {
							document.getElementById("interest_desc").focus()
						}, 500)
						this.ListNOC(feedback[0].interest_id)
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(true, "error", feedback, 0)
				}
				this.loading.list = false
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog = true
				this.remarks.feedback = e
				this.loading.list = false
			})
		},
		Delete(id) {
			let formdata = {
				url_type: "delete",
				interest_id: id,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteInterest"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"success",
							"Delete Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						this.selected = []
						this.List()
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
		DeleteNOC (intid, id) {
			let formdata = {
				url_type: 'delete',
				natureclaimint_interestid: intid,
				natureclaimint_natureclaimid: id
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteNatureClaimInt'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Delete Success', 3000)
		            	this.confirm_noc.dialog  	= false
		                this.confirm_noc.text 		= 'Ok'
		                this.selected_natureclaimint 	= []
						this.ListNOC(intid)
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
		DeleteLossItem (intid,id) {
			let formdata = {
				url_type: 'delete',
				lossitemint_lossitemid: id,
				lossitemint_interestid: intid,
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteLossItemInt'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Delete Success', 3000)
		            	this.confirm_lossitem.dialog  	= false
		                this.confirm_lossitem.text 		= 'Ok'
		                this.selected_lossitem 	= []
						this.ListLossItem(this.form.interest_id)
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
		MultiProcess() {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteInterest"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback[0].feedback === "Y") {
					let feed
					if (feedback[0].feedback_failed == 0) {
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					} else {
						this.remarks.feedback = feedback[0].feedback_failed_remarks
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, "success", feed, 0)
					this.confirm.dialog = false
					this.confirm.text 	= "Ok"
					this.selected 		= []
					this.List()
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = feedback[0].feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
		MultiProcessNOC () {
			let multiData = JSON.stringify(this.selected_natureclaimint)
			let formdata = {
				url_type: this.url_type_noc,
				multi_data: multiData,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteNatureClaimInt'),param,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				let feedback = response.data
				if (feedback[0].feedback === 'Y') {
					let feed
					if (feedback[0].feedback_failed == 0){
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					} else {
						this.remarks.feedback = feedback[0].feedback_failed_remarks
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, 'success', feed, 0)
					this.confirm_noc.dialog  	 = false
					this.confirm_noc.text 	 	 = 'Ok'
					this.selected_natureclaimint 	 = []
					this.ListNOC(this.form.interest_id)
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback[0].feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
		MultiProcessLossItem () {
			let multiData = JSON.stringify(this.selected_lossitem)
			let formdata = {
				url_type: this.url_type_lossitem,
				multi_data: multiData,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteLossItemInt'),param,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				let feedback = response.data
				if (feedback[0].feedback === 'Y') {
					let feed
					if (feedback[0].feedback_failed == 0){
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					} else {
						this.remarks.feedback = feedback[0].feedback_failed_remarks
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, 'success', feed, 0)
					this.confirm_lossitem.dialog  	 = false
					this.confirm_lossitem.text 	 	 = 'Ok'
					this.selected_lossitem 	 = []
					this.ListLossItem(this.form.interest_id)
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback[0].feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
		Loading() {
			this.confirm.text = "Loading..."
			if (this.url_type === "add" || this.url_type === "edit") {
				this.Write()
			} else if (this.url_type === "delete") {
				this.Delete(this.interest_id)
			} else if (this.url_type === "multidelete") {
				this.MultiProcess("delete")
			}
		},
	  	LoadingNOC () {
	  		this.confirm_noc.text = 'Loading...'
	  		if (this.url_type_noc === 'add' || this.url_type_noc === 'edit') {
	  			this.WriteNOC()
	  		} else if (this.url_type_noc === 'delete') {
	  			this.DeleteNOC(this.form.interest_id,this.natureclaimint_natureclaimid)
	  		} else if (this.url_type_noc === 'multidelete') {
	  			this.MultiProcessNOC('delete')
	  		}
	  	},
	  	LoadingLossItem () {
	  		this.confirm_lossitem.text = 'Loading...'
	  		if (this.url_type_lossitem === 'add' || this.url_type_lossitem === 'edit') {
	  			this.WriteLossItem()
	  		} else if (this.url_type_lossitem === 'delete') {
	  			this.DeleteLossItem(this.form.interest_id,this.form.lossitem_id)
	  		} else if (this.url_type_lossitem === 'multidelete') {
	  			this.MultiProcessLossItem('delete')
	  		}
	  	},
		Write() {
			let formdata = {
				url_type: this.url_type,
				interest_id: this.form.interest_id,
				interest_desc: this.form.interest_desc,
				interest_adjustable: this.$functions.ActivedFlag(this.form.interest_adjustable),
				// interest_adjustmethod: this.form.interest_adjustmethod,
				interest_adjustpremium: this.form.interest_adjustpremium,
				interest_agreedvalue: this.$functions.ActivedFlag(this.form.interest_agreedvalue),
				interest_actived: this.$functions.ActivedFlag(this.form.interest_actived),
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteInterest"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"success",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "add") {
							this.form.interest_cuser = feedback[0].feedback_users_id
							this.form.interest_cdate = feedback[0].feedback_users_date
						}
						this.form.interest_luser = feedback[0].feedback_users_id
						this.form.interest_ldate = feedback[0].feedback_users_date
						this.url_type = "edit"
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
		WriteNOC () {
			let natureclaimid = document.getElementById('natureclaimint_natureclaimid').value
	  		let formdata = {
				url_type: this.url_type_noc,
				natureclaimint_natureclaimid: natureclaimid,
				natureclaimint_interestid: this.form.interest_id,
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteNatureClaimInt'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Save Success', 3000)
		            	this.confirm_noc.dialog  	 = false
		                this.confirm_noc.text 	 	 = 'Ok'
						this.natureclaimint_dialog 	 = false
			  			if (this.url_type === 'add') {
		                	this.form.interest_cuser  = feedback[0].feedback_users_id
			  				this.form.interest_cdate  = feedback[0].feedback_users_date
			  			}
			  			this.form.interest_luser  = feedback[0].feedback_users_id
			  			this.form.interest_ldate  = feedback[0].feedback_users_date
						this.url_type_noc 		   = 'write'
		  			} else {
		  				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
						this.confirm_noc.dialog  	 = false
		                this.confirm_noc.text 	 	 = 'Ok'
						
					}
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
		WriteLossItem () {
			let lossitemid = document.getElementById('lossitemint_lossitemid').value
	  		let formdata = {
				url_type: this.url_type_lossitem,
				lossitemint_lossitemid: lossitemid,
				lossitemint_interestid: this.form.interest_id
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteLossItemInt'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Save Success', 3000)
		            	this.confirm_lossitem.dialog  	 = false
		                this.confirm_lossitem.text 	 	 = 'Ok'
			  			if (this.url_type_lossitem === 'add') {
		                	this.form.interest_cuser  = feedback[0].feedback_users_id
			  				this.form.interest_cdate  = feedback[0].feedback_users_date
			  			}
						this.lossitem_dialog = false
			  			this.form.interest_luser  = feedback[0].feedback_users_id
			  			this.form.interest_ldate  = feedback[0].feedback_users_date
						this.url_type_lossitem 		   = 'write'
		  			} else {
		  				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
						this.confirm_noc.dialog  	 = false
		                this.confirm_noc.text 	 	 = 'Ok'
						
					}
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
		SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			if (color === "error") {
				this.confirm.dialog 	= false
				this.confirm.text 		= "Ok"
			}
		},
		Close (flag) {
	  		if (flag === 'confirm') {
	  			this.confirm.dialog = false
	  			if (this.url_type !== 'add') {
	  				this.url_type = 'edit'
	  			}
	  		} else if (flag === 'natureclaim') {
				this.url_type = 'edit'
	  			this.dialog.natureclaim = false
				this.ListNOC(this.form.interest_id)
			} else if (flag === 'lossitem') {
				this.url_type = 'edit'
				this.dialog.lossitem = false
				this.ListLossItem(this.form.interest_id)	  			
			}
	  	},
		ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	},
};
</script>