<template>
  <div>
    <app-drawer_menu drawer_menu_title="Voucher Type"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs background-color="primary" color="white" v-if="access.read === 1">
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad">
        Form
      </v-tab>
      <v-tab href="#tab-list" @click="List">
        List
      </v-tab>
      <v-tab-item value="tab-form">
        <v-form
          enctype="multipart/form-data"
          ref="form_vouchertype"
          lazy-validation
        >
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>
            <!-- ID -->
            <v-col cols="3" sm="6" md="3" class="mt-n3">
              <v-text-field
                v-model="form.vouchertype_id"
                required
                :rules="form.vouchertype_id_rules"
                :readonly="form.vouchertype_id_readonly"
                :background-color="
                  form.vouchertype_id_readonly ? 'readonly' : ''
                "
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID *"
                placeholder="ID"
                id="vouchertype_id"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Desc -->
            <v-col cols="3" sm="6" md="3" class="mt-n3">
              <v-text-field
                v-model="form.vouchertype_desc"
                required
                :rules="form.vouchertype_desc_rules"
                :maxlength="maxinput.desc"
                label="Description *"
                placeholder="Description"
                id="vouchertype_desc"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!--SlCategory -->

            <v-col cols="3" sm="6" md="3">
              <app-cb
                cb_type="slcategory"
                cb_url="apiListSLCategory"
                cb_url_parameter="slcategory_actived=Y"
                cb_title="SL Category"
                cb_id="vouchertype_sl_category"
                cb_desc="vouchertype_sl_categorydesc"
                cb_rules="SL Category"
                cb_desc_readonly="readonly"
                cb_items_id="slcategory_id"
                cb_items_desc="slcategory_desc"
                :cb_value_id="form.vouchertype_sl_category"
                :cb_value_desc="form.vouchertype_sl_categorydesc"
              >
              </app-cb>
            </v-col>

            <!--Vouchertype Numbering -->
            <v-col cols="3" sm="6" md="3">
              <app-cb
                cb_type="numbering"
                cb_url="apiListNumbering"
                cb_url_parameter="numbering_actived=Y"
                cb_title="Numbering"
                cb_id="vouchertype_numbering"
                cb_desc="vouchertype_numberingdesc"
                cb_rules="Numbering"
                cb_desc_readonly="readonly"
                cb_items_id="numbering_id"
                cb_items_desc="numbering_desc"
                :cb_value_id="form.vouchertype_numbering"
                :cb_value_desc="form.vouchertype_numberingdesc"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="4" md="2" class="mt-n5">
              <small>Debit/Credit</small><br />
              <v-btn-toggle dense v-model="form.vouchertype_dc" rounded>
                <v-btn small>
                  Debit
                </v-btn>
                <v-btn small>
                  Credit
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="4" md="2" class="mt-n5">
              <small>Cheque</small><br />
              <v-btn-toggle dense v-model="form.vouchertype_cheque" rounded>
                <v-btn small>
                  NO
                </v-btn>
                <v-btn small>
                  YES
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="4" md="2" class="mt-n5">
              <small>Tax</small><br />
              <v-btn-toggle dense v-model="form.vouchertype_tax" rounded>
                <v-btn small>
                  NO
                </v-btn>
                <v-btn small>
                  YES
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="4" md="2" class="mt-n5">
              <small>Vat</small><br />
              <v-btn-toggle dense v-model="form.vouchertype_vat" rounded>
                <v-btn small>
                  NO
                </v-btn>
                <v-btn small>
                  YES
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="12" sm="2" md="2" class="mt-n5">
              <small>Suspend</small><br />
              <v-btn-toggle dense v-model="form.vouchertype_suspend" rounded>
                <v-btn small>
                  NO
                </v-btn>
                <v-btn small>
                  YES
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- Active -->
            <v-col cols="12" sm="4" md="2" class="mt-n8">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.vouchertype_actived"
                color="primary"
                id="form.vouchertype_actived"
                :label="form.vouchertype_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                <v-banner
                  v-model="cont"
                  single-line
                  transition="slide-y-transition"
                >
<pre>
Created Date 	 : {{form.vouchertype_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.vouchertype_cuser)">{{form.vouchertype_cuser}}</span>
Last Update Date : {{form.vouchertype_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.vouchertype_luser)">{{form.vouchertype_luser}}</span>
</pre>
                  >
                </v-banner>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <template v-if="url_type !== 'add'">			  	
				<!-- Tab Sub Module -->
				<v-tabs
					background-color="white"
					color="fourth"
					v-if ="access.read === 1"
				>
					<v-tab
						id      ="tabaccount"
						href   	="#tab-account"	
						@click	="ListPattern(form.vouchertype_id)"
					>
						CHART OF ACCOUNT
					</v-tab>
          <v-tab-item
						value  ="tab-account"
					>
						<!-- Data Table Treaty Limit -->
            <v-col cols="12" sm="12" md="12">
                <v-btn
                  :color="
                    vpattern.selected.length === vpattern.items.length
                      ? 'fourth'
                      : ''
                  "
                  @click="SelectAllDetail"
                  class="mr-2"
                  title="select all"
                  small
                  >Select all</v-btn
                >
                <v-btn small @click="OpenDetail">Add</v-btn>
            </v-col>
          <!-- End Dialog Edit Voucher Pattern-->
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :value="vpattern.selected"
                :headers="vpattern.headers"
                :items="ItemsVoucherPattern"
                @input="CheckDetail($event)"
                item-key="voucherpattern_row"
                show-select
                class="elevation-1"
                 :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'first_page',
                  lastIcon: 'last_page',
                  prevIcon: 'keyboard_arrow_left',
                  nextIcon: 'keyboard_arrow_right',
                }"
                dense
                :loading="loading.vpattern"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="
                      EditDetail(
                        item.voucherpattern_vouchertype,
                        item.voucherpattern_account
                      )
                    "
                    :disabled="access.edit === 0 ? true : false"
                    title="edit"
                  >
                    edit
                  </v-icon>
                  <v-icon
                    small
                  @click="Confirm('deletedetail',  item.voucherpattern_vouchertype,
                        item.voucherpattern_account)"
                  :disabled="access.delete === 0 ? true : false"
                  title="delete"
                  >
                    delete


                    
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          <!-- End List Table Voucher Pattern-->
          </v-tab-item>
          </v-tabs>
          </template>
        <!-- Dialog Write Voucher Pattern -->
        <v-dialog
          v-model="dialog.details"
          scrollable
          transition="dialog-bottom-transition"
          persistent
        >
          <v-card>
            <v-toolbar dark color="primary" dense class="mb-2">
              <v-btn icon dark @click="dialog.details = false">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Voucher Pattern</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark title="Add" @click="Addpattern"
                ><v-icon color="white">add</v-icon></v-btn
              >
              <v-btn icon dark @click="Confirm('addvoucherpattern', '')"
                ><v-icon color="white">save</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-form
                enctype="multipart/form-data"
                ref="FormDetail"
                lazy-validation
              >
                <v-row class="mx-2">
                  <v-col cols="12" sm="12" md="12">
                    <v-card class="mb-12" outlined>
                      <template>
                        <!-- Table voucher pattern-->
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left"></th>
                                <th class="text-left">
                                  Account
                                </th>
                                <th class="text-left">
                                  Debit
                                </th>
                                <th class="text-left">
                                  Credit
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(pattern, k) in voucherpatterns"
                                :key="k"
                              >
                                <td></td>
                                <td>
                                <app-cb
                                  cb_type="account"
                                  cb_url="apiListAccount"
                                  cb_url_parameter="account_actived=Y"
                                  cb_title="Account"
                                  cb_id="voucherpattern_account"
                                  cb_desc="voucherpattern_accountdesc"
                                  cb_rules="Please fill Account"
                                  cb_desc_readonly="readonly"
                                  cb_items_id="account_id"
                                  cb_items_desc="account_desc"
                                  :cb_value_id="pattern.voucherpattern_account"
                                  :cb_value_desc="pattern.voucherpattern_accountdesc"
                                >
                                </app-cb>
                                </td>
                                <td>
                                  <v-text-field
                                    background-color="numeric"
                                    placeholder="0"
                                    v-model="pattern.voucherpattern_debit"
                                  ></v-text-field>
                                </td>

                                <td>
                                  <v-text-field
                                    background-color="numeric"
                                    placeholder="0"
                                    v-model="pattern.voucherpattern_credit"
                                  ></v-text-field>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <!-- Voucher Pattern Form-->
                      </template>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- End Dialog Write Voucher Pattern-->

        <!-- Dialog Edit Voucher Pattern -->
        <v-dialog
          v-model="dialog.editdetails"
          scrollable
          transition="dialog-bottom-transition"
          persistent
        >
          <v-card>
            <v-toolbar dark color="primary" dense class="mb-2">
              <v-btn icon dark @click="dialog.editdetails = false">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Voucher Pattern </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="Confirm('updatevoucherpattern', '')"
                ><v-icon color="white">save</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-form
                enctype="multipart/form-data"
                ref="form_edit_detail"
                lazy-validation
              >
                <v-row class="mx-2">
                  <v-col cols="12" sm="12" md="12">
                    <v-card class="mb-12" outlined>
                      <template>
                        <!-- Table voucher pattern-->
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  Account
                                </th>
                                <th class="text-left">
                                  Debit
                                </th>
                                <th class="text-left">
                                  Credit
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <v-text-field
                                    v-model="form.voucherpattern_account"
                                  ></v-text-field>
                                </td>

                                <td>
                                  <v-text-field
                                    background-color="numeric"
                                    placeholder="0"
                                    v-model="form.voucherpattern_debit"
                                  ></v-text-field>
                                </td>

                                <td>
                                  <v-text-field
                                    background-color="numeric"
                                    placeholder="0"
                                    v-model="form.voucherpattern_credit"
                                  ></v-text-field>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <!-- Voucher Pattern Form-->
                      </template>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item value="tab-list">
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
              >{{$vars.V('txt_select_all_page')}}
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="vouchertype_id"
            show-select
            class="elevation-1"
             :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.vouchertype_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                mouse
              </v-icon>|
              <v-icon
                small
                @click="Confirm('delete', item.vouchertype_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>

    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Users -->
	 <app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 10,
      desc: 150,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],

    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        value: "vouchertype_id",
      },
      { text: "Description", value: "vouchertype_desc" },
      { text: "Actived", value: "vouchertype_actived" },
      { text: "Actions", value: "action", sortable: false },
    ],
    // list voucher pattern
    vpattern: {
      headers: [
        {
          text: "ID",
          align: "left",
          value: "voucherpattern_vouchertype",
        },
        { text: "Account", value: "voucherpattern_account" },
        { text: "Debit", value: "voucherpattern_debit" },
        { text: "Credit", value: "voucherpattern_credit" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end list voucher pattern
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    accessmenu: [],
    loading: {
      page: true,
      list: false,
      account: false,
      vpattern: false,
      editdetails: false,
    },
    btn_search: false,
    form: {
      // form voucher type
      vouchertype_id: "",
      vouchertype_id_rules: [(v) => !!v || "Please fill ID"],
      vouchertype_id_readonly: false,
      vouchertype_desc: "",
      vouchertype_desc_rules: [(v) => !!v || "Please fill Description"],
      vouchertype_sl_category: "",
      vouchertype_sl_categorydesc: "",

      vouchertype_dc: 0,
      vouchertype_cheque: 0,
      vouchertype_tax: 0,
      vouchertype_vat: 0,
      vouchertype_suspend: 0,
      vouchertype_numbering: "",
      vouchertype_numberingdesc: "",
      vouchertype_actived: true,
      //form end voucher type
      //form voucher pattern
      voucherpattern_vouchertype: "",
      voucherpattern_account_par: "",
      voucherpattern_account: "",
      voucherpattern_debit: "",
      voucherpattern_credit: "",
      display_users: "",
      // end form voucher pattern
    },
    voucherpatterns:[
      {
        voucherpattern_vouchertype:null,
        voucherpattern_account:null,
        voucherpattern_debit:null,
        voucherpattern_credit:null,
      }
    ],
    dialog: {
      details: false,
      editdetails: false,
      users: false,
    },
  }),
  created() {
    this.FirstLoad()
  },
  computed: {
		ItemsVoucherPattern() {
			for (let i = 0 ;i <= this.vpattern.items.length - 1 ;i++) {
				const voucherpattern = this.vpattern.items[i]
				voucherpattern.voucherpattern_row = this.vpattern.items[i].voucherpattern_vouchertype+'|'+this.vpattern.items[i].voucherpattern_account
			}
			return this.vpattern.items
		},
  },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access()
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "vouchertype";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              )
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            )
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0)
          this.loading.page = false;
        })
    },
    // select all voucher type
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    // select all voucher pattern
    SelectAllDetail() {
      this.vpattern.selected =
        this.vpattern.selected === this.vpattern.items
          ? []
          : this.vpattern.items;
    },
    CheckDetail(value) {
      this.vpattern.selected = value;
    },

    Check(value) {
      this.selected = value;
    },
    Close(flag) {
      if (flag === "attributes") {
        this.url_type = "edit";
        this.dialog.attributes = false;
      } else if (flag === "detail") {
        this.url_type = "edit";
        this.dialog.detail = false;
        this.ListDetail(this.form.vouchertype_id)
      } else if (flag === "confirm") {
        this.confirm.dialog = false;
        if (this.url_type !== "add") {
          this.url_type = "edit";
        }
      }
    },
    GetData(value) {
      if (value !== undefined) {
        //let splits = this.$functions.Split(value, "|")
        //this.$set(this.voucherpattern_account, splits[2], splits[1])
      }
    },
    Dialog(flag) {
      if (flag === "AT") {
        this.AttributesValue()
      } else if (flag === "SC") {
        this.SubClassValue()
      } else if (flag === "CH") {
        this.ChannelValue()
      }
    },
    //Function for get all data from table vouchertype with API in mod_vouchertype.go
    List() {
      this.loading.list = true;
      let formdata = {
        vouchertype_id: "",
        vouchertype_desc: "",
        order_by: "vouchertype_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListVouchertype"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0)
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0)
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000)
          this.loading.list = false;
        })
    },

    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id,par1) {
      if (flag === "write") {
        if (this.$refs.form_vouchertype.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function() {
            document.getElementById("dialog").focus()
          }, 500)
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.vouchertype_id = id;
        setTimeout(function() {
          document.getElementById("dialog").focus()
        }, 500)
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function() {
          document.getElementById("dialog").focus()
        }, 500)
      } else if (flag === "addvoucherpattern") {
        this.url_type = "addvoucherpattern";
        this.confirm.dialog = true;
        this.confirm.title = "Save Voucher Pattern";
        setTimeout(function() {
          document.getElementById("vouchertype_id").focus()
        }, 500)
      } else if (flag === "editdetail") {
        if (this.$refs.form_detail.validate()) {
          this.url_type = "editdetail";
          this.confirm.dialog = true;
          this.confirm.title = "Edit Detail";
          setTimeout(function() {
            document.getElementById("dialog").focus()
          }, 500)
        }
      } else if (flag === "updatevoucherpattern") {
        if (this.$refs.form_edit_detail.validate()) {
          this.url_type = "updatevoucherpattern";
          this.confirm.dialog = true;
          this.confirm.title = "Edit Detail";
          setTimeout(function() {
            document.getElementById("vouchertype_id").focus()
          }, 500)
        }
      }
      else if (flag === "deletedetail") {
        this.url_type = "deletedetail";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.voucherpattern_vouchertype = id;
        this.voucherpattern_account = par1;
        setTimeout(function() {
          document.getElementById("dialog").focus()
        }, 500)
      }
    },
    //VOUCHER TYPE PROCESS
    //Function to request insert data to table vouchertype 'The API will validating your data, include the user who process the data' (go to APIWriteVoucherType with url_type = "add")
    Add() {
      this.url_type = "add";
      this.form.vouchertype_id = "";
      this.form.vouchertype_desc = "";
      this.form.vouchertype_sl_category = "";
      this.form.vouchertype_sl_categorydesc = "";

      this.form.vouchertype_dc = 0;
      this.form.vouchertype_cheque = 0;
      this.form.vouchertype_tax = 0;
      this.form.vouchertype_vat = 0;
      this.form.vouchertype_suspend = 0;
      this.form.vouchertype_numbering = "";
      this.form.vouchertype_numberingdesc = "";
      this.form.vouchertype_actived = true;
      this.form.vouchertype_id_readonly = false;
      setTimeout(function() {
        document.getElementById("vouchertype_id").focus()
      }, 500)
    },
    Write() {
      let voucherdc = "D";
      let vouchercheque = "N";
      let vouchertax = "N";
      let vouchervat = "N";
      let vouchersuspend = "N";

      let vouchertypeslcategory = document.getElementById(
        "vouchertype_sl_category"
      ).value;
      let vouchertypenumbering = document.getElementById(
        "vouchertype_numbering"
      ).value;

      if (this.form.vouchertype_dc === 1) {
        voucherdc = "C";
      }
      if (this.form.vouchertype_cheque === 1) {
        vouchercheque = "Y";
      }
      if (this.form.vouchertype_tax === 1) {
        vouchertax = "Y";
      }
      if (this.form.vouchertype_vat === 1) {
        vouchervat = "Y";
      }
      if (this.form.vouchertype_suspend === 1) {
        vouchersuspend = "Y";
      }
      let formdata = {
        url_type: this.url_type,
        vouchertype_id: this.form.vouchertype_id,
        vouchertype_desc: this.form.vouchertype_desc,
        vouchertype_sl_category: vouchertypeslcategory,

        vouchertype_dc: voucherdc,
        vouchertype_cheque: vouchercheque,
        vouchertype_tax: vouchertax,
        vouchertype_vat: vouchervat,
        vouchertype_suspend: vouchersuspend,
        vouchertype_numbering: vouchertypenumbering,
        vouchertype_actived: this.$functions.ActivedFlag(
          this.form.vouchertype_actived
        ),
      };

      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteVouchertype"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })

        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000)
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.vouchertype_cuser = feedback[0].feedback_users_id;
                this.form.vouchertype_cdate = feedback[0].feedback_users_date;
              }
              this.form.vouchertype_luser = feedback[0].feedback_users_id;
              this.form.vouchertype_ldate = feedback[0].feedback_users_date;
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })

        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        })
    },
    //Function to request update data to table vouchertype 'The API will validating your data, include the user who process the data' (go to APIWriteVoucherType with url_type = "edit")
    Edit(id) {
      this.loading.list = true;
      let formdata = {
        vouchertype_id: id,
        vouchertype_desc: "",
        order_by: "vouchertype_id",
        order_type: "ASC",
        limit: this.limit,
      };
      
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListVouchertype"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          //console.log("editvouchertype",feedback)
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click()
              this.url_type = "edit";
              this.form.vouchertype_id = feedback[0].vouchertype_id;

              this.form.vouchertype_desc = feedback[0].vouchertype_desc;
              this.form.vouchertype_sl_category =
                feedback[0].vouchertype_sl_category;
              this.form.vouchertype_sl_categorydesc =
                feedback[0].vouchertype_sl_categorydesc;

              this.form.vouchertype_dc = this.$functions.YesOrNo(
                feedback[0].vouchertype_dc
              )
              this.form.vouchertype_cheque = this.$functions.YesOrNo(
                feedback[0].vouchertype_cheque
              )
              this.form.vouchertype_tax = this.$functions.YesOrNo(
                feedback[0].vouchertype_tax
              )
              this.form.vouchertype_vat = this.$functions.YesOrNo(
                feedback[0].vouchertype_vat
              )
              this.form.vouchertype_suspend = this.$functions.YesOrNo(
                feedback[0].vouchertype_suspend
              )
              this.form.vouchertype_numbering =
                feedback[0].vouchertype_numbering
              this.form.vouchertype_numberingdesc =
                feedback[0].vouchertype_numberingdesc
              this.form.vouchertype_actived = this.$functions.TrueOrFalse(
                feedback[0].vouchertype_actived
              )
              this.form.vouchertype_cdate = feedback[0].vouchertype_cdate
              this.form.vouchertype_cuser = feedback[0].vouchertype_cuser
              this.form.vouchertype_ldate = feedback[0].vouchertype_ldate
              this.form.vouchertype_luser = feedback[0].vouchertype_luser
              this.form.vouchertype_id_readonly = true;
              this.ListPattern(feedback[0].vouchertype_id)
              setTimeout(function() {
                document.getElementById("vouchertype_desc").focus()
              }, 500)
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
          this.loading.list = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
          this.loading.list = false
        })
    },

    Delete(id) {
      let formdata = {
        url_type: "delete",
        vouchertype_id: id,
      };
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteVouchertype"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000)
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.List()
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        })
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected)
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata)
      
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteVouchertype"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0)
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.List()
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        })
    },
    // END VOUCHER TYPE PROCESS

    /// VOUCHER PATTERN PROCESS
    OpenDetail() {
      this.dialog.details = true;
      this.voucherpatterns.splice(1)
      this.$refs.FormDetail.reset()
    },
    Addpattern() {
      let id = document.getElementById("vouchertype_id").value;
      let accountpattern = document.getElementById("voucherpattern_account").value;
      this.voucherpatterns.push({
        voucherpattern_vouchertype: id,
        voucherpattern_account:accountpattern,
        voucherpattern_debit: "",
        voucherpattern_credit: "",
      })
     
    },
    // write voucher pattern
    VoucherPattern() {
      
      let id = document.getElementById("vouchertype_id").value;
      let formpattern = {
        url_type: "add",
        multi_pattern: JSON.stringify(this.voucherpatterns),
      };
      let params = this.$functions.ParamPOST(formpattern)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteVoucherpattern"), params, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })

        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000)
             this.voucherpatterns.slice(0)
              this.confirm.dialog = false;
              this.dialog.details = false;
              
               this.ListPattern(id)
              setTimeout(function() {
                document.getElementById("vouchertype_desc").focus()
              }, 500)
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.vouchertype_cuser = feedback[0].feedback_users_id;
                this.form.vouchertype_cdate = feedback[0].feedback_users_date;
                
              }
              this.form.vouchertype_luser = feedback[0].feedback_users_id;
              this.form.vouchertype_ldate = feedback[0].feedback_users_date;
              this.url_type = "editdetail";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })

        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        })
    },
    // get voucher pattern to list
    ListPattern(id) {
      this.loading.vpattern = true;
      let formdata = {
        voucherpattern_vouchertype: id,
        order_by: "voucherpattern_vouchertype",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListVoucherpattern"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0)
            } else {
              this.vpattern.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0)
          }
          this.loading.vpattern = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000)
          this.loading.vpattern = false;
        })
    },
    // get data voucher pattern by id to form
    EditDetail(id,account) {
      
      let formdata = {
        voucherpattern_vouchertype: id,
        voucherpattern_account: account,
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListVoucherpattern"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.voucherpattern_vouchertype =
                feedback[0].voucherpattern_vouchertype;
              this.form.voucherpattern_account =
                feedback[0].voucherpattern_account;
              this.form.voucherpattern_debit = feedback[0].voucherpattern_debit;
              this.form.voucherpattern_credit =
                feedback[0].voucherpattern_credit;
              this.form.voucherpattern_account_par =
                feedback[0].voucherpattern_account;

              this.dialog.editdetails = true;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
          this.loading.editdetails = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.editdetails = false;
        })
    },
    //  update voucher pattern
    UpdateDetailValue() {
      let id = document.getElementById("vouchertype_id").value;
      let formdata = {
        url_type: "edit",
        voucherpattern_vouchertype: id,
        voucherpattern_account_par: this.form.voucherpattern_account_par,
        voucherpattern_account: this.form.voucherpattern_account,
        voucherpattern_debit: this.form.voucherpattern_debit,
        voucherpattern_credit: this.form.voucherpattern_credit,
      };
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteVoucherpattern"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })

        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000)
              this.confirm.dialog = false;
              this.dialog.editdetails = false;
              this.ListPattern(id)
              setTimeout(function() {
                document.getElementById("vouchertype_desc").focus()
              }, 500)
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.vouchertype_cuser = feedback[0].feedback_users_id;
                this.form.vouchertype_cdate = feedback[0].feedback_users_date;
              }
              this.form.vouchertype_luser = feedback[0].feedback_users_id;
              this.form.vouchertype_ldate = feedback[0].feedback_users_date;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })

        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        })
    },

    DeleteDetail (id, account) {
      let vouchertypeid = document.getElementById("vouchertype_id").value;
			let formdata ={
				url_type                : 'deletedetail',
				voucherpattern_vouchertype : id,
				voucherpattern_account  : account
			}
      let param 	   =this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteVoucherpattern'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then((response) => {
        let feedback = response.data          
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(
              true,
              "success",
              "Delete Success",
              3000
            )
            this.confirm.dialog = false
            this.confirm.text = "Ok"
            this.ListPattern(vouchertypeid)
            setTimeout(function() {
                document.getElementById("vouchertype_desc").focus()
              }, 500)
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog 	= true
            this.remarks.feedback 	= feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog 	= true
        this.remarks.feedback 	= e
      })
    },


    /// END VOUCHER PATTERN PROCESS
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write()
      } else if (this.url_type === "delete") {
        this.Delete(this.vouchertype_id)
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete")
      } else if (this.url_type === "addvoucherpattern") {
        this.VoucherPattern()
      } else if (this.url_type === "updatevoucherpattern") {
        this.UpdateDetailValue()
      }else if (this.url_type === "deletedetail") {
        this.DeleteDetail(this.voucherpattern_vouchertype,this.voucherpattern_account)
      }
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
  },
};
</script>
