<template>
    <v-row>
       <input type="text" :value="`${oi_refid}`" :id="`ref${oi_id}`" style="display:none;">
       <v-col v-if="oi_type === 'L'" cols="12" sm="3" md="3">
           <v-text-field 
               v-model 			    ="id[oi_id]"
               :id					="oi_id" 
               :name				="oi_id"
               prepend-inner-icon	="list"
               maxlength			="10"
               background-color 	= "combo"
               :required			="oi_rules.length > 0 ? true : false" 
               :rules				="oi_rules.length > 0 && !id[oi_id] ? [oi_rules] : []" 
               :readonly			="oi_id_readonly === undefined ? false : oi_id_readonly"
               :value              = "oi_value_id"
               v-bind              ="$attrs"
               @keyup.enter	       ="GTView"
               @change             = "GTView">
           </v-text-field>
       </v-col>
       <v-col v-if="oi_type === 'L'" cols="12" sm="9" md="9">
           <v-text-field 
               v-model 			    ="desc[oi_desc]"
               :id					="oi_desc" 
               :name				="oi_desc"
               :label		 		="oi_rules.length > 0 ? `${oi_title} *`: oi_title" 
               maxlength			="255"
               placeholder			="Description"
               background-color 	= "readonly"
               :readonly			="oi_desc_readonly === undefined ? false : oi_desc_readonly"
               :value              = "oi_value_desc"
               v-bind              ="$attrs"
               >
           </v-text-field>
       </v-col>
       <v-col v-if="oi_type === 'S'" cols="12" sm="12" md="12">
           <v-text-field v-model ="id[oi_id]" :id="oi_id" :value= "oi_value_id" v-show="false"></v-text-field>
           <v-text-field
               v-model             ="desc[oi_desc]"
               :id                 ="oi_desc" 
               :name				="oi_desc"
               :label				="oi_rules.length > 0 ? `${oi_title} *`: oi_title"
               maxlength			="255"
               prepend-inner-icon	="title"
               :placeholder 		="oi_id_placeholder === undefined ? 'Description' : oi_id_placeholder"
               :required           ="oi_rules.length > 0 ? true : false" 
               :rules              ="oi_rules.length > 0 && !desc[oi_desc] ? [oi_rules] : []" 
               :readonly           ="oi_id_readonly === undefined ? false : oi_id_readonly"
               v-bind              ="$attrs"
               :value              = "oi_value_desc">
           </v-text-field>
       </v-col>
       <v-col v-if="oi_type ==='N'" cols="12" sm="12" md="12">
           <v-text-field v-model ="id[oi_id]" :id="oi_id" :value= "oi_value_id" v-show="false"></v-text-field>
           <v-text-field
               v-model             ="desc[oi_desc]"
               :id                 ="oi_desc" 
               :name               ="oi_desc"
               :label              ="oi_rules.length > 0 ? `${oi_title} *`: oi_title"
               maxlength           ="255"
               prepend-inner-icon  ="money"
               :placeholder        ="oi_id_placeholder === undefined ? 'Input Number' : oi_id_placeholder"
               :required           ="oi_rules.length > 0 ? true : false" 
               :rules              ="oi_rules.length > 0 && !desc[oi_desc] ? [oi_rules] : []" 
               :readonly           ="oi_id_readonly === undefined ? false : oi_id_readonly"
               background-color    = "numeric"
               :value              = "oi_value_desc"
               v-bind              ="$attrs"
               reverse>
           </v-text-field>
       </v-col>
       <v-col v-if="oi_type ==='D'" cols="12" sm="12" md="12">
           <v-text-field v-model ="id[oi_id]" :id="oi_id" :value= "oi_value_id" v-if="false"></v-text-field>
           <v-text-field
               v-model 			  ="desc[oi_desc]"
               :id                 ="oi_desc" 
               :name				="oi_desc"
               :label				="oi_rules.length > 0 ? `${oi_title} *`: oi_title" 
               :required           ="oi_rules.length > 0 ? true : false" 
               :rules              ="oi_rules.length > 0 && !desc[oi_desc] ? [oi_rules] : []" 
               :readonly           ="oi_id_readonly === undefined ? false : oi_id_readonly"
               prepend-inner-icon	="calendar_today"
               placeholder	  		="YYYY-MM-DD"
               background-color    = "date"
               v-mask				="'####-##-##'"
               v-bind              ="$attrs"
               :value              = "oi_value_desc">
               </v-text-field>
       </v-col>
       <!--Modal-->
       <v-dialog v-model="modal['generaltable']" fullscreen hide-overlay persistent transition="dialog-bottom-transition" v-if="modal['generaltable']">
           <v-card>
               <v-toolbar dark color="primary">
                   <v-btn icon dark @click.stop="GTClose()">
                       <v-icon>arrow_back</v-icon>
                   </v-btn>
                   <v-toolbar-title>{{oi_title}}</v-toolbar-title>
                   <v-spacer></v-spacer>
               </v-toolbar>
               <v-container grid-list-md class="pa-12">
                   <v-layout row wrap>
                       <v-col xs12>
                           <v-text-field
                               v-model          ="search[oi_id]"
                               id 			    = "search_generaltable"
                               append-icon 	    ="search"
                               :label 			="`Search ${oi_title}`" 
                               v-if 			="modal['generaltable']"
                               @keyup.enter	    ="GTSearch"
                               :loading		   ="loading"
                               ></v-text-field>
                           <v-data-table 
                                   :headers 	="oi_header"
                                   :items 		="items[oi_id]"
                                   item-key	    ="gendet_id" 
                                   @click:row	="GTGet"
                                   class		="elevation-1">
                               <template slot="headerCell" slot-scope="props">
                                   <v-tooltip bottom>
                                       <span slot="activator">
                                           {{ props.header.text }}
                                       </span>
                                       <span>
                                           {{ props.header.text }}
                                       </span>
                                   </v-tooltip>
                               </template>
                               <template slot="items" slot-scope="props">
                                   <tr class="cursor">
                                       <td class="text-xs-left">{{props.item['gendet_id']}}</td>
                                       <td class="text-xs-left">{{props.item['gendet_desc']}}</td>
                                   </tr>
                               </template>
                               <template v-slot:no-data>
                                         No Data Available
                               </template>
                           </v-data-table>
                       </v-col>
                   </v-layout>
               </v-container>
           </v-card>
       </v-dialog>
   </v-row>
</template>
<script>
   export default {
       data: () => ({
           loading: false,
           format_money: {
               decimal: ',',
               thousands: '.',
               precision: 0
           },
           id: {},
           desc: {},
           search: {},
           items: [],
           modal: {
               generaltable: false
           },
           oi_header:
           [
               {
                   text: 'ID',
                   value: 'gendet_id',
                   align: 'left'
               },
               { text: 'Name', value: 'gendet_desc' }
           ],
           oi_type: ''
       }),
       created () {
           this.GTType(this.oi_refid)
       },
       watch: {
           oi_value_id: function (newdata) {
               if (newdata !== '') {
                   this.id[this.oi_id]     = newdata
               } else {
                   this.id[this.oi_id] = ''
               }
           },
           oi_value_desc: function (newdata) {
               if (newdata !== '') {
                   this.desc[this.oi_desc] = newdata
               } else {
                   this.desc[this.oi_desc] = ''
               }
           }
       },
       props: ['oi_title',
               'oi_id',
               'oi_desc',
               'oi_rules',
               'oi_id_readonly',
               'oi_desc_readonly',
               'oi_value_id',
               'oi_value_desc',
               'oi_url_parameter',
               'oi_id_placeholder',
               'oi_refid'],
       methods: {
           // View Data and show dialog when Enter
           GTView () {
               this.modal['generaltable'] = true
               this.GTCall(this.id[this.oi_id])
               setTimeout(function(){
                   document.getElementById('search_generaltable').focus()
               }, 500)
           },
           //Close Dialog
           GTClose () {
               this.id[this.oi_id]   = ''
               this.desc[this.oi_desc] = ''
               this.modal['generaltable'] = false
               var v = this
               setTimeout(function(){
                   document.getElementById(v.oi_id).focus()
               }, 500)
           },
           // Search data
           GTSearch () {
               this.loading = true
               this.GTCall(this.search[this.oi_id])
           },
           GTGet (value) {
               this.id[this.oi_id]         = value['gendet_id']
               this.desc[this.oi_desc]     = value['gendet_desc']
               this.modal['generaltable']  = false
           },
           GTCall (values) {
               this.loading = true
               this.search[this.oi_id] = values
               let formdata = {
                   datavalue: values,
                   gendet_gentab_id: this.oi_refid,
                   parameter: this.cb_url_parameter,
               }
               let param      = this.$functions.ParamPOST(formdata)
               this.$axios.post(this.$functions.UrlPOST('apiListGenTabDetail'),param,{
                   headers: {
                       'Content-Type': 'text/plain; charset=ISO-8859-1'
                   }
               })
               .then(response => {
                   let feedback    = response.data
                   if (feedback.length === 0) {
                       this.id[this.oi_id]         = ''
                       this.desc[this.oi_desc]     = ''
                       this.items[this.oi_id]      = []
                   } else if (feedback.length === 1) {
                       if (feedback[0].feedback === 'Y') {
                           this.id[this.oi_id]         = feedback[0]['gendet_id']
                           this.desc[this.oi_desc]     = feedback[0]['gendet_desc']
                           this.modal['generaltable']  = false
                           var v = this
                           setTimeout(function(){
                               document.getElementById(v.cb_id).focus()
                           }, 500)
                       } else {
                           alert(feedback[0].feedback)
                       }
                   } else {
                       this.modal['generaltable']  = true
                       this.search[this.oi_id]     = this.id[this.oi_id]
                       this.items[this.oi_id]      = response.data
                   }
                   this.loading = false
               })
               .catch(e => {
                   alert(e)
                   this.loading = false
               })
           },
           GTType (values) {
               let formdata = {
                   gentab_id: values,
               }
               let param      = this.$functions.ParamPOST(formdata)
               this.$axios.post(this.$functions.UrlPOST('apiListGenTab'),param,{
                   headers: {
                       'Content-Type': 'text/plain; charset=ISO-8859-1'
                   }
               })
               .then(response => {
                let feedback    = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === 'Y') {
                        this.flag = 'Y'
                        this.oi_type = 'L'
                        if (feedback[0].gentab_selectf !== 'Y') {
                            this.oi_type = feedback[0].gentab_fieldtype
                        }
                    }
                } else {
                    this.flag = 'N'
                }
               })
               .catch(e => {
                   alert(e)
               })
           },
       }
   }
</script>