	<template>
	<div>
		<app-drawer_menu drawer_menu_title="Report"></app-drawer_menu>
		<v-progress-linear
			indeterminate
			color="fourth"
			rounded
			v-if="loading.page"
			></v-progress-linear>
		<v-row class="mx-2">
			<v-col cols="12" sm="3" md="3">
				<template>
				<v-card
					class="mx-auto"
					width="300"
					flat
				>
					<v-list
					dense>
					<!-- <v-list-item>
						<v-list-item-icon>
						<v-icon>mdi-home</v-icon>
						</v-list-item-icon>

						<v-list-item-title>Accounting</v-list-item-title>
					</v-list-item> -->

					<v-list-group
						:value="false"
						prepend-icon="mdi-file-outline"
						v-for="row in items"
						:key = "row.report_id"
					>
						<template v-slot:activator>
							<v-list-item-content>
							<v-list-item-title>{{ row.report_desc }}</v-list-item-title>
							</v-list-item-content>
						</template>

						<v-list-item
							v-for="childs in row.report_child"
							:key="childs.report_id"
							@click="GetReport(childs.report_id,childs.report_desc)"
						>
							<v-list-item-title v-text="childs.report_desc" :class="childs.report_id === form.id ? 'fourth--text':''"></v-list-item-title>

							<v-list-item-icon>
							<v-icon v-text="childs.report_icon"></v-icon>
							</v-list-item-icon>
						</v-list-item>
					</v-list-group>
					</v-list>
				</v-card>
				</template>
			</v-col>
			<v-col cols="12" sm="9" md="9">
				<v-card
				flat>
					<div class="title fourth--text" v-if="form.id">{{ form.title }} <small>#{{ form.id }}</small></div>
					<div><component :is="appreport"></component></div>
				</v-card>
			</v-col>
		</v-row>
		<v-snackbar
		v-model	= "snackbar.dialog"
		:timeout= "snackbar.timeout"
		color	= "fourth"
		rounded	= "pill"
		top
		style 	= "z-index: 9999;"
		>
		{{ snackbar.text }}
		<template v-slot:action="{ attrs }">
		<v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
		<v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
		</template>
	</v-snackbar>
	</div>
	</template>
	<script>
	export default {
		data: () => ({
		items: [],
		access: {
			read: 0,
			add: 0,
			edit: 0,
			delete: 0,
		},
		loading: {
			list: false,
			page: false,
		},
		snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000
		},
		confirm: {
			dialog: false,
			title: '',
			text: 'Ok',
		},
		form: {
			id: '',
			title: ''
		}
		}),
		created () {
			this.FirstLoad()
		},
		computed: {
			appreport() {
				let feedbacks
				let formid = 'rpt-default'
				if (this.form.id) {
					formid = this.form.id
				}
				feedbacks = () => import(`@/views/modul/mod_report/${formid}`)
				return feedbacks
			}
		},
		methods: {
			FirstLoad () {
				//this function use for validating the screen with user privilleges
				this.Access()
				this.List()
			},
			//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
			Access () {
				let modul = 'report'
				let formdata = {
					menu_id: modul,
					users_id: this.$functions.UsersID(),
					order_by: 'menu_id',
					order_type: 'ASC',
					limit: this.limit,
				}
				let param 	   = this.$functions.ParamPOST(formdata)
				this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
					headers: {
						'Content-Type': 'text/plain; charset=ISO-8859-1'
					}
				})
				.then(response => {
					let priv = response.data
					if (priv.length > 0) {
						if (priv[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = priv[0].feedback
						} else {
						this.access.read    = priv[0].usersmenu_read
						this.access.add     = priv[0].usersmenu_add
						this.access.edit    = priv[0].usersmenu_edit
						this.access.delete  = priv[0].usersmenu_delete
						if (priv[0].usersmenu_read === 0) {
							this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
						}
						}
					} else {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					}
					this.loading.page = false
				})
				.catch(e => {
					this.SnackBar(true, 'error', e, 0)
					this.loading.page = false
				})
			},
			List () {
				this.loading.list = true
				let formdata = {
					menu_id: '',
					menu_desc: '',
					order_by: 'code_id',
					order_type: 'ASC',
					limit: this.limit,
				}
				let param 	   = this.$functions.ParamPOST(formdata)
				this.$axios.post(this.$functions.UrlPOST('apiListMenuReport'),param,{
					headers: {
						'Content-Type': 'text/plain; charset=ISO-8859-1'
					}
				})
				.then(response => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback !== 'Y') {
							this.SnackBar(true, 'error', feedback[0].feedback, 0)
						} else {
							this.items = feedback
						}
					} else {
						this.items = feedback
						this.SnackBar(true, 'error', this.$functions.NoData(), 0)
					}
					this.loading.list = false
				})
				.catch(e => {
					this.SnackBar(true, 'error', e, 3000)
					this.loading.list = false
				})
			},
			GetReport (id, desc) {
				let formdata = {
					menu_id: id,
					users_id: this.$functions.UsersID(),
					order_by: 'menu_id',
					order_type: 'ASC',
				}
				let param 	   = this.$functions.ParamPOST(formdata)
				this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
					headers: {
						'Content-Type': 'text/plain; charset=ISO-8859-1'
					}
				})
				.then(response => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].usersmenu_read === 0) {
							this.SnackBar(true, "error", this.$functions.NoPriviledge(id), 3000)
						} else {
							this.form.id    = id
							this.form.title = desc
						}
					} else {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(id), 3000)
					}
				})
				.catch(e => {
					this.SnackBar(true, 'error', e, 0)
				})
			},
			SnackBar (dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout
			}
			if (color === 'error') {
				this.confirm.dialog 	 = false
				this.confirm.text 	 	 = 'Ok'
			}
			}
		}
	};
	</script>