<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Increment"></app-drawer_menu>
	  	<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if = "this.access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_increment" lazy-validation>
			<v-row class="mx-2">
				<v-col cols="12" sm="12" md="12">
					<v-btn
				      color		    ="tertiary"
				      class			="white--text"
				      :disabled     = "access.add === 0 ? true:false" 
				      rounded
				      @click 	    = "Add" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
				    </v-btn> &nbsp;
				    <v-btn
					color		="primary"
					class		="white--text"
					:disabled 	= "access.add === 1 || access.edit === 1 ? false:true"
					rounded
					@click 		= "Confirm('write','')" 
					small
				    >
				      <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
				    </v-btn>
				</v-col>
              <!-- ID -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	= "form.increment_table"
                  required 
                  :rules 	  	="form.increment_table_rules"
                  :readonly     = "form.increment_table_readonly"
                  label       	= "ID *"
                  placeholder 	= "ID"
                  id 			= "increment_table"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- ID -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	= "form.increment_lastnum"
                  required 
                  :rules 	  	="form.increment_lastnum_rules"
                  label       	= "Last Number"
                  placeholder 	= "Last Number"
                  id 			= "increment_lastnum"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4" class="mt-n5">
                  <label>Active Status</label>
				  <v-switch
                    v-model="form.increment_actived"
                    color  = "primary"
                    label  = "Actived"
                  ></v-switch>
                </v-col>
			</v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
					<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.increment_cdate}}
Created User 	 : {{form.increment_cuser}}
Last Update Date : {{form.increment_ldate}}
Last Update User : {{form.increment_luser}}

</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<v-card-title>
				<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
					<template v-slot:activator="{ on }">
						<button class="AlertButton ErrorButton" v-on="on">
							<div class="blobs-container">
								<v-icon class="blob red mt-1 mr-2">error_outline</v-icon>
								Open Error Message
							</div>
						</button>
					</template>
					<v-card>
						<v-toolbar dark color="primary">
						<v-btn icon dark @click="remarks.dialog = false">
							<v-icon>{{$vars.V('icon_close')}}</v-icon>
						</v-btn>
						<v-toolbar-title>Data Failed</v-toolbar-title>
						</v-toolbar>
						<v-list three-line subheader>
							<v-list-item>
								<v-list-item-content>
								<v-list-item-title>Daftar Data yang gagal diproses</v-list-item-title>
								<v-list-item-subtitle><div class="ml-4" v-html="remarks.feedback"></div></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-dialog>
			</v-card-title>
			<template>
			  <v-card-title>
	                <v-btn 
	                	color = "error"
	                	title = "Delete"
	                	@click = "Confirm('multidelete','')"
	                	v-if  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false">
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-btn 
	                	color  = "primary"
	                	title  = "Search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true">
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    v-model="selected"
			    :headers="headers"
			    :items="items"
			    :search="search"
			    item-key="increment_table"
			    class="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			  >
			    <!-- <template v-slot:top>
			      <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
			    </template> -->
			    <!-- <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class="mr-2"
			        @click="Edit(item.increment_table)"
			        :disabled = "access.edit === 0 ? true:false"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click="Confirm('delete',item.increment_table)"
			        :disabled = "access.delete === 0 ? true:false"
			      >
			        delete
			      </v-icon>
			    </template> -->
			  </v-data-table>
			</template>
            <template  slot="items" slot-scope="row">
                <tr>
                    <td class="text-xs-left">{{row.item.increment_table}}</td>
                    <td class="text-xs-left">{{row.item.increment_lastnum}}</td>
                    <td class="text-xs-left">{{row.item.increment_actived}}</td>
                    <!-- <td class="text-xs-left">Edit</td> -->
                </tr>
            </template>
		  	</v-tab-item>
  	</v-tabs>
	<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth">
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      absolute
      top
      style 	= "z-index: 9999;"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
	</v-snackbar>
	<v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
			<v-card>
				<v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
				<v-card-text>
					
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn id="dialog" color="success"
						:disabled   = "confirm.text === 'Ok' ? false:true"
						@click      = "Loading">{{confirm.text}}</v-btn>
					<v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
  </div>
</template>
<script>
export default {
  	data: () => ({
		  url_type: 'add',
		  cont: false,
  		access: {
  			read: 0,
  			add: 0,
  			edit: 0,
  			delete: 0,
  		},
  		snackbar_dialog: false,
	    snackbar_color: 'success',
	    snackbar_text: '',
	    snackbar_timeout: 3000,
	    confirm: {
	    	dialog: false,
	    	title: '',
	    	ok_text: 'Ok',
	    	ok_disabled: false,
	    },
        singleselect: false,
        selected: [],
        search: '',
        headers: [
          {
            text: 'ID',
            align: 'left',
            value: 'increment_table',
          },
          { text: 'Last Number', value: 'increment_lastnum' },
          { text: 'Actived', value: 'increment_actived' },
        //   { text: 'Actions', value: 'action', sortable: false }
		],
		remarks: {
			feedback:'',
			dialog: false,
		},
		items: [],
		feeds: [],
        loading: {
          list : false
        },
        btn_search: false,
        form: {
        	increment_table: '',
        	increment_table_rules: [v => !!v || 'Please fill ID'],
        	increment_table_readonly: false,
        	increment_lastnum: 0,
        	increment_actived: true,
        },
      	}),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
			//this function use for validating the screen with user privilleges
  			this.Access()
  			setTimeout(function(){
                document.getElementById('increment_table').focus()
            }, 500)
		},
		Access () {
			let modul = 'increment'
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: 'menu_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let priv = response.data
                if (priv.length > 0) {
                    if (priv[0].feedback !== 'Y') {
                      this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                      this.remarks.dialog = true
                      this.remarks.feedback = priv[0].feedback
                    } else {
                      this.access.read  	= priv[0].usersmenu_read
                      this.access.add   	= priv[0].usersmenu_add
                      this.access.edit    	= priv[0].usersmenu_edit
                      this.access.delete  	= priv[0].usersmenu_delete
                      if (priv[0].usersmenu_read === 0) {
                        this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                      }
                    }
                } else {
                    this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                }
                this.loading.page = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 0)
				this.loading.page = false
			})
		},
        SelectAllPage () {
	      this.selected = this.selected === this.items ? [] : this.items
	    },
	    Check (value) {
	      this.selected = value
	    },
		//This function use for give feedback when user has no access with some feature
        NoPriviledge () {
        	this.SnackBar(true, 'error', 'You dont have access `increment` modul, please contact Administrator', 30000)
        },
		List () {
			this.loading.list = true
			let formdata = {
				increment_table: '',
				increment_lastnum: '',
				order_by: 'increment_table',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListIncrement'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.items = feedback
					}
				} else {
					this.items = feedback
					this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading.list = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.list = false
			})
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
	  	Confirm (flag, id) {
	  		if (flag === 'write') {
		  		if (this.$refs.form_increment.validate()) {
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
		  		}
	  		} else if (flag === 'delete') {
	  			this.url_type 		        = 'delete'
	  			this.confirm.dialog         = true
	  			this.confirm.title          = 'Delete `'+ id + '`?'
	  			this.increment_table 	    = id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multidelete') {
	  			this.url_type 		  = 'multidelete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		}
		},  
		//Function to request insert data to table increment 'The API will validating your data, include the user who process the data' (go to APIWriteincrement with url_type = "add") 
	  	Add () {
	  		this.url_type 		 		        = 'add'
	  		this.form.increment_table 	 	    = ''
	  		this.form.increment_lastnum 	 	= ''
	  		this.form.increment_actived 	    = true
	  		this.form.increment_table_readonly  = false
	  		setTimeout(function(){
                document.getElementById('increment_table').focus()
            }, 500)
		},
		Edit (id) {
			this.loading.list = true
			let formdata = {
				increment_table: id,
				increment_lastnum: '',
				order_by: 'increment_table',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListIncrement'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
            .then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		document.getElementById('tabform').click()
	            		this.url_type 			    	= 'edit'
	            		this.form.increment_id   	    	= feedback[0].increment_id
	                    this.form.increment_table   	= feedback[0].increment_table
						this.form.increment_lastnum     = feedback[0].increment_lastnum
						this.form.increment_actived   	= this.$functions.TrueOrFalse(feedback[0].increment_actived)
						this.form.increment_cdate		= feedback[0].increment_cdate
						this.form.increment_cuser		= feedback[0].increment_cuser
						this.form.increment_ldate		= feedback[0].increment_ldate
						this.form.increment_luser		= feedback[0].increment_luser
						this.form.increment_table_readonly  = true
						setTimeout(function(){
	                		document.getElementById('increment_desc').focus()
	            		}, 500)
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
				}
				this.loading.list = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
				this.loading.list 		= false
			})
		},
		Delete (id) {
			let formdata = {
				url_type: 'delete',
				increment_table: id
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiDeleteIncrement'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Delete Success', 3000)
		            	this.confirm.dialog  	 = false
		                this.confirm.text 	 	 = 'Ok'
		                this.selected 			 = []
						this.List()
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
	  	},
	  	MultiProcess () {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteIncrement'),param,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				let feedback = response.data
				if (feedback[0].feedback === 'Y') {
					let feed
					if (feedback[0].feedback_failed == 0){
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					} else {
						this.remarks.feedback = feedback[0].feedback_failed_remarks
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, 'success', feed, 0)
					this.confirm.dialog  	 = false
					this.confirm.text 	 	 = 'Ok'
					this.selected 			 = []
					this.List()
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback[0].feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
	  	Loading () {
	  		this.confirm.ok_text 	 = 'Loading...'
	  		this.confirm.ok_disabled = true
	  		if (this.url_type === 'add' || this.url_type === 'edit') {
	  			this.Write()
	  		} else if (this.url_type === 'delete') {
	  			this.Delete(this.increment_table)
	  		} else if (this.url_type === 'multidelete') {
	  			this.MultiProcess('delete')
	  		}
	  	},
		Write () {
	  		let formdata = {
				url_type: this.url_type,
				increment_table: this.form.increment_table,
				increment_lastnum: this.form.increment_lastnum,
				increment_actived: this.$functions.ActivedFlag(this.form.increment_actived),
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteIncrement'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Save Success', 3000)
		            	this.confirm.dialog  	 = false
		                this.confirm.text 	 	 = 'Ok'
		                if (this.url_type === 'add') {
		                	this.form.increment_cuser  	= feedback[0].feedback_users_id
			  				this.form.increment_cdate  	= feedback[0].feedback_users_date
			  			}
			  			this.form.increment_luser  		= feedback[0].feedback_users_id
			  			this.form.increment_ldate  		= feedback[0].feedback_users_date
			  			this.url_type 			 	= 'edit'
		  			} else {
		  				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
	  	SnackBar (dialog, color, text, timeout) {
	      this.snackbar = {
	      	dialog: dialog,
	      	color: color,
	      	text: text,
	      	timeout: timeout
	      }
	      if (color === 'error') {
	      	this.confirm.dialog 	 = false
	      	this.confirm.text 	 	 = 'Ok'
	      }
	    }
	}
}
</script>