<template>
<div>
  <app-drawer_menu drawer_menu_title="Certificate"></app-drawer_menu>
  <v-progress-linear
    indeterminate
    color   ="fourth"
    rounded
    v-if    ="loading.page"
  ></v-progress-linear>

  <v-stepper v-model="pp_la" vertical>
    <v-stepper-step :complete="pp_la > 1" step="1">
      Start
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card class="mb-12" outlined>
        <v-form enctype="multipart/form-data" ref="form_campaign" lazy-validation>
          <v-card-title>Campaign</v-card-title>
          <v-card-subtitle>Select Campaign Template</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <app-cb
                  cb_type           ="campaign"
                  cb_url            ="apiListCampaign"
                  cb_url_parameter  ="campaig_actived=Y"
                  cb_title          ="Campaign"
                  cb_id             ="campaign_id"
                  cb_desc           ="campaign_desc"
                  cb_rules          ="Please fill Campaign ID"
                  cb_desc_readonly  ="readonly"
                  cb_items_id       ="campaign_id"
                  cb_items_desc     ="campaign_desc"
                  :cb_value_id		  ="form.campaign_id"
                  :cb_value_desc		="form.campaign_desc"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-dialog
                  ref                 ="dialog_policy_sdate"
                  v-model             ="modal.policy_sdate"
                  :return-value.sync  ="form.policy_sdate"
                  width               ="290px"
                  persistent
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model             ="form.policy_sdate"
                      label               ="Start Date"
                      prepend-inner-icon  ="event"
                      background-color    ="date"
                      v-on                ="on"
                      v-mask              ="'####-##-##'"
                      placeholder         ="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.policy_sdate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.policy_sdate = false">Cancel</v-btn>
                    <v-btn
                      text
                      color   ="primary"
                      @click  ="$refs.dialog_policy_sdate.save(form.policy_sdate), SEDMOnChange('inception')"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-dialog
                  ref                 ="dialog_policy_edate"
                  v-model             ="modal.policy_edate"
                  :return-value.sync  ="form.policy_edate"
                  width               ="290px"
                  persistent
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model             ="form.policy_edate"
                      label               ="End Date"
                      prepend-inner-icon  ="event"
                      background-color    ="date"
                      v-on                ="on"
                      v-mask              ="'####-##-##'"
                      placeholder         ="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.policy_edate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.policy_edate = false">Cancel</v-btn>
                    <v-btn
                      text
                      color   ="primary"
                      @click  ="$refs.dialog_policy_edate.save(form.policy_edate), SEDMOnChange('expiry')"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <!-- Day -->
              <v-col cols="6" sm="2" md="2" class="mt-n3">
                <v-text-field 
                  v-model           ="form.policy_day"
                  label             ="Day"
                  background-color  ="numeric"
                  append-icon       ="wb_sunny"
                  reverse
                  @change           ="SEDMOnChange('day')"
                >
                </v-text-field>
              </v-col>

              <!-- Month -->
              <v-col cols="6" sm="2" md="2" class="mt-n3">
                <v-text-field 
                  v-model           ="form.policy_month"
                  label             ="Month"
                  background-color  ="numeric"
                  append-icon       ="brightness_2"
                  reverse
                  @change           ="SEDMOnChange('month')"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="Confirm('writestep1','')">Confirm</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-stepper-content>

    <v-stepper-step :complete="pp_la > 2" step="2">
      Risk Logic
    </v-stepper-step>
    <v-stepper-content step="2">
      <v-row>
        <v-col cols="12" sm="12" md="12" class="mb-n5">
          <v-card class="mb-12" outlined>
            <v-form enctype="multipart/form-data" ref="form_risklogic" lazy-validation>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="8" class="mb-n5">
                    <v-card class="mb-12" outlined>
                      <v-form enctype="multipart/form-data" ref="form_risklogic" lazy-validation>
                        <v-card-title>Risk Logic</v-card-title>
                        <v-card-subtitle>Please Fill Risk Logic</v-card-subtitle>
                        <v-card-text>
                          <v-row>
                            <!-- Riskcov -->
                            <v-col cols="12" sm="6" md="6" class="mt-n3">
                              <app-cb
                                cb_type           ="riskcov"
                                cb_url            ="apiListRiskcov"
                                cb_url_parameter  ="riskcov_actived=Y"
                                cb_title          ="Risk Coverage"
                                cb_id             ="policyrisklogic_riskcov_id"
                                cb_desc           ="policyrisklogic_riskcov_desc"
                                cb_rules          ="Please fill Risk Coverage"
                                cb_desc_readonly  ="readonly"
                                cb_items_id       ="riskcov_id"
                                cb_items_desc     ="riskcov_desc"
                                :cb_value_id      ="form.policyrisklogic_riskcov_id"
                                :cb_value_desc    ="form.policyrisklogic_riskcov_desc"
                              >
                              </app-cb>
                            </v-col>

                            <!-- Interest -->
                            <v-col cols="12" sm="6" md="6" class="mt-n3">
                              <app-cb
                                cb_type           ="interest"
                                cb_url            ="apiListInterest"
                                cb_url_parameter  ="interest_actived=Y"
                                cb_title          ="Interest"
                                cb_id             ="policyrisklogic_interest_id"
                                cb_desc           ="policyrisklogic_interest_desc"
                                cb_rules          ="Please fill Interest"
                                cb_desc_readonly  ="readonly"
                                cb_items_id       ="interest_id"
                                cb_items_desc     ="interest_desc"
                                :cb_value_id      ="form.policyrisklogic_interest_id"
                                :cb_value_desc    ="form.policyrisklogic_interest_desc"
                              >
                              </app-cb>
                            </v-col>

                            <!-- Inception -->
                            <v-col cols="7" sm="3" md="2" class="mb-n5">
                              <v-dialog
                                ref                 ="dialog_policyrisklogic_inception"
                                v-model             ="modal.policyrisklogic_inception"
                                :return-value.sync  ="form.policyrisklogic_inception"
                                persistent
                                width               ="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model             ="form.policyrisklogic_inception"
                                    label               ="Inception"
                                    prepend-inner-icon  ="event"
                                    background-color    ="date"
                                    readonly
                                    v-on                ="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker 
                                  v-model ="form.policyrisklogic_inception" 
                                  scrollable                      
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn text color="primary" @click="modal.policyrisklogic_inception = false">Cancel</v-btn>
                                  <v-btn text color="primary" @click="$refs.dialog_policyrisklogic_inception.save(form.policyrisklogic_inception), IEDMOnChange('inception')">OK</v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-col>

                            <!-- Inception Time -->
                            <v-col cols="5" sm="2" md="2" class="mb-n5">
                              <v-dialog
                                ref                 ="dialog_policyrisklogic_stime"
                                v-model             ="modal.policyrisklogic_stime"
                                :return-value.sync  ="form.policyrisklogic_stime"
                                persistent
                                width               ="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model             ="form.policyrisklogic_stime"
                                    prepend-inner-icon  ="access_time"
                                    background-color    ="time"
                                    readonly
                                    v-on                ="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  v-if    ="modal.policyrisklogic_stime"
                                  v-model ="time"
                                  format  ="24hr"
                                  full-width
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn text color="primary" @click="modal.policyrisklogic_stime = false">Cancel</v-btn>
                                  <v-btn text color="primary" @click="$refs.dialog_policyrisklogic_stime.save(form.policyrisklogic_stime)">OK</v-btn>
                                </v-time-picker>
                              </v-dialog>
                            </v-col>
                            
                            <!-- Expiry -->
                            <v-col cols="7" sm="3" md="2" class="mb-n5">
                              <v-dialog
                                ref                 ="dialog_policyrisklogic_expiry"
                                v-model             ="modal.policyrisklogic_expiry"
                                :return-value.sync  ="form.policyrisklogic_expiry"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model             ="form.policyrisklogic_expiry"
                                    label               ="Expiry"
                                    prepend-inner-icon  ="event"
                                    readonly
                                    background-color    ="date"
                                    v-on                ="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.policyrisklogic_expiry" :min="form.policyrisklogic_inception" scrollable>
                                  <v-spacer></v-spacer>
                                  <v-btn text color="primary" @click="modal.policyrisklogic_expiry = false">Cancel</v-btn>
                                  <v-btn text color="primary" @click="$refs.dialog_policyrisklogic_expiry.save(form.policyrisklogic_expiry), IEDMOnChange('expiry')">OK</v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-col>

                            <!-- Expiry Time -->
                            <v-col cols="5" sm="2" md="2" class="mb-n5">
                              <v-dialog
                                ref                 ="dialog_policyrisklogic_etime"
                                v-model             ="modal.policyrisklogic_etime"
                                :return-value.sync  ="form.policyrisklogic_etime"
                                persistent
                                width               ="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model           ="form.policyrisklogic_etime"
                                    prepend-inner-icon="access_time"
                                    background-color  ="time"
                                    readonly
                                    v-on              ="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  v-if    ="modal.policyrisklogic_etime"
                                  v-model ="time"
                                  format  ="24hr"
                                  full-width
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn text color="primary" @click="modal.policyrisklogic_etime = false">Cancel</v-btn>
                                  <v-btn text color="primary" @click="$refs.dialog_policyrisklogic_etime.save(form.policyrisklogic_etime)">OK</v-btn>
                                </v-time-picker>
                              </v-dialog>
                            </v-col>

                            <!-- Day -->
                            <v-col cols="6" sm="2" md="2" class="mb-n5">
                                <v-text-field 
                                v-model           ="form.policyrisklogic_day"
                                label             ="Day"
                                background-color  ="numeric"
                                append-icon       ="wb_sunny"
                                reverse
                                @change           ="IEDMOnChange('day')"
                                >
                                </v-text-field>
                            </v-col>

                            <!-- Month -->
                            <v-col cols="6" sm="2" md="2" class="mb-n5">
                                <v-text-field 
                                v-model           ="form.policyrisklogic_month"
                                label             ="Month"
                                background-color  ="numeric"
                                append-icon       ="brightness_2"
                                reverse
                                @change           ="IEDMOnChange('month')"
                                >
                                </v-text-field>
                            </v-col>

                            <!-- Rate Flag -->
                            <v-col cols="7" sm="3" md="2" class="mb-n5">
                              <label class="caption">Rate Flag</label>
                              <v-switch
                                v-model ="form.policyrisklogic_rateflag"
                                color   ="primary"
                                id      ="form.policyrisklogic_rateflag"
                                :label  ="form.policyrisklogic_rateflag !== true ? 'Lower' : 'Upper'"
                                class   ="mt-n1"
                              ></v-switch>
                            </v-col>

                            <!-- TSI -->
                            <v-col cols="7" sm="3" md="2" class="mb-n5">
                              <v-text-field
                                v-model.lazy      ="form.policyrisklogic_tsi"
                                ref               ="policyrisklogic_tsi"
                                label             ="TSI"
                                placeholder       ="0"
                                background-color  ="numeric"
                                v-money           ="format_policyrisklogic.tsi"
                                id                ="policyrisklogic_tsi"
                                reverse
                              >
                              </v-text-field>
                            </v-col>

                            <!-- Rate -->
                            <v-col cols="7" sm="3" md="2" class="mb-n5">
                              <v-text-field
                                v-model.lazy      ="form.policyrisklogic_rate"
                                ref               ="policyrisklogic_rate"
                                label             ="Rate"
                                placeholder       ="0"
                                background-color  ="numeric"
                                v-money           ="format_policyrisklogic.rate"
                                id                ="policyrisklogic_rate"
                                reverse
                              >
                              </v-text-field>
                            </v-col>

                            <!-- Rate Unit -->
                            <v-col cols="7" sm="3" md="2" class="mb-n5">
                              <v-select
                                v-model		  ="form.policyrisklogic_rateunit"										
                                :items		  ="form.policyrisklogic_rateunit_option"
                                :item-text  ="form.policyrisklogic_rateunit_option.text"
                                :item-value ="form.policyrisklogic_rateunit_option.value"
                                label		    ="Rate Unit"
                                id			    ="policyrisklogic_rateunit"
                              ></v-select>
                            </v-col>

                            <!-- Risk Flag -->
                            <v-col cols="7" sm="3" md="2" class="mb-n5">
                              <label class="caption">Risk Flag</label>
                              <v-switch
                                v-model ="form.policyrisklogic_rateflag"
                                color   ="primary"
                                id      ="form.policyrisklogic_rateflag"
                                :label  ="form.policyrisklogic_rateflag !== true ? 'Single' : 'Multiple'"
                                class   ="mt-n1"
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-form>
                    </v-card>

                    <v-card-actions>
                      <v-btn color="primary" @click="pp_la=3">Next</v-btn>
                      <v-btn color="primary">View Multiple Data</v-btn>
                    </v-card-actions>
                  </v-col>

                  <v-col cols="12" sm="12" md="4" class="mb-n5">
                    <v-card class="mb-4" outlined>
                      <v-card-title>Object Information</v-card-title>
                      <v-card-text>
                        <v-row class="mx-2" v-for="index in 20" :key="index">
                          <v-col cols="12" sm="12" md="12" class="" v-if ="gendet_fieldlabel_value[index] !== undefined">
                            <!-- <app-oi
                              :oi_title         ="gendet_fieldlabel_value[index]"
                              :oi_id            ="`gendet_fieldid_${index}`"
                              :oi_desc          ="`gendet_fielddesc_${index}`"
                              oi_rules          ="Please fill"
                              :oi_desc_readonly ="false"
                              :oi_refid         ="gendet_fieldid_value[index]"
                              :oi_value_id      ="gentab_detailid_value[index]"
                              :oi_value_desc    ="gentab_detaildesc_value[index]"
                              dense
                            ></app-oi> -->
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-stepper-content>

    <v-stepper-step :complete="pp_la > 3" step="3">
      Main Form
      <small></small>
    </v-stepper-step>
    <v-stepper-content step="3">
      <v-row>
        <v-col cols="12" sm="12" md="8" class="mb-n5">
          <v-card class="mb-12" outlined>
            <v-card-title>General Policy Information</v-card-title>
            <!-- <v-card-subtitle>Select Campaign Template</v-card-subtitle> -->
            <v-card-text>
              <v-row>
                <!-- Campaign ID -->
                <v-col cols="3" sm="3" md="3" class="mb-n5">
                  <v-text-field 
                  v-model     ="form.policy_campaign_id"
                  label       ="Campaign No."
                  placeholder ="Campaign No."
                  readonly    ="readonly"
                  background-color ="readonly"
                  >
                  </v-text-field>
                </v-col>
                <!-- Campaign Description -->
                <v-col cols="9" sm="9" md="9" class="mb-n5">
                  <v-text-field 
                  v-model           ="form.policy_campaign_desc"
                  readonly          ="readonly"
                  background-color  ="readonly"
                  >
                  </v-text-field>
                </v-col>
                <!-- Quotation Number -->
                <v-col cols="12" sm="4" md="3" class="mb-n5">
                    <v-text-field 
                    v-model           ="form.policy_quotation_no"
                    label             ="Quotation No."
                    placeholder       ="<<Automation No.>>"
                    readonly          ="readonly"
                    background-color  ="readonly"
                    >
                    </v-text-field>
                </v-col>
                <!-- Policy Number -->
                <v-col cols="6" sm="5" md="3" class="mb-n5">
                  <v-text-field 
                  v-model           ="form.policy_no"
                  label             ="Policy No"
                  placeholder       ="<<Automation No.>>"
                  readonly          ="readonly"
                  background-color  ="readonly"
                  >
                  </v-text-field>
                </v-col>
                <!-- Certificate Number -->
                <v-col cols="6" sm="5" md="3" class="mb-n5">
                  <v-text-field 
                  v-model           ="form.policy_certificateno"
                  label             ="Certificate No"
                  placeholder       ="<<Automation No.>>"
                  readonly          ="readonly"
                  background-color  ="readonly"
                  >
                  </v-text-field>
                </v-col>
                <!-- Endorsment Number -->
                <v-col cols="2" sm="1" md="1" class="mb-n5">
                  <v-text-field 
                  v-model     ="form.pp_end_no"
                  readonly    = "readonly"
                  background-color = "numeric"
                  reverse
                  >
                  </v-text-field>
                </v-col>
                <!-- Endorsment External Number -->
                <v-col cols="2" sm="1" md="1" class="mb-n5">
                  <v-text-field 
                  v-model="form.pp_end_ext_no"
                  readonly    = "readonly"
                  background-color = "numeric"
                  reverse
                  >
                  </v-text-field>
                </v-col>
                <!-- Renewal Number -->
                <v-col cols="2" sm="1" md="1">
                  <v-text-field 
                  v-model="form.pp_renewal_no"
                  readonly    = "readonly"
                  background-color = "numeric"
                  reverse
                  >
                  </v-text-field>
                </v-col>
                <!-- Sub Class -->
                <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <app-cb
                    cb_type           = "subclass"
                    cb_url            = "apiListSubClass"
                    cb_url_parameter  = "subclass_actived=Y"
                    cb_title          = "Sub Class"
                    cb_id             = "policy_subclass"
                    cb_desc           = "policy_subclass_desc"
                    cb_rules          = "Please fill ID"
                    cb_desc_readonly  = "readonly"
                    cb_items_id       = "subclass_id"
                    cb_items_desc     = "subclass_desc"
                    :cb_value_id		  = "form.policy_subclass"
                    :cb_value_desc		= "form.policy_subclass_desc"
                  >
                  </app-cb>
                </v-col>
                <!-- Insurance Type -->
                <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <app-cb
                    cb_type           = "instype"
                    cb_url            = "apiListInstype"
                    cb_url_parameter  = "instype_actived=Y"
                    cb_title          = "Insurance Type"
                    cb_id             = "policy_instype"
                    cb_desc           = "policy_instype_desc"
                    cb_rules          = "Please fill ID"
                    cb_desc_readonly  = "readonly"
                    cb_items_id       = "instype_id"
                    cb_items_desc     = "instype_description"
                    :cb_value_id		  = "form.policy_instype"
                    :cb_value_desc		= "form.policy_instype_desc"
                  >
                  </app-cb>
                </v-col>                                
                <!-- Marketing Officer -->
                <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <app-cb
                    cb_type           = "marketingofficer"
                    cb_url            = "apiListUsers"
                    cb_url_parameter  = "users_actived=Y"
                    cb_title          = "Marketing Officer"
                    cb_id             = "policy_mo"
                    cb_desc           = "policy_mo_desc"
                    cb_rules          = "Please fill ID"
                    cb_desc_readonly  = "readonly"
                    cb_items_id       = "users_id"
                    cb_items_desc     = "users_name"
                    :cb_value_id		  = "form.policy_mo"
                    :cb_value_desc		= "form.policy_mo_desc"
                    
                  >
                  </app-cb>
                </v-col>
                <!-- Channel -->
                <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <app-cb
                    cb_type           = "channel"
                    cb_url            = "apiListChannel"
                    cb_url_parameter  = "channel_actived=Y"
                    cb_title          = "Channel"
                    cb_id             = "policy_channel"
                    cb_desc           = "policy_channel_desc"
                    cb_rules          = "Please fill ID"
                    cb_desc_readonly  = "readonly"
                    cb_items_id       = "channel_id"
                    cb_items_desc     = "channel_desc"
                    :cb_value_id		  = "form.policy_channel"
                    :cb_value_desc		= "form.policy_channel_desc"
                  >
                  </app-cb>
                </v-col>
                <!-- Branch -->
                <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <app-cb
                    cb_type           = "branch"
                    cb_url            = "apiListBranch"
                    cb_url_parameter  = "branch_actived=Y"
                    cb_title          = "Branch"
                    cb_id             = "policy_branch"
                    cb_desc           = "policy_branch_desc"
                    cb_rules          = "Please fill ID"
                    cb_desc_readonly  = "readonly"
                    cb_items_id       = "branch_id"
                    cb_items_desc     = "branch_desc"
                    :cb_value_id		  = "form.policy_branch"
                    :cb_value_desc		= "form.policy_branch_desc"
                  >
                  </app-cb>
                </v-col>
                <!-- Cost Center -->
                <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <app-cb
                    cb_type           = "costcenter"
                    cb_url            = "apiListDepartment"
                    cb_url_parameter  = "costcenter_actived=Y"
                    cb_title          = "Cost Center"
                    cb_id             = "policy_costcenter"
                    cb_desc           = "policy_costcenter_desc"
                    cb_rules          = "Please fill ID"
                    cb_desc_readonly  = "readonly"
                    cb_items_id       = "department_id"
                    cb_items_desc     = "department_desc"
                    :cb_value_id		  = "form.policy_costcenter"
                    :cb_value_desc		= "form.policy_costcenter_desc"
                  >
                  </app-cb>
                </v-col>
                <!-- Policy Holder -->
                <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <app-cb
                    cb_type           ="policyholder"
                    cb_url            ="apiListProfile"
                    cb_url_parameter  ="profile_actived=Y"
                    cb_title          ="Policy Holder"
                    cb_id             ="policy_holder"
                    cb_desc           ="policy_holder_desc"
                    cb_rules          ="Please fill ID"
                    cb_desc_readonly  ="readonly"
                    cb_items_id       ="profile_id"
                    cb_items_desc     ="profile_name"
                    :cb_value_id		  ="form.policy_holder"
                    :cb_value_desc		="form.policy_holder_desc"
                    @clicked          ="GetPolicyHolder"
                  >
                  </app-cb>
                </v-col>
                <!-- Insured -->
                <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <app-cb
                    cb_type           ="policyholder"
                    cb_url            ="apiListProfile"
                    cb_url_parameter  ="profile_actived=Y"
                    cb_title          ="Insured"
                    cb_id             ="policy_insured"
                    cb_desc           ="policy_insured_desc"
                    cb_rules          ="Please fill ID"
                    cb_desc_readonly  ="readonly"
                    cb_items_id       ="profile_id"
                    cb_items_desc     ="profile_name"
                    :cb_value_id		  ="form.policy_insured"
                    :cb_value_desc		="form.policy_insured_desc"
                    @clicked          ="GetInsured"
                  >
                  </app-cb>
                </v-col>                
                <!-- Long Insured Name -->
                <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <v-textarea
                    v-model    ="form.policy_longinsured"
                    id         ="form.policy_longinsured"
                    label      ="Long Insured Name"
                    hint       ="Insured Name"
                    rows       ="1"
                  ></v-textarea>
                </v-col>
                <!-- Field Exclusive Period -->
                <v-col cols="12" sm="12" md="2" class="mt-n0">
                  <label>Exclusive Period :</label>
                  <v-checkbox
                    v-model ="form.policy_excl_period"
                    :label  ="form.policy_excl_period === true ? 'Yes' : '' "
                    class   ="mt-n1">
                  </v-checkbox>
                </v-col>
                <!-- Prorata Basis -->
                <v-col cols="12" sm="2" md="2" class="mt-n0">
                  <v-select
                  v-model           ="form.policy_prorate"
                  :items            ="form.policy_prorate_data"
                  label             ="Prorata Basis"
                  item-value        ="id"
                  item-text         ="text"
                  return-object
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                </v-col>
                <!-- Total Sum Insured -->
                <v-col cols="12" sm="12" md="2" class="mb-n5">
                    <v-text-field
                    v-model.lazy      ="form.pp_sum_insured"
                    label             = "Sum Insured"
                    placeholder       = "0"
                    background-color  = "numeric"
                    v-money           ="format_money"
                    reverse
                    readonly
                    >
                    </v-text-field>
                </v-col>
                <!-- Additional Sum Insured -->
                <v-col cols="12" sm="12" md="2" class="mb-n5">
                    <v-text-field 
                    v-model.lazy      ="form.pp_add_sum_insured"
                    label             = "Additional Sum Insured"
                    placeholder       = "0"
                    background-color  = "numeric"
                    v-money           ="format_money"
                    reverse
                    readonly
                    >
                    </v-text-field>
                </v-col>
                <!-- Policy Limit -->
                <v-col cols="12" sm="12" md="2" class="mb-n5">
                    <v-text-field 
                    v-model           ="form.pp_policy_limit"
                    label             = "Policy Limit"
                    placeholder       = "0"
                    background-color  = "numeric"
                    v-money           ="format_money"
                    reverse
                    readonly
                    >
                    </v-text-field>
                </v-col> 

                <v-col cols="12" sm="6" md="6" class="mb-n5">
                </v-col>

                <!-- Day Basis -->
                <v-col cols="6" sm="2" md="2" class="mt-n3">
                    <v-text-field 
                      v-model           ="form.policy_day_basis"
                      id                ="form.policy_day_basis"
                      label             ="Day Basis"
                      background-color  ="numeric"
                      hint              ="in day(s)"
                      reverse
                    >
                    </v-text-field>
                </v-col>
                <!-- Extended -->
                <v-col cols="6" sm="2" md="2" class="mt-n3">
                    <v-text-field 
                      v-model           ="form.policy_extended"
                      id                ="form.policy_extended"
                      label             ="Extended"
                      background-color  ="numeric"
                      reverse
                    >
                    </v-text-field>
                </v-col>
                <!-- Validation -->
                <v-col cols="12" sm="12" md="2" class="mt-n3">
                    <v-text-field 
                      v-model           ="form.policy_validation"
                      id                ="form.policy_validation"
                      label             ="Validation"
                      background-color  ="numeric"
                      reverse
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">                  
                </v-col>
                <!-- Location -->
                <v-col cols="12" sm="12" md="6" class="mt-n0">
                  <app-cb
                    cb_type           ="location"
                    cb_url            ="apiListGenDetailShort"
                    cb_url_parameter  ="gendet_actived=Y&gendet_gentab_id=L01"
                    cb_title          ="Location"
                    cb_id             ="policy_location"
                    cb_desc           ="policy_locationdesc"
                    cb_rules          ="Please fill Location"
                    cb_desc_readonly  ="readonly"
                    cb_items_id       ="gendet_id"
                    cb_items_desc     ="gendet_desc"
                  >
                  </app-cb>
                </v-col>
                <!-- Wording -->
                <v-col cols="12" sm="12" md="6" class="mt-n0">
                  <app-cb
                    cb_type           ="wording"
                    cb_url            ="apiListWording"
                    cb_url_parameter  ="wording_actived=Y"
                    cb_title          ="Wording"
                    cb_id             ="policy_wording"
                    cb_desc           ="policy_wordingdesc"
                    cb_rules          ="Please fill Wording"
                    cb_desc_readonly  ="readonly"
                    cb_items_id       ="wording_id"
                    cb_items_desc     ="wording_shortdesc"
                  >
                  </app-cb>
                </v-col>
                <!-- Original Reference No -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <v-text-field 
                    v-model     ="form.policy_refno"
                    label       ="Reference No"
                    placeholder ="Reference No"
                  >
                  </v-text-field>
                </v-col>
                <!-- Original Document No -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                    <v-text-field 
                    v-model     ="form.policy_original_docno"
                    label       ="Original Document No"
                    placeholder ="Original Document No"
                    >
                    </v-text-field>
                </v-col>
                <!-- Conjunction Quo No -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                    <v-text-field 
                    v-model     ="form.policy_conjunction_quotation"
                    label       ="Conjunction Quo No"
                    placeholder ="Conjunction Quo No"
                    >
                    </v-text-field>
                </v-col>
                <!-- Leader Policy No -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <v-text-field 
                  v-model="form.policy_leader_no"
                  label       ="Leader Policy No"
                  placeholder ="Leader Policy No"
                  >
                  </v-text-field>
                </v-col>
                <!-- Old Policy No -->
                <v-col cols="6" sm="4" md="4" class="mt-n3">
                    <v-text-field 
                    v-model     ="form.policy_old_no"
                    label       ="Old Policy No"
                    placeholder ="Old Policy No"
                    >
                    </v-text-field>
                </v-col>
                <!-- Conjunction Reg No -->
                <!-- <v-col cols="12" sm="6" md="6" class="mb-n5">
                  <app-cb
                    cb_type           = "insured"
                    cb_url            = "apiListConjunctionRegNo"
                    cb_url_parameter  = "profile_actived=Y"
                    cb_title          = "Conjunction Reg No"
                    cb_id             = "pp_insured_id"
                    cb_desc           = "pp_insured_name"
                    cb_rules          = "Please fill ID"
                    cb_desc_readonly  = "readonly"
                    cb_items_id       = "profile_id"
                    cb_items_desc     = "profile_name"
                  >
                  </app-cb>
                </v-col> -->
                <!-- Reference No -->
                <!-- <v-col cols="12" sm="6" md="4" class="mb-n5">
                    <v-text-field 
                    v-model="form.pp_reference_no"
                    label       = "Reference No"
                    placeholder = "Reference No"
                    >
                    </v-text-field>
                </v-col> -->
                <!-- Reference Date -->
                <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-dialog
                    ref                 ="dialog_policy_refdate"
                    v-model             ="modal.policy_refdate"
                    :return-value.sync  ="form.policy_refdate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model             ="form.policy_refdate"
                        label               ="Reference Date"
                        prepend-inner-icon  ="event"
                        background-color    ="date"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.policy_refdate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.policy_refdate = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog_policy_refdate.save(form.policy_refdate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- Leader Policy Date -->
                <v-col cols="12" sm="6" md="2" class="mt-n3">
                  <v-dialog
                    ref                 ="dialog_policy_leader_date"
                    v-model             ="modal.policy_leader_date"
                    :return-value.sync  ="form.policy_leader_date"
                    width               ="290px"
                    persistent
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model             ="form.policy_leader_date"
                        label               ="Leader Policy"
                        prepend-inner-icon  ="event"
                        background-color    ="date"
                        v-on                ="on"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.policy_leader_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.policy_leader_date = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog_policy_leader_date.save(form.policy_leader_date)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- Ujroh Cedant -->
                <v-col cols="6" sm="2" md="2" class="mt-n3">
                    <v-text-field 
                    v-model           ="form.policy_ujroh_cedant"
                    background-color  ="numeric"
                    label             ="Ujroh Cedant"
                    placeholder       ="Ujroh Cedant"
                    hint              ="(%)"
                    reverse
                    >
                    </v-text-field>
                </v-col>
                <!-- Ujroh Cedant Ori -->
                <v-col cols="6" sm="2" md="2" class="mt-n3">
                    <v-text-field 
                    v-model           ="form.policy_ujroh_cedant_ori"
                    background-color  ="numeric"
                    label             ="Ujroh Cedant Ori"
                    placeholder       ="Ujroh Cedant Ori"
                    hint              ="(%)"
                    reverse
                    >
                    </v-text-field>
                </v-col>
                <!-- Field Automatic Calculate Weighted Average Ujroh -->
                <v-col cols="12" sm="5" md="5" class="mt-n2">
                  <label>Automatic Calculate Weighted Average Ujroh :</label>
                  <v-checkbox
                    v-model ="form.policy_ujroh_flag"
                    :label  ="form.policy_ujroh_flag === true ? 'Yes' : '' "
                    class   ="mt-n1">
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mb-n5">
          <v-card class="mb-4" outlined>
            <v-card-title>Object Information</v-card-title>
            <v-card-text>
              <v-row class="mx-2" v-for="index in 20" :key="index">
                <v-col cols="12" sm="12" md="12" class="" v-if ="gendet_fieldlabel_value[index] !== undefined">
                  <app-oi
                    :oi_title         ="gendet_fieldlabel_value[index]"
                    :oi_id            ="`gendet_fieldid_${index}`"
                    :oi_desc          ="`gendet_fielddesc_${index}`"
                    oi_rules          ="Please fill"
                    :oi_desc_readonly ="false"
                    :oi_refid         ="gendet_fieldid_value[index]"
                    :oi_value_id      ="gentab_detailid_value[index]"
                    :oi_value_desc    ="gentab_detaildesc_value[index]"
                    dense
                  ></app-oi>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-alert
            border="top"
            colored-border
            type="info"
            elevation="2"
          >
            Please save your data first to unlock the next form 
          </v-alert>
          <!-- <v-btn color="primary" @click="pp_la = 3" outlined block>{{$vars.V('icon_save')}}</v-btn> -->
          <v-btn color="primary" @click="WritePolicy(form.policy_row)" outlined block>{{$vars.V('icon_save')}}</v-btn>

          <v-card class="mb-4 mt-4" outlined>
            <v-card-title>Coverage</v-card-title>
            <v-card-text>
              <v-btn tile color="theme--dark purple darken-1" class="mr-2" @click="OpenPolicyRiskIndex()">
                <v-icon left>lock</v-icon>
                RISK
              </v-btn>
              <v-btn tile color="theme--dark purple darken-2" class="mr-2" @click="OpenPolicyIntIndex()">
                <v-icon left>mdi-heart</v-icon>
                INTEREST
              </v-btn>
              <v-btn tile color="theme--dark purple darken-3" class="mr-2" @click="OpenPolicyDedIndex()">
                <v-icon left>assignment_ind</v-icon>
                DEDUCTIBLE
              </v-btn>
            </v-card-text>
            <v-card-title>Wording & Clause</v-card-title>
            <v-card-text>
              <v-btn tile color="theme--dark green darken-1" class="mr-2" @click="genpolicy.dialog.wording = true">
                <v-icon left>lock</v-icon>
                WORDING & CLAUSE
              </v-btn>
              <v-btn tile color="theme--dark green darken-2" class="mr-2" @click="genpolicy.dialog.clause = true">
                <v-icon left>mdi-heart</v-icon>
                Banker CLAUSE
              </v-btn>
            </v-card-text>
            <v-card-title>Source / Payor / Fee & Duty</v-card-title>
            <v-card-text>
              <v-btn tile color="theme--dark blue darken-1" class="mr-2" @click="OpenPolicySourceIndex()">
                <v-icon left>lock</v-icon>
                Biz. Source
              </v-btn>
              <v-btn tile color="theme--dark blue darken-2" class="mr-2" @click="genpolicy.dialog.payors = true">
                <v-icon left>mdi-heart</v-icon>
                Payor
              </v-btn>
              <v-btn tile color="theme--dark blue darken-2" class="mr-2" @click="genpolicy.dialog.payors = true">
                <v-icon left>mdi-heart</v-icon>
                Surveyor
              </v-btn>
            </v-card-text>
            <v-card-text>
              <v-btn tile color="theme--dark blue darken-3" class="mr-2" @click="OpenPolicyFeeDutyIndex()">
                <v-icon left>assignment_ind</v-icon>
                Fee & Duty
              </v-btn>
              <v-btn tile color="theme--dark blue darken-3" class="mr-2" @click="OpenPolicyTCIndex()">
                <v-icon left>assignment_ind</v-icon>
                Term & Condition
              </v-btn>
              <v-btn tile color="theme--dark blue darken-2" class="mr-2" @click="genpolicy.dialog.payors = true">
                <v-icon left>mdi-heart</v-icon>
                Delivery
              </v-btn>
            </v-card-text>
            <v-card-title>Spreading Of Risk / Depreciation</v-card-title>
            <v-card-text>
              <v-btn tile color="theme--dark blue darken-2" class="mr-2" @click="genpolicy.dialog.payors = true">
                <v-icon left>mdi-heart</v-icon>
                SOR
              </v-btn>
              <v-btn tile color="theme--dark blue darken-2" class="mr-2" @click="OpenPolicyDepreciationIndex()">
                <v-icon left>mdi-heart</v-icon>
                Depreciation
              </v-btn>
            </v-card-text>
            <v-card-title>Timeline History</v-card-title>
            <v-card-actions>
              <v-btn color="primary" @click="pp_la = 3">Next</v-btn>
            </v-card-actions>
            <v-overlay
              :absolute="true"
              :value="gpioverlay"
            >
            <v-btn
              color="danger"
              @click="gpioverlay = false"
            >
              <v-icon left>lock</v-icon> Locked
            </v-btn>
            </v-overlay>
          </v-card>
            
        </v-col>
      </v-row>
    </v-stepper-content>
    
    <v-stepper-step :complete="pp_la > 4" step="4">
      Calculate
      <small></small>
    </v-stepper-step>
    <v-stepper-content step="4">
      <v-row>
        <v-col cols="12" sm="12" md="12" class="mb-n5">
          <v-card class="mb-12" outlined>
            <v-card-title>Premium Calculation</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="6" class="mb-n5">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>Type</td>
                          <td>New</td>
                        </tr>
                        <tr>
                          <td>Quotation No</td>
                          <td>123456</td>
                        </tr>
                        <tr>
                          <td>C/I No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Policy No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Certificate No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Remarks</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="mb-n5">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>ANO</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Inforce Date</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Period</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Voyage</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Open Cover</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Comment</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="12" class="mb-n5">
          <v-card class="mb-12" outlined>
            <v-card-title>General Policy Information</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="6" class="mb-n5">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>Policy No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Certificate No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Prev Policy No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Prev Certificate No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Endorse/Renewal #</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Boking Date</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Effective Date</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Expiry At</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>segment</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Policy Holder</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Insured Name</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Business Source</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Fax</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Sent To (Policy)</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Sent To (Claim)</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Batch No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>ANO</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="mb-n5">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>Premium Amount Due</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Premium Paid</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Premium Outstanding</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Premium Lapse</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Payment Due</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Insurance Period</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Risk Coverage</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Memo</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Comment</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Remarks</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Original Document No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Reference Date</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Reference No</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Bulk Inforce</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>STNC Date</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
               </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="mb-n5">
          <v-toolbar
            flat
            color="primary"
            dark
          >
            <v-toolbar-title>Review</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical>
            <v-tab>Object Information</v-tab>
            <v-tab>Risk Coverage</v-tab>
            <v-tab>Interest Insured</v-tab>
            <v-tab>Deductible</v-tab>
            <v-tab>General Clause</v-tab>
            <v-tab>General Condition</v-tab>
            <v-tab>General Exclusions</v-tab>
            <v-tab>Claim History</v-tab>
            <v-tab>O/S Premium</v-tab>
            <v-tab>O/S Claim Payable</v-tab>
            <v-tab>Business Source</v-tab>
            <v-tab>Premium Paid</v-tab>
            <v-tab>Claim Paid</v-tab>
            <v-tab>Installment</v-tab>
            <v-tab>DN/CN List</v-tab>
            <v-tab>Policy Conjunction</v-tab>
            <v-tab>Policy EStamp</v-tab>
            <v-tab>Interested Party</v-tab>
            <v-tab>Beneficiaries</v-tab>
            <v-tab>Policy Limit</v-tab>
            <v-tab>Credit Limit</v-tab>
            <v-tab>Master Limit Liability</v-tab>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    Sed aliquam ultrices mauris. Donec posuere vulputate arcu. Morbi ac felis. Etiam feugiat lorem non metus. Sed a libero.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Nunc sed turpis.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-stepper-content>
    
    <v-stepper-step :complete="pp_la > 5" step="5">
      Payor Information
      <small></small>
    </v-stepper-step>
    
    <v-stepper-step :complete="pp_la > 6" step="6">
      Review & Done
      <small></small>
    </v-stepper-step>
  </v-stepper>


  <!-- DIALOG -->
  <!-- Start dialog risk -->
  <v-dialog v-model="genpolicy.dialog.risk" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="genpolicy.dialog.risk = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Risk</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="OpenRisk" :title="`${$vars.V('txt_add')} Map Detail`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Map Detail"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.valuedialog"></v-progress-linear>
        <v-spacer></v-spacer>
        <!-- Menu Table Risk -->
        <v-col cols="12" sm="12" md="12">
          <v-btn :color="risk.table.selected.length === risk.table.items.length ? 'fourth':''" @click="SelectAllMapValue" title="select all" class="mr-2" small>
            select all
          </v-btn>
          <v-btn @click="OpenRisk" class="mr-2" title="select all" small>
            Add
          </v-btn>
          <v-btn color="error" title="delete selected" @click="Confirm('multideletemapfield','')" class="mr-2" v-if="risk.table.selected.length > 0" :disabled="access.delete === 0 ? true:false" small>
            <v-icon>{{$vars.V('icon_delete')}}</v-icon>
          </v-btn>
          <v-btn color="primary" title="search" v-if="risk.table.search_button === false" @click="SearchMapValue" small>
            <v-icon>{{$vars.V('icon_search')}}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="risk.table.search" append-icon="search" @click:append="risk.table.search_button = false" label="Type and Enter" single-line hide-values v-if="risk.table.search_button" id="risk.table.search" clearable @keyup.enter="ListMapValue(form.mapheader_id, mapdetail.form.input.fieldfrom, mapdetail.form.input.fieldto)"></v-text-field>
          <!-- Start Table Risk -->
          <v-data-table
            v-model="risk.table.selected"
            :headers="risk.table.data"
            :items="risk.table.items"
            class="elevation-1"
            item-key="risk_id"
            show-select
             :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right'
            }"
          >
            <template v-slot:top>
              <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
            </template>
            <template v-slot:item.action="{ item }">
              <!-- <v-icon small class="mr-2" @click="EditValue(item.risk_headerid, item.risk_fieldfrom, item.risk_fieldto, item.risk_from, item.risk_to)" :disabled = "access.edit === 0 ? true:false">
                edit
              </v-icon> -->
              <v-icon small @click="Confirm('deleterisk',item.risk_headerid, item.risk_fieldfrom, item.risk_fieldto, item.risk_from, item.risk_to)" :disabled="access.delete === 0 ? true:false">
                delete
              </v-icon>
            </template>
          </v-data-table>
          <!-- End Table Risk -->
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="risk.dialog.form" transition="dialog-bottom-transition"  max-width="890">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="risk.dialog.form = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Risk</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Risk`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Risk"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <!-- Field Rate Code -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-text-field 
            v-model     = "risk.form.rate_code"
            label       = "Rate Code"
            placeholder = "Rate Code"
            ></v-text-field>
          </v-col>
          <!-- Field Rate Category -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-select
              v-model     ="risk.form.input.rate_category"
              :items      ="risk.form.group.rate_category"
              label       ="Rate Category"
              item-value  ="id"
              item-text   ="text"
              return-object
            ></v-select>
          </v-col>
          <!-- Field Period -->
          <v-col cols="12" sm="12" md="4" class="mb-n5">
            <v-text-field 
            v-model     = "risk.form.period"
            label       = "Period"
            placeholder = "0"
            background-color  ="numeric"
            ></v-text-field>
          </v-col>
          <!-- Field Discount & Loading -->
          <v-col cols="12" sm="12" md="4" class="mb-n5">
            <v-text-field 
            v-model     = "risk.form.discount"
            label       = "Discount & Loading (%)"
            placeholder = "0"
            background-color  ="numeric"
            ></v-text-field>
          </v-col>
          <!-- Field Net Rate -->
          <v-col cols="12" sm="12" md="4" class="mb-n5">
            <v-text-field 
            v-model     = "risk.form.net_rate_pct_loss"
            label       = "Net Rate PTC Loss (%)"
            placeholder = "0"
            background-color  ="numeric"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- End dialog risk -->

  <!-- Start dialog benefit -->
  <v-dialog v-model="genpolicy.dialog.benefit" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="genpolicy.dialog.benefit = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Benefit</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.valuedialog"></v-progress-linear>
        <v-spacer></v-spacer>
        <!-- Menu Table Risk -->
        <v-col cols="12" sm="12" md="12">
          <v-btn :color="benefit.table.selected.length === benefit.table.items.length ? 'fourth':''" @click="SelectAllMapValue" title="select all" class="mr-2" small>
            select all
          </v-btn>
          <v-btn @click="OpenBenefit" class="mr-2" title="select all" small>
            Add
          </v-btn>
          <v-btn color="error" title="delete selected" @click="Confirm('multideletemapfield','')" class="mr-2" v-if="benefit.table.selected.length > 0" :disabled="access.delete === 0 ? true:false" small>
            <v-icon>{{$vars.V('icon_delete')}}</v-icon>
          </v-btn>
          <v-btn color="primary" title="search" v-if="benefit.table.search_button === false" @click="SearchMapValue" small>
            <v-icon>{{$vars.V('icon_search')}}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="benefit.table.search" append-icon="search" @click:append="benefit.table.search_button = false" label="Type and Enter" single-line hide-values v-if="benefit.table.search_button" id="benefit.table.search" clearable @keyup.enter="ListMapValue(form.mapheader_id, mapdetail.form.input.fieldfrom, mapdetail.form.input.fieldto)"></v-text-field>
          <!-- Start Table Risk -->
          <v-data-table
            v-model="benefit.table.selected"
            :headers="benefit.table.data"
            :items="benefit.table.items"
            class="elevation-1"
            item-key="benefit_id"
            show-select
             :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right'
            }"
          >
            <template v-slot:top>
              <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
            </template>
            <template v-slot:item.action="{ item }">
              <!-- <v-icon small class="mr-2" @click="EditValue(item.benefit_headerid, item.benefit_fieldfrom, item.benefit_fieldto, item.benefit_from, item.benefit_to)" :disabled = "access.edit === 0 ? true:false">
                edit
              </v-icon> -->
              <v-icon small @click="Confirm('deletebenefit',item.benefit_headerid, item.benefit_fieldfrom, item.benefit_fieldto, item.benefit_from, item.benefit_to)" :disabled="access.delete === 0 ? true:false">
                delete
              </v-icon>
            </template>
          </v-data-table>
          <!-- End Table Risk -->
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="benefit.dialog.form" transition="dialog-bottom-transition"  max-width="890">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="benefit.dialog.form = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Beneficiaries</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Map Detail`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Map Detail"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <!-- Field Profile ID -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <app-cb
              cb_type           = "policyholder"
              cb_url            = "apiListProfile"
              cb_url_parameter  = "profile_actived=Y"
              cb_title          = "Profile ID"
              cb_id             = "pp_policyholder_id"
              cb_desc           = "pp_policyholder_name"
              cb_rules          = "Please fill ID"
              cb_desc_readonly  = "readonly"
              cb_items_id       = "profile_id"
              cb_items_desc     = "profile_name"
              :cb_value_id		  = "form.pp_policyholder_id"
              :cb_value_desc		= "form.pp_policyholder_name"
            ></app-cb>
          </v-col>
          <!-- Field Address -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-textarea
              v-model    = "benefit.form.input.address"
              label      = "Address"
              hint       = "Address"
              rows      = "3"
            ></v-textarea>
          </v-col>
          <!-- Field Zip Code -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-text-field 
            v-model="benefit.form.zip_code"
            label       = "Zip Code"
            placeholder = "Zip Code"
            ></v-text-field>
          </v-col>
          <!-- Field Gender -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-select
              v-model     ="benefit.form.input.gender"
              :items      ="benefit.form.group.gender"
              label       ="Gender"
              item-value  ="id"
              item-text   ="text"
              return-object
              dense
            ></v-select>
          </v-col>
          <!-- Field Relation -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-select
              v-model     ="benefit.form.input.relation"
              :items      ="benefit.form.group.relation"
              label       ="Relation"
              item-value  ="id"
              item-text   ="text"
              return-object
              dense
            ></v-select>
          </v-col>
          <!-- Field Birth Date -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-dialog
              ref="dialog_benefit_birth_date"
              v-model="benefit.dialog.birth_date"
              :return-value.sync="benefit.form.input.birth_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="benefit.form.input.birth_date"
                  label="Birth Date"
                  prepend-inner-icon="event"
                  background-color ="date"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="benefit.form.input.birth_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="benefit.dialog.birth_date = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dialog_benefit_birth_date.save(benefit.form.input.birth_date)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <!-- Ujroh -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="benefit.form.input.reim"
            background-color="numeric"
            reverse
            label           = "Reim / Indem"
            placeholder     = "Reim / Indem"
            hint            = "(%)"
            ></v-text-field>
          </v-col>
          <!-- Field Remark -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-textarea
              v-model    = "benefit.form.input.address"
              label      = "Remark"
              hint       = "Remark"
              rows      = "3"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- End dialog benefit -->

  <!-- Start dialog deductible -->
  <v-dialog v-model="genpolicy.dialog.deductible" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="genpolicy.dialog.deductible = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Deductible</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Deductible`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Deductible"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.valuedialog"></v-progress-linear>
        <v-spacer></v-spacer>
        <!-- Menu Table Deductible -->
        <v-col cols="12" sm="12" md="12">
          <v-btn :color="deductible.table.selected.length === deductible.table.items.length ? 'fourth':''" @click="SelectAllMapValue" title="select all" class="mr-2" small>
            select all
          </v-btn>
          <v-btn @click="OpenDeductible" class="mr-2" title="select all" small>
            Add
          </v-btn>
          <v-btn color="error" title="delete selected" @click="Confirm('multideletemapfield','')" class="mr-2" v-if="deductible.table.selected.length > 0" :disabled="access.delete === 0 ? true:false" small>
            <v-icon>{{$vars.V('icon_delete')}}</v-icon>
          </v-btn>
          <v-btn color="primary" title="search" v-if="deductible.table.search_button === false" @click="SearchMapValue" small>
            <v-icon>{{$vars.V('icon_search')}}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="deductible.table.search" append-icon="search" @click:append="deductible.table.search_button = false" label="Type and Enter" single-line hide-values v-if="deductible.table.search_button" id="deductible.table.search" clearable @keyup.enter="ListMapValue(form.mapheader_id, mapdetail.form.input.fieldfrom, mapdetail.form.input.fieldto)"></v-text-field>
          <!-- Start Table Deductible -->
          <v-data-table
            v-model="deductible.table.selected"
            :headers="deductible.table.data"
            :items="deductible.table.items"
            class="elevation-1"
            item-key="deductible_id"
            show-select
             :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right'
            }"
          >
            <template v-slot:top>
              <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
            </template>
            <template v-slot:item.action="{ item }">
              <!-- <v-icon small class="mr-2" @click="EditValue(item.deductible_headerid, item.deductible_fieldfrom, item.deductible_fieldto, item.deductible_from, item.deductible_to)" :disabled = "access.edit === 0 ? true:false">
                edit
              </v-icon> -->
              <v-icon small @click="Confirm('deletedeductible',item.deductible_headerid, item.deductible_fieldfrom, item.deductible_fieldto, item.deductible_from, item.deductible_to)" :disabled="access.delete === 0 ? true:false">
                delete
              </v-icon>
            </template>
          </v-data-table>
          <!-- End Table Deductible -->
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="deductible.dialog.form" transition="dialog-bottom-transition"  max-width="890">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="deductible.dialog.form = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Deductible</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Deductible`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Deductible"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <!-- Field Deductible -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-text-field 
            v-model     = "deductible.form.deductible"
            label       = "Deductible"
            placeholder = "Deductible"
            ></v-text-field>
          </v-col>
          <!-- Field Selection -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-select
              v-model     ="deductible.form.input.selection"
              :items      ="deductible.form.group.selection"
              label       ="Selection"
              item-value  ="id"
              item-text   ="text"
              return-object
            ></v-select>
          </v-col>
          <!-- Field Risk -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-text-field 
            v-model     = "deductible.form.risk"
            label       = "Risk"
            placeholder = "0"
            ></v-text-field>
          </v-col>
          <!-- Field Year -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-text-field 
            v-model     = "deductible.form.year"
            label       = "Year #"
            placeholder = "0"
            background-color  ="numeric"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- End dialog deductible -->

  <!-- Start dialog wording -->
  <v-dialog v-model="genpolicy.dialog.wording" transition="dialog-bottom-transition" max-width="500">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="genpolicy.dialog.wording = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Policy Wording List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Map Detail`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Map Detail"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-text-field 
            v-model="form.pp_leader_policy_no"
            label       = "C/I No"
            placeholder = "C/I No"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-text-field 
            v-model="form.pp_leader_policy_no"
            label       = "Policy No"
            placeholder = "Policy No"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-text-field 
            v-model="form.pp_leader_policy_no"
            label       = "Insured"
            placeholder = "Insured"
            background-color="readonly"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- End dialog wording -->

  <!-- Start dialog clause -->
  <v-dialog v-model="genpolicy.dialog.clause" transition="dialog-bottom-transition" max-width="500">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="genpolicy.dialog.clause = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Banker's Clause</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Map Detail`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Map Detail"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <app-cb
              cb_type           = "banker"
              cb_url            = "apiListBanker"
              cb_url_parameter  = "profile_actived=Y"
              cb_title          = "Bank Name"
              cb_id             = "pp_banker_id"
              cb_desc           = "pp_banker_name"
              cb_rules          = "Please fill ID"
              cb_desc_readonly  = "readonly"
              cb_items_id       = "profile_id"
              cb_items_desc     = "profile_name"
            >
            </app-cb>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- End dialog clause -->

  <!-- Start dialog busines -->
  <v-dialog v-model="genpolicy.dialog.busines" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="genpolicy.dialog.busines = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Business Source</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Map Detail`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Map Detail"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.valuedialog"></v-progress-linear>
        <v-spacer></v-spacer>
        <!-- Menu Table Risk -->
        <v-col cols="12" sm="12" md="12">
          <v-btn :color="busines.table.selected.length === busines.table.items.length ? 'fourth':''" @click="SelectAllMapValue" title="select all" class="mr-2" small>
            select all
          </v-btn>
          <v-btn @click="OpenBusines" class="mr-2" title="select all" small>
            Add
          </v-btn>
          <v-btn color="error" title="delete selected" @click="Confirm('multideletemapfield','')" class="mr-2" v-if="busines.table.selected.length > 0" :disabled="access.delete === 0 ? true:false" small>
            <v-icon>{{$vars.V('icon_delete')}}</v-icon>
          </v-btn>
          <v-btn color="primary" title="search" v-if="busines.table.search_button === false" @click="SearchMapValue" small>
            <v-icon>{{$vars.V('icon_search')}}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="busines.table.search" append-icon="search" @click:append="busines.table.search_button = false" label="Type and Enter" single-line hide-values v-if="busines.table.search_button" id="busines.table.search" clearable @keyup.enter="ListMapValue(form.mapheader_id, mapdetail.form.input.fieldfrom, mapdetail.form.input.fieldto)"></v-text-field>
          <!-- Start Table Risk -->
          <v-data-table
            v-model="busines.table.selected"
            :headers="busines.table.data"
            :items="busines.table.items"
            class="elevation-1"
            item-key="busines_id"
            show-select
             :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right'
            }"
          >
            <template v-slot:top>
              <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
            </template>
            <template v-slot:item.action="{ item }">
              <!-- <v-icon small class="mr-2" @click="EditValue(item.busines_headerid, item.busines_fieldfrom, item.busines_fieldto, item.busines_from, item.busines_to)" :disabled = "access.edit === 0 ? true:false">
                edit
              </v-icon> -->
              <v-icon small @click="Confirm('deletebusines',item.busines_headerid, item.busines_fieldfrom, item.busines_fieldto, item.busines_from, item.busines_to)" :disabled="access.delete === 0 ? true:false">
                delete
              </v-icon>
            </template>
          </v-data-table>
          <!-- End Table Risk -->
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="busines.dialog.form" transition="dialog-bottom-transition"  max-width="890">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="busines.dialog.form = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Business Source</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Business Source`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Business Source"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <!-- Field Business Source ID -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-text-field 
            v-model     = "busines.form.id"
            label       = "Business Source ID"
            placeholder = "Business Source ID"
            ></v-text-field>
          </v-col>
          <!-- Field Business Source Name -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-text-field 
            v-model     = "busines.form.name"
            label       = "Business Source Name"
            placeholder = "0"
            ></v-text-field>
          </v-col>
          <!-- Field Business Source Type -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-select
              v-model     ="busines.form.input.type"
              :items      ="busines.form.group.type"
              label       ="Business Source Type"
              item-value  ="id"
              item-text   ="text"
              return-object
            ></v-select>
          </v-col>
          <!-- Field Fee | Discount | Up Fee -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-text-field 
            v-model     = "busines.form.year"
            label       = "Fee | Discount | Up Fee (%)"
            placeholder = "0"
            background-color  ="numeric"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- End dialog busines -->

  <!-- Start dialog Payor Information -->
  <v-dialog v-model="genpolicy.dialog.payors" transition="dialog-bottom-transition" max-width="600">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="genpolicy.dialog.payors = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Payor Information</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Map Detail`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Map Detail"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <!-- Field Payor ID -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <app-cb
              cb_type           = "constructionclass"
              cb_url            = "apiListClass"
              cb_url_parameter  = "users_actived=Y"
              cb_title          = "Payor ID"
              cb_id             = "pp_class_id"
              cb_desc           = "pp_class_desc"
              cb_rules          = "Please fill ID"
              cb_desc_readonly  = "readonly"
              cb_items_id       = "users_id"
              cb_items_desc     = "users_name"
              :cb_value_id		  = "form.pp_class_id"
              :cb_value_desc		= "form.pp_class_desc"
            >
            </app-cb>
          </v-col>
          <!-- Field Address -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-text-field 
            v-model="payors.form.input.address"
            label       = "Address"
            placeholder = "Address"
            >
            </v-text-field>
          </v-col>
          <!-- Field Group Discount -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.group_dicount"
            background-color="numeric"
            reverse
            label           = "Group Discount"
            placeholder     = "Group Discount"
            hint            = "(%)"
            ></v-text-field>
          </v-col>
          <!-- Field No Claim Discount -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.group_dicount"
            background-color="numeric"
            reverse
            label           = "No Claim Discount"
            placeholder     = "No Claim Discount"
            hint            = "(%)"
            ></v-text-field>
          </v-col>
          <!-- Field Premium Discount -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.group_dicount"
            background-color="numeric"
            reverse
            label           = "Premium Discount"
            placeholder     = "Premium Discount"
            hint            = "(%)"
            ></v-text-field>
          </v-col>
          <!-- Field Discount in Amount -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.group_dicount"
            background-color="numeric"
            reverse
            label           = "Discoun in Amount"
            placeholder     = "Discoun in Amount"
            hint            = "(%)"
            ></v-text-field>
          </v-col>
          <!-- Field Surchage Discount -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.group_dicount"
            background-color="numeric"
            reverse
            label           = "Surchage Discount"
            placeholder     = "Surchage Discount"
            hint            = "(%)"
            ></v-text-field>
          </v-col>
          <!-- Field Payment Method -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-select
              v-model     ="payors.form.input.gender"
              :items      ="payors.form.group.gender"
              label       ="Payment Method"
              item-value  ="id"
              item-text   ="text"
              return-object
              dense
            ></v-select>
          </v-col>
          <!-- Field Default -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <label>Default :</label>
            <v-checkbox v-model="payors.form.input.default" :label="payors.form.input.default === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
          <!-- Field Discount -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <label>Discount :</label>
            <v-checkbox v-model="payors.form.input.discount" :label="payors.form.input.discount === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
          <!-- Field Allow Payor Due Greater Than Fac Due -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <label>Allow Payor Due Greater Than Fac Due :</label>
            <v-checkbox v-model="payors.form.input.allow" :label="payors.form.input.allow === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>

          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <div class="text-center">Premium Payment Procedure</div>
          </v-col>
          <!-- Grace Period -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.group_dicount"
            background-color="numeric"
            reverse
            label           = "Grace Period"
            placeholder     = "Grace Period"
            hint            = "In Day(s)"
            ></v-text-field>
          </v-col>
          <!-- Due Date -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-dialog
              ref                 = "dialog_due_date"
              v-model             = "modal.due_date"
              :return-value.sync  = "form.pp_due_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model             = "form.pp_due_date"
                  label               = "Due Date"
                  prepend-inner-icon  = "event"
                  background-color    = "date"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.pp_due_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.due_date = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dialog_due_date.save(form.pp_due_date)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <!-- WPC -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.group_dicount"
            background-color="numeric"
            reverse
            label           = "WPC"
            placeholder     = "WPC"
            hint            = "In Day(s)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="mb-n5"></v-col>
          <!-- Period Term -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <v-select
              v-model     ="payors.form.input.period_term"
              :items      ="payors.form.group.period_term"
              label       ="Period Term"
              item-value  ="id"
              item-text   ="text"
              return-object
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model="payors.form.input.Daily"
            background-color="readonly"
            label       = "Daily"
            placeholder = "Daily"
            readonly
            >
            </v-text-field>
          </v-col>
          <!-- Of Installment -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.group_dicount"
            background-color="numeric"
            reverse
            label           = "Of Installment"
            placeholder     = "Of Installment"
            hint            = "In Day(s)"
            ></v-text-field>
          </v-col>
          <!-- Modal Factor -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.group_dicount"
            background-color="numeric"
            reverse
            label           = "Modal Factor"
            placeholder     = "Modal Factor"
            hint            = "(%)"
            ></v-text-field>
          </v-col>
          <!-- 1st Installment Date -->
          <v-col cols="12" sm="6" md="12" class="mb-n5">
            <v-dialog
              ref                 = "dialog_installment_date"
              v-model             = "modal.installment_date"
              :return-value.sync  = "form.pp_installment_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model             = "form.pp_installment_date"
                  label               = "1st Installment Date"
                  prepend-inner-icon  = "event"
                  background-color    = "date"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.pp_installment_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.installment_date = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dialog_installment_date.save(form.pp_installment_date)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <!-- Field Billing by Policy Year -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <label>Billing by Policy Year :</label>
            <v-checkbox v-model="payors.form.input.policy_year" :label="payors.form.input.policy_year === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
          <!-- Field Installment Fee & Duty -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <label>Installment Fee & Duty :</label>
            <v-checkbox v-model="payors.form.input.installment_fee_duty" :label="payors.form.input.installment_fee_duty === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
          <!-- Field SI Schedule -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <label>SI Schedule :</label>
            <v-checkbox v-model="payors.form.input.si_schedule" :label="payors.form.input.si_schedule === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
          <!-- Field Billing Follow SI SChedule -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <label>Billing Follow SI SChedule :</label>
            <v-checkbox v-model="payors.form.input.billing_follow_si_schedule" :label="payors.form.input.billing_follow_si_schedule === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
          <!-- Interest Rate -->
          <v-col cols="12" sm="6" md="6" class="mb-n5">
            <v-text-field 
            v-model         ="payors.form.input.interest_rate"
            background-color="numeric"
            reverse
            label           = "Interest Rate"
            placeholder     = "Interest Rate"
            hint            = "(%)"
            ></v-text-field>
          </v-col>
          <!-- Field Decresing SI -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <label>Decresing SI :</label>
            <v-checkbox v-model="payors.form.input.decresing_si" :label="payors.form.input.decresing_si === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
          <!-- Field Decresing Premium -->
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <label>Decresing Premium :</label>
            <v-checkbox v-model="payors.form.input.decresing_premium" :label="payors.form.input.decresing_premium === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- End dialog Payor Information -->

  <!-- Start fee & duty -->
  <v-dialog v-model="genpolicy.dialog.feeduty" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="genpolicy.dialog.feeduty = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Fee & Duty</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.valuedialog"></v-progress-linear>
        <v-spacer></v-spacer>
        <!-- Menu Table Risk -->
        <v-col cols="12" sm="12" md="12">
          <v-btn :color="feeduty.table.selected.length === feeduty.table.items.length ? 'fourth':''" @click="SelectAllMapValue" title="select all" class="mr-2" small>
            select all
          </v-btn>
          <v-btn @click="OpenFeeduty()" class="mr-2" title="select all" small>
            Add
          </v-btn>
          <v-btn color="error" title="delete selected" @click="Confirm('multideletemapfield','')" class="mr-2" v-if="feeduty.table.selected.length > 0" :disabled="access.delete === 0 ? true:false" small>
            <v-icon>{{$vars.V('icon_delete')}}</v-icon>
          </v-btn>
          <v-btn color="primary" title="search" v-if="feeduty.table.search_button === false" @click="SearchMapValue" small>
            <v-icon>{{$vars.V('icon_search')}}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="feeduty.table.search" append-icon="search" @click:append="feeduty.table.search_button = false" label="Type and Enter" single-line hide-values v-if="feeduty.table.search_button" id="feeduty.table.search" clearable @keyup.enter="ListMapValue(form.mapheader_id, mapdetail.form.input.fieldfrom, mapdetail.form.input.fieldto)"></v-text-field>
          <!-- Start Table Risk -->
          <v-data-table
            v-model="feeduty.table.selected"
            :headers="feeduty.table.data"
            :items="feeduty.table.items"
            class="elevation-1"
            item-key="feeduty_id"
            show-select
             :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right'
            }"
          >
            <template v-slot:top>
              <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
            </template>
            <template v-slot:item.action="{ item }">
              <!-- <v-icon small class="mr-2" @click="EditValue(item.feeduty_headerid, item.feeduty_fieldfrom, item.feeduty_fieldto, item.feeduty_from, item.feeduty_to)" :disabled = "access.edit === 0 ? true:false">
                edit
              </v-icon> -->
              <v-icon small @click="Confirm('deletefeeduty',item.feeduty_headerid, item.feeduty_fieldfrom, item.feeduty_fieldto, item.feeduty_from, item.feeduty_to)" :disabled="access.delete === 0 ? true:false">
                delete
              </v-icon>
            </template>
          </v-data-table>
          <!-- End Table Risk -->
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="feeduty.dialog.form" transition="dialog-bottom-transition"  max-width="890">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="feeduty.dialog.form = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Fee and Duty</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddMapDetail" :title="`${$vars.V('txt_add')} Map Detail`"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type, form.mapheader_id )" title="Save Map Detail"><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <!-- Field Profile ID -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <app-cb
              cb_type           = "policyholder"
              cb_url            = "apiListProfile"
              cb_url_parameter  = "profile_actived=Y"
              cb_title          = "Profile ID"
              cb_id             = "pp_policyholder_id"
              cb_desc           = "pp_policyholder_name"
              cb_rules          = "Please fill ID"
              cb_desc_readonly  = "readonly"
              cb_items_id       = "profile_id"
              cb_items_desc     = "profile_name"
              :cb_value_id		  = "form.pp_policyholder_id"
              :cb_value_desc		= "form.pp_policyholder_name"
            ></app-cb>
          </v-col>
          <!-- Field Fee & Duty Code -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-text-field 
            v-model="feeduty.form.zip_code"
            background-color="combo"
            label       = "Fee & Duty Code"
            placeholder = "Fee & Duty Code"
            ></v-text-field>
          </v-col>
          <!-- Field Remarks -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-text-field 
            v-model="feeduty.form.input.remarks"
            label       = "Remarks"
            placeholder = "Remarks"
            ></v-text-field>
          </v-col>
          <!-- Field In Amount -->
          <v-col cols="12" sm="2" md="2" class="mb-n5">
            <v-text-field 
            v-model="feeduty.form.input.amount"
            background-color="combo"
            label       = "In Amount"
            placeholder = "Currency"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="mb-n5">
            <v-text-field 
            v-model="feeduty.form.input.amount"
            background-color="numeric"
            placeholder = "0.00"
            hint            = "(Nominal)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="mb-n5">
            <v-text-field 
            v-model="feeduty.form.input.amount"
            background-color="numeric"
            label = "or"
            placeholder = "0.00"
            hint            = "(%)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="mb-n5">
            <v-select
              v-model     ="feeduty.form.input.gender"
              :items      ="feeduty.form.group.amount"
              item-value  ="id"
              label = "Category"
              item-text   ="text"
              return-object
              dense
            ></v-select>
          </v-col>
          <!-- Field Year -->
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-select
              v-model     ="feeduty.form.input.year"
              :items      ="feeduty.form.group.year"
              label       ="Year"
              item-value  ="id"
              item-text   ="text"
              hint        ="(0 : All Coverage Period)"
              return-object
              dense
            ></v-select>
          </v-col>
          <!-- Field Contributed -->
          <v-col cols="12" sm="4" md="4">
            <label>Contributed :</label>
            <v-checkbox v-model="feeduty.form.input.contributed" :label="feeduty.form.input.contributed === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
          <!-- Field R/I Tabarru -->
          <v-col cols="12" sm="4" md="4">
            <label>R/I Tabarru :</label>
            <v-checkbox v-model="feeduty.form.input.tabarru" :label="feeduty.form.input.tabarru === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
          <!-- Field AutoGenerated -->
          <v-col cols="12" sm="4" md="4">
            <label>AutoGenerated :</label>
            <v-checkbox v-model="feeduty.form.input.autogenerated" :label="feeduty.form.input.autogenerated === true ? 'Yes' : '' ">
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- End fee & duty -->

  <!-- Start Unkown -->
  <v-breadcrumbs :items="pp_breadcrumb">
    <template v-slot:divider >
      <v-icon>trip_origin</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item v-on:click="BreadcrumbURL(item.text)">{{item.text.toUpperCase() }}</v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
  
  <v-dialog v-model="dialog.policy" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Policy Processing</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-toolbar-items>
          <v-btn dark text @click="dialog = false">{{$vars.V('icon_save')}}</v-btn>
        </v-toolbar-items> -->
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader>This will be showing time now</v-subheader>
          <v-card class="policycard" v-if="widgets==false">
            <v-app-bar dark color="primary">
              <v-toolbar-title>Policy Processing Document Printing</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
            <!-- <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>by Kurt Wagner</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
            <div id="policycard">
              <v-row>
                <v-col cols="12" sm="5" md="5" class="tdleft">Campaign No : </v-col>
                <v-col cols="12" sm="7" md="7" class="tdright"><input type="text" v-model="form.pp_campaign_no" readonly ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="5" md="5" class="tdleft"> Quotation No : </v-col>
                <v-col cols="12" sm="7" md="7" class="tdright"><input type="text" v-model="form.pp_quotation_no" readonly ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="5" md="5" class="tdleft"><label for="documenttype">Document Type : </label></v-col>
                <v-col cols="12" sm="7" md="7" class="tdright"><select id="documenttype" class="textpolicycard" v-model="selected.documenttype">
                  <option>COMMISION NOTE</option>
                  <option>DOCUMENT RECEIPT</option>
                  <option>FACULTATIVE INSURANCE SLIP</option>
                  <option>KUITANSI POLIS</option>
                  <option>OVERRIDING DISCOUNT</option>
                  <option>POLICY SCHEDULE</option>
                  <option>PREMIUM NOTE</option>
                  <option>PRODUCTION CONTROL SHEET</option>
                  <option>RENEWAL NOTICE</option>
                </select>
                </v-col>
              </v-row>
                <!-- <v-btn
                      color		="primary"
                      class		="white--text mt-2"
                      rounded 
                      @click	="Print(form.pp_campaign_no, selected, 'pdf')"
                    >
                      <v-icon dark>print</v-icon>Print
                </v-btn> -->
                <button class="buttoncard" v-on:click ="Print(form.pp_campaign_no, selected.documenttype)">Print</button>
                <button class="buttoncard" v-on:click ="widgets=true">View Log</button>
            </div>
            <!-- <v-card-actions>
              <v-row class="mx-2">
                <v-col cols="12" sm="4" md="4">
                </v-col>
                <v-col cols="12" sm="4" md="4">
                </v-col>
              </v-row>
            </v-card-actions> -->
          </v-card>
            <v-card class="policycard1" v-else>
              <v-system-bar
                color="white"
              >
                <v-spacer></v-spacer>
                <v-card-subtitle v-on:click="widgets=false">Close<v-icon>{{$vars.V('icon_close')}}</v-icon></v-card-subtitle>
              </v-system-bar>
            <v-app-bar dark color="primary">
              <v-toolbar-title>Policy Schedule</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
            <!-- <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>by Kurt Wagner</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
            <div id="policycard1">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="tdleft"><label for="languange">Languange : </label></v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright">
                    <select id="languange" class="textpolicycard" v-model="selected.languange">
                    <option>English</option>
                    <option>Indonesia</option>
                    </select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="tdleft">Print Date : </v-col>
                  <v-col cols="12" sm="4" md="2" class="mb-n5 tdright">
                    <v-dialog
                      ref="dialog_leader_policy_date"
                      v-model="modal.leader_policy_date"
                      :return-value.sync="form.pp_leader_policy_date"
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.pp_leader_policy_date"
                          prepend-inner-icon="event"
                          background-color ="date"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="form.pp_leader_policy_date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.leader_policy_date = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialog_leader_policy_date.save(form.pp_leader_policy_date)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="tdleft">Options : </v-col>
                    <v-col cols="12" sm="6" md="6" class="tdright">
                      <input type="radio" id="one" class="ml-2 mr-1" value="Without Originality" v-model="selected.picked"><label for="one">Without Originality</label>
                      <input type="radio" id="two" class="ml-2 mr-1" value="Original" v-model="selected.picked"><label for="two">Original</label>
                      <input type="radio" id="three" class="ml-2 mr-1" value="Duplicate" v-model="selected.picked"><label for="three">Duplicate</label>
                      <input type="radio" id="Four" class="ml-2 mr-1" value="Triplecate" v-model="selected.picked"><label for="four">Triplecate</label>
                      <input type="radio" id="five" class="ml-2 mr-1" value="Copy" v-model="selected.picked"><label for="five">Copy</label>
                    </v-col>
                  <!-- <label class="container-checkbox">
                    <input type="checkbox" >
                    <span class="checkmark"></span>
                  </label> -->
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright">
                    <label class="containter-checkbox-policy mb-n2">
                      <input type="checkbox" id="Premium" value="Show Premium Content" v-model="checked.premium">
                      <label for="Premium">Show Premium Content</label>
                      <span class="checkmark-policy"></span>
                    </label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright">
                    <label class="containter-checkbox-policy mb-n2">
                      <input type="checkbox" id="Discount" value="Show Discount" v-model="checked.discount">
                      <label for="Discount">Show Discount</label>
                      <span class="checkmark-policy"></span>
                    </label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="tdleft"> Select item to Print :</v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright">
                    <label class="containter-checkbox-policy mb-n2">
                      <input type="checkbox" id="Attachment" value="Attachment" v-model="checked.attachment">
                      <label for="Attachment">Attachment</label>
                      <span class="checkmark-policy"></span>
                    </label>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright">
                    <label class="containter-checkbox-policy mb-n2">
                      <input type="checkbox" id="Selected Classess" value="Selected Classes" v-model="checked.selected">
                      <label for="Selected Classess">Selected Classess</label>
                      <span class="checkmark-policy"></span>
                    </label>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright">
                    <label class="containter-checkbox-policy mb-n2">
                      <input type="checkbox" id="Deferred Premium Clause" value="Deferred Premium Clause" v-model="checked.deferred">
                      <label for="Deferred Premium Clause">Deferred Premium Clause</label>
                      <span class="checkmark-policy"></span>
                    </label>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright">
                    <label class="containter-checkbox-policy mb-n2">
                      <input type="checkbox" id="PA Card" value="PA Card" v-model="checked.pa">
                      <label for="PA Card">PA Card</label>
                      <span class="checkmark-policy"></span>
                    </label>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright">
                    <label class="containter-checkbox-policy">
                      <input type="checkbox" id="With Header And Footer" value="With Header And Footer" v-model="checked.headerfooter">
                      <label for="With Header And Footer">With Header And Footer</label>
                      <span class="checkmark-policy"></span>
                    </label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="tdleft"><label for="layout">Layout : </label></v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright"><select id="layout" class="textpolicycard" v-model="selected.layout">
                    <option disabled value="">Default</option>
                    <option>Point</option>
                  </select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="tdleft"><label for="layoutAttachment">Layout Attachment : </label></v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright"><select id="layoutAttachment" class="textpolicycard" v-model="selected.layoutAttachment">
                  <option disabled value="">Default</option>
                  <option>Point</option>
                  </select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="tdleft mt-n2">
                    <button class="buttoncard" v-on:click ="Export(form.pp_campaign_no)">Export</button>
                    <button class="buttoncard" v-on:click ="Print(form.pp_campaign_no, selected.documenttype, 'pdf')">Print</button>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="tdright mt-n2">
                    <button class="buttoncard" v-on:click ="Preview(form.pp_campaign_no, selected.documenttype)">Preview Only</button>
                    <button class="buttoncard" v-on:click ="Print(form.pp_campaign_no, selected.documenttype, 'pdf')">Preview WithPrint</button>
                  </v-col>
                </v-row>
                <!-- <v-btn
                      color		="primary"
                      class		="white--text mt-2"
                      rounded 
                      @click	="Print(form.pp_campaign_no, selected.documenttype, 'pdf')"
                    >
                      <v-icon dark>print</v-icon>Print
                </v-btn>
                <v-btn
                      color		="primary"
                      class		="white--text mt-2"
                      rounded 
                      @click	="Print(form.pp_campaign_no, selected.documenttype, 'word')"
                    >
                      <v-icon dark>print</v-icon>Word
                </v-btn> -->
            </div>
            </v-card>
      </v-list>
      <v-row class="mx-2" v-show="selected.iframe">
        <!-- <v-slider
          v-model="slider"
          prepend-icon="mdi-pan-horizontal"
          min="0"
          max="1000"
          thumb-label
        ></v-slider> -->
        <v-col cols="12" sm="12" md="12">
          <iframe :src="selected.iframeURL" frameborder="1" width="100%" height="1000px" id="PDFPreview">
          </iframe>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>

  <!-- Dialog Policy Risk -->
  <v-dialog v-model="dialog.policyrisk_index" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="dialog.policyrisk_index = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Risk</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- Data Table Policy Risk -->
        <template>
          <!-- Main Button Policy Risk -->
          <v-card-title class="ml-n4">
            <v-btn
              :color  ="policyrisk.selected.length === policyrisk.items.length ? 'fourth' : ''"
              @click  ="SelectAllPolicyRisk"
              class   ="mr-2"
              title   ="select all"
              small
            >
              select all
            </v-btn>
            <v-btn
              @click  ="OpenPolicyRisk"
              class   ="mr-2"
              title   ="add"
              small
            >
              Add
            </v-btn>
            <v-btn
              color     ="error"
              title     ="delete selected"
              @click    ="Confirm('multideletepolicyrisk', '')"
              class     ="mr-2"
              v-if      ="policyrisk.selected.length > 0"
              :disabled ="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color   ="primary"
              title   ="search"
              v-if    ="policyrisk.searchbutton === false"
              @click  ="SearchPolicyRisk"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model       ="policyrisk.search"
              append-icon   ="search"
              @click:append ="policyrisk.searchbutton = false"
              label         ="Type and Enter"
              single-line
              hide-details
              v-if          ="policyrisk.searchbutton"
              id            ="searchpolicyrisk"
              clearable
              @keyup.enter  ="ListPolicyRisk(form.campaign_id)"
            ></v-text-field>
          </v-card-title>
          <!-- end: Main Button Policy Risk  -->
          <v-spacer></v-spacer>

          <v-data-table
            :value        ="policyrisk.selected"
            :headers      ="policyrisk.headers"
            :items        ="ItemsPolicyRisk"
            :search       ="policyrisk.search"
            @input        ="CheckPolicyRisk($event)"
            item-key      ="policyrisk_row"
            show-select
            class="elevation-1"
            :footer-props ="{
              showFirstLastPage : true,
              firstIcon         : 'first_page',
              lastIcon          : 'last_page',
              prevIcon          : 'keyboard_arrow_left',
              nextIcon          : 'keyboard_arrow_right',
            }"
            dense
            :loading      ="loading.listpolicyrisk"
            loading-text  ="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class     ="mr-2"
                @click    ="EditPolicyRisk(item.policyrisk_policyrow, item.policyrisk_riskcovid)"
                :disabled ="access.edit === 0 ? true : false"
                title     ="edit"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click    ="Confirm('deletepolicyrisk', item.policyrisk_policyrow, item.policyrisk_riskcovid)"
                :disabled ="access.delete === 0 ? true : false"
                title     ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
        <!-- end: Data Table Policy Risk -->
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model     ="dialog.policyrisk"
    scrollable
    transition  ="dialog-bottom-transition"
    persistent
    max-width   ="1024"
  >
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('policyrisk')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Policy Risk</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddPolicyRisk" :title="`${$vars.V('txt_add')} Detail`">
          <v-icon color="white">add</v-icon>
        </v-btn>
        <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail">
          <v-icon color="white">{{$vars.V('icon_save')}}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear
          :indeterminate  ="true"
          v-if            ="loading.policyriskdialog"
        ></v-progress-linear>
        <v-form
          enctype ="multipart/form-data"
          ref     ="form_policyrisk"
          lazy-validation
        >
          <v-row class="mx-2 mt-2">
            <!-- Riskcov -->
            <v-col cols="12" sm="6" md="6">
              <app-cb
                cb_type           ="riskcov"
                cb_url            ="apiListRiskcov"
                cb_url_parameter  ="riskcov_actived=Y"
                cb_title          ="Riskcov"
                cb_id             ="policyrisk_riskcovid"
                cb_desc           ="policyrisk_riskcovdesc"
                cb_rules          ="Please fill Riskcov"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="riskcov_id"
                cb_items_desc     ="riskcov_desc"
                :cb_value_id	  	="form.policyrisk_riskcovid"
                :cb_value_desc		="form.policyrisk_riskcovdesc"
              >
              </app-cb>
            </v-col>

            <!-- Description -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-text-field
                v-model       ="form.policyrisk_desc"
                required
                :rules        ="form.policyrisk_desc_rules"
                :maxlength    ="maxinput.desc"
                label         ="Description *"
                placeholder   ="Description"
                id            ="policyrisk_desc"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Rate Discount -->
            <v-col cols="12" sm="6" md="6">
              <app-cb
                cb_type               ="ratediscount"
                cb_url                ="apiListRateDiscount"
                cb_url_parameter      ="ratediscount_actived=Y"
                cb_title              ="Rate Discount"
                cb_id                 ="policyrisk_ratediscount"
                cb_desc               ="policyrisk_ratediscountdesc"
                cb_rules              ="Please fill RateDiscount"
                cb_desc_readonly      ="readonly"
                cb_items_id           ="ratediscount_id"
                cb_items_desc         ="ratediscount_desc"
                :cb_value_id	  	    ="form.policyrisk_ratediscount"
                :cb_value_desc		    ="form.policyrisk_ratediscountdesc"
                cb_items_additional_1 ="ratediscount_pct"
                @clicked              ="GetRateDiscountPct"
              >
              </app-cb>
            </v-col>

            <!-- Rate Unit -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-select
                v-model       ="form.policyrisk_rateunit"
                required
                :rules 	  		="form.policyrisk_rateunit_rules"
                :items        ="policyrisk_rateunit"
                item-text     ="code_desc"
                item-value    ="code_id"
                label         ="Unit"
                placeholder   ="Unit"
                id 				    ="policyrisk_rateunit"
                @keyup.enter  ="Confirm('write','')"
              >
              </v-select>
            </v-col>

            <!-- Category -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-select
                v-model       ="form.policyrisk_category"
                required
                :rules 	  		="form.policyrisk_category_rules"
                :items        ="policyrisk_category"
                item-text     ="code_desc"
                item-value    ="code_id"
                label         ="Category"
                placeholder   ="Category"
                id 				    ="policyrisk_category"
                @keyup.enter  ="Confirm('write','')"
              >
              </v-select>
            </v-col>

            <!-- Rate -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.policyrisk_rate"
                ref 				      ="policyrisk_rate"
                label 				    ="Rate"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_policyrisk.rate"
                id 		      	    ="policyrisk_rate"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Rate Loading -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.policyrisk_rateloading"
                ref 				      ="policyrisk_rateloading"
                label 				    ="Rate Loading"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_policyrisk.rateloading"
                id 		      	    ="policyrisk_rateloading"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Passenger -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.policyrisk_passenger"
                ref 				      ="policyrisk_passenger"
                label 				    ="Passenger"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_policyrisk.passenger"
                id 		      	    ="policyrisk_passenger"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Rate Discount Pct-->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.policyrisk_ratediscountpct"
                ref 				      ="policyrisk_ratediscountpct"
                label 				    ="Rate Discount Pct"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_policyrisk.ratediscountpct"
                id 		      	    ="policyrisk_ratediscountpct"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Start Date -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-dialog
                ref="dialog_sdate"
                v-model="modal.sdate"
                :return-value.sync="form.policyrisk_sdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.policyrisk_sdate"
                    label="Start Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.policyrisk_sdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.sdate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_sdate.save(form.policyrisk_sdate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- End Date -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-dialog
                ref="dialog_edate"
                v-model="modal.edate"
                :return-value.sync="form.policyrisk_edate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.policyrisk_edate"
                    label="End Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.policyrisk_edate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.edate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_edate.save(form.policyrisk_edate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- Voyage Start Date -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-dialog
                ref="dialog_voyageestarrdate"
                v-model="modal.voyageestarrdate"
                :return-value.sync="form.policyrisk_voyageestarrdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.policyrisk_voyageestarrdate"
                    label="Voyage Start Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.policyrisk_voyageestarrdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.voyageestarrdate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_voyageestarrdate.save(form.policyrisk_voyageestarrdate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- Voyage End Date -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-dialog
                ref="dialog_voyageestdepdate"
                v-model="modal.voyageestdepdate"
                :return-value.sync="form.policyrisk_voyageestdepdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.policyrisk_voyageestdepdate"
                    label="Voyage End Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.policyrisk_voyageestdepdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.voyageestdepdate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_voyageestdepdate.save(form.policyrisk_voyageestdepdate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- Voyage Vessel -->
            <v-col cols="12" sm="12" md="12" class="mt-n3">
              <v-text-field
                v-model       ="form.policyrisk_voyagevessel"
                :maxlength    ="maxinput.policyrisk.vessel"
                label         ="Voyage Vessel"
                placeholder   ="Voyage Vessel"
                id            ="policyrisk_voyagevessel"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Voyage From -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-textarea
                v-model       ="form.policyrisk_voyagefrom"
                :maxlength    ="maxinput.policyrisk.voyagefrom"
                label         ="Voyage From"
                placeholder   ="Voyage From"
                id            ="policyrisk_voyagefrom"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-textarea>
            </v-col>

            <!-- Voyage To -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-textarea
                v-model       ="form.policyrisk_voyageto"
                :maxlength    ="maxinput.policyrisk.voyageto"
                label         ="Voyage To"
                placeholder   ="Voyage To"
                id            ="policyrisk_voyageto"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-textarea>
            </v-col>
          </v-row>

          <!-- Info Campaign Risk -->
          <!-- <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <div v-show="url_type !== 'addcampaignrisk' ? true : false">
                <v-checkbox
                  v-model="cont.campaignrisk"
                  label="Show Info"
                ></v-checkbox>
                <v-banner
                  v-model="cont.campaignrisk"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.campaignrisk_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignrisk_cuser)">{{form.campaignrisk_cuser}}</span>
Last Update Date : {{form.campaignrisk_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignrisk_luser)">{{form.campaignrisk_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row> -->
          <!-- end: Info Campaign Risk -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end: Dialog Policy Risk -->

  <!-- Dialog Policy Interest -->
  <v-dialog v-model="dialog.policyint_index" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="dialog.policyint_index = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Interest</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- Data Table Policy Interest -->
        <template>
          <!-- Main Button Policy Interest -->
          <v-card-title class="ml-n4">
            <v-btn
              :color="
                policyint.selected.length === policyint.items.length
                  ? 'fourth'
                  : ''
              "
              @click="SelectAllPolicyInt"
              class="mr-2"
              title="select all"
              small
            >
              select all
            </v-btn>
            <v-btn
              @click="OpenPolicyInt"
              class="mr-2"
              title="add"
              small
            >
              Add
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multideletepolicyint', '')"
              class="mr-2"
              v-if="policyint.selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="policyint.searchbutton === false"
              @click="SearchPolicyInt"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model       ="policyint.search"
              append-icon   ="search"
              @click:append ="policyint.searchbutton = false"
              label         ="Type and Enter"
              single-line
              hide-details
              v-if          ="policyint.searchbutton"
              id            ="searchpolicyint"
              clearable
              @keyup.enter="ListPolicyInt(form.campaign_id)"
            ></v-text-field>
          </v-card-title>
          <!-- end: Main Button Policy Interest  -->
          <v-spacer></v-spacer>

          <v-data-table
            :value        ="policyint.selected"
            :headers      ="policyint.headers"
            :items        ="ItemsPolicyInt"
            :search       ="policyint.search"
            @input        ="CheckPolicyInt($event)"
            item-key      ="policyint_row"
            show-select
            class         ="elevation-1"
            :footer-props ="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.listpolicyint"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class     ="mr-2"
                @click    ="EditPolicyInt(item.policyint_policyrow, item.policyint_id)"
                :disabled ="access.edit === 0 ? true : false"
                title     ="edit"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click    ="Confirm('deletepolicyint', item.policyint_policyrow, item.policyint_id)"
                :disabled ="access.delete === 0 ? true : false"
                title     ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
        <!-- end: Data Table Policy Interest -->
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="dialog.policyint"
    scrollable
    transition="dialog-bottom-transition"
    persistent
    max-width="850"
  >
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('policyint')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Policy Interest</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddPolicyInt" :title="`${$vars.V('txt_add')} Detail`"
          ><v-icon color="white">add</v-icon></v-btn
        >
        <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
          ><v-icon color="white">{{$vars.V('icon_save')}}</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-progress-linear
          :indeterminate="true"
          v-if="loading.policyintdialog"
        ></v-progress-linear>
        <v-form
          enctype="multipart/form-data"
          ref="form_policyint"
          lazy-validation
        >
          <v-row class="mx-2 mt-2">
            <v-col cols="12" sm="9" md="9" class="mt-n0">
              <app-cb
                cb_type           ="interest"
                cb_url            ="apiListInterest"
                cb_url_parameter  ="interest_actived=Y"
                cb_title          ="Insterest"
                cb_id             ="policyint_id"
                cb_desc           ="policyint_desc"
                cb_rules          ="Please fill Insterest"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="interest_id"
                cb_items_desc     ="interest_desc"
                :cb_value_id      ="form.policyint_id"
                :cb_value_desc		="form.policyint_desc"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <small>First Loss</small><br />
              <v-btn-toggle
                dense
                v-model="form.policyint_firstloss"
                rounded
              >
                <v-btn small> No </v-btn>
                <v-btn small> Yes </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="12" sm="9" md="9" class="mt-n0">
              <app-cb
                cb_type           ="section"
                cb_url            ="apiListSection"
                cb_url_parameter  ="section_actived=Y"
                cb_title          ="Section"
                cb_id             ="policyint_sectionid"
                cb_desc           ="policyint_sectiondesc"
                cb_rules          ="Please fill Section"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="section_id"
                cb_items_desc     ="section_desc"
                :cb_value_id      ="form.policyint_sectionid"
                :cb_value_desc		="form.policyint_sectiondesc"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <small>Adjustable</small><br />
              <v-btn-toggle
                dense
                v-model="form.policyint_adjustable"
                rounded
              >
                <v-btn small> No </v-btn>
                <v-btn small> Yes </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n0">
              <app-cb
                cb_type           ="currency"
                cb_url            ="apiListCurrency"
                cb_url_parameter  ="currency_actived=Y"
                cb_title          ="Currency"
                cb_id             ="policyint_currency"
                cb_desc           ="policyint_currency_desc"
                cb_rules          ="Please fill Currency"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="currency_id"
                cb_items_desc     ="currency_desc"
                :cb_value_id      ="form.policyint_currency"
                cb_single         ="Y"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyint_limitofliability"
                ref               ="policyint_limitofliability"
                label             ="Limit Of Liability"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policyint.limitofliability"
                id                ="policyint_limitofliability"
                reverse
                @change           ="LOLOnType()"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyint_tsi"
                ref               ="policyint_tsi"
                label             ="TSI"
                placeholder       ="Rp 0"
                background-color  ="numeric"
                v-money           ="format_policyint.tsi"
                id                ="policyint_tsi"
                reverse
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyint_aggregate"
                ref               ="policyint_aggregate"
                label             ="Aggregate"
                placeholder       ="Rp 0"
                background-color  ="numeric"
                v-money           ="format_policyint.aggregate"
                id                ="policyint_aggregate"
                reverse
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n0">
              <app-cb
                cb_type           ="currency"
                cb_url            ="apiListCurrency"
                cb_url_parameter  ="currency_actived=Y"
                cb_title          ="Currency OC"
                cb_id             ="policyint_currencyoc"
                cb_desc           ="policyint_currencyoc_desc"
                cb_rules          ="Please fill Currency OC"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="currency_id"
                cb_items_desc     ="currency_desc"
                :cb_value_id      ="form.policyint_currencyoc"
                cb_single         ="Y"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyint_limitofliabilityoc"
                ref               ="policyint_limitofliabilityoc"
                label             ="Limit Of Liability OC"
                placeholder       ="Rp 0"
                background-color  ="numeric"
                v-money           ="format_policyint.limitofliabilityoc"
                id                ="policyint_limitofliabilityoc"
                reverse
                @change           ="LOLOCOnType()"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyint_tsioc"
                ref               ="policyint_tsioc"
                label             ="TSI OC"
                placeholder       ="Rp 0"
                background-color  ="numeric"
                v-money           ="format_policyint.tsioc"
                id                ="policyint_tsioc"
                reverse
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyint_aggregateoc"
                ref               ="policyint_aggregateoc"
                label             ="Aggregate OC"
                placeholder       ="Rp 0"
                background-color  ="numeric"
                v-money           ="format_policyint.aggregateoc"
                id                ="policyint_aggregateoc"
                reverse
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n5">
              <v-combobox
                v-model       ="form.policyint_category"
                required
                :rules        ="form.policyint_category_rules"
                :items        ="policyint_category"
                label         ="Category"
                placeholder   ="Category"
                id            ="policyint_category"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6" md="6" class="mt-n5">
              <v-text-field
                v-model       ="form.policyint_remarks"
                required
                :rules        ="form.policyint_remarks_rules"
                :maxlength    ="maxinput.policyint.remarks"
                label         ="Remarks *"
                placeholder   ="Remarks"
                id            ="policyint_remarks"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="mt-n5">
              <v-text-field
                v-model.lazy      ="form.policyint_adjustablerate"
                ref               ="policyint_adjustablerate"
                label             ="Adjustable Rate"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policyint.adjustablerate"
                id                ="policyint_adjustablerate"
                reverse
                :disabled         ="this.form.policyint_adjustable !== 1 ? true : false"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <!-- Info Campaign Risk -->
          <!-- <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <div v-show="url_type !== 'addcampaignrisk' ? true : false">
                <v-checkbox
                  v-model="cont.campaignrisk"
                  label="Show Info"
                ></v-checkbox>
                <v-banner
                  v-model="cont.campaignrisk"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.campaignrisk_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignrisk_cuser)">{{form.campaignrisk_cuser}}</span>
Last Update Date : {{form.campaignrisk_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignrisk_luser)">{{form.campaignrisk_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row> -->
          <!-- end: Info Campaign Risk -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end: Dialog Policy Interest -->

  <!-- Dialog Policy Deductible -->
  <v-dialog v-model="dialog.policyded_index" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="dialog.policyded_index = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Deductible</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- Data Table Policy Deductible -->
        <template>
          <!-- Main Button Policy Deductible -->
          <v-card-title class="ml-n4">
            <v-btn
              :color                    ="
              policyded.selected.length === policyded.items.length ? 'fourth' : ''"
              @click                    ="SelectAllPolicyDed"
              class                     ="mr-2"
              title                     ="select all"
              small
            >
              select all
            </v-btn>
            <v-btn
              @click  ="OpenPolicyDed"
              class   ="mr-2"
              title   ="add"
              small
            >
              Add
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multideletepolicyded', '')"
              class="mr-2"
              v-if="policyded.selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="policyded.searchbutton === false"
              @click="SearchPolicyDed"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model       ="policyded.search"
              append-icon   ="search"
              @click:append ="policyded.searchbutton = false"
              label         ="Type and Enter"
              single-line
              hide-details
              v-if          ="policyded.searchbutton"
              id            ="searchpolicyded"
              clearable
              @keyup.enter="ListPolicyDed(form.campaign_id)"
            ></v-text-field>
          </v-card-title>
          <!-- end: Main Button Policy Deductible  -->
          <v-spacer></v-spacer>

          <v-data-table
            :value        ="policyded.selected"
            :headers      ="policyded.headers"
            :items        ="ItemsPolicyDed"
            :search       ="policyded.search"
            @input        ="CheckPolicyDed($event)"
            item-key      ="policyded_row"
            show-select
            class         ="elevation-1"
            :footer-props ="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.listpolicyded"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class     ="mr-2"
                @click    ="EditPolicyDed(item.policyded_policyrow, item.policyded_id)"
                :disabled ="access.edit === 0 ? true : false"
                title     ="edit"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click    ="Confirm('deletepolicyded', item.policyded_policyrow, item.policyded_id)"
                :disabled ="access.delete === 0 ? true : false"
                title     ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
        <!-- end: Data Table Policy Deductible -->
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model     ="dialog.policyded"
    scrollable
    transition  ="dialog-bottom-transition"
    persistent
    max-width   ="850"
  >
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('policyded')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Policy Deductible</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddPolicyDed" :title="`${$vars.V('txt_add')} Detail`">
          <v-icon color="white">add</v-icon>
        </v-btn>
        <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail">
          <v-icon color="white">{{$vars.V('icon_save')}}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear
          :indeterminate="true"
          v-if="loading.policydeddialog"
        ></v-progress-linear>
        <v-form
          enctype="multipart/form-data"
          ref="form_policyded"
          lazy-validation
        >
          <v-row class="mx-2 mt-2">
            <!-- Deductible -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <app-cb
                cb_type           ="deductible"
                cb_url            ="apiListDeductible"
                cb_url_parameter  ="deductible_actived=Y"
                cb_title          ="Deductible"
                cb_id             ="policyded_id"
                cb_desc           ="policyded_ccy"
                cb_rules          ="Please fill Deductible"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="deductible_id"
                cb_items_desc     ="deductible_ccy"
                :cb_value_id      ="form.policyded_id"
                :cb_value_desc    ="form.policyded_ccy"
                cb_single         ="Y"
                @clicked          ="GetDeductible"
              >
              </app-cb>
            </v-col>

            <!-- CCY -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <app-cb
                cb_type           ="currency"
                cb_url            ="apiListCurrency"
                cb_url_parameter  ="currency_actived=Y"
                cb_title          ="In CCY"
                cb_id             ="policyded_ccy"
                cb_desc           ="policyded_ccydesc"
                cb_rules          =""
                cb_desc_readonly  ="readonly"
                cb_items_id       ="currency_id"
                cb_items_desc     ="currency_desc"
                :cb_value_id      ="form.policyded_ccy"
                :cb_value_desc    ="form.policyded_ccydesc"
                cb_single         ="Y"
              >
              </app-cb>
            </v-col>

            <!-- For Indemnity -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <app-cb
                cb_type           ="indemnity"
                cb_url            ="apiListIndemnity"
                cb_url_parameter  ="indemnity_actived=Y"
                cb_title          ="For Indemnity"
                cb_id             ="policyded_forindemnity"
                cb_desc           ="policyded_forindemnitydesc"
                cb_rules          =""
                cb_desc_readonly  ="readonly"
                cb_items_id       ="indemnity_id"
                cb_items_desc     ="indemnity_desc"
                :cb_value_id      ="form.policyded_forindemnity"
                :cb_value_desc    ="form.policyded_forindemnitydesc"
                cb_single         ="Y"
              >
              </app-cb>
            </v-col>

            <!-- TSI SI Pct -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyded_tsisipct"
                ref               ="policyded_tsisipct"
                label             ="TSI SI %"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policyded.tsisipct"
                id                ="policyded_tsisipct"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Claim Pct -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyded_claimpct"
                ref               ="policyded_claimpct"
                label             ="Claim %"
                placeholder       ="Rp 0"
                background-color  ="numeric"
                v-money           ="format_policyded.claimpct"
                id                ="policyded_claimpct"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Amount Minimum -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyded_amountmin"
                ref               ="policyded_amountmin"
                label             ="Amount Minimum"
                placeholder       ="Rp 0"
                background-color  ="numeric"
                v-money           ="format_policyded.amountmin"
                id                ="policyded_amountmin"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Amount Maximum -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyded_amountmax"
                ref               ="policyded_amountmax"
                label             ="Amount Maximum"
                placeholder       ="Rp 0"
                background-color  ="numeric"
                v-money           ="format_policyded.amountmax"
                id                ="policyded_amountmax"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Total Loss Flag -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <small>Loss Flag</small><br />
              <v-btn-toggle
                dense
                v-model ="form.policyded_totallossflag"
                rounded
              >
                <v-btn small> No </v-btn>
                <v-btn small> Yes </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- With Flag -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <small>With Flag</small><br />
              <v-btn-toggle
                dense
                v-model ="form.policyded_withflag"
                rounded
              >
                <v-btn small> No </v-btn>
                <v-btn small> Yes </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- TSI SI Flag -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <small>With Flag</small><br />
              <v-btn-toggle
                dense
                v-model ="form.policyded_tsisiflag"
                rounded
              >
                <v-btn small> SI </v-btn>
                <v-btn small> TSI </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- Actived -->
            <v-col cols="4" sm="3" md="3" class="mt-n3">
              <label class="caption">Active Status</label>
              <v-switch
                v-model ="form.policyded_actived"
                color   ="primary"
                id      ="form.policyded_actived"
                :label  ="form.policyded_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>

            <!-- Remarks Eng -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-textarea
                v-model       ="form.policyded_remarkseng"
                required
                :rules        ="form.policyded_remarkseng_rules"
                :maxlength    ="maxinput.desc"
                label         ="Remarks Eng *"
                placeholder   ="Remarks Eng"
                id            ="policyded_remarkseng"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-textarea>
            </v-col>

            <!-- Remarks Loc -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-textarea
                v-model       ="form.policyded_remarksind"
                required
                :rules        ="form.policyded_remarksind_rules"
                :maxlength    ="maxinput.desc"
                label         ="Remarks Loc *"
                placeholder   ="Remarks Loc"
                id            ="policyded_remarksind"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-textarea>
            </v-col>
          </v-row>

          <!-- Info Campaign Risk -->
          <!-- <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <div v-show="url_type !== 'addcampaignrisk' ? true : false">
                <v-checkbox
                  v-model="cont.campaignrisk"
                  label="Show Info"
                ></v-checkbox>
                <v-banner
                  v-model="cont.campaignrisk"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.campaignrisk_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignrisk_cuser)">{{form.campaignrisk_cuser}}</span>
Last Update Date : {{form.campaignrisk_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignrisk_luser)">{{form.campaignrisk_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row> -->
          <!-- end: Info Campaign Risk -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end: Dialog Policy Deductible -->

  <!-- Dialog Policy Fee & Duty -->
  <v-dialog v-model="dialog.policyfeeduty_index" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="dialog.policyfeeduty_index = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Fee & Duty</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- Data Table Policy Fee & Duty -->
        <template>
          <!-- Main Button Policy Fee & Duty -->
          <v-card-title class="ml-n4">
            <v-btn
              :color                        ="
              policyfeeduty.selected.length === policyfeeduty.items.length ? 'fourth' : ''"
              @click                        ="SelectAllPolicyFeeDuty"
              class                         ="mr-2"
              title                         ="select all"
              small
            >
              select all
            </v-btn>
            <v-btn
              @click  ="OpenPolicyFeeDuty"
              class   ="mr-2"
              title   ="add"
              small
            >
              Add
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multideletepolicyfeeduty', '')"
              class="mr-2"
              v-if="policyfeeduty.selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="policyfeeduty.searchbutton === false"
              @click="SearchPolicyFeeDuty"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model       ="policyfeeduty.search"
              append-icon   ="search"
              @click:append ="policyfeeduty.searchbutton = false"
              label         ="Type and Enter"
              single-line
              hide-details
              v-if          ="policyfeeduty.searchbutton"
              id            ="searchpolicyfeeduty"
              clearable
              @keyup.enter="ListPolicyFeeDuty(form.campaign_id)"
            ></v-text-field>
          </v-card-title>
          <!-- end: Main Button Policy Fee & Duty  -->
          <v-spacer></v-spacer>

          <v-data-table
            :value        ="policyfeeduty.selected"
            :headers      ="policyfeeduty.headers"
            :items        ="ItemsPolicyFeeDuty"
            :search       ="policyfeeduty.search"
            @input        ="CheckPolicyFeeDuty($event)"
            item-key      ="policyfeeduty_row"
            show-select
            class         ="elevation-1"
            :footer-props ="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.listpolicyfeeduty"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class     ="mr-2"
                @click    ="EditPolicyFeeDuty(item.policyfeeduty_policyrow, item.policyfeeduty_id)"
                :disabled ="access.edit === 0 ? true : false"
                title     ="edit"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click    ="Confirm('deletepolicyfeeduty', item.policyfeeduty_policyrow, item.policyfeeduty_id)"
                :disabled ="access.delete === 0 ? true : false"
                title     ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
        <!-- end: Data Table Policy Fee & Duty -->
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model     ="dialog.policyfeeduty"
    scrollable
    transition  ="dialog-bottom-transition"
    persistent
    max-width   ="950"
  >
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('policyfeeduty')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Policy Fee & Duty</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddPolicyFeeDuty" :title="`${$vars.V('txt_add')} Detail`">
          <v-icon color="white">add</v-icon>
        </v-btn>
        <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail">
          <v-icon color="white">{{$vars.V('icon_save')}}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear
          :indeterminate  ="true"
          v-if            ="loading.policyfeedutydialog"
        ></v-progress-linear>
        <v-form
          enctype ="multipart/form-data"
          ref     ="form_policyfeeduty"
          lazy-validation
        >
          <v-row class="mx-2 mt-2">
            <!-- Fee & Duty -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <app-cb
                cb_type           ="feeduty"
                cb_url            ="apiListFeeDuty"
                cb_url_parameter  ="feeduty_actived=Y"
                cb_title          ="Fee & Duty"
                cb_id             ="policyfeeduty_id"
                cb_desc           ="policyfeeduty_desc"
                cb_rules          ="Please fill Fee & Duty"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="feeduty_id"
                cb_items_desc     ="feeduty_desc"
                :cb_value_id      ="form.policyfeeduty_id"
                :cb_value_desc    ="form.policyfeeduty_desc"
              >
              </app-cb>
            </v-col>

            <!-- Payor ID -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <app-cb
                cb_type           ="profile"
                cb_url            ="apiListProfile"
                cb_url_parameter  ="profile_actived=Y"
                cb_title          ="Payor ID"
                cb_id             ="policyfeeduty_payorid"
                cb_desc           ="policyfeeduty_payor_desc"
                cb_rules          ="Please fill Payor ID"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="profile_id"
                cb_items_desc     ="profile_name"
                :cb_value_id      ="form.policyfeeduty_payorid"
                :cb_value_desc    ="form.policyfeeduty_payor_desc"
              >
              </app-cb>
            </v-col>

            <!-- Remarks -->
            <v-col cols="12" sm="8" md="8" class="mt-n3">
              <v-text-field
                v-model       ="form.policyfeeduty_remarks"
                required
                :rules        ="form.policyfeeduty_remarks_rules"
                :maxlength    ="maxinput.policyfeeduty.remarks"
                label         ="Remarks *"
                placeholder   ="Remarks"
                id            ="policyfeeduty_remarks"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Amount -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policyfeeduty_amount"
                ref               ="policyfeeduty_amount"
                label             ="Amount"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policyfeeduty.amount"
                id                ="policyfeeduty_amount"
                reverse
              >
              </v-text-field>
            </v-col>
          </v-row>

          <!-- Info Campaign Risk -->
          <!-- <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <div v-show="url_type !== 'addcampaignrisk' ? true : false">
                <v-checkbox
                  v-model="cont.campaignrisk"
                  label="Show Info"
                ></v-checkbox>
                <v-banner
                  v-model="cont.campaignrisk"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.campaignrisk_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignrisk_cuser)">{{form.campaignrisk_cuser}}</span>
Last Update Date : {{form.campaignrisk_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignrisk_luser)">{{form.campaignrisk_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row> -->
          <!-- end: Info Campaign Risk -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end: Dialog Policy Fee & Duty -->

  <!-- Dialog Policy Biz. Source -->
  <v-dialog v-model="dialog.policysource_index" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="dialog.policysource_index = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Biz. Source</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- Data Table Policy Biz. Source -->
        <template>
          <!-- Main Button Policy Biz. Source -->
          <v-card-title class="ml-n4">
            <v-btn
              :color                        ="
              policysource.selected.length === policysource.items.length ? 'fourth' : ''"
              @click                        ="SelectAllPolicySource"
              class                         ="mr-2"
              title                         ="select all"
              small
            >
              select all
            </v-btn>
            <v-btn
              @click  ="OpenPolicySource"
              class   ="mr-2"
              title   ="add"
              small
            >
              Add
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multideletepolicysource', '')"
              class="mr-2"
              v-if="policysource.selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="policysource.searchbutton === false"
              @click="SearchPolicySource"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model       ="policysource.search"
              append-icon   ="search"
              @click:append ="policysource.searchbutton = false"
              label         ="Type and Enter"
              single-line
              hide-details
              v-if          ="policysource.searchbutton"
              id            ="searchpolicysource"
              clearable
              @keyup.enter="ListPolicySource(form.policy_row)"
            ></v-text-field>
          </v-card-title>
          <!-- end: Main Button Policy Biz. Source  -->
          <v-spacer></v-spacer>

          <v-data-table
            :value        ="policysource.selected"
            :headers      ="policysource.headers"
            :items        ="ItemsPolicySource"
            :search       ="policysource.search"
            @input        ="CheckPolicySource($event)"
            item-key      ="policysource_row"
            show-select
            class         ="elevation-1"
            :footer-props ="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.listpolicysource"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class     ="mr-2"
                @click    ="EditPolicySource(item.policysource_policyrow, item.policysource_profileid, item.policysource_type)"
                :disabled ="access.edit === 0 ? true : false"
                title     ="edit"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click    ="Confirm('deletepolicysource', item.policysource_policyrow, item.policysource_profileid, item.policysource_type)"
                :disabled ="access.delete === 0 ? true : false"
                title     ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
        <!-- end: Data Table Policy Biz. Source -->
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model     ="dialog.policysource"
    scrollable
    transition  ="dialog-bottom-transition"
    persistent
    max-width   ="950"
  >
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('policysource')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Policy Biz. Source</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddPolicySource" :title="`${$vars.V('txt_add')} Detail`">
          <v-icon color="white">add</v-icon>
        </v-btn>
        <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail">
          <v-icon color="white">{{$vars.V('icon_save')}}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear
          :indeterminate  ="true"
          v-if            ="loading.policysourcedialog"
        ></v-progress-linear>
        <v-form
          enctype ="multipart/form-data"
          ref     ="form_policysource"
          lazy-validation
        >
          <v-row class="mx-2 mt-2">
            <!-- Profile ID -->
            <v-col cols="12" sm="9" md="9" class="mt-n0">
              <app-cb
                cb_type               ="profile"
                cb_url                ="apiListProfile"
                cb_url_parameter      ="profile_actived=Y"
                cb_title              ="Biz. Source"
                cb_id                 ="policysource_profileid"
                cb_desc               ="policysource_profile_desc"
                cb_rules              ="Please fill Biz Source"
                cb_desc_readonly      ="readonly"
                cb_items_id           ="profile_id"
                cb_items_desc         ="profile_name"
                cb_items_additional_1 ="profile_tax_pct"
                cb_items_additional_2 ="profile_vat_pct"
                :cb_value_id          ="form.policysource_profileid"
                :cb_value_desc        ="form.policysource_profile_desc"
                @clicked              ="GetBizSource"
              >
              </app-cb>
            </v-col>

            <!-- Type -->
            <v-col cols="12" sm="3" md="3" class="mt-n3">
              <v-combobox
                v-model       ="form.policysource_type"
                required
                :rules        ="form.policysource_type_rules"
                :items        ="policysource_type"
                label         ="Type"
                placeholder   ="Type"
                id            ="policysource_type"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-combobox>
            </v-col>

            <!-- Main Flag -->
            <v-col cols="12" sm="3" md="3" class="mt-n5">
              <small>Main Flag</small><br />
              <v-btn-toggle
                dense
                v-model ="form.policysource_mainflag"
                rounded
              >
                <v-btn small> No </v-btn>
                <v-btn small> Yes </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- Net After Member Flag -->
            <v-col cols="12" sm="3" md="3" class="mt-n5">
              <small>Net After Member Flag</small><br />
              <v-btn-toggle
                dense
                v-model="form.policysource_netaftermemberflag"
                rounded
              >
                <v-btn small> No </v-btn>
                <v-btn small> Yes </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- Gross Commision Flag -->
            <v-col cols="12" sm="3" md="3" class="mt-n5">
              <small>Gross Commision Flag</small><br />
              <v-btn-toggle
                dense
                v-model="form.policysource_grosscommflag"
                rounded
              >
                <v-btn small> No </v-btn>
                <v-btn small> Yes </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- Member Fee Flag -->
            <v-col cols="12" sm="3" md="3" class="mt-n5">
              <small>Member Fee Flag</small><br />
              <v-btn-toggle
                dense
                v-model="form.policysource_memberfeeflag"
                rounded
              >
                <v-btn small> No </v-btn>
                <v-btn small> Yes </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- Fee Pct -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model.lazy      ="form.policysource_feepct"
                ref               ="policysource_feepct"
                label             ="Fee Percent"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policysource.feepct"
                id                ="policysource_feepct"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Tax -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model.lazy      ="form.policysource_tax"
                ref               ="policysource_tax"
                label             ="Tax"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policysource.tax"
                id                ="policysource_tax"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Vat -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model.lazy      ="form.policysource_vat"
                ref               ="policysource_vat"
                label             ="Vat"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policysource.vat"
                id                ="policysource_vat"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Discount -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model.lazy      ="form.policysource_discount"
                ref               ="policysource_discount"
                label             ="Discount"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policysource.discount"
                id                ="policysource_discount"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Fee Amt -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model.lazy      ="form.policysource_feeamt"
                ref               ="policysource_feeamt"
                label             ="Fee Amount"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policysource.feeamt"
                id                ="policysource_feeamt"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Add Fee Pct -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model.lazy      ="form.policysource_addfeepct"
                ref               ="policysource_addfeepct"
                label             ="Fee Amount"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policysource.addfeepct"
                id                ="policysource_addfeepct"
                reverse
              >
              </v-text-field>
            </v-col>
          </v-row>

          <!-- Info Campaign Risk -->
          <!-- <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <div v-show="url_type !== 'addcampaignrisk' ? true : false">
                <v-checkbox
                  v-model="cont.campaignrisk"
                  label="Show Info"
                ></v-checkbox>
                <v-banner
                  v-model="cont.campaignrisk"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.campaignrisk_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignrisk_cuser)">{{form.campaignrisk_cuser}}</span>
Last Update Date : {{form.campaignrisk_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignrisk_luser)">{{form.campaignrisk_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row> -->
          <!-- end: Info Campaign Risk -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end: Dialog Policy Biz. Source -->

  <!-- Dialog Policy Term & Condition -->
  <v-dialog v-model="dialog.policytc_index" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="dialog.policytc_index = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Term & Condition</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- Data Table Policy Term & Condition -->
        <template>
          <!-- Main Button Policy Term & Condition -->
          <v-card-title class="ml-n4">
            <v-btn
              :color  ="policytc.selected.length === policytc.items.length ? 'fourth' : ''"
              @click  ="SelectAllPolicyTC"
              class   ="mr-2"
              title   ="select all"
              small
            >
              select all
            </v-btn>
            <v-btn
              @click  ="OpenPolicyTC"
              class   ="mr-2"
              title   ="add"
              small
            >
              Add
            </v-btn>
            <v-btn
              color     ="error"
              title     ="delete selected"
              @click    ="Confirm('multideletepolicytc', '')"
              class     ="mr-2"
              v-if      ="policytc.selected.length > 0"
              :disabled ="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="policytc.searchbutton === false"
              @click="SearchPolicyTC"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model       ="policytc.search"
              append-icon   ="search"
              @click:append ="policytc.searchbutton = false"
              label         ="Type and Enter"
              single-line
              hide-details
              v-if          ="policytc.searchbutton"
              id            ="searchpolicytc"
              clearable
              @keyup.enter="ListPolicyTC(form.policy_row)"
            ></v-text-field>
          </v-card-title>
          <!-- end: Main Button Policy Term & Condition  -->
          <v-spacer></v-spacer>

          <v-data-table
            :value        ="policytc.selected"
            :headers      ="policytc.headers"
            :items        ="ItemsPolicyTC"
            :search       ="policytc.search"
            @input        ="CheckPolicyTC($event)"
            item-key      ="policytc_row"
            show-select
            class         ="elevation-1"
            :footer-props ="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.listpolicytc"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class     ="mr-2"
                @click    ="EditPolicyTC(item.policytc_policyrow, item.policytc_id, item.policytc_classid)"
                :disabled ="access.edit === 0 ? true : false"
                title     ="edit"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click    ="Confirm('deletepolicytc', item.policytc_policyrow, item.policytc_id, item.policytc_classid)"
                :disabled ="access.delete === 0 ? true : false"
                title     ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
        <!-- end: Data Table Policy Term & Condition -->
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model     ="dialog.policytc"
    scrollable
    transition  ="dialog-bottom-transition"
    persistent
    max-width   ="950"
  >
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('policytc')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Policy Term & Condition</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddPolicyTC" :title="`${$vars.V('txt_add')} Detail`">
          <v-icon color="white">add</v-icon>
        </v-btn>
        <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail">
          <v-icon color="white">{{$vars.V('icon_save')}}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear
          :indeterminate  ="true"
          v-if            ="loading.policytcdialog"
        ></v-progress-linear>
        <v-form
          enctype ="multipart/form-data"
          ref     ="form_policytc"
          lazy-validation
        >
          <v-row class="mx-2 mt-2">
            <!-- Class TC -->
            <v-col cols="12" sm="12" md="12" class="mt-n3">
              <app-cb
                cb_type           ="classtc"
                cb_url            ="apiListClassTC"
                :cb_url_parameter ="`classtc_actived=Y&classtc_subclassid=${form.campaign_subclass}`"
                cb_title          ="Class TC"
                cb_id             ="policytc_id"
                cb_desc           ="policytc_classid"
                cb_rules          ="Please fill Class TC"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="classtc_id"
                cb_items_desc     ="classtc_classid"
                :cb_value_id      ="form.policytc_id"
                :cb_value_desc    ="form.policytc_classid"
                @clicked          ="GetTC"
              >
              </app-cb>
            </v-col>

            <!-- Short Desc Eng -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-text-field
                v-model       ="form.policytc_shortdesc_eng"
                required
                :rules        ="form.policytc_shortdesc_eng_rules"
                :maxlength    ="maxinput.tc.short_desc"
                label         ="Short Desc ENG *"
                placeholder   ="Short Desc ENG"
                id            ="policytc_shortdesc_eng"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Short Desc Ind -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-text-field
                v-model       ="form.policytc_shortdesc_ind"
                required
                :rules        ="form.policytc_shortdesc_ind_rules"
                :maxlength    ="maxinput.tc.short_desc"
                label         ="Short Desc IND *"
                placeholder   ="Short Desc IND"
                id            ="policytc_shortdesc_ind"
                @keyup.enter  ="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Long Desc Eng -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <label class="caption">Long Desc ENG *</label>
              <quill-editor
                ref     ="policytc_longdesc_eng"
                v-model ="form.policytc_longdesc_eng"
              >
              </quill-editor>
            </v-col>

            <!-- Long Desc Ind -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <label class="caption">Long Desc IND *</label>
              <quill-editor
                ref     ="policytc_longdesc_ind"
                v-model ="form.policytc_longdesc_ind"
              >
              </quill-editor>
            </v-col>
          </v-row>

          <!-- Info Campaign Risk -->
          <!-- <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <div v-show="url_type !== 'addcampaignrisk' ? true : false">
                <v-checkbox
                  v-model="cont.campaignrisk"
                  label="Show Info"
                ></v-checkbox>
                <v-banner
                  v-model="cont.campaignrisk"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.campaignrisk_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignrisk_cuser)">{{form.campaignrisk_cuser}}</span>
Last Update Date : {{form.campaignrisk_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignrisk_luser)">{{form.campaignrisk_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row> -->
          <!-- end: Info Campaign Risk -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end: Dialog Policy Term & Condition -->

  <!-- Dialog Policy Depreciation -->
  <v-dialog v-model="dialog.policydepreciation_index" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="dialog.policydepreciation_index = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Depreciation</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- Data Table Policy Depreciation -->
        <template>
          <!-- Main Button Policy Depreciation -->
          <v-card-title class="ml-n4">
            <v-btn
              :color  ="policydepreciation.selected.length === policydepreciation.items.length ? 'fourth' : ''"
              @click  ="SelectAllPolicyDepreciation"
              class   ="mr-2"
              title   ="select all"
              small
            >
              select all
            </v-btn>
            <v-btn
              @click  ="OpenPolicyDepreciation"
              class   ="mr-2"
              title   ="add"
              small
            >
              Add
            </v-btn>
            <v-btn
              color     ="error"
              title     ="delete selected"
              @click    ="Confirm('multideletepolicydepreciation', '')"
              class     ="mr-2"
              v-if      ="policydepreciation.selected.length > 0"
              :disabled ="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="policydepreciation.searchbutton === false"
              @click="SearchPolicyDepreciation"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model       ="policydepreciation.search"
              append-icon   ="search"
              @click:append ="policydepreciation.searchbutton = false"
              label         ="Type and Enter"
              single-line
              hide-details
              v-if          ="policydepreciation.searchbutton"
              id            ="searchpolicydepreciation"
              clearable
              @keyup.enter="ListPolicyDepreciation(form.policy_row)"
            ></v-text-field>
          </v-card-title>
          <!-- end: Main Button Policy Depreciation  -->
          <v-spacer></v-spacer>

          <v-data-table
            :value        ="policydepreciation.selected"
            :headers      ="policydepreciation.headers"
            :items        ="ItemsPolicyDepreciation"
            :search       ="policydepreciation.search"
            @input        ="CheckPolicyDepreciation($event)"
            item-key      ="policydepreciation_row"
            show-select
            class         ="elevation-1"
            :footer-props ="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.listpolicydepreciation"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class     ="mr-2"
                @click    ="EditPolicyDepreciation(item.policydepreciation_policyrow, item.policydepreciation_yearnumber)"
                :disabled ="access.edit === 0 ? true : false"
                title     ="edit"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click    ="Confirm('deletepolicydepreciation', item.policydepreciation_policyrow, item.policydepreciation_yearnumber)"
                :disabled ="access.delete === 0 ? true : false"
                title     ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
        <!-- end: Data Table Policy Depreciation -->
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model     ="dialog.policydepreciation"
    scrollable
    transition  ="dialog-bottom-transition"
    persistent
    max-width   ="520"
  >
    <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('policydepreciation')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Policy Depreciation</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddPolicyDepreciation" :title="`${$vars.V('txt_add')} Detail`">
          <v-icon color="white">add</v-icon>
        </v-btn>
        <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail">
          <v-icon color="white">{{$vars.V('icon_save')}}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear
          :indeterminate  ="true"
          v-if            ="loading.policydepreciationdialog"
        ></v-progress-linear>
        <v-form
          enctype ="multipart/form-data"
          ref     ="form_policydepreciation"
          lazy-validation
        >
          <v-row class="mx-2 mt-2">
            <!-- Year Number -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policydepreciation_yearnumber"
                ref               ="policydepreciation_yearnumber"
                label             ="Year Number"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policydepreciation.yearnumber"
                id                ="policydepreciation_yearnumber"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Pct -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-text-field
                v-model.lazy      ="form.policydepreciation_pct"
                ref               ="policydepreciation_pct"
                label             ="Pct"
                placeholder       ="0"
                background-color  ="numeric"
                v-money           ="format_policydepreciation.pct"
                id                ="policydepreciation_pct"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Actived -->
            <v-col cols="4" sm="3" md="3" class="mt-n3">
              <label class="caption">Active Status</label>
              <v-switch
                v-model ="form.policydepreciation_actived"
                color   ="primary"
                id      ="form.policydepreciation_actived"
                :label  ="form.policydepreciation_actived !== true ? '' : 'Active'"
                class   ="mt-n1"
              ></v-switch>
            </v-col>
          </v-row>

          <!-- Info Campaign Risk -->
          <!-- <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <div v-show="url_type !== 'addcampaignrisk' ? true : false">
                <v-checkbox
                  v-model="cont.campaignrisk"
                  label="Show Info"
                ></v-checkbox>
                <v-banner
                  v-model="cont.campaignrisk"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.campaignrisk_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignrisk_cuser)">{{form.campaignrisk_cuser}}</span>
Last Update Date : {{form.campaignrisk_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignrisk_luser)">{{form.campaignrisk_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row> -->
          <!-- end: Info Campaign Risk -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end: Dialog Policy Depreciation -->

  <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !==''">
    <v-card>
      <v-toolbar dark color="fourth" dense>
      <v-btn icon dark @click="remarks.dialog =false">
        <v-icon>{{$vars.V('icon_close')}}</v-icon>
      </v-btn>
      <v-toolbar-title>Error Messages</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-2 ml-n2">
        <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
      </v-card-text>
    </v-card>
  </v-dialog>

    <v-snackbar
      v-model	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      color		  ="fourth"
      rounded	  ="pill"
      top
      style 	  ="z-index: 9999;"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog =true" small v-if="snackbar.color ==='error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog =false" small>Close</v-btn>
      </template>
    </v-snackbar>

  <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
    <v-card>
      <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            id        ="dialog" 
            color     ="success"
            :disabled ="confirm.text ==='Ok' ? false:true"
            @click    ="Loading">{{confirm.text}}
          </v-btn>
          <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

</div>


</template>
<script>
	export default {
    data: () => ({
      access: {
        read: 0,
        add: 0,
        edit: 0,
        delete: 0,
      },
      // ========================================================================
      // Start General Policy Information
      // ========================================================================
        gpioverlay: true,
        pp_la: 1,
        genpolicy: {
          table: {
            data: [
              { text: 'ID', value: 'genpolicy_id', sortable: false },
              { text: 'CI No', value: 'genpolicy_ci_no', sortable: false },
              { text: 'Policy No', value: 'policy_policy_no' },
              { text: 'Insurance Type', value: 'policy_policy_no' },
              { text: 'Policy No', value: 'policy_policy_no' },
              { text: 'Actions', value: 'action', sortable: false }
            ],
            items: [],
            single_select: false,
            selected: [],
            search: '',
            search_button: false,
          },
          form: {
            input: {
              from: [],
              to: [],
              type: [],
              defaultvalue: '',
              func: [],
              param_1: '',
              param_2: '',
              compulsory: false,
              allowspace: false,
              touppercase: false,
              mapvalue: false,
            },
            group: {
              from: [],
              to: [],
              type: ['N','D','S'],
              func: ['Left','Right','Mid'],
            },
            rules: {

            },
            readonly: {
              from: false,
              param_1: false,
              param_2: false,
            },
          },
          dialog: {
            risk: false,
            benefit: false,
            deductible: false,
            wording: false,
            clause: false,
            busines: false,
            payors: false,
            feeduty: false,
          },
        },

      // ========================================================================
      // End General Policy Information
      // ========================================================================

      // ========================================================================
      // Start Risk
      // ========================================================================
        risk: {
          table: {
            data: [
              { text: 'Rate Code', value: 'polrisk_rate_code', sortable: false },
              { text: 'Rate Category', value: 'polrisk_rate_category', sortable: false },
              { text: 'Period', value: 'polrisk_period' },
              { text: 'Discount & Loading (%)', value: 'polrisk_discount' },
              { text: 'Net Rate PCT Loss (%)', value: 'polrisk_net_rate_pct_loss' },
              { text: 'Actions', value: 'action', sortable: false }
            ],
            items: [],
            single_select: false,
            selected: [],
            search: '',
            search_button: false,
          },
          form: {
            input: {
              rate_code: [],
              rate_category: [],
              period: '',
              discount: '',
              net_rate_pct_loss: '',
            },
            group: {
              rate_code: [],
              rate_category: [],
            },
            rules: {

            },
            readonly: {
              rate_code: false,
            },
          },
          dialog: {
            form: false,
          },
        },
      // ========================================================================
      // End Risk
      // ========================================================================

      // ========================================================================
      // Start Benefit
      // ========================================================================
        benefit: {
          table: {
            data: [
              { text: 'Profile ID', value: 'polbenefit_profile_id', sortable: false },
              { text: 'Address', value: 'polbenefit_address', sortable: false },
              { text: 'Zip Code', value: 'polbenefit_zip_code' },
              { text: 'Gender', value: 'polbenefit_gender' },
              { text: 'Relation', value: 'polbenefit_relation' },
              { text: 'Birth Date', value: 'polbenefit_birth_date' },
              { text: 'Reim / Indem', value: 'polbenefit_reim_no' },
              { text: 'Remark', value: 'polbenefit_remark' },
              { text: 'Actions', value: 'action', sortable: false }
            ],
            items: [],
            single_select: false,
            selected: [],
            search: '',
            search_button: false,
          },
          form: {
            input: {
              profile_id: '',
              address: '',
              zip_code: '',
              gender: [],
              relation: '',
              birth_date: new Date().toISOString().substr(0, 10),
              reim: '',
              remark: '',
            },
            group: {
              gender: ['male', 'female'],
              relation: ['1', '2'],
            },
            rules: {

            },
            readonly: {
              profile_id: false,
            },
          },
          dialog: {
            form: false,
            birth_date: false,
          },
        },
      // ========================================================================
      // End Benefit
      // ========================================================================

      // ========================================================================
      // Start Deductible
      // ========================================================================
        deductible: {
          table: {
            data: [
              { text: 'Deductible', value: 'poldeductible_id', sortable: false },
              { text: 'Selection', value: 'poldeductible_selection', sortable: false },
              { text: 'Risk', value: 'poldeductible_risk' },
              { text: 'Year #', value: 'poldeductible_year' },
              { text: 'Actions', value: 'action', sortable: false }
            ],
            items: [],
            single_select: false,
            selected: [],
            search: '',
            search_button: false,
          },
          form: {
            input: {
              id: [],
              selection: [],
              risk: '',
              year: '',
            },
            group: {
              id: [],
              selection: [],
            },
            rules: {

            },
            readonly: {
              id: false,
            },
          },
          dialog: {
            form: false,
          },
        },
      // ========================================================================
      // End Deductible
      // ========================================================================

      // ========================================================================
      // Start Business Source
      // ========================================================================
        busines: {
          table: {
            data: [
              { text: 'Biz. Source ID', value: 'polbizsource_id', sortable: false },
              { text: 'BSID', value: 'polbizsource_bsid', sortable: false },
              { text: 'Biz. Source Name', value: 'polbizsource_name' },
              { text: 'Source Type', value: 'polbizsource_type' },
              { text: 'Fee | Discount | Up. Fee (%)', value: 'polbizsource_fee' },
              { text: 'Actions', value: 'action', sortable: false }
            ],
            items: [],
            single_select: false,
            selected: [],
            search: '',
            search_button: false,
          },
          form: {
            input: {
              id: [],
              bsid: [],
              name: '',
              type: [],
              fee: '',
              discount: '',
              upfee: '',
            },
            group: {
              id: [],
              bsid: [],
              type: ['N','D','S'],
            },
            rules: {

            },
            readonly: {
              id: false,
              bsid: false,
            },
          },
          dialog: {
            form: false,
          },
        },
      // ========================================================================
      // End Business Source
      // ========================================================================

      // ========================================================================
      // Start Payor Information
      // ========================================================================
        payors: {
          table: {
            data: [
              { text: 'Biz. Source ID', value: 'polbizsource_id', sortable: false },
              { text: 'BSID', value: 'polbizsource_bsid', sortable: false },
              { text: 'Biz. Source Name', value: 'polbizsource_name' },
              { text: 'Source Type', value: 'polbizsource_type' },
              { text: 'Fee | Discount | Up. Fee (%)', value: 'polbizsource_fee' },
              { text: 'Actions', value: 'action', sortable: false }
            ],
            items: [],
            single_select: false,
            selected: [],
            search: '',
            search_button: false,
          },
          form: {
            input: {
              id: [],
              bsid: [],
              name: '',
              type: [],
              fee: '',
              discount: '',
              upfee: '',
            },
            group: {
              id: [],
              bsid: [],
              type: ['N','D','S'],
            },
            rules: {

            },
            readonly: {
              id: false,
              bsid: false,
            },
          },
          dialog: {
            form: false,
          },
        },
      // ========================================================================
      // End Payor Information
      // ========================================================================

      // ========================================================================
      // Start Fee & Duty
      // ========================================================================
        feeduty: {
          table: {
            data: [
              { text: 'Fee & Duty', value: 'polfeeduty_feeduty', sortable: false },
              { text: 'Payor ID', value: 'polfeeduty_payor_id', sortable: false },
              { text: 'Payor Name', value: 'polfeeduty_payor_name' },
              { text: 'Currency', value: 'polfeeduty_currency' },
              { text: 'Year #', value: 'polfeeduty_year' },
              { text: 'Actions', value: 'action', sortable: false }
            ],
            items: [],
            single_select: false,
            selected: [],
            search: '',
            search_button: false,
          },
          form: {
            input: {
              feeduty: [],
              payor_id: [],
              payor_name: '',
              currency: [],
              category: [],
              year: '',
              contributed: false,
              tabarru: false,
              autogenerated: false,
            },
            group: {
              feeduty: [],
              payor_id: [],
              currency: [],
              year: ['0','1','2','3','4'],
              category: ['basic','premium'],
            },
            rules: {

            },
            readonly: {
              feeduty: false,
              payor_id: false,
            },
          },
          dialog: {
            form: false,
          },
        },
      // ========================================================================
      // End Fee & Duty
      // ========================================================================

      // New Comment =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      maxinput: {
        policyrisk: {
          desc: 150,
          voyagefrom: 500,
          voyageto: 500,
          vessel: 255
        },
        policyint: {
          remarks: 8000,
        },
        policyded: {
          remarkseng: 8000,
          remarksind: 8000,
        },
        policyfeeduty: {
          remarks: 8000
        },
        tc: {
          short_desc: 255,
          long_desc: 8000,
        }
      },

      dialog: {
        policy                    : false,
        policyrisk_index          : false,
        policyrisk                : false,
        policyint_index           : false,
        policyint                 : false,
        policyded_index           : false,
        policyded                 : false,
        policyfeeduty_index       : false,
        policyfeeduty             : false,
        policysource_index        : false,
        policysource              : false,
        policytc_index            : false,
        policytc                  : false,
        policydepreciation_index  : false,
        policydepreciation        : false
      },

      // Data Table Policy Risk
      policyrisk: {
        headers: [
          { text: "Riskcov ID", value: "policyrisk_riskcovid"},
          { text: "Description", value: "policyrisk_desc"},
          { text: "Category", value: "policyrisk_category"},
          { text: "Actions", value: "action", sortable: false},
        ],
        items: [],
        selected: [],
        search: "",
        searchbutton: false,
      },
      // end: Data Table Policy Risk

      // Data Table Policy Interest
      policyint: {
        headers: [
          { text: "Interest ID", value: "policyint_id" },
          { text: "Category", value: "policyint_category" },
          { text: "Adjustable", value: "policyint_adjustable" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        selected: [],
        search: "",
        searchbutton: false,
      },
      // end: Data Table Policy Interest

      // Data Table Policy Deductible
      policyded: {
        headers: [
          { text: "Deductible ID", value: "policyded_id" },
          { text: "CCY", value: "policyded_ccy" },
          { text: "For Indemnity", value: "policyded_forindemnity" },
          { text: "TSI SI %", value: "policyded_tsisipct" },
          { text: "Claim %", value: "policyded_claimpct" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        selected: [],
        search: "",
        searchbutton: false,
      },
      // end: Data Table Policy Deductible

      // Data Table Policy Fee & Duty
      policyfeeduty: {
        headers: [
          { text: "Fee & Duty ID", value: "policyfeeduty_id" },
          { text: "Payor ID", value: "policyfeeduty_payorid" },
          { text: "Amount", value: "policyfeeduty_amount" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        selected: [],
        search: "",
        searchbutton: false,
      },
      // end: Data Table Policy Fee & Duty

      // Data Table Policy Source
      policysource: {
        headers: [
          { text: "Profile ID", value: "policysource_profileid" },
          { text: "Type", value: "policysource_type" },
          { text: "Main Flag", value: "policysource_mainflag" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        selected: [],
        search: "",
        searchbutton: false,
      },
      // end: Data Table Policy Source

      // Data Table Policy Term & Condition
      policytc: {
        headers: [
          { text: "ID", value: "policytc_id" },
          { text: "Short Desc Eng", value: "policytc_shortdesc_eng" },
          { text: "Short Desc Ind", value: "policytc_shortdesc_ind" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        selected: [],
        search: "",
        searchbutton: false,
      },
      // end: Data Table Policy Term & Condition

      // Data Table Policy Depreciation
      policydepreciation: {
        headers: [
          { text: "Year Number", value: "policydepreciation_yearnumber" },
          { text: "Pct", value: "policydepreciation_pct" },
          { text: "Actived", value: "policydepreciation_actived" },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
        selected: [],
        search: "",
        searchbutton: false,
      },
      // end: Data Table Policy Depreciation

      //Format Number
      format_money: {
          decimal: ',',
          thousands: '.',
          precision: 0
      },

      // Format Policy Risk Logic
      format_policyrisklogic: {
        tsi: {
          decimal: ',',
          thousands: '.',
          precision: 0
        },
        rate: {
          decimal: ',',
          thousands: '.',
          precision: 0
        },
      },

      // Format Policy Risk
      format_policyrisk: {
        rate: {
          decimal: ',',
          thousands: '.',
          precision: 0
        },
        rateloading: {
          decimal: ',',
          thousands: '.',
          precision: 0
        },
        passenger: {
          decimal: ',',
          thousands: '.',
          precision: 0
        },
        ratediscountpct: {
          decimal: '.',
          thousands: ',',
          precision: 2
        },
      },

      // Format Policy Interest
      format_policyint: {
        limitofliabilityoc: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        tsioc: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        aggregateoc: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        limitofliability: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        tsi: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        aggregate: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        adjustablerate: {
          decimal: ".",
          thousands: ",",
          precision: 2,
        },
      },

      // Format Policy Deductible
      format_policyded: {
        tsisipct: {
          decimal: ".",
          thousands: ",",
          precision: 2,
        },
        claimpct: {
          decimal: ".",
          thousands: ",",
          precision: 2,
        },
        amountmin: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        amountmax: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
      },

      // Format Policy Fee & Duty
      format_policyfeeduty: {
        amount: {
          decimal   : ".",
          thousands : ",",
          precision : 4,
        }
      },

      // Format Policy Source
      format_policysource: {
        feepct: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        discount: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        feeamt: {
          decimal: ".",
          thousands: ",",
          precision: 4,
        },
        tax: {
          decimal: ".",
          thousands: ",",
          precision: 2,
        },
        vat: {
          decimal: ".",
          thousands: ",",
          precision: 2,
        },
        addfeepct: {
          decimal: ".",
          thousands: ",",
          precision: 0,
        }
      },

      // Format Policy Depreciation
      format_policydepreciation: {
        yearnumber: {
          decimal: ".",
          thousands: ",",
          precision: 0,
        },
        pct: {
          decimal: ".",
          thousands: ",",
          precision: 2,
        },
      },

		  	pp_stepper: 2,
        pp_panel: [0],

        policyrisk_rateunit : [],
        policyrisk_category : [],

        form: {
          campaign_id           : '',
          campaign_desc         : '',
          campaign_subclass     : '',
          policy_row            : '',
          policy_sdate          : new Date().toISOString().substr(0, 10),
          policy_edate          : new Date().toISOString().substr(0, 10),
          policy_quotation_no   : '',
          policy_no             : '',
          policy_certificateno  : '',
          policy_day            : 1,
          policy_month          : 0,

          policy_campaign_id      : '',
          policy_campaign_desc    : '',
          policy_subclass         : '',
          policy_subclass_desc    : '',
          policy_instype          : '',
          policy_instype_desc     : '',
          policy_instype_prefix   : '',
          policy_mo               : '',
          policy_mo_desc          : '',
          policy_channel          : '',
          policy_channel_desc     : '',
          policy_branch           : '',
          policy_branch_desc      : '',
          policy_costcenter       : '',
          policy_costcenter_desc  : '',
          policy_holder           : '',
          policy_holder_desc      : '',
          policy_insured          : '',
          policy_insured_desc     : '',

          policy_refno: '',
          policy_longinsured: '',
          policy_excl_period: false,
          policy_prorate: 'D',
          policy_day_basis: 0,
          policy_extended: 0,
          policy_validation: 0,
          policy_location: '',
          policy_locationdesc: '',
          policy_original_docno: '',
          policy_conjunction_quotation: '',
          policy_refdate: new Date().toISOString().substr(0, 10),
          policy_leader_no: '',
          policy_leader_date: new Date().toISOString().substr(0, 10),
          policy_wording: '',
          policy_old_no: '',
          policy_ujroh_cedant: 0,
          policy_ujroh_flag: false,
          policy_ujroh_cedant_ori: 0,

          policy_prorate_data: [
            {
              id: 'D',
              text: 'Daily'
            },
            {
              id: 'M',
              text: 'Month'
            }
          ],

          // Policy Risk
          policyrisk_policyrow        : '',
          policyrisk_riskcovid        : '',
          policyrisk_riskcovdesc      : '',
          policyrisk_desc             : '',
          policyrisk_rate             : '',
          policyrisk_rateunit         : '',
          policyrisk_rateloading      : '',
          policyrisk_category         : '',
          policyrisk_sdate            : '',
          policyrisk_edate            : '',
          policyrisk_passenger        : '',
          policyrisk_ratediscount     : '',
          policyrisk_ratediscountdesc : '',
          policyrisk_ratediscountpct  : '',
          policyrisk_voyagefrom       : '',
          policyrisk_voyageto         : '',
          policyrisk_voyageestdepdate : '',
          policyrisk_voyageestarrdate : '',
          policyrisk_voyagevessel     : '',
          policyrisk_riskcovid_par    : '',

          // Policy Int
          policyint_policyrow           : '',
          policyint_id                  : '',
          policyint_desc                : '',
          policyint_category            : '',
          policyint_remarks             : '',
          policyint_adjustable          : '',
          policyint_currencyoc          : '',
          policyint_currency            : '',
          policyint_limitofliabilityoc  : 'IDR',
          policyint_tsioc               : '',
          policyint_aggregateoc         : '',
          policyint_limitofliability    : 'IDR',
          policyint_tsi                 : '',
          policyint_aggregate           : '',
          policyint_firstloss           : '',
          policyint_adjustablerate      : '',
          policyint_sectionid           : '',
          policyint_sectiondesc         : '',
          policyint_id_par              : '',

          policyint_category_rules  : [(v) => !!v || "Please fill Category"],
          policyint_remarks_rules   : [(v) => !!v || "Please fill Remarks"],
          policyint_currencyoc_rules: [(v) => !!v || "Please fill Currency OC"],
          policyint_currency_rules  : [(v) => !!v || "Please fill Currency"],

          // Policy Deductible
          policyded_policyrow     : "",
          policyded_id            : "",
          policyded_withflag      : 0,
          policyded_ccy           : "",
          policyded_forindemnity  : "",
          policyded_tsisipct      : 0,
          policyded_tsisiflag     : 0,
          policyded_claimpct      : 0,
          policyded_amountmin     : 0,
          policyded_amountmax     : 0,
          policyded_remarkseng    : "",
          policyded_remarksind    : "",
          policyded_totallossflag : 0,
          policyded_actived       : true,
          policyded_id_par        : "",

          policyded_id_rules            : [(v) => !!v || "Please fill Deductible ID"],
          policyded_withflag_rules      : [(v) => !!v || "Please fill With Flag"],
          policyded_ccy_rules           : [(v) => !!v || "Please fill CCY"],
          policyded_forindemnity_rules  : [(v) => !!v || "Please fill For Indemnity",],
          policyded_tsisipct_rules      : [(v) => !!v || "Please fill TSI SI Pct"],
          policyded_tsisiflag_rules     : [(v) => !!v || "Please fill TSI SI Flag"],
          policyded_claimpct_rules      : [(v) => !!v || "Please fill Claim Pct"],
          policyded_amountmin_rules     : [(v) => !!v || "Please fill Amount Min"],
          policyded_amountmax_rules     : [(v) => !!v || "Please fill Amount Max"],
          policyded_remarkseng_rules    : [(v) => !!v || "Please fill Remarks Eng"],
          policyded_remarksind_rules    : [(v) => !!v || "Please fill Remarks Ind"],
          policyded_totallossflag_rules : [(v) => !!v || "Please fill Total Loss Flag",],

          // Policy Fee & Duty
          policyfeeduty_policyrow   : '',
          policyfeeduty_id          : '',
          policyfeeduty_desc        : '',
          policyfeeduty_payorid     : '',
          policyfeeduty_payor_desc  : '',
          policyfeeduty_amount      : 0,
          policyfeeduty_remarks     : '',
          policyfeeduty_id_par      : '',

          policyfeeduty_id_rules      : [(v) => !!v || "Please fill Fee & Duty ID"],
          policyfeeduty_payorid_rules : [(v) => !!v || "Please fill Payor ID"],
          policyfeeduty_amount_rules  : [(v) => !!v || "Please fill Amount"],
          policyfeeduty_remarks_rules : [(v) => !!v || "Please fill Adjustable"],

          // Policy Source
          policysource_policyrow          : '',
          policysource_profileid          : '',
          policysource_profile_desc       : '',
          policysource_type               : '',
          policysource_mainflag           : 0,
          policysource_feepct             : 0,
          policysource_discount           : 0,
          policysource_netaftermemberflag : 0,
          policysource_grosscommflag      : 0,
          policysource_memberfeeflag      : 0,
          policysource_feeamt             : 0,
          policysource_tax                : 0,
          policysource_vat                : 0,
          policysource_addfeepct          : 0,
          policysource_profileid_par      : '',
          policysource_type_par           : '',

          policysource_profileid_rules  : [(v) => !!v || "Please fill Profile ID"],
          policysource_type_rules       : [(v) => !!v || "Please fill Type"],
          policysource_feepct_rules     : [(v) => !!v || "Please fill Fee Percent"],
          policysource_discount_rules   : [(v) => !!v || "Please fill Discount"],
          policysource_feeamt_rules     : [(v) => !!v || "Please fill Fee Amount"],
          policysource_tax_rules        : [(v) => !!v || "Please fill Tax"],
          policysource_vat_rules        : [(v) => !!v || "Please fill Vat"],

          // Policy Term & Condition
          policytc_policyrow      : '',
          policytc_id             : '',
          policytc_classid        : '',
          policytc_shortdesc_eng  : '',
          policytc_longdesc_eng   : '',
          policytc_shortdesc_ind  : '',
          policytc_longdesc_ind   : '',
          policytc_id_par         : '',
          policytc_classid_par    : '',

          policytc_id_rules             : [(v) => !!v || "Please fill Term & Condition ID"],
          policytc_shortdesc_eng_rules  : [(v) => !!v || "Please fill Short Desc Eng"],
          policytc_longdesc_eng_rules   : [(v) => !!v || "Please fill Long Desc Eng"],
          policytc_shortdesc_ind_rules  : [(v) => !!v || "Please fill Short Desc Ind"],
          policytc_longdesc_ind_rules   : [(v) => !!v || "Please fill Long Desc Ind"],

          // Policy Depreciation
          policydepreciation_policyrow      : '',
          policydepreciation_yearnumber     : "",
          policydepreciation_pct            : "",
          policydepreciation_actived        : true,
          policydepreciation_yearnumber_par : "",

          policydepreciation_campaignid_rules : [(v) => !!v || "Please fill Profile Id"],
          policydepreciation_yearnumber_rules : [(v) => !!v || "Please fill Type"],
          policydepreciation_pct_rules        : [(v) => !!v || "Please fill Share Pct"],

          policy_riskcov_id      : '',
          policy_riskcov_desc    : '',

          pp_campaign_id_rules: [v => !!v || 'Please fill Campaign ID'],

          // Policy Risk Logic
          policyrisklogic_policy_row     : '',
          policyrisklogic_riskcov_id     : '',
          policyrisklogic_riskcov_desc   : '',
          policyrisklogic_interest_id    : '',
          policyrisklogic_interest_desc  : '',
          policyrisklogic_inception     : new Date().toISOString().substr(0, 10),
          policyrisklogic_expiry        : new Date().toISOString().substr(0, 10),
          policyrisklogic_stime         : '00:00:00',
          policyrisklogic_etime         : '00:00:00',
          policyrisklogic_day           : 1,
          policyrisklogic_month         : 0,
          policyrisklogic_rateflag      : false,
          policyrisklogic_tsi           : 0,
          policyrisklogic_rate          : 0,
          policyrisklogic_rateunit      : '',
          
          policyrisklogic_rateunit_option  : [
            {text: '-- Select --', value: ''},
            {text: '%', value: 'C'},
            {text: '%o', value: 'M'},
            {text: 'F', value: 'F'}
          ],

          pp_campaign_no: '',
          pp_campaign_desc: '',
          pp_quotation_no: '',
          pp_policy_no: '',
          pp_end_no: 0,
          pp_end_ext_no: 0,
          pp_renewal_no: 0,
          pp_instype_id:'',
          pp_instype_name:'',
          pp_subclass_id:'',
          pp_subclass_desc:'',
          pp_channel_id:'',
          pp_channel_desc:'',
          pp_branch_id:'',
          pp_branch_desc:'',
          pp_costcenter_id:'',
          pp_costcenter_desc:'',
          pp_policyholder_id:'',
          pp_policyholder_name:'',
          pp_insurance_type: '',
          pp_sum_insured: 0,
          pp_add_sum_insured: 0,
          pp_policy_limit: 0,
          pp_longinsured_name: '',
          pp_business: '',
          pp_inception: new Date().toISOString().substr(0, 10),
          pp_expiry: new Date().toISOString().substr(0, 10),
          pp_inception_time: '12:00',
          pp_expiry_time: '12:00',
          pp_period_day: 0,
          pp_extended: 0,
          pp_extended_period: true,
          pp_day_basis: 365,
          pp_validation: 0,
          pp_reference_date: new Date().toISOString().substr(0, 10),
          pp_leader_policy_date: new Date().toISOString().substr(0, 10),
          pp_reference_no: '',
          pp_leader_policy_no: '',
          pp_old_policy_no: '',
          pp_ujroh: 0,
          pp_prorata_basis_data: [
            {
              id: 'T',
              text: 'Default'
            },
            {
              id: 'D',
              text: 'Daily'
            },
            {
              id: 'M',
              text: 'Month'
            }
          ],
          pp_prorata_basis: 'T',
          pp_tab_source: true,
          pp_tab_source_item: false,
          pp_tab_payor: true,
          pp_tab_payor_item: false,
          pp_tab_payor_prm_grp_disc_pct: 0,
          pp_tab_payor_prm_disc_pct: 0,
          pp_tab_payor_prm_disc_amt: 0,
          pp_tab_payor_payment_method_data: [
            {
              id: 'TRANSFER',
              text: 'Transfer'
            },
            {
              id: 'CASH',
              text: 'Cash'
            },
            {
              id: 'CREDIT',
              text: 'Credit Card'
            },
            {
              id: 'VIRTUAL',
              text: 'Virtual'
            }
          ],
          pp_tab_payor_payment_method: 'TRANSFER',
          pp_tab_payor_grace_period: 0,
          pp_tab_payor_wpc_period: 0,
          pp_tab_payor_duedate: new Date().toISOString().substr(0, 10),
          pp_tab_payor_period_term_data: [
            {
              id: 0,
              text: 'Daily'
            },
            {
              id: 1,
              text: 'MOnthly'
            },
            {
              id: 3,
              text: 'Quarterly'
            },
            {
              id: 6,
              text: 'Half Yearly'
            },
            {
              id: 12,
              text: 'Annually'
            }
          ],
          pp_tab_payor_period_term: 0,
          pp_tab_payor_ninstallment: 0,
          pp_tab_payor_first_due_date: new Date().toISOString().substr(0, 10),
          pp_tab_payor_billing_policyyear: false,
          pp_tab_payor_instalment_feeduty: false,
          pp_tab_payor_items_source: [],
          pp_tab_payor_items_feeduty: [],
          pp_tab_risk_coverage: [],
          pp_tab_risk_benefit: [],
          pp_tab_risk_deductible: [],
        },

        gendet_fieldlabel_value       : {},
        gendet_fieldid_value          : {},
        gentab_detailid_value         : {},
        gentab_detaildesc_value       : {},

        selected: {
          documenttype:'COMMISION NOTE',
          languange:'English',
          picked:'Without Originality',
          layout:'',
          checkedOption: [],
          layoutAttachment:'',
          iframe:false,
          iframeURL: '',
        },
        checked: {
          premium: false,
          discount: false,
          attachment: false,
          selected: false,
          deferred: false,
          pa: false,
          headerfooter: false,
        },
        slider: 0,
        loading: {
          page                      : false,
          riskcoverage              : false,
          benefit                   : false,
          deductible                : false,
          source                    : false,
          feeduty                   : false,
          policyriskdialog          : false,
          listpolicyrisk            : false,
          policyintdialog           : false,
          listpolicyint             : false,
          policydeddialog           : false,
          listpolicyded             : false,
          policyfeedutydialog       : false,
          listpolicyfeeduty         : false,
          policysourcedialog        : false,
          listpolicysource          : false,
          policytcdialog            : false,
          listpolicytc              : false,
          policydepreciationdialog  : false,
          listpolicydepreciation    : false
        },
        modal: {
          policyrisklogic_inception: false,
          policyrisklogic_inception_time: false,
          policyrisklogic_expiry: false,
          policyrisklogic_expiry_time: false,
          reference_date: false,
          leader_policy_date: false,
          due_date: false,
          first_due_date: false,
        },
        widgets: false,
        pp_breadcrumb: [
        {
          text: 'Treaty Arragement',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Business Result',
          disabled: false,
          href: 'breadcrumbs_link_1',
        },
        {
          text: 'Upload',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
        {
          text: 'Print',
          disabled: false,
          href: '#/policyproduction/print',
        },
      ],
      confirm: {
        dialog: false,
        title: '',
        text: 'Ok',
      },
      remarks: {
        feedback  :'',
        dialog    : false,
      },
      snackbar: {
        dialog: false,
        color: 'success',
        text: '',
        timeout: 3000
      },
	  	}),
    
    created () {
      this.FirstLoad()
    },
    
    computed: {
      ItemsPolicyRisk() {
        for (let i = 0; i <= this.policyrisk.items.length - 1; i++) {
          const policyriskindex = this.policyrisk.items[i];
          policyriskindex.policyrisk_row =
            this.policyrisk.items[i].policyrisk_policyrow +
            "|" +
            this.policyrisk.items[i].policyrisk_riskcovid +
            "|";
        }
        return this.policyrisk.items;
      },

      ItemsPolicyInt() {
        for (let i = 0; i <= this.policyint.items.length - 1; i++) {
          const policyintindex = this.policyint.items[i];
          policyintindex.policyint_row =
            this.policyint.items[i].policyint_policyrow +
            "|" +
            this.policyint.items[i].policyint_id +
            "|";
        }
        return this.policyint.items;
      },

      ItemsPolicyDed() {
        for (let i = 0; i <= this.policyded.items.length - 1; i++) {
          const policydedindex = this.policyded.items[i];
          policydedindex.policyded_row =
            this.policyded.items[i].policyded_policyrow +
            "|" +
            this.policyded.items[i].policyded_id +
            "|";
        }
        return this.policyded.items;
      },

      ItemsPolicyFeeDuty() {
        for (let i = 0; i <= this.policyfeeduty.items.length - 1; i++) {
          const policyfeedutyindex = this.policyfeeduty.items[i];
          policyfeedutyindex.policyfeeduty_row =
            this.policyfeeduty.items[i].policyfeeduty_policyrow +
            "|" +
            this.policyfeeduty.items[i].policyfeeduty_id +
            "|";
        }
        return this.policyfeeduty.items;
      },

      ItemsPolicySource() {
        for (let i = 0; i <= this.policysource.items.length - 1; i++) {
          const policysourceindex = this.policysource.items[i];
          policysourceindex.policysource_row =
            this.policysource.items[i].policysource_policyrow +
            "|" +
            this.policysource.items[i].policysource_profileid +
            "|" +
            this.policysource.items[i].policysource_type +
            "|";
        }
        return this.policysource.items;
      },

      ItemsPolicyTC() {
        for (let i = 0; i <= this.policytc.items.length - 1; i++) {
          const policytcindex = this.policytc.items[i];
          policytcindex.policytc_row =
            this.policytc.items[i].policytc_policyrow +
            "|" +
            this.policytc.items[i].policytc_id +
            "|" +
            this.policytc.items[i].policytc_classid +
            "|";
        }
        return this.policytc.items;
      },
      
      ItemsPolicyDepreciation() {
        for (let i = 0; i <= this.policydepreciation.items.length - 1; i++) {
          const policydepreciationindex = this.policydepreciation.items[i];
          policydepreciationindex.policydepreciation_row =
            this.policydepreciation.items[i].policydepreciation_policyrow +
            "|" +
            this.policydepreciation.items[i].policydepreciation_yearnumber +
            "|";
        }
        return this.policydepreciation.items;
      },
    },
    
    methods: {
      FirstLoad () {
        //this function use for validating the screen with user privilleges
        this.Access()
        
        // Load API to select box riskcov_unit
        this.GetListCode('RATEUNIT')
        this.GetListCode('POLICYCATEGORY')
      },

      //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
      Access () {
        let modul     = 'policy'
        let formdata  = {
          menu_id: 'policy',
          users_id: this.$functions.UsersID(),
          order_by: 'menu_id',
          order_type: 'ASC',
          limit: this.limit,
        }
        let param     = this.$functions.ParamPOST(formdata)		

        this.$axios
        .post(this.$functions.UrlPOST('apiListMenuUser'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let priv = response.data
          if (priv.length > 0) {
            this.access.read 	  = priv[0].usersmenu_read
            this.access.add  	  = priv[0].usersmenu_add
            this.access.edit    = priv[0].usersmenu_edit
            this.access.delete  = priv[0].usersmenu_delete
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
            }
          } else {
            this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
          }
          this.loading.page = false
        })
        .catch((e) => {
          console.log(e)
          this.SnackBar(true, 'error', e, 0)
          this.loading.page = false
        })
      },

      //This function use for give feedback when user has no access with some feature
      NoPriviledge () {
        this.SnackBar(true, 'error', 'You dont have access `Rate Tab` modul, please contact Administrator', 0)
      },

      // Temp
      AddMapDetail () {

      },
      SelectAllMapValue () {

      },

      SearchMapValue () {

      },
      // End: Temp
      Confirm (flag, par1, par2, par3) {
        console.log('Confirm', flag)
        if (flag ==='writestep1') {
          if (this.$refs.form_campaign.validate()) {
            this.url_type 		  = 'writestep1'
            this.confirm.dialog = true
            this.confirm.title  = 'Confirm Campaign'
            setTimeout(function(){
              document.getElementById('dialog').focus()
            }, 500)
          }
        } else if (flag === "addpolicyrisk" || flag === "editpolicyrisk") {
          this.url_type       = this.url_type
          this.confirm.dialog = true
          this.confirm.title  = "Save"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "deletepolicyrisk") {
          this.url_type             = "deletepolicyrisk"
          this.confirm.dialog       = true
          this.confirm.title        = "Delete `" + par2 + "`"
          this.policyrisk_policyrow = par1
          this.policyrisk_riskcovid = par2
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "multideletepolicyrisk") {
          this.url_type       = "multideletepolicyrisk"
          this.confirm.dialog = true
          this.confirm.title  =
            "Delete `" + this.policyrisk.selected.length + "` data"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "addpolicyint" || flag === "editpolicyint") {
          this.url_type       = this.url_type
          this.confirm.dialog = true
          this.confirm.title  = "Save"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "deletepolicyint") {
          this.url_type             = "deletepolicyint"
          this.confirm.dialog       = true
          this.confirm.title        = "Delete `" + par2 + "`"
          this.policyint_policyrow  = par1
          this.policyint_id         = par2
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "multideletepolicyint") {
          this.url_type       = "multideletepolicyint"
          this.confirm.dialog = true
          this.confirm.title  =
            "Delete `" + this.policyint.selected.length + "` data"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "addpolicyded" || flag === "editpolicyded") {
          this.url_type       = this.url_type
          this.confirm.dialog = true
          this.confirm.title  = "Save"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "deletepolicyded") {
          this.url_type             = "deletepolicyded"
          this.confirm.dialog       = true
          this.confirm.title        = "Delete `" + par2 + "`"
          this.policyded_policyrow  = par1
          this.policyded_id         = par2
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "multideletepolicyded") {
          this.url_type       = "multideletepolicyded"
          this.confirm.dialog = true
          this.confirm.title  =
            "Delete `" + this.policyded.selected.length + "` data"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "addpolicyfeeduty" || flag === "editpolicyfeeduty") {
          this.url_type       = this.url_type
          this.confirm.dialog = true
          this.confirm.title  = "Save"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "deletepolicyfeeduty") {
          this.url_type                 = "deletepolicyfeeduty"
          this.confirm.dialog           = true
          this.confirm.title            = "Delete `" + par2 + "`"
          this.policyfeeduty_policyrow  = par1
          this.policyfeeduty_id         = par2
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "multideletepolicyfeeduty") {
          this.url_type       = "multideletepolicyfeeduty"
          this.confirm.dialog = true
          this.confirm.title  =
            "Delete `" + this.policyfeeduty.selected.length + "` data"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "addpolicysource" || flag === "editpolicysource") {
          this.url_type       = this.url_type
          this.confirm.dialog = true
          this.confirm.title  = "Save"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500);
        } else if (flag === "deletepolicysource") {
          this.url_type               = "deletepolicysource"
          this.confirm.dialog         = true
          this.confirm.title          = "Delete `" + par2 + "`"
          this.policysource_policyrow = par1
          this.policysource_profileid = par2
          this.policysource_type      = par3
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "multideletepolicysource") {
          this.url_type       = "multideletepolicysource"
          this.confirm.dialog = true
          this.confirm.title  =
            "Delete `" + this.policysource.selected.length + "` data"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "addpolicytc" || flag === "editpolicytc") {
          this.url_type       = this.url_type
          this.confirm.dialog = true
          this.confirm.title  = "Save"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500);
        } else if (flag === "deletepolicytc") {
          this.url_type           = "deletepolicytc"
          this.confirm.dialog     = true
          this.confirm.title      = "Delete `" + par2 + "`"
          this.policytc_policyrow = par1
          this.policytc_id        = par2
          this.policytc_classid   = par3
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "multideletepolicytc") {
          this.url_type       = "multideletepolicytc"
          this.confirm.dialog = true
          this.confirm.title  =
            "Delete `" + this.policytc.selected.length + "` data"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "addpolicydepreciation" || flag === "editpolicydepreciation") {
          this.url_type       = this.url_type
          this.confirm.dialog = true
          this.confirm.title  = "Save"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500);
        } else if (flag === "deletepolicydepreciation") {
          this.url_type           = "deletepolicydepreciation"
          this.confirm.dialog     = true
          this.confirm.title      = "Delete `" + par2 + "`"
          this.policydepreciation_policyrow   = par1
          this.policydepreciation_yearnumber  = par2
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        } else if (flag === "multideletepolicydepreciation") {
          this.url_type       = "multideletepolicydepreciation"
          this.confirm.dialog = true
          this.confirm.title  =
            "Delete `" + this.policydepreciation.selected.length + "` data"
          setTimeout(function () {
            document.getElementById("dialog").focus()
          }, 500)
        }
      },
      Close (flag) {
        if (flag === 'writestep1') {            
          this.url_type       = 'add'
          this.confirm.dialog = false
        } else if (flag === "policyrisk_index") {
          this.url_type               = "edit"
          this.dialog.policyrisk_index = false
          this.ListPolicyRisk(this.form.policy_row)
        } else if (flag === "policyrisk") {
          this.url_type         = "edit"
          this.dialog.policyrisk = false
          this.ListPolicyRisk(this.form.policy_row)
        } else if (flag === "policyint_index") {
          this.url_type               = "edit"
          this.dialog.policyint_index = false
          this.ListPolicyInt(this.form.policy_row)
        } else if (flag === "policyint") {
          this.url_type         = "edit"
          this.dialog.policyint = false
          this.ListPolicyInt(this.form.policy_row)
        } else if (flag === "policyded_index") {
          this.url_type               = "edit"
          this.dialog.policyded_index = false
          this.ListPolicyDed(this.form.policy_row)
        } else if (flag === "policyded") {
          this.url_type         = "edit"
          this.dialog.policyded = false
          this.ListPolicyDed(this.form.policy_row)
        } else if (flag === "policyfeeduty_index") {
          this.url_type                   = "edit"
          this.dialog.policyfeeduty_index = false
          this.ListPolicyFeeDuty(this.form.policy_row)
        } else if (flag === "policyfeeduty") {
          this.url_type             = "edit"
          this.dialog.policyfeeduty = false
          this.ListPolicyFeeDuty(this.form.policy_row)
        } else if (flag === "policysource_index") {
          this.url_type                  = "edit"
          this.dialog.policysource_index = false
          this.ListPolicySource(this.form.policy_row)
        } else if (flag === "policysource") {
          this.url_type            = "edit"
          this.dialog.policysource = false
          this.ListPolicySource(this.form.policy_row)
        } else if (flag === "policytc_index") {
          this.url_type                  = "edit"
          this.dialog.policytc_index = false
          this.ListPolicyTC(this.form.policy_row)
        } else if (flag === "policytc") {
          this.url_type            = "edit"
          this.dialog.policytc = false
          this.ListPolicyTC(this.form.policy_row)
        } else if (flag === "policydepreciation_index") {
          this.url_type                  = "edit"
          this.dialog.policydepreciation_index = false
          this.ListPolicyDepreciation(this.form.policy_row)
        } else if (flag === "policydepreciation") {
          this.url_type            = "edit"
          this.dialog.policydepreciation = false
          this.ListPolicyDepreciation(this.form.policy_row)
        }
      },
      Loading () {
        this.confirm.text ='Loading...'
        if (this.url_type ==='add' || this.url_type ==='edit') {
          this.Write()
        } else if (this.url_type ==='writestep1') {
          this.Step1()
        } else if (this.url_type === "addpolicyrisk" || this.url_type === "editpolicyrisk") {
          this.WritePolicyRisk()
        } else if (this.url_type === "deletepolicyrisk") {
          this.DeletePolicyRisk(this.policyrisk_policyrow, this.policyrisk_riskcovid)
        } else if (this.url_type === "multideletepolicyrisk") {
          this.MultiProcessPolicyRisk()
        } else if (this.url_type === "addpolicyint" || this.url_type === "editpolicyint") {
          this.WritePolicyInt()
        } else if (this.url_type === "deletepolicyint") {
          this.DeletePolicyInt(this.policyint_policyrow, this.policyint_id)
        } else if (this.url_type === "multideletepolicyint") {
          this.MultiProcessPolicyInt()
        } else if (this.url_type === "addpolicyded" || this.url_type === "editpolicyded") {
          this.WritePolicyDed()
        } else if (this.url_type === "deletepolicyded") {
          this.DeletePolicyDed(this.policyded_policyrow, this.policyded_id)
        } else if (this.url_type === "multideletepolicyded") {
          this.MultiProcessPolicyDed()
        } else if (this.url_type === "addpolicyfeeduty" || this.url_type === "editpolicyfeeduty") {
          this.WritePolicyFeeDuty()
        } else if (this.url_type === "deletepolicyfeeduty") {
          this.DeletePolicyFeeDuty(this.policyfeeduty_policyrow, this.policyfeeduty_id)
        } else if (this.url_type === "multideletepolicyfeeduty") {
          this.MultiProcessPolicyFeeDuty()
        } else if (this.url_type === "addpolicysource" || this.url_type === "editpolicysource") {
          this.WritePolicySource()
        } else if (this.url_type === "deletepolicysource") {
          this.DeletePolicySource(this.policysource_policyrow, this.policysource_profileid, this.policysource_type)
        } else if (this.url_type === "multideletepolicysource") {
          this.MultiProcessPolicySource()
        } else if (this.url_type === "addpolicytc" || this.url_type === "editpolicytc") {
          this.WritePolicyTC()
        } else if (this.url_type === "deletepolicytc") {
          this.DeletePolicyTC(this.policytc_policyrow, this.policytc_id, this.policytc_classid)
        } else if (this.url_type === "multideletepolicytc") {
          this.MultiProcessPolicyTC()
        } else if (this.url_type === "addpolicydepreciation" || this.url_type === "editpolicydepreciation") {
          this.WritePolicyDepreciation()
        } else if (this.url_type === "deletepolicydepreciation") {
          this.DeletePolicyDepreciation(this.policydepreciation_policyrow, this.policydepreciation_yearnumber)
        } else if (this.url_type === "multideletepolicydepreciation") {
          this.MultiProcessPolicyDepreciation()
        }
      },

      GetCampaign() {
        let campaign_id = document.getElementById('campaign_id').value
        let formdata    = {
          campaign_id : campaign_id,
          order_by    : 'campaign_id',
          order_type  : 'ASC',
          limit       : this.limit,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios
        .post(this.$functions.UrlPOST("apiListCampaign"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          console.log("Response", feedback)
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.campaign_id       = feedback[0].campaign_id              
              this.form.campaign_desc     = feedback[0].campaign_desc
              this.form.campaign_subclass = feedback[0].campaign_subclass

              this.form.policy_campaign_id      = feedback[0].campaign_id
              this.form.policy_campaign_desc    = feedback[0].campaign_desc
              this.form.policy_subclass         = feedback[0].campaign_subclass
              this.form.policy_subclass_desc    = feedback[0].campaign_subclass_desc
              this.form.policy_instype          = feedback[0].campaign_instype
              this.form.policy_instype_desc     = feedback[0].campaign_instype_desc
              this.form.policy_instype_prefix   = feedback[0].campaign_instype_prefix
              this.form.policy_mo               = feedback[0].campaign_mo
              this.form.policy_mo_desc          = feedback[0].campaign_mo_desc
              this.form.policy_channel          = feedback[0].campaign_channel
              this.form.policy_channel_desc     = feedback[0].campaign_channel_desc
              this.form.policy_branch           = feedback[0].campaign_branch
              this.form.policy_branch_desc      = feedback[0].campaign_branch_desc
              this.form.policy_costcenter       = feedback[0].campaign_costcenter
              this.form.policy_costcenter_desc  = feedback[0].campaign_costcenter_desc
              this.form.policy_holder           = feedback[0].campaign_pholder
              this.form.policy_holder_desc      = feedback[0].campaign_pholder_desc
              this.form.policy_insured          = feedback[0].campaign_pholder
              this.form.policy_insured_desc     = feedback[0].campaign_pholder_desc

              // let pholder = feedback[0].campaign_pholder_desc
              // let insured = feedback[0].campaign_pholder_desc

              if(this.form.campaign_id != '' && this.form.campaign_subclass != '') {
                this.WriteStep1()

                // Set Long Insured Name
                this.PHIOnChange(this.form.policy_holder_desc, this.form.policy_insured_desc)

                // Check Risk Logic
                let checkrisklogic = feedback[0].campaign_risklogicf
                if(checkrisklogic == 'Y') {
                  this.pp_la              = 2
                } else {
                  this.pp_la              = 3
                }

                // Object Info
                this.DetailData()
              }
            } else {
              this.SnackBar(
                true,
                "error",
                this.$functions.ErrorCode(),
                0
              )
              this.remarks.dialog   = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
        })
        .catch((e) => {
          this.SnackBar(
            true,
            "error",
            this.$functions.ErrorCode(),
            0
          )
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
      },        

      // Step 1
      Step1 () {                    
        this.GetCampaign()
      },
      SEDMOnChange (flag) {
        let day   = parseInt(this.form.policy_day)
        let month = parseInt(this.form.policy_month)
        let formdata    = {
          url_type            : flag,
          calculate_inception : this.form.policy_sdate,
          calculate_expiry    : this.form.policy_edate,
          calculate_day       : day,
          calculate_month     : month
        }
        console.log("Param SEDM", formdata)
        let param = this.$functions.ParamPOST(formdata)
        this.$axios
        .post(this.$functions.UrlPOST("apiWriteCalculate"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          console.log("Response", feedback)
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.policy_sdate  = feedback[0].calculate_inception              
              this.form.policy_edate  = feedback[0].calculate_expiry
              this.form.policy_day    = feedback[0].calculate_day
              this.form.policy_month  = feedback[0].calculate_month
            } else {
              this.SnackBar(
                true,
                "error",
                this.$functions.ErrorCode(),
                0
              )
              this.remarks.dialog   = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
        })
        .catch((e) => {
          this.SnackBar(
            true,
            "error",
            this.$functions.ErrorCode(),
            0
          )
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
      },
      WriteStep1 () {
        console.log('Write Step 1')
        this.loading.page = true
        let formdata ={
          url_type              : this.url_type,
          policy_campaign_id    : this.form.campaign_id,
          policy_instype_prefix : this.form.policy_instype_prefix,
          policy_branch         : this.form.policy_branch
        }      
        let param 	   =this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWritePolicy'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          console.log('Response', response)
          let feedback =response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback ==='Y') {
              this.SnackBar(true, 'success', 'Campaign Available', 3000)
              this.confirm.dialog             = false
              this.confirm.text               = 'Ok'
              this.form.policy_row            = feedback[0].policy_row
              this.form.policy_quotation_no   = feedback[0].policy_quotation_no
              this.form.policy_no             = feedback[0].policy_no
              this.form.policy_certificateno  = feedback[0].policy_certificateno

              this.form.policyrisk_policyrow    = feedback[0].policy_row
              this.form.policyint_policyrow     = feedback[0].policy_row
              this.form.policyded_policyrow     = feedback[0].policy_row
              this.form.policyfeeduty_policyrow = feedback[0].policy_row

              this.loading.page       = false

              console.log('Policy Row:', feedback[0].policy_row)
            } else {
              this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
              this.remarks.dialog   =true
              this.remarks.feedback =feedback[0].feedback
            }
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   =true
            this.remarks.feedback =feedback
          }
        })
        .catch(e => {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   =true
          this.remarks.feedback =e
        })
      },        

      // Step 2
      IEDMOnChange (flag) {
        let day   = parseInt(this.form.policyrisklogic_day)
        let month = parseInt(this.form.policyrisklogic_month)
        let formdata    = {
          url_type            : flag,
          calculate_inception : this.form.policyrisklogic_inception,
          calculate_expiry    : this.form.policyrisklogic_expiry,
          calculate_day       : day,
          calculate_month     : month
        }
        console.log("Param IEDM", formdata)
        let param = this.$functions.ParamPOST(formdata)
        this.$axios
        .post(this.$functions.UrlPOST("apiWriteCalculate"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          console.log("Response", feedback)
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.policyrisklogic_inception = feedback[0].calculate_inception              
              this.form.policyrisklogic_expiry    = feedback[0].calculate_expiry
              this.form.policyrisklogic_day       = feedback[0].calculate_day
              this.form.policyrisklogic_month     = feedback[0].calculate_month
            } else {
              this.SnackBar(
                true,
                "error",
                this.$functions.ErrorCode(),
                0
              )
              this.remarks.dialog   = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
        })
        .catch((e) => {
          this.SnackBar(
            true,
            "error",
            this.$functions.ErrorCode(),
            0
          )
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
      },
      GetPolicyHolder (value) {
        if (value !== undefined) {            
          let splits  = this.$functions.Split(value, '|')
          let policyholder = splits[1]
          this.form.policy_holder_desc = policyholder
          this.PHIOnChange(policyholder, this.form.policy_insured_desc)
        }
      },
      GetInsured (value) {
        if (value !== undefined) {            
          let splits  = this.$functions.Split(value, '|')
          let insured = splits[1]
          this.form.policy_insured_desc = insured
          this.PHIOnChange(this.form.policy_holder_desc, insured)
        }
      },
      PHIOnChange (policyholder, insured) {
        if (policyholder == insured) {
          console.log("Long Insured Name", insured)
          this.form.policy_longinsured = insured
        } else {
          console.log("Long Insured Name", policyholder+' QQ '+insured)
          this.form.policy_longinsured = policyholder+' QQ '+insured
        }
      },
      Step2 () {

      },
      WriteStep2 () {

      },
      WriteStep3 () {

      },
      WriteStep4 () {

      },
      WriteStep5 () {

      },

      DetailData () {
      let formdata = {
        subclass_id: this.form.tablelimit_subclassid,
      }
      let param 	   = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiListSubClassObjectInfo'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback 		 = response.data
        let checking 		 = false
        for (let i = 1; i <= 20; i ++) {
          let field_id    = `subclass_fieldid_` + i
          let field_label = `subclass_fieldlabel_` + i
          if (feedback[0][field_id] !== '') {
            this.$set(this.gendet_fieldid_value, i, feedback[0][field_id])
            this.$set(this.gendet_fieldlabel_value, i, feedback[0][field_label])
            if (feedback[0].subclass_fieldid_1 !== '') {
              checking = true
            }
          }
        }
        // this.DetailValue()
        this.attributes = checking
      })
      .catch(e => {
        this.SnackBar(true, 'error', e, 3000)
      })
      },

  // Function Policy
  WritePolicy(row) {
    let subclass    = document.getElementById("policy_subclass").value
    let instype     = document.getElementById("policy_instype").value
    let mo          = document.getElementById("policy_mo").value
    let channel     = document.getElementById("policy_channel").value
    let branch      = document.getElementById("policy_branch").value
    let costcenter  = document.getElementById("policy_costcenter").value
    let holder      = document.getElementById("policy_holder").value
    let insured     = document.getElementById("policy_insured").value
    let location    = document.getElementById("policy_location").value
    let wording     = document.getElementById("policy_wording").value

    let objectinfo = []
    for (let i = 1; i <= 20; i ++) {
      if (document.getElementById('gendet_fielddesc_' + i) !== null) {
        let gendet_fieldid 		  = this.gendet_fieldid_value[i]
        let gendet_fieldlabel 	  = this.gendet_fieldlabel_value[i]
        let gendet_fieldvalue 	  = document.getElementById('gendet_fieldid_' + i).value
        let gendet_fieldvaluedesc = document.getElementById('gendet_fielddesc_' + i).value
        objectinfo.push({gendet_fieldid: gendet_fieldid, gendet_fieldlabel: gendet_fieldlabel, gendet_fieldvalue: gendet_fieldvalue, gendet_fieldvaluedesc: gendet_fieldvaluedesc, gendet_fieldno: i})
      }
    }

    let formdata = {
      url_type                      : 'editpolicy',
      policy_row                    : row,
      policy_subclass               : subclass,
      policy_instype                : instype,
      policy_mo                     : mo,
      policy_channel                : channel,
      policy_branch                 : branch,
      policy_costcenter             : costcenter,
      policy_holder                 : holder,
      policy_insured                : insured,
      policy_longinsured            : this.form.policy_longinsured,
      policy_excl_period            : this.$functions.ActivedFlag(this.form.policy_excl_period),
      policy_prorate                : this.form.policy_prorate,
      policy_day_basis              : this.form.policy_day_basis,
      policy_extended               : this.form.policy_extended,
      policy_validation             : this.form.policy_validation,
      policy_location               : location,
      policy_refno                  : this.form.policy_refno,
      policy_original_docno         : this.form.policy_original_docno,
      policy_conjunction_quotation  : this.form.policy_conjunction_quotation,
      policy_refdate                : this.form.policy_refdate,
      policy_leader_no              : this.form.policy_leader_no,
      policy_leader_date            : this.form.policy_leader_date,
      policy_wording                : wording,
      policy_old_no                 : this.form.policy_old_no,
      policy_ujroh_cedant           : this.form.policy_ujroh_cedant,
      policy_ujroh_flag             : this.$functions.ActivedFlag(this.form.policy_ujroh_flag),
      policy_ujroh_cedant_ori       : this.form.policy_ujroh_cedant_ori,
      policy_objectinfo             : JSON.stringify(objectinfo)
    }
    console.log('WRITE POLICY', formdata)
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicy"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", "Save Success", 3000)
            this.confirm.dialog = false
            this.confirm.text   = "Ok"
            if (this.url_type === "addpolicy") {
              this.form.policy_cuser = feedback[0].feedback_users_id
              this.form.policy_cdate = feedback[0].feedback_users_date
            }
            this.form.policy_luser  = feedback[0].feedback_users_id
            this.form.policy_ldate  = feedback[0].feedback_users_date
            this.url_type           = "editpolicy"
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },
  
  // Function Policy Risk
  SelectAllPolicyRisk() {
    this.policyrisk.selected =
    this.policyrisk.selected === this.policyrisk.items ? [] : this.policyrisk.items
  },

  ListPolicyRisk(row, id) {
    this.loading.listpolicyrisk = true
    let formdata = {
      policyrisk_policyrow  : row,
      policyrisk_riskcovid  : id,
      order_by              : "policyrisk_riskcovid",
      order_type            : "ASC",
      limit                 : this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyRisk"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(true, "error", feedback[0].feedback, 0)
          } else {
            this.policyrisk.items = feedback
          }
        } else {
          this.policyrisk.items = feedback
          this.SnackBar(true, "error", this.$functions.NoData(), 0)
        }
        this.loading.listpolicyrisk = false
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000)
        this.loading.listpolicyrisk = false
      })
  },

  CheckPolicyRisk(value) {
    this.policyrisk.selected = value
  },

  SearchPolicyRisk() {
    this.policyrisk.searchbutton = true
    setTimeout(function () {
      document.getElementById("searchpolicyrisk").focus()
    }, 500)
  },

  OpenPolicyRiskIndex() {
    this.dialog.policyrisk_index = true;
    this.ListPolicyRisk(this.form.policy_row)
  },

  OpenPolicyRisk() {
    this.dialog.policyrisk = true
    this.AddPolicyRisk()
  },

  AddPolicyRisk() {
    this.url_type                         = 'addpolicyrisk'
    this.form.policyrisk_riskcovid        = ''
    this.form.policyrisk_riskcovdesc      = ''
    this.form.policyrisk_desc             = ''
    this.form.policyrisk_rate             = 0
    this.form.policyrisk_rateunit         = ''
    this.form.policyrisk_rateloading      = 0
    this.form.policyrisk_category         = ''
    this.form.policyrisk_sdate            = ''
    this.form.policyrisk_edate            = ''
    this.form.policyrisk_passenger        = 0
    this.form.policyrisk_ratediscount     = ''
    this.form.policyrisk_ratediscountdesc = ''
    this.form.policyrisk_ratediscountpct  = 0
    this.form.policyrisk_voyagefrom       = ''
    this.form.policyrisk_voyageto         = ''
    this.form.policyrisk_voyageestdepdate = ''
    this.form.policyrisk_voyageestarrdate = ''
    this.form.policyrisk_voyagevessel     = ''
    this.form.policyrisk_policyrow        = this.form.policy_row

    this.$refs.policyrisk_rate.$el.getElementsByTagName("input")[0].value             = 0
    this.$refs.policyrisk_rateloading.$el.getElementsByTagName("input")[0].value      = 0
    this.$refs.policyrisk_passenger.$el.getElementsByTagName("input")[0].value        = 0
    this.$refs.policyrisk_ratediscountpct.$el.getElementsByTagName("input")[0].value  = 0
    
    // this.flag.policyrisk    = true
    this.dialog.policyrisk  = true
  },
  
  WritePolicyRisk() {
    let riskcovid     = document.getElementById("policyrisk_riskcovid").value;
    let ratediscount  = document.getElementById("policyrisk_ratediscount").value;
    let formdata = {
      url_type                    : this.url_type,
      policyrisk_policyrow        : this.form.policy_row,
      policyrisk_riskcovid        : riskcovid,
      policyrisk_desc             : this.form.policyrisk_desc,
      policyrisk_rate             : this.form.policyrisk_rate,
      policyrisk_rateunit         : this.form.policyrisk_rateunit,
      policyrisk_rateloading      : this.form.policyrisk_rateloading,
      policyrisk_category         : this.form.policyrisk_category,
      policyrisk_sdate            : this.form.policyrisk_sdate,
      policyrisk_edate            : this.form.policyrisk_edate,
      policyrisk_passenger        : this.form.policyrisk_passenger,
      policyrisk_ratediscount     : ratediscount,
      policyrisk_ratediscountpct  : this.form.policyrisk_ratediscountpct,
      policyrisk_voyagefrom       : this.form.policyrisk_voyagefrom,
      policyrisk_voyageto         : this.form.policyrisk_voyageto,
      policyrisk_voyageestdepdate : this.form.policyrisk_voyageestdepdate,
      policyrisk_voyageestarrdate : this.form.policyrisk_voyageestarrdate,
      policyrisk_voyagevessel     : this.form.policyrisk_voyagevessel,
      policyrisk_riskcovid_par    : this.form.policyrisk_riskcovid_par,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyRisk"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", "Save Success", 3000)
            this.confirm.dialog = false
            this.confirm.text   = "Ok"
            if (this.url_type === "addpolicyrisk") {
              this.form.policy_cuser = feedback[0].feedback_users_id
              this.form.policy_cdate = feedback[0].feedback_users_date
            }
            this.form.policy_luser  = feedback[0].feedback_users_id
            this.form.policy_ldate  = feedback[0].feedback_users_date
            this.url_type           = "editpolicyrisk"
            this.dialog.policyrisk  = false
            this.ListPolicyRisk(this.form.policy_row)
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  EditPolicyRisk(row, id) {
    this.ClearCBPolicyRisk()

    // this.flag.policyrisk          = true
    this.dialog.policyrisk        = true
    this.loading.policyriskdialog = true
    let formdata = {
      policyrisk_policyrow  : row,
      policyrisk_riskcovid  : id,
      order_by              : "policyrisk_riskcovid",
      order_type            : "ASC",
      limit                 : this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyRisk"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.url_type = "editpolicyrisk"

            this.form.policyrisk_policyrow        = feedback[0].policyrisk_policyrow
            this.form.policyrisk_riskcovid        = feedback[0].policyrisk_riskcovid
            this.form.policyrisk_riskcovdesc      = feedback[0].policyrisk_riskcovdesc
            this.form.policyrisk_desc             = feedback[0].policyrisk_desc
            this.form.policyrisk_rate             = feedback[0].policyrisk_rate
            this.form.policyrisk_rateunit         = feedback[0].policyrisk_rateunit
            this.form.policyrisk_rateloading      = feedback[0].policyrisk_rateloading
            this.form.policyrisk_category         = feedback[0].policyrisk_category
            this.form.policyrisk_sdate            = feedback[0].policyrisk_sdate
            this.form.policyrisk_edate            = feedback[0].policyrisk_edate
            this.form.policyrisk_passenger        = feedback[0].policyrisk_passenger
            this.form.policyrisk_ratediscount     = feedback[0].policyrisk_ratediscount
            this.form.policyrisk_ratediscountdesc = feedback[0].policyrisk_ratediscountdesc
            this.form.policyrisk_ratediscountpct  = feedback[0].policyrisk_ratediscountpct
            this.form.policyrisk_voyagefrom       = feedback[0].policyrisk_voyagefrom
            this.form.policyrisk_voyageto         = feedback[0].policyrisk_voyageto
            this.form.policyrisk_voyageestdepdate = feedback[0].policyrisk_voyageestdepdate
            this.form.policyrisk_voyageestarrdate = feedback[0].policyrisk_voyageestarrdate
            this.form.policyrisk_voyagevessel     = feedback[0].policyrisk_voyagevessel

            this.$refs.policyrisk_rate.$el.getElementsByTagName("input")[0].value             = feedback[0].policyrisk_rate
            this.$refs.policyrisk_rateloading.$el.getElementsByTagName("input")[0].value      = feedback[0].policyrisk_rateloading
            this.$refs.policyrisk_passenger.$el.getElementsByTagName("input")[0].value        = feedback[0].policyrisk_passenger
            this.$refs.policyrisk_ratediscountpct.$el.getElementsByTagName("input")[0].value  = feedback[0].policyrisk_ratediscountpct

            this.form.policyrisk_riskcovid_par  = feedback[0].policyrisk_riskcovid

            this.form.policyrisk_cdate = feedback[0].policyrisk_cdate
            this.form.policyrisk_cuser = feedback[0].policyrisk_cuser
            this.form.policyrisk_ldate = feedback[0].policyrisk_ldate
            this.form.policyrisk_luser = feedback[0].policyrisk_luser
            this.dialog.policyrisk = true
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", feedback, 0)
        }
        this.loading.policyriskdialog = false
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog           = true
        this.remarks.feedback         = e
        this.loading.policyriskdialog = false
      })
  },

  DeletePolicyRisk(row, id) {
    let formdata = {
      url_type              : "deletepolicyrisk",
      policyrisk_policyrow  : row,
      policyrisk_riskcovid  : id,
    };
    let param = this.$functions.ParamPOST(formdata);
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyRisk"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", "Delete Success", 3000)
            this.confirm.dialog         = false
            this.confirm.text           = "Ok"
            this.policyrisk.selected  = []
            this.ListPolicyRisk(this.form.campaign_id)
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
  },

  MultiProcessPolicyRisk() {
    let multiData = JSON.stringify(this.policyrisk.selected)
    let formdata = {
      url_type: this.url_type,
      multi_data: multiData,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyRisk"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === "Y") {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed                  = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog   = true
          }
          this.SnackBar(true, "success", feed, 0)
          this.confirm.dialog       = false
          this.confirm.text         = "Ok"
          this.policyrisk.selected  = []
          if (this.url_type === "multideletepolicyrisk") {
            this.ListPolicyRisk(this.form.campaign_id)
          }
        } else {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  GetRateDiscountPct(value) {
    if (value !== undefined) {
      let splits = this.$functions.Split(value, "|");
      let ujroh = splits[3] * 1;
      this.form.policyrisk_ratediscountpct = ujroh.toFixed(2);
      this.$refs.policyrisk_ratediscountpct.$el.getElementsByTagName(
        "input"
      )[0].value = ujroh.toFixed(2);
    }
  },

  ClearCBPolicyRisk() {
    this.form.policyrisk_riskcovid        = ''
    this.form.policyrisk_riskcovdesc      = ''
    this.form.policyrisk_ratediscount     = ''
    this.form.policyrisk_ratediscountdesc = ''
  },
  // end: Function Policy Risk

  // Function Policy Interest
  SelectAllPolicyInt() {
    this.policyint.selected =
    this.policyint.selected === this.policyint.items ? [] : this.policyint.items;
  },

  ListPolicyInt(row) {
    this.loading.listpolicyint = true;
    let formdata = {
      policyint_policyrow : row,
      order_by            : "policyint_id",
      order_type          : "ASC",
      limit               : this.limit,
    };
    let param = this.$functions.ParamPOST(formdata);
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyInt"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data;
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(true, "error", feedback[0].feedback, 0);
          } else {
            this.policyint.items = feedback;
          }
        } else {
          this.policyint.items = feedback;
          this.SnackBar(true, "error", this.$functions.NoData(), 0);
        }
        this.loading.listpolicyint = false;
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000);
        this.loading.listpolicyint = false;
      });
  },

  CheckPolicyInt(value) {
    this.policyint.selected = value;
  },

  SearchPolicyInt() {
    this.policyint.searchbutton = true;
    setTimeout(function () {
      document.getElementById("searchpolicyint").focus();
    }, 500);
  },

  OpenPolicyIntIndex() {
    this.dialog.policyint_index = true;
    this.ListPolicyInt(this.form.policy_row)
  },

  OpenPolicyInt() {
    this.dialog.policyint = true;
    this.AddPolicyInt();
  },

  AddPolicyInt() {    
    this.url_type                           = 'addpolicyint'
    this.form.policyint_id                  = ''
    this.form.policyint_desc                = ''
    this.form.policyint_category            = ''
    this.form.policyint_remarks             = ''
    this.form.policyint_adjustable          = 0
    this.form.policyint_currencyoc          = ''
    this.form.policyint_currency            = ''
    this.form.policyint_limitofliabilityoc  = 'IDR'
    this.form.policyint_tsioc               = 0
    this.form.policyint_aggregateoc         = 0
    this.form.policyint_limitofliability    = 'IDR'
    this.form.policyint_tsi                 = 0
    this.form.policyint_aggregate           = 0
    this.form.policyint_firstloss           = 0
    this.form.policyint_adjustablerate      = 0
    this.form.policyint_sectionid           = ''
    this.form.policyint_sectiondesc         = ''
    this.form.policyint_policyrow           = this.form.policy_row

    // this.flag.policyint    = true
    this.dialog.policyint  = true

    this.$refs.policyint_tsioc.$el.getElementsByTagName("input")[0].value               = 0
    this.$refs.policyint_aggregateoc.$el.getElementsByTagName("input")[0].value         = 0
    this.$refs.policyint_tsi.$el.getElementsByTagName("input")[0].value                 = 0
    this.$refs.policyint_aggregate.$el.getElementsByTagName("input")[0].value           = 0
    this.$refs.policyint_adjustablerate.$el.getElementsByTagName("input")[0].value      = 0
  },

  WritePolicyInt() {
    let intid       = document.getElementById("policyint_id").value
    let sectionid   = document.getElementById("policyint_sectionid").value
    let currency    = document.getElementById("policyint_currency").value
    let currencyoc  = document.getElementById("policyint_currencyoc").value
    let adjustable
    let firstloss

    if (this.form.policyint_adjustable == 1) {
      adjustable = "Y"
    } else {
      adjustable = "N"
    }

    if (this.form.policyint_firstloss == 1) {
      firstloss = "Y"
    } else {
      firstloss = "N"
    }
    let formdata = {
      url_type                      : this.url_type,
      policyint_policyrow           : this.form.policy_row,
      policyint_id                  : intid,
      policyint_remarks             : this.form.policyint_remarks,
      policyint_sectionid           : sectionid,
      policyint_category            : this.form.policyint_category,
      policyint_currency            : currency,
      policyint_currencyoc          : currencyoc,
      policyint_limitofliability    : this.form.policyint_limitofliability,
      policyint_tsi                 : this.form.policyint_tsi,
      policyint_aggregate           : this.form.policyint_aggregate,
      policyint_limitofliabilityoc  : this.form.policyint_limitofliabilityoc,
      policyint_tsioc               : this.form.policyint_tsioc,
      policyint_aggregateoc         : this.form.policyint_aggregateoc,
      policyint_adjustable          : adjustable,
      policyint_firstloss           : firstloss,
      policyint_adjustablerate      : this.form.policyint_adjustablerate,
      policyint_id_par              : this.form.policyint_id_par
    };
    console.log('formdata', formdata)
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyInt"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", "Save Success", 3000)
            this.confirm.dialog = false
            this.confirm.text = "Ok"
            if (this.url_type === "addpolicyint") {
              this.form.policyint_cuser = feedback[0].feedback_users_id
              this.form.policyint_cdate = feedback[0].feedback_users_date
            }
            this.form.policyint_luser = feedback[0].feedback_users_id
            this.form.policyint_ldate = feedback[0].feedback_users_date
            this.url_type = "editpolicyint"
            this.dialog.policyint = false
            this.ListPolicyInt(this.form.policy_row)
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
  },

  EditPolicyInt(row, id) {
    this.ClearCBPolicyInt()

    // this.flag.policyint          = true
    this.dialog.policyint        = true
    this.loading.policyintdialog = true
    let formdata = {
      policyint_policyrow : row,
      policyint_id        : id,
      order_by            : 'policyint_id',
      order_type          : 'ASC',
      limit               : this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiListPolicyInt'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.url_type = 'editpolicyint'

            this.form.policyint_policyrow           = feedback[0].policyint_policyrow
            this.form.policyint_id                  = feedback[0].policyint_id
            this.form.policyint_desc                = feedback[0].policyint_desc
            this.form.policyint_sectionid           = feedback[0].policyint_sectionid
            this.form.policyint_sectiondesc         = feedback[0].policyint_sectiondesc
            this.form.policyint_category            = feedback[0].policyint_category
            this.form.policyint_remarks             = feedback[0].policyint_remarks
            this.form.policyint_adjustable          = this.$functions.YesOrNo(feedback[0].policyint_adjustable)
            this.form.policyint_firstloss           = this.$functions.YesOrNo( feedback[0].policyint_firstloss)
            this.form.policyint_currencyoc          = feedback[0].policyint_currencyoc
            this.form.policyint_currencyoc_desc     = feedback[0].policyint_currencyoc_desc
            this.form.policyint_currency            = feedback[0].policyint_currency
            this.form.policyint_currency_desc       = feedback[0].policyint_currency_desc
            this.form.policyint_limitofliabilityoc  = feedback[0].policyint_limitofliabilityoc
            this.form.policyint_tsioc               = feedback[0].policyint_tsioc
            this.form.policyint_aggregateoc         = feedback[0].policyint_aggregateoc
            this.form.policyint_limitofliability    = feedback[0].policyint_limitofliability
            this.form.policyint_tsi                 = feedback[0].policyint_tsi
            this.form.policyint_aggregate           = feedback[0].policyint_aggregate
            this.form.policyint_adjustablerate      = feedback[0].policyint_adjustablerate

            this.$refs.policyint_limitofliability.$el.getElementsByTagName("input")[0].value    = feedback[0].policyint_limitofliability
            this.$refs.policyint_tsi.$el.getElementsByTagName("input")[0].value                 = feedback[0].policyint_tsi
            this.$refs.policyint_aggregate.$el.getElementsByTagName("input")[0].value           = feedback[0].policyint_aggregate
            this.$refs.policyint_limitofliabilityoc.$el.getElementsByTagName("input")[0].value  = feedback[0].policyint_limitofliabilityoc
            this.$refs.policyint_tsioc.$el.getElementsByTagName("input")[0].value               = feedback[0].policyint_tsioc
            this.$refs.policyint_aggregateoc.$el.getElementsByTagName("input")[0].value         = feedback[0].policyint_aggregateoc
            this.$refs.policyint_adjustablerate.$el.getElementsByTagName("input")[0].value      = feedback[0].policyint_adjustablerate

            this.form.policyint_id_par = feedback[0].policyint_id

            this.form.policyint_cdate = feedback[0].policyint_cdate
            this.form.policyint_cuser = feedback[0].policyint_cuser
            this.form.policyint_ldate = feedback[0].policyint_ldate
            this.form.policyint_luser = feedback[0].policyint_luser
            this.dialog.policyint = true;
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', feedback, 0)
        }
        this.loading.policyintdialog = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.policyintdialog = false
      })
  },

  DeletePolicyInt(row, id) {
    let formdata = {
      url_type            : 'deletepolicyint',
      policyint_policyrow : row,
      policyint_id        : id,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyInt'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog = false
            this.confirm.text   = 'Ok'
            this.policyint.selected = []
            this.ListPolicyInt(this.form.policy_row)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  MultiProcessPolicyInt() {
    let multiData = JSON.stringify(this.policyint.selected)
    let formdata = {
      url_type    : this.url_type,
      multi_data  : multiData,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyInt'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === 'Y') {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed                  = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog   = true
          }
          this.SnackBar(true, 'success', feed, 0)
          this.confirm.dialog     = false
          this.confirm.text       = 'Ok'
          this.policyint.selected = []
          if (this.url_type === 'multideletepolicyint') {
            this.ListPolicyInt(this.form.policy_row)
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      });
  },

  ClearCBPolicyInt() {
    this.form.policyint_id          = ''
    this.form.policyint_desc        = ''
    this.form.policyint_currency    = ''
    this.form.policyint_currencyoc  = ''
    this.form.policyint_sectionid   = ''
    this.form.policyint_sectiondesc = ''
  },

  LOLOnType() {    
    let limitofliability = document.getElementById("policyint_limitofliability").value

    this.form.policyint_tsi       = limitofliability
    this.form.policyint_aggregate = limitofliability

    this.$refs.policyint_tsi.$el.getElementsByTagName("input")[0].value       = limitofliability
    this.$refs.policyint_aggregate.$el.getElementsByTagName("input")[0].value = limitofliability
  },

  LOLOCOnType() {    
    let limitofliabilityoc  = this.form.policyint_limitofliabilityoc

    this.form.policyint_tsioc       = limitofliabilityoc
    this.form.policyint_aggregateoc = limitofliabilityoc

    this.$refs.policyint_tsioc.$el.getElementsByTagName("input")[0].value       = limitofliabilityoc
    this.$refs.policyint_aggregateoc.$el.getElementsByTagName("input")[0].value = limitofliabilityoc
  },
  // end: Function Policy Interest

  // Function Policy Deductible
  SelectAllPolicyDed() {
    this.policyded.selected =
    this.policyded.selected === this.policyded.items ? [] : this.policyded.items;
  },

  ListPolicyDed(row) {
    this.loading.listpolicyded = true;
    let formdata = {
      policyded_policyrow : row,
      order_by            : "policyded_id",
      order_type          : "ASC",
      limit               : this.limit,
    };
    let param = this.$functions.ParamPOST(formdata);
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyDed"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data;
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(true, "error", feedback[0].feedback, 0);
          } else {
            this.policyded.items = feedback;
          }
        } else {
          this.policyded.items = feedback;
          this.SnackBar(true, "error", this.$functions.NoData(), 0);
        }
        this.loading.listpolicyded = false;
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000);
        this.loading.listpolicyded = false;
      });
  },

  CheckPolicyDed(value) {
    this.policyded.selected = value;
  },

  SearchPolicyDed() {
    this.policyded.searchbutton = true;
    setTimeout(function () {
      document.getElementById("searchpolicyded").focus();
    }, 500);
  },

  OpenPolicyDedIndex() {
    this.dialog.policyded_index = true;
    this.ListPolicyDed(this.form.policy_row)
  },

  OpenPolicyDed() {
    this.dialog.policyded = true;
    this.AddPolicyDed();
  },

  AddPolicyDed() {
    this.url_type                     = 'addpolicyded'
    this.form.policyded_id            = ''
    this.form.policyded_withflag      = 0
    this.form.policyded_ccy           = ''
    this.form.policyded_forindemnity  = ''
    this.form.policyded_tsisipct      = 0
    this.form.policyded_tsisiflag     = 0
    this.form.policyded_claimpct      = 0
    this.form.policyded_amountmin     = 0
    this.form.policyded_amountmax     = 0
    this.form.policyded_remarkseng    = ''
    this.form.policyded_remarksind    = ''
    this.form.policyded_totallossflag = 0
    this.form.policyded_actived       = true
    this.form.policyded_policyrow     = this.form.policy_row

    // this.flag.policyded    = true
    this.dialog.policyded  = true

    this.$refs.policyded_tsisipct.$el.getElementsByTagName("input")[0].value  = 0
    this.$refs.policyded_claimpct.$el.getElementsByTagName("input")[0].value  = 0
    this.$refs.policyded_amountmin.$el.getElementsByTagName("input")[0].value = 0
    this.$refs.policyded_amountmax.$el.getElementsByTagName("input")[0].value = 0
  },

  WritePolicyDed() {
    let deductible    = document.getElementById("policyded_id").value
    let ccy           = document.getElementById("policyded_ccy").value
    let forindemnity  = document.getElementById("policyded_forindemnity").value
    let withflag
    let totallossflag
    let tsisiflag

    if (this.form.policyded_withflag == 1) {
      withflag = "Y"
    } else {
      withflag = "N"
    }

    if (this.form.policyded_totallossflag == 1) {
      totallossflag = "Y"
    } else {
      totallossflag = "N"
    }

    if (this.form.policyded_tsisiflag == 0) {
      tsisiflag = "SI"
    } else {
      tsisiflag = "TSI"
    }

    let formdata = {
      url_type                : this.url_type,
      policyded_policyrow     : this.form.policy_row,
      policyded_id            : deductible,
      policyded_withflag      : withflag,
      policyded_ccy           : ccy,
      policyded_forindemnity  : forindemnity,
      policyded_tsisipct      : this.form.policyded_tsisipct,
      policyded_tsisiflag     : tsisiflag,
      policyded_claimpct      : this.form.policyded_claimpct,
      policyded_amountmin     : this.form.policyded_amountmin,
      policyded_amountmax     : this.form.policyded_amountmax,
      policyded_remarkseng    : this.form.policyded_remarkseng,
      policyded_remarksind    : this.form.policyded_remarksind,
      policyded_totallossflag : totallossflag,
      policyded_actived       : this.$functions.ActivedFlag(this.form.policyded_actived),
      policyded_id_par        : this.form.policyded_id_par,
    };
    console.log('formdata', formdata)
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyDed"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", "Save Success", 3000)
            this.confirm.dialog = false
            this.confirm.text   = "Ok"
            if (this.url_type === "addpolicyded") {
              this.form.policyded_cuser = feedback[0].feedback_users_id
              this.form.policyded_cdate = feedback[0].feedback_users_date
            }
            this.form.policyded_luser = feedback[0].feedback_users_id
            this.form.policyded_ldate = feedback[0].feedback_users_date
            this.url_type             = "editpolicyded"
            this.dialog.policyded     = false
            this.ListPolicyDed(this.form.policy_row)
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  EditPolicyDed(row, id) {
    this.ClearCBPolicyDed()
    
    // this.flag.policyded          = true
    this.dialog.policyded        = true
    this.loading.policydeddialog = true
    let formdata = {
      policyded_policyrow : row,
      policyded_id        : id,
      order_by            : 'policyded_id',
      order_type          : 'ASC',
      limit               : this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiListPolicyDed'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data

        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.url_type = 'editpolicyded'

            let tsisiflag
            if (feedback[0].policyded_tsisiflag == "SI") {
              tsisiflag = 0
            } else {
              tsisiflag = 1
            }

            this.form.policyded_policyrow     = feedback[0].policyded_policyrow
            this.form.policyded_id            = feedback[0].policyded_id
            this.form.policyded_withflag      = this.$functions.YesOrNo(feedback[0].policyded_withflag)
            this.form.policyded_ccy           = feedback[0].policyded_ccy
            this.form.policyded_forindemnity  = feedback[0].policyded_forindemnity
            this.form.policyded_tsisipct      = this.$functions.FormatNumber(feedback[0].policyded_tsisipct, 2)
            this.form.policyded_tsisiflag     = tsisiflag
            this.form.policyded_claimpct      = this.$functions.FormatNumber(feedback[0].policyded_claimpct, 2)
            this.form.policyded_amountmin     = this.$functions.FormatNumber(feedback[0].policyded_amountmin, 4)
            this.form.policyded_amountmax     = this.$functions.FormatNumber(feedback[0].policyded_amountmax, 4)
            this.form.policyded_remarkseng    = feedback[0].policyded_remarkseng
            this.form.policyded_remarksind    = feedback[0].policyded_remarksind
            this.form.policyded_totallossflag = this.$functions.YesOrNo(feedback[0].policyded_totallossflag)
            this.form.policyded_actived       = this.$functions.ActivedFlag(feedback[0].policyded_actived)

            this.$refs.policyded_tsisipct.$el.getElementsByTagName("input")[0].value  = this.$functions.FormatNumber(feedback[0].policyded_tsisipct, 2)
            this.$refs.policyded_claimpct.$el.getElementsByTagName("input")[0].value  = this.$functions.FormatNumber(feedback[0].policyded_claimpct, 2)
            this.$refs.policyded_amountmin.$el.getElementsByTagName("input")[0].value = this.$functions.FormatNumber(feedback[0].policyded_amountmin, 4)
            this.$refs.policyded_amountmax.$el.getElementsByTagName("input")[0].value = this.$functions.FormatNumber(feedback[0].policyded_amountmax, 4)

            this.form.policyded_id_par = feedback[0].policyded_id

            this.form.policyded_cdate = feedback[0].policyded_cdate
            this.form.policyded_cuser = feedback[0].policyded_cuser
            this.form.policyded_ldate = feedback[0].policyded_ldate
            this.form.policyded_luser = feedback[0].policyded_luser
            this.dialog.policyded = true;
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', feedback, 0)
        }
        this.loading.policydeddialog = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.policydeddialog = false
      })
  },

  DeletePolicyDed(row, id) {
    let formdata = {
      url_type            : 'deletepolicyded',
      policyded_policyrow : row,
      policyded_id        : id,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyDed'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog     = false
            this.confirm.text       = 'Ok'
            this.policyded.selected = []
            this.ListPolicyDed(this.form.policy_row)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  MultiProcessPolicyDed() {
    let multiData = JSON.stringify(this.policyded.selected)
    let formdata = {
      url_type    : this.url_type,
      multi_data  : multiData,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyDed'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === 'Y') {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed                  = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog   = true
          }
          this.SnackBar(true, 'success', feed, 0)
          this.confirm.dialog     = false
          this.confirm.text       = 'Ok'
          this.policyded.selected = []
          if (this.url_type === 'multideletepolicyded') {
            this.ListPolicyDed(this.form.policy_row)
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      });
  },

  ClearCBPolicyDed() {
    this.form.policyded_id            = ''
    this.form.policyded_ccy           = ''
    this.form.policyded_forindemnity  = ''
  },

  GetDeductible(value) {
    this.loading.policydeddialog = true;
    if (value !== undefined) {
      let splits        = this.$functions.Split(value, "|");
      let deductible_id = splits[0];

      // Call Back API Deductible
      let formdata = {
        deductible_id : deductible_id,
        order_by      : "deductible_id",
        order_type    : "ASC",
        limit         : this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListDeductible"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.policyded_withflag      = this.$functions.YesOrNo(feedback[0].deductible_withflag)
              this.form.policyded_ccy           = feedback[0].deductible_ccy
              this.form.policyded_forindemnity  = feedback[0].deductible_forindemnity
              this.form.policyded_tsisipct      = this.$functions.FormatNumber(feedback[0].deductible_tsisipct, 2)
              this.form.policyded_tsisiflag     = feedback[0].deductible_tsisiflag
              this.form.policyded_claimpct      = this.$functions.FormatNumber(feedback[0].deductible_claimpct, 2)
              this.form.policyded_amountmin     = this.$functions.FormatNumber(feedback[0].deductible_amountmin, 4)
              this.form.policyded_amountmax     = this.$functions.FormatNumber(feedback[0].deductible_amountmax, 4)
              this.form.policyded_remarkseng    = feedback[0].deductible_remarkseng
              this.form.policyded_remarksind    = feedback[0].deductible_remarksind
              this.form.policyded_totallossflag = this.$functions.YesOrNo(feedback[0].deductible_totallossflag)
              this.form.policyded_actived       = this.$functions.YesOrNo(feedback[0].deductible_actived)

              this.$refs.policyded_tsisipct.$el.getElementsByTagName("input")[0].value  = this.$functions.FormatNumber(feedback[0].deductible_tsisipct, 2)
              this.$refs.policyded_claimpct.$el.getElementsByTagName("input")[0].value  = this.$functions.FormatNumber(feedback[0].deductible_claimpct, 2)
              this.$refs.policyded_amountmin.$el.getElementsByTagName("input")[0].value = this.$functions.FormatNumber(feedback[0].deductible_amountmin, 4)
              this.$refs.policyded_amountmax.$el.getElementsByTagName("input")[0].value = this.$functions.FormatNumber(feedback[0].deductible_amountmax, 4)

              // this.dialog.policyded = true
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
          this.loading.policydeddialog = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
          this.loading.policydeddialog = false
        })
    }
  },
  // end: Function Policy Deductible

  // Function Policy Fee & Duty
  SelectAllPolicyFeeDuty() {
    this.policyfeeduty.selected =
    this.policyfeeduty.selected === this.policyfeeduty.items ? [] : this.policyfeeduty.items;
  },

  ListPolicyFeeDuty(row) {
    this.loading.listpolicyfeeduty = true;
    let formdata = {
      policyfeeduty_policyrow : row,
      order_by                : "policyfeeduty_id",
      order_type              : "ASC",
      limit                   : this.limit,
    };
    let param = this.$functions.ParamPOST(formdata);
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyFeeDuty"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data;
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(true, "error", feedback[0].feedback, 0);
          } else {
            this.policyfeeduty.items = feedback;
          }
        } else {
          this.policyfeeduty.items = feedback;
          this.SnackBar(true, "error", this.$functions.NoData(), 0);
        }
        this.loading.listpolicyfeeduty = false;
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000);
        this.loading.listpolicyfeeduty = false;
      });
  },

  CheckPolicyFeeDuty(value) {
    this.policyfeeduty.selected = value;
  },

  SearchPolicyFeeDuty() {
    this.policyfeeduty.searchbutton = true;
    setTimeout(function () {
      document.getElementById("searchpolicyfeeduty").focus();
    }, 500);
  },

  OpenPolicyFeeDutyIndex() {
    this.dialog.policyfeeduty_index = true;
    this.ListPolicyFeeDuty(this.form.policy_row)
  },

  OpenPolicyFeeDuty() {
    this.dialog.policyfeeduty = true;
    this.AddPolicyFeeDuty();
  },

  AddPolicyFeeDuty() {
    this.url_type                       = 'addpolicyfeeduty'
    this.form.policyfeeduty_id          = ""
    this.form.policyfeeduty_desc        = ""
    this.form.policyfeeduty_payorid     = ""
    this.form.policyfeeduty_payor_desc  = ""
    this.form.policyfeeduty_amount      = 0
    this.form.policyfeeduty_remarks     = ""
    this.form.policyfeeduty_policyrow   = this.form.policy_row

    // this.flag.policyfeeduty    = true
    this.dialog.policyfeeduty  = true

    this.$refs.policyfeeduty_amount.$el.getElementsByTagName("input")[0].value  = 0
  },

  WritePolicyFeeDuty() {
    let feedutyid = document.getElementById("policyfeeduty_id").value;
    let payorid   = document.getElementById("policyfeeduty_payorid").value;

    let formdata = {
      url_type                : this.url_type,
      policyfeeduty_policyrow : this.form.policy_row,
      policyfeeduty_id        : feedutyid,
      policyfeeduty_payorid   : payorid,
      policyfeeduty_amount    : this.form.policyfeeduty_amount,
      policyfeeduty_remarks   : this.form.policyfeeduty_remarks,
      policyfeeduty_id_par    : this.form.policyfeeduty_id_par,
    };
    console.log('formdata', formdata)
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyFeeDuty"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", "Save Success", 3000)
            this.confirm.dialog = false
            this.confirm.text   = "Ok"
            if (this.url_type === "addpolicyfeeduty") {
              this.form.policyfeeduty_cuser = feedback[0].feedback_users_id
              this.form.policyfeeduty_cdate = feedback[0].feedback_users_date
            }
            this.form.policyfeeduty_luser = feedback[0].feedback_users_id
            this.form.policyfeeduty_ldate = feedback[0].feedback_users_date
            this.url_type             = "editpolicyfeeduty"
            this.dialog.policyfeeduty = false
            this.ListPolicyFeeDuty(this.form.policy_row)
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  EditPolicyFeeDuty(row, id) {
    this.ClearCBPolicyFeeDuty()
    
    // this.flag.policyfeeduty          = true
    this.dialog.policyfeeduty        = true
    this.loading.policyfeedutydialog = true
    let formdata = {
      policyfeeduty_policyrow : row,
      policyfeeduty_id        : id,
      order_by                : 'policyfeeduty_id',
      order_type              : 'ASC',
      limit                   : this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiListPolicyFeeDuty'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data

        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.url_type = 'editpolicyfeeduty'

            this.form.policyfeeduty_policyrow   = feedback[0].policyfeeduty_policyrow
            this.form.policyfeeduty_id          = feedback[0].policyfeeduty_id
            this.form.policyfeeduty_desc        = feedback[0].policyfeeduty_desc
            this.form.policyfeeduty_payorid     = feedback[0].policyfeeduty_payorid
            this.form.policyfeeduty_payor_desc  = feedback[0].policyfeeduty_payor_desc
            this.form.policyfeeduty_amount      = feedback[0].policyfeeduty_amount
            this.form.policyfeeduty_remarks     = feedback[0].policyfeeduty_remarks

            this.$refs.policyfeeduty_amount.$el.getElementsByTagName("input")[0].value = feedback[0].policyfeeduty_amount

            this.form.policyfeeduty_id_par = feedback[0].policyfeeduty_id

            this.form.policyfeeduty_cdate = feedback[0].policyfeeduty_cdate
            this.form.policyfeeduty_cuser = feedback[0].policyfeeduty_cuser
            this.form.policyfeeduty_ldate = feedback[0].policyfeeduty_ldate
            this.form.policyfeeduty_luser = feedback[0].policyfeeduty_luser
            this.dialog.policyfeeduty = true
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', feedback, 0)
        }
        this.loading.policyfeedutydialog = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog               = true
        this.remarks.feedback             = e
        this.loading.policyfeedutydialog  = false
      })
  },

  DeletePolicyFeeDuty(row, id) {
    let formdata = {
      url_type                : 'deletepolicyfeeduty',
      policyfeeduty_policyrow : row,
      policyfeeduty_id        : id,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyFeeDuty'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog         = false
            this.confirm.text           = 'Ok'
            this.policyfeeduty.selected = []
            this.ListPolicyFeeDuty(this.form.policy_row)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  MultiProcessPolicyFeeDuty() {
    let multiData = JSON.stringify(this.policyfeeduty.selected)
    let formdata = {
      url_type    : this.url_type,
      multi_data  : multiData,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyFeeDuty'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === 'Y') {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed                  = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog   = true
          }
          this.SnackBar(true, 'success', feed, 0)
          this.confirm.dialog     = false
          this.confirm.text       = 'Ok'
          this.policyfeeduty.selected = []
          if (this.url_type === 'multideletepolicyfeeduty') {
            this.ListPolicyFeeDuty(this.form.policy_row)
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      });
  },

  ClearCBPolicyFeeDuty() {
    this.form.policyfeeduty_id          = ''
    this.form.policyfeeduty_desc        = ''
    this.form.policyfeeduty_payorid     = ''
    this.form.policyfeeduty_payor_desc  = ''
  },
  // end: Function Policy Fee & Duty

  // Function Policy Source
  SelectAllPolicySource() {
    this.policysource.selected =
    this.policysource.selected === this.policysource.items ? [] : this.policysource.items;
  },

  ListPolicySource(row) {
    this.loading.listpolicysource = true;
    let formdata = {
      policysource_policyrow  : row,
      order_by                : "policysource_profileid",
      order_type              : "ASC",
      limit                   : this.limit,
    };
    let param = this.$functions.ParamPOST(formdata);
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicySource"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data;
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(true, "error", feedback[0].feedback, 0);
          } else {
            this.policysource.items = feedback;
          }
        } else {
          this.policysource.items = feedback;
          this.SnackBar(true, "error", this.$functions.NoData(), 0);
        }
        this.loading.listpolicysource = false;
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000);
        this.loading.listpolicysource = false;
      });
  },

  CheckPolicySource(value) {
    this.policysource.selected = value;
  },

  SearchPolicySource() {
    this.policysource.searchbutton = true;
    setTimeout(function () {
      document.getElementById("searchpolicysource").focus();
    }, 500);
  },

  OpenPolicySourceIndex() {
    this.dialog.policysource_index = true;
    this.ListPolicySource(this.form.policy_row)
  },

  OpenPolicySource() {
    this.dialog.policysource = true;
    this.AddPolicySource();
  },

  AddPolicySource() {
    this.url_type                             = 'addpolicysource'
    this.form.policysource_profileid          = ''
    this.form.policysource_profile_desc       = ''
    this.form.policysource_type               = ''
    this.form.policysource_mainflag           = 0
    this.form.policysource_feepct             = 0
    this.form.policysource_discount           = 0
    this.form.policysource_netaftermemberflag = 0
    this.form.policysource_grosscommflag      = 0
    this.form.policysource_memberfeeflag      = 0
    this.form.policysource_feeamt             = 0
    this.form.policysource_tax                = 0
    this.form.policysource_vat                = 0
    this.form.policysource_addfeepct          = 0
    this.form.policysource_policyrow          = this.form.policy_row

    // this.flag.policysource    = true
    this.dialog.policysource  = true

    this.$refs.policysource_feepct.$el.getElementsByTagName('input')[0].value     = 0
    this.$refs.policysource_discount.$el.getElementsByTagName('input')[0].value   = 0
    this.$refs.policysource_feeamt.$el.getElementsByTagName('input')[0].value     = 0
    this.$refs.policysource_tax.$el.getElementsByTagName('input')[0].value        = 0
    this.$refs.policysource_vat.$el.getElementsByTagName('input')[0].value        = 0
    this.$refs.policysource_addfeepct.$el.getElementsByTagName('input')[0].value  = 0
  },

  WritePolicySource() {
    let profileid = document.getElementById("policysource_profileid").value
    let type = document.getElementById("policysource_type").value
    let mainflag
    let netaftermemberflag
    let grosscommflag
    let memberfeeflag

    if (this.form.policysource_mainflag == 1) {
      mainflag = "Y";
    } else {
      mainflag = "N";
    }

    if (this.form.policysource_netaftermemberflag == 1) {
      netaftermemberflag = "Y";
    } else {
      netaftermemberflag = "N";
    }

    if (this.form.policysource_grosscommflag == 1) {
      grosscommflag = "Y";
    } else {
      grosscommflag = "N";
    }

    if (this.form.policysource_memberfeeflag == 1) {
      memberfeeflag = "Y";
    } else {
      memberfeeflag = "N";
    }

    let formdata = {
      url_type                        : this.url_type,
      policysource_policyrow          : this.form.policy_row,
      policysource_profileid          : profileid,
      policysource_type               : type,
      policysource_mainflag           : mainflag,
      policysource_feepct             : this.form.policysource_feepct,
      policysource_discount           : this.form.policysource_discount,
      policysource_netaftermemberflag : netaftermemberflag,
      policysource_grosscommflag      : grosscommflag,
      policysource_memberfeeflag      : memberfeeflag,
      policysource_feeamt             : this.form.policysource_feeamt,
      policysource_tax                : this.form.policysource_tax,
      policysource_vat                : this.form.policysource_vat,
      policysource_addfeepct          : this.form.policysource_addfeepct,
      policysource_profileid_par      : this.form.policysource_profileid_par,
      policysource_type_par           : this.form.policysource_type_par
    };
    console.log('formdata', formdata)
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicySource"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", "Save Success", 3000)
            this.confirm.dialog = false
            this.confirm.text   = "Ok"
            if (this.url_type === "addpolicysource") {
              this.form.policysource_cuser = feedback[0].feedback_users_id
              this.form.policysource_cdate = feedback[0].feedback_users_date
            }
            this.form.policysource_luser = feedback[0].feedback_users_id
            this.form.policysource_ldate = feedback[0].feedback_users_date
            this.url_type             = "editpolicysource"
            this.dialog.policysource = false
            this.ListPolicySource(this.form.policy_row)
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  EditPolicySource(row, profileid, type) {
    this.ClearCBPolicySource()
    
    // this.flag.policysource          = true
    this.dialog.policysource        = true
    this.loading.policysourcedialog = true
    let formdata = {
      policysource_policyrow  : row,
      policysource_profileid  : profileid,
      policysource_type       : type,
      order_by                : 'policysource_profileid',
      order_type              : 'ASC',
      limit                   : this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiListPolicySource'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data

        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.url_type = 'editpolicysource'

            this.form.policysource_policyrow          = feedback[0].policysource_policyrow
            this.form.policysource_profileid          = feedback[0].policysource_profileid
            this.form.policysource_profile_desc       = feedback[0].policysource_profile_desc
            this.form.policysource_type               = feedback[0].policysource_type
            this.form.policysource_mainflag           = this.$functions.YesOrNo(feedback[0].policysource_mainflag)
            this.form.policysource_feepct             = feedback[0].policysource_feepct
            this.form.policysource_discount           = feedback[0].policysource_discount
            this.form.policysource_netaftermemberflag = this.$functions.YesOrNo(feedback[0].policysource_netaftermemberflag)
            this.form.policysource_grosscommflag      = this.$functions.YesOrNo(feedback[0].policysource_grosscommflag)
            this.form.policysource_memberfeeflag      = this.$functions.YesOrNo(feedback[0].policysource_memberfeeflag)
            this.form.policysource_feeamt             = feedback[0].policysource_feeamt
            this.form.policysource_tax                = feedback[0].policysource_tax
            this.form.policysource_vat                = feedback[0].policysource_vat
            this.form.policysource_addfeepct          = feedback[0].policysource_addfeepct

            this.$refs.policysource_feepct.$el.getElementsByTagName('input')[0].value     = feedback[0].policysource_feepct
            this.$refs.policysource_discount.$el.getElementsByTagName('input')[0].value   = feedback[0].policysource_discount
            this.$refs.policysource_feeamt.$el.getElementsByTagName('input')[0].value     = feedback[0].policysource_feeamt
            this.$refs.policysource_tax.$el.getElementsByTagName('input')[0].value        = feedback[0].policysource_tax
            this.$refs.policysource_vat.$el.getElementsByTagName('input')[0].value        = feedback[0].policysource_vat
            this.$refs.policysource_addfeepct.$el.getElementsByTagName('input')[0].value  = feedback[0].policysource_addfeepct

            this.form.policysource_profileid_par  = feedback[0].policysource_profileid
            this.form.policysource_type_par       = feedback[0].policysource_type

            this.form.policysource_cdate = feedback[0].policysource_cdate
            this.form.policysource_cuser = feedback[0].policysource_cuser
            this.form.policysource_ldate = feedback[0].policysource_ldate
            this.form.policysource_luser = feedback[0].policysource_luser
            this.dialog.policysource = true
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', feedback, 0)
        }
        this.loading.policysourcedialog = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog             = true
        this.remarks.feedback           = e
        this.loading.policysourcedialog = false
      })
  },

  DeletePolicySource(row, profileid, type) {
    let formdata = {
      url_type                : 'deletepolicysource',
      policysource_policyrow  : row,
      policysource_profileid  : profileid,
      policysource_type       : type,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicySource'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog         = false
            this.confirm.text           = 'Ok'
            this.policysource.selected  = []
            this.ListPolicySource(this.form.policy_row)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  MultiProcessPolicySource() {
    let multiData = JSON.stringify(this.policysource.selected)
    let formdata = {
      url_type    : this.url_type,
      multi_data  : multiData,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicySource'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === 'Y') {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed                  = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog   = true
          }
          this.SnackBar(true, 'success', feed, 0)
          this.confirm.dialog     = false
          this.confirm.text       = 'Ok'
          this.policysource.selected = []
          if (this.url_type === 'multideletepolicysource') {
            this.ListPolicySource(this.form.policy_row)
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      });
  },

  ClearCBPolicySource() {
    this.form.policysource_profileid    = ''
    this.form.policysource_profile_desc = ''
  },

  GetBizSource(value) {
    if (value !== undefined) {
      let splits = this.$functions.Split(value, "|");
      let tax = splits[3] * 1;
      let vat = splits[4] * 1;
      this.form.policysource_tax = tax.toFixed(2);
      this.$refs.policysource_tax.$el.getElementsByTagName("input")[0].value = tax.toFixed(2);
      this.form.policysource_vat = vat.toFixed(2);
      this.$refs.policysource_vat.$el.getElementsByTagName("input")[0].value = vat.toFixed(2);
    }
  },
  // end: Function Policy Source

  // Function Policy Term & Condition
  SelectAllPolicyTC() {
    this.policytc.selected =
    this.policytc.selected === this.policytc.items ? [] : this.policytc.items;
  },

  ListPolicyTC(row) {
    this.loading.listpolicytc = true;
    let formdata = {
      policytc_policyrow  : row,
      order_by            : "policytc_id",
      order_type          : "ASC",
      limit               : this.limit,
    };
    let param = this.$functions.ParamPOST(formdata);
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyTC"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data;
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(true, "error", feedback[0].feedback, 0);
          } else {
            this.policytc.items = feedback;
          }
        } else {
          this.policytc.items = feedback;
          this.SnackBar(true, "error", this.$functions.NoData(), 0);
        }
        this.loading.listpolicytc = false;
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000);
        this.loading.listpolicytc = false;
      });
  },

  CheckPolicyTC(value) {
    this.policytc.selected = value;
  },

  SearchPolicyTC() {
    this.policytc.searchbutton = true;
    setTimeout(function () {
      document.getElementById("searchpolicytc").focus();
    }, 500);
  },

  OpenPolicyTCIndex() {
    this.dialog.policytc_index = true;
    this.ListPolicyTC(this.form.policy_row)
  },

  OpenPolicyTC() {
    this.dialog.policytc = true;
    this.AddPolicyTC();
  },

  AddPolicyTC() {
    this.url_type                     = 'addpolicytc'
    this.form.policytc_id             = ''
    this.form.policytc_classid        = ''
    this.form.policytc_shortdesc_eng  = ''
    this.form.policytc_longdesc_eng   = ''
    this.form.policytc_shortdesc_ind  = ''
    this.form.policytc_longdesc_ind   = ''
    this.form.policytc_policyrow      = this.form.policy_row

    // this.flag.policytc    = true
    this.dialog.policytc  = true
  },

  WritePolicyTC() {
    let formdata = {
      url_type                : this.url_type,
      policytc_policyrow      : this.form.policy_row,
      policytc_id             : this.form.policytc_id,
      policytc_classid        : this.form.policytc_classid,
      policytc_shortdesc_eng  : this.form.policytc_shortdesc_eng,
      policytc_longdesc_eng   : this.form.policytc_longdesc_eng,
      policytc_shortdesc_ind  : this.form.policytc_shortdesc_ind,
      policytc_longdesc_ind   : this.form.policytc_longdesc_ind,
      policytc_id_par         : this.form.policytc_id_par,
      policytc_classid_par    : this.form.policytc_classid_par
    };
    console.log('formdata', formdata)
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyTC"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", "Save Success", 3000)
            this.confirm.dialog = false
            this.confirm.text   = "Ok"
            if (this.url_type === "addpolicytc") {
              this.form.policytc_cuser = feedback[0].feedback_users_id
              this.form.policytc_cdate = feedback[0].feedback_users_date
            }
            this.form.policytc_luser = feedback[0].feedback_users_id
            this.form.policytc_ldate = feedback[0].feedback_users_date
            this.url_type             = "editpolicytc"
            this.dialog.policytc = false
            this.ListPolicyTC(this.form.policy_row)
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  EditPolicyTC(row, id, classid) {
    this.ClearCBPolicyTC()
    
    // this.flag.policytc          = true
    this.dialog.policytc        = true
    this.loading.policytcdialog = true
    let formdata = {
      policytc_policyrow  : row,
      policytc_id         : id,
      policyntc_classid   : classid,
      order_by            : 'policytc_id',
      order_type          : 'ASC',
      limit               : this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiListPolicyTC'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data

        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.url_type = 'editpolicytc'

            this.form.policytc_policyrow      = feedback[0].policytc_policyrow
            this.form.policytc_id             = feedback[0].policytc_id;
            this.form.policytc_classid        = feedback[0].policytc_classid;
            this.form.policytc_shortdesc_eng  = feedback[0].policytc_shortdesc_eng;
            this.form.policytc_longdesc_eng   = feedback[0].policytc_longdesc_eng;
            this.form.policytc_shortdesc_ind  = feedback[0].policytc_shortdesc_ind;
            this.form.policytc_longdesc_ind   = feedback[0].policytc_longdesc_ind;

            this.form.policytc_id_par       = feedback[0].policytc_id;
            this.form.policytc_classid_par  = feedback[0].policytc_classid;

            this.form.policytc_cdate = feedback[0].policytc_cdate
            this.form.policytc_cuser = feedback[0].policytc_cuser
            this.form.policytc_ldate = feedback[0].policytc_ldate
            this.form.policytc_luser = feedback[0].policytc_luser
            this.dialog.policytc = true
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', feedback, 0)
        }
        this.loading.policytcdialog = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog             = true
        this.remarks.feedback           = e
        this.loading.policytcdialog = false
      })
  },

  DeletePolicyTC(row, id, classid) {
    let formdata = {
      url_type            : 'deletepolicytc',
      policytc_policyrow  : row,
      policytc_id         : id,
      policytc_classid    : classid,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyTC'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog     = false
            this.confirm.text       = 'Ok'
            this.policytc.selected  = []
            this.ListPolicyTC(this.form.policy_row)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  MultiProcessPolicyTC() {
    let multiData = JSON.stringify(this.policytc.selected)
    let formdata = {
      url_type    : this.url_type,
      multi_data  : multiData,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyTC'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === 'Y') {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed                  = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog   = true
          }
          this.SnackBar(true, 'success', feed, 0)
          this.confirm.dialog     = false
          this.confirm.text       = 'Ok'
          this.policytc.selected  = []
          if (this.url_type === 'multideletepolicytc') {
            this.ListPolicyTC(this.form.policy_row)
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      });
  },

  ClearCBPolicyTC() {
    this.form.policytc_id    = ''
  },

  GetTC(value) {
    this.loading.policytcdialog = true;
    if (value !== undefined) {
      let splits = this.$functions.Split(value, "|");
      let classtc_id = splits[0];

      // Call Back API Class TC
      let formdata = {
        classtc_id  : classtc_id,
        order_by    : "classtc_id",
        order_type  : "ASC",
        limit       : this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListClassTC"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.policytc_id             = feedback[0].classtc_id;
              this.form.policytc_classid        = feedback[0].classtc_classid;
              this.form.policytc_shortdesc_eng  = feedback[0].classtc_shortdesc_eng;
              this.form.policytc_longdesc_eng   = feedback[0].classtc_longdesc_eng;
              this.form.policytc_shortdesc_ind  = feedback[0].classtc_shortdesc_ind;
              this.form.policytc_longdesc_ind   = feedback[0].classtc_longdesc_ind;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.policytcdialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.policytcdialog = false;
        });
    }
  },
  // end: Function Policy Term & Condition

  // Function Policy Depreciation
  SelectAllPolicyDepreciation() {
    this.policydepreciation.selected =
    this.policydepreciation.selected === this.policydepreciation.items ? [] : this.policydepreciation.items;
  },

  ListPolicyDepreciation(row) {
    this.loading.listpolicydepreciation = true;
    let formdata = {
      policydepreciation_policyrow  : row,
      order_by                      : "policydepreciation_yearnumber",
      order_type                    : "ASC",
      limit                         : this.limit,
    };
    let param = this.$functions.ParamPOST(formdata);
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyDepreciation"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data;
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(true, "error", feedback[0].feedback, 0);
          } else {
            this.policydepreciation.items = feedback;
          }
        } else {
          this.policydepreciation.items = feedback;
          this.SnackBar(true, "error", this.$functions.NoData(), 0);
        }
        this.loading.listpolicydepreciation = false;
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000);
        this.loading.listpolicydepreciation = false;
      });
  },

  CheckPolicyDepreciation(value) {
    this.policydepreciation.selected = value;
  },

  SearchPolicyDepreciation() {
    this.policydepreciation.searchbutton = true;
    setTimeout(function () {
      document.getElementById("searchpolicydepreciation").focus();
    }, 500);
  },

  OpenPolicyDepreciationIndex() {
    this.dialog.policydepreciation_index = true;
    this.ListPolicyDepreciation(this.form.policy_row)
  },

  OpenPolicyDepreciation() {
    this.dialog.policydepreciation = true;
    this.AddPolicyDepreciation();
  },

  AddPolicyDepreciation() {
    this.url_type                           = 'addpolicydepreciation'
    this.form.policydepreciation_yearnumber = ''
    this.form.policydepreciation_pct        = ''
    this.form.policydepreciation_actived    = ''
    this.form.policydepreciation_policyrow  = this.form.policy_row

    // this.flag.policydepreciation    = true
    this.dialog.policydepreciation  = true
  },

  WritePolicyDepreciation() {
    let formdata = {
      url_type                          : this.url_type,
      policydepreciation_policyrow      : this.form.policy_row,
      policydepreciation_yearnumber     : this.form.policydepreciation_yearnumber,
      policydepreciation_pct            : this.form.policydepreciation_pct,
      policydepreciation_actived        : this.$functions.ActivedFlag(this.form.policydepreciation_actived),
      policydepreciation_yearnumber_par : this.form.policydepreciation_yearnumber_par,
    };
    console.log('formdata', formdata)
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyDepreciation"), param, {
        headers: {
          "Content-Type": "text/plain; charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", "Save Success", 3000)
            this.confirm.dialog = false
            this.confirm.text   = "Ok"
            if (this.url_type === "addpolicydepreciation") {
              this.form.policydepreciation_cuser = feedback[0].feedback_users_id
              this.form.policydepreciation_cdate = feedback[0].feedback_users_date
            }
            this.form.policydepreciation_luser = feedback[0].feedback_users_id
            this.form.policydepreciation_ldate = feedback[0].feedback_users_date
            this.url_type             = "editpolicydepreciation"
            this.dialog.policydepreciation = false
            this.ListPolicyDepreciation(this.form.policy_row)
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  EditPolicyDepreciation(row, id) {
    this.ClearCBPolicyDepreciation()
    
    // this.flag.policydepreciation          = true
    this.dialog.policydepreciation        = true
    this.loading.policydepreciationdialog = true
    let formdata = {
      policydepreciation_policyrow  : row,
      policydepreciation_yearnumber : id,
      order_by                      : 'policydepreciation_yearnumber',
      order_type                    : 'ASC',
      limit                         : this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiListPolicyDepreciation'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data

        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.url_type = 'editpolicydepreciation'

            this.form.policydepreciation_policyrow  = feedback[0].policydepreciation_policyrow
            this.form.policydepreciation_yearnumber = feedback[0].policydepreciation_yearnumber
            this.form.policydepreciation_pct        = feedback[0].policydepreciation_pct
            this.form.policydepreciation_actived    = this.$functions.TrueOrFalse(feedback[0].policydepreciation_actived)

            this.$refs.policydepreciation_yearnumber.$el.getElementsByTagName("input")[0].value = feedback[0].policydepreciation_yearnumber
            this.$refs.policydepreciation_pct.$el.getElementsByTagName("input")[0].value        = feedback[0].policydepreciation_pct

            this.form.policydepreciation_yearnumber_par = feedback[0].policydepreciation_yearnumber

            this.form.policydepreciation_cdate = feedback[0].policydepreciation_cdate
            this.form.policydepreciation_cuser = feedback[0].policydepreciation_cuser
            this.form.policydepreciation_ldate = feedback[0].policydepreciation_ldate
            this.form.policydepreciation_luser = feedback[0].policydepreciation_luser

            this.dialog.policydepreciation = true
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', feedback, 0)
        }
        this.loading.policydepreciationdialog = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog             = true
        this.remarks.feedback           = e
        this.loading.policydepreciationdialog = false
      })
  },

  DeletePolicyDepreciation(row, yearnumber) {
    let formdata = {
      url_type                      : 'deletepolicydepreciation',
      policydepreciation_policyrow  : row,
      policydepreciation_yearnumber : yearnumber,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyDepreciation'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog     = false
            this.confirm.text       = 'Ok'
            this.policydepreciation.selected  = []
            this.ListPolicyDepreciation(this.form.policy_row)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      })
  },

  MultiProcessPolicyDepreciation() {
    let multiData = JSON.stringify(this.policydepreciation.selected)
    let formdata = {
      url_type    : this.url_type,
      multi_data  : multiData,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST('apiWritePolicyDepreciation'), param, {
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === 'Y') {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed                  = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog   = true
          }
          this.SnackBar(true, 'success', feed, 0)
          this.confirm.dialog               = false
          this.confirm.text                 = 'Ok'
          this.policydepreciation.selected  = []
          if (this.url_type === 'multideletepolicydepreciation') {
            this.ListPolicyDepreciation(this.form.policy_row)
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog   = true
        this.remarks.feedback = e
      });
  },
  // end: Function Policy Depreciation

  GetListCode (value) {
    console.log('GET LIST CODE')
    let formdata = {
      code_group  : value,
      order_by    : 'code_id',
      order_type  : 'ASC',
      limit       : this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
    .post(this.$functions.UrlPOST('apiListCode'), param, {
      headers: {
        'Content-Type': 'text/plain; charset=ISO-8859-1',
      },
    })
    .then((response) => {
      let feedback = response.data
      if (feedback.length > 0) {
        if (feedback[0].feedback !== 'Y') {
          this.SnackBar(
            true,
            'error',
            feedback[0].feedback,
            0
          )
        } else {
          if (value === 'RATEUNIT') {
            this.policyrisk_rateunit  = feedback
          } else if (value === 'POLICYCATEGORY') {
            this.policyrisk_category  = feedback
          }
        }
      } else {
        this.SnackBar(true, 'error', this.$functions.NoData(), 0)
      }
    })
    .catch((e) => {
      this.SnackBar(true, 'error', e, 3000)
    })
  },

      SnackBar (dialog, color, text, timeout) {
        if (text !== 'No Data Available') {
          this.snackbar ={
            dialog: dialog,
            color: color,
            text: text,
            timeout: timeout
          }
          if (color ==='error') {
            this.confirm.dialog 	 =false
            this.confirm.text 	 	 ='Ok'
          }
        }
      },

      PolicyInfo () {
        let pp_campaign_id = document.getElementById('pp_campaign_id').value
        if (pp_campaign_id === '') {
          setTimeout(function(){
            document.getElementById('pp_campaign_id').focus()
          }, 500)
        } else {
          let formData = new FormData()
          formData.append('campaign_id', pp_campaign_id)
          this.$axios.post(this.$functions.SafeURL('apiListCampaign', ``), formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            if (response.data[0].feedback === 'Y') {
              this.pp_stepper = 2
              this.form.pp_campaign_no          = response.data[0].campaign_id
              this.form.pp_campaign_desc        = response.data[0].campaign_title
              this.form.pp_instype_id           = response.data[0].campaign_instype
              this.form.pp_instype_desc         = response.data[0].campaign_instype_desc
              this.form.pp_subclass_id          = response.data[0].campaign_subclass
              this.form.pp_subclass_desc        = response.data[0].campaign_subclass_desc
              this.form.pp_channel_id           = response.data[0].campaign_channel
              this.form.pp_channel_desc         = response.data[0].campaign_channel_desc
              this.form.pp_branch_id            = response.data[0].campaign_branch
              this.form.pp_branch_desc          = response.data[0].campaign_branch_desc
              this.form.pp_costcenter_id        = response.data[0].campaign_costcenter
              this.form.pp_costcenter_desc      = response.data[0].campaign_costcenter_desc
              this.form.pp_policyholder_id      = response.data[0].campaign_pholder
              this.form.pp_policyholder_name    = response.data[0].campaign_pholder_name
            } else {
          this.SnackBar(true, 'error', response.data[0].feedback, 3000)
        }
          })
          .catch(e => {
            this.confirm.dialog  	 = false
              this.confirm.ok_text 	 = 'Ok'
        this.confirm.ok_disabled = false
            this.SnackBar(true, 'error', e, 3000)
          })
        }
      },
      RiskCoverage () {
          this.loading.riskcoverage = true
          let formData = new FormData()
              this.$axios.post(this.$functions.SafeURL('apiListFeeDuty', ``), formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(response => {
                  if (response.data.length === 0) {
                        alert('No Data')
                  } else {
                      this.form.pp_tab_risk_coverage = response.data
                  }
                  this.loading.riskcoverage = false
              })
              .catch(e => {
                  console.log(e)
                  this.loading.riskcoverage = false
              })
      },
      OpenRisk () {
        this.risk.dialog.form = true
        // this.Risk.form.input.fieldfrom = policyid
        // this.Risk.form.input.fieldto = fieldto
        // this.ListRisk(id, fieldfrom, fieldto)
        this.AddRisk()
      },
      AddRisk () {
        this.url_type  = this.url_type
        this.risk.form.input.profile_id = ''
        this.risk.form.input.address = ''
        setTimeout(function(){
          document.getElementById('risk_from').focus()
        }, 500)
      },
      Benefit () {
          this.loading.benefit = true
          let formData = new FormData()
            this.$axios.post(this.$functions.SafeURL('apiListFeeDuty', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
                if (response.data.length === 0) {
                      alert('No Data')
                } else {
                    this.form.pp_tab_risk_benefit = response.data
                }
                this.loading.benefit = false
            })
            .catch(e => {
                console.log(e)
                this.loading.benefit = false
            })
      },
      ListBenefit (id, fieldfrom, fieldto) {
        this.mapvalue.table.items = []
        this.loading.value = true
        let formdata = {
          mapvalue_headerid: id,
          mapvalue_fieldfrom: fieldfrom,
          mapvalue_fieldto: fieldto,
          datavalue: this.mapvalue.table.search,
          order_type: 'ASC',
          limit: '100',
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListBenefit'),param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0)
              } else {
                this.mapvalue.table.items = feedback
              }
            }
            this.loading.value = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.value = false
        })
      },
      OpenBenefit () {
        this.benefit.dialog.form = true
        // this.benefit.form.input.fieldfrom = policyid
        // this.benefit.form.input.fieldto = fieldto
        // this.ListBenefit(id, fieldfrom, fieldto)
        this.AddBenefit()
      },
      AddBenefit () {
        this.url_type  = this.url_type
        this.benefit.form.input.profile_id = ''
        this.benefit.form.input.address = ''
        setTimeout(function(){
          document.getElementById('benefit_from').focus()
        }, 500)
      },
      Deductible () {
          this.loading.deductible = true
          let formData = new FormData()
              this.$axios.post(this.$functions.SafeURL('apiListFeeDuty', ``), formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(response => {
                  if (response.data.length === 0) {
                        alert('No Data')
                  } else {
                      this.form.pp_tab_risk_deductible = response.data
                  }
                  this.loading.deductible = false
              })
              .catch(e => {
                  console.log(e)
                  this.loading.deductible = false
              })
      },
      OpenDeductible () {
        this.deductible.dialog.form = true
        // this.Deductible.form.input.fieldfrom = policyid
        // this.Deductible.form.input.fieldto = fieldto
        // this.ListDeductible(id, fieldfrom, fieldto)
        this.AddDeductible()
      },
      AddDeductible () {
        this.url_type  = this.url_type
        this.deductible.form.input.profile_id = ''
        this.deductible.form.input.address = ''
        setTimeout(function(){
          document.getElementById('deductible_from').focus()
        }, 500)
      },
      BreadcrumbURL(url){
        if (url === 'Print'){
          // this.$router.push({name: 'print', params: { id: this.pp_campaign_id }})
          // this.dialog = true
        }
      },
      Preview (id, type) {
        let url
        if (type == '' || type == null || type == undefined) 
        {
          this.SnackBar(true, 'error', 'Please Select Document Type', 3000)
        } else {
          url = this.$functions.DocumentURL(type, `id=${id}&lang=${this.form.pp_leader_policy_date}&option=${this.selected.picked}&Layout=${this.selected.layout}&LayoutAttachment=${this.selected.layoutAttachment}`, 'pdf')
          this.selected.iframeURL = url
          if (this.selected.iframe == false ){
            this.selected.iframe = true
          } else {
            alert("c")
            this.selected.iframeURL = url
            document.getElementById('PDFPrevie').contentDocument.location.reload(true);
          }
          // window.open(url, '_blank')
        }
      },
      Export(id) {
        let formData = new FormData()
        formData.append('campaign_id', id)
            this.$axios.post(this.$functions.SafeURL('apiWriteExcel', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
                if (response.data.length === 0) {
                      alert('No Data')
                } else {
                    this.form.pp_tab_risk_benefit = response.data
                }
                this.loading.benefit = false
            })
            .catch(e => {
                console.log(e)
                this.loading.benefit = false
            })
      },
      Print (id, type, convert) {
        let url
        if (type == '' || type == null || type == undefined) 
        {
          this.SnackBar(true, 'error', 'Please Select Document Type', 3000)
        } else {
          url = this.$functions.DocumentURL(type, `id=${id}&lang=${this.form.pp_leader_policy_date}&option=${this.selected.picked}&Layout=${this.selected.layout}&LayoutAttachment=${this.selected.layoutAttachment}&ShowPremiumContent=${this.checked.premium}&ShowDiscount=${this.checked.discount}&Attachment=${this.checked.attachment}&SelectedClassess=${this.checked.selected}&DeferredPremiumClause=${this.checked.deferred}&PACard=${this.checked.pa}&WithHeaderAndFooter=${this.checked.headerfooter}`, convert)
          this.pdf = url
          window.open(url, '_blank')
        }
      },
      PolicySource () {
          this.loading.source = true
          let formData = new FormData()
              this.$axios.post(this.$functions.SafeURL('apiListProfile', `limit=1`), formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(response => {
                  if (response.data.length === 0) {
                        alert('No Data')
                  } else {
                      this.form.pp_tab_payor_items_source = response.data
                  }
                  this.loading.source = false
              })
              .catch(e => {
                  console.log(e)
                  this.loading.source = false
              })
      },
      Busines () {
          this.loading.feeduty = true
          let formData = new FormData()
              this.$axios.post(this.$functions.SafeURL('apiListBusines', ``), formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(response => {
                  if (response.data.length === 0) {
                        alert('No Data')
                  } else {
                      this.form.pp_tab_payor_items_feeduty = response.data
                  }
                  this.loading.feeduty = false
              })
              .catch(e => {
                  console.log(e)
                  this.loading.feeduty = false
              })
      },
      OpenBusines () {
        this.busines.dialog.form = true
        // this.busines.form.input.fieldfrom = policyid
        // this.busines.form.input.fieldto = fieldto
        // this.ListFeedOpenBusines(id, fieldfrom, fieldto)
        this.AddBusOpenBusines()
      },
      AddBusOpenBusines () {
        this.url_type  = this.url_type
        this.busines.form.input.profile_id = ''
        this.busines.form.input.address = ''
        setTimeout(function(){
          document.getElementById('busines_from').focus()
        }, 500)
      },
      Payors () {
        this.loading.payors = true
        let formData = new FormData()
        this.$axios.post(this.$functions.SafeURL('apiListPayors', ``), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if (response.data.length === 0) {
                  alert('No Data')
            } else {
                this.form.pp_tab_payor_items_payors = response.data
            }
            this.loading.payors = false
        })
        .catch(e => {
            console.log(e)
            this.loading.payors = false
        })
      },
      FeeDuty () {
          this.loading.feeduty = true
          let formData = new FormData()
              this.$axios.post(this.$functions.SafeURL('apiListFeeDuty', ``), formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(response => {
                  if (response.data.length === 0) {
                        alert('No Data')
                  } else {
                      this.form.pp_tab_payor_items_feeduty = response.data
                  }
                  this.loading.feeduty = false
              })
              .catch(e => {
                  console.log(e)
                  this.loading.feeduty = false
              })
      },
      OpenFeeduty () {
        this.feeduty.dialog.form = true
        // this.feeduty.form.input.fieldfrom = policyid
        // this.feeduty.form.input.fieldto = fieldto
        // this.ListFeedOpenFeeduty(id, fieldfrom, fieldto)
        this.AddFeeduty()
      },
      AddFeeduty () {
        this.url_type  = this.url_type
        this.feeduty.form.input.profile_id = ''
        this.feeduty.form.input.address = ''
        setTimeout(function(){
          document.getElementById('feeduty_from').focus()
        }, 500)
      },
    },
	}
</script>
