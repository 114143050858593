<template>
  <div>
  	<app-drawer_menu drawer_menu_title="General Table"></app-drawer_menu>
	  	<v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
	  	<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if = "access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_gentab" lazy-validation>
			<v-row class="mx-2 ma-1">
				<v-col cols="12" sm="12" md="12">
					<v-btn
				      color		    ="tertiary"
				      class			="white--text"
				      :disabled     = "access.add === 0 ? true:false" 
				      rounded
				      @click 	    = "Add" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
				    </v-btn> &nbsp;
				    <v-btn
				      color		="primary"
				      class		="white--text"
				      :disabled = "access.add === 0 || access.edit === 0 ? true:false"
				      rounded
				      @click 	= "Confirm('write','')" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
				    </v-btn>
				</v-col>
              <!-- ID -->
               <v-col cols="12" sm="2" md="2">
                  <v-text-field
                  v-model 	  	= "form.gentab_id"
                  required
                  :rules 	  	= "form.gentab_id_rules"
                  :readonly     = "form.gentab_id_readonly"
                  :background-color = "form.gentab_id_readonly ? 'readonly' : ''"
				  :maxlength 	= "maxinput.id"
				  :counter		= "maxinput.id"
                  label       	= "ID *"
                  placeholder 	= "ID"
                  id 			= "gentab_id"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- ID -->
               <v-col cols="12" sm="6" md="6">
                  <v-text-field
                  v-model 	  	= "form.gentab_desc"
                  required
                  :rules        = "form.gentab_desc_rules"
				  :maxlength 	= "maxinput.desc"
                  label       	= "Description *"
                  placeholder 	= "Description"
                  id 			= "gentab_desc"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4" class="mt-n3">
		        <small>Field Type</small><br>
		        <v-btn-toggle dense v-model="form.gentab_fieldtype" shaped>
		          <v-btn small>
		            <v-icon>text_format</v-icon> String
		          </v-btn>
		          <v-btn small>
		           <v-icon>filter_5</v-icon> Numeric
		          </v-btn>
		          <v-btn small>
		            <v-icon>calendar_today</v-icon> Date
		          </v-btn>
		        </v-btn-toggle>
		      </v-col>
		      <v-col cols="12" sm="2" md="2" class="mt-n3">
		        <small>Compulsory</small><br>
		        <v-btn-toggle dense v-model="form.gentab_compulsoryf" rounded>
		          <v-btn small>
		            No
		          </v-btn>
		          <v-btn small>
		            Yes
		          </v-btn>
		        </v-btn-toggle>
		      </v-col>
		      <v-col cols="12" sm="2" md="2" class="mt-n3">
		        <small>Data List</small><br>
		        <v-btn-toggle dense v-model="form.gentab_selectf" rounded>
		          <v-btn small>
		            No
		          </v-btn>
		          <v-btn small>
		            Yes
		          </v-btn>
		        </v-btn-toggle>
		      </v-col>
			   <!-- Active -->
               <v-col cols="12" sm="2" md="2" class="mt-n6">
					<label class="caption">Active Status</label>
					<v-switch
						v-model	="form.gentab_actived"
						color  	="primary"
						id 		="form.gentab_actived"
						:label 	="form.gentab_actived !== true ? '' : 'Active'" 
					></v-switch>
				</v-col>
				<v-col cols="12" sm="12" md="12">
				<v-btn-toggle v-if = "url_type !== 'add'">
					<v-btn small @click="Dialog('AT')">Attributes</v-btn>
					<v-btn small @click="Dialog('SC')">Sub Class</v-btn>
					<v-btn small @click="Dialog('CH')">Channel</v-btn>
				</v-btn-toggle>
				</v-col>
            </v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
			   		<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.gentab_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.gentab_cuser)">{{form.gentab_cuser}}</span>
Last Update Date : {{form.gentab_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.gentab_luser)">{{form.gentab_luser}}</span>
</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
         	</v-form>
         	<template v-if="url_type !== 'add' && form.gentab_selectf ===1">
			  <!-- Tab Sub Module -->
				<v-tabs
					background-color="white"
					color="fourth"
					v-if ="access.read === 1"
				>
					<v-tab
						id      ="tabgeneraldetail"
						href   	="#tab-generaldetail"	
						@click	="ListDetail(form.gentab_id)"
					>
						Detail
					</v-tab>
					<v-tab-item
						value  ="tab-generaldetail"
					>
			  <v-card-title 
			  		class="ml-n4"
			  	>
			  		<v-btn 
					  	:color 	="detail.selected.length === detail.items.length ? 'fourth':''"
					  	@click	="SelectAllDetail"
					  	class	="mr-2"
					  	title	="select all"
					  	small
					  	>select all
					</v-btn>
					<v-btn 
					  	@click	="OpenDetail"
					  	class	="mr-2"
					  	title	="select all"
					  	small
					  	>Add
					</v-btn>
	                <v-btn 
	                	color     = "error"
	                	title 	  = "delete selected"
	                	@click 	  = "Confirm('multideletedetail','')"
	                	class	  = "mr-2"
	                	v-if  	  = "detail.selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false"
	                	small>
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-btn 
	                	color  = "primary"
	                	title  = "search"
	                	v-if   = "detail.searchbutton === false"
	                	@click = "Search"
	                	small>
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-text-field
			        v-model				="detail.searchdetail"
			        append-icon			="search"
			        @click:append		="detail.searchbutton = false"
			        label				="Type and Enter"
			        single-line
			        hide-details
			        v-if 				="detail.searchbutton"
			        id   				= "searchdetail"
			        clearable
			        @keyup.enter 		= "ListDetail(form.gentab_id)"
			      	></v-text-field>
				  </v-card-title>
				  <v-data-table
				    :value="detail.selected"
				    :headers="detail.headers"
				    :items="detail.items"
				    @input="CheckDetail($event)"
				    item-key="gendet_id"
				    show-select
				    class="elevation-1"
				     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
				      showFirstLastPage: true,
				      firstIcon: 'first_page',
				      lastIcon: 'last_page',
				      prevIcon: 'keyboard_arrow_left',
				      nextIcon: 'keyboard_arrow_right'
				    }"
				    dense
				    :loading 	= "loading.detail"
	    			loading-text="Loading... Please wait"
				  >
				    <template v-slot:item.action="{ item }">
				      <v-icon
				        small
				        class	  ="mr-2"
				        @click="EditDetail(item.gendet_id, item.gendet_desc, item.gendet_actived, item.gendet_cdate, item.gendet_cuser, item.gendet_ldate, item.gendet_luser)"
				        :disabled = "access.edit === 0 ? true:false"
				        title 	  = "edit"
				      >
				        edit
				      </v-icon>
				      <v-icon
				        small
				        @click	  ="Confirm('deletedetail',item.gendet_id)"
				        :disabled = "access.delete === 0 ? true:false"
				        title 	  = "delete"
				      >
				        delete
				      </v-icon>
				    </template>
				  </v-data-table>
				  </v-tab-item>
				  </v-tabs>
				</template>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  <v-card-title 
			  		class="ml-n4"
			  	>
			  		<v-btn 
					  	:color 	="selected.length === items.length ? 'secondary':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	title	="select all page"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
					</v-btn>
	                <v-btn 
	                	color     = "error"
	                	title 	  = "delete selected"
	                	@click 	  = "Confirm('multidelete','')"
	                	class	  = "mr-2"
	                	v-if  	  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false"
	                	small>
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-btn 
	                	color  = "primary"
	                	title  = "search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true"
	                	small>
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    :value="selected"
			    :headers="headers"
			    :items="items"
			    :search="search"
			    @input="Check($event)"
			    item-key="gentab_id"
			    show-select
			    class="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			    dense
			    :loading 	= "loading.list"
    			loading-text="Loading... Please wait"
			  >
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class	  ="mr-2"
			        @click	  ="Edit(item.gentab_id)"
			        :disabled = "access.edit === 0 ? true:false"
			        title 	  = "edit"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click	  ="Confirm('delete',item.gentab_id)"
			        :disabled = "access.delete === 0 ? true:false"
			        title 	  = "delete"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
		</v-tab-item>
  	</v-tabs>
	<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      top
      style 	= "z-index: 9999"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
  </v-snackbar>
  <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
	<!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
    <v-dialog
      v-model="dialog.attributes"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="690" 
    >
      <v-card>
      	<v-toolbar dark color="primary" dense class="mb-2">
		<v-btn icon dark @click="Close('attributes')">
			<v-icon>{{$vars.V('icon_close')}}</v-icon>
		</v-btn>
		<v-toolbar-title>Attributes - {{ form.gentab_desc  }}</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-btn icon dark @click="Confirm('addattributes','')"><v-icon color="white">save</v-icon></v-btn>
		</v-toolbar>
        <v-card-text>
          <v-progress-linear :indeterminate="true" v-if="loading.attributes"></v-progress-linear>
          <template v-for="index in 20">
          <v-row :class="index > 1 ? 'mt-n1': 'mt-3'" :key="index">
            <v-col cols="12" sm="7" md="7">
                <app-cb
                cb_type             ="gentab"
                cb_url              ="apiListGenTab"
                cb_url_parameter    ="gentab_actived=Y"
                :cb_title           ="`Attribute - ${index}`"
                :cb_id              ="`gentab_fieldid_${index}`"
                :cb_desc            ="`gentab_fieldname_${index}`"
                cb_rules            =""
                cb_desc_readonly    ="readonly"
                cb_items_id         ="gentab_id"
                cb_items_desc       ="gentab_desc"
                :cb_items_add 		="`${index}`"
                :cb_value_id        ="gentab_fieldid_value[index]"
                :cb_value_desc      ="gentab_fieldname_value[index]"
                @clicked		    =GetData
                dense
                >
                </app-cb>
            </v-col>
            <v-col cols="12" sm="5" md="5" class="mt-n3">
                <v-text-field 
                :value  		= "gentab_fieldlabel[index]"
                required 
                label       	= "Label"
                placeholder    	= "Label"
                :id 			= "`gentab_fieldlabel_${index}`"
                dense
                >
                </v-text-field>
            </v-col>
          	</v-row>
          </template>
        </v-card-text>
        <v-card-actions>
        	
        </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.subclass"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="490" 
    >
      <v-card>
      	<v-toolbar dark color="primary" dense class="mb-2">
		<v-btn icon dark @click="Close('subclass')">
			<v-icon>{{$vars.V('icon_close')}}</v-icon>
		</v-btn>
		<v-toolbar-title>Sub Class - {{ form.gentab_desc  }} </v-toolbar-title>
		<v-spacer></v-spacer>
		<v-btn 
			@click  ="CheckSubClass()"
			class   = "mr-2"
			small
			icon
			dark
			><v-icon>done_all</v-icon></v-btn>
		<v-btn icon dark @click="Confirm('addsubclass','')"><v-icon color="white">save</v-icon></v-btn>
		</v-toolbar>
      	<v-card-text>
      	<v-progress-linear :indeterminate="true" v-if="loading.subclass"></v-progress-linear>
      	<template>
      	  <v-simple-table dense>
		    <template v-slot:default>
		      <thead>
		        <tr>
		          <th class="text-left"></th>
		          <th class="text-left">ID</th>
		          <th class="text-left">Description</th>
		        </tr>
		      </thead>
		      <tbody>
		        <tr v-for="item in items_subclass" :key="item.gentabsubclass_id">
		          <td>	
		          		<input
		          		type 	 ="checkbox"
		          		dense
						:id		 ="`id${item.gentabsubclass_id}`"
						:checked = "item.gentabsubclass_gentab_id != '' ? true : false"
						>
		          </td>
		          <td>{{ item.gentabsubclass_id }}</td>
		          <td>{{ item.gentabsubclass_desc }}</td>
		        </tr>
		      </tbody>
		    </template>
		  </v-simple-table>
        </template>
      	</v-card-text>
      </v-card>
     </v-dialog>
     <v-dialog
      v-model="dialog.channel"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="490" 
    >
      <v-card>
      	<v-toolbar dark color="primary" dense class="mb-2">
		<v-btn icon dark @click="Close('channel')">
			<v-icon>{{$vars.V('icon_close')}}</v-icon>
		</v-btn>
		<v-toolbar-title>Channel - {{ form.gentab_desc  }} </v-toolbar-title>
		<v-spacer></v-spacer>
		<v-btn 
			@click  ="CheckChannel()"
			class   = "mr-2"
			small
			icon
			dark
			><v-icon>done_all</v-icon></v-btn>
		<v-btn icon dark @click="Confirm('addchannel','')"><v-icon color="white">save</v-icon></v-btn>
		</v-toolbar>
      	<v-card-text>
      	<v-progress-linear :indeterminate="true" v-if="loading.channel"></v-progress-linear>
      	<template>
      	  <v-simple-table dense>
		    <template v-slot:default>
		      <thead>
		        <tr>
		          <th class="text-left"></th>
		          <th class="text-left">ID</th>
		          <th class="text-left">Description</th>
		        </tr>
		      </thead>
		      <tbody>
		        <tr v-for="item in items_channel" :key="item.gentabchannel_id">
		          <td>	
		          		<input
		          		type 	 ="checkbox"
		          		dense
						:id		 ="`id${item.gentabchannel_id}`"
						:checked = "item.gentabchannel_gentab_id != '' ? true : false"
						>
		          </td>
		          <td>{{ item.gentabchannel_id }}</td>
		          <td>{{ item.gentabchannel_desc }}</td>
		        </tr>
		      </tbody>
		    </template>
		  </v-simple-table>
        </template>
      	</v-card-text>
      </v-card>
     </v-dialog>
    <v-dialog
      v-model="dialog.detail"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="690"
    >
      <v-card>
      	<v-toolbar dark color="primary" dense class="mb-2">
		<v-btn icon dark @click="Close('detail')">
			<v-icon>{{$vars.V('icon_close')}}</v-icon>
		</v-btn>
		<v-toolbar-title>Detail - {{ form.gentab_desc  }}</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-btn icon dark @click="AddDetail" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
		<v-btn icon dark @click="Confirm(url_type,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
		</v-toolbar>
        <v-card-text>
        	<v-progress-linear :indeterminate="true" v-if="loading.detaildialog"></v-progress-linear>
        	<v-form enctype="multipart/form-data" ref="form_gendet" lazy-validation>
        	<v-row class="mx-2 mt-2">
	          	<v-col cols="12" sm="3" md="3" class="mt-n3">
	          		<v-text-field 
					v-model 	  	= "form.gendet_id"
					required 
					:rules 	  		= "form.gendet_id_rules"
					:readonly    	= "flag.detail"
					:counter		= "maxinput.id"
					:maxlength		= "maxinput.id"
					label       	= "ID *"
					placeholder 	= "ID"
					id 				= "gendet_id"
					@keyup.enter 	= "Confirm(url_type,'')"
					>
					</v-text-field>
	          	</v-col>
	          	<v-col cols="12" sm="6" md="6" class="mt-n3">
	          		<v-text-field 
					v-model 	  	= "form.gendet_desc"
					required 
					:rules 	  		= "form.gendet_desc_rules"
					:readonly    	= "form.gendet_desc_readonly"
					:counter		= "maxinput.desc"
					:maxlength		= "maxinput.desc"
					label       	= "Description *"
					placeholder 	= "Description"
					id 				= "gendet_desc"
					@keyup.enter 	= "Confirm(url_type,'')"
					>
					</v-text-field>
	          	</v-col>
	          	<v-col cols="12" sm="3" md="3">
                  <v-switch
                    v-model		="form.gendet_actived"
                    color  		="primary"
					id 			="form.gendet_actived"
					:label 		="form.gendet_actived !== true ? 'Non Active' : 'Active'" 
                  ></v-switch>
                </v-col>
          	</v-row>
          	</v-form>
          	<v-col cols="12" sm="12" md="12" class="mb-7 mt-n7">
				<div v-show="url_type === 'editdetail' ? true:false ">
					<v-checkbox v-model="flag.showdetail" label="Show Info"></v-checkbox>
					<v-banner v-model="flag.showdetail" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.gendet_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.gendet_cuser)">{{form.gendet_cuser}}</span>
Last Update Date : {{form.gendet_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.gendet_luser)">{{form.gendet_luser}}</span>
</pre>
					</v-banner>
				</div>
			</v-col>
          	<div v-if="attributes">
            <v-row class="mx-2" v-for="index in 20" :key="index">
	            <v-col cols="12" sm="12" md="12" class="mt-n5" v-if = "gendet_fieldid_value[index] !== ''">
	                <app-gt
	                  :gt_title         ="gendet_fieldlabel_value[index]"
	                  :gt_id            ="`gendet_fieldid_${index}`"
	                  :gt_desc          ="`gendet_fielddesc_${index}`"
	                  gt_rules          ="Please fill"
	                  :gt_desc_readonly	="false"
	                  :gt_refid         ="gendet_fieldid_value[index]"
	                  :gt_value_id      ="gentab_detailid_value[index]"
	                  :gt_value_desc    ="gentab_detaildesc_value[index]"
	                  dense
	                ></app-gt>
	            </v-col>
          	</v-row>
          	</div>
        </v-card-text>
        <v-card-actions>
        	<v-spacer></v-spacer>
        	
        </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
	data: () => ({
		url_type: "add",
		cont: false,
		access: {
			read: 0,
			add: 0,
			edit: 0,
			delete: 0,
		},
		maxinput: {
			id: 10,
			desc: 255,
		},
		snackbar: {
			dialog: false,
			color: "success",
			text: "",
			timeout: 3000,
		},
		confirm: {
			dialog: false,
			title: "",
			text: "Ok",
		},
		selected: [],
		search: "",
		headers: [
			{
				text: "ID",
				align: "left",
				value: "gentab_id",
			},
			{ text: "Description", value: "gentab_desc" },
			{ text: "Field Type", value: "gentab_fieldtype" },
			{ text: "Actived", value: "gentab_actived" },
			{ text: "Actions", value: "action", sortable: false },
		],
		remarks: {
			feedback: "",
			dialog: false,
		},
		items: [],
		accessmenu: [],
		loading: {
			page: true,
			list: false,
			detail: false,
			detaildialog: false,
			subclass: false,
			channel: false,
			attributes: false,
		},
		btn_search: false,
		form: {
        	gentab_id: '',
        	gentab_id_rules: [v => !!v || 'Please fill ID'],
        	gentab_id_readonly: false,
        	gentab_desc: '',
        	gentab_desc_rules: [v => !!v || 'Please fill Description'],
        	gentab_fieldtype: 0,
        	gentab_compulsoryf: 0,
        	gentab_selectf: 0,
        	gentab_actived: true,
        	gendet_id: '',
			gendet_id_rules: [v => !!v || 'Please fill ID'],
        	gendet_desc: '',
			gendet_desc_rules: [v => !!v || 'Please fill Description'],
        	gendet_cdate: '',
        	gendet_cuser: '',
        	gendet_ldate: '',
        	gendet_luser: '',
        	gendet_actived: true,
        	display_users: '',
        },
        selected_subclass: [],
		items_subclass: [],
		selected_channel: [],
		items_channel: [],
		gentab_attribures: [],
        gentab_fieldlabel: {},
        gentab_fieldid_value: {},
        gentab_fieldname_value: {},
        gendet_fieldid_value: {},
        gendet_fieldtype_value: {},
        gendet_fieldlabel_value: {},
        gendet_fieldvalue_value: {},
        gendet_fieldvaluedesc_value: {},
        gentab_detailid_value: {},
        gentab_detaildesc_value: {},
        attributes: false,
        detail: {
        	headers: [
				{
					text: "ID",
					align: "left",
					value: "gendet_id",
				},
				{ text: "Descriptions", value: "gendet_desc" },
				{ text: "Actived", value: "gendet_actived" },
				{ text: "Actions", value: "action", sortable: false },
			],
			items: [],
			selected: [],
			checksubclass: false,
			checkchannel: false,
			search: "",
			searchbutton: false,
        },
        flag: {
        	detail: false,
        	showdetail: false,
        },
        dialog: {
        	attributes: false,
        	subclass: false,
        	channel: false,
        	detail: false,
        	users: false,
        },
	}),
	created() {
		this.FirstLoad()
	},
	methods: {
		FirstLoad() {
			//this function use for validating the screen with user privilleges
			this.Access()
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "gentab"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let priv = response.data
					if (priv.length > 0) {
						this.access.read = priv[0].usersmenu_read
						this.access.add = priv[0].usersmenu_add
						this.access.edit = priv[0].usersmenu_edit
						this.access.delete = priv[0].usersmenu_delete
						if (priv[0].usersmenu_read === 0) {
							this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
						}
					} else {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					}
					this.loading.page = false
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 0)
					this.loading.page = false
				})
		},
		SelectAllPage() {
			this.selected = this.selected === this.items ? [] : this.items
		},
		Check(value) {
			this.selected = value
		},
		SelectAllDetail() {
			this.detail.selected = this.detail.selected === this.detail.items ? [] : this.detail.items
		},
		CheckDetail(value) {
			this.detail.selected = value
		},
		//Function for get all data from table GenTab with API in mod_users.go
		List() {
			this.loading.list = true
			let formdata = {
				gentab_id: "",
				gentab_desc: "",
				order_by: "gentab_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListGenTab"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== "Y") {
						this.SnackBar(
							true,
							"error",
							feedback[0].feedback,
							0
						)
					} else {
						this.items = feedback
					}
				} else {
					this.items = feedback
					this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading.list = false
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 3000)
				this.loading.list = false
			})
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
		Confirm(flag, id) {
			if (flag === "write") {
				if (this.$refs.form_gentab.validate()) {
					this.confirm.dialog = true
					this.confirm.title = "Save"
					setTimeout(function() {
						document.getElementById("dialog").focus()
					}, 500)
				}
			} else if (flag === "delete") {
				this.url_type = "delete"
				this.confirm.dialog = true
				this.confirm.title = "Delete `" + id + "`"
				this.gentab_id = id
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag === "multidelete") {
				this.url_type = "multidelete"
				this.confirm.dialog = true
				this.confirm.title =
					"Delete `" + this.selected.length + "` data"
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag === "addattributes") {
				this.url_type = "addattributes"
				this.confirm.dialog   = true
	  			this.confirm.title    = 'Save Attributes General Table'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
			} else if (flag === "addsubclass") {
				this.url_type = "addsubclass"
				this.confirm.dialog   = true
	  			this.confirm.title    = 'Save Sub Class General Table'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
			} else if (flag === "addchannel") {
				this.url_type = "addchannel"
				this.confirm.dialog   = true
	  			this.confirm.title    = 'Save Channel General Table'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
			} else if (flag === 'adddetail') {
				if (this.$refs.form_gendet.validate()) {
		  			this.url_type 		  = 'adddetail'
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save Detail'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
	  			}
	  		} else if (flag === 'editdetail') {
	  			if (this.$refs.form_gendet.validate()) {
		  			this.url_type 		  = 'editdetail'
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Edit Detail'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
	  			}
	  		} else if (flag === 'deletedetail') {
	  			this.url_type 		  = 'deletedetail'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete Detail `' + id + '`'
	  			this.form.gendet_id   = id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multideletedetail') {
	  			this.url_type 		  = 'multideletedetail'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ this.detail.selected.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		}
		},
		//Function to request insert data to table GenTab 'The API will validating your data, include the user who process the data' (go to APIWriteGenTab with url_type = "add")
		Add() {
			this.url_type 					= "add"
			this.form.gentab_id 			= ""
			this.form.gentab_desc 			= ""
			this.form.gentab_fieldtype		= 0
			this.form.gentab_compulsoryf	= 0
			this.form.gentab_selectf		= 0
			this.form.gentab_actived 		= true
			this.form.gentab_id_readonly 	= false
			setTimeout(function() {
				document.getElementById("gentab_id").focus()
			}, 500)
		},
		//Function to request update data to table GenTab 'The API will validating your data, include the user who process the data' (go to APIWriteGenTab with url_type = "edit")
		Edit(id) {
			this.loading.list = true
			let formdata = {
				gentab_id: id,
				gentab_desc: "",
				order_by: "gentab_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListGenTab"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						document.getElementById("tabform").click()
						this.url_type 					= "edit"
						this.form.gentab_id 			= feedback[0].gentab_id
						this.form.gentab_desc 			= feedback[0].gentab_desc
						this.form.gentab_fieldtype  	= this.$functions.FieldType(feedback[0].gentab_fieldtype)
						this.form.gentab_compulsoryf 	= this.$functions.YesOrNo(feedback[0].gentab_compulsoryf)
						this.form.gentab_selectf 		= this.$functions.YesOrNo(feedback[0].gentab_selectf)
						this.form.gentab_actived    	= this.$functions.TrueOrFalse(feedback[0].gentab_actived)
						this.form.gentab_cdate 			= feedback[0].gentab_cdate
						this.form.gentab_cuser 			= feedback[0].gentab_cuser
						this.form.gentab_ldate 			= feedback[0].gentab_ldate
						this.form.gentab_luser 			= feedback[0].gentab_luser
						this.form.gentab_id_readonly 	= true
						this.ListDetail(feedback[0].gentab_id)
						setTimeout(function() {
							document.getElementById("gentab_desc").focus()
						}, 500)
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(true, "error", feedback, 0)
				}
				this.loading.list = false
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog = true
				this.remarks.feedback = e
				this.loading.list = false
			})
		},
		Delete(id) {
			let formdata = {
				url_type: "delete",
				gentab_id: id,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteGenTab"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"success",
							"Delete Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						this.selected = []
						this.List()
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
		MultiProcess() {
			let multiData = JSON.stringify(this.selected)
			let multiDetail = JSON.stringify(this.detail.selected)
			let formdata = {
				url_type: this.url_type,
				gendet_gentab_id: this.form.gentab_id,
				multi_data: multiData,
				multi_detail: multiDetail,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteGenTab"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback[0].feedback === "Y") {
					let feed
					if (feedback[0].feedback_failed == 0) {
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					} else {
						this.remarks.feedback = feedback[0].feedback_failed_remarks
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, "success", feed, 0)
					this.confirm.dialog = false
					this.confirm.text 	= "Ok"
					this.selected 		= []
					if (this.url_type === 'multidelete') {
						this.List()
					} else if (this.url_type === 'multideletedetail') {
						this.ListDetail(this.form.gentab_id)
						this.detail.selected = []
						this.url_type = 'edit'
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = feedback[0].feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
		Loading() {
			this.confirm.text = "Loading..."
			if (this.url_type === "add" || this.url_type === "edit") {
				this.Write()
			} else if (this.url_type === "delete") {
				this.Delete(this.gentab_id)
			} else if (this.url_type === "multidelete") {
				this.MultiProcess("delete")
			} else if (this.url_type === "addattributes") {
				this.WriteAttributesValue()
			} else if (this.url_type === "addsubclass") {
				this.WriteSubClassValue()
			} else if (this.url_type === "addchannel") {
				this.WriteChannelValue()
			} else if (this.url_type === 'adddetail' || this.url_type === 'editdetail') {
	  			this.WriteDetail()
	  		} else if (this.url_type === 'deletedetail') {
	  			this.DeleteDetail(this.form.gendet_id)
	  		} else if (this.url_type === 'multideletedetail') {
	  			this.MultiProcess('multideletedetail')
	  		}
		},
		Write() {
			let fieldtype  = 'S'
			let compulsory = 'N'
			let datalist   = 'N'
			if (this.form.gentab_fieldtype === 1) {
				fieldtype = 'N'
			} else if (this.form.gentab_fieldtype === 2) {
				fieldtype = 'D'
			}
			if (this.form.gentab_compulsoryf === 1) {
				compulsory = 'Y'
			}
			if (this.form.gentab_selectf === 1) {
				datalist = 'Y'
			}
			let formdata = {
				url_type: this.url_type,
				gentab_id: this.form.gentab_id,
				gentab_desc: this.form.gentab_desc,
				gentab_fieldtype: fieldtype,
				gentab_compulsoryf: compulsory,
				gentab_selectf: datalist,
				gentab_actived: this.$functions.ActivedFlag(this.form.gentab_actived)
			}			
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteGenTab"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"success",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "add") {
							this.form.gentab_cuser = feedback[0].feedback_users_id
							this.form.gentab_cdate = feedback[0].feedback_users_date
						}
						this.form.gentab_luser = feedback[0].feedback_users_id
						this.form.gentab_ldate = feedback[0].feedback_users_date
						this.url_type = "edit"
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
		SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			if (color === "error") {
				this.confirm.dialog 	= false
				this.confirm.text 		= "Ok"
			}
		},
		Dialog (flag) {
	    	if (flag === 'AT') {
	    		this.AttributesValue()
	    	} else if (flag === 'SC') {
	    		this.SubClassValue()
	    	} else if (flag === 'CH') {
	    		this.ChannelValue()
	    	}
	    },
	    GetData (value) {
	    	if (value !== undefined) {
		    	let splits = this.$functions.Split(value, '|')
		    	this.$set(this.gentab_fieldlabel, splits[2],splits[1])
	    	}
	    },
	    Attributes () {
	    	let gentab = []
	    	for (let i = 1; i <= 20; i ++) {
	    		let gentabid 	= document.getElementById('gentab_fieldid_' + i).value
	    		let gentabname  = document.getElementById('gentab_fieldname_' + i).value
	    		let gentablabel = document.getElementById('gentab_fieldlabel_' + i).value
	    		gentab.push({gentab_fieldid: gentabid, gentab_fieldname: gentabname, gentab_fieldlabel: gentablabel, gentab_fieldno: i})
	    	}
	  		let formdata = {
				url_type: 'attributes',
				gentab_id: this.form.gentab_id,
				gentab_attributes: JSON.stringify(gentab)
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteGenTab'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Save Success', 0)
		            	this.confirm.dialog = false
						this.confirm.text 	= "Ok"
		  			} else {
		  				this.SnackBar(true, 'error', feedback[0].feedback, 0)
		  			}
	  			}	
			})
			.catch(e => {
            	this.SnackBar(true, 'error', e, 3000)
            })
	  	},
	  	ListDetail (id) {
			this.loading.detail = true
			let formdata = {
					gendet_gentab_id: id,
					datavalue: this.detail.searchdetail,
					order_by: 'gendet_desc',
					order_type: 'ASC',
					limit: '100',
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListGenDetailShort'),param,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				let feedback 	  = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.detail.items = feedback
					}
				} else {
					this.detail.items = feedback
					// this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading.detail = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 0)
				this.loading.detail = false
			})
		},
		AttributesValue () {
			this.dialog.attributes = true
			this.loading.attributes = true
			let formdata = {
				gentab_id: this.form.gentab_id,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListGenTabAttributes'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						for (let i = 1; i <= 20; i ++) {
							let field_id    = `gentab_fieldid_` + i
							let field_name  = `gentab_fieldname_` + i
							let field_label = `gentab_fieldlabel_` + i
							if (feedback[0][field_id] !== '') {
								this.$set(this.gentab_fieldid_value, i, feedback[0][field_id])
								this.$set(this.gentab_fieldname_value, i, feedback[0][field_name])
								this.$set(this.gentab_fieldlabel, i, feedback[0][field_label])
							} else {
								this.$set(this.gentab_fieldid_value, i, '')
								this.$set(this.gentab_fieldname_value, i, '')
								this.$set(this.gentab_fieldlabel, i, '')
							}
						}
					}
				}
				this.loading.attributes = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 0)
				this.loading.attributes = false
			})
		},
		WriteAttributesValue() {
			this.gentab_attribures = []      
			for (let i = 1; i <= 20; i ++) {
				let id   	= document.getElementById('gentab_fieldid_' + i).value
				let name 	= document.getElementById('gentab_fieldname_' + i).value
				let label	= document.getElementById('gentab_fieldlabel_' + i).value
				this.gentab_attribures.push({'gentab_fieldid': id, 'gentab_fieldname': name, 'gentab_fieldlabel': label})
			}
			let formdata = {
				url_type        	: 'addattributes',
				gentab_id     		: this.form.gentab_id,
				gentab_attributes	: JSON.stringify(this.gentab_attribures)
			}
			console.log("Form Data", formdata)
			let param 	   = this.$functions.ParamPOST(formdata)      
			this.$axios.post(this.$functions.UrlPOST('apiWriteGenTab'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
			})
			.then(response => {
				let feedback = response.data
				console.log("Feedback", feedback)
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
						this.SnackBar(true, 'success', 'Save Success', 0)
						this.confirm.dialog = false
						this.confirm.text 	= "Ok"
					} else {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					}
				}	
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
			})
		},
		ClearAttributesValue() {      
			for (let i = 1; i <= 20; i ++) {
				if (document.getElementById('gentab_fieldid_' + i) !== null) {
					this.$set(this.gentab_fieldid_value, i, '')
					this.$set(this.gentab_fieldname_value, i, '')
					this.$set(this.gentab_fieldlabel, i, '')
				}
			}
			this.gentab_fieldid_value     = {}
			this.gentab_fieldname_value   = {}
			this.gentab_fieldlabel        = {}
			this.gentab_attribures        = []
		},
		OpenDetail () {
			this.dialog.detail = true
			this.AddDetail()
			this.DetailData()
			console.log("Open Detail Switch", this.form.gendet_actived)
		},
		AddDetail () {
			this.url_type      	  	 = 'adddetail'
			this.form.gendet_id   	 = ''
			this.form.gendet_desc 	 = ''
			this.form.gendet_actived = true
			this.flag.detail      	 = false
			this.ClearDetailData()
			setTimeout(function(){
                document.getElementById('gendet_id').focus()
            }, 500)
		},
		EditDetail (id, desc, active, cdate, cuser, ldate, luser) {
			this.url_type      	  	 = 'editdetail'
			this.form.gendet_id   	 = id
			this.form.gendet_desc    = desc
			this.form.gendet_cdate   = cdate
			this.form.gendet_cuser   = cuser
			this.form.gendet_ldate   = ldate
			this.form.gendet_luser   = luser
			this.form.gendet_actived = this.$functions.TrueOrFalse(active)
			this.flag.detail      	 = true
			this.dialog.detail       = true
			this.DetailData()
			setTimeout(function(){
                document.getElementById('gendet_desc').focus()
            }, 500)
		},
		DetailData () {
			this.loading.detaildialog = true
			let formdata = {
				gentab_id: this.form.gentab_id,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListGenTabAttributes'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback 		 = response.data
				let checking 		 = false
				for (let i = 1; i <= 20; i ++) {
					let field_id    = `gentab_fieldid_` + i
					// let field_type  = `gentab_fieldtype_` + i
					// let field_name  = `gentab_fieldname_` + i
					let field_label = `gentab_fieldlabel_` + i
					this.$set(this.gendet_fieldid_value, i, feedback[0][field_id])
					// this.$set(this.gendet_fieldtype_value, i, feedback[0][field_type])
					// this.$set(this.gendet_fieldname_value, i, feedback[0][field_name])
					this.$set(this.gendet_fieldlabel_value, i, feedback[0][field_label])
					if (feedback[0].gentab_fieldid_1 !== '') {
						checking = true
					}
				}
				this.DetailValue()
				this.attributes = checking
				this.loading.detaildialog = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.detaildialog = false
			})
		},
		DetailValue () {
			this.ClearDetailData()
			if (this.form.gendet_id !== '') {
				let formdata = {
						gendet_id: this.form.gendet_id,
						gendet_gentab_id: this.form.gentab_id,
				}
				let param 	   = this.$functions.ParamPOST(formdata)
				this.$axios.post(this.$functions.UrlPOST('apiListGenTabDetail'),param,{
			        headers: {
			            'Content-Type': 'text/plain; charset=ISO-8859-1'
			        }
				})
				.then(response => {
					let feedback = response.data
					if (feedback.length > 0) {
						for (let i = 1; i <= 20; i ++) {
							let value_id    = `gendet_fieldvalue_` + i
							let value_desc  = `gendet_fieldvaluedesc_` + i
							if (value_desc !== '') {
								this.$set(this.gentab_detailid_value, i, feedback[0][value_id])
								this.$set(this.gentab_detaildesc_value, i, feedback[0][value_desc])
							}
						}
					}
				})
				.catch(e => {
					this.SnackBar(true, 'error', e, 3000)
				})
			}
		},
		ClearDetailData () {
			for (let i = 1; i <= 20; i ++) {
				if (document.getElementById('gendet_fielddesc_' + i) !== null) {
				    this.$set(this.gentab_detailid_value, i, '')
				    this.$set(this.gentab_detaildesc_value, i, '')
				}
			}
		},
		WriteDetail () {
			let detail = []
	    	for (let i = 1; i <= 20; i ++) {
	    		if (document.getElementById('gendet_fielddesc_' + i) !== null) {
		    		let gendet_fieldid 		  = this.gendet_fieldid_value[i]
		    		let gendet_fieldlabel 	  = this.gendet_fieldlabel_value[i]
		    		let gendet_fieldvalue 	  = document.getElementById('gendet_fieldid_' + i).value
		    		let gendet_fieldvaluedesc = document.getElementById('gendet_fielddesc_' + i).value
		    		detail.push({gendet_fieldid: gendet_fieldid, gendet_fieldlabel: gendet_fieldlabel, gendet_fieldvalue: gendet_fieldvalue, gendet_fieldvaluedesc: gendet_fieldvaluedesc, gendet_fieldno: i})
	    		}
	    	}
	  		let formdata = {
				url_type: this.url_type,
				gendet_id: this.form.gendet_id,
				gendet_gentab_id: this.form.gentab_id,
				gendet_desc: this.form.gendet_desc,
				gendet_actived: this.$functions.ActivedFlag(this.form.gendet_actived),
				multi_detail: JSON.stringify(detail)
			}
			console.log("Form Data", formdata)
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteGenTab'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.SnackBar(true, 'success', 'Save Success', 0)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "adddetail") {
							this.form.gendet_cuser = feedback[0].feedback_users_id
							this.form.gendet_cdate = feedback[0].feedback_users_date
						}
						this.form.gendet_luser = feedback[0].feedback_users_id
						this.form.gendet_ldate = feedback[0].feedback_users_date
						this.url_type = "editdetail"
						this.ListDetail(this.form.gentab_id)
					}
				}
			})
			.catch(e => {
            	this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
            })
	  	},
	  	DeleteDetail (id) {
			let formdata = {
				url_type: this.url_type,
				gendet_id: id,
				gendet_gentab_id: this.form.gentab_id
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteGenTab'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.SnackBar(true, 'success', 'Delete Success', 0)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						this.ListDetail(this.form.gentab_id)
						this.url_type = "edit"
					}
				}
			})
            .catch(e => {
            	this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
            })
	  	},
	  	CheckSubClass () {
	  		this.detail.checksubclass = !this.detail.checksubclass
	  		let checked = ''
	  		if (this.detail.checksubclass === true) {
	  			checked = this.form.gentab_id
	  		}
			for (var i = 0; i < this.items_subclass.length; i++) {
				this.items_subclass[i].gentabsubclass_gentab_id = checked
			}
		},
	  	SubClassValue () {
	  		this.items_subclass = []
	  		this.dialog.subclass = true
	  		this.loading.subclass = true
	  		let formdata = {
				gentabsubclass_gentab_id: this.form.gentab_id
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListGenTabSubClass'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.items_subclass     = response.data
						this.loading.subclass = false
					}
				}
			})
            .catch(e => {
            	this.SnackBar(true, 'error', e, 3000)
            	this.loading.subclass = false
            })
	  	},
	  	WriteSubClassValue () {
	  		this.selected_subclass = []
			for (let i = 0; i < this.items_subclass.length; i ++) {
				let menutype = this.items_subclass[i].gentabsubclass_id
				if (document.getElementById('id'+menutype).checked === true) {
					this.selected_subclass.push({'gentabsubclass_id': menutype})
				}
			}
			let multiData   = JSON.stringify(this.selected_subclass)
			let formdata = {
				url_type: this.url_type,
				gentabsubclass_gentab_id: this.form.gentab_id,
				multi_subclass: multiData
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteGenTab'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then((response) => {
			let feedback = response.data
			if (feedback[0].feedback === "Y") {
				let feed
				if (feedback[0].feedback_failed == 0) {
					feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
				} else {
					this.remarks.feedback = feedback[0].feedback_failed_remarks
					feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					this.remarks.dialog = true
				}
				this.SnackBar(true, "success", feed, 0)
				this.confirm.dialog = false
				this.confirm.text 	= "Ok"
			} else {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog = true
				this.remarks.feedback = feedback[0].feedback
			}
		})
		.catch((e) => {
			this.SnackBar(
				true,
				"error",
				this.$functions.ErrorCode(),
				0
			)
			this.remarks.dialog = true
			this.remarks.feedback = e
		})
	  	},
	  	CheckChannel () {
	  		this.detail.checkchannel = !this.detail.checkchannel
	  		let checked = ''
	  		if (this.detail.checkchannel === true) {
	  			checked = this.form.gentab_id
	  		}
			for (var i = 0; i < this.items_channel.length; i++) {
				this.items_channel[i].gentabchannel_gentab_id = checked
			}
		},
	  	ChannelValue () {
	  		this.items_channel = []
	  		this.dialog.channel = true
	  		this.loading.channel = true
	  		let formdata = {
				gentabchannel_gentab_id: this.form.gentab_id
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListGenTabChannel'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.items_channel     = response.data
						this.loading.channel = false
					}
				}
			})
            .catch(e => {
            	this.SnackBar(true, 'error', e, 3000)
            	this.loading.channel = false
            })
	  	},
	  	WriteChannelValue () {
	  		this.selected_channel = []
			for (let i = 0; i < this.items_channel.length; i ++) {
				let menutype = this.items_channel[i].gentabchannel_id
				if (document.getElementById('id'+menutype).checked === true) {
					this.selected_channel.push({'gentabchannel_id': menutype})
				}
			}
			let multiData   = JSON.stringify(this.selected_channel)
			let formdata = {
				url_type: this.url_type,
				gentabchannel_gentab_id: this.form.gentab_id,
				multi_channel: multiData
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteGenTab'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then((response) => {
			let feedback = response.data
			if (feedback[0].feedback === "Y") {
				let feed
				if (feedback[0].feedback_failed == 0) {
					feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
				} else {
					this.remarks.feedback = feedback[0].feedback_failed_remarks
					feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					this.remarks.dialog = true
				}
				this.SnackBar(true, "success", feed, 0)
				this.confirm.dialog = false
				this.confirm.text 	= "Ok"
			} else {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog = true
				this.remarks.feedback = feedback[0].feedback
			}
		})
		.catch((e) => {
			this.SnackBar(
				true,
				"error",
				this.$functions.ErrorCode(),
				0
			)
			this.remarks.dialog = true
			this.remarks.feedback = e
		})
	  	},
	  	Close (flag) {
	  		if (flag === 'attributes') {
	  			this.url_type = 'edit'
	  			this.dialog.attributes = false
				this.ClearAttributesValue()
	  		} else if (flag === 'subclass') {
	  			this.url_type = 'edit'
	  			this.dialog.subclass = false
	  		} else if (flag === 'channel') {
	  			this.url_type = 'edit'
	  			this.dialog.channel = false
	  		} else if (flag === 'detail') {
	  			this.url_type = 'edit'
	  			this.dialog.detail = false
	  			this.ListDetail(this.form.gentab_id)
	  		} else if (flag === 'confirm') {
	  			this.confirm.dialog = false
	  			if (this.url_type !== 'add') {
	  				this.url_type = 'edit'
	  			}
	  		}
	  	},
	  	Search () {
	  		this.detail.searchbutton = true
	  		setTimeout(function() {
				document.getElementById("searchdetail").focus()
			}, 500)
	  	},
		ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	},
};
</script>