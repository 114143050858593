<template>
	<div>
		<Profile :value="true"></Profile>
	</div>
  </template>
  <script>
  import Profile from '../../../components/com_profile.vue'
  export default {
		components: {
            Profile
        },
  }
  </script>