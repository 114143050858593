<template>
  <v-row>
    <v-col v-if="gt_type === 'L'" cols="12" sm="3" md="3">
      <v-text-field
        v-model="id[gt_id]"
        :id="gt_id"
        :name="gt_id"
        prepend-inner-icon="list"
        maxlength="10"
        background-color="combo"
        :required="gt_rules.length > 0 ? true : false"
        :rules="gt_rules.length > 0 && !id[gt_id] ? [gt_rules] : []"
        :readonly="gt_id_readonly === undefined ? false : gt_id_readonly"
        :value="gt_value_id"
        v-bind="$attrs"
        @keyup.enter="GTView"
        @change="GTView"
      >
      </v-text-field>
    </v-col>
    <v-col
      v-if="gt_type === 'L'"
      cols="12"
      sm="9"
      md="9"
      :class="$vuetify.breakpoint.md || $vuetify.breakpoint.lg ? '' : 'mt-n1'"
    >
      <v-text-field
        v-model="desc[gt_desc]"
        :id="gt_desc"
        :name="gt_desc"
        :label="gt_rules.length > 0 ? `${gt_title} *` : gt_title"
        maxlength="255"
        placeholder="Description"
        background-color="readonly"
        :readonly="gt_desc_readonly === undefined ? false : gt_desc_readonly"
        :value="gt_value_desc"
        v-bind="$attrs"
      >
      </v-text-field>
    </v-col>
    <v-col v-if="gt_type === 'S'" cols="12" sm="12" md="12">
      <v-text-field
        v-model="id[gt_id]"
        :id="gt_id"
        :value="gt_value_id"
        v-show="false"
      ></v-text-field>
      <v-text-field
        v-model="desc[gt_desc]"
        :id="gt_desc"
        :name="gt_desc"
        :label="gt_rules.length > 0 ? `${gt_title} *` : gt_title"
        maxlength="255"
        prepend-inner-icon="title"
        :placeholder="
          gt_id_placeholder === undefined ? 'Description' : gt_id_placeholder
        "
        :required="gt_rules.length > 0 ? true : false"
        :rules="gt_rules.length > 0 && !desc[gt_desc] ? [gt_rules] : []"
        :readonly="gt_id_readonly === undefined ? false : gt_id_readonly"
        v-bind="$attrs"
        :value="gt_value_desc"
      >
      </v-text-field>
    </v-col>
    <v-col v-if="gt_type === 'N'" cols="12" sm="12" md="12">
      <v-text-field
        v-model="id[gt_id]"
        :id="gt_id"
        :value="gt_value_id"
        v-show="false"
      ></v-text-field>
      <v-text-field
        v-model="desc[gt_desc]"
        :id="gt_desc"
        :name="gt_desc"
        :label="gt_rules.length > 0 ? `${gt_title} *` : gt_title"
        maxlength="255"
        prepend-inner-icon="money"
        :placeholder="
          gt_id_placeholder === undefined ? 'Input Number' : gt_id_placeholder
        "
        :required="gt_rules.length > 0 ? true : false"
        :rules="gt_rules.length > 0 && !desc[gt_desc] ? [gt_rules] : []"
        :readonly="gt_id_readonly === undefined ? false : gt_id_readonly"
        background-color="numeric"
        :value="gt_value_desc"
        v-bind="$attrs"
        reverse
      >
      </v-text-field>
    </v-col>
    <v-col v-if="gt_type === 'D'" cols="12" sm="12" md="12">
      <v-text-field
        v-model="id[gt_id]"
        :id="gt_id"
        :value="gt_value_id"
        v-if="false"
      ></v-text-field>
      <v-text-field
        v-model="desc[gt_desc]"
        :id="gt_desc"
        :name="gt_desc"
        :label="gt_rules.length > 0 ? `${gt_title} *` : gt_title"
        :required="gt_rules.length > 0 ? true : false"
        :rules="gt_rules.length > 0 && !desc[gt_desc] ? [gt_rules] : []"
        :readonly="gt_id_readonly === undefined ? false : gt_id_readonly"
        prepend-inner-icon="calendar_today"
        placeholder="YYYY-MM-DD"
        background-color="date"
        v-mask="'####-##-##'"
        v-bind="$attrs"
        :value="gt_value_desc"
      >
      </v-text-field>
    </v-col>
    <!--Modal-->
    <v-dialog
      v-model="modal['generaltable']"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      v-if="modal['generaltable']"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.stop="GTClose()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ gt_title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container grid-list-md class="pa-12">
          <v-layout row wrap>
            <v-col xs12>
              <v-text-field
                v-model="search[gt_id]"
                id="search_generaltable"
                append-icon="search"
                :label="`Search ${gt_title}`"
                v-if="modal['generaltable']"
                @keyup.enter="GTSearch"
                :loading="loading"
              ></v-text-field>
              <v-data-table
                :headers="gt_header"
                :items="items[gt_id]"
                item-key="gendet_id"
                @click:row="GTGet"
                class="elevation-1"
              >
                <template slot="headerCell" slot-scope="props">
                  <v-tooltip bottom>
                    <span slot="activator">
                      {{ props.header.text }}
                    </span>
                    <span>
                      {{ props.header.text }}
                    </span>
                  </v-tooltip>
                </template>
                <template slot="items" slot-scope="props">
                  <tr class="cursor">
                    <td class="text-xs-left">{{ props.item["gendet_id"] }}</td>
                    <td class="text-xs-left">
                      {{ props.item["gendet_desc"] }}
                    </td>
                  </tr>
                </template>
                <template v-slot:no-data> No Data Available </template>
              </v-data-table>
            </v-col>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    format_money: {
      decimal: ",",
      thousands: ".",
      precision: 0,
    },
    id: {},
    desc: {},
    search: {},
    items: [],
    modal: {
      generaltable: false,
    },
    gt_header: [
      {
        text: "ID",
        value: "gendet_id",
        align: "left",
      },
      { text: "Name", value: "gendet_desc" },
    ],
    gt_type: "",
  }),
  created() {
    this.GTType(this.gt_refid);
  },
  watch: {
    gt_value_id: function (newdata) {
      if (newdata !== "") {
        this.id[this.gt_id] = newdata;
      } else {
        this.id[this.gt_id] = "";
      }
    },
    gt_value_desc: function (newdata) {
      if (newdata !== "") {
        this.desc[this.gt_desc] = newdata;
      } else {
        this.desc[this.gt_desc] = "";
      }
    },
  },
  props: [
    "gt_title",
    "gt_id",
    "gt_desc",
    "gt_rules",
    "gt_id_readonly",
    "gt_desc_readonly",
    "gt_value_id",
    "gt_value_desc",
    "gt_url_parameter",
    "gt_id_placeholder",
    "gt_refid",
  ],
  methods: {
    // View Data and show dialog when Enter
    GTView() {
      this.modal["generaltable"] = true;
      this.GTCall(this.id[this.gt_id]);
      setTimeout(function () {
        document.getElementById("search_generaltable").focus();
      }, 500);
    },
    //Close Dialog
    GTClose() {
      this.id[this.gt_id] = "";
      this.desc[this.gt_desc] = "";
      this.modal["generaltable"] = false;
      var v = this;
      setTimeout(function () {
        document.getElementById(v.gt_id).focus();
      }, 500);
    },
    // Search data
    GTSearch() {
      this.loading = true;
      this.GTCall(this.search[this.gt_id]);
    },
    GTGet(value) {
      this.id[this.gt_id] = value["gendet_id"];
      this.desc[this.gt_desc] = value["gendet_desc"];
      this.modal["generaltable"] = false;
    },
    GTCall(values) {
      this.loading = true;
      this.search[this.gt_id] = values;
      let formdata = {
        datavalue: values,
        gendet_gentab_id: this.gt_refid,
        parameter: this.cb_url_parameter,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListGenTabDetail"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length === 0) {
            this.id[this.gt_id] = "";
            this.desc[this.gt_desc] = "";
            this.items[this.gt_id] = [];
          } else if (feedback.length === 1) {
            if (feedback[0].feedback === "Y") {
              this.id[this.gt_id] = feedback[0]["gendet_id"];
              this.desc[this.gt_desc] = feedback[0]["gendet_desc"];
              this.modal["generaltable"] = false;
              var v = this;
              setTimeout(function () {
                document.getElementById(v.cb_id).focus();
              }, 500);
            } else {
              alert(feedback[0].feedback);
            }
          } else {
            this.modal["generaltable"] = true;
            this.search[this.gt_id] = this.id[this.gt_id];
            this.items[this.gt_id] = response.data;
          }
          this.loading = false;
        })
        .catch((e) => {
          alert(e);
          this.loading = false;
        });
    },
    GTType(values) {
      let formdata = {
        gentab_id: values,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListGenTab"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.flag = "Y";
              this.gt_type = "L";
              if (feedback[0].gentab_selectf !== "Y") {
                this.gt_type = feedback[0].gentab_fieldtype;
              }
            }
          } else {
            this.flag = "N";
          }
        })
        .catch((e) => {
          alert(e);
        });
    },
  },
};
</script>