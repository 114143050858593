<template>
  <div>
    <app-drawer_menu drawer_menu_title="Policy Production"></app-drawer_menu>
    <mapping-tab-form ref="form" />
    <mapping-tab-list ref="list" />
    <app-snackbar ref="snackbar"></app-snackbar>
    <v-fab-transition>
      <v-btn
        @click="ConfirmDelete"
        v-visible="selection.length != 0"
        fixed
        medium
        dark
        bottom
        right
        class="v-btn--example"
      >
        <v-icon>{{$vars.V('icon_delete')}}</v-icon>
        <p class="my-2">Delete {{ selection.length }} Items?</p>
      </v-btn>
    </v-fab-transition>
    <app-confirm ref="confirm"></app-confirm>
  </div>
</template>

<script>
import api from "./functions.js";
import MappingTabForm from "./mapping_tab_form.vue";
import MappingTabList from "./mapping_tab_list.vue";

export default {
  components: {
    "mapping-tab-form": MappingTabForm,
    "mapping-tab-list": MappingTabList,
  },
  data: () => ({
    //Temporary data for storing access data
    accessData: {},
    selection: [],
  }),
  methods: {
    ConfirmDelete: function () {
      this.$refs.confirm.$emit(
        "Show",
        this,
        "Confirmation",
        "Yakin ingin menghapus " + this.selection.length + " data?",
        function (ctx1) {
          ctx1.$refs.confirm.Loading("Menghapus");
          api.Delete(
            ctx1.selection,
            function (data) {
              if (data[0].feedback == "Y") {
                ctx1.$refs.list.$emit("RefreshData", ctx1.selection);
                ctx1.selection = [];
                ctx1.$refs.confirm.Dimiss();
              } else {
                ctx1.$refs.snackbar.ShowSnackbar(
                  "error",
                  data[0].feedback,
                  30000
                );
              }
            },
            function (e) {
              ctx1.$refs.snackbar.ShowSnackbar("error", e, 30000);
            }
          );
        }
      );
    },
  },
  watch: {},

  mounted: function () {
    this.$on("SelectionRow", function (data) {
      this.selection = data;
    });
    this.$on("RecordCount", function (data) {
      this.$refs.form.$emit("RecordCount", data);
    });
    api.CheckAccess2(this, this.$refs.snackbar, (data, ctx) => {
      ctx.$root.$emit("Init", data);
    });
  },
};
</script>