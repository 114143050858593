// src/plugins/vuetify/theme.js

import colors from 'vuetify/lib/util/colors'

export default {
  primary: {
    base: '#00AFF2',
    darken1: colors.purple.darken2,
  },
  secondary: '#E69500',
  background: {
    base: '#f2f2f2',
  },
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  tertiary: '#E69500',
  fourth: '#E69500',
  readonly: '#ECEFF1',
  date: '#1DE9B6',
  time: '#BCAAA4',
  numeric: '#4DD0E1',
  combo: '#FFCC80',
  more:'#00ff00',
}