<template>
    <div id="dashboard">
        <app-drawer_menu :drawer_menu_title="form.title"></app-drawer_menu>
        <v-progress-linear
            indeterminate
            color="fourth"
            rounded
            v-if="loading.page"
            ></v-progress-linear>
        <app-quotation-search
          :dialog       ="quotationsearch.dialog"
          :search_title ="quotationsearch.title"
          :src_id       ="quotationsearch.id"
          @close        ="quotationsearch.dialog=false"
          @clicked      ="GetSearch"
          v-if          ="$route.meta.module==='quotation'"
        >
        </app-quotation-search>
        <v-container>
         <v-btn
              color="primary"
              title="search"
              @click="OpenSearch('quotation')"
              block
              outlined
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>Search
            </v-btn>
        <v-layout
        wrap>
        <v-container fluid v-if="formwizard.step === 1 && form.ticket_quotype === ''">
            <v-row align="center" justify="center">
                <v-col v-for="(row, i) in products" :key="i" cols="auto">
                <template>
                    <v-card
                        class="mx-auto"
                        max-width="400"
                    >
                        <v-img
                        class="white--text align-end"
                        height="200px"
                        :src="ImagesID(row.id)"
                        >
                        <v-card-title></v-card-title>
                        </v-img>

                        <v-card-subtitle class="pb-0 title">
                        {{row.desc}}
                        </v-card-subtitle>

                        <v-card-text class="text--primary">

                        <div v-html="$functions.ReadMore(row.remarks,120)"></div>
                        <div class="font-weight-black mt-2">Choose Quotation Type?</div>
                        </v-card-text>

                        <v-card-actions>
                        <v-btn
                            color="primary"
                            text
                            @click="QuotationType('U','Umum',row.id, row.desc)"
                        >
                            Umum
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="secondary"
                            text
                            @click="QuotationType('K','Khusus',row.id, row.desc)"
                        >
                            Khusus
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-container>
        </v-layout>
        <v-layout wrap v-if="form.ticket_quotype === 'U'">
            <v-container fluid>
            <v-form enctype="multipart/form-data" ref="form_quotation_umum" lazy-validation>
                <v-row>
                    <v-col cols="12" sm="12" md="12" class="mb-n5">
                        <v-text-field 
                        v-model       ="form.ticket_id"
                        required 
                        label       	="ID"
                        placeholder 	="Automated ID"
                        id 				="ticket_id"
                        prepend-inner-icon  ="pin"
                        background-color="readonly"
                        :readonly ="true"
                        v-if="form.ticket_id"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5" md="5">
                        <app-cb
                        cb_type                ="profile"
                        cb_url                 ="apiListProfile"
                        cb_url_parameter       ="profile_lob=02&profile_lob_desc=PRINCIPAL&profile_actived=Y"
                        cb_title               ="Perusahaan Prinsipal"
                        cb_id                  ="ticket_profileid"
                        cb_desc                ="ticket_profiledesc"
                        cb_rules               ="Mohon isi Perusahaan Prinsipal"
                        cb_desc_readonly       ="readonly"
                        cb_items_id            ="profile_id"
                        cb_items_desc          ="profile_fullname"
                        cb_items_additional_1  ="profile_address"
                        cb_items_additional_2  ="profile_pic_name"
                        cb_items_additional_3  ="profile_pic_title"
                        cb_items_additional_4  ="profile_pic_phone"
                        cb_items_additional_5  ="profile_pic_email"
                        cb_items_additional_6  ="profile_pic_gender"
                        cb_items_additional_7  ="profile_phone_1"
                        :cb_push_add           ="form.ticket_quotype==='U'?'Y':'N'"
                        :cb_value_id		   ="form.ticket_profileid"
                        :cb_value_desc	       ="form.ticket_profiledesc"
                        @clicked               ="GetProfile"
                        >
                    </app-cb>
                    </v-col>
                    <!-- <v-col cols="12" sm="3" md="3" class="mb-n5">
                        <v-text-field 
                        v-model       ="form.ticket_profiledesc"
                        required 
                        label       	="Principal Name"
                        placeholder 	="Principal Name"
                        id 				="ticket_profiledesc"
                        prepend-inner-icon  ="person"
                        :rules 	  	      ="form.ticket_profiledesc_rules"
                        >
                        </v-text-field>
                    </v-col> -->
                     <v-col cols="12" sm="5" md="5" class="mt-n3">
                        <v-text-field 
                        v-model       ="form.ticket_profileaddress"
                        required 
                        label       	="Principal Address"
                        placeholder 	="Principal Address"
                        id 				="ticket_profileaddress"
                        prepend-inner-icon  ="location_on"
                        :rules 	  	      ="form.ticket_profileaddress_rules"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mt-n3">
                        <v-text-field 
                        v-model       ="form.ticket_mobile"
                        required 
                        label       	="Principal Phone"
                        placeholder 	="Principal Phone"
                        id 				="ticket_mobile"
                        prepend-inner-icon  ="call"
                        :rules 	  	      ="form.ticket_mobile_rules"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mt-n6">
                        <v-select  
                            v-model="form.ticket_profilepicgender"
                            :items="form.ticket_profilepicgender_item"
                            item-text='text'
                            item-value='id'
                            label="PIC Gender">
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="mt-n6">
                        <v-text-field 
                        v-model       ="form.ticket_profilepicname"
                        required 
                        label       	="PIC Name"
                        placeholder 	="PIC Name"
                        id 				="ticket_profilepicname"
                        prepend-inner-icon  ="person"
                        :rules 	  	      ="form.ticket_profilepicname_rules"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mt-n6">
                        <v-text-field 
                        v-model       ="form.ticket_profilepictitle"
                        required 
                        label       	="PIC Title"
                        placeholder 	="PIC Title"
                        id 				="ticket_profilepictitle"
                        prepend-inner-icon  ="badge"
                        :rules 	  	      ="form.ticket_profilepictitle_rules"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mt-n6">
                        <v-text-field 
                        v-model       ="form.ticket_profilepicmobile"
                        required 
                        label       	="PIC Mobile Phone"
                        placeholder 	="PIC Mobile Phone"
                        id 				="ticket_profilepicmobile"
                        prepend-inner-icon  ="call"
                        :rules 	  	      ="form.ticket_profilepicmobile_rules"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mt-n6">
                        <v-text-field 
                        v-model       ="form.ticket_profilepicemail"
                        required 
                        label       	="Principal PIC Email"
                        placeholder 	="Principal PIC Email"
                        id 				="ticket_profilepicemail"
                        prepend-inner-icon ="mail"
                        :rules 	  	      ="form.ticket_profilepicemail_rules"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mt-n6">
                    <label class="caption">Remarks</label>
                    <quill-editor ref="ticket_remarks"
                        v-model="form.ticket_remarks"
                    >
                    </quill-editor>
                    </v-col>
                     <v-col cols="12" sm="12" md="12">
                    <v-btn
                        class="mr-2"
                        color="primary"
                        @click="Confirm('write',form.ticket_id)"
                    >
                        Save
                    </v-btn>
                    <v-btn
                        class="mr-2"
                        color="secondary"
                        @click="PrintQuotation"
                    >
                        Print
                    </v-btn>
                    <v-btn
                        class="mr-2"
                        color="primary"
                        @click="Confirm('sendemail',form.ticket_quotype)"
                    >
                        Send Email
                    </v-btn>
                     <v-btn
                        color="secondary"
                        @click="form.ticket_quotype = 'K'"
                        class="mr-2"
                    >
                        Special Quotation
                    </v-btn>
                     </v-col>
                </v-row>
            </v-form>
            </v-container>
        </v-layout>
        <v-layout wrap v-else-if="form.ticket_quotype === 'K'" class="mt-1">
        <v-container fluid>
             <v-form enctype="multipart/form-data" ref="form_quotation_khusus" lazy-validation>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <template>
                              <v-row>
                                <v-col cols="12" sm="9" md="9" class="mb-n5">
                                    <v-text-field 
                                    v-model       ="form.ticket_id"
                                    required 
                                    placeholder 	="Automated ID"
                                    id 				="ticket_id"
                                    prepend-inner-icon  ="pin"
                                    background-color="readonly"
                                    :readonly ="true"
                                    v-if="form.ticket_id"
                                    >
                                    </v-text-field>
                                </v-col>
                                 <v-col cols="12" sm="3" md="3" class="mb-n5">
                                    <v-text-field 
                                    v-model       ="form.ticket_keydesc"
                                    required 
                                    label       	="Status"
                                    placeholder 	="Automated ID"
                                    id 				="ticket_id"
                                    background-color="readonly"
                                    :readonly ="true"
                                    v-if="form.ticket_id"
                                    >
                                    </v-text-field>
                                </v-col>
                                </v-row>
                            <v-stepper
                                v-model="formwizard.step"
                                vertical
                                :class="form.ticket_id!==''?'':'mt-3'"
                            >
                                <v-stepper-step
                                :complete="formwizard.step > 1"
                                step="1"
                                >
                                <span v-if="form.ticket_profiledesc === ''">Data Prinsipal</span><span v-else>{{form.ticket_profiledesc}}</span>
                                <small>Masukkan data Perusahaan Prinsipal</small>
                                </v-stepper-step>

                                <v-stepper-content step="1">
                                <v-card
                                    flat
                                >
                                <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="5" md="5" class="mt-n3">
                                        <app-cb
                                        cb_type                ="profile"
                                        cb_url                 ="apiListProfile"
                                        cb_url_parameter       ="profile_lob=02&profile_lob_desc=PRINCIPAL&profile_actived=Y"
                                        cb_title               ="Perusahaan Prinsipal"
                                        cb_id                  ="ticket_profileid"
                                        cb_desc                ="ticket_profiledesc"
                                        cb_rules               ="Mohon isi Perusahaan Prinsipal"
                                        cb_desc_readonly       ="readonly"
                                        cb_items_id            ="profile_id"
                                        cb_items_desc          ="profile_fullname"
                                        cb_items_additional_1  ="profile_address"
                                        cb_items_additional_2  ="profile_pic_name"
                                        cb_items_additional_3  ="profile_pic_title"
                                        cb_items_additional_4  ="profile_pic_phone"
                                        cb_items_additional_5  ="profile_pic_email"
                                        cb_items_additional_6  ="profile_pic_gender"
                                        cb_items_additional_7  ="profile_phone_1"
                                        :cb_value_id		   ="form.ticket_profileid"
                                        :cb_value_desc	       ="form.ticket_profiledesc"
                                        @clicked               ="GetProfile"
                                        >
                                        </app-cb>
                                    </v-col>
                                    <!-- Address -->
                                    <v-col cols="12" sm="5" md="5" class="mt-n6">
                                        <v-text-field 
                                        v-model                ="form.ticket_profileaddress"
                                        required 
                                        label       	       ="Alamat Prinsipal"
                                        placeholder 	       ="Alamat Prinsipal"
                                        id 				       ="ticket_profileaddress"
                                        :readonly              = "true"
                                        >
                                        </v-text-field>
                                    </v-col>
                                     <v-col cols="12" sm="2" md="2" class="mt-n6">
                                    <v-text-field 
                                        v-model       ="form.ticket_mobile"
                                        required 
                                        label       	="Telp Prinsipal"
                                        placeholder 	="Telp Prinsipal"
                                        id 				="ticket_mobile"
                                        prepend-inner-icon  ="call"
                                        :rules 	  	      ="form.ticket_mobile_rules"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" class="mt-n5">
                                        <v-select  
                                            v-model="form.ticket_profilepicgender"
                                            :items="form.ticket_profilepicgender_item"
                                            item-text='text'
                                            item-value='id'
                                            label="PIC Gender">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" class="mt-n5">
                                        <v-text-field 
                                        v-model       ="form.ticket_profilepicname"
                                        required 
                                        label       	="PIC Name"
                                        placeholder 	="PIC Name"
                                        id 				="ticket_profilepicname"
                                        prepend-inner-icon  ="person"
                                        :rules 	  	      ="form.ticket_profilepicname_rules"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" class="mt-n5">
                                        <v-text-field 
                                        v-model       ="form.ticket_profilepictitle"
                                        required 
                                        label       	="PIC Title"
                                        placeholder 	="PIC Title"
                                        id 				="ticket_profilepictitle"
                                        prepend-inner-icon  ="badge"
                                        :rules 	  	      ="form.ticket_profilepictitle_rules"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" class="mt-n5">
                                        <v-text-field 
                                        v-model       ="form.ticket_profilepicmobile"
                                        required 
                                        label       	="PIC Mobile Phone"
                                        placeholder 	="PIC Mobile Phone"
                                        id 				="ticket_profilepicmobile"
                                        prepend-inner-icon  ="call"
                                        :rules 	  	      ="form.ticket_profilepicmobile_rules"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" class="mt-n5">
                                        <v-text-field 
                                        v-model       ="form.ticket_profilepicemail"
                                        required 
                                        label       	="Principal PIC Email"
                                        placeholder 	="Principal PIC Email"
                                        id 				="ticket_profilepicemail"
                                        prepend-inner-icon  ="mail"
                                        :rules 	  	      ="form.ticket_profilepicemail_rules"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" v-show="form.ticket_profileid">
                                        <v-tabs
                                            v-model="tabs"
                                            background-color="white"
                                            color="secondary"
                                            next-icon="mdi-arrow-right-bold-box-outline"
                                            prev-icon="mdi-arrow-left-bold-box-outline"
                                            show-arrows
                                        >
                                            <v-tab id="taborganization" href="#tab-organization" v-if="form.ticket_profileid">
                                            Organization
                                            </v-tab>
                                            <v-tab id="tabportfolio" href="#tab-portfolio" v-if="form.ticket_profileid">
                                            Portfolio
                                            </v-tab>
                                            <v-tab id="tabcapital" href="#tab-capital" v-if="form.ticket_profileid">
                                            Finance Report
                                            </v-tab>
                                            <v-tab id="tabattachment" href="#tab-attachment" v-if="form.ticket_profileid">
                                            Attachment
                                            </v-tab>
                                            <v-tab-item value="tab-organization">
                                            <app-profile-organization :value="true" :id="form.ticket_profileid"/>
                                            </v-tab-item>
                                            <v-tab-item value="tab-portfolio">
                                            <app-profile-porto :value="true" :id="form.ticket_profileid"/>
                                            </v-tab-item>
                                            <v-tab-item value="tab-capital">
                                            <app-profile-capital :value="true" :id="form.ticket_profileid"/>
                                            </v-tab-item>
                                            <v-tab-item value="tab-attachment">
                                            <app-attachment modul="profile" :refno="form.ticket_profileid"/>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-col>
                                </v-row>
                                </v-card-text>
                                </v-card>
                                <v-btn
                                    color="primary"
                                    @click="Step('2','','')"
                                >
                                    Continue
                                </v-btn>
                                <v-spacer></v-spacer>
                                </v-stepper-content>

                                <v-stepper-step
                                :complete="formwizard.step > 2"
                                step="2"
                                >
                                <span v-if="form.ticket_pholderdesc === ''">Data Obligee</span><span v-else>{{form.ticket_pholderdesc}}</span>
                                <small>Masukkan data Perusahaan Obligee</small>
                                </v-stepper-step>

                                <v-stepper-content step="2">
                                <v-card
                                    flat
                                >
                                <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="mt-n3">
                                        <app-cb
                                        cb_type                ="profile"
                                        cb_url                 ="apiListProfile"
                                        cb_url_parameter       ="profile_lob=01&profile_lob_desc=OBLIGEE&profile_actived=Y"
                                        cb_title               ="Perusahaan Obligee"
                                        cb_id                  ="ticket_pholderid"
                                        cb_desc                ="ticket_pholderdesc"
                                        cb_rules               ="Mohon isi Perusahaan Obligee"
                                        cb_desc_readonly       ="readonly"
                                        cb_items_id            ="profile_id"
                                        cb_items_desc          ="profile_fullname"
                                        cb_items_additional_1  ="profile_address"
                                        :cb_value_id		   ="form.ticket_pholderid"
                                        :cb_value_desc	       ="form.ticket_pholderdesc"
                                        @clicked               ="GetPholder"
                                        >
                                        </app-cb>
                                    </v-col>
                                    <!-- Address -->
                                    <v-col cols="12" sm="6" md="6" class="mt-n6">
                                        <v-text-field 
                                        v-model                ="form.ticket_pholderaddress"
                                        required 
                                        label       	       ="Alamat Obligee"
                                        placeholder 	       ="Alamat Obligee"
                                        id 				       ="ticket_pholderaddress"
                                        :readonly              = "true"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                </v-card-text>
                                </v-card>
                                <v-btn
                                    color="primary"
                                    @click="Step('3','','')"
                                    class="mr-2"
                                >
                                    Continue
                                </v-btn>
                                <v-btn
                                    color="secondary"
                                    @click="formwizard.step = 1"
                                >
                                    Back
                                </v-btn>
                                </v-stepper-content>

                                <v-stepper-step
                                :complete="formwizard.step > 3"
                                step="3"
                                >
                                <span v-if="form.ticket_insurancedesc === ''">Perusahaan Asuransi atau Penjaminan</span><span v-else>{{form.ticket_insurancedesc}}</span>
                                <small>Pilih perusahaan penerbit sertifikat</small>
                                </v-stepper-step>

                                <v-stepper-content step="3">
                                <v-card
                                    flat
                                >
                                <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="mt-n3">
                                        <app-cb
                                        cb_type                ="profile"
                                        cb_url                 ="apiListProfile"
                                        cb_url_parameter       ="profile_lob=05&profile_lob_desc=INSURANCE&profile_actived=Y"
                                        cb_title               ="Perusahaan Asuransi/Penjaminan"
                                        cb_id                  ="ticket_insuranceid"
                                        cb_desc                ="ticket_insurancedesc"
                                        cb_rules               ="Mohon isi Perusahaan Asuransi/Penjaminan"
                                        cb_desc_readonly       ="readonly"
                                        cb_items_id            ="profile_id"
                                        cb_items_desc          ="profile_cgroup_desc"
                                        cb_items_additional_1  ="profile_address"
                                        cb_items_additional_2  ="profile_cgroup"
                                        cb_items_additional_3  ="profile_branchunit"
                                        cb_items_additional_4  ="profile_name"
                                        :cb_value_id		   ="form.ticket_insuranceid"
                                        :cb_value_desc	       ="form.ticket_insurancecgroupdesc"
                                        @clicked               ="GetInsurance"
                                        >
                                        </app-cb>
                                    </v-col>
                                    <!-- Address -->
                                    <v-col cols="12" sm="6" md="6" class="mt-n6">
                                        <v-text-field 
                                        v-model                ="form.ticket_insurancebranchunit"
                                        required 
                                        label       	       ="Cabang Asuransi"
                                        placeholder 	       ="Cabang Asuransi"
                                        id 				       ="ticket_insurancebranchunit"
                                        :readonly              = "true"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <!-- Address -->
                                    <v-col cols="12" sm="12" md="12" class="mt-n6">
                                        <v-text-field 
                                        v-model                ="form.ticket_insuranceaddress"
                                        required 
                                        label       	       ="Alamat Asuransi"
                                        placeholder 	       ="Alamat Asuransi"
                                        id 				       ="ticket_insuranceaddress"
                                        :readonly              = "true"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                </v-card-text>
                                </v-card>
                                <v-btn
                                    color="primary"
                                    @click="Step('4','','')"
                                    class="mr-2"
                                >
                                    Continue
                                </v-btn>
                                <v-btn color="secondary" @click="formwizard.step = 2">
                                    Back
                                </v-btn>
                                </v-stepper-content>
                                <v-stepper-step
                                :complete="formwizard.step > 4"
                                step="4"
                                >
                                <span v-if="form.ticket_bankdesc === ''">Data Bank</span><span v-else>{{form.ticket_bankdesc}}</span>
                                <small>Masukkan data Bank</small>
                                </v-stepper-step>

                                <v-stepper-content step="4">
                                    <v-card
                                            flat
                                        >
                                    <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6" class="mt-n3">
                                            <app-cb
                                            cb_type               ="profile"
                                            cb_url                ="apiListProfile"
                                            :cb_url_parameter     ="`profile_cgrouprate=${form.ticket_insurancecgroup}&profile_lob=03&profile_lob_desc=BANK&profile_actived=Y`"
                                            cb_title              ="Bank"
                                            cb_id                 ="ticket_bank"
                                            cb_desc               ="ticket_bankdesc"
                                            cb_rules              =""
                                            cb_desc_readonly      ="readonly"
                                            cb_items_id           ="profile_id"
                                            cb_items_desc         ="profile_cgroup_desc"
                                            cb_items_additional_1 ="profile_address"
                                            cb_items_additional_2 ="profile_branchunit"
                                            cb_items_additional_3 ="profile_name"
                                            :cb_value_id		  ="form.ticket_bank"
                                            :cb_value_desc		  ="form.ticket_bankcgroupdesc"
                                            @clicked              ="GetBank"          
                                            >
                                            </app-cb>
                                        </v-col>
                                        <!-- Cabang -->
                                        <v-col cols="12" sm="6" md="6" class="mt-n6">
                                            <v-text-field 
                                            v-model       ="form.ticket_bankbranchunit"
                                            required 
                                            label         ="Cabang Bank"
                                            placeholder   ="Cabang Bank"
                                            id 			  ="ticket_bankbranchunit"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <!-- Address -->
                                        <v-col cols="12" sm="12" md="12" class="mt-n6">
                                            <v-text-field 
                                            v-model       ="form.ticket_bankaddress"
                                            required 
                                            label         ="Bank Address"
                                            placeholder   ="Bank Address"
                                            id 			  ="ticket_bankaddress"
                                            :readonly     = "true"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    </v-card-text>
                                    </v-card>
                                     <v-btn
                                        color="primary"
                                        @click="Step('5','','')"
                                        class="mr-2"
                                    >
                                        Continue
                                    </v-btn>
                                    <v-btn color="secondary" @click="formwizard.step = 3">
                                        Back
                                    </v-btn>
                                </v-stepper-content>
                                <v-stepper-step step="5">
                                PROJECT INFORMATION
                                </v-stepper-step>
                                <v-stepper-content step="5">
                                <v-card
                                flat
                                >
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" sm="5" md="5" class="mb-n5">
                                                <v-text-field 
                                                v-model       ="form.ticket_projectname"
                                                required 
                                                label       	="Project Name"
                                                placeholder 	="Project Name"
                                                id 				="ticket_projectname"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" class="mb-n5">
                                                <v-text-field 
                                                v-model       ="form.ticket_projectbasedon"
                                                required 
                                                label       	="Project Based On(No/Contract Date/SPK)"
                                                placeholder 	="Project Based On(No/Contract Date/SPK)"
                                                id 				="ticket_projectbasedon"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <!-- Project Date -->
                                            <v-col cols="6" sm="2" md="2">
                                            <v-dialog
                                                ref                 ="ticket_projectdate"
                                                v-model             ="modal.ticket_projectdate"
                                                :return-value.sync  ="form.ticket_projectdate"
                                                persistent
                                                width               ="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model             ="form.ticket_projectdate"
                                                    label               ="Underlying Date"
                                                    prepend-inner-icon  ="event"
                                                    background-color    ="date"
                                                    v-on                ="on"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker 
                                                v-model ="form.ticket_projectdate" 
                                                scrollable                      
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="modal.ticket_projectdate = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.ticket_projectdate.save(form.ticket_projectdate)">OK</v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" class="mb-n5">
                                                <v-text-field 
                                                v-model       ="form.ticket_projectaddress"
                                                required 
                                                label       	="Project Address"
                                                placeholder 	="Project Address"
                                                id 				="ticket_projectaddress"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <!-- Inception Date -->
                                            <v-col cols="6" sm="2" md="2" class="mt-n3">
                                            <v-dialog
                                                ref                 ="ticket_inception"
                                                v-model             ="modal.ticket_inception"
                                                :return-value.sync  ="form.ticket_inception"
                                                persistent
                                                width               ="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model             ="form.ticket_inception"
                                                    label               ="Inception Date"
                                                    prepend-inner-icon  ="event"
                                                    background-color    ="date"
                                                    v-on                ="on"
                                                    @change             ="IEDMOnChange('inception')"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker 
                                                v-model ="form.ticket_inception" 
                                                scrollable                      
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="modal.ticket_inception = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.ticket_inception.save(form.ticket_inception), IEDMOnChange('inception')">OK</v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                            </v-col>
                                            <!-- Expiry Date -->
                                            <v-col cols="6" sm="2" md="2" class="mt-n3">
                                            <v-dialog
                                                ref                 ="ticket_expiry"
                                                v-model             ="modal.ticket_expiry"
                                                :return-value.sync  ="form.ticket_expiry"
                                                persistent
                                                width               ="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model             ="form.ticket_expiry"
                                                    label               ="Expiry Date"
                                                    prepend-inner-icon  ="event"
                                                    background-color    ="date"
                                                    v-on                ="on"
                                                    @change             ="IEDMOnChange('expiry')"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker 
                                                v-model ="form.ticket_expiry" 
                                                scrollable     
                                                :min = "form.ticket_inception"               
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="modal.ticket_expiry = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.ticket_expiry.save(form.ticket_expiry), IEDMOnChange('expiry')">OK</v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                            </v-col>
                                            <!-- Day -->
                                            <v-col cols="6" sm="1" md="1" class="mt-n3">
                                                <v-text-field 
                                                v-model           ="form.ticket_durationday"
                                                label             ="Day"
                                                background-color  ="numeric"
                                                append-icon       ="event"
                                                reverse
                                                @change           ="IEDMOnChange('day')"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <!-- Day -->
                                            <v-col cols="6" sm="1" md="1" class="mt-n3">
                                                <v-text-field 
                                                v-model           ="form.ticket_durationmonth"
                                                label             ="Month"
                                                background-color  ="numeric"
                                                append-icon       ="event"
                                                reverse
                                                @change           ="IEDMOnChange('month')"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <app-cb
                                                cb_type           ="riskcov"
                                                cb_url            ="apiListRiskcov"
                                                cb_url_parameter  ="riskcov_actived=Y"
                                                cb_title          ="Risk Coverage"
                                                cb_id             ="ticket_riskcov"
                                                cb_desc           ="ticket_riskcovdesc"
                                                cb_rules          ="Please fill Risk Coverage"
                                                cb_desc_readonly  ="readonly"
                                                cb_items_id       ="riskcov_id"
                                                cb_items_desc     ="riskcov_desc"
                                                :cb_value_id	  ="form.ticket_riskcov"
                                                :cb_value_desc	  ="form.ticket_riskcovdesc"
                                                @clicked          ="GetRiskCov"
                                                >
                                                </app-cb>
                                            </v-col>
                                            <v-col cols="12" sm="1" md="1" class="mt-n3">
                                                <app-cb
                                                cb_single             = "Y"
                                                cb_type               ="currency"
                                                cb_url                ="apiListCurrency"
                                                cb_url_parameter      ="currency_actived=Y"
                                                cb_title              ="CCY"
                                                cb_id                 ="ticket_ccy"
                                                cb_desc               ="ticket_ccy"
                                                cb_rules              =""
                                                cb_desc_readonly      ="readonly"
                                                cb_items_id           ="currency_id"
                                                cb_items_desc         ="currency_desc"
                                                :cb_value_id		  ="form.ticket_ccy"
                                                >
                                                </app-cb>
                                                </v-col>
                                            <!-- Project Amount -->
                                            <v-col cols="12" sm="3" md="3" class="mt-n6">
                                                <vuetify-money
                                                v-model           ="form.ticket_tsi"
                                                v-bind:options    ="formatamount"
                                                label             ="Project Amount"
                                                placeholder       ="Project Amount"
                                                background-color  ="numeric"
                                                v-bind:properties ="properties"
                                                @input            ="BondValue"
                                                />
                                            </v-col>
                                             <v-col cols="12" sm="1" md="1" class="mt-n6">
                                            <vuetify-money
                                                v-model           ="form.ticket_pcttsi"
                                                v-bind:options    ="formatrate"
                                                label             ="Bond PCT(%)"
                                                placeholder       ="Bond PCT(%)"
                                                background-color  ="numeric"
                                                v-bind:properties ="properties"
                                                @input            ="BondValue"
                                            />
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3" class="mt-n6">
                                            <vuetify-money
                                                v-model           ="form.ticket_guaranteevalue"
                                                v-bind:options    ="formatrate"
                                                label             ="Bond Value"
                                                placeholder       ="Bond Value"
                                                background-color  ="numeric"
                                                v-bind:properties ="properties"
                                                :readonly          ="true"
                                            />
                                            </v-col>
                                            </v-row>
                                            <!-- <v-row>
                                            <v-col cols="12" sm="3" md="3" class="mt-n3">
                                            <app-cb
                                                cb_type             ="code"
                                                cb_url              ="apiListCode"
                                                cb_url_parameter    ="code_actived=Y&code_group=COLLATERAL"
                                                cb_title            ="Collateral"
                                                cb_id               ="ticket_collateral"
                                                cb_desc             ="ticket_collateraldesc"
                                                cb_desc_readonly    ="readonly"
                                                cb_rules            ="Please fill Collateral"
                                                cb_items_id         ="code_id"
                                                cb_items_desc       ="code_desc"
                                                :cb_value_id        ="form.ticket_collateral"
                                                :cb_value_desc      ="form.ticket_collateraldesc"
                                            >
                                            </app-cb>
                                            </v-col>
                                            <v-col cols="12" sm="1" md="1" class="mt-n6">
                                            <vuetify-money
                                                v-model           ="form.ticket_collateralpct"
                                                v-bind:options    ="formatrate"
                                                label             ="In Percentage"
                                                placeholder       ="In Percentage"
                                                background-color  ="numeric"
                                                v-bind:properties ="properties"
                                            />
                                            </v-col>
                                            <v-col cols="12" sm="1" md="1" class="mt-n3">
                                            <app-cb
                                                cb_type="currency"
                                                cb_url="apiListCurrency"
                                                cb_url_parameter="currency_actived=Y"
                                                cb_title="CCY"
                                                cb_id="ticket_collateralccy"
                                                cb_desc="ticket_collateralccydesc"
                                                cb_rules=""
                                                cb_desc_readonly="readonly"
                                                cb_items_id="currency_id"
                                                cb_items_desc="currency_desc"
                                                :cb_value_id="form.ticket_collateralccy"
                                                cb_single="Y"
                                            >
                                            </app-cb>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3" class="mt-n6">
                                            <vuetify-money
                                                v-model           ="form.ticket_collateralamount"
                                                v-bind:options    ="formatamount"
                                                label             ="In Amount"
                                                placeholder       ="In Amount"
                                                background-color  ="numeric"
                                                v-bind:properties ="properties"
                                            />
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" class="mt-n3">
                                                <v-textarea
                                                v-model           ="form.ticket_collateralremarks"
                                                label             ="Collateral Remarks"
                                                :rules 	  	      ="form.ticket_collateralremarks_rules"
                                                >
                                                </v-textarea>
                                            </v-col>
                                            </v-row> -->

                
                                    </v-card-text>
                                </v-card>
                                <v-btn
                                    color="primary"
                                    @click="Step('6','', '')"
                                    class="mr-2"
                                >
                                    Continue
                                </v-btn>
                                <v-btn color="secondary" @click="BackData">
                                    Back
                                </v-btn>
                                </v-stepper-content>
                                <v-stepper-step step="6">
                                CALCULATE IJP
                                </v-stepper-step>
                                <v-stepper-content step="6">
                                    <v-progress-linear
                                    indeterminate
                                    color="fourth"
                                    rounded
                                    v-if="loading.calculate"
                                    ></v-progress-linear>
                                 <v-card
                                            flat
                                        >
                                    <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="2" md="2">
                                             <vuetify-money
                                                v-model           ="form.ticket_sourcefee"
                                                v-bind:options    ="formatrate"
                                                label             ="Additional Fee %"
                                                placeholder       ="Additional Fee %"
                                                background-color  ="numeric"
                                                v-bind:properties ="properties"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="2" md="2" class="mt-3">
                                            <v-btn class="primary white--text" @click="AddCalculate" v-if="form.calculateedit === false && form.ticket_status !== 'C'">Submit</v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                        <template>
                                            <v-btn small rounded class="secondary mr-2 white--text" @click="form.calculateedit = !form.calculateedit" v-if="form.ticket_status !== 'C'">Edit Calculate</v-btn>
                                            <v-btn small rounded class="primary white--text" v-if="form.calculateedit" @click="Confirm('calculate','')">Save Calculate</v-btn>
                                            <v-simple-table>
                                                <template>
                                                <thead>
                                                    <tr>
                                                    <th class="text-left">
                                                        Profile
                                                    </th>
                                                    <th class="text-left">
                                                        Rate (%)
                                                    </th>
                                                     <th class="text-left">
                                                        Bond Value
                                                    </th>
                                                    <th class="text-left">
                                                        IJP/Provisi
                                                    </th>
                                                    <th class="text-left">
                                                       Fee
                                                    </th>
                                                    <th class="text-left">
                                                       Duty
                                                    </th>
                                                     <th class="text-left">
                                                        Total
                                                    </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template  
                                                    v-for="(item, index) in detail.calculate">
                                                    <tr
                                                    :key="item.calcquotation_type"
                                                    >
                                                    <td>{{ item.calcquotation_profile_desc }}</td>
                                                    <td class="text-right" v-if="form.calculateedit"><vuetify-money
                                                                        v-model           ="item.calcquotation_rate"
                                                                        v-bind:options    ="formatrate"
                                                                        background-color  ="numeric"
                                                                        dense
                                                                        v-bind:properties ="properties"
                                                                        style="width:50px"
                                                                        @input="ChangeCalc(index)"
                                                                        /></td>
                                                    <td class="text-right" v-else>{{$functions.NewFormatNumber(item.calcquotation_rate,2)}} {{$functions.RateUnitPCT(item.calcquotation_rate_unit)}}</td>
                                                    <td class="text-right">{{ $functions.NewFormatNumber(form.ticket_guaranteevalue) }}</td>
                                                    <td class="text-right" v-if="form.calculateedit"><vuetify-money
                                                                        v-model           ="item.calcquotation_premium"
                                                                        v-bind:options    ="formatamount"
                                                                        background-color  ="numeric"
                                                                        dense
                                                                        v-bind:properties ="properties"
                                                                        style="width:100px"
                                                                        @input="ChangeRate(index)"
                                                                        /></td>
                                                    <td class="text-right" v-else>{{$functions.NewFormatNumber(item.calcquotation_premium)}}</td>
                                                    <td class="text-right" v-if="form.calculateedit"><vuetify-money
                                                                        v-model           ="item.calcquotation_fee"
                                                                        v-bind:options    ="formatamount"
                                                                        background-color  ="numeric"
                                                                        dense
                                                                        v-bind:properties ="properties"
                                                                        style="width:100px"
                                                                        @input="ChangeCalc(index)"
                                                                        /></td>
                                                    <td class="text-right" v-else>{{$functions.NewFormatNumber(item.calcquotation_fee)}}</td>
                                                    <td class="text-right" v-if="form.calculateedit"><vuetify-money
                                                                        v-model           ="item.calcquotation_duty"
                                                                        v-bind:options    ="formatamount"
                                                                        background-color  ="numeric"
                                                                        dense
                                                                        v-bind:properties ="properties"
                                                                        style="width:100px"
                                                                        @input="ChangeCalc(index)"
                                                                        /></td>
                                                    <td class="text-right" v-else>{{$functions.NewFormatNumber(item.calcquotation_duty)}}</td>
                                                    <td class="text-right">{{ $functions.NewFormatNumber(item.calcquotation_premium * 1 + item.calcquotation_fee * 1 + item.calcquotation_duty * 1) }}</td>
                                                    </tr>
                                                    </template>
                                                    <tr>
                                                        <td colspan="6" class="text-right">Grand Total</td>
                                                        <td class="text-right">{{$functions.NewFormatNumber(TotalCalculation)}}</td>
                                                    </tr>
                                                </tbody>
                                                </template>
                                            </v-simple-table>
                                        </template>
                                        </v-col>
                                         <v-col cols="12" sm="6" md="6">
                                            <app-cb
                                            cb_type                ="profileaccount"
                                            cb_url                 ="apiListProfileAccount"
                                            cb_url_parameter       ="profileaccount_actived=Y"
                                            cb_title               ="Account"
                                            cb_id                  ="ticket_bankid"
                                            cb_desc                ="ticket_bankcompanyname"
                                            cb_rules               ="Mohon pilih Rekening"
                                            cb_desc_readonly       ="readonly"
                                            cb_items_id            ="profileaccount_id"
                                            cb_items_desc          ="profileaccount_accountname"
                                            cb_items_additional_1  ="profileaccount_accountno"
                                            cb_items_additional_2  ="profileaccount_bankcompanyname"
                                            cb_items_additional_3  ="profileaccount_bankbranch"
                                            cb_items_additional_4  ="profileaccount_bank"
                                            cb_items_additional_5  ="profileaccount_currency"
                                            :cb_value_id		   ="form.ticket_bankidref"
                                            :cb_value_desc	       ="form.ticket_bankaccountname"
                                            @clicked               ="GetProfileAccount"
                                            >
                                        </app-cb>
                                        </v-col>
                                        <!-- Account No -->
                                        <v-col cols="12" sm="6" md="6" class="mt-n3">
                                            <v-text-field 
                                            v-model                ="form.ticket_bankaccountno"
                                            required 
                                            label       	       ="Nomor Rekening"
                                            placeholder 	       ="Nomor Rekening"
                                            id 				       ="ticket_bankaccountno"
                                            :readonly              ="true"
                                            background-color       ="readonly"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="mt-n3">
                                            <v-text-field 
                                            v-model                ="form.ticket_bankcompanyname"
                                            required 
                                            label       	       ="Bank"
                                            placeholder 	       ="Bank"
                                            id 				       ="ticket_bankcompanyname"
                                            :readonly              = "true"
                                            background-color       ="readonly"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="mt-n3">
                                            <v-text-field 
                                            v-model                ="form.ticket_bankbranch"
                                            required 
                                            label       	       ="Cabang Bank"
                                            placeholder 	       ="Cabang Bank"
                                            id 				       ="ticket_bankbranch"
                                            :readonly              = "true"
                                            background-color       ="readonly"
                                            >
                                            </v-text-field>
                                        </v-col>
                                         <v-col cols="12" sm="12" md="12" class="mt-n3">
                                        <label class="caption">Term & Conditions</label>
                                        <quill-editor ref="ticket_tc"
                                            v-model="form.ticket_tc"
                                        >
                                        </quill-editor>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                        <label class="caption">Remarks</label>
                                        <quill-editor ref="ticket_remarks"
                                            v-model="form.ticket_remarks"
                                        >
                                        </quill-editor>
                                        </v-col>
                                    </v-row>
                                    </v-card-text>
                                <v-btn
                                    class="mr-2"
                                    color="secondary"
                                    @click="formwizard.step = 5"
                                >
                                    Back
                                </v-btn>
                                <v-btn
                                    class="mr-2"
                                    color="primary"
                                    @click="Confirm('write',form.ticket_id)"
                                    :disabled="form.ticket_status!=='C'?false:true"
                                    v-if="form.calculateedit === false"
                                >
                                    Save
                                </v-btn>
                                <v-btn
                                    class="mr-2 white--text"
                                    v-if="form.ticket_id"
                                    color="green"
                                    @click="Confirm('closing',form.ticket_id)"
                                    :disabled="form.ticket_status!=='C'?false:true"
                                >
                                    Request Closing
                                </v-btn>
                                <v-btn
                                    class="mr-2"
                                    v-if="form.ticket_id"
                                    color="red"
                                    @click="Confirm('loss',form.ticket_id)"
                                    :disabled="form.ticket_status!=='C'?false:true"
                                >
                                    Loss Business
                                </v-btn>
                                <v-btn
                                    v-if="form.ticket_id"
                                    class="mr-2"
                                    color="secondary"
                                    @click="PrintQuotation"
                                >
                                    Print
                                </v-btn>
                                 <v-btn
                                    v-if="form.ticket_id"
                                    color="primary"
                                    @click="Confirm('sendemail',form.ticket_quotype)"
                                >
                                    Send Email
                                </v-btn>
                                </v-card>
                                </v-stepper-content>
                            </v-stepper>
                            </template>
                        </v-col>
                    </v-row>
            </v-form>
        </v-container>
        </v-layout>
        <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
        <v-card>
            <v-toolbar dark color="fourth" dense>
            <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
            </v-btn>
            <v-toolbar-title>Error Messages</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-2 ml-n2">
            <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
            </v-card-text>
        </v-card>
        </v-dialog>

        <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
            <div v-if="confirm.subtitle">{{confirm.subtitle}}</div>
            <v-row v-if="url_type === 'lossbusiness'">
            <v-col cols="12" sm="12" md="12" class="mt-3">
             <app-cb
                    cb_type             ="code"
                    cb_url              ="apiListCode"
                    cb_url_parameter    ="code_actived=Y&code_group=LOSSREASON"
                    cb_title            ="Loss Reason"
                    cb_id               ="ticket_closereason"
                    cb_desc             ="ticket_closereasondesc"
                    cb_desc_readonly    ="readonly"
                    cb_rules            ="Please fill Loss Reason"
                    cb_items_id         ="code_id"
                    cb_items_desc       ="code_desc"
                    :cb_value_id        ="form.ticket_closereason"
                    :cb_value_desc      ="form.ticket_closereasondesc"
                    @clicked            ="GetLossReason"
                >
                </app-cb>
            </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                id        ="dialog" 
                :color    ="Variable('confirm',1)"
                :disabled ="confirm.text === 'Ok' ? false:true"
                @click    ="Loading">{{confirm.text}}
                </v-btn>
                <v-btn
                :color="Variable('confirm',2)"
                @click="confirm.dialog=false"
                >
                Close
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <v-snackbar
        v-model	  ="snackbar.dialog"
        :timeout  ="snackbar.timeout"
        color	  ="fourth"
        rounded	  ="pill"
        top
        >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
        </template>
        </v-snackbar>
        </v-container>
        <app-print v-model="dialog.print" modul="quotation" :id="form.ticket_id" :refid="form.ticket_quotype"></app-print>
    </div>
    </template>
    <script>

    export default {
        data: () => ({
            url_type: '',
            access: {
                read: 0,
                add: 0,
                edit: 0,
                delete: 0,
            },
            remarks: {
                feedback: "",
                dialog: false,
            },
            snackbar: {
                dialog: false,
                color: "success",
                text: "",
                timeout: 3000,
            },
            confirm: {
                dialog: false,
                title: "",
                subtitle: "",
                text: "Ok",
            },
            loading: {
                page      : false,
                calculate : false,
            },
            modal: {
                date: false,
                date_time: false,
                ticket_inception: false,
                ticket_expiry: false,
                ticket_projectdate: false,
            },
            formatamount: {
                locale: "id-ID",
                prefix: "",
                suffix: "",
                length: 15,
                precision: 0
            },
            formatrate: {
                locale: "id-ID",
                prefix: "",
                suffix: "",
                length: 5,
                precision: 2
            },
            properties: {
                reverse: true,
            },
            form: {
                title: 'Quotation',
                id: '',
                desc: '',
                ticket_id: '',
                ticket_subclass: '',
                ticket_mobile: '',
                ticket_mobile_rules: [(v) => !!v || "Please fill Office Phone"],
                ticket_insuranceid: '',
                ticket_insurancedesc: '',
                ticket_insurancecgroupdesc: '',
                ticket_insuranceaddress: '',
                ticket_pholderid: '',
                ticket_pholderdesc: '',
                ticket_pholderaddress: '',
                ticket_profileid: '',
                ticket_profiledesc: '',
                ticket_profiledesc_rules: [(v) => !!v || "Please fill Principal Name"],
                ticket_profilepicgender: 'M',
                ticket_profilepicgender_item: [{'id':'M','text':'MALE'},{'id':'F','text':'FEMALE'}],
                ticket_profilepicgender_rules: [(v) => !!v || "Please fill PIC Gender"],
                ticket_profilepicname: '',
                ticket_profilepicname_rules: [(v) => !!v || "Please fill PIC Name"],
                ticket_profilepictitle: '',
                ticket_profilepictitle_rules: [(v) => !!v || "Please fill PIC Title"],
                ticket_profilepicmobile: '',
                ticket_profilepicmobile_rules: [(v) => !!v || "Please fill PIC Mobile Phone"],
                ticket_profilepicemail: '',
                ticket_profilepicemail_rules: [(v) => !!v || "Please fill PIC Email"],
                ticket_profileaddress: '',
                ticket_profileaddress_rules: [(v) => !!v || "Please fill Principal Address"],
                ticket_bank: '',
                ticket_bankdesc: '',
                ticket_bankaddress: '',
                ticket_projectname: '',
                ticket_projectbasedon: '',
                ticket_projectdate: '',
                ticket_projectaddress: '',
                ticket_inception: '',
                ticket_expiry: '',
                ticket_durationday: 1,
                ticket_durationmonth: 0,
                ticket_extendday: 0,
                ticket_instype: 'D',
                ticket_policytype: 'S',
                ticket_riskcov: '',
                ticket_riskcovdesc: '',
                ticket_interest: 'INCL01',
                ticket_ccy: 'IDR',
                ticket_tsi: 0,
                ticket_pcttsi: 5,
                ticket_guaranteevalue: 0,
                ticket_collateral: 'C',
                ticket_collateraldesc: 'CASH',
                ticket_collateralpct: 5,
                ticket_collateralccy: 'IDR',
                ticket_collateralamount: 0,
                ticket_collateralremarks: '',
                ticket_sourceid: '',
                ticket_sourcename: '',
                ticket_sourcefee: 0,
                ticket_quotype: '',
                ticket_rate: 0,
                ticket_bankid: '',
                ticket_bankidref: '',
                ticket_bankiddesc: '',
                ticket_bankaccountno: '',
                ticket_bankaccountname: '',
                ticket_bankbranch: '',
                ticket_bankcompanyname: '',
                ticket_bankccy: '',
                ticket_insurancecgroup: '',
                ticket_remarks: '<ol><li>Indikasi Rate diatas sewaktu-waktu dapat berubah, sesuai Kebijakan perusahaan Penjamin/Asuransi dan Bank;</li><li>Belum termasuk biaya Administrasi di Asuransi &amp; Bank;</li><li>Biaya Jasa PT Focus Inservindo sebesar 0,5% (negotiable);</li><li>Semua Pembayaran dilakukan melalui Rekening PT Focus Inservindo;</li><li>Dana Cash Collateral (jika dipersyaratkan), ditransfer ke Rekening Perusahaan Asuransi;&nbsp;</li><li>Syarat dan ketentuan lainnya sesuai ketentuan perusahaan asuransi dan Bank.</li></ol>',
                ticket_tc: '',
                ticket_closereason: '',
                ticket_closereasondesc: '',
                ticket_status: '',
                ticket_statusdesc: '',
                ticket_key: '',
                ticket_keydesc: '',
                ticket_sourcedata: 'ASI',
                calculateedit: false,
                ticket_bankbranchunit: '',
                ticket_insurancebranchunit: '',
                ticket_recalc: 'Y',
                doctype: 'Umum'
            },
            formwizard: {
                step: 1
            },
            products: [
                {'id':'0201', 'desc':'Penjaminan Kontra Bank Garansi', 'remarks':'Memberikan jaminan dalam bentuk kontra garansi atas fasilitas bank garansi yang diterbitkan oleh Bank kepada Principal apabila Principal mengalami wanprestasi dalam pelaksanaan pekerjaannya'},
                {'id':'0202', 'desc':'Penjaminan Surety Bond', 'remarks':'Memberikan jaminan kepada Pemilik Pekerjaan (Obligee) terhadap wanprestasi yang timbul akibat tidak dipenuhinya kewajiban oleh Pelaksana Pekerjaan (Principal) atas suatu pekerjaan (konstruksi/non konstruksi) dalam jangka waktu yang telah ditentukan di dalam kontrak.'},
            ],
            tabs: null,
            detail: {
                calculate: [],
                quotation: []
            },
            dialog: {
                print: false,
            },
            quotationsearch: {
                dialog: false,
                id: '',
                title: '',
            }
        }),
        created() {
            this.form.ticket_inception = this.$functions.TodayYYYYMMDD()
            this.form.ticket_expiry = this.$functions.TodayYYYYMMDD()
            this.form.ticket_projectdate = this.$functions.TodayYYYYMMDD()
        },
        watch: {
            "$route.params.id": {
            handler: function (value) {
                this.Edit(value)
            },
            deep: true,
            immediate: true,
            },
        },
        computed: {
             TotalCalculation (){
                let sum = 0
                for(let i = 0; i < this.detail.calculate.length; i++){
                    sum += (parseFloat(this.detail.calculate[i].calcquotation_premium * 1 + this.detail.calculate[i].calcquotation_fee * 1 + this.detail.calculate[i].calcquotation_duty * 1 ))
                }
                return sum
            },
            TotalRate (){
                let sum = 0
                for(let i = 0; i < this.detail.calculate.length; i++){
                    sum += (parseFloat(this.detail.calculate[i].calcquotation_rate))
                }
                return sum
            },
            TotalFee (){
                let sum = 0
                for(let i = 0; i < this.detail.calculate.length; i++){
                    sum += (parseFloat(this.detail.calculate[i].calcquotation_fee))
                }
                return sum
            },
            TotalDuty (){
                let sum = 0
                for(let i = 0; i < this.detail.calculate.length; i++){
                    sum += (parseFloat(this.detail.calculate[i].calcquotation_duty))
                }
                return sum
            },
            TotalGuarantee (){
                let sum = 0
                for(let i = 0; i < this.detail.calculate.length; i++){
                    sum += (parseFloat(this.detail.calculate[i].calcquotation_premium))
                }
                return sum
            },
        },
        methods: {
            Access() {
                let modul = 'quotation'
                let formdata = {
                    menu_id     : modul,
                    users_id    : this.$functions.UsersID(),
                    order_by    : 'menu_id',
                    order_type  : 'ASC',
                    limit       : this.limit,
                }
                let param = this.$functions.ParamPOST(formdata)
                this.$axios
                .post(this.$functions.UrlPOST('apiListMenuUser'), param, {
                    headers: {
                    'Content-Type': 'text/plain charset=ISO-8859-1',
                    },
                })
                .then((response) => {
                    let priv = response.data
                    if (priv.length > 0) {
                    this.access.read = priv[0].usersmenu_read
                    this.access.add = priv[0].usersmenu_add
                    this.access.edit = priv[0].usersmenu_edit
                    this.access.delete = priv[0].usersmenu_delete
                    if (priv[0].usersmenu_read === 0) {
                        this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
                    }
                    } else {
                    this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
                    }
                    this.loading.page = false
                })
                .catch((e) => {
                    this.SnackBar(true, 'error', e, 0)
                    this.loading.page = false
                })
            },
            SnackBar(dialog, color, text, timeout) {
                this.snackbar = {
                    dialog: dialog,
                    color: color,
                    text: text,
                    timeout: timeout,
                }
                if (color === "error") {
                    this.confirm.dialog 	= false
                    this.confirm.text 		= "Ok"
                }
            },
            Variable (flag, position) {
                return this.$functions.Variable(flag,position)
            },
            Step (number, id, desc) {
                if (number === '1') {
                    this.form.id = id
                    this.form.desc = desc
                    this.form.ticket_subclass = id
                    setTimeout(function () {
                        document.getElementById("ticket_profileid").focus();
                    }, 500)
                } else if (number === '2') {
                    if (this.form.ticket_profileid === '') {
                        this.SnackBar(true, 'error', 'Mohon pilih Principal terlebih dahulu', 3000)
                    } else {
                        this.formwizard.step = 2
                        setTimeout(function () {
                            document.getElementById("ticket_pholderid").focus();
                        }, 500)
                    }
                } else if (number === '3') {
                    if (this.form.ticket_pholderid === '') {
                        this.SnackBar(true, 'error', 'Mohon pilih Obligee terlebih dahulu', 3000)
                    } else {
                        this.formwizard.step = 3
                        setTimeout(function () {
                            document.getElementById("ticket_profileid").focus();
                        }, 500)
                    }
                } else if (number === '4') {
                    if (this.form.ticket_insuranceid === '') {
                        this.SnackBar(true, 'error', 'Mohon pilih Asuransi terlebih dahulu', 3000)
                    } else {
                        if (this.form.id === '0201') {
                            this.formwizard.step = 4
                            setTimeout(function () {
                                document.getElementById("ticket_bank").focus();
                            }, 500)
                        } else {
                            this.formwizard.step = 5
                            setTimeout(function () {
                                document.getElementById("ticket_projectname").focus();
                            }, 500)
                        }
                    }
                } else if (number === '5') {
                    if (this.form.ticket_bank === '') {
                        this.SnackBar(true, 'error', 'Mohon pilih Bank terlebih dahulu', 3000)
                    } else {
                        this.formwizard.step = 5
                        this.form.ticket_ccy = 'IDR'
                        setTimeout(function () {
                            document.getElementById("ticket_projectname").focus();
                        }, 500)
                    }
                } else if (number === '6') {
                    if (this.form.ticket_projectname === '') {
                        this.SnackBar(true, 'error', 'Mohon pilih nama project', 3000)
                        setTimeout(function () {
                            document.getElementById("ticket_projectname").focus();
                        }, 500)
                    } else if (this.form.ticket_projectbasedon === '') {
                        this.SnackBar(true, 'error', 'Mohon pilih project based on', 3000)
                        setTimeout(function () {
                            document.getElementById("ticket_projectbasedon").focus();
                        }, 500)
                    } else if (this.form.ticket_projectdate === '') {
                        this.SnackBar(true, 'error', 'Mohon isi underlying date', 3000)
                        setTimeout(function () {
                            document.getElementById("ticket_projectdate").focus();
                        }, 500)
                    } else if (this.form.ticket_riskcov === '') {
                        this.SnackBar(true, 'error', 'Mohon isi risk coverage', 3000)
                        setTimeout(function () {
                            document.getElementById("ticket_riskcov").focus();
                        }, 500)
                    } else {
                        this.CalculateQuotation()
                    }
                }
            },
            BackData () {
                if (this.form.id === '0201') {
                    this.formwizard.step = 4
                } else {
                    this.formwizard.step = 3
                }
            },
            Loading() {
                this.confirm.text = "Loading..."
                if (this.url_type === "add" || this.url_type === "edit") {
                    this.Write()
                } else if (this.url_type === "sendemail") {
                    this.SendQuotation(this.form.doctype)
                } else if (this.url_type === "requestclosing") {
                    this.Write()
                } else if (this.url_type === "lossbusiness") {
                    if (this.form.ticket_closereason === '') {
                        this.SnackBar(true, 'error', 'Please fill Loss Reason', 3000)
                        this.confirm.dialog = true
                        setTimeout(function () {
                            document.getElementById("ticket_closereason").focus();
                        }, 500);
                    } else {
                        this.Write()
                    }
                } else if (this.url_type === "calculate") {
                   this.UpdateCalculation()
                }
            },
            Write () {
            let ticket_type    = 'C01'
            let ticket_date    = this.$functions.TodayYYYYMMDD()
            let ticket_source  = 'A'
            let ticket_name    = this.form.ticket_profiledesc
            let ticket_subject = 'Q#'+this.form.id+'#'+this.form.ticket_profiledesc
            let ticket_refno   = 'NA'
            let ticket_description = ''
            let ticket_remarks = this.form.ticket_remarks
            let ticket_solution = ''
            let ticket_category = 'PENAWARAN'
            let ticket_priority = 'H'
            let ticket_status = ''
            let ticket_pic = 'eka.septiana'
            let ticket_receiveddate = this.$functions.TodayYYYYMMDD()
            let ticket_targetdate = this.$functions.TodayYYYYMMDD()
            let ticket_key = 'N'
            let ticket_amount = 0
            let ticket_mo = ''
            let ticket_multiyear = 'N'
            let ticket_wordingcustom = 'N'
            let ticket_paymentmethod = ''
            let ticket_information = ''
            let ticket_rate = this.TotalRate
            let ticket_rateunit = 'C'
            let ticket_guaranteefee = this.TotalGuarantee
            let ticket_share = '100'
            let ticket_extendday = '0'
            let ticket_policytype = 'S'
            let ticket_channel = 'AGENCY'
            let ticket_instype = 'D'
            let ticket_feeid = 'CF'
            let ticket_feeamount = this.TotalFee
            let ticket_dutyid = 'FD'
            let ticket_dutyamount = this.TotalDuty
            let ticket_feedesc = 'Certificate Fee'
            let ticket_dutydesc = 'Fee Duty'
            let ticket_adminrandom = '0'
            let ticket_bookdate = this.$functions.TodayYYYYMMDD()
            let ticket_endorsef = '0'
            let ticket_indemnity = '0'
            let ticket_sourcetax = '0'
            let formdata = {
                url_type: this.url_type,
                ticket_id: this.form.ticket_id,
                ticket_subclass: this.form.id,
                ticket_type: ticket_type,
                ticket_date: ticket_date,
                ticket_source: ticket_source,
                ticket_name: ticket_name,
                ticket_email: this.form.ticket_profilepicemail,
                ticket_mobile: this.form.ticket_mobile,
                ticket_address: this.form.ticket_profileaddress,
                ticket_subject: ticket_subject,
                ticket_refno: ticket_refno,
                ticket_description: ticket_description,
                ticket_remarks: ticket_remarks,
                ticket_solution: ticket_solution,
                ticket_category: ticket_category,
                ticket_priority: ticket_priority,
                ticket_status: ticket_status,
                ticket_pic: ticket_pic,
                ticket_receiveddate: ticket_receiveddate,
                ticket_targetdate: ticket_targetdate,
                ticket_key: ticket_key,
                ticket_ccy: this.form.ticket_ccy,
                ticket_amount: String(ticket_amount),
                ticket_mo: ticket_mo,
                ticket_riskcov: this.form.ticket_riskcov,
                ticket_pholdername: this.form.ticket_pholderdesc,
                ticket_pholderaddress: this.form.ticket_pholderaddress,
                ticket_tsi: String(this.form.ticket_tsi),
                ticket_pcttsi: String(this.form.ticket_pcttsi),
                ticket_bank: this.form.ticket_bank,
                ticket_bankdesc: this.form.ticket_bankdesc,
                ticket_profileid: this.form.ticket_profileid,
                ticket_multiyear: ticket_multiyear,
                ticket_wordingcustom: ticket_wordingcustom,
                ticket_paymentmethod: ticket_paymentmethod,
                ticket_information: ticket_information,
                ticket_rate: String(ticket_rate),
                ticket_rateunit: ticket_rateunit,
                ticket_guaranteefee: String(ticket_guaranteefee),
                ticket_share: ticket_share,
                ticket_pholderid: this.form.ticket_pholderid,
                ticket_interest: this.form.ticket_interest,
                ticket_guaranteevalue: String(this.form.ticket_guaranteevalue),
                ticket_inception: this.form.ticket_inception,
                ticket_expiry: this.form.ticket_expiry,
                ticket_durationday: String(this.form.ticket_durationday),
                ticket_durationmonth: String(this.form.ticket_durationmonth),
                ticket_riskcovdesc: this.form.ticket_riskcovdesc,
                ticket_interestdesc: this.form.ticket_interestdesc,
                ticket_extendday: ticket_extendday,
                ticket_policytype: ticket_policytype,
                ticket_channel: ticket_channel,
                ticket_instype: ticket_instype,
                ticket_sourceid: this.form.ticket_sourceid,
                ticket_sourcefee: String(this.form.ticket_sourcefee),
                ticket_feeid: ticket_feeid,
                ticket_feeamount: String(ticket_feeamount),
                ticket_dutyid: ticket_dutyid,
                ticket_dutyamount: String(ticket_dutyamount),
                ticket_feedesc: ticket_feedesc,
                ticket_dutydesc: ticket_dutydesc,
                ticket_projectname: this.form.ticket_projectname,
                ticket_projectbasedon: this.form.ticket_projectbasedon,
                ticket_projectdate: this.form.ticket_projectdate,
                ticket_adminrandom: ticket_adminrandom,
                ticket_bookdate: ticket_bookdate,
                ticket_endorsef: ticket_endorsef,
                ticket_indemnity: ticket_indemnity,
                ticket_sourcetax: ticket_sourcetax,
                ticket_insuranceid: this.form.ticket_insuranceid,
                ticket_projectaddress: this.form.ticket_projectaddress,
                ticket_quotype: this.form.ticket_quotype,
                ticket_picname: this.form.ticket_profilepicname,
                ticket_pictitle: this.form.ticket_profilepictitle,
                ticket_picgender: this.form.ticket_profilepicgender,
                ticket_picemail: this.form.ticket_profilepicemail,
                ticket_picmobile: this.form.ticket_profilepicmobile,
                ticket_profileaccount: this.form.ticket_bankidref,
                ticket_bankid: this.form.ticket_bankid,
                ticket_bankaccountno: this.form.ticket_bankaccountno,
                ticket_bankaccountname: this.form.ticket_bankaccountname,
                ticket_bankbranch: this.form.ticket_bankbranch,
                ticket_bankccy: this.form.ticket_bankccy,
                ticket_bankcompanyname: this.form.ticket_bankcompanyname,
                ticket_tc: this.form.ticket_tc,
                ticket_closereason: this.form.ticket_closereasondesc,
                ticket_sourcedata: this.form.ticket_sourcedata,
                ticket_bankbranchunit: this.form.ticket_bankbranchunit,
                ticket_insurancebranchunit: this.form.ticket_insurancebranchunit,
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiWriteTicket'),param,{
            headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.SnackBar(true, 'success', 'Save Success', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                            this.form.ticket_id      = feedback[0].feedback_id
                            if (this.url_type === 'lossbusiness') {
                                this.form.ticket_status = 'C'
                                this.form.ticket_key = 'L'
                            }
                        } else {
                            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                            this.remarks.dialog 	= true
                            this.remarks.feedback 	= feedback[0].feedback
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.remarks.dialog = true
                        this.remarks.feedback = feedback
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    this.remarks.dialog = true
                    this.remarks.feedback = e
                })
            },
            GetInsurance(value) {
                if (value !== undefined) {
                    let splits = this.$functions.Split(value, "|")
                    let address = splits[3]
                    this.form.ticket_insuranceid      = splits[0]
                    this.form.ticket_insurancecgroupdesc = splits[1]
                    this.form.ticket_insurancecgroup  = splits[4]
                    this.form.ticket_insurancebranchunit = splits[5]
                    this.form.ticket_insurancedesc = splits[6]
                    this.form.ticket_insuranceaddress = address
                }
            },
            GetPholder(value) {
                if (value !== undefined) {
                    let splits = this.$functions.Split(value, "|")
                    let address = splits[3]
                    this.form.ticket_pholderid      = splits[0]
                    this.form.ticket_pholderdesc    = splits[1]
                    this.form.ticket_pholderaddress = address
                }
            },
            GetProfile(value) {
                if (value !== undefined) {
                    let splits = this.$functions.Split(value, "|")
                    this.CheckQuotation(value,splits)
                }
            },
            GetProfileAccount(value) {
                if (value !== undefined) {
                    let splits = this.$functions.Split(value, "|")
                    this.form.ticket_bankidref      = splits[0]
                    this.form.ticket_bankaccountname  = splits[1]
                    this.form.ticket_bankaccountno  = splits[3]
                    this.form.ticket_bankcompanyname  = splits[4]
                    this.form.ticket_bankbranch  = splits[5]
                    this.form.ticket_bankid      = splits[6]
                    this.form.ticket_bankccy      = splits[7]
                }
            },
            GetBank(value) {
                if (value !== undefined) {
                    let splits = this.$functions.Split(value, "|")
                    let address = splits[3]
                    this.form.ticket_bank        = splits[0]
                    this.form.ticket_bankcgroupdesc = splits[1]
                    this.form.ticket_bankbranchunit = splits[4]
                    this.form.ticket_bankdesc = splits[5]
                    this.form.ticket_bankaddress = address
                }
            },
            GetRiskCov(value) {
                if (value !== undefined) {
                    let splits = this.$functions.Split(value, "|")
                    this.form.ticket_riskcov      = splits[0]
                    this.form.ticket_riskcovdesc    = splits[1]
                }
            },
            GetLossReason(value) {
                if (value !== undefined) {
                    let splits = this.$functions.Split(value, "|")
                    this.form.ticket_closereason      = splits[0]
                    this.form.ticket_closereasondesc    = splits[1]
                }
            },
            IEDMOnChange (flag) {
                let day   = parseInt(this.form.ticket_durationday)
                let month = parseInt(this.form.ticket_durationmonth)
                let formdata    = {
                    url_type            : flag,
                    calculate_inception : this.form.ticket_inception,
                    calculate_expiry    : this.form.ticket_expiry,
                    calculate_day       : day,
                    calculate_month     : month
                }
                let param = this.$functions.ParamPOST(formdata)
                this.$axios
                .post(this.$functions.UrlPOST("apiWriteCalculate"), param, {
                    headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                    },
                })
                .then((response) => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === "Y") {
                            this.form.ticket_inception  = feedback[0].calculate_inception              
                            this.form.ticket_expiry     = feedback[0].calculate_expiry
                            this.form.ticket_durationday = feedback[0].calculate_day
                            this.form.ticket_durationmonth = feedback[0].calculate_month
                        } else {
                            this.SnackBar(
                            true,
                            "error",
                            this.$functions.ErrorCode(),
                            0
                            )
                            this.remarks.dialog   = true
                            this.remarks.feedback = feedback[0].feedback
                        }
                    } else {
                        this.SnackBar(true, "error", feedback, 0)
                    }
                })
                .catch((e) => {
                    this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                    )
                    this.remarks.dialog = true
                    this.remarks.feedback = e
                })
            },
            BondValue () {
                this.form.ticket_guaranteevalue = this.form.ticket_tsi * (this.form.ticket_pcttsi / 100)
            },
            CalculateQuotation () {
            this.loading.calculate = true
            let formdata = {
                ticket_modul: 'quotation',
                ticket_id: this.form.ticket_id,
                ticket_subclass: this.form.ticket_subclass,
                ticket_insuranceid: this.form.ticket_insuranceid,
                ticket_bank: this.form.ticket_bank,
                ticket_riskcov: this.form.ticket_riskcov,
                ticket_interest: this.form.ticket_interest,
                ticket_tsi: String(this.form.ticket_guaranteevalue),
                ticket_periodmonth: String(this.form.ticket_durationmonth),
                ticket_sourceid: this.form.ticket_sourceid,
                ticket_sourcename: this.form.ticket_sourcename,
                ticket_sourcefee: String(this.form.ticket_sourcefee),
                ticket_recalc: this.form.ticket_recalc,
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiCalculateQuotation'),param,{
            headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.detail.calculate = feedback
                            this.formwizard.step = 6
                        } else {
                            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                            this.remarks.dialog 	= true
                            this.remarks.feedback 	= feedback[0].feedback
                        }
                    } else {
                        this.SnackBar(true, 'error', 'Tidak ada setting untuk default rate '+this.form.ticket_insurancecgroupdesc, 3000)
                        this.remarks.dialog = true
                        this.remarks.feedback = feedback
                    }
                    this.loading.calculate = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    this.remarks.dialog = true
                    this.remarks.feedback = e
                    this.loading.calculate = false
                })
            },
            AddCalculate () {
                this.form.ticket_sourceid = this.$functions.UsersOthersID()
                this.form.ticket_sourcename = this.$functions.UsersName()
                this.CalculateQuotation()
            },
            ImagesID (id) {
                let images = ''
                if (id === '0202') {
                    images = 'https://kerrylondon.co.uk/custom-content/uploads/2015/11/project-460x370-460x300.jpg'
                } else if (id === '0201') {
                    images = 'https://storage.googleapis.com/flip-prod-mktg-strapi/media-library/66_1_Envato_b189a50c86/66_1_Envato_b189a50c86.jpg'
                }
                return images
            },
            QuotationType (type, desc, id, iddesc) {
               this.form.ticket_quotype = type
               this.form.title = 'Quotation > '+ iddesc +' > '+desc
               this.Step('1', id, iddesc)
            },
            Confirm(flag, id) {
                this.confirm.subtitle = ''
                if (flag === "write") {
                    if (this.form.ticket_quotype === 'U') {
                        if (this.$refs.form_quotation_umum.validate()) {
                            if (id === '') {
                                this.url_type = 'add'
                            } else {
                                this.url_type = 'edit'
                            }
                            this.confirm.dialog = true;
                            this.confirm.title = "Save";
                            setTimeout(function () {
                                document.getElementById("dialog").focus();
                            }, 500);
                        }
                    } else if (this.form.ticket_quotype === 'K') {
                         if (id === '') {
                                this.url_type = 'add'
                            } else {
                                this.url_type = 'edit'
                            }
                            this.confirm.dialog = true;
                            this.confirm.title = "Save";
                            setTimeout(function () {
                                document.getElementById("dialog").focus();
                            }, 500);
                    }
                } else if (flag === "sendemail") {
                    this.url_type       = 'sendemail'
                    this.doctype       = id
                    this.confirm.dialog = true;
                    this.confirm.title = "Send Email";
                    this.confirm.subtitle = this.form.ticket_picemail;
                    setTimeout(function () {
                        document.getElementById("dialog").focus();
                    }, 500);
                } else if (flag === "closing") {
                    this.url_type       = 'requestclosing'
                    this.confirm.dialog = true;
                    this.confirm.title = "Request Closing";
                    setTimeout(function () {
                        document.getElementById("dialog").focus();
                    }, 500);
                } else if (flag === "loss") {
                    this.url_type       = 'lossbusiness'
                    this.confirm.dialog = true;
                    this.confirm.title = "Loss Business";
                    setTimeout(function () {
                        document.getElementById("dialog").focus();
                    }, 500);
                } else if (flag === "calculate") {
                    this.url_type       = 'calculate'
                    this.confirm.dialog = true;
                    this.confirm.title = "Save Calculation";
                    setTimeout(function () {
                        document.getElementById("dialog").focus();
                    }, 500);
                } else {
                    alert(id)
                }
            },
            Edit(id) {
            if (id !== undefined) {
                this.loading.page = true
                let ticket_id = this.$functions.DecodeUrl(id)
                let formdata = {
                ticket_id: ticket_id,
                order_by: "ticket_id",
                order_type: "ASC",
                limit: this.limit,
                };
                let param = this.$functions.ParamPOST(formdata);
                this.$axios
                .post(this.$functions.UrlPOST("apiListTicket"), param, {
                    headers: {
                    "Content-Type": "text/plain; charset=ISO-8859-1",
                    },
                })
                .then((response) => {
                    let feedback = response.data;
                    if (feedback.length > 0) {
                    if (feedback[0].feedback === "Y") {
                        this.form.id = feedback[0].ticket_subclass
                        this.form.desc = feedback[0].ticket_subclassdesc
                        this.form.ticket_subclass = feedback[0].ticket_subclass
                        this.form.ticket_quotype = feedback[0].ticket_quotype
                        this.form.title = 'Quotation > '+ feedback[0].ticket_subclassdesc  +' > '+feedback[0].ticket_quotypedesc
                        this.form.ticket_id = feedback[0].ticket_id
                        this.form.ticket_profiledesc = feedback[0].ticket_name
                        this.form.ticket_mobile = feedback[0].ticket_mobile
                        this.form.ticket_profileaddress = feedback[0].ticket_address
                        this.form.ticket_profilepicname = feedback[0].ticket_picname
                        this.form.ticket_profilepictitle = feedback[0].ticket_pictitle
                        this.form.ticket_profilepicgender = feedback[0].ticket_picgender
                        this.form.ticket_profilepicmobile = feedback[0].ticket_picmobile
                        this.form.ticket_profilepicemail = feedback[0].ticket_picemail
                        this.form.ticket_sourcefee = feedback[0].ticket_sourcefee
                        this.form.ticket_insuranceid = feedback[0].ticket_insuranceid
                        this.form.ticket_insurancedesc = feedback[0].ticket_insurancedesc
                        this.form.ticket_insuranceaddress = feedback[0].ticket_insuranceaddress
                        this.form.ticket_insurancecgroupdesc = feedback[0].ticket_insurancecgroupdesc
                        this.form.ticket_insurancebranchunit = feedback[0].ticket_insurancebranchunit
                        this.form.ticket_pholderid = feedback[0].ticket_pholderid
                        this.form.ticket_pholderdesc = feedback[0].ticket_pholderdesc
                        this.form.ticket_pholderaddress = feedback[0].ticket_pholderaddress
                        this.form.ticket_profileid = feedback[0].ticket_profileid
                        this.form.ticket_bank = feedback[0].ticket_bank
                        this.form.ticket_bankdesc = feedback[0].ticket_bankdesc
                        this.form.ticket_bankaddress = feedback[0].ticket_bankaddress
                        this.form.ticket_bankcgroupdesc = feedback[0].ticket_bankcgroupdesc
                        this.form.ticket_bankbranchunit = feedback[0].ticket_bankbranchunit
                        this.form.ticket_projectname = feedback[0].ticket_projectname
                        this.form.ticket_projectaddress = feedback[0].ticket_projectaddress
                        this.form.ticket_projectdate = this.$functions.FormatDate(feedback[0].ticket_projectdate)
                        this.form.ticket_projectbasedon = feedback[0].ticket_projectbasedon
                        this.form.ticket_inception = this.$functions.FormatDate(feedback[0].ticket_inception)
                        this.form.ticket_expiry = this.$functions.FormatDate(feedback[0].ticket_expiry)
                        this.form.ticket_durationday = feedback[0].ticket_durationday
                        this.form.ticket_durationmonth = feedback[0].ticket_durationmonth
                        this.form.ticket_riskcov = feedback[0].ticket_riskcov
                        this.form.ticket_riskcovdesc = feedback[0].ticket_riskcovdesc
                        this.form.ticket_ccy = feedback[0].ticket_ccy
                        this.form.ticket_tsi = feedback[0].ticket_tsi
                        this.form.ticket_pcttsi = feedback[0].ticket_pcttsi
                        this.form.ticket_guaranteevalue = feedback[0].ticket_guaranteevalue
                        this.form.ticket_remarks = feedback[0].ticket_remarks
                        this.form.ticket_bankidref = feedback[0].ticket_profileaccount
                        this.form.ticket_bankaccountname = feedback[0].ticket_bankaccountname
                        this.form.ticket_bankaccountno = feedback[0].ticket_bankaccountno
                        this.form.ticket_bankcompanyname = feedback[0].ticket_bankcompanyname
                        this.form.ticket_bankccy = feedback[0].ticket_bankccy
                        this.form.ticket_bankbranch = feedback[0].ticket_bankbranch
                        this.form.ticket_tc = feedback[0].ticket_tc
                        this.form.ticket_status = feedback[0].ticket_status
                        this.form.ticket_statusdesc = feedback[0].ticket_statusdesc
                        this.form.ticket_key = feedback[0].ticket_key
                        this.form.ticket_keydesc = feedback[0].ticket_keydesc
                        this.form.ticket_recalc = 'Y'
                        if (this.form.ticket_status === 'C') {
                            this.form.ticket_recalc = ''
                        }
                        this.loading.page = false
                    } else {
                        this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
                        this.loading.page = false;
                    }
                    } else {
                    this.SnackBar(true, "error", feedback, 0);
                    this.loading.page = false;
                    }
                })
                .catch((e) => {
                    this.SnackBar(true, "error", e, 0);
                    this.loading.page = false;
                });
            }
            },
            PrintQuotation () {
                this.dialog.print = true
            },
            SendQuotation (type) {
            let formdata = {
                url_type: 'sendemail',
                ticket_id: this.form.ticket_id,
                ticket_doctype: type,
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiWriteTicket'),param,{
            headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.SnackBar(true, 'success', 'Send Email Success, Check Your Email and PIC Email', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                            this.url_type 			 = 'edit'
                            this.form.ticket_id      = feedback[0].feedback_id 
                        } else {
                            this.SnackBar(true, 'error', feedback[0].feedback, 0)
                            this.remarks.dialog 	= true
                            this.remarks.feedback 	= feedback[0].feedback
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.remarks.dialog = true
                        this.remarks.feedback = feedback
                    }
                })
                .catch(e => {
                    this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    this.remarks.dialog = true
                    this.remarks.feedback = e
                })
            },
            OpenSearch (flag) {
            if (flag === 'quotation') {
                this.quotationsearch.id  = 'ticket_id'
                this.quotationsearch.dialog  = true
                this.quotationsearch.title   = flag
            }
            },
            GetSearch (value) {
                if (this.quotationsearch.title === 'quotation') {
                    this.$router.push({name: 'quotationdetail', params: { id:this.$functions.EncodeUrl(value) }}).catch(()=>{})
                }
            },
            CheckQuotation (id, splits) {
                if (this.form.ticket_quotype === 'U') {
                    let formdata = {
                        url_type: 'sendemail',
                        ticket_profileid: splits[0],
                        ticket_quotype: this.form.ticket_quotype,
                        order_by: 'ticket_cdate',
                        order_type: 'DESC',
                    }
                    let param 	   = this.$functions.ParamPOST(formdata)
                    this.$axios.post(this.$functions.UrlPOST('apiListTicket'),param,{
                    headers: {
                    'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                        })
                        .then(response => {
                            let feedback = response.data
                            if (feedback.length > 0) {
                                if (feedback[0].feedback === 'Y') {
                                    this.SnackBar(true, 'success', 'Penawaran Umum untuk '+feedback[0].ticket_name+' sudah ada di '+feedback[0].ticket_id, 0)
                                    this.confirm.dialog  	 = false
                                    this.confirm.text 	 	 = 'Ok'
                                    this.form.ticket_id      = feedback[0].ticket_id 
                                    this.form.ticket_profileid      = feedback[0].ticket_profileid
                                    this.form.ticket_profiledesc    = feedback[0].ticket_name
                                    this.form.ticket_profilepicname = feedback[0].ticket_picname
                                    this.form.ticket_profilepictitle = feedback[0].ticket_pictitle
                                    this.form.ticket_profilepicmobile = feedback[0].ticket_picmobile
                                    this.form.ticket_profilepicemail = feedback[0].ticket_picemail
                                    this.form.ticket_profilepicgender = feedback[0].ticket_picgender
                                    this.form.ticket_mobile = feedback[0].ticket_mobile
                                    this.form.ticket_profileaddress = feedback[0].ticket_address
                                    this.form.ticket_remarks = feedback[0].ticket_remarks
                                } else {
                                    this.SnackBar(true, 'error', feedback[0].feedback, 0)
                                    this.remarks.dialog 	= true
                                    this.remarks.feedback 	= feedback[0].feedback
                                }
                            } else {
                                this.SnackBar(true, 'error', 'Silahkan Lanjut', 3000)
                                this.form.ticket_profileid      = splits[0]
                                this.form.ticket_profiledesc    = splits[1]
                                this.form.ticket_profilepicname = splits[4]
                                this.form.ticket_profilepictitle = splits[5]
                                this.form.ticket_profilepicmobile = splits[6]
                                this.form.ticket_profilepicemail = splits[7]
                                this.form.ticket_profilepicgender = splits[8]
                                this.form.ticket_mobile = splits[9]
                                this.form.ticket_profileaddress = splits[3]
                            }
                        })
                        .catch(e => {
                            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                            this.remarks.dialog = true
                            this.remarks.feedback = e
                        })
                } else if(this.form.ticket_quotype === 'K') {
                    this.form.ticket_profileid      = splits[0]
                    this.form.ticket_profiledesc    = splits[1]
                    this.form.ticket_profilepicname = splits[4]
                    this.form.ticket_profilepictitle = splits[5]
                    this.form.ticket_profilepicmobile = splits[6]
                    this.form.ticket_profilepicemail = splits[7]
                    this.form.ticket_profilepicgender = splits[8]
                    this.form.ticket_mobile = splits[9]
                    this.form.ticket_profileaddress = splits[3]
                }
            },
            UpdateCalculation () {
            this.loading.calculate = true
            let formdata = {
                calculation_modul: 'quotation',
                calculation_id: this.form.ticket_id,
                calculation_data: JSON.stringify(this.detail.calculate),
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiUpdateCalculation'),param,{
            headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
                })
                .then(response => {
                    let feedback = response.data
                    if (feedback.length > 0) {
                        if (feedback[0].feedback === 'Y') {
                            this.SnackBar(true, 'success', 'Calculate Successfully', 3000)
                            this.confirm.dialog  	 = false
                            this.confirm.text 	 	 = 'Ok'
                            this.form.calculateedit = false
                        } else {
                            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                            this.remarks.dialog 	= true
                            this.remarks.feedback 	= feedback[0].feedback
                        }
                    } else {
                        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                        this.remarks.dialog = true
                        this.remarks.feedback = feedback
                    }
                    this.loading.calculate = false
                })
                .catch(e => {
                    this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                    this.remarks.dialog = true
                    this.remarks.feedback = e
                    this.loading.calculate = false
                })
            },
            ChangeCalc(index) {
                let rate = this.detail.calculate[index].calcquotation_rate
                let tsi = this.form.ticket_guaranteevalue
                let contribution = rate / 100 * tsi
                this.detail.calculate[index].calcquotation_premium = contribution
            },
            ChangeRate(index) {
                let premium = this.detail.calculate[index].calcquotation_premium
                let tsi = this.form.ticket_guaranteevalue
                let rate = premium / tsi * 100
                this.detail.calculate[index].calcquotation_rate = rate
            }
        }
    }
    </script>