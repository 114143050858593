<template>
    <div id="app-profilecg-rate">
    <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.page"
        ></v-progress-linear>
     <div v-if="access.read === 0" class="text-center font-weight-black">
      {{$vars.V('txt_no_access')}}
     </div>
     <div v-else>
     <v-card-title 
        class="ml-n4"
    >
        <v-btn 
        :color 	="selected.length === items.length ? 'fourth':''"
        @click	="SelectAllPage"
        class	="mr-2"
        title	="select all page"
        small
        >{{$vars.V('txt_select_all_page')}}
        </v-btn>
        <v-btn 
        color     = "error"
        title 	  = "delete selected"
        @click 	  = "Confirm('multidelete','')"
        class	  = "mr-2"
        v-if  	  = "selected.length > 0"
        :disabled = "access.delete === 0 ? true:false"
        small>
        <v-icon>{{$vars.V('icon_delete')}}</v-icon>
        </v-btn>    
        <v-btn 
        @click	="Add"
        class	="mr-2"
        title	="Add New"
        small
        >{{$vars.V('txt_add')}}
        </v-btn>
       <v-btn 
          @click="List(id)"
          class="mr-2"
          title="Refresh"
          small
          ><v-icon>{{$vars.V('icon_refresh')}}</v-icon>
        </v-btn>
        <v-btn 
        color  = "primary"
        title  = "search"
        v-if   = "btn_search === false"
        @click = "btn_search = true"
        small>
        <v-icon>{{$vars.V('icon_search')}}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        @click:append="btn_search = false"
        label="Search"
        single-line
        hide-details
        v-if ="btn_search"
        ></v-text-field>
    </v-card-title>
    <v-data-table
    :value="selected"
    :headers="headers"
    :items="items"
    :search="search"
    @input="Check($event)"
    item-key="profilecgrouprate_id"
    show-select
    class="elevation-1"
    :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
    :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right'
    }"
    dense
    :loading 	= "loading.detail"
    loading-text="Loading... Please wait"
    >
    <template v-slot:item.profilecgrouprate_subclass="{ item }">
        {{item.profilecgrouprate_subclass}} - {{item.profilecgrouprate_subclass_desc}}
    </template>
    <template v-slot:item.profilecgrouprate_riskcovid="{ item }">
        {{item.profilecgrouprate_riskcovid}} - {{item.profilecgrouprate_riskcov_desc}}
    </template>
    <template v-slot:item.profilecgrouprate_interestid="{ item }">
        {{item.profilecgrouprate_interestid}} - {{item.profilecgrouprate_interest_desc}}
    </template>
    <template v-slot:item.profilecgrouprate_periodmin="{ item }">
        {{$functions.NewFormatNumber(item.profilecgrouprate_periodmin)}} - {{$functions.NewFormatNumber(item.profilecgrouprate_periodmax)}} {{$functions.PeriodUnit(item.profilecgrouprate_periodunit)}}
    </template>
    <template v-slot:item.profilecgrouprate_ratemin="{ item }">
        <span v-if="item.profilecgrouprate_ratemin !== item.profilecgrouprate_ratemax">{{$functions.NewFormatNumber(item.profilecgrouprate_ratemin,2)}} - {{$functions.NewFormatNumber(item.profilecgrouprate_ratemax,2)}} {{$functions.RateUnitPCT(item.profilecgrouprate_rateunit)}}</span>
        <span v-else>{{$functions.NewFormatNumber(item.profilecgrouprate_ratemin,2)}} {{$functions.RateUnitPCT(item.profilecgrouprate_rateunit)}}</span>
    </template>
    <template v-slot:item.action="{ item }">
        <v-icon
        small
        @click	  ="Edit(item.profilecgrouprate_id)"
        :disabled ="access.edit === 0 ? true:false"
        title 	  = "edit"
        >
        {{$vars.V('icon_edit')}}
        </v-icon>
    </template>
    </v-data-table>
    </div>
    <!-- Start Form -->
    <v-dialog
      v-model="dialog.detail"
      max-width="800"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog.detail = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Bank</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Add" title="Add New"><v-icon color="white">add</v-icon></v-btn>
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save"><v-icon color="white">save</v-icon></v-btn>
        </v-toolbar>
      <v-card-text>
      <v-form enctype="multipart/form-data" ref="form_bank" lazy-validation>
        <v-row class="mx-auto">
            <v-col cols="12" sm="12" md="12" class="mt-3">
                <app-cb
                cb_type                ="subclass"
                cb_url                 ="apiListSubClass"
                cb_url_parameter       ="subclass_actived=Y"
                cb_title               ="Sub Class"
                cb_id                  ="profilecgrouprate_subclass"
                cb_desc                ="profilecgrouprate_subclass_desc"
                cb_rules               ="Sub Class"
                cb_desc_readonly       ="readonly"
                cb_items_id            ="subclass_id"
                cb_items_desc          ="subclass_desc"
                :cb_value_id		       ="form.profilecgrouprate_subclass"
                :cb_value_desc	       ="form.profilecgrouprate_subclass_desc"
                @clicked               ="GetSubClass"
                >
                </app-cb>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="mt-n3" v-show="form.profilecgrouprate_subclass === '0201'">
                <app-cb
                cb_type                ="profilecgroup"
                cb_url                 ="apiListProfileCGroup"
                cb_url_parameter       ="profilecgroup_actived=Y"
                cb_title               ="Bank"
                cb_id                  ="profilecgrouprate_refid"
                cb_desc                ="profilecgrouprate_bank_desc"
                cb_rules               ="Bank"
                cb_desc_readonly       ="readonly"
                cb_items_id            ="profilecgroup_id"
                cb_items_desc          ="profilecgroup_desc"
                :cb_value_id		       ="form.profilecgrouprate_refid"
                :cb_value_desc	       ="form.profilecgrouprate_bank_desc"
                @clicked               ="GetBank"
                >
                </app-cb>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mt-n3">
                <app-cb
                cb_type                ="riskcov"
                cb_url                 ="apiListRiskcov"
                cb_url_parameter       ="riskcov_actived=Y"
                cb_title               ="Risk Coverage"
                cb_id                  ="profilecgrouprate_riskcovid"
                cb_desc                ="profilecgrouprate_riskcov_desc"
                cb_rules               ="Risk Coverage"
                cb_desc_readonly       ="readonly"
                cb_items_id            ="riskcov_id"
                cb_items_desc          ="riskcov_desc"
                :cb_value_id		       ="form.profilecgrouprate_riskcovid"
                :cb_value_desc	       ="form.profilecgrouprate_riskcov_desc"
                @clicked               ="GetRiskCov"
                >
                </app-cb>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mt-n3">
                <app-cb
                cb_type                ="interest"
                cb_url                 ="apiListInterest"
                cb_url_parameter       ="interest_actived=Y"
                cb_title               ="Interest"
                cb_id                  ="profilecgrouprate_interestid"
                cb_desc                ="profilecgrouprate_interest_desc"
                cb_rules               ="Interest"
                cb_desc_readonly       ="readonly"
                cb_items_id            ="interest_id"
                cb_items_desc          ="interest_desc"
                :cb_value_id		       ="form.profilecgrouprate_interestid"
                :cb_value_desc	       ="form.profilecgrouprate_interest_desc"
                @clicked               ="GetInterest"
                >
                </app-cb>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mt-n6">
              <vuetify-money
                v-model           ="form.profilecgrouprate_periodmin"
                v-bind:options    ="formatamount"
                label             ="Period Min"
                placeholder       ="Period Min"
                background-color  ="numeric"
                v-bind:properties ="properties"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mt-n6">
              <vuetify-money
                v-model           ="form.profilecgrouprate_periodmax"
                v-bind:options    ="formatamount"
                label             ="Period Max"
                placeholder       ="Period Max"
                background-color  ="numeric"
                v-bind:properties ="properties"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <app-cb
                cb_type             ="code"
                cb_url              ="apiListCode"
                cb_url_parameter    ="code_actived=Y&code_group=PERIODUNIT"
                cb_title            ="Unit"
                cb_id               ="profilecgrouprate_periodunit"
                cb_rules            ="Please Period Unit"
                cb_items_id         ="code_id"
                cb_items_desc       ="code_desc"
                :cb_value_id        ="form.profilecgrouprate_periodunit"
                cb_single           ="Y"
              >
              </app-cb>
            </v-col>
            
             <v-col cols="12" sm="2" md="2" class="mt-n6">
              <vuetify-money
                v-model           ="form.profilecgrouprate_ratemin"
                v-bind:options    ="formatrate"
                label             ="Rate Min"
                placeholder       ="Rate Min"
                background-color  ="numeric"
                v-bind:properties ="properties"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mt-n6">
              <vuetify-money
                v-model           ="form.profilecgrouprate_ratemax"
                v-bind:options    ="formatrate"
                label             ="Rate Max"
                placeholder       ="Rate Max"
                background-color  ="numeric"
                v-bind:properties ="properties"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <app-cb
                cb_type             ="code"
                cb_url              ="apiListCode"
                cb_url_parameter    ="code_actived=Y&code_group=RATEUNIT"
                cb_title            ="Unit"
                cb_id               ="profilecgrouprate_rateunit"
                cb_rules            ="Please Rate Unit"
                cb_items_id         ="code_id"
                cb_items_desc       ="code_desc"
                :cb_value_id        ="form.profilecgrouprate_rateunit"
                cb_single           ="Y"
              >
              </app-cb>
            </v-col>
            <!-- Active -->
            <v-col cols="2" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.profilecgrouprate_actived"
                color="primary"
                id="form.profilecgrouprate_actived"
                :label="form.profilecgrouprate_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>
        </v-row>
      </v-form>
      </v-card-text>
      <v-card-actions>
        
      </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      color		  ="fourth"
      rounded	  ="pill"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
      <v-card :color="Variable('confirm',4)">
        <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            id="dialog"
            :color="Variable('confirm',1)"
            :disabled   ="confirm.text === 'Ok' ? false:true"
            @click      ="Loading"
          >
            {{confirm.text}}
          </v-btn>
          <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Organization -->
    </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    id: String,
  },
  data: () => ({
    url_type: 'add',
    access: {
      read    : 0,
      add     : 0,
      edit    : 0,
      delete  : 0,
    },
    remarks: {
        feedback: "",
        dialog: false
    },
    snackbar: {
        dialog: false,
        color: "success",
        text: "",
        timeout: 3000,
    },
    confirm: {
        dialog: false,
        title: "",
        text: "Ok",
    },
    loading: {
        page      : true,
        detail    : true,
    },
    dialog: {
        detail: false
    },
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2
    },
    properties: {
      reverse: true
    },
    selected: [],
    items: [],
    btn_search: false,
    search: '',
    headers: [
      { text: 'ID', value: 'profilecgrouprate_id' },
      { text: 'Sub Class', value: 'profilecgrouprate_subclass' },
      { text: 'Risk Coverage', value: 'profilecgrouprate_riskcovid' },
      { text: 'Interest', value: 'profilecgrouprate_interestid' },
      { text: 'Period', value: 'profilecgrouprate_periodmin', align:'right' },
      { text: 'Rate', value: 'profilecgrouprate_ratemin', align:'right' },
      { text: 'Actions', value: 'action', sortable: false }
    ],
    form: {
        profilecgrouprate_id: '',
        profilecgrouprate_cgroup: '',
        profilecgrouprate_refid: '',
        profilecgrouprate_bank_desc: '',
        profilecgrouprate_subclass: '',
        profilecgrouprate_subclass_desc: '',
        profilecgrouprate_riskcov: '',
        profilecgrouprate_riskcov_desc: '',
        profilecgrouprate_interestid: 'INCL01',
        profilecgrouprate_interest_desc: 'PROJECT',
        profilecgrouprate_periodmin: 0,
        profilecgrouprate_periodmax: 0,
        profilecgrouprate_periodunit: 'M',
        profilecgrouprate_ratemin: 0,
        profilecgrouprate_ratemax: 0,
        profilecgrouprate_rateunit: 'C',
        profilecgrouprate_actived: true,
    },
   }),
   watch: {
    id: function (newdata) {
      if (newdata !== "") {
        this.id = newdata
        this.List(newdata)
      } else {
        this.id = ""
        this.List(newdata)
      }
    },
   },
   created() {
    this.List(this.id)
   },
   methods: {
    Access() {
        let modul = 'profile'
        let formdata = {
            menu_id     : modul,
            users_id    : this.$functions.UsersID(),
            order_by    : 'menu_id',
            order_type  : 'ASC',
            limit       : this.limit,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios
        .post(this.$functions.UrlPOST('apiListMenuUser'), param, {
            headers: {
            'Content-Type': 'text/plain charset=ISO-8859-1',
            },
        })
        .then((response) => {
            let priv = response.data
            if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read
            this.access.add = priv[0].usersmenu_add
            this.access.edit = priv[0].usersmenu_edit
            this.access.delete = priv[0].usersmenu_delete
            if (priv[0].usersmenu_read === 0) {
                this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
            }
            } else {
            this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
            }
            this.loading.page = false
        })
        .catch((e) => {
            this.SnackBar(true, 'error', e, 0)
            this.loading.page = false
        })
    },
    SnackBar(dialog, color, text, timeout) {
        this.snackbar = {
            dialog: dialog,
            color: color,
            text: text,
            timeout: timeout,
        }
        if (color === "error") {
            this.confirm.dialog 	= false
            this.confirm.text 		= "Ok"
        }
    },
    Variable (flag, position) {
        return this.$functions.Variable(flag,position)
    },
    SelectAllPage () {
        this.selected = this.selected === this.items ? [] : this.items
    },
    Check (value) {
        this.selected = value
    },
    Loading () {
        this.confirm.text = 'Loading...'
        if (this.url_type === 'add' || this.url_type === 'edit') {
            this.Write()
        } else if (this.url_type === 'delete') {
            this.Delete(this.form.profilecgrouprate_id)
        } else if (this.url_type === 'multidelete') {
            this.MultiProcess('delete')
        }
    },
    Write () {
      let formdata = {
        url_type: this.url_type,
        profilecgrouprate_id: this.form.profilecgrouprate_id,
        profilecgrouprate_cgroup: this.form.profilecgrouprate_cgroup,
        profilecgrouprate_refid: this.form.profilecgrouprate_refid,
        profilecgrouprate_subclass: this.form.profilecgrouprate_subclass,
        profilecgrouprate_riskcovid: this.form.profilecgrouprate_riskcovid,
        profilecgrouprate_interestid: this.form.profilecgrouprate_interestid,
        profilecgrouprate_periodmin: String(this.form.profilecgrouprate_periodmin),
        profilecgrouprate_periodmax: String(this.form.profilecgrouprate_periodmax),
        profilecgrouprate_periodunit: this.form.profilecgrouprate_periodunit,
        profilecgrouprate_ratemin: String(this.form.profilecgrouprate_ratemin),
        profilecgrouprate_ratemax: String(this.form.profilecgrouprate_ratemax),
        profilecgrouprate_rateunit: this.form.profilecgrouprate_rateunit,
        profilecgrouprate_actived: this.$functions.ActivedFlag(this.form.profilecgrouprate_actived),
      }
      let param 	   = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteProfileCGroupRate'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.dialog.detail = false
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            if (this.url_type === 'add') {
              this.form.profilecgrouprate_cuser  = feedback[0].feedback_users_id
              this.form.profilecgrouprate_cdate  = feedback[0].feedback_users_date
            }
            this.List(this.id)
            this.form.profilecgrouprate_luser  = feedback[0].feedback_users_id
            this.form.profilecgrouprate_ldate  = feedback[0].feedback_users_date
            this.url_type 		      = 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = feedback
        }
      })
      .catch(e => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
    },
    Delete (id) {
      let formdata = {
        url_type: 'delete',
        profilecgrouprate_id: id
      }
      let param 	   = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteProfileCGroupRate'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog  	= false
            this.confirm.text 		= 'Ok'
            this.List(this.id)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
            this.confirm.dialog  	= false
            this.confirm.text 		= 'Ok'
          }
        }
      })
      .catch(e => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.confirm.dialog  	= false
        this.confirm.text 		= 'Ok'
      })
    },
    Add() { 
      this.dialog.detail = true
      this.url_type = 'add'
      this.form.profilecgrouprate_id = ''
      // this.form.profilecgrouprate_subclass = ''
      // this.form.profilecgrouprate_subclass_desc = ''
      this.form.profilecgrouprate_refid = ''
      this.form.profilecgrouprate_bank_desc = ''
      this.form.profilecgrouprate_riskcovid = ''
      this.form.profilecgrouprate_riskcov_desc = ''
      this.form.profilecgrouprate_interestid = 'INCL01'
      this.form.profilecgrouprate_interest_desc = 'PROJECT'
      this.form.profilecgrouprate_periodmin = 0
      this.form.profilecgrouprate_periodmax = 0
      this.form.profilecgrouprate_periodunit = 'M'
      this.form.profilecgrouprate_ratemin = 0
      this.form.profilecgrouprate_ratemax = 0
      this.form.profilecgrouprate_rateunit = 'C'
      setTimeout(function () {
        document.getElementById("profilecgrouprate_subclass").focus();
      }, 500)
    },
    Edit (id) {
      if (id !== '') {
        this.Add()
        this.loading.detail = true
        let formdata = {
          profilecgrouprate_id: id
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListProfileCGroupRate'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
                this.dialog.detail = true
                this.url_type = 'edit'
                this.form.profilecgrouprate_id = feedback[0].profilecgrouprate_id
                this.form.profilecgrouprate_cgroup = feedback[0].profilecgrouprate_cgroup
                this.form.profilecgrouprate_refid = feedback[0].profilecgrouprate_refid
                this.form.profilecgrouprate_bank_desc = feedback[0].profilecgrouprate_bank_desc
                this.form.profilecgrouprate_subclass = feedback[0].profilecgrouprate_subclass
                this.form.profilecgrouprate_subclass_desc = feedback[0].profilecgrouprate_subclass_desc
                this.form.profilecgrouprate_riskcovid = feedback[0].profilecgrouprate_riskcovid
                this.form.profilecgrouprate_riskcov_desc = feedback[0].profilecgrouprate_riskcov_desc
                this.form.profilecgrouprate_interestid = feedback[0].profilecgrouprate_interestid
                this.form.profilecgrouprate_interest_desc = feedback[0].profilecgrouprate_interest_desc
                this.form.profilecgrouprate_periodmin = feedback[0].profilecgrouprate_periodmin
                this.form.profilecgrouprate_periodmax = feedback[0].profilecgrouprate_periodmax
                this.form.profilecgrouprate_periodunit = feedback[0].profilecgrouprate_periodunit
                this.form.profilecgrouprate_ratemin = feedback[0].profilecgrouprate_ratemin
                this.form.profilecgrouprate_ratemax = feedback[0].profilecgrouprate_ratemax
                this.form.profilecgrouprate_rateunit = feedback[0].profilecgrouprate_rateunit
                this.form.profilecgrouprate_actived = this.$functions.TrueOrFalse(feedback[0].profilecgrouprate_actived)
            } else {
                this.SnackBar(true, 'error', feedback[0].feedback, '3000')
            }
          } else {
            this.SnackBar(true, 'error', this.$functions.NoData(), '3000')
          }
          this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      }
    },
    Confirm (flag, id) {
      if (flag === 'add' || flag === 'edit') {
        if (this.$refs.form_bank.validate()) {
          this.confirm.dialog   	= true
          this.confirm.title    	= 'Save'
          setTimeout(function(){
          document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'delete') {
        this.url_type	  = 'delete'
        this.confirm.dialog       = true
        this.confirm.title        = 'Delete `'+ id + '`'
        this.form.profilecgrouprate_id 	        = id
        setTimeout(function(){
        document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multidelete') {
        this.url_type 		        = 'multidelete'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
        setTimeout(function(){
        document.getElementById('dialog').focus()
        }, 500)
      }
    },
    List (id) {
      this.Access()
      this.items = []
      if (id !== '') {
        this.loading.detail = true
        this.form.profilecgrouprate_cgroup = id
        let formdata = {
          profilecgrouprate_cgroup: id,
          order_by: 'profilecgrouprate_cgroup',
          order_type: 'ASC',
          limit: this.limit,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListProfileCGroupRate'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback !== 'Y') {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
            } else {
              this.items = feedback
            }
          } else {
            this.items = feedback
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      }
    },
    GetBank(value) {
        if (value !== undefined) {
            let splits = this.$functions.Split(value, "|")
            this.form.profilecgrouprate_refid   = splits[0]
            this.form.profilecgrouprate_bank_desc = splits[1]
        }
    },
    GetSubClass(value) {
        if (value !== undefined) {
            let splits = this.$functions.Split(value, "|")
            this.form.profilecgrouprate_subclass   = splits[0]
            this.form.profilecgrouprate_subclass_desc = splits[1]
        }
    },
    GetRiskCov(value) {
        if (value !== undefined) {
            let splits = this.$functions.Split(value, "|")
            this.form.profilecgrouprate_riskcovid   = splits[0]
            this.form.profilecgrouprate_riskcov_desc = splits[1]
        }
    },
    GetInterest(value) {
        if (value !== undefined) {
            let splits = this.$functions.Split(value, "|")
            this.form.profilecgrouprate_interestid   = splits[0]
            this.form.profilecgrouprate_interest_desc = splits[1]
        }
    },
    }
}
</script>