<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Fee Duty"></app-drawer_menu>
	  	<v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
	  	<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if = "access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_feeduty" lazy-validation>
			<v-row class="mx-2 ma-1">
				<v-col cols="12" sm="12" md="12">
					<v-btn
				      color		    ="tertiary"
				      class			="white--text"
				      :disabled     = "access.add === 0 ? true:false" 
				      rounded
				      @click 	= "Add" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
				    </v-btn> &nbsp;
				    <v-btn
				      color		="primary"
				      class		="white--text"
				      :disabled = "access.add === 0 || access.edit === 0 ? true:false"
				      rounded
				      @click 	= "Confirm('write','')" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
				    </v-btn>
				</v-col>
              <!-- ID -->
              <!-- code -->
				<v-col cols="12" sm="4" md="2" class="mt-2">
					<v-combobox
					v-model 	  	= "form.feeduty_code"
					required 
					:rules 	  		="form.feeduty_code_rules"
					:items        	="form.feeduty_code_items"
					:item-text      = "form.feeduty_code_items.text"
					:item-value     = "form.feeduty_code_items.value"
					label       	= "Code"
					placeholder 	= "Code"
					id 				= "feeduty_code"
					@keyup.enter 	= "Confirm('write','')"
					class 			= "body-1"
					dense
					>
					</v-combobox>
				</v-col>
               <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                  v-model 	  		= "form.feeduty_id"
                  required
                  :rules 	  		= "form.feeduty_id_rules"
                  :readonly     	= "form.feeduty_id_readonly"
                  :background-color = "form.feeduty_id_readonly ? 'readonly' : ''"
				  :maxlength 		= "maxinput.id"
				  :counter			= "maxinput.id"
                  label       		= "ID *"
                  placeholder 		= "ID"
                  id 				= "feeduty_id"
                  @keyup.enter 		= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- ID -->
               <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <v-text-field
                  v-model 	  	= "form.feeduty_desc"
                  required
                  :rules        = "form.feeduty_desc_rules"
				  :maxlength 	= "maxinput.desc"
                  label       	= "Description *"
                  placeholder 	= "Description"
                  id 			= "feeduty_desc"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- currency -->
				<v-col cols="12" sm="12" md="3">
					<app-cb
					cb_type           	= "currency"
					cb_url            	= "apiListCurrency"
					cb_url_parameter  	= "currency_actived=Y"
					cb_title          	= "Currency"
					cb_id             	= "feeduty_currency"
					cb_desc           	= "feeduty_currency_desc"
					cb_rules          	= "Please fill Currency"
					cb_desc_readonly  	= "readonly"
					cb_items_id       	= "currency_id"
					cb_items_desc     	= "currency_desc"
					:cb_value_id		= "form.feeduty_currency"
					:cb_value_desc		= "form.feeduty_currency_desc"
					>
					</app-cb>
				</v-col>
				<!-- amount -->
				<v-col cols="12" sm="3" md="3" class="mt-n4">
					<v-text-field 
					v-model.lazy 	  	= "form.feeduty_amount"
					required 
					:rules 	  			="form.feeduty_amount_rules"
					ref					="feeduty_amount"
					:maxlength			="maxinput.amount"
					background-color  	= "numeric"
                	v-money           	="format_money"
					label       		= "Amount"
					placeholder 		= "Amount"
					id 					= "feeduty_amount"
					@keyup.enter 		= "Confirm('write','')"
					reverse
					>
					</v-text-field>
				</v-col>
				<!-- policy status -->
				<v-col cols="12" sm="4" md="4">
					<v-combobox
					v-model 	  	= "form.feeduty_policystatus"
					:items        	= "form.feeduty_policystatus_items"
					:item-text      = "form.feeduty_policystatus_items.text"
					:item-value     = "form.feeduty_policystatus_items.value"
					label       	= "Policy Status"
					placeholder 	= "Policy Status"
					id 				= "feeduty_policystatus"
					@keyup.enter 	= "Confirm('write','')"
					class 			= "body-1"
					dense
					>
					</v-combobox>
				</v-col>
				<v-col cols="12" sm="3" md="3">
					<v-combobox
					v-model 	  	= "form.feeduty_claimstatus"
					:items        	="form.feeduty_claimstatus_items"
					:item-text      = "form.feeduty_claimstatus_items.text"
					:item-value     = "form.feeduty_claimstatus_items.value"
					label       	= "Claim Status"
					placeholder 	= "Claim Status"
					id 				= "feeduty_claimstatus"
					@keyup.enter 	= "Confirm('write','')"
					class 			= "body-1"
					dense
					>
					</v-combobox>
				</v-col>
			   <!-- Active -->
               <v-col cols="12" sm="2" md="2" class="mt-n8">
					<label class="caption">Active Status</label>
					<v-switch
						v-model	= "form.feeduty_actived"
						color  	= "primary"
						id 		= "form.feeduty_actived"
						:label 	= "form.feeduty_actived !== true ? '' : 'Active'" 
					></v-switch>
				</v-col>
            </v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
			   		<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.feeduty_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.feeduty_cuser)">{{form.feeduty_cuser}}</span>
Last Update Date : {{form.feeduty_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.feeduty_luser)">{{form.feeduty_luser}}</span>
</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  <v-card-title 
			  		class="ml-n4"
			  	>
			  		<v-btn 
					  	:color 	="selected.length === items.length ? 'secondary':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	title	="select all page"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
					</v-btn>
	                <v-btn 
	                	color     = "error"
	                	title 	  = "delete selected"
	                	@click 	  = "Confirm('multidelete','')"
	                	class	  = "mr-2"
	                	v-if  	  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false"
	                	small>
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-btn 
	                	color  = "primary"
	                	title  = "search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true"
	                	small>
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    :value="selected"
			    :headers="headers"
			    :items="items"
			    :search="search"
			    @input="Check($event)"
			    item-key="feeduty_id"
			    show-select
			    class="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			    dense
			    :loading 	= "loading.list"
    			loading-text="Loading... Please wait"
			  >
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class	  ="mr-2"
			        @click	  ="Edit(item.feeduty_id)"
			        :disabled = "access.edit === 0 ? true:false"
			        title 	  = "edit"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click	  ="Confirm('delete',item.feeduty_id)"
			        :disabled = "access.delete === 0 ? true:false"
			        title 	  = "delete"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
		</v-tab-item>
  	</v-tabs>
	<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      top
      style 	= "z-index: 9999;"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
  </v-snackbar>
  <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
	<!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>
<script>
export default {
  	data: () => ({
		url_type: 'add',
		cont: false,
  		access: {
  			read: 0,
  			add: 0,
  			edit: 0,
  			delete: 0,
		  },
		maxinput: {
			id:	10,
			desc:150,
		},
		snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000
		},
	    confirm: {
	    	dialog: false,
	    	title: '',
	    	text: 'Ok',
	    },
        selected: [],
        search: '',
        headers: [
          {
            text: 'ID',
            align: 'left',
            value: 'feeduty_id',
          },
          { text: 'Description', value: 'feeduty_desc' },
          { text: 'Code', value: 'feeduty_code' },
          { text: 'CCY', value: 'feeduty_currency' },
          { text: 'Actived', value: 'feeduty_actived' },
          { text: 'Actions', value: 'action', sortable: false }
		],
		remarks: {
			feedback:'',
			dialog: false,
		},
		items: [],
		accessmenu: [],
        loading: {
          page: true,
          list: false
        },
        btn_search: false,
        form: {
        	feeduty_id: '',
        	feeduty_id_rules: [v => !!v || 'Please fill ID'],
        	feeduty_id_readonly: false,
        	feeduty_desc: '',
			feeduty_desc_rules: [v => !!v || 'Please fill Description'],
			feeduty_policystatus: '',
			feeduty_policystatus_items: [
				{text: 'New', value: 'N'},
				{text: 'Endorsment', value: 'E'},
				{text: 'Cancellation', value: 'C'},
				{text: 'Adjustment', value: 'A'},
			],
			feeduty_policystatus_rules: [v => !!v || 'Please fill Policy Status'],
			feeduty_claimstatus: '',
			feeduty_claimstatus_items: [
				{text: 'New', value: 'N'},
				{text: 'Correction', value: 'E'},
				{text: 'Cancellation', value: 'C'},
			],
			feeduty_claimstatus_rules: [v => !!v || 'Please fill Claim Status'],
			feeduty_code: '',
			feeduty_code_items: [
				{text: 'Fee', value: 'F'},
				{text: 'Duty', value: 'D'},
				{text: 'Others', value: 'O'},
			],
			feeduty_code_rules: [v => !!v || 'Please fill Code'],
			feeduty_currency: '',
        	feeduty_currency_rules: [v => !!v || 'Please fill Currency'],
			feeduty_amount: '',
			feeduty_actived: true,
			display_users: '',
        },
		dialog: {
			users: false
		},
        format_money: {
            decimal: ',',
            thousands: '.',
            precision: 0
        },
      	}),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
			//this function use for validating the screen with user privilleges
  			this.Access()
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access () {
			let modul = 'feeduty'
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: 'menu_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let priv = response.data
				if (priv.length > 0) {
                	this.access.read 	= priv[0].usersmenu_read
                	this.access.add  	= priv[0].usersmenu_add
                	this.access.edit    = priv[0].usersmenu_edit
                	this.access.delete  = priv[0].usersmenu_delete
                	if (priv[0].usersmenu_read === 0) {
                		this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                	}
                } else {
                	this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                }
                this.loading.page = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 0)
				this.loading.page = false
			})
		},
        SelectAllPage () {
	      this.selected = this.selected === this.items ? [] : this.items
	    },
	    Check (value) {
	      this.selected = value
	    },
		//Function for get all data from table FeeDuty with API in mod_users.go
		List () {
			this.loading.list = true
			let formdata = {
				feeduty_id: '',
				feeduty_desc: '',
				order_by: 'feeduty_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListFeeDuty'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.items = feedback
					}
				} else {
					this.items = feedback
					this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading.list = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.list = false
			})
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
	  	Confirm (flag, id) {
	  		if (flag === 'write') {
		  		if (this.$refs.form_feeduty.validate()) {
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
		  		}
	  		} else if (flag === 'delete') {
	  			this.url_type 		  = 'delete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ id + '`'
	  			this.feeduty_id 	  = id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multidelete') {
	  			this.url_type 		  = 'multidelete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		}
		  },
		  
		//Function to request insert data to table FeeDuty 'The API will validating your data, include the user who process the data' (go to APIWriteFeeDuty with url_type = "add") 
	  	Add () {
	  		this.url_type 		 			= 'add'
	  		this.form.feeduty_id 	 		= ''
	  		this.form.feeduty_desc 	 		= ''
	  		this.form.feeduty_currency 		= ''
	  		this.form.feeduty_currency_desc	= ''
	  		this.form.feeduty_amount 	    = 0
	  		this.$refs.feeduty_amount.$el.getElementsByTagName('input')[0].value = 0
	  		this.form.feeduty_policystatus	= ''
	  		this.form.feeduty_claimstatus	= ''
	  		this.form.feeduty_actived 		= true
	  		this.form.feeduty_id_readonly 	= false
	  		setTimeout(function(){
                document.getElementById('feeduty_id').focus()
            }, 500)
		},
		//Function to request update data to table FeeDuty 'The API will validating your data, include the user who process the data' (go to APIWriteFeeDuty with url_type = "edit") 
		Edit (id) {
			this.loading.list = true
			let formdata = {
				feeduty_id: id,
				feeduty_desc: '',
				order_by: 'feeduty_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListFeeDuty'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
            .then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		document.getElementById('tabform').click()
	            		this.url_type 			    	= 'edit'
	            		this.form.feeduty_id   	    	= feedback[0].feeduty_id
	                    this.form.feeduty_desc 	    	= feedback[0].feeduty_desc
	                    this.form.feeduty_currency  	= feedback[0].feeduty_currency
	                    this.form.feeduty_amount    	= feedback[0].feeduty_amount
	                    this.$refs.feeduty_amount.$el.getElementsByTagName('input')[0].value = feedback[0].feeduty_amount
	                    this.form.feeduty_policystatus 	= feedback[0].feeduty_policystatus
	                    this.form.feeduty_claimstatus	= feedback[0].feeduty_claimstatus
						this.form.feeduty_actived   	= this.$functions.TrueOrFalse(feedback[0].feeduty_actived)
						this.form.feeduty_cdate			= feedback[0].feeduty_cdate
						this.form.feeduty_cuser			= feedback[0].feeduty_cuser
						this.form.feeduty_ldate			= feedback[0].feeduty_ldate
						this.form.feeduty_luser			= feedback[0].feeduty_luser
	            		this.form.feeduty_id_readonly  = true
						setTimeout(function(){
	                		document.getElementById('feeduty_desc').focus()
	            		}, 500)
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
				}
				this.loading.list = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
				this.loading.list = false
			})
		},
		Delete (id) {
			let formdata = {
				url_type: 'delete',
				feeduty_id: id
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteFeeDuty'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Delete Success', 3000)
		            	this.confirm.dialog  	 = false
		                this.confirm.text 	 	 = 'Ok'
		                this.selected 			 = []
						this.List()
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
	  	MultiProcess () {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteFeeDuty'),param,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				let feedback = response.data
				if (feedback[0].feedback === 'Y') {
					let feed
					if (feedback[0].feedback_failed == 0){
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					} else {
						this.remarks.feedback = feedback[0].feedback_failed_remarks
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, 'success', feed, 0)
					this.confirm.dialog  	 = false
					this.confirm.text 	 	 = 'Ok'
					this.selected 			 = []
					this.List()
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback[0].feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
	  	Loading () {
	  		this.confirm.text = 'Loading...'
	  		if (this.url_type === 'add' || this.url_type === 'edit') {
	  			this.Write()
	  		} else if (this.url_type === 'delete') {
	  			this.Delete(this.feeduty_id)
	  		} else if (this.url_type === 'multidelete') {
	  			this.MultiProcess('delete')
	  		}
	  	},
		Write () {
			let feeduty_currency = document.getElementById('feeduty_currency').value
	  		let formdata = {
				url_type: this.url_type,
				feeduty_id: this.form.feeduty_id,
				feeduty_code: this.form.feeduty_code.value,
				feeduty_desc: this.form.feeduty_desc,
				feeduty_currency: feeduty_currency,
				feeduty_amount: this.form.feeduty_amount,
				feeduty_policystatus: this.form.feeduty_policystatus.value,
				feeduty_claimstatus: this.form.feeduty_claimstatus.value,
				feeduty_actived: this.$functions.ActivedFlag(this.form.feeduty_actived),
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteFeeDuty'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Save Success', 3000)
		            	this.confirm.dialog  	 = false
		                this.confirm.text 	 	 = 'Ok'
		                if (this.url_type === 'add') {
		                	this.form.feeduty_cuser  = feedback[0].feedback_users_id
			  				this.form.feeduty_cdate  = feedback[0].feedback_users_date
			  			}
			  			this.form.feeduty_luser  = feedback[0].feedback_users_id
			  			this.form.feeduty_ldate  = feedback[0].feedback_users_date
			  			this.url_type 			 = 'edit'
		  			} else {
		  				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
	  	SnackBar (dialog, color, text, timeout) {
	      this.snackbar = {
	      	dialog: dialog,
	      	color: color,
	      	text: text,
	      	timeout: timeout
	      }
	      if (color === 'error') {
	      	this.confirm.dialog 	 = false
	      	this.confirm.text 	 	 = 'Ok'
	      }
	    },
		ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	}
}
</script>