<template>
    <div>
        <app-drawer_menu drawer_menu_title="Reinsurance Currency"></app-drawer_menu>
            <v-progress-linear
            indeterminate
            color="fourth"
            rounded
            v-if="loading.page"
          ></v-progress-linear>
            <v-tabs
            background-color="white"
            color="fourth"
            v-if ="access.read ===1"
          >
              <v-tab
                  id     	="tabform"
                  href   	="#tab-form"
                  @click	="FirstLoad"
              >
                  Form
              </v-tab>
              <v-tab 
                  href	="#tab-list"
                  @click 	="List"
              >
                  List
              </v-tab>
              <v-tab-item
                  value  	="tab-form"
              >
              
              <!-- Form Table Limit -->
              <v-form enctype="multipart/form-data" ref="form_reinsrate" lazy-validation>
                  <v-row class="mx-2 ma-1">
                      <v-col cols="12" sm="12" md="12">
                          <v-btn
                              color		="tertiary"
                              class		="white--text"
                              :disabled   ="access.add ===0 ? true:false" 
                              rounded
                              @click 	    ="Add" 
                              small
                          >
                              <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
                          </v-btn> &nbsp;
                          <v-btn
                              color		    ="primary"
                              class		    ="white--text"
                              :disabled 	="access.add ===0 || access.edit ===0 ? true:false"
                              rounded
                              @click		="Confirm('write','')" 
                              small
                          >
                              <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
                          </v-btn> &nbsp;
                      </v-col>
                      <!-- Form Year -->
                      <v-col cols="12" sm="12" md="4">                    
                            <v-text-field
                                v-model 	  	    ="form.reinsrate_year"
                                required
                                :rules              ="form.reinsrate_year_rules"
                                :maxlength 	        ="maxinput.year"
                                label       	    ="Year *"
                                placeholder 	    ="Year"
                                background-color 	="numeric"
                                id 			        ="reinsrate_year"
                                v-mask		        ="'####'"
                                @keyup.enter 	    ="Confirm('write','')"
                                reverse
                            >
                            </v-text-field>                    
                      </v-col>
                      <!-- end: Form Year -->
                  </v-row>
  
                  <!-- Info -->
                  <!-- <v-row class="mx-2">
                      <v-col cols="12" sm="5" md="5">
                      <div v-show="url_type !=='add' ? true:false ">
                          <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                          <v-banner v-model="cont" single-line transition="slide-y-transition">
                              <pre>Created Date 	 : {{form.reinsrate_cdate}}<br>Created User 	 : {{form.reinsrate_cuser}}<br>Last Update Date : {{form.reinsrate_ldate}}<br>Last Update User : {{form.reinsrate_luser}}</pre>
                          </v-banner>
                      </div>
                      </v-col>
                  </v-row> -->
                  <!-- end: Info -->
               </v-form>
              <!-- end: Form Table Limit -->
  
               <template v-if="url_type !=='add'">		
                  <!-- Tab Sub Module -->
                  <v-tabs
                      background-color="white"
                      color="fourth"
                      v-if ="access.read ===1"
                  >
                     <v-tab
                        id     	="tabreinsurancerate"
                        href   	="#tab-reinsurancerate"
                        @click  = "ListReinsuranceRate(form.reinsrate_year)"
                    >
                        Currency
                    </v-tab>
                      <v-tab-item
                          value  ="tab-reinsurancerate"
                      >
                          <!-- Data Table Reinsurance Rate -->
                          <template>
                              <!-- Main Button Reinsurance Rate -->
                              <v-card-title
                                  class="ml-n4"
                              >
                                  <v-btn 
                                      :color 	="reinsurancerate.selected.length ===reinsurancerate.items.length ? 'fourth':''"
                                      @click	="SelectAllReinsuranceRate"
                                      class	    ="mr-2"
                                      title	    ="select all"
                                      small
                                      >select all
                                  </v-btn>
                                  <v-btn 
                                      @click ="OpenReinsuranceRate"
                                      class	="mr-2"
                                      title	="select all"
                                      small
                                      >Add
                                  </v-btn>
                                  <v-btn 
                                      color       ="error"
                                      title 	  ="delete selected"
                                      @click 	  ="Confirm('multideletedetail','')"
                                      class	      ="mr-2"
                                      v-if  	  ="reinsurancerate.selected.length > 0"
                                      :disabled   ="access.delete ===0 ? true:false"
                                      small
                                  >
                                      <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                                  </v-btn>
                                  <v-btn 
                                      color  ="primary"
                                      title  ="search"
                                      v-if   ="reinsurancerate.searchbutton ===false"
                                      @click ="SearchReinsuranceRate"
                                      small>
                                      <v-icon>{{$vars.V('icon_search')}}</v-icon>
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-text-field
                                      v-model		="reinsurancerate.search"
                                      append-icon	="search"
                                      @click:append	="reinsurancerate.searchbutton =false"
                                      label			="Type and Enter"
                                      single-line
                                      hide-details
                                      v-if 			="reinsurancerate.searchbutton"
                                      id   			="searchreinsurancerate"
                                      clearable
                                      @keyup.enter 	="ListReinsuranceRate(form.reinsrate_year)"
                                  ></v-text-field>
                              </v-card-title>
                              <!-- end: Main Button Reinsurance Rate  -->
                              <v-spacer></v-spacer>
                              <v-data-table
                                  :value="reinsurancerate.selected"
                                  :headers="reinsurancerate.headers"
                                  :items="reinsurancerate.items"
                                  :search="reinsurancerate.search"
                                  @input="CheckReinsuranceRate($event)"
                                  item-key="`${reinsrate_year}${reinsrate_ccyfrom}`"
                                  show-select
                                  class="elevation-1"
                                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                                      showFirstLastPage: true,
                                      firstIcon: 'first_page',
                                      lastIcon: 'last_page',
                                      prevIcon: 'keyboard_arrow_left',
                                      nextIcon: 'keyboard_arrow_right'
                                  }"
                                  dense
                                  :loading 	="loading.reinsurancerate"
                                  loading-text="Loading... Please wait"
                              >
                              <template v-slot:item.reinsrate_uwyearrate ="{ item }">
                                {{ FormatNumber(item.reinsrate_uwyearrate,0) }}
                              </template>
                              <template v-slot:item.reinsrate_lossyearrate ="{ item }">
                                {{ FormatNumber(item.reinsrate_lossyearrate,0) }}
                              </template>
                              <template v-slot:item.action="{ item }">
                                  <v-icon
                                      small
                                      class	      ="mr-2"									
                                      @click	  ="EditReinsuranceRate(item.reinsrate_year, item.reinsrate_ccyfrom, item.reinsrate_ccyto)"
                                      :disabled   ="access.edit ===0 ? true:false"
                                      title 	  ="edit"
                                  >
                                      edit
                                  </v-icon>
                                  <v-icon
                                      small
                                      @click	  ="Confirm('deletereinsurancerate', item.reinsrate_year, item.reinsrate_ccyfrom)"
                                      :disabled   ="access.delete ===0 ? true:false"
                                      title 	  ="delete"
                                  >
                                      delete
                                  </v-icon>
                              </template>
                              </v-data-table>							
                          </template>
                          <!-- end: Data Table Reinsurance Rate -->
                      </v-tab-item>
                  </v-tabs>
                  <!-- end: Tab Sub Module -->
              </template>
                </v-tab-item>
                <v-tab-item
                  value="tab-list"
              >
              <template>
                <v-card-title 
                        class="ml-n4"
                    >
                        <v-btn 
                            :color 	="selected.length ===items.length ? 'secondary':''"
                            @click	="SelectAllPage"
                            class	="mr-2"
                            title	="select all page"
                            small
                            >{{$vars.V('txt_select_all_page')}}
                      </v-btn>
                      <!-- <v-btn 
                          color       ="error"
                          title 	  ="delete selected"
                          @click 	  ="Confirm('multidelete','')"
                          class	      ="mr-2"
                          v-if  	  ="selected.length > 0"
                          :disabled   ="access.delete ===0 ? true:false"
                          small>
                          <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                      </v-btn> -->
                      <v-btn 
                          color  ="primary"
                          title  ="search"
                          v-if   ="btn_search ===false"
                          @click ="btn_search =true"
                          small>
                          <v-icon>{{$vars.V('icon_search')}}</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-text-field
                      v-model="search"
                      append-icon="search"
                      @click:append="btn_search =false"
                      label="Search"
                      single-line
                      hide-details
                      v-if ="btn_search"
                        ></v-text-field>
                </v-card-title>
                <v-data-table
                  :value="selected"
                  :headers="headers"
                  :items="items"
                  :search="search"
                  @input="Check($event)"
                  item-key="reinsrate_year"
                  show-select
                  class="elevation-1"
                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right'
                  }"
                  dense
                  :loading 	="loading.list"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class	  ="mr-2"
                      @click	  ="Edit(item.reinsrate_year)"
                      :disabled ="access.edit ===0 ? true:false"
                      title 	  ="edit"
                    >
                      edit
                    </v-icon>
                    <!-- <v-icon
                      small
                      @click	  ="Confirm('delete',item.reinsrate_year)"
                      :disabled ="access.delete ===0 ? true:false"
                      title 	  ="delete"
                    >
                      delete
                    </v-icon> -->
                  </template>
                </v-data-table>
              </template>
          </v-tab-item>
        </v-tabs>
      <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !==''">
          <v-card>
              <v-toolbar dark color="fourth" dense>
              <v-btn icon dark @click="remarks.dialog =false">
                  <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Error Messages</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="mt-2 ml-n2">
                <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
              </v-card-text>
          </v-card>
      </v-dialog>
      <v-snackbar
          v-model	="snackbar.dialog"
          :timeout	="snackbar.timeout"
          color		="fourth"
          rounded	="pill"
          top
          style 	="z-index: 9999"
        >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog =true" small v-if="snackbar.color ==='error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog =false" small>Close</v-btn>
          </template>
        </v-snackbar>
        <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
          <v-card>
              <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
              <v-card-text>
                  
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="dialog" color="success"
                         :disabled   ="confirm.text ==='Ok' ? false:true"
                           @click      ="Loading">{{confirm.text}}</v-btn>
                  <v-btn color="error" @click="Close('confirm')">Close</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  
  
      <!-- Dialog Reinsurance Rate -->
      <v-dialog
        v-model="dialog.reinsurancerate"
        scrollable
        transition="dialog-bottom-transition"
        persistent
        max-width="550"
      >
            <v-card>
              <v-toolbar dark color="primary" dense class="mb-2">
              <v-btn icon dark @click="Close('reinsurancerate')">
                  <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Reinsurance Rate</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="AddReinsuranceRate" title="Add New"><v-icon color="white">add</v-icon></v-btn>
              <v-btn icon dark @click="Confirm(url_type,'')" title="Save Reinsurance Rate"><v-icon color="white">save</v-icon></v-btn>
              </v-toolbar>
              <v-card-text>
                  <v-progress-linear :indeterminate="true" v-if="loading.reinsuranceratedialog"></v-progress-linear>
                  <v-form enctype="multipart/form-data" ref="form_reinsurancerate" lazy-validation>					
                      <v-row class="mx-2 mt-2">						
                          <v-col cols="12" sm="6" md="6" class="mt-n3">							
                              <app-cb
                                  cb_type           	="currency"
                                  cb_url            	="apiListCurrency"
                                  cb_url_parameter  	="currency_actived=Y"
                                  cb_title          	="Currency From"
                                  cb_id             	="reinsrate_ccyfrom"
                                  cb_rules          	="Please fill Currency From"
                                  cb_items_id       	="currency_id"
                                  :cb_value_id          ="form.reinsrate_ccyfrom"
                                  cb_single			    ="Y"
                              >
                              </app-cb>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="mt-n3">
                              <app-cb
                                  cb_type           	="currency"
                                  cb_url            	="apiListCurrency"
                                  cb_url_parameter  	="currency_actived=Y"
                                  cb_title          	="Currency To"
                                  cb_id             	="reinsrate_ccyto"
                                  cb_rules          	="Please fill Currency To"
                                  cb_items_id       	="currency_id"
                                  :cb_value_id          ="form.reinsrate_ccyto"
                                  cb_single			    ="Y"
                              >
                              </app-cb>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="mt-n3">							
                              <v-text-field 
                                  v-model.lazy 		="form.reinsrate_uwyearrate"
                                  ref 				="reinsrate_uwyearrate"
                                  label 		    ="U/W Year Rate (To Base Currency)"
                                  placeholder 		="0"
                                  background-color	="numeric"
                                  v-money 			="format_money"
                                  id 		      	="reinsrate_uwyearrate"
                                  reverse								
                              >
                              </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="mt-n3">
                              <v-text-field 
                                  v-model.lazy 		="form.reinsrate_lossyearrate"
                                  ref 				="reinsrate_lossyearrate"
                                  label 		    ="Loss Year Rate (To Base Currency)"
                                  placeholder 		="0"
                                  background-color	="numeric"
                                  v-money 			="format_money"
                                  id 		      	="reinsrate_lossyearrate"
                                  reverse								
                              >
                              </v-text-field>
                          </v-col>
                      </v-row>
                  </v-form>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>        	
              </v-card-actions>
          </v-card>
      </v-dialog>
      <!-- end: Dialog Reinsurance Rate -->
  
    </div>
  </template>
  <script>
  export default {
      data: () => ({
          url_type: "add",
          cont: false,
          access: {
              read: 0,
              add: 0,
              edit: 0,
              delete: 0,
          },
          maxinput: {
              year: 4,
          },
          snackbar: {
              dialog: false,
              color: "success",
              text: "",
              timeout: 3000,
          },
          confirm: {
              dialog: false,
              title: "",
              text: "Ok",
          },		
          format_money: {
              decimal: ',',
              thousands: '.',
              precision: 0,
          },
          format_percent: {
              decimal: ',',
              thousands: '.',
              precision: 0,
          },
          selected: [],
          search: "",
          headers: [
              {
                  text: "Year",
                  align: "left",
                  value: "reinsrate_year",
              },
              { text: "Count", value: "reinsrate_count" },
              { text: "Actions", value: "action", sortable: false },
          ],
          remarks: {
              feedback: "",
              dialog: false,
          },
          items: [],
          accessmenu: [],
          loading: {
              page: true,
              list: false,
              detail: false,
              detaildialog: false,
              subclass: false,
              channel: false,
              attributes: false,
              treatylimit: false,
              treatylimitdialog: false,
              cocessionlimit: false,
              cocessionlimitdialog: false,
              reinsurancerate: false,
              reinsuranceratedialog: false
          },
          btn_search: false,
          form: {
              reinsrate_year: '',
              reinsrate_year_rules: [v => !!v || 'Please fill Year'],
              // Reinsurance Rate
              reinsrate_ccyfrom: '',			
              reinsrate_ccyto: '',			
              reinsrate_uwyearrate: '',
              reinsrate_lossyearrate: ''
          },
          attributes: false,
          detail: {
              headers: [
                  {
                      text: "ID",
                      align: "left",
                      sortable: false,
                      value: "gendet_id",
                  },
                  { text: "Description", value: "gendet_desc" },
                  { text: "Actived", value: "gendet_actived" },
                  { text: "Actions", value: "action", sortable: false },
              ],
              items: [],
              selected: [],
              checksubclass: false,
              checkchannel: false,
              search: "",
              searchbutton: false,
          },
          flag: {
              detail: false,
              showdetail: false,
              reinsurancerate: false,
              showreinsurancerate: false
          },
          dialog: {
              attributes: false,
              subclass: false,
              channel: false,
              detail: false,			
              reinsurancerate: false,
          },
  
          // Data Table Reinsurance Rate						
          reinsurancerate: {  
              headers: [
                  {
                      text: "From",
                      align: "left",
                      value: "reinsrate_ccyfrom",
                  },
                  {
                      text: "To",
                      align: "left",
                      value: "reinsrate_ccyto",
                  },
                  {
                      text: "UW Rate",
                      align: "right",
                      value: "reinsrate_uwyearrate",
                  },
                  {
                      text: "Loss Rate",
                      align: "right",
                      value: "reinsrate_lossyearrate",
                  },
                  { text: "Action", value: "action" }
              ],     
              items: [], 	
              selected: [],
              search: "",
              searchbutton: false,
          },
          // end: Data Table Reinsurance Rate
      }),
      created() {
          this.FirstLoad()
      },
      methods: {
          FirstLoad() {
              //this function use for validating the screen with user privilleges
              this.Access()
          },
          //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
          Access() {
              let modul ="reinsrate"
              let formdata ={
                  menu_id: modul,
                  users_id: this.$functions.UsersID(),
                  order_by: "menu_id",
                  order_type: "ASC",
                  limit: this.limit,
              }
              let param =this.$functions.ParamPOST(formdata)
              this.$axios
                  .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
                      headers: {
                          "Content-Type": "text/plain charset=ISO-8859-1",
                      },
                  })
                  .then((response) => {
                      let priv =response.data
                      if (priv.length > 0) {
                          this.access.read =priv[0].usersmenu_read
                          this.access.add =priv[0].usersmenu_add
                          this.access.edit =priv[0].usersmenu_edit
                          this.access.delete =priv[0].usersmenu_delete
                          if (priv[0].usersmenu_read ===0) {
                              this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                          }
                      } else {
                          this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                      }
                      this.loading.page =false
                  })
                  .catch((e) => {
                      this.SnackBar(true, "error", e, 0)
                      this.loading.page =false
                  })
          },
          SelectAllPage() {
              this.selected =this.selected ===this.items ? [] : this.items
          },
          Check(value) {
              this.selected =value
          },
          SelectAllDetail() {
              this.detail.selected =this.detail.selected ===this.detail.items ? [] : this.detail.items
          },
          CheckDetail(value) {
              this.detail.selected =value
          },
          //Function for get all data from table GenTab with API in mod_users.go
          List() {
              this.loading.list =true
              let formdata ={
                  reinsrate_year: "",
                  reinsrate_description: "",
                  order_by: "reinsrate_year",
                  order_type: "ASC",
                  limit: this.limit,
              }
              let param =this.$functions.ParamPOST(formdata)
              this.$axios
                  .post(this.$functions.UrlPOST("apiListReinsRateYear"), param, {
                      headers: {
                          "Content-Type": "text/plain charset=ISO-8859-1",
                      },
                  })
                  .then((response) => {
                      let feedback =response.data
                      if (feedback.length > 0) {
                          if (feedback[0].feedback !=="Y") {
                              this.SnackBar(
                                  true,
                                  "error",
                                  feedback[0].feedback,
                                  0
                              )
                          } else {
                              this.items =feedback
                          }
                      } else {
                          this.items =feedback
                          this.SnackBar(true, 'error', this.$functions.NoData(), 0)
                      }
                      this.loading.list =false
                  })
                  .catch((e) => {
                      this.SnackBar(true, "error", e, 3000)
                      this.loading.list =false
                  })
          },
          //Pop Up message for validating (Ok or Cancel) your proccess
          Confirm(flag, id, key1, key2) {
              if (flag ==="write") {
                  if (this.$refs.form_reinsrate.validate()) {
                      this.confirm.dialog =true
                      this.confirm.title ="Save"
                      setTimeout(function() {
                          document.getElementById("dialog").focus()
                      }, 500)
                  }
              } else if (flag ==="delete") {
                  this.url_type ="delete"
                  this.confirm.dialog =true
                  this.confirm.title ="Delete `" + id + "`"
                  this.form.reinsrate_year =id
                  this.form.reinsrate_ccyfrom =key1
                  setTimeout(function() {
                      document.getElementById("dialog").focus()
                  }, 500)
              } else if (flag ==="multidelete") {
                  this.url_type ="multidelete"
                  this.confirm.dialog =true
                  this.confirm.title = "Delete `" + this.selected.length + "` data"
                  setTimeout(function() {
                      document.getElementById("dialog").focus()
                  }, 500)
                } else if (flag ==='addreinsurancerate') {
                  if (this.$refs.form_reinsurancerate.validate()) {
                        this.url_type 		  ='addreinsurancerate'
                        this.confirm.dialog   =true
                        this.confirm.title    ='Save Reinsurance Rate'
                        setTimeout(function(){
                          document.getElementById('dialog').focus()
                      }, 500)
                    }
                }  else if (flag ==='editreinsurancerate') {
                  if (this.$refs.form_reinsurancerate.validate()) {
                        this.url_type 		  ='editreinsurancerate'
                        this.confirm.dialog   =true
                        this.confirm.title    ='Save Reinsurance Rate'
                        setTimeout(function(){
                          document.getElementById('dialog').focus()
                      }, 500)
                    }
                } else if (flag ==='deletereinsurancerate') {
                    this.url_type 		  				='deletereinsurancerate'
                    this.confirm.dialog   				=true
                    this.confirm.title    				='Delete Reinsurance Rate `' +id+'-'+ key1 + '`'
                    this.form.reinsrate_year            =id
                    this.form.reinsrate_ccyfrom   		=key1
                    this.form.reinsrate_ccyto   		=key2
                    setTimeout(function(){
                      document.getElementById('dialog').focus()
                  }, 500)
                } else if (flag ==='multideletereinsurancerate') {
                    this.url_type 		  ='multideletereinsurancerate'
                    this.confirm.dialog   =true
                    this.confirm.title    ='Delete `'+ this.reinsurancerate.selected.length + '` data'
                    setTimeout(function(){
                      document.getElementById('dialog').focus()
                  }, 500)
                }
          },
  
          //Function to request insert data to table Table Limit 'The API will validating your data, include the user who process the data' (go to APIWriteGenTab with url_type ="add")
          Add() {
              this.url_type 					         ="add"			
              this.form.reinsrate_subclassid             =""
              this.form.reinsrate_description	         =""
              this.form.reinsrate_year	                 =""			
              this.form.reinsrate_subclassid_readonly =false
              setTimeout(function() {
                  document.getElementById("reinsrate_subclassid").focus()
              }, 500)
          },
          //Function to request update data to table Table Limit 'The API will validating your data, include the user who process the data' (go to APIWriteGenTab with url_type ="edit")
          Edit(id) {			
              this.loading.list =true			
                  
              let formdata ={
                  reinsrate_year		: id,
                  order_by				: "reinsrate_year",
                  order_type			: "ASC",
                  limit					: this.limit,
              }
              let param =this.$functions.ParamPOST(formdata)
              this.$axios
                  .post(this.$functions.UrlPOST("apiListReinsRateYear"), param, {
                      headers: {
                          "Content-Type": "text/plain charset=ISO-8859-1",
                      },
                  })
                  .then((response) => {
                      let feedback =response.data
                      if (feedback.length > 0) {
                          if (feedback[0].feedback ==="Y") {
                              document.getElementById("tabform").click()
                              this.url_type 						="edit"
                              this.form.reinsrate_year 			    =feedback[0].reinsrate_year
                              this.ListReinsuranceRate(id)
                          } else {
                              this.SnackBar(
                                  true,
                                  "error",
                                  this.$functions.ErrorCode(),
                                  0
                              )
                              this.remarks.dialog =true
                              this.remarks.feedback =feedback[0].feedback
                          }
                      } else {
                          this.SnackBar(true, "error", feedback, 0)
                      }
                      this.loading.list =false
                  })
                  .catch((e) => {
                      this.SnackBar(
                          true,
                          "error",
                          this.$functions.ErrorCode(),
                          0
                      )
                      this.remarks.dialog =true
                      this.remarks.feedback =e
                      this.loading.list =false
                  })
          },
          Delete(id) {
              let formdata ={
                  url_type: "delete",
                  gentab_id: id,
              }
              let param =this.$functions.ParamPOST(formdata)
              this.$axios
                  .post(this.$functions.UrlPOST("apiWriteReinsRate"), param, {
                      headers: {
                          "Content-Type": "text/plain charset=ISO-8859-1",
                      },
                  })
                  .then((response) => {
                      let feedback =response.data
                      if (feedback.length > 0) {
                          if (feedback[0].feedback ==="Y") {
                              this.SnackBar(
                                  true,
                                  "success",
                                  "Delete Success",
                                  3000
                              )
                              this.confirm.dialog =false
                              this.confirm.text ="Ok"
                              this.selected =[]
                              this.List()
                          } else {
                              this.SnackBar(
                                  true,
                                  "error",
                                  this.$functions.ErrorCode(),
                                  0
                              )
                              this.remarks.dialog 	=true
                              this.remarks.feedback =feedback[0].feedback
                          }
                      }
                  })
                  .catch((e) => {
                      this.SnackBar(
                          true,
                          "error",
                          this.$functions.ErrorCode(),
                          0
                      )
                      this.remarks.dialog 	=true
                      this.remarks.feedback =e
                  })
          },
          MultiProcess() {
              let multiData =JSON.stringify(this.selected)
              let multiDetail =JSON.stringify(this.detail.selected)
              let formdata ={
                  url_type: this.url_type,
                  multi_data: multiData,
                  multi_detail: multiDetail,
              }
              let param =this.$functions.ParamPOST(formdata)
              this.$axios
                  .post(this.$functions.UrlPOST("apiWriteReinsuranceRate"), param, {
                      headers: {
                          "Content-Type": "text/plain charset=ISO-8859-1",
                      },
                  })
                  .then((response) => {
                      let feedback =response.data
                      if (feedback[0].feedback ==="Y") {
                          let feed
                          if (feedback[0].feedback_failed ==0) {
                              feed =`Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                          } else {
                              this.remarks.feedback =feedback[0].feedback_failed_remarks
                              feed =`Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                              this.remarks.dialog =true
                          }
                          this.SnackBar(true, "success", feed, 0)
                          this.confirm.dialog =false
                          this.confirm.text 	="Ok"
                          this.selected 		=[]
                          if (this.url_type ==='multidelete') {
                              this.List()
                          } else if (this.url_type ==='multideletedetail') {
                              this.ListDetail()
                              this.url_type ='edit'
                          }
                      } else {
                          this.SnackBar(
                              true,
                              "error",
                              this.$functions.ErrorCode(),
                              0
                          )
                          this.remarks.dialog =true
                          this.remarks.feedback =feedback[0].feedback
                      }
                  })
                  .catch((e) => {
                      this.SnackBar(
                          true,
                          "error",
                          this.$functions.ErrorCode(),
                          0
                      )
                      this.remarks.dialog =true
                      this.remarks.feedback =e
                  })
          },
          Loading() {
              this.confirm.text ="Loading..."
              if (this.url_type ==="add" || this.url_type ==="edit") {
                  this.Write()
              } else if (this.url_type ==="delete") {
                  this.Delete(this.gentab_id)
              } else if (this.url_type ==="multidelete") {
                  this.MultiProcess("delete")
              } else if (this.url_type ==='addreinsurancerate' || this.url_type ==='editreinsurancerate') {
                    this.WriteReinsuranceRate()
                } else if (this.url_type ==="deletereinsurancerate") {
                  this.DeleteReinsuranceRate(this.form.reinsrate_year, this.form.reinsrate_ccyfrom, this.form.reinsrate_ccyto)
              } else if (this.url_type ==='multideletereinsurancerate') {
                    this.MultiProcess('multideletereinsurancerate')
              }
          },
          Write() {
              let formdata ={
                  url_type				: this.url_type,
                  reinsrate_year		: this.form.reinsrate_year,
              }
              let param =this.$functions.ParamPOST(formdata)
              this.$axios
                  .post(this.$functions.UrlPOST("apiWriteReinsuranceRate"), param, {
                      headers: {
                          "Content-Type": "text/plain charset=ISO-8859-1",
                      },
                  })
                  .then((response) => {
                      let feedback =response.data
                      if (feedback.length > 0) {
                          if (feedback[0].feedback ==="Y") {
                              this.SnackBar(
                                  true,
                                  "success",
                                  "Save Success",
                                  3000
                              )
                              this.confirm.dialog =false
                              this.confirm.text ="Ok"
                              if (this.url_type ==="add") {
                                  this.form.reinsrate_cuser =feedback[0].feedback_users_id
                                  this.form.reinsrate_cdate =feedback[0].feedback_users_date
                              }
                              this.form.reinsrate_ldate =feedback[0].feedback_users_id
                              this.form.reinsrate_luser =feedback[0].feedback_users_date
                              this.url_type ="edit"
                          } else {
                              this.SnackBar(
                                  true,
                                  "error",
                                  this.$functions.ErrorCode(),
                                  0
                              )
                              this.remarks.dialog 	=true
                              this.remarks.feedback =feedback[0].feedback
                          }
                      } else {
                          this.SnackBar(
                              true,
                              "error",
                              this.$functions.ErrorCode(),
                              0
                          )
                          this.remarks.dialog 	=true
                          this.remarks.feedback 	=feedback
                      }
                  })
                  .catch((e) => {
                      this.SnackBar(
                          true,
                          "error",
                          this.$functions.ErrorCode(),
                          0
                      )
                      this.remarks.dialog 	=true
                      this.remarks.feedback 	=e
                  })
          },
          SnackBar(dialog, color, text, timeout) {
              this.snackbar ={
                  dialog: dialog,
                  color: color,
                  text: text,
                  timeout: timeout,
              }
              if (color ==="error") {
                  this.confirm.dialog 	=false
                  this.confirm.text 		="Ok"
              }
          },
            Close (flag) {
                if (flag ==='confirm') {
                    this.confirm.dialog =false
                    if (this.url_type !=='add') {
                        this.url_type ='edit'
                    }
                } else if (flag ==='reinsurancerate') {
                  this.url_type ='edit'
                  this.dialog.reinsurancerate =false
                  this.ListReinsuranceRate(this.form.reinsrate_year)
              }
            },
            Search () {
                this.detail.searchbutton =true
                setTimeout(function() {
                  document.getElementById("searchdetail").focus()
              }, 500)
            },
          
          // Function Reinsurance Rate
          SearchReinsuranceRate () {
                this.reinsurancerate.searchbutton =true
                setTimeout(function() {
                  document.getElementById("searchreinsurancerate").focus()
              }, 500)
            },
  
          ListReinsuranceRate (id) {						
              this.loading.reinsurancerate =true
              let formdata ={
                  reinsrate_year: id,
                  order_by: "reinsrate_year",
                  order_type: "ASC",
                  limit: this.limit,
              }
              let param =this.$functions.ParamPOST(formdata)
              this.$axios
                  .post(this.$functions.UrlPOST("apiListReinsRate"), param, {
                      headers: {
                          "Content-Type": "text/plain charset=ISO-8859-1",
                      },
                  })
                  .then((response) => {
                      let feedback =response.data
                      if (feedback.length > 0) {
                          if (feedback[0].feedback !=="Y") {
                              this.SnackBar(
                                  true,
                                  "error",
                                  feedback[0].feedback,
                                  0
                              )
                          } else {
                              this.reinsurancerate.items =feedback
                          }
                      } else {
                          this.reinsurancerate.items =feedback
                          this.SnackBar(true, 'error', this.$functions.NoData(), 0)
                      }
                      this.loading.reinsurancerate =false
                  })
                  .catch((e) => {
                      this.SnackBar(true, "error", e, 3000)
                      this.loading.reinsurancerate =false
                  })
          },
  
          WriteReinsuranceRate () {
              let ccyfrom	=document.getElementById('reinsrate_ccyfrom').value
              let ccyto 	=document.getElementById('reinsrate_ccyto').value
              let formdata ={
                  url_type				 : this.url_type,
                  reinsrate_year	     : this.form.reinsrate_year,
                  reinsrate_ccyfrom		 : ccyfrom,
                  reinsrate_ccyto		 : ccyto,
                  reinsrate_uwyearrate	 : this.form.reinsrate_uwyearrate,
                  reinsrate_lossyearrate : this.form.reinsrate_lossyearrate
              }
              let param =this.$functions.ParamPOST(formdata)
              this.$axios
                  .post(this.$functions.UrlPOST("apiWriteReinsuranceRate"), param, {
                      headers: {
                          "Content-Type": "text/plain charset=ISO-8859-1",
                      },
                  })
                  .then((response) => {
                      let feedback =response.data
                      if (feedback.length > 0) {
                          if (feedback[0].feedback ==="Y") {
                              this.SnackBar(
                                  true,
                                  "success",
                                  "Save Success",
                                  3000
                              )
                              this.confirm.dialog =false
                              this.confirm.text ="Ok"
                              if (this.url_type ==="addreinsurancerate") {
                                  this.form.reinsrate_cuser =feedback[0].feedback_users_id
                                  this.form.reinsrate_cdate =feedback[0].feedback_users_date
                              }
                              this.form.reinsrate_ldate =feedback[0].feedback_users_id
                              this.form.reinsrate_luser =feedback[0].feedback_users_date
                              this.url_type ="editreinsurancerate"
                          } else {
                              this.SnackBar(
                                  true,
                                  "error",
                                  this.$functions.ErrorCode(),
                                  0
                              )
                              this.remarks.dialog 	=true
                              this.remarks.feedback 	=feedback[0].feedback
                          }
                      } else {
                          this.SnackBar(
                              true,
                              "error",
                              this.$functions.ErrorCode(),
                              0
                          )
                          this.remarks.dialog 	=true
                          this.remarks.feedback 	=feedback
                      }
                  })
                  .catch((e) => {
                      this.SnackBar(
                          true,
                          "error",
                          this.$functions.ErrorCode(),
                          0
                      )
                      this.remarks.dialog 	=true
                      this.remarks.feedback 	=e
                  })
          },
          
          OpenReinsuranceRate () {
              this.dialog.reinsurancerate =true
              this.AddReinsuranceRate()
          },
  
          AddReinsuranceRate () {
              this.url_type      	  	 			='addreinsurancerate'
              this.form.reinsrate_ccyfrom			=''
              this.form.reinsrate_ccyto			    =''
              this.form.reinsrate_uwyearrate		=0
              this.form.reinsrate_lossyearrate	    =0			
              this.flag.reinsurancerate			    =true
              this.dialog.reinsurancerate			=true
              
              this.$refs.reinsrate_uwyearrate.$el.getElementsByTagName('input')[0].value =0
              this.$refs.reinsrate_lossyearrate.$el.getElementsByTagName('input')[0].value =0
          },
  
          EditReinsuranceRate (row, ccyfrom, ccyto) {			
              this.flag.reinsurancerate			=true
              this.dialog.reinsurancerate			=true
              this.loading.reinsuranceratedialog 	=true
  
              let formdata ={
                  reinsrate_year: row,
                  reinsrate_ccyfrom: ccyfrom,
                  reinsrate_ccyto: ccyto,
                  order_by: 'reinsrate_year',
                  order_type: 'ASC',
                  limit: this.limit,
              }
              let param 	   =this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiListReinsRate'),param,{
                  headers: {
                      'Content-Type': 'text/plain; charset=ISO-8859-1'
                  }
              })
              .then(response => {
                  let feedback =response.data
                  if (feedback.length > 0) {
                      if (feedback[0].feedback ==='Y') {	            		
                          this.url_type 			    		='editreinsurancerate'
                          this.form.reinsrate_year  	        =feedback[0].reinsrate_year
                          this.form.reinsrate_ccyfrom 	        =feedback[0].reinsrate_ccyfrom
                          this.form.reinsrate_ccyto  			=feedback[0].reinsrate_ccyto	
                          this.form.reinsrate_uwyearrate		=this.FormatNumber(feedback[0].reinsrate_uwyearrate,0)
                          this.form.reinsrate_lossyearrate		=this.FormatNumber(feedback[0].reinsrate_lossyearrate,0)
  
                          this.$refs.reinsrate_uwyearrate.$el.getElementsByTagName('input')[0].value 	=this.FormatNumber(feedback[0].reinsrate_uwyearrate,0)
                          this.$refs.reinsrate_lossyearrate.$el.getElementsByTagName('input')[0].value 	=this.FormatNumber(feedback[0].reinsrate_lossyearrate,0)           					
  
                          this.form.reinsrate_cdate			=feedback[0].reinsrate_cdate
                          this.form.reinsrate_cuser			=feedback[0].reinsrate_cuser
                          this.form.reinsrate_ldate			=feedback[0].reinsrate_ldate
                          this.form.reinsrate_luser			=feedback[0].reinsrate_luser
                          // this.form.feeduty_id_readonly  =true					
                        } else {
                          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                          this.remarks.dialog =true
                          this.remarks.feedback =feedback[0].feedback
                      }
                  } else {
                      this.SnackBar(true, 'error', feedback, 0)
                  }
                  this.loading.reinsuranceratedialog =false
              })
              .catch(e => {
                  this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                  this.remarks.dialog =true
                  this.remarks.feedback =e
                  this.loading.reinsuranceratedialog =false
              })		
          },
  
          DeleteReinsuranceRate(row, ccyfrom, ccyto) {
              let formdata ={
                  url_type: "delete",
                  reinsrate_year: row,
                  reinsrate_ccyfrom: ccyfrom,
                  reinsrate_ccyto: ccyto
              }
              let param =this.$functions.ParamPOST(formdata)
              this.$axios
                  .post(this.$functions.UrlPOST("apiWriteReinsuranceRate"), param, {
                      headers: {
                          "Content-Type": "text/plain charset=ISO-8859-1",
                      },
                  })
                  .then((response) => {
                      let feedback =response.data
                      if (feedback.length > 0) {
                          if (feedback[0].feedback ==="Y") {
                              this.SnackBar(
                                  true,
                                  "success",
                                  "Delete Success",
                                  3000
                              )
                              this.confirm.dialog =false
                              this.confirm.text ="Ok"
                              this.selected =[]
                              this.ListReinsuranceRate()
                          } else {
                              this.SnackBar(
                                  true,
                                  "error",
                                  this.$functions.ErrorCode(),
                                  0
                              )
                              this.remarks.dialog 	=true
                              this.remarks.feedback 	=feedback[0].feedback
                          }
                      }
                  })
                  .catch((e) => {
                      this.SnackBar(
                          true,
                          "error",
                          this.$functions.ErrorCode(),
                          0
                      )
                      this.remarks.dialog 	=true
                      this.remarks.feedback 	=e
                  })
          },
  
          SelectAllReinsuranceRate() {
              this.reinsurancerate.selected =this.reinsurancerate.selected ===this.reinsurancerate.items ? [] : this.reinsurancerate.items
          },
  
          CheckReinsuranceRate(value) {
              this.reinsurancerate.selected =value
          },
          FormatNumber(value,fixed) {
			return this.$functions.NewFormatNumber(value,fixed)
		  }
          //  end: Function Reinsurance Rate 
      },
  };
  </script>