<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Country"></app-drawer_menu>
	  	<v-progress-linear
            indeterminate
            color="fourth"
            rounded
            v-if="loading.page"
	    ></v-progress-linear>
		<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if = "access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_country" lazy-validation>
			<v-row class="mx-2 ma-1">
				<v-col cols="12" sm="12" md="12">
					<v-btn
				      color		    ="tertiary"
				      class		    ="white--text"
				      :disabled     = "access.add === 0 ? true:false" 
				      rounded
				      @click 	    = "Add" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
				    </v-btn> &nbsp;
				    <v-btn
				      color		="primary"
				      class		="white--text"
				      :disabled = "access.add === 1 || access.edit === 1 ? false:true"
				      rounded
				      @click 	= "Confirm('write','')" 
				      small
				    >
				      <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
				    </v-btn>
				</v-col>
              <!-- ID -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field
                  v-model 	  	= "form.country_id"
                  required
                  :rules 	  	="form.country_id_rules"
                  :readonly     = "form.country_id_readonly"
				  :maxlength 	="maxinput.id"
				  :counter		="maxinput.id"
                  label       	= "ID *"
                  placeholder 	= "ID"
                  id 			= "country_id"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- Desc -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field
                  v-model 	  	= "form.country_desc"
                  required 
                  :rules 	  	="form.country_desc_rules"
				  :maxlength 	="maxinput.desc"
                  :counter		="maxinput.desc"
                  label       	= "Description"
                  placeholder 	= "Description"
                  id 			= "country_desc"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- Domain -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field
                  v-model 	  	= "form.country_domain"
                  required 
                  :rules 	  	="form.country_domain_rules"
				  :maxlength 	="maxinput.domain"
                  :counter		="maxinput.domain"
                  label       	= "Domain"
                  placeholder 	= "Domain"
                  id 			= "country_domain"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <!-- Phone Code -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field
                  v-model 	  	= "form.country_phonecode"
                  required 
                  :rules 	  	="form.country_phonecode_rules"
				  :maxlength 	="maxinput.phonecode"
                  :counter		="maxinput.phonecode"
                  label       	= "Phone Code"
                  placeholder 	= "Phone Code"
                  id 			= "country_phonecode"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>   
                    <v-col cols="12" sm="2" md="2" class="mt-n2">
                        <small>Default</small><br>
                        <v-btn-toggle dense v-model="form.country_defaultf" rounded>
                        <v-btn>
                            No
                        </v-btn>
                        <v-btn>
                            Yes
                        </v-btn>
                        </v-btn-toggle>
                    </v-col>
			   <!-- Active -->
               <v-col cols="12" sm="2" md="2" class="mt-n5">
					<label>Active Status</label>
					<v-switch
						v-model	="form.country_actived"
						color  	= "primary"
						id 		= "form.country_actived"
						:label 	="form.country_actived !== true ? '' : 'Active'" 
					></v-switch>
				</v-col>
            </v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
			   		<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.country_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.country_cuser)">{{form.country_cuser}}</span>
Last Update Date : {{form.country_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.country_luser)">{{form.country_luser}}</span>
</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  <v-card-title 
			  		class="ml-n4"
			  	>
			  		<v-btn 
					  	:color 	="selected.length === items.length ? 'secondary':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	title	="select all page"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
					</v-btn>
	                <v-btn 
	                	color     = "error"
	                	title 	  = "delete selected"
	                	@click 	  = "Confirm('multidelete','')"
	                	class	  = "mr-2"
	                	v-if  	  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false"
	                	small>
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-btn 
	                	color  = "primary"
	                	title  = "search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true"
	                	small>
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    :value="selected"
			    :headers="headers"
			    :items="items"
			    :search="search"
			    @input="Check($event)"
			    item-key="country_id"
			    show-select
			    country="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
				dense
				:loading  ="loading.list"
				loading-text="Loading... Please wait"
			  >
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class	  ="mr-2"
			        @click	  ="Edit(item.country_id)"
			        :disabled = "access.edit === 0 ? true:false"
			        title 	  = "edit"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click	  ="Confirm('delete',item.country_id)"
			        :disabled = "access.delete === 0 ? true:false"
			        title 	  = "delete"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
            <template  slot="items" slot-scope="row">
                <tr>
                	<td>
				        <v-checkbox
				          v-model="props.selected"
				          primary
				          hide-details
				        ></v-checkbox>
				      </td>
                    <td class="text-xs-left">{{row.item.country_id}}</td>
                    <td class="text-xs-left">{{row.item.country_desc}}</td>
                    <td class="text-xs-left">{{row.item.country_actived}}</td>
                    <td class="text-xs-left">Edit</td>
                </tr>
            </template>
		</v-tab-item>
  	</v-tabs>
	<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth">
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      absolute
      top
      style 	= "z-index: 9999;"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
  </v-snackbar>
  <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
      <v-card :color="Variable('confirm',4)">
          <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
          <v-card-text>
              
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="dialog" :color="Variable('confirm',1)"
                  :disabled   = "confirm.text === 'Ok' ? false:true"
                @click      = "Loading">{{confirm.text}}</v-btn>
              <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
	<!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>
<script>
export default {
  	data: () => ({
		url_type: 'add',
		cont: false,
  		access: {
  			read: 0,
  			add: 0,
  			edit: 0,
  			delete: 0,
		  },
		maxinput: {
			id:	4,
            desc:255,
            domain:5,
            phonecode:5,
		},
		loading: {
			page: true,
			list: false,
		},
		snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000
		},
	    confirm: {
	    	dialog: false,
	    	title: '',
	    	text: 'Ok',
	    },
        selected: [],
        search: '',
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: false,
            value: 'country_id',
          },
          { text: 'Description', value: 'country_desc' },
          { text: 'Actived', value: 'country_actived' },
          { text: 'Actions', value: 'action', sortable: false }
		],
		remarks: {
			feedback:'',
			dialog: false,
		},
		items: [],
		accessmenu: [],
        btn_search: false,
        form: {
        	country_id: '',
        	country_id_rules: [v => !!v || 'Please fill ID'],
        	country_id_readonly: false,
        	country_desc: '',
            country_desc_rules: [v => !!v || 'Please fill Description'],
            country_domain : '',
            country_domain_rules : [v => !!v || 'Please fill Domain'],
            country_phonecode : '',
            country_phonecode_rules :[v => !!v || 'Please fill Phone Code'],
            country_defaultf : 0,
        	country_actived: true,
        	display_users: '',
        },
		dialog: {
			users: false
		},
        loader: null,
      	}),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
			//this function use for validating the screen with user privilleges
  			this.Access()
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "country"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let priv = response.data
                if (priv.length > 0) {
                    this.access.read        = priv[0].usersmenu_read
                    this.access.add         = priv[0].usersmenu_add
                    this.access.edit        = priv[0].usersmenu_edit
                    this.access.delete      = priv[0].usersmenu_delete
                    if (priv[0].usersmenu_read === 0) {
                        this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                    }
                } else {
                    this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                }
                this.loading.page = false
            })
            .catch((e) => {
                this.SnackBar(true, "error", e, 0)
                this.loading.page = false
            })
        },
        SelectAllPage () {
	      this.selected = this.selected === this.items ? [] : this.items
	    },
	    Check (value) {
	      this.selected = value
	    },
		//Function for get all data from table country with API in mod_users.go
		List() {
			this.loading.list = true
			let formdata = {
				country_id: "",
				country_desc: "",
				order_by: "country_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListCountry"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback !== "Y") {
                        this.SnackBar(
                            true,
                            "error",
                            feedback[0].feedback,
                            0
                        )
                    } else {
                        this.items = feedback
                    }
                } else {
                    this.SnackBar(true, "error", feedback, 0)
                }
                this.loading.list = false
            })
            .catch((e) => {
            this.SnackBar(true, "error", e, 3000)
                this.loading.list = false
            })
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
	  	Confirm (flag, id) {
	  		if (flag === 'write') {
		  		if (this.$refs.form_country.validate()) {
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
		  		}
	  		} else if (flag === 'delete') {
	  			this.url_type 		  = 'delete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ id
	  			this.country_id 	  = id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multidelete') {
	  			this.url_type 		  = 'multidelete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		}
		},
		//Function to request insert data to table channel 'The API will validating your data, include the user who process the data' (go to APIWritechannel with url_type = "add") 
	  	Add () {
	  		this.url_type 		 		= 'add'
	  		this.form.country_id 	 	= ''
            this.form.country_desc 	 	= ''
            this.form.country_domain    = ''
            this.form.country_phonecode = ''
            this.form.country_defaultf  = 0  
	  		this.form.country_actived 	= true
	  		this.form.country_id_readonly = false
	  		setTimeout(function(){
                document.getElementById('country_id').focus()
            }, 500)
		},
		//Function to request update data to table channel 'The API will validating your data, include the user who process the data' (go to APIWritechannel with url_type = "edit") 
		Edit(id) {
			this.loading.list = true
			let formdata = {
				country_id: id,
				country_desc: "",
				order_by: "country_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListCountry"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === "Y") {
                        document.getElementById("tabform").click()
                        this.url_type 			    = 'edit'
						this.form.country_id   	    = feedback[0].country_id
						this.form.country_desc 	    = feedback[0].country_desc
						this.form.country_domain 	= feedback[0].country_domain
						this.form.country_phonecode = feedback[0].country_phonecode
						this.form.country_defaultf  = this.$functions.YesOrNo(feedback[0].country_defaultf)
						this.form.country_actived   = this.$functions.TrueOrFalse(feedback[0].country_actived)
						this.form.country_cdate		= feedback[0].country_cdate
						this.form.country_cuser		= feedback[0].country_cuser
						this.form.country_ldate		= feedback[0].country_ldate
						this.form.country_luser		= feedback[0].country_luser
						this.form.country_id_readonly  = true
                        setTimeout(function() {
                            document.getElementById("country_desc").focus()
                        }, 500)
                    } else {
                        this.SnackBar(
                            true,
                            "error",
                            this.$functions.ErrorCode(),
                            0
                        )
                        this.remarks.dialog = true
                        this.remarks.feedback = feedback[0].feedback
                    }
                } else {
                    this.SnackBar(true, "error", feedback, 0)
                }
                this.loading.list = false
            })
            .catch((e) => {
                this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                )
                this.remarks.dialog = true
                this.remarks.feedback = e
                this.loading.list = false
            })
		},
		Delete(id) {
			let formdata = {
				url_type: "delete",
				country_id: id,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteCountry"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === "Y") {
                        this.SnackBar(
                            true,
                            "success",
                            "Delete Success",
                            3000
                        )
                        this.confirm.dialog = false
                        this.confirm.text = "Ok"
                        this.selected = []
                        this.List()
                    } else {
                        this.SnackBar(
                            true,
                            "error",
                            this.$functions.ErrorCode(),
                            0
                        )
                        this.remarks.dialog 	= true
                        this.remarks.feedback 	= feedback[0].feedback
                    }
                }
            })
            .catch((e) => {
                this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                )
                this.remarks.dialog 	= true
                this.remarks.feedback 	= e
            })
		},
		MultiProcess() {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteCountry"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback[0].feedback === "Y") {
                    let feed
                    if (feedback[0].feedback_failed == 0) {
                        feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                    } else {
                        this.remarks.feedback = feedback[0].feedback_failed_remarks
                        feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                        this.remarks.dialog = true
                    }
                    this.SnackBar(true, "success", feed, 0)
                    this.confirm.dialog = false
                    this.confirm.text 	= "Ok"
                    this.selected 		= []
                    this.List()
                } else {
                    this.SnackBar(
                        true,
                        "error",
                        this.$functions.ErrorCode(),
                        0
                    )
                    this.remarks.dialog = true
                    this.remarks.feedback = feedback[0].feedback
                }
            })
            .catch((e) => {
                this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                )
                this.remarks.dialog = true
                this.remarks.feedback = e
            })
		},
	  	Loading () {
	  		this.confirm.text = 'Loading...'
	  		if (this.url_type === 'add' || this.url_type === 'edit') {
	  			this.Write()
	  		} else if (this.url_type === 'delete') {
	  			this.Delete(this.country_id)
	  		} else if (this.url_type === 'multidelete') {
	  			this.MultiProcess('delete')
	  		}
	  	},
		Write() {
	  		let formdata = {
				url_type: this.url_type,
				country_id: this.form.country_id,
                country_desc: this.form.country_desc,
                country_domain: this.form.country_domain,
                country_phonecode: this.form.country_phonecode,
                country_defaultf: this.$functions.SwitchFlag(this.form.country_defaultf),
				country_actived: this.$functions.ActivedFlag(this.form.country_actived),
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteCountry"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"success",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "add") {
							this.form.country_cuser = feedback[0].feedback_users_id
							this.form.country_cdate = feedback[0].feedback_users_date
						}
						this.form.country_luser = feedback[0].feedback_users_id
						this.form.country_ldate = feedback[0].feedback_users_date
						this.url_type = "edit"
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
	  	SnackBar (dialog, color, text, timeout) {
	      this.snackbar = {
	      	dialog: dialog,
	      	color: color,
	      	text: text,
	      	timeout: timeout
	      }
	      if (color === 'error') {
	      	this.confirm.dialog 	 = false
	      	this.confirm.text 	 	 = 'Ok'
	      }
	    },
		ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	}
}
</script>