<template>
    <div id="dashboard">
        <app-drawer_menu drawer_menu_title="Dashboard Management"></app-drawer_menu>
        <v-container>

        <v-layout
        wrap>
        <v-container fluid>
            <v-row
                no-gutters
            >
                <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    >
                    <template>
                    <v-card
                    max-width="344"
                    outlined
                    loading
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                        <div class="text-overline mb-4">
                            GROSS CONTRIBUTION
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                            IDR 138.104.241.962
                        </v-list-item-title>
                        <v-list-item-subtitle>Target: IDR 152.934.741.562</v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                    <v-card-actions>
                        <v-btn
                        outlined
                        rounded
                        text
                        >
                        <span class="fourth--text">GAP -9.70%</span>
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                    </template>
                </v-col>
                <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    >
                    <template>
                    <v-card
                    max-width="344"
                    outlined
                    loading
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                        <div class="text-overline mb-4">
                            TABARRU & UJROH TAKAFUL
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                            IDR 78.367.693.533
                        </v-list-item-title>
                        <v-list-item-subtitle>Ujroh: IDR 59.736.548.428</v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                    <v-card-actions>
                        <v-btn
                        outlined
                        rounded
                        text
                        >
                        DETAIL
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                    </template>
                </v-col>
                <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    >
                    <template>
                    <v-card
                    max-width="344"
                    outlined
                    loading
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                        <div class="text-overline mb-4">
                            CLAIM SETTLED & RESERVED
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                            IDR 45.530.971.947
                        </v-list-item-title>
                        <v-list-item-subtitle>Reserved: IDR 19.873.132.761</v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                    <v-card-actions>
                        <v-btn
                        outlined
                        rounded
                        text
                        >
                        DETAIL
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                    </template>
                </v-col>
                <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    >
                    <template>
                    <v-card
                    max-width="344"
                    outlined
                    loading
                    >
                    <v-list-item three-line>
                        <v-list-item-content>
                        <div class="text-overline mb-4">
                            SW TABARRU & PROFIT AND LOSS
                        </div>
                        <v-list-item-title class="text-h5 mb-1 fourth--text">
                            IDR -10.538.667.723
                        </v-list-item-title>
                        <v-list-item-subtitle class="secondary--text">Profit: IDR 4.150.467.604</v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                    <v-card-actions>
                        <v-btn
                        outlined
                        rounded
                        text
                        >
                        DETAIL
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                    </template>
                </v-col>
            </v-row>
            </v-container>
        </v-layout>
        <v-layout
            text-center
            wrap
        >
            <template>
            <v-container fluid>
                <v-sparkline
                :value="value"
                :gradient="gradient"
                :smooth="radius || false"
                :padding="padding"
                :line-width="lineWidth"
                :stroke-linecap="lineCap"
                :gradient-direction="gradientDirection"
                :fill="fill"
                :type="type"
                :auto-line-width="autoLineWidth"
                auto-draw
                :show-labels="showLabels"
                :label-size="labelSize"
                ></v-sparkline>

                <v-divider></v-divider>

                <v-row>
                <v-col cols="12">
                    <v-row class="fill-height" align="center">
                    <v-subheader class="pl-0">Type</v-subheader>
                    <v-btn-toggle v-model="type" mandatory>
                        <v-btn small text value="bar">bar</v-btn>
                        <v-btn small text value="trend">trend</v-btn>
                    </v-btn-toggle>
                    </v-row>
                </v-col>

                <v-col cols="12" md="6">
                    <v-row class="fill-height" align="center">
                    <v-subheader class="pl-0">Gradient</v-subheader>
                    <v-item-group v-model="gradient" mandatory>
                        <v-row>
                        <v-item
                            v-for="(gradient, i) in gradients"
                            :key="i"
                            v-slot:default="{ active, toggle }"
                            :value="gradient"
                        >
                            <v-card
                            :style="{
                                background: gradient.length > 1
                                ? `linear-gradient(0deg, ${gradient})`
                                : gradient[0],
                                border: '2px solid',
                                borderColor: active ? '#222' : 'white'
                            }"
                            width="30"
                            height="30"
                            class="mr-2"
                            @click.native="toggle"
                            ></v-card>
                        </v-item>
                        </v-row>
                    </v-item-group>
                    </v-row>
                </v-col>

                <v-col cols="12" md="6">
                    <v-row class="fill-height" align="center">
                    <v-subheader class="pl-0">Gradient direction</v-subheader>
                    <v-btn-toggle v-model="gradientDirection" mandatory>
                        <v-btn small text value="top">top</v-btn>
                        <v-btn small text value="right">right</v-btn>
                        <v-btn small text value="left">left</v-btn>
                        <v-btn small text value="bottom">bottom</v-btn>
                    </v-btn-toggle>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-slider
                    v-model="lineWidth"
                    label="Line width"
                    min="0.1"
                    max="10"
                    step="0.1"
                    thumb-label
                    :disabled="autoLineWidth"
                    ></v-slider>
                </v-col>

                <v-col cols="12">
                    <v-slider
                    v-model="radius"
                    label="Radius"
                    min="0"
                    max="16"
                    thumb-label
                    ></v-slider>
                </v-col>

                <v-col cols="12">
                    <v-slider
                    v-model="padding"
                    label="Padding"
                    min="0"
                    max="16"
                    thumb-label
                    ></v-slider>
                </v-col>

                <v-col cols="6">
                    <v-row class="fill-height" align="center">
                    <v-subheader class="pl-0">Linecap</v-subheader>
                    <v-btn-toggle v-model="lineCap" mandatory :disabled="type !== 'trend'">
                        <v-btn small text value="butt">butt</v-btn>
                        <v-btn small text value="round">round</v-btn>
                        <v-btn small text value="square">square</v-btn>
                    </v-btn-toggle>
                    </v-row>
                </v-col>

                <v-col cols="6">
                    <v-row justify="space-around">
                    <v-switch v-model="showLabels" label="Show labels"></v-switch>
                    <v-switch v-model="fill" label="Fill" :disabled="type !== 'trend'"></v-switch>
                    <v-switch v-model="autoLineWidth" label="Auto-line-width" :disabled="type !== 'bar'"></v-switch>
                    </v-row>
                </v-col>

                <v-col v-if="showLabels" cols="12">
                    <v-slider
                    v-model="labelSize"
                    label="Label size"
                    min="1"
                    max="20"
                    thumb-label
                    ></v-slider>
                </v-col>
                </v-row>
            </v-container>
            </template>
        </v-layout>
        </v-container>
    </div>
    </template>
    <script>
    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['fourth', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#001F48', '#008493', '#FF6600'],
    ]

    export default {
        data: () => ({
        showLabels: false,
        lineWidth: 2,
        labelSize: 7,
        radius: 10,
        padding: 8,
        lineCap: 'round',
        gradient: gradients[5],
        value: [0, 2, 5, 9, 5, 10, 3, 5, -4, -10, 1, 8, 2, 9, 0],
        gradientDirection: 'top',
        gradients,
        fill: false,
        type: 'trend',
        autoLineWidth: false,
        }),
    }
    </script>