<template>
  <v-dialog
    v-model="dialogprint"
    persistent
    transition="dialog-bottom-transition"
    width="690"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark @click.stop="dialogprint = false">
          <v-icon>cancel</v-icon>
        </v-btn>
        <v-toolbar-title>Print - {{title}}</v-toolbar-title>
      </v-toolbar>
      <template v-if="modul === 'quotation'">
        <v-form enctype="multipart/form-data" ref="print_quotation" lazy-validation onSubmit="return false">
              <v-row class="mx-2">
                <v-col cols="12" sm="12" md="3">
                    <v-radio-group
                        v-model="print.language"
                    >
                    <template>
                        <div>Choose Language:</div>
                    </template>
                    <v-radio
                    label="Indonesian"
                    value="IND"
                    >
                    </v-radio>
                    <v-radio
                    label="English"
                    value="ENG"
                    >
                    </v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-radio-group
                        v-model="print.detail"
                    >
                    <template>
                        <div>Choose Detail:</div>
                    </template>
                    <v-radio
                    label="No"
                    value="N"
                    >
                    </v-radio>
                    <v-radio
                    label="Yes"
                    value="Y"
                    >
                    </v-radio>
                </v-radio-group>
                </v-col>

            </v-row>
          </v-form>
      </template>
      <v-card-actions>
        <v-btn title="Print" @click="Open" outlined> PRINT <v-icon color="primary">print</v-icon> </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    modul: String,
    id: String,
    title: String,
    doc: String,
  },
  data: () => ({
    url_type: "add",
    items: [],
    print: {
        language: 'IND',
        detail: 'N',
    }
  }),
  computed: {
    dialogprint: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
  methods: {
    Open () {
        let document = 'doc_'+this.doc+'.php'
        let url = this.$functions.AppDoc()+'/modul/mod_documents/'+document+'?document='+this.doc+'&login='+this.$functions.UsersID()+'&modul='+this.modul+'&id='+this.id+'&language='+this.print.language+'&detail='+this.print.detail
        this.$functions.OpenURL(url)
    }
  }
}
</script>