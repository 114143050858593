<template>
    <div id="app-profile-capital">
    <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.page"
        ></v-progress-linear>
    <div v-if="access.read === 0" class="text-center font-weight-black">
      {{$vars.V('txt_no_access')}}
     </div>
     <div v-else>
    <v-card-title 
        class="ml-n4"
    >
        <template>
            <v-progress-linear
                indeterminate
                color="fourth"
                rounded
                v-if="loading.detail"
              ></v-progress-linear>
            <v-row class="mx-2">
            <v-col cols="12" sm="3" md="3">
                <v-btn @click="Add" class="mx-2" title="add" small>
                    Add
                </v-btn>
                <v-btn 
                  @click="List(id)"
                  title="Refresh"
                  small
                  ><v-icon>{{$vars.V('icon_refresh')}}</v-icon>
                </v-btn>
                <v-btn @click="form.rangeyear=!form.rangeyear" class="mx-2" title="range year" small color="secondary">Range Year: {{form.profilecapital_rangeyear}}</v-btn>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="mt-n4">
                    <v-text-field 
                    v-model           ="form.profilecapital_rangeyear"
                    required 
                    label       	  ="Range Year"
                    placeholder 	  ="Range Year"
                    background-color  ="numeric"
                    @change           ="OpenRangeYear"
                    @keyup.enter      ="OpenRangeYear"
                    reverse
                    >
                    </v-text-field>
            </v-col>
            </v-row>
            <v-row class="mx-2" v-if="items.length > 0">
            <v-col cols="12" sm="12" md="12">
            <v-simple-table dense class="mx-2 mt-3">
                <template v-slot:default>
                <thead>
                    <tr class="fourth">
                    <th class="text-left">
                        REPORT
                    </th>
                    <th class="text-right" v-for="header in items[0].year" :key="`${header.profilecapital_year}`">
                        {{header.profilecapital_year}} <v-icon small @click="Edit(header.profilecapital_year)">edit</v-icon>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in items[0].data"
                    :key="item.profilecapital_category"
                    >
                    <td>{{ item.profilecapital_category }}</td>
                    <td class="text-right">{{ $functions.NewFormatNumber(item.profilecapital_current,0) }}</td>
                    <td class="text-right">{{ $functions.NewFormatNumber(item.profilecapital_next,0)}}</td>
                    <td class="text-right" v-if="form.profilecapital_rangeyear === '3' || form.profilecapital_rangeyear === '4'">{{ $functions.NewFormatNumber(item.profilecapital_next1,0)}}</td>
                    <td class="text-right" v-if="form.profilecapital_rangeyear === '4'">{{ $functions.NewFormatNumber(item.profilecapital_next2,0)}}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            </v-col>
            <v-col cols="12" sm="12" md="12">
                <v-alert
                border="left"
                color="primary"
                dark
                dense
                icon="analytics"
            >
                Ratio
            </v-alert>
                <v-simple-table dense class="mx-2 mt-3">
                    <template v-slot:default>
                    <thead>
                        <tr class="fourth">
                        <th class="text-left">
                            YEAR
                        </th>
                        <th class="text-center">
                            Ratio Liquidity
                        </th>
                        <th class="text-center">
                            Ratio Profitability
                        </th>
                        <th class="text-center">
                            Ratio Solvability
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(item, index) in itemsdetail"
                        :key="item.profilecapital_year"
                        >
                        <th class="text-right">{{ item.profilecapital_year }}</th>
                        <td class="text-right"><span v-if="itemsdetail.length ===  2 && index === 0"><v-icon>{{$functions.CompareIcon(item.profilecapital_ratioliquidity,itemsdetail[1].profilecapital_ratioliquidity)}}</v-icon></span>{{ $functions.NewFormatNumber(item.profilecapital_ratioliquidity,0) }}</td>
                        <td class="text-right"><span v-if="itemsdetail.length ===  2 && index === 0"><v-icon>{{$functions.CompareIcon(item.profilecapital_ratioprofitability,itemsdetail[1].profilecapital_ratioprofitability)}}</v-icon></span>{{ $functions.NewFormatNumber(item.profilecapital_ratioprofitability,0)}}</td>
                        <td class="text-right"><span v-if="itemsdetail.length ===  2 && index === 0"><v-icon>{{$functions.CompareIcon(item.profilecapital_ratiosolvability,itemsdetail[1].profilecapital_ratiosolvability)}}</v-icon></span>{{ $functions.NewFormatNumber(item.profilecapital_ratiosolvability,0)}}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-col>
            </v-row>
        </template>
    </v-card-title>
    </div>
    <!-- Start Form -->
    <v-dialog
      v-model="dialog.detail"
      max-width="800"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog.detail = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Finance Report</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Add" title="Add New"><v-icon color="white">add</v-icon></v-btn>
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save"><v-icon color="white">save</v-icon></v-btn>
        </v-toolbar>
      <v-card-text>
      <v-form enctype="multipart/form-data" ref="form_capital" lazy-validation>
        <v-row class="mx-auto">
              <v-col cols="10" sm="10" md="10">
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-text-field 
                  v-model           ="form.profilecapital_year"
                  label             ="Year"
                  background-color  ="numeric"
                  reverse
                  :rules 	  	    ="form.profilecapital_year_rules"
                  :maxlength        ="maxinput.year"
                  id                ="profilecapital_year"
                  :readonly         ="form.profilecapital_year_readonly"
                  >
                  </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-auto">
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.profilecapital_currentasset"
                  v-bind:options    ="formatamount"
                  label             ="Current Asset"
                  placeholder       ="Current Asset"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                  prepend-icon      ="list"
                  prepend-icon-cb   ="OpenCurrentAsset"
                  :rules 	  	      ="form.profilecapital_currentasset_rules"
                  @keyup.enter 	    ="Confirm(url_type,'')"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.profilecapital_fixedasset"
                  v-bind:options    ="formatamount"
                  label             ="Fixed Asset"
                  placeholder       ="Fixed Asset"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                  @keyup.enter 	    ="Confirm(url_type,'')"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.profilecapital_income"
                  v-bind:options    ="formatamount"
                  label             ="Income"
                  placeholder       ="Income"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                  @keyup.enter 	    ="Confirm(url_type,'')"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.profilecapital_pl"
                  v-bind:options    ="formatamount"
                  label             ="Profit and Loss"
                  placeholder       ="Profit and Loss"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                  @keyup.enter 	    ="Confirm(url_type,'')"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.profilecapital_liquidity"
                  v-bind:options    ="formatamount"
                  label             ="Liquidity"
                  placeholder       ="Liquidity"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                  @keyup.enter 	    ="Confirm(url_type,'')"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.profilecapital_profitability"
                  v-bind:options    ="formatamount"
                  label             ="Profitability"
                  placeholder       ="Profitability"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                  @keyup.enter 	    ="Confirm(url_type,'')"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.profilecapital_equity"
                  v-bind:options    ="formatamount"
                  label             ="Equity"
                  placeholder       ="Equity"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                  @keyup.enter 	    ="Confirm(url_type,'')"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.profilecapital_noncurrliability"
                  v-bind:options    ="formatamount"
                  label             ="Non Current Liability"
                  placeholder       ="Non Current Liability"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                  @keyup.enter 	    ="Confirm(url_type,'')"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.profilecapital_currliability"
                  v-bind:options    ="formatamount"
                  label             ="Current Liability"
                  placeholder       ="Current Liability"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                  @keyup.enter 	    ="Confirm(url_type,'')"
                />
              </v-col>
        </v-row>
      </v-form>
      </v-card-text>
      <v-card-actions>
        
      </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      color		  ="fourth"
      rounded	  ="pill"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
      <v-card :color="Variable('confirm',4)">
        <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            id="dialog"
            :color="Variable('confirm',1)"
            :disabled   ="confirm.text === 'Ok' ? false:true"
            @click      ="Loading"
          >
            {{confirm.text}}
          </v-btn>
          <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Capital -->
    </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    id: String,
  },
  data: () => ({
    url_type: 'add',
    access: {
      read    : 0,
      add     : 0,
      edit    : 0,
      delete  : 0,
    },
    remarks: {
        feedback: "",
        dialog: false,
    },
    snackbar: {
        dialog: false,
        color: "success",
        text: "",
        timeout: 3000,
    },
    confirm: {
        dialog: false,
        title: "",
        text: "Ok",
    },
    loading: {
        page      : true,
        detail    : true,
    },
    dialog: {
        detail: false
    },
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2
    },
    properties: {
      reverse: true
    },
    selected: [],
    items: [],
    itemsdetail: [],
    btn_search: false,
    search: '',
    headers: [
      { text: 'ID', value: 'profilecapital_id' },
      { text: 'Year', value: 'profilecapital_year' },
      { text: 'Obligee', value: 'profilecapital_obligee' },
      { text: 'Project Name', value: 'profilecapital_projectname' },
      { text: 'Location', value: 'profilecapital_location' },
      { text: 'CCY', value: 'profilecapital_ccy' },
      { text: 'Amount', value: 'profilecapital_amount',align: 'right' },
      { text: 'Actions', value: 'action', sortable: false }
    ],
    form: {
      profilecapital_rangeyear: '2',
      profilecapital_id: '',
      profilecapital_profileid: '',
      profilecapital_year_readonly: false,
      profilecapital_year: '',
      profilecapital_year_rules: [v => !!v || 'Please fill Year'],
      profilecapital_currentasset: 0,
      profilecapital_currentasset_rules: [v => !!v || 'Please fill Current Asset'],
      profilecapital_fixedasset: 0,
      profilecapital_fixedasset_rules: [v => !!v || 'Please fill Fixed Asset'],
      profilecapital_income: 0,
      profilecapital_income_rules: [v => !!v || 'Please fill Income'],
      profilecapital_pl: 0,
      profilecapital_pl_rules: [v => !!v || 'Please fill Profit and Loss'],
      profilecapital_liquidity: 0,
      profilecapital_liquidity_rules: [v => !!v || 'Please fill Liquidity'],
      profilecapital_profitability: 0,
      profilecapital_profitability_rules: [v => !!v || 'Please fill Profitability'],
      profilecapital_noncurrliability: 0,
      profilecapital_noncurrliability_rules: [v => !!v || 'Please fill Non Current Liability'],
      profilecapital_currliability: 0,
      profilecapital_currliability_rules: [v => !!v || 'Please fill Current Liability'],
      profilecapital_equity: 0,
      profilecapital_ratioliquidity: 0,
      profilecapital_ratioprofitability: 0,
      profilecapital_ratiosolvability: 0,
      profilecapital_actived: true,
    },
    maxinput: {
      id    :20,
      desc  :150,
      year  :4,
    },
   }),
   watch: {
    id: function (newdata) {
      if (newdata !== "") {
        this.id = newdata
        this.List(newdata)
      } else {
        this.id = ""
        this.List(newdata)
      }
    },
   },
   created() {
    this.List(this.id)
   },
   methods: {
    Access() {
        let modul = 'profile'
        let formdata = {
            menu_id     : modul,
            users_id    : this.$functions.UsersID(),
            order_by    : 'menu_id',
            order_type  : 'ASC',
            limit       : this.limit,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios
        .post(this.$functions.UrlPOST('apiListMenuUser'), param, {
            headers: {
            'Content-Type': 'text/plain charset=ISO-8859-1',
            },
        })
        .then((response) => {
            let priv = response.data
            if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read
            this.access.add = priv[0].usersmenu_add
            this.access.edit = priv[0].usersmenu_edit
            this.access.delete = priv[0].usersmenu_delete
            if (priv[0].usersmenu_read === 0) {
                this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
            }
            } else {
            this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
            }
            this.loading.page = false
        })
        .catch((e) => {
            this.SnackBar(true, 'error', e, 0)
            this.loading.page = false
        })
    },
    SnackBar(dialog, color, text, timeout) {
        this.snackbar = {
            dialog: dialog,
            color: color,
            text: text,
            timeout: timeout,
        }
        if (color === "error") {
            this.confirm.dialog 	= false
            this.confirm.text 		= "Ok"
        }
    },
    Variable (flag, position) {
        return this.$functions.Variable(flag,position)
    },
    SelectAllPage () {
        this.selected = this.selected === this.items ? [] : this.items
    },
    Check (value) {
        this.selected = value
    },
    Loading () {
        this.confirm.text = 'Loading...'
        if (this.url_type === 'add' || this.url_type === 'edit') {
            this.Write()
        } else if (this.url_type === 'delete') {
            this.Delete(this.form.profilecapital_id)
        } else if (this.url_type === 'multidelete') {
            this.MultiProcess('delete')
        }
    },
    Write () {
      let formdata = {
        url_type: this.url_type,
        profilecapital_profileid: this.form.profilecapital_profileid,
        profilecapital_id: this.form.profilecapital_id,
        profilecapital_year: String(this.form.profilecapital_year),
        profilecapital_currentasset: String(this.form.profilecapital_currentasset),
        profilecapital_fixedasset: String(this.form.profilecapital_fixedasset),
        profilecapital_income: String(this.form.profilecapital_income),
        profilecapital_pl: String(this.form.profilecapital_pl),
        profilecapital_liquidity: String(this.form.profilecapital_liquidity),
        profilecapital_profitability: String(this.form.profilecapital_profitability),
        profilecapital_noncurrliability: String(this.form.profilecapital_noncurrliability),
        profilecapital_currliability: String(this.form.profilecapital_currliability),
        profilecapital_equity: String(this.form.profilecapital_equity),
      }
      let param 	   = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteProfileCapital'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.dialog.detail = false
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            if (this.url_type === 'add') {
              this.form.profilecapital_cuser  = feedback[0].feedback_users_id
              this.form.profilecapital_cdate  = feedback[0].feedback_users_date
            }
            this.List(this.id)
            this.form.profilecapital_luser  = feedback[0].feedback_users_id
            this.form.profilecapital_ldate  = feedback[0].feedback_users_date
            this.url_type 		      = 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = feedback
        }
      })
      .catch(e => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
    },
    Delete (id) {
      let formdata = {
        url_type: 'delete',
        profilecapital_id: id
      }
      let param 	   = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteProfileCapital'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog  	= false
            this.confirm.text 		= 'Ok'
            this.List(this.id)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
            this.confirm.dialog  	= false
            this.confirm.text 		= 'Ok'
          }
        }
      })
      .catch(e => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.confirm.dialog  	= false
        this.confirm.text 		= 'Ok'
      })
    },
    Add() { 
      this.dialog.detail = true
      this.url_type = 'add'
      this.form.profilecapital_year_readonly = false
      this.form.profilecapital_year = ''
      this.form.profilecapital_currentasset = 0
      this.form.profilecapital_fixedasset = 0
      this.form.profilecapital_income = 0
      this.form.profilecapital_pl = 0
      this.form.profilecapital_liquidity = 0
      this.form.profilecapital_profitability = 0
      this.form.profilecapital_noncurrliability = 0
      this.form.profilecapital_currliability = 0
      this.form.profilecapital_equity = 0
      setTimeout(function () {
        document.getElementById("profilecapital_year").focus();
      }, 500)
    },
    Edit (year) {
      if (year !== '') {
        this.Add()
        this.loading.detail = true
        let formdata = {
          profilecapital_year: year,
          profilecapital_profileid: this.id
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListProfileCapital'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
                this.dialog.detail = true
                this.url_type = 'edit'
                this.form.profilecapital_profileid = feedback[0].profilecapital_profileid
                this.form.profilecapital_year = feedback[0].profilecapital_year
                this.form.profilecapital_currentasset = feedback[0].profilecapital_currentasset
                this.form.profilecapital_fixedasset = feedback[0].profilecapital_fixedasset
                this.form.profilecapital_income = feedback[0].profilecapital_income
                this.form.profilecapital_pl = feedback[0].profilecapital_pl
                this.form.profilecapital_liquidity = feedback[0].profilecapital_liquidity
                this.form.profilecapital_profitability = feedback[0].profilecapital_profitability
                this.form.profilecapital_noncurrliability = feedback[0].profilecapital_noncurrliability
                this.form.profilecapital_currliability = feedback[0].profilecapital_currliability
                this.form.profilecapital_equity = feedback[0].profilecapital_equity
                this.form.profilecapital_actived = this.$functions.TrueOrFalse(feedback[0].profilecapital_actived)
            } else {
                this.SnackBar(true, 'error', feedback[0].feedback, '3000')
            }
          } else {
            this.SnackBar(true, 'error', this.$functions.NoData(), '3000')
          }
          this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      }
    },
    Confirm (flag, id) {
      if (flag === 'add' || flag === 'edit') {
        if (this.$refs.form_capital.validate()) {
          this.confirm.dialog   	= true
          this.confirm.title    	= 'Save'
          setTimeout(function(){
          document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'delete') {
        this.url_type	  = 'delete'
        this.confirm.dialog       = true
        this.confirm.title        = 'Delete `'+ id + '`'
        this.form.profilecapital_id 	        = id
        setTimeout(function(){
        document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multidelete') {
        this.url_type 		        = 'multidelete'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
        setTimeout(function(){
        document.getElementById('dialog').focus()
        }, 500)
      }
    },
    List (id) {
      this.Access()
      this.items = []
      if (id !== '') {
        this.loading.detail = true
        this.form.profilecapital_profileid = id
        let formdata = {
          profilecapital_profileid: id,
          order_by: 'profilecapital_profileid',
          order_type: 'ASC',
          limit: this.limit,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListProfileCapitalGroup'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
              this.items = feedback
              this.ListDetail(id)
            } else if (feedback[0].feedback === '') {
              this.items = []
            } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
            }
          } else {
            this.items = feedback
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      }
    },
    ListDetail (id) {
      this.itemsdetail = []
      if (id !== '') {
        this.loading.detail = true
        let formdata = {
          profilecapital_profileid: id,
          order_by: 'profilecapital_profileid',
          order_type: 'ASC',
          limit: this.limit,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListProfileCapital'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback !== 'Y') {
              this.SnackBar(true, 'error', feedback[0].feedback, '3000')
            } else {
              this.itemsdetail = feedback
            }
          } else {
            this.itemsdetail = feedback
            this.SnackBar(true, 'error', this.$functions.NoData(), '3000')
          }
          this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      }
    },
    OpenRangeYear (){
      this.List(this.id)
    },
    OpenCurrentAsset () {
      alert('vai')
    }
    }
}
</script>