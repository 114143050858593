<template>
    <div>
    <v-row>
		<v-col cols="12" sm="12" md="12">
			<v-card>
				<v-card-title class="primary headline white--text">Module Not Found</v-card-title>
				<v-card-text>
					<p></p>
					<v-btn color="orange" class="white--text" @click="BackToDashboard">Back to First Page</v-btn>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
    </div>
</template>
<script>
export default {
	methods: {
		BackToDashboard () {
			this.$router.push({name: 'dashboard'})
		}
	}
}
</script>