<template>
  <div>
  	<app-drawer_menu drawer_menu_title="AJK Test"></app-drawer_menu>
	  	<v-tabs
	      background-color="primary"
        dark
	      color="fourth"
	      v-if = "this.access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_campaign" lazy-validation>
            <v-row class="mx-2">
                <v-col cols="12" sm="12" md="12">
                    <div id="app">
                        <v-app id="inspire">
                        	<v-stepper v-model="e1">
                                <v-stepper-header>
                                <v-stepper-step :complete="e1 > 1" editable step="1">Q</v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step :complete="e1 > 2" editable step="2">Q</v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step :complete="e1 > 3" editable step="3">Q</v-stepper-step>

								<v-divider></v-divider>

								<v-stepper-step :complete="e1 > 4" editable step="4">Q</v-stepper-step>

								<v-divider></v-divider>

								<v-stepper-step :complete="e1 > 5" editable step="5">Q</v-stepper-step>

								<v-divider></v-divider>

								<v-stepper-step :complete="e1 > 6" editable step="6">Q</v-stepper-step>
								
								<v-divider></v-divider>

								<v-stepper-step :complete="e1 > 7" editable step="7">Q</v-stepper-step>
								
								<v-divider></v-divider>

								<v-stepper-step :complete="e1 > 8" editable step="8">Q</v-stepper-step>

								<v-divider></v-divider>

								<v-stepper-step :complete="e1 > 9" editable step="9">Q</v-stepper-step>

								<v-divider></v-divider>

								<v-stepper-step :complete="e1 > 10" editable step="10">Q</v-stepper-step>

								<v-divider></v-divider>

								<v-stepper-step :complete="e1 > 11" editable step="11">Q</v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Apakah  Anda  pernah  mengalami  gejala-gejala, diperiksa, menderita, didiagnosa, mendapat pengobatan, disarankan atau  menjalani  rawat  inap,  menjalani operasi,  dianjurkan untuk  mendapat nasehat medis,  telah mendapat nasehat medis, atau dirujuk ke dokter spesialis, untuk penyakit di bawah ini :</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2">
                                        <v-col cols="6" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="6" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label>Jika "Ya", Mohon Calon Tertanggung memberi tanda "√" untuk setiap kelainan yang dimiliki dan mengisi KUESIONER sesuai dengan kelainan tersebut.</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2">
                                        <v-col cols="1" sm="1" md="1">
											<label>TU</label>
                                        </v-col>
										<v-col cols="5" sm="5" md="5">
											<label>TT</label>
                                        </v-col>
										<v-col cols="1" sm="1" md="1">
											<label>TU</label>
                                        </v-col>
										<v-col cols="5" sm="5" md="5">
											<label>TT</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2">
										<v-col cols="1" sm="1" md="1">
                                            <label class="container-checkbox-ajktest">
												<input type="checkbox" v-model="q1.tul1" id="L12">
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
												<input type="checkbox" v-model="q1.ttl1" id="L1">
												<span class="checkmark-ajktest"></span>
												<label for="L1">Jantung bawaan</label>
											</label>
                                        </v-col>
										<v-col cols="1" sm="1" md="1">
                                            <label class="container-checkbox-ajktest">
												<input type="checkbox" v-model="q1.tur1" id="L1">
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
												<input type="checkbox" v-model="q1.ttr1" id="R1">
												<span class="checkmark-ajktest"></span>
												<label for="R1">Malaria</label>
											</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2">
										<v-col cols="1" xs="1" sm="1" md="1">
                                            <label class="container-checkbox-ajktest">
												<input type="checkbox" v-model="q1.tul2">
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="5" xs="5" sm="5" md="5">
                                            <label class="container-checkbox-ajktest">
												<input type="checkbox" v-model="q1.ttl2" id="L2">
												<span class="checkmark-ajktest"></span>
												<label for="L2">Kelenjar / Thyroid</label>
											</label>
                                        </v-col>
										<v-col cols="1" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tur2" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="5" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttr2" id="R2">
												<span class="checkmark-ajktest"></span>
												<label for="R2">Nyeri dada, kelainan jantung dan pembuluh darah</label>
											</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tul3" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttl3" id="L3">
												<span class="checkmark-ajktest"></span>
												<label for="L3">Tumor/Kista/Benjolan/pembengkakan/Kanker</label>
											</label>
                                        </v-col>
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tur3" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttr3" id="R3">
												<span class="checkmark-ajktest"></span>
												<label for="R3">Epilepsi / Ayan / Kejang / pingsan </label>
											</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tul4" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttl4" id="L4">
												<span class="checkmark-ajktest"></span>
												<label for="L4">Maag, diare dan gangguan pencernaan lainnya</label>
											</label>
                                        </v-col>
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tur4" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttr4" id="R4">
												<span class="checkmark-ajktest"></span>
												<label for="R4">Kencing manis</label>
											</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tul5" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttl5" id="L5">
												<span class="checkmark-ajktest"></span>
												<label for="L5">Sakit Kepala/Migrain/Pusing/Vertigo/Stroke</label>
											</label>
                                        </v-col>
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tur5" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttr5" id="R6">
												<span class="checkmark-ajktest"></span>
												<label for="R6">Polio</label>
											</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tul6" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttl6" id="L7">
												<span class="checkmark-ajktest"></span>
												<label for="L7">Gangguan Persendian/Rematik/Kelainan pada Otot/Sendi/Tulang</label>
											</label>
                                        </v-col>
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tur6" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttr6" id="R7">
												<span class="checkmark-ajktest"></span>
												<label for="R7">Tekanan darah tinggi</label>
											</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tul7" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttl7" id="L8">
												<span class="checkmark-ajktest"></span>
												<label for="L8">Gangguan Pernafasan/batuk berkepanjangan/sesak nafas/Bronkitis/Asthma/Batuk Darah</label>
											</label>
                                        </v-col>
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tur7" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttr7" id="R8">
												<span class="checkmark-ajktest"></span>
												<label for="R8">Kelainan Psikologis</label>
											</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tul8" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttl8" id="L9">
												<span class="checkmark-ajktest"></span>
												<label for="L9">Anemia, hemofilia atau kelainan darah lainnya</label>
											</label>
                                        </v-col>
										<v-col cols="12" xs="1" sm="1" md="1">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.tur8" >
												<span class="checkmark-ajktest"></span>
											</label>
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttr8" id="R9">
												<span class="checkmark-ajktest"></span>
												<label for="R9">Sakit Ginjal/ batu Ginjal/ saluran kemih/ prostat</label>
											</label>
                                        </v-col>
										<v-col cols="12" xs="7" sm="7" md="7">
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
											<label class="container-checkbox-ajktest">
                                            	<input type="checkbox" v-model="q1.ttr8" id="R9">
												<span class="checkmark-ajktest"></span>
												<label for="R9">Penyakiy Lain? sebutkan :</label>
											</label>
                                        </v-col>
										<v-col cols="12" xs="7" sm="7" md="7">
                                        </v-col>
										<v-col cols="12" xs="5" sm="5" md="5">
                                            <v-text-field 
											v-model 	  	= "q1.penyakitlain"
											required
											id 			    = "q1.penyakitlain"
											@keyup.enter 	= "Confirm('write','')"
											>
											</v-text-field>
                                        </v-col>
										<v-col cols="12" sm="3" md="3">
                                            <!-- <label> Kuesioner diisi oleh Dokter</label>  -->
                                        </v-col>
										<v-col cols="12" sm="9" md="9">
                                            <!-- <label> Kuesioner diisi oleh Calon Tertanggung</label>  -->
                                        </v-col>
                                    </v-row>                                  
                                    <v-btn color="primary" @click="e1 = 2">
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
                                <v-stepper-content step="2">
                                    <v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Apakah  Anda  pernah  mengalami  gejala-gejala, diperiksa, menderita, didiagnosa, mendapat pengobatan, disarankan atau  menjalani  rawat  inap,  menjalani operasi,  dianjurkan untuk  mendapat nasehat medis,  telah mendapat nasehat medis, atau dirujuk ke dokter spesialis, untuk penyakit di bawah ini :</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2">
                                        <v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q2.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q2.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q2.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q2.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label>Jika "Ya", Mohon Calon Tertanggung memberi tanda "√" untuk setiap kelainan yang dimiliki dan mengisi KUESIONER sesuai dengan kelainan tersebut.</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2">
                                        <v-col cols="12" sm="1" md="1">
											<label>TU</label>
                                        </v-col>
										<v-col cols="12" sm="3" md="3">
											<label>TT</label>
                                        </v-col>
										<v-col cols="12" sm="4" md="4">
											<label>TU</label>
                                        </v-col>
										<v-col cols="12" sm="4" md="4">
											<label>TT</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="4" md="4">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label><b>Penyakit</b></label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label><b>Tgl. Sakit</b></label>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <label><b>Nama, Alamat Dokter yg Merawat</b></label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label><b>Penyakit</b></label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label><b>Tgl. Sakit</b></label>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <label><b>Nama, Alamat Dokter yg Merawat</b></label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q2.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q2.ttl1">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>a. Hepatitis A, Hepatitis B, Hepatitis C, Hepatitis D, Hepatitis E, Hati (selain hepatitis) dan Kandung Empedu ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuPenyakit1"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuTglSakit1"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuDokter1"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttPenyakit1"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttTglSakit1"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuDokter1"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q2.tul2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q2.ttl2">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>b. Usus, Pankreas, Wasir dan Organ Pencernaan lain ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuPenyakit2"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuTglSakit2"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuDokter2"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttPenyakit1"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttTglSakit2"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttDokter2"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q2.tul3">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q2.ttl3">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>c. Ginjal, Batu Ginjal, Saluran Kemih  dan Prostat ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuPenyakit3"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuTglSakit3"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuDokter3"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttPenyakit3"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttTglSakit3"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttDokter3"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q2.tul4">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q2.ttl4">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>d. Nyeri Dada, Jantung, Jantung Bawaan, Demam Rheuma,  Pembuluh Darah dan Stroke ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuPenyakit4"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuTglSakit4"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuDokter4"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttPenyakit4"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttTglSakit4"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttDokter4"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q2.tul5">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q2.ttl5">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>e. Payudara, Kandungan dan Indung Telur ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuPenyakit5"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuTglSakit5"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuDokter5"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttPenyakit5"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttTglSakit5"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttDokter5"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q2.tul6">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q2.ttl6">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>f. Alergi, Penyakit Kulit, penyakit kelamin ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuPenyakit6"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuTglSakit6"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuDokter6"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttPenyakit6"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttTglSakit6"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttDokter6"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q2.tul7">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q2.ttl7" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>g. Mata, Telinga, Hidung, Tenggorokan (THT), Kelenjar Gondok/Tiroid, Sinus dan gangguan bicara ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuPenyakit7"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuTglSakit7"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
												v-model="q2.tuDokter7"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttPenyakit7"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
												v-model="q2.ttTglSakit7"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>h. Sakit Kepala, Migran, Pusing, Vertigo, Otak, Syaraf, Epilepsi, Ayan, Kejang, Pingsan dan Kelumpuhan ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>i. Polio, Gangguan pada anggota tubuh, Gangguan Persendian, Rematik, Kelainan pada Otot, Sendi, Tulang ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>j. Kecelakaan dan cedera berat berkepanjangan ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>k. Hernia ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>l. Kelainan Psikologis </label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>m. Kencing Manis, Asam Urat, Kolesterol, Penyakit Endokrin / Hormon lainny ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>n. Tuberkulosis (TBC), Gangguan Pernafasan, batuk berkepanjangan, sesak nafas, Bronkitis, Asthma, Batuk darah ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>o. AIDS & kondisi yang berhubungan dengan AIDS (demam, kelelahan, diare kronis, penurunan berat badan, sariawan yang lama sembuh, pembengkakan getah bening atau luka di kulit berulang & berkepanjangan yang tidak diketahui penyebabnya) ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>p. Tumor/ Kista/ Benjolan/ pembengkakan/ Kanker ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>q. Kelainan Darah, Tekanan Darah Rendah, Tekanan Darah Tinggi ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>r. Malaria ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>s. Cacat bawaan / kehilangan fungsi pada anggota tubuh ?</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
											<label>i. mata</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
											<label for="L2"><b>Tdk</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Ya</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Jika Ya, Kanan</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Kiri </b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col><v-col cols="12" sm="1" md="1">
											<label for="L2"><b>Tdk</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Ya</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Jika Ya, Kanan</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Kiri </b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
											<label>ii. Kaki</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
											<label for="L2"><b>Tdk</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Ya</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Jika Ya, Kanan</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Kiri </b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col><v-col cols="12" sm="1" md="1">
											<label for="L2"><b>Tdk</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Ya</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Jika Ya, Kanan</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Kiri </b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
											<label>iii. Tangan</label>
										</v-col>
										<v-col cols="12" sm="1" md="1" class="ml-5">
											<label for="L2"><b>Tdk</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Ya</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Jika Ya, Kanan</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Kiri </b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col><v-col cols="12" sm="1" md="1">
											<label for="L2"><b>Tdk</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Ya</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Jika Ya, Kanan</b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>Kiri </b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input type="checkbox" v-model="q1.tul1">
                                        </v-col>
										<v-col cols="12" sm="1" md="1" class="mr-n5">
                                            <input type="checkbox" v-model="q1.ttl6" id="L9">
                                        </v-col>
										<v-col cols="12" sm="2" md="2" class="ml-n5 mr-5">
											<label>t. Penyakit lain yang belum disebutkan?</label>
										</v-col>
										<v-col cols="12" sm="4" md="4" class="ml-5">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="4" md="4">
                                            <v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									
                                    <v-btn
                                    color="primary"
                                    @click="e1 = 3"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
                                <v-stepper-content step="3">
                                   <v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Pernahkan Anda menjalani atau dianjurkan melakukan pemeriksaan : Jantung, darah, air seni, rontgen, USG, CT Scan, Biopsi, Pemeriksaan penunjangan atau diagnostik lainnya?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5">
                                        <v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5">
                                        <v-col cols="12" sm="12" md="12">
                                            <label>Jika "Ya" untuk pertanyaan nomor 3, mohon menjawab pertanyaan dibawah ini. <i>x(Apabila jawabannya melebihi kolom yang telah tersedia, mohon menuliskan jawabannya pada "Surat pernyataan untuk SPAJ" dan dilampirkan bersama SPAJ ini)</i>.</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="5" md="5">
											<label>a. Jenis pemeriksaan ?</label>
                                            <v-text-field
												single-line
												filled
												class="ml-5"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                        </v-col>
										<v-col cols="12" sm="5" md="5">
											<label>a. Jenis pemeriksaan ?</label>
                                            <v-text-field
												single-line
												filled
												class="ml-5"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="5" md="5">
											<label>b. Alasan dilakukan pemeriksaan ?</label>
                                            <v-text-field
												single-line
												filled
												class="ml-5"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                        </v-col>
										<v-col cols="12" sm="5" md="5">
											<label>b. Alasan dilakukan pemeriksaan ?</label>
                                            <v-text-field
												single-line
												filled
												class="ml-5"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="5" md="5">
											<label>c. Tanggal dilakukan pemeriksaan ?</label>
                                            <v-text-field
												single-line
												filled
												class="ml-5"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                        </v-col>
										<v-col cols="12" sm="5" md="5">
											<label>c. Tanggal dilakukan pemeriksaan ?</label>
                                            <v-text-field
												single-line
												filled
												class="ml-5"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="5" md="5">
											<label>d. Bagaimana hasilnya?</label>
                                            <v-text-field
												single-line
												filled
												class="ml-5"
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                        </v-col>
										<v-col cols="12" sm="5" md="5">
											<label>d. Bagaimana hasilnya?</label>
                                            <v-text-field
												single-line
												filled
												class="ml-5"
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2">
										<v-col cols="12" sm="1" md="1" class="ml-5">
											<label for="L2"><b>TU</b></label>
											<br>
											<input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <label for="L2"><b>TT </b></label>
											<br>
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="5" md="5">
											<br>
                                            <label for="L2"><b>Ada dan dilampirkan pada SPAJ & SKK ini </b></label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2">
										<v-col cols="12" sm="1" md="1" class="ml-5">
											<input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.ttl2"
											id="L2">
                                        </v-col>
										<v-col cols="12" sm="5" md="5">
                                            <label for="L2"><b>Tidak ada/tidak disimpan lagi </b></label>
                                        </v-col>
									</v-row>
                                    <v-btn
                                    color="primary"
                                    @click="e1 = 4"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
								<v-stepper-content step="4">
                                    <v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label>a. Apakah anda pernah atau sedang menggunakan obat-obatan terlarang, narkoba atau bahan adiktif lainnya ?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5">
                                        <v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label>b. Apakah anda obat-obatan lain yg dikonsumsi?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5">
                                        <v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label>b.1  Bila ada mohon sebutkan jenisnya dan alasan penggunaannya</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5">
                                        <v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                    color="primary"
                                    @click="e1 = 5"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
								<v-stepper-content step="5">
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Apakah anda minum minuman beralkohol lebih dari 750 ml perminggu ?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5">
                                        <v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                    color="primary"
                                    @click="e1 = 6"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
								<v-stepper-content step="6">
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Apakah Anda Merokok ?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5 ml-5">
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <label>a. Kalau "Ya", berapa batang sehari ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>a. Kalau "Ya", berapa batang sehari ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>b. Sejak kapan mulai merokok?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>b. Sejak kapan mulai merokok?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                    color="primary"
                                    @click="e1 = 7"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
								<v-stepper-content step="7">
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Apakah Anda pernah memeriksa pada dokter, dirawat di Rumah Sakit, Sanatorium, atau tempat istirahat lain karena sakit (pemulihan) atau saat ini sedang mendapat pengobatan dokter dan mendapat obat yang dikonsumsi secara teratur ?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5 ml-5">
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 ml-5">
											<label>Jika Anda menjawab <b>"YA"</b> mohon diisi pertanyaan berikut :</label>
									</v-row>
									<v-row class="mx-2 mt-n5 ml-5">
                                        <v-col cols="12" sm="6" md="6">
                                            <label>a. Nama Penyakit ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>a. Nama Penyakit ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>b. Kapan berobat atau dirawat (bulan dan tahun)</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>b. Kapan berobat atau dirawat (bulan dan tahun)</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>c. Berapa lama dirawat</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>c. Berapa lama dirawat</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>d. Nama dan Dosis Obat</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>d. Nama dan Dosis Obat</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>e. Nama Rumah Sakit /Dokter</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>e. Nama Rumah Sakit /Dokter</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                    color="primary"
                                    @click="e1 = 8"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
								<v-stepper-content step="8">
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Apakah Anda pernah mendapatkan luka berat atau dioperasi ?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5 ml-5">
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 ml-5">
											<label>Jika Anda menjawab <b>"YA"</b> mohon diisi pertanyaan berikut :</label>
									</v-row>
									<v-row class="mx-2 mt-n5 ml-5">
										<v-col cols="12" sm="6" md="6">
                                            <label>a. Kapan dioperasi (bulan dan tahun)</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>a. Kapan dioperasi (bulan dan tahun)</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>b. Operasi Apa ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>b. Operasi Apa ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>c. Nama Rumah Sakit / Dokter yang mengoperasi ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>c. Nama Rumah Sakit / Dokter yang mengoperasi ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                    color="primary"
                                    @click="e1 = 9"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
								<v-stepper-content step="9">
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Sekarang ini merasa sehat ?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5 ml-5">
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 ml-5">
										<v-col cols="12" sm="6" md="6">
                                            <label>a. Jika "Tidak" disebabkan karena apa ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>a. Jika "Tidak" disebabkan karena apa ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>b. Apakah Anda dapat melakukan pekerjaan dengan baik ?</label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>b. Apakah Anda dapat melakukan pekerjaan dengan baik ?</label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>c. Jika "Tidak" sebutkan alasannya ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>c. Jika "Tidak" sebutkan alasannya ?</label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                    color="primary"
                                    @click="e1 = 10"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
								<v-stepper-content step="10">
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Apakah anda memiliki hobi atau sewaktu-waktu melakukan kegiatan yang berisiko tinggi atau Anda masih aktif dalam pekerjaan berisiko tinggi yang tersebut di bawah ini ?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 mt-n5 ml-5">
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2">
                                        <v-col cols="12" sm="1" md="1">
											<label>TU</label>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
											<label>TT</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
											<label>TU</label>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
											<label>TT</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
											<label>TU</label>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
											<label>TT</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
											<label>TU</label>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
											<label>TT</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tul1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttl1"
											id="L1">
											<label for="L1"> a.Menyelam</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tur1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttr1"
											id="R1">
											<label for="R1"> e. Paralayang</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tul1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttl1"
											id="L1">
											<label for="L1"> i. Penerbangan</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tur1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttr1"
											id="R1">
											<label for="R1"> m. Nelayan</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tul1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttl1"
											id="L1">
											<label for="L1"> b.Olahraga air selain menyelam</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tur1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttr1"
											id="R1">
											<label for="R1"> f. Panjant tebing/mendaki gunung</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tul1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttl1"
											id="L1">
											<label for="L1"> j. Minyak dan Gas Bumi</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tur1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttr1"
											id="R1">
											<label for="R1"> n. Kelautan</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tul1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttl1"
											id="L1">
											<label for="L1"> c. Caving & Potholing</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tur1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttr1"
											id="R1">
											<label for="R1"> g. Microlighting</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tul1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttl1"
											id="L1">
											<label for="L1"> k. Pertambangan</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tur1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttr1"
											id="R1">
											<label for="R1"> lain-lain .....</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tul1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttl1"
											id="L1">
											<label for="L1"> d. Terjung Payung</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tur1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttr1"
											id="R1">
											<label for="R1"> h. Balap motor / mobil</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                            <input
											type="checkbox"
											v-model="q1.tul1"
											>
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                            <input
											type="checkbox"
											v-model="q1.ttl1"
											id="L1">
											<label for="L1"> l. Angkatan Bersenjata</label>
                                        </v-col>
										<v-col cols="12" sm="1" md="1">
                                        </v-col>
										<v-col cols="12" sm="2" md="2">
                                        </v-col>
									</v-row>

                                    <v-btn
                                    color="primary"
                                    @click="e1 = 11"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
								<v-stepper-content step="11">
									<v-row class="mx-2">
                                        <v-col cols="12" sm="12" md="12">
                                            <label> Khusus untuk Tertanggung perempuan ?</label>
                                        </v-col>
                                    </v-row>
									<v-row class="mx-2 ml-5">
											<label>a. Apakah anda pernah melakukan pap Smear ?</label>
									</v-row>
									<v-row class="mx-2 ml-5">
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Utama (TU)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label><b><u>Calon Tertanggung Tambahan (TT)</u></b></label>
											<br>
											<input type="radio" id="YaPickedTT" value="Ya" v-model="q1.pickedTT">
                                            <label for="YaPickedTT"> Ya</label>
                                            <input type="radio" id="TidakPickedTT" value="Tidak" v-model="q1.pickedTT" class="ml-5">
                                            <label for="TidakPickedTT"> Tidak</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5 ml-5">
										<v-col cols="12" sm="6" md="6">
                                            <label>i. Jika "Ya", Kapan pemeriksaan terakhir? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>i. Jika "Ya", Kapan pemeriksaan terakhir? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>ii. Bagaimana Hasilnya ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6">
                                            <label>ii. Bagaimana Hasilnya ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="12" md="12">
                                            <label>b. Apakah Anda dapat melakukan pekerjaan dengan baik ?</label>
										</v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="6" md="6" class="ml-4">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="12" md="12">
                                            <label>c. Apakah Anda saat ini dalam keadaan hamil ?</label>
										</v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="6" md="6" class="ml-4">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="12" md="12">
                                            <label>d. Apakah Anda pernah melahirkan ?</label>
										</v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="6" md="6" class="ml-4">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="ml-4">
                                            <label>Jika "Ya", sudah berapa kali ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
                                            <label>Jika "Ya", sudah berapa kali ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="12" md="12">
                                            <label>e. Apakah Anda pernah keguguran ?</label>
										</v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="6" md="6" class="ml-4">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="ml-4">
                                            <label>i. Jika "Ya", sudah berapa kali ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
                                            <label>i. Jika "Ya", sudah berapa kali ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="ml-4">
                                            <label>ii. Pada kehamilan berapa ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
                                            <label>ii. Pada kehamilan berapa ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="12" md="12">
                                            <label>f. Apakah Anda pernah melahirkan dengan cara operasi (sectio caesarea) ?</label>
										</v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="6" md="6" class="ml-4">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="ml-4">
                                            <label>i. Jika "Ya", kapan dan jelaskan penyebabnya ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
                                            <label>i. Jika "Ya", kapan dan jelaskan penyebabnya ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="ml-4">
                                            <label>ii. Nama & Alamat dokter/Rumah Sakit yang merawat ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
                                            <label>ii. Nama & Alamat dokter/Rumah Sakit yang merawat ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="12" md="12">
                                            <label>g. Apakah Anda pernah mengalami kesulitan/komplikasi pada saat hamil ataupun melahirkan ?</label>
										</v-col>
									</v-row>
									<v-row class="mx-2 mt-n5">
										<v-col cols="12" sm="6" md="6" class="ml-4">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
											<input type="radio" id="YaPickedTU" value="Ya" v-model="q1.pickedTU">
                                            <label for="YaPickedTU"> Ya</label>
                                            <input type="radio" id="TidakPickedTU" value="Tidak" v-model="q1.pickedTU" class="ml-5">
                                            <label for="TidakPickedTU"> Tidak</label>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="ml-4">
                                            <label>i. Jika "Ya", kapan dan bagaimana kondisi saat itu </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
                                            <label>i. Jika "Ya", kapan dan bagaimana kondisi saat itu </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="ml-4">
                                            <label>ii. Nama dan Alamat Dokter yang merawat ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
                                            <label>ii. Nama dan Alamat Dokter yang merawat ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="ml-4">
                                            <label>iii. Jenis Perawatan yang diberikan ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
										<v-col cols="12" sm="6" md="6" class="mr-n5">
                                            <label>iii. Jenis Perawatan yang diberikan ? </label>
											<br>
											<v-text-field
												single-line
												filled
											></v-text-field>
                                        </v-col>
									</v-row>

                                    <v-btn
                                    color="primary"
                                    @click="e1 = 11"
                                    >
                                    Continue
                                    </v-btn>

                                    <v-btn text>Cancel</v-btn>
                                </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-app>
                    </div>
                </v-col>
			</v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
			   		<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.campaign_cdate}}
Created User 	 : {{form.campaign_cuser}}
Last Update Date : {{form.campaign_ldate}}
Last Update User : {{form.campaign_luser}}

</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  <v-card-title>
	                <v-btn 
	                	color = "error"
	                	title = "Delete"
	                	@click = "Confirm('multidelete','')"
	                	v-if  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false">
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-btn 
	                	color  = "primary"
	                	title  = "Search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true">
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    v-model="selected"
			    :headers="headers"
			    :items="items"
			    :single-select="singleselect"
			    :search="search"
			    item-key="campaign_id"
			    show-select
			    class="elevation-1"
			     :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			  >
			    <template v-slot:top>
			      <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
			    </template>
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class="mr-2"
			        @click="Edit(item.campaign_id)"
			        :disabled = "access.edit === 0 ? true:false"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click="Confirm('delete',item.campaign_id)"
			        :disabled = "access.delete === 0 ? true:false"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
            <template  slot="items" slot-scope="row">
                <tr>
                    <td class="text-xs-left">{{row.item.campaign_id}}</td>
                    <td class="text-xs-left">{{row.item.campaign_title}}</td>
                    <!-- <td class="text-xs-left">{{row.item.campaign_actived}}</td> -->
                    <td class="text-xs-left">Edit</td>
                </tr>
            </template>
		  	</v-tab-item>
  	</v-tabs>
	<v-snackbar 
      :timeout="snackbar_timeout"
      :color="snackbar_color"
      v-model="snackbar_dialog"
      >
      {{ snackbar_text }}
      <v-btn dark text @click.native="snackbar_dialog = false">Close</v-btn>
  </v-snackbar>
  <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
        <v-card>
            <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
            	<v-spacer></v-spacer>
            	<v-btn id="dialog" color="success"
            			:disabled   = "confirm.ok_text !== 'Ok' ? true:false"
      					@click      = "Loading">{{confirm.ok_text}}</v-btn>
                <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  	data: () => ({
		  url_type: 'add',
		  cont: false,
  		access: {
  			read: 0,
  			add: 0,
  			edit: 0,
  			delete: 0,
          },
        e1:1,
		q1:{
			pickedTU:'',
			pickedTT:'',
			tul1:false,
			ttl1:false,
			tur1:false,
			ttr1:false,
			tul2:false,
			ttl2:false,
			tur2:false,
			ttr2:false,
			tul3:false,
			ttl3:false,
			tur3:false,
			ttr3:false,
			tul4:false,
			ttl4:false,
			tur4:false,
			ttr4:false,
			tul5:false,
			ttl5:false,
			tur5:false,
			ttr5:false,
			tul6:false,
			ttl6:false,
			tur6:false,
			ttr6:false,
			tul7:false,
			ttl7:false,
			tur7:false,
			ttr7:false,
			tul8:false,
			ttl8:false,
			tur8:false,
			ttr8:false,
			penyakitlain:'',
		},
		q2:{
			pickedTU:'',
			pickedTT:'',
			tul1:false,
			ttl1:false,
			tuPenyakit1:'',
			tuTglSakit1:'',
			tuDokter1:'',
			ttPenyakit1:'',
			ttTglSakit1:'',
			ttDokter1:'',
			tul2:false,
			ttl2:false,
			tuPenyakit2:'',
			tuTglSakit2:'',
			tuDokter2:'',
			ttPenyakit2:'',
			ttTglSakit2:'',
			ttDokter2:'',
			tul3:false,
			ttl3:false,
			tuPenyakit3:'',
			tuTglSakit3:'',
			tuDokter3:'',
			ttPenyakit3:'',
			ttTglSakit3:'',
			ttDokter3:'',
			tul4:false,
			ttl4:false,
			tuPenyakit4:'',
			tuTglSakit4:'',
			tuDokter4:'',
			ttPenyakit4:'',
			ttTglSakit4:'',
			ttDokter4:'',
			tul5:false,
			ttl5:false,
			tuPenyakit5:'',
			tuTglSakit5:'',
			tuDokter5:'',
			ttPenyakit5:'',
			ttTglSakit5:'',
			ttDokter5:'',
			tul6:false,
			ttl6:false,	
			tuPenyakit6:'',
			tuTglSakit6:'',
			tuDokter6:'',
			ttPenyakit6:'',
			ttTglSakit6:'',
			ttDokter6:'',
			tul7:false,
			ttl7:false,
			tuPenyakit7:'',
			tuTglSakit7:'',
			tuDokter7:'',
			ttPenyakit7:'',
			ttTglSakit7:'',
			ttDokter7:'',
		},
		format_money: {
			decimal: ',',
			thousands: '.',
			precision: 2,
		},
		maxinput:{
			id:4,
			title:150,
		},
  		snackbar_dialog: false,
	    snackbar_color: 'success',
	    snackbar_text: '',
		snackbar_timeout: 3000,
		modal:{
			effdate:false,
			expdate:false,
		},
	    confirm: {
	    	dialog: false,
	    	title: '',
	    	ok_text: 'Ok',
	    	ok_disabled: false,
	    },
        singleselect: false,
        selected: [],
        search: '',
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: false,
            value: 'campaign_id',
          },
          { text: 'Title', value: 'campaign_title' },
        //   { text: 'Actived', value: 'campaign_actived' },
          { text: 'Actions', value: 'action', sortable: false }
        ],
        items: [],
        loading: {
          list : false
        },
        btn_search: false,
        form: {
        	// campaign_id: '',
        	// campaign_id_rules: [v => !!v || 'Please fill ID'],
        	// campaign_id_readonly: false,
        },
        loader: null,
        loading_save: false
      	}),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
			//this function use for validating the screen with user privilleges
  			this.Access()
  			// setTimeout(function(){
            //     document.getElementById('campaign_id').focus()
            // }, 500)
		},
		Continue(index){
			if(index === 1 ) {
			this.e6 = 2
			}
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access () {
            let formData = new FormData()
            formData.append('menu_id', 'campaign')
            formData.append('users_id', this.$functions.UsersID())
            this.$axios.post(this.$functions.SafeURL('apiListMenuUser', ''),formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            })
            .then(response => {
                if (response.data.length > 0) {
                	this.access.read 	= response.data[0].usersmenu_read
                	this.access.add  	= response.data[0].usersmenu_add
                	this.access.edit    = response.data[0].usersmenu_edit
                	this.access.delete  = response.data[0].usersmenu_delete
                	if (response.data[0].usersmenu_read === 0) {
                		this.NoPriviledge()
                	}
                } else {
                	this.NoPriviledge()
                }

            })
            .catch(e => {
                this.SnackBar(true, 'error', e, 3000)
            })
		},
		//This function use for give feedback when user has no access with some feature
        NoPriviledge () {
        	this.SnackBar(true, 'error', 'You dont have access `Campaign` modul, please contact Administrator', 30000)
        },
		//Function for get all data from table campaign with API in mod_users.go
	  	List () {
	  		this.loading.list = true
            let formData = new FormData()
                this.$axios.post(this.$functions.SafeURL('apiListCampaign', ``), formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then(response => {
                    this.items = response.data
                    this.loading.list = false
                })
                .catch(e => {
                	this.SnackBar(true, 'error', e, 3000)
                    this.loading.list = false
                })
		  },
		  
		//Pop Up message for validating (Ok or Cancel) your proccess
	  	Confirm (flag, id) {
	  		if (flag === 'write') {
		  		if (this.$refs.form_campaign.validate()) {
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
		  		} else {
					  this.SnackBar(true, 'error', 'Some Fill Is Empty, Please Check Again', 3000)
				  }
	  		} else if (flag === 'delete') {
	  			this.url_type 		  = 'delete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ id + '`?'
	  			this.campaign_id 	  = id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multidelete') {
	  			this.url_type 		  = 'multidelete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'cancel'){
				this.url_type 		  = 'cancel'
				this.confirm.dialog	  = true
				this.confirm.title	  = 'This process will be clean up, are you sure? '
				setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
			}
		  },
		  
		//Function to request insert data to table Campaign 'The API will validating your data, include the user who process the data' (go to APIWriteCampaign with url_type = "add") 
	  	Add () {
	  		this.url_type 		 															= 'add'
			this.form.campaign_id_readonly 													= false
	  		setTimeout(function(){
                document.getElementById('campaign_id').focus()
			}, 500)
			this.confirm.dialog  	 = false
			this.confirm.ok_text 	 = 'Ok'
			this.confirm.ok_disabled = false
		  },
		  
		//Function to request update data to table Campaign 'The API will validating your data, include the user who process the data' (go to APIWriteCampaign with url_type = "edit") 
	  	Edit (id) {
	  		let formData = new FormData()
	  		formData.append('campaign_id', id)
            this.$axios.post(this.$functions.SafeURL('apiListCampaign', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
            	if (response.data[0].feedback === 'Y') {
            		document.getElementById('tabform').click()
            		this.url_type 			    		= 'edit'
					// this.form.campaign_ldate			= response.data[0].campaign_ldate
					// setTimeout(function(){
                	// 	document.getElementById('campaign_title').focus()
					// }, 500)
					this.e6 = 1
					// this.$refs.campaign_ujrohcedant.$el.getElementsByTagName('input')[0].value = response.data[0].campaign_ujrohcedant
	  			} else {
	  				this.SnackBar(true, 'error', response.data[0].feedback, 3000)
	  			}
            })
            .catch(e => {
            	this.confirm.dialog  	 = false
                this.confirm.ok_text 	 = 'Ok'
	  			this.confirm.ok_disabled = false
            	this.SnackBar(true, 'error', e, 3000)
            })
	  	},
	  	Delete (id) {
	  		let formData = new FormData()
	  		formData.append('campaign_id', id)
            this.$axios.post(this.$functions.SafeURL('apiDeleteCampaign', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
            	if (response.data[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Delete Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.ok_text 	 = 'Ok'
		  			this.confirm.ok_disabled = false
		  			this.url_type 			 = ''
		  			this.List()
	  			} else {
	  				this.SnackBar(true, 'error', response.data[0].feedback, 3000)
	  			}
            })
            .catch(e => {
            	this.confirm.dialog  	 = false
                this.confirm.ok_text 	 = 'Ok'
	  			this.confirm.ok_disabled = false
            	this.SnackBar(true, 'error', e, 3000)
            })
	  	},
	  	MultiProcess (flag) {
	  		let formData = new FormData()
	  		formData.append('choose', flag)
            let multiData = JSON.stringify(this.selected)
            let objData   = JSON.parse(multiData)
            for (var i = 0; i < this.selected.length; i++) {
                formData.append('data_multi', objData[i]['campaign_id'])
            }
            this.$axios.post(this.$functions.SafeURL('apiMultiDataCampaign', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
            	if (response.data[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.ok_text 	 = 'Ok'
		  			this.confirm.ok_disabled = false
		  			this.url_type 			 = ''
		  			this.List()
	  			} else {
	  				this.SnackBar(true, 'error', response.data[0].feedback, 3000)
	  			}
            })
            .catch(e => {
            	this.confirm.dialog  	 = false
                this.confirm.ok_text 	 = 'Ok'
	  			this.confirm.ok_disabled = false
            	this.SnackBar(true, 'error', e, 3000)
            })
	  	},
	  	Loading () {
	  		this.confirm.ok_text 	 = 'Loading...'
	  		this.confirm.ok_disabled = true
	  		if (this.url_type === 'add' || this.url_type === 'edit') {
	  			this.Write()
	  		} else if (this.url_type === 'delete') {
	  			this.Delete(this.campaign_id)
	  		} else if (this.url_type === 'multidelete') {
	  			this.MultiProcess('delete')
	  		} else if (this.url_type === 'cancel'){
				this.Add()
			}
	  	},
	  	Write () {
	  		let formData = new FormData()
			// let instype = document.getElementById('campaign_instype').value
	  		formData.append('url_type', this.url_type)
	  		// formData.append('campaign_id', this.form.campaign_id)
            this.$axios.post(this.$functions.SafeURL('apiWriteCampaign', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
            	if (response.data[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Save Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.ok_text 	 = 'Ok'
		  			this.confirm.ok_disabled = false
		  			this.url_type 			 = 'edit'
	  			} else {
	  				this.SnackBar(true, 'error', response.data[0].feedback, 3000)
	  			}
            })
            .catch(e => {
            	this.confirm.dialog  	 = false
                this.confirm.ok_text 	 = 'Ok'
	  			this.confirm.ok_disabled = false
            	this.SnackBar(true, 'error', e, 3000)
            })
	  	},
	  	SnackBar (dialog, color, text, timeout) {
	      this.snackbar_dialog = dialog
	      this.snackbar_color  = color
	      this.snackbar_text   = text
	      this.snackbar_timeout = timeout
	    }
	}
}
</script>