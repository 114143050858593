<template>
    <div>
        <v-dialog
            v-model="dialogtodo"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
        <v-snackbar
        :value	  ="snackbar.dialog"
        :timeout  ="snackbar.timeout"
        color	  ="fourth"
        rounded	  ="pill"
        top
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
          <v-btn dark text @click.native="snackbar.dialog =false" small>Close</v-btn>
        </template>
      </v-snackbar>
        <v-card>
            <v-toolbar dark color="primary" dense>
            <v-btn icon dark @click.stop="Close()">
                <v-icon>{{$vars.V('icon_back')}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }} - {{ id }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="ListData"
            >
                Search Data
                <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-btn
                text
                @click="Confirm('write',form.todo_id)"
            >
                Save Data
                <v-icon>{{$vars.V('icon_save')}}</v-icon>
            </v-btn>
            </v-toolbar>
            <v-container grid-list-md class="pa-12">
            <v-layout row wrap>
            <v-form enctype="multipart/form-data" ref="form_register" lazy-validation>
            <v-row class="mx-auto">
                  <v-col sm="12" md="2">
                  <v-dialog
                            ref="dialog_date"
                            v-model="modal.todo_date"
                            :return-value.sync="form.todo_date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                :value        ="todo_date_value"
                                :rules        ="form.todo_date_rules"
                                label         ="Follow Up Date"
                                append-icon   ="event"
                                placeholder   ="Input Follow Up Date"
                                readonly
                                v-on          ="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            ref           ="todo_date_picker"
                            label         ="Follow Up Date"
                            append-icon   ="calendar_today"
                            v-model       ="form.todo_date"
                            :max           ="new Date().toISOString().substr(0, 10)"
                            @change       ="SaveDate"
                            ></v-date-picker>
                        </v-dialog>
                  </v-col>
                  <v-col sm="12" md="1">
                   <v-dialog
                        ref="dialog_time"
                        v-model="modal.todo_time"
                        :return-value.sync="form.todo_time"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            :value        ="todo_time_value"
                            :rules        ="form.todo_time_rules"
                            label         ="Time"
                            append-icon   ="event"
                            placeholder   ="Input Time"
                            readonly
                            v-on          ="on"
                        ></v-text-field>
                        </template>
                        <v-time-picker
                        ref           ="todo_time_picker"
                        label         ="Time"
                        append-icon   ="calendar_today"
                        v-model       ="form.todo_time"
                        format="24hr"
                        >
                        <v-btn outlined @click ="modal.todo_time = false">Batal</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn outlined @click ="$refs.dialog_time.save(form.todo_time)">Ok</v-btn>
                        </v-time-picker>
                    </v-dialog>
                  </v-col>
                    <v-col sm="12" md="4" class="mt-3">
                    <app-cb
                        cb_type="code"
                        cb_url="apiListCode"
                        cb_url_parameter="code_actived=Y&code_group=REASONTODO"
                        cb_title="Reason"
                        cb_id="todo_reason"
                        cb_desc="todo_reason_desc"
                        cb_rules="Please fill Reason"
                        cb_desc_readonly="readonly"
                        cb_items_id="code_id"
                        cb_items_desc="code_desc"
                        :cb_value_id="form.todo_reason"
                        :cb_value_desc="form.todo_reason_desc"
                        @clicked="GetReason"
                    >
                    </app-cb>
                  </v-col>
                  <template v-if="form.todo_reason === 'R'">
                  <v-col sm="12" md="2">
                  <v-dialog
                            ref="dialog_reminderdate"
                            v-model="modal.todo_reminderdate"
                            :return-value.sync="form.todo_reminderdate"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                :value        ="todo_reminderdate_value"
                                :rules        ="form.todo_reminderdate_rules"
                                label         ="Reminder Date"
                                append-icon   ="event"
                                placeholder   ="Input Follow Up Date"
                                readonly
                                v-on          ="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            ref           ="todo_reminderdate_picker"
                            label         ="Reminder Date"
                            append-icon   ="calendar_today"
                            v-model       ="form.todo_reminderdate"
                            :min           ="new Date().toISOString().substr(0, 10)"
                            @change       ="SaveReminderDate"
                            ></v-date-picker>
                        </v-dialog>
                  </v-col>
                  <v-col sm="12" md="1">
                   <v-dialog
                        ref="dialog_remindertime"
                        v-model="modal.todo_remindertime"
                        :return-value.sync="form.todo_remindertime"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            :value        ="todo_remindertime_value"
                            :rules        ="form.todo_remindertime_rules"
                            label         ="Time"
                            append-icon   ="event"
                            placeholder   ="Input Time"
                            readonly
                            v-on          ="on"
                        ></v-text-field>
                        </template>
                        <v-time-picker
                        ref           ="todo_remindertime_picker"
                        label         ="Time"
                        append-icon   ="calendar_today"
                        v-model       ="form.todo_remindertime"
                        format="24hr"
                        >
                        <v-btn outlined @click ="modal.todo_remindertime = false">Batal</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn outlined @click ="$refs.dialog_remindertime.save(form.todo_remindertime)">Ok</v-btn>
                        </v-time-picker>
                    </v-dialog>
                  </v-col>
                  </template>
                  <v-col sm="12" :md="form.todo_reason === 'R'?'12':'5'">
                    <v-text-field
                      v-model="form.todo_desc"
                      :rules="form.todo_desc_rules"
                      id="todo_name"
                      prepend-inner-icon="notes"
                      label="Remarks"
                      @keyup.enter="Confirm('write',form.todo_id)"
                  >
                  </v-text-field>
                  </v-col>
                <v-col sm="12" md="12">
                <v-data-table
                    :headers="header['todo']"
                    :items="items['todo']"
                    :loading="loading.data"
                    loading-text="Loading... Please wait"
                    dense
                    item-key="dataid"
                    class="elevation-1 row-pointer"
                >
                    <template slot="headerCell" slot-scope="props">
                    <v-tooltip bottom>
                        <span slot="activator">
                        {{ props.header.text }}
                        </span>
                        <span>
                        {{ props.header.text }}
                        </span>
                    </v-tooltip>
                    </template>
                    <template v-slot:no-data> No Data Available </template>
                    <template v-slot:item.todo_reason="{ item }">
                        {{item.todo_reason_desc}}
                    </template>
                    <template v-slot:item.todo_date="{ item }">
                        {{$functions.FormatDateFullName(item.todo_date)}} {{item.todo_time}}
                    </template>
                    <template v-slot:item.todo_reminderdate="{ item }">
                        {{$functions.FormatDateFullName(item.todo_reminderdate)}} {{$functions.FormatTime(item.todo_remindertime)}}
                    </template>
                    <template v-slot:item.action="{ item }">
                      <div class="cursor primary--text" @click="Edit(item.todo_id)"><small><u>Edit</u></small></div>
                    </template>
                </v-data-table>
                </v-col>
                </v-row>
                </v-form>
            </v-layout>
            </v-container>
        </v-card>
        </v-dialog>
        <v-dialog
          v-model="remarks.dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          persistent
          v-if="remarks.feedback !== ''"
        >
          <v-card>
            <v-toolbar dark color="fourth" dense>
              <v-btn icon dark @click="remarks.dialog = false">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Error Messages</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-2 ml-n2">
              <div
                class="subheading font-weight-bold"
                v-html="remarks.feedback"
              ></div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
  export default {
    props: {
    dialog: Boolean,
    title: String,
    id: String,
  },
  data: () => ({
    limit: '1000',
    header: {
      todo: [
        { text: "Actions", value: "action", sortable: false },
        { text: "Date", value: "todo_date" },
        { text: "Reason", value: "todo_reason" },
        { text: "Reminder Date", value: "todo_reminderdate" },
        { text: "Remarks", value: "todo_desc" },
        { text: "User", value: "todo_cuser" },
      ],
    },
    items: [],
    loading: {
      data: false
    },
    modal: {
        todo_date: false,
        todo_time: false,
        todo_reminderdate: false,
        todo_remindertime: false,
    },
    confirm: {
      dialog: false,
      title: '',
      text: 'Ok',
      ok_disabled: false,
    },
    form: {
        todo_id: '',
        todo_desc: '',
        todo_desc_rules: [v => !!v || 'Please fill remarks'],
        todo_event: '',
        todo_date: new Date().toISOString().substr(0, 10),
        todo_date_date: new Date().toISOString().substr(0, 10),
        todo_date_rules: [v => !!v || 'Please fill date'],
        todo_time: '12:00',
        todo_time_value: '12:00',
        todo_reminderdate: new Date().toISOString().substr(0, 10),
        todo_reminderdate_date: new Date().toISOString().substr(0, 10),
        todo_reminderdate_rules: [v => !!v || 'Please fill date'],
        todo_remindertime: '12:00',
        todo_remindertime_value: '12:00',
        todo_remindertime_rules: [v => !!v || 'Please fill time'],
        todo_externalf: 'N',
        todo_attachment: '',
        todo_resultremarks: '',
        todo_status: '',
        todo_reason: '',
        todo_reason_desc: '',
        todo_actived: 'Y',
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: 'success',
      text: '',
      timeout: 3000
    },
  }),
//   created() {
    
//   },
  watch: {
    id: function (newdata) {
      if (newdata !== "") {
        this.id = newdata
        this.ListData()
      } else {
        this.id = ""
      }
    },
    dialogprofile: function (newdata) {
      if (newdata === true) {
        this.FirstLoad()
      }
    }
  },
  computed: {
    dialogtodo: {
        get() {
            return this.dialog
        },
        set(value) {
            if (!value) {
                this.$emit('close')
            }
        }
    },
    todo_date_value () {
        return this.form.todo_date ? this.$moment(this.form.todo_date).format('LL') : ''
    },
    todo_time_value () {
        return this.form.todo_time ? this.form.todo_time : ''
    },
    todo_reminderdate_value () {
        return this.form.todo_reminderdate ? this.$moment(this.form.todo_reminderdate).format('LL') : ''
    },
    todo_remindertime_value () {
        return this.form.todo_remindertime ? this.form.todo_remindertime : ''
    },
  },
  methods: {
    Confirm (flag, id) {
      if (flag === 'write') {
        if (this.$refs.form_register.validate()) {
          this.confirm.dialog   = true
          this.confirm.title    = 'Save'
          this.form.todo_id = id
          this.url_type = 'add'
          if (id !== '') {
            this.url_type = 'edit'
          }
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      }
    },
    Loading () {
      this.confirm.text 	 = 'Loading...'
      this.confirm.ok_disabled = true
      if (this.url_type === 'add' || this.url_type === 'edit') {
        this.Write()
      }
    },
    Close() {
        this.dialogtodo = false
    },
    ListData() {
        this.loading.data = true
        let formdata = {
          todo_refno: this.id,
          todo_modul: 'quotation',
          order_by: "todo_date",
          order_type: "ASC",
          limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListTodo"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
                this.loading.data = false
                this.items['todo'] = feedback
            } else {
               this.items['todo'] = feedback
               this.loading.data = false
               this.SnackBar(true, "error", feedback[0].feedback, 0)
            }
          } else {
            this.items['todo'] = feedback
            this.loading.data = false
            this.SnackBar(true, "error", 'No Data Available', 0)
          }
        })
    },
    CBGet(value) {
        this.$emit(
        "clicked",
        value[this.id]
      );
      this.Close()
    },
    GetReason(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|")
        this.form.todo_reason = splits[0]
        this.form.todo_reason_desc = splits[1]
      }
    },
    SaveDate (date) {
        this.$refs.dialog_date.save(date)
        this.form.todo_date_date = this.$moment(date).format('YYYY-MM-DD')
    },
    SaveReminderDate (date) {
        this.$refs.dialog_reminderdate.save(date)
        this.form.todo_reminderdate_date = this.$moment(date).format('YYYY-MM-DD')
    },
    Edit (id) {
        this.loading.data = true
        let formdata = {
          todo_id: id,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListTodo"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
                this.loading.data = false
                this.form.todo_id = feedback[0].todo_id
                this.form.todo_date = this.$functions.FormatDate(feedback[0].todo_date)
                this.form.todo_date_date = this.$functions.FormatDate(feedback[0].todo_date)
                this.form.todo_time = feedback[0].todo_time
                this.form.todo_reason = feedback[0].todo_reason
                this.form.todo_reason_desc = feedback[0].todo_reason_desc
                this.form.todo_desc = feedback[0].todo_desc
                this.form.todo_reminderdate = this.$functions.FormatDate(feedback[0].todo_reminderdate)
                this.form.todo_reminderdate_date = this.$functions.FormatDate(feedback[0].todo_reminderdate)
                this.form.todo_remindertime = feedback[0].todo_remindertime
                this.form.todo_actived = feedback[0].todo_actived
            } else {
               this.loading.data = false
               this.SnackBar(true, "error", feedback[0].feedback, 0)
            }
          } else {
            this.loading.data = false
            this.SnackBar(true, "error", 'No Data Available', 0)
          }
        })
    },
    Write () {
      let formdata = {
        url_type: this.url_type,
        todo_id: this.form.todo_id,
        todo_desc: this.form.todo_desc,
        todo_date: this.form.todo_date_date,
        todo_time: this.form.todo_time,
        todo_reason: this.form.todo_reason,
        todo_reminderdate: this.form.todo_reminderdate_date,
        todo_remindertime: this.form.todo_remindertime,
        todo_modul: 'quotation',
        todo_refno: this.id,
        todo_externalf: 'Y',
		}
        let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteTodo'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
		})
		.then(response => {
		let feedback = response.data
			if (feedback.length > 0) {
				if (feedback[0].feedback === 'Y') {
                this.SnackBar(true, 'success', 'Save Success', 3000)
                this.confirm.dialog  	 = false
                this.confirm.text 	 	 = 'Ok'
                this.form.todo_id        = feedback[0].feedback_id
                this.ListData()
		  	} else {
                this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                // this.remarks.dialog 	= true
				// this.remarks.feedback 	= feedback[0].feedback
			}
		} else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback
		}
		})
		.catch(e => {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = e
        })
	},
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      }
      if (color === "error") {
        this.confirm.dialog = false
        this.confirm.text = "Ok"
      }
    },
    Variable (flag, position) {
        return this.$functions.Variable(flag,position)
    }
  },
};
</script>