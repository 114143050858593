<template>
    <div id="app-profilecg-rate">
    <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.page"
        ></v-progress-linear>
     <div v-if="access.read === 0" class="text-center font-weight-black">
      {{$vars.V('txt_no_access')}}
     </div>
     <div v-else>
     <v-card-title 
        class="ml-n4"
    >
        <v-btn 
        :color 	="selected.length === items.length ? 'fourth':''"
        @click	="SelectAllPage"
        class	="mr-2"
        title	="select all page"
        small
        >{{$vars.V('txt_select_all_page')}}
        </v-btn>
        <v-btn 
        color     = "error"
        title 	  = "delete selected"
        @click 	  = "Confirm('multidelete','')"
        class	  = "mr-2"
        v-if  	  = "selected.length > 0"
        :disabled = "access.delete === 0 ? true:false"
        small>
        <v-icon>{{$vars.V('icon_delete')}}</v-icon>
        </v-btn>    
        <v-btn 
        @click	="Add"
        class	="mr-2"
        title	="Add New"
        small
        >{{$vars.V('txt_add')}}
        </v-btn>
       <v-btn 
          @click="List(id)"
          class="mr-2"
          title="Refresh"
          small
          ><v-icon>{{$vars.V('icon_refresh')}}</v-icon>
        </v-btn>
        <v-btn 
        color  = "primary"
        title  = "search"
        v-if   = "btn_search === false"
        @click = "btn_search = true"
        small>
        <v-icon>{{$vars.V('icon_search')}}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        @click:append="btn_search = false"
        label="Search"
        single-line
        hide-details
        v-if ="btn_search"
        ></v-text-field>
    </v-card-title>
    <v-data-table
    :value="selected"
    :headers="headers"
    :items="items"
    :search="search"
    @input="Check($event)"
    item-key="profilecgroupfd_id"
    show-select
    class="elevation-1"
    :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
    :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right'
    }"
    dense
    :loading 	= "loading.detail"
    loading-text="Loading... Please wait"
    >
    <template v-slot:item.profilecgroupfd_refid="{ item }">
        {{item.profilecgroupfd_refid}} - {{item.profilecgroupfd_refid_desc}}
    </template>
    <template v-slot:item.profilecgroupfd_feeamount="{ item }">
        {{$functions.NewFormatNumber(item.profilecgroupfd_feeamount)}}
    </template>
    <template v-slot:item.profilecgroupfd_dutyamount="{ item }">
        {{$functions.NewFormatNumber(item.profilecgroupfd_dutyamount)}}
    </template>
    <template v-slot:item.action="{ item }">
        <v-icon
        small
        @click	  ="Edit(item.profilecgroupfd_id)"
        :disabled ="access.edit === 0 ? true:false"
        title 	  = "edit"
        >
        {{$vars.V('icon_edit')}}
        </v-icon>
    </template>
    </v-data-table>
    </div>
    <!-- Start Form -->
    <v-dialog
      v-model="dialog.detail"
      max-width="800"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog.detail = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Bank</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Add" title="Add New"><v-icon color="white">add</v-icon></v-btn>
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save"><v-icon color="white">save</v-icon></v-btn>
        </v-toolbar>
      <v-card-text>
      <v-form enctype="multipart/form-data" ref="form_bank" lazy-validation>
        <v-row class="mx-auto">
            <v-col cols="12" sm="8" md="8" class="mt-3">
                <app-cb
                cb_type                ="profilecgroup"
                cb_url                 ="apiListProfileCGroup"
                cb_url_parameter       ="profilecgroup_actived=Y"
                cb_title               ="Profile"
                cb_id                  ="profilecgroupfd_refid"
                cb_desc                ="profilecgroupfd_refid_desc"
                cb_rules               ="Profile"
                cb_desc_readonly       ="readonly"
                cb_items_id            ="profilecgroup_id"
                cb_items_desc          ="profilecgroup_desc"
                :cb_value_id		   ="form.profilecgroupfd_refid"
                :cb_value_desc	       ="form.profilecgroupfd_refid_desc"
                @clicked               ="GetProfile"
                >
                </app-cb>
            </v-col>
            
             <v-col cols="12" sm="2" md="2">
              <vuetify-money
                v-model           ="form.profilecgroupfd_feeamount"
                v-bind:options    ="formatamount"
                label             ="Certificate Fee Amount"
                placeholder       ="Certificate Fee Amount"
                background-color  ="numeric"
                v-bind:properties ="properties"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <vuetify-money
                v-model           ="form.profilecgroupfd_dutyamount"
                v-bind:options    ="formatamount"
                label             ="Stamp Duty Amount"
                placeholder       ="Stamp Duty Amount"
                background-color  ="numeric"
                v-bind:properties ="properties"
              />
            </v-col>
            <!-- Active -->
            <v-col cols="2" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.profilecgroupfd_actived"
                color="primary"
                id="form.profilecgroupfd_actived"
                :label="form.profilecgroupfd_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>
        </v-row>
      </v-form>
      </v-card-text>
      <v-card-actions>
        
      </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      color		  ="fourth"
      rounded	  ="pill"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
      <v-card :color="Variable('confirm',4)">
        <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            id="dialog"
            :color="Variable('confirm',1)"
            :disabled   ="confirm.text === 'Ok' ? false:true"
            @click      ="Loading"
          >
            {{confirm.text}}
          </v-btn>
          <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Organization -->
    </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    id: String,
  },
  data: () => ({
    url_type: 'add',
    access: {
      read    : 0,
      add     : 0,
      edit    : 0,
      delete  : 0,
    },
    remarks: {
        feedback: "",
        dialog: false
    },
    snackbar: {
        dialog: false,
        color: "success",
        text: "",
        timeout: 3000,
    },
    confirm: {
        dialog: false,
        title: "",
        text: "Ok",
    },
    loading: {
        page      : true,
        detail    : true,
    },
    dialog: {
        detail: false
    },
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2
    },
    properties: {
      reverse: true
    },
    selected: [],
    items: [],
    btn_search: false,
    search: '',
    headers: [
      { text: 'ID', value: 'profilecgroupfd_id' },
      { text: 'Profile', value: 'profilecgroupfd_refid' },
      { text: 'Certificate Fee', value: 'profilecgroupfd_feeamount', align:'right' },
      { text: 'Stamp Duty', value: 'profilecgroupfd_dutyamount', align:'right' },
      { text: 'Actions', value: 'action', sortable: false }
    ],
    form: {
        profilecgroupfd_id: '',
        profilecgroupfd_cgroup: '',
        profilecgroupfd_refid: '',
        profilecgroupfd_refid_desc: '',
        profilecgroupfd_feeamount: 0,
        profilecgroupfd_dutyamount: 0,
        profilecgroupfd_actived: true,
    },
   }),
   watch: {
    id: function (newdata) {
      if (newdata !== "") {
        this.id = newdata
        this.List(newdata)
      } else {
        this.id = ""
        this.List(newdata)
      }
    },
   },
   created() {
    this.List(this.id)
   },
   methods: {
    Access() {
        let modul = 'profile'
        let formdata = {
            menu_id     : modul,
            users_id    : this.$functions.UsersID(),
            order_by    : 'menu_id',
            order_type  : 'ASC',
            limit       : this.limit,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios
        .post(this.$functions.UrlPOST('apiListMenuUser'), param, {
            headers: {
            'Content-Type': 'text/plain charset=ISO-8859-1',
            },
        })
        .then((response) => {
            let priv = response.data
            if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read
            this.access.add = priv[0].usersmenu_add
            this.access.edit = priv[0].usersmenu_edit
            this.access.delete = priv[0].usersmenu_delete
            if (priv[0].usersmenu_read === 0) {
                this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
            }
            } else {
            this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
            }
            this.loading.page = false
        })
        .catch((e) => {
            this.SnackBar(true, 'error', e, 0)
            this.loading.page = false
        })
    },
    SnackBar(dialog, color, text, timeout) {
        this.snackbar = {
            dialog: dialog,
            color: color,
            text: text,
            timeout: timeout,
        }
        if (color === "error") {
            this.confirm.dialog 	= false
            this.confirm.text 		= "Ok"
        }
    },
    Variable (flag, position) {
        return this.$functions.Variable(flag,position)
    },
    SelectAllPage () {
        this.selected = this.selected === this.items ? [] : this.items
    },
    Check (value) {
        this.selected = value
    },
    Loading () {
        this.confirm.text = 'Loading...'
        if (this.url_type === 'add' || this.url_type === 'edit') {
            this.Write()
        } else if (this.url_type === 'delete') {
            this.Delete(this.form.profilecgroupfd_id)
        } else if (this.url_type === 'multidelete') {
            this.MultiProcess('delete')
        }
    },
    Write () {
      let formdata = {
        url_type: this.url_type,
        profilecgroupfd_id: this.form.profilecgroupfd_id,
        profilecgroupfd_cgroup: this.form.profilecgroupfd_cgroup,
        profilecgroupfd_refid: this.form.profilecgroupfd_refid,
        profilecgroupfd_feeamount: String(this.form.profilecgroupfd_feeamount),
        profilecgroupfd_dutyamount: String(this.form.profilecgroupfd_dutyamount),
        profilecgroupfd_actived: this.$functions.ActivedFlag(this.form.profilecgroupfd_actived),
      }
      let param 	   = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteProfileCGroupFD'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.dialog.detail = false
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            if (this.url_type === 'add') {
              this.form.profilecgroupfd_cuser  = feedback[0].feedback_users_id
              this.form.profilecgroupfd_cdate  = feedback[0].feedback_users_date
            }
            this.List(this.id)
            this.form.profilecgroupfd_luser  = feedback[0].feedback_users_id
            this.form.profilecgroupfd_ldate  = feedback[0].feedback_users_date
            this.url_type 		      = 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = feedback
        }
      })
      .catch(e => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
    },
    Delete (id) {
      let formdata = {
        url_type: 'delete',
        profilecgroupfd_id: id
      }
      let param 	   = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteProfileCGroupFD'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog  	= false
            this.confirm.text 		= 'Ok'
            this.List(this.id)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
            this.confirm.dialog  	= false
            this.confirm.text 		= 'Ok'
          }
        }
      })
      .catch(e => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.confirm.dialog  	= false
        this.confirm.text 		= 'Ok'
      })
    },
    Add() { 
      this.dialog.detail = true
      this.url_type = 'add'
      this.form.profilecgroupfd_id = ''
      // this.form.profilecgroupfd_subclass = ''
      // this.form.profilecgroupfd_subclass_desc = ''
      this.form.profilecgroupfd_refid = ''
      this.form.profilecgroupfd_refid_desc = ''
      this.form.profilecgroupfd_feeamount = 0
      this.form.profilecgroupfd_dutyamount = 0
      setTimeout(function () {
        document.getElementById("profilecgroupfd_refid").focus();
      }, 500)
    },
    Edit (id) {
      if (id !== '') {
        this.Add()
        this.loading.detail = true
        let formdata = {
          profilecgroupfd_id: id
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListProfileCGroupFD'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
                this.dialog.detail = true
                this.url_type = 'edit'
                this.form.profilecgroupfd_id = feedback[0].profilecgroupfd_id
                this.form.profilecgroupfd_cgroup = feedback[0].profilecgroupfd_cgroup
                this.form.profilecgroupfd_refid = feedback[0].profilecgroupfd_refid
                this.form.profilecgroupfd_refid_desc = feedback[0].profilecgroupfd_refid_desc
                this.form.profilecgroupfd_feeamount = feedback[0].profilecgroupfd_feeamount
                this.form.profilecgroupfd_dutyamount = feedback[0].profilecgroupfd_dutyamount
                this.form.profilecgroupfd_actived = this.$functions.TrueOrFalse(feedback[0].profilecgroupfd_actived)
            } else {
                this.SnackBar(true, 'error', feedback[0].feedback, '3000')
            }
          } else {
            this.SnackBar(true, 'error', this.$functions.NoData(), '3000')
          }
          this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      }
    },
    Confirm (flag, id) {
      if (flag === 'add' || flag === 'edit') {
        if (this.$refs.form_bank.validate()) {
          this.confirm.dialog   	= true
          this.confirm.title    	= 'Save'
          setTimeout(function(){
          document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'delete') {
        this.url_type	  = 'delete'
        this.confirm.dialog       = true
        this.confirm.title        = 'Delete `'+ id + '`'
        this.form.profilecgroupfd_id 	        = id
        setTimeout(function(){
        document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multidelete') {
        this.url_type 		        = 'multidelete'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
        setTimeout(function(){
        document.getElementById('dialog').focus()
        }, 500)
      }
    },
    List (id) {
      this.Access()
      this.items = []
      if (id !== '') {
        this.loading.detail = true
        this.form.profilecgroupfd_cgroup = id
        let formdata = {
          profilecgroupfd_cgroup: id,
          order_by: 'profilecgroupfd_cgroup',
          order_type: 'ASC',
          limit: this.limit,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListProfileCGroupFD'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback !== 'Y') {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
            } else {
              this.items = feedback
            }
          } else {
            this.items = feedback
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      }
    },
    GetProfile(value) {
        if (value !== undefined) {
            let splits = this.$functions.Split(value, "|")
            this.form.profilecgroupfd_refid   = splits[0]
            this.form.profilecgroupfd_refid_desc = splits[1]
        }
    },
    }
}
</script>