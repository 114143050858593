import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
	state: {
   		 policy_holder: '',
   		 insured_name: '',
   		 long_insured_name: '',
   		 payor_address: '',
  	},
  	mutations: {
	    policy_holder(state, policy_holder) {
	      state.policy_holder = policy_holder
	    },
	    insured_name(state, insured_name) {
	      state.insured_name = insured_name
	    },
	    long_insured_name(state, long_insured_name) {
	      state.long_insured_name = long_insured_name
	    },
	    payor_address(state, payor_address) {
	      state.payor_address = payor_address
	    }
	},
	getters: {
    	policy_holder: state => state.policy_holder,
    	insured_name: state => state.insured_name,
    	long_insured_name: state => state.long_insured_name,
    	payor_address: state => state.payor_address,
  	}
})